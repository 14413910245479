import React from 'react';
import "./domainesInspireMe.css";
import { Trans, useTranslation } from "react-i18next"
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';

export const DomainesInspireMe = ({ setActiveStep, domainesSelected, setDomainesSelected, funelInformations, setFunelInformations, listeDomaines, listDomainesReconstitues, setListDomainesReconstitues }) => {
    const { t } = useTranslation();

    const itemsDomainesOptionsTemplate = (domaine) => {
        return (
            <div className='dom-card-container'>
                <div className='dom-card-image'>
                    <img src={domaine.image} alt={"description image"} />
                </div>
                <div className="dom-card-content">
                    <p>{domaine.label}</p>
                </div>
            </div>
        )
    }

    function handleChange(e) {
        // Constiue la liste des domaines choisis
        setDomainesSelected(e.target.value);
    }

    //Permet de reconstituer la liste des domaines avec toutes les infos car on a que la value
    function domainesReconstitues() {
        // Permet de récupérer les informations liées au domaine et reconstituer la liste avec toutes les infos
        const result = listeDomaines.filter((domaine) => {

            return domainesSelected.includes(domaine.value);
        });

        // Permet de constituer l'objet seulement avec les informations nécessaires  pour la requête au back
        let _resultFilterTemp = [];
        result.map(domaine => {
            _resultFilterTemp.push({
                label: domaine.label,
                value: domaine.value,
                categorie: domaine.categorie
            })
        });
        setListDomainesReconstitues(_resultFilterTemp);
    }

    function nextStep() {
        domainesReconstitues();
        setActiveStep(2);
    }

    return (
        <>
            <h1 className='dip-ta-center dip-color-ed'><Trans i18nKey="inspire-me.domaines-title" /></h1>
            <div className="dom-card-list">
                <SelectButton className='btn-domaines'
                    options={listeDomaines}
                    value={domainesSelected}
                    optionLabel="label"
                    optionValue="value"
                    multiple
                    itemTemplate={itemsDomainesOptionsTemplate}
                    onChange={(e) => handleChange(e)}
                />
            </div>
            <div className='dip-flex dip-jc-end p-mt-4 p-mr-1'>
                <Button
                    className="dip-btn-iris-bg p-button-rounded dip-ai-center dip-jc-center dip-ff-muslish"
                    disabled={domainesSelected.length === 0}
                    onClick={() => nextStep()}>
                    {(t('general.next'))}
                </Button>
            </div>
        </>
    )
}
