import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import "./confidentialite.css";

export const Confidentialite = () => {

    const history = useHistory();
    const {t} = useTranslation();

    const onRetourAccueilClick = () => {
        history.push('/');
    }

    return (
        <div className="confidentialite-container">
            {parse(t('confidentialite.body'))}
            <div className="float-accueil-button-confidentialite"><Button label="Accueil" onClick={(e) => onRetourAccueilClick()}></Button></div>
        </div>
    )
}