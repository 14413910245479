import { SpeedDial } from "primereact/speeddial";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { Trans, useTranslation } from "react-i18next";
import Helper from "../../utils/helper";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import RechercheService from "../../services/rechercheService";
import { Button } from "primereact/button";
import AppellationService from "../../services/appellationService";
import { Toast } from "primereact/toast";
import { enumHttpError } from "../../enums/enumHttpError";
import { classNames } from "primereact/utils";

export const AjouterAppellationSpeedDial = (props) => {

    const { t } = useTranslation();
    const [displayDialog, setDisplayDialog] = useState(false);
    const [appellation, setAppellation] = useState(null);
    const [lazyDomaines, setLazyDomaines] = useState([]);
    const [selectedDomaine, setSelectedDomaine] = useState(null);
    const [lazyFonctions, setLazyFonctions] = useState([]);
    const [selectedFonction, setSelectedFonction] = useState(null);
    const [optionsAlias, setOptionsAlias] = useState([]);
    const [selectedAliases, setSelectedAliases] = useState([]);
    const [lazyLoading, setLazyLoading] = useState(false);
    const [filterTimerId, setFilterTimerId] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false)

    const toast = useRef(null)

    const rechercheService = new RechercheService();
    const appellationService = new AppellationService();

    useEffect(() => {
        appellationService.getAppellationAlias()
            .then(
                (_alias) => {
                    setOptionsAlias(_alias)
                }
            )
    }, [])


    const itemsAddAppellation = [
        {
            label: t('formation.add_metier'),
            icon: 'pi pi-plus',
            command: (event) => {
                setDisplayDialog(true);
            }
        },
    ]

    const addAppellation = () => {
        setSubmitted(true)
        setLoading(true)
        if (appellation) {
            appellationService
                .addAppellation(appellation)
                .then((_appellation) => {
                    //setRefreshToken(new Date());
                    props.onNewAppellation(appellation?.libelle)
                    setDisplayDialog(false);
                    setSubmitted(false);
                    toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('formation.added_appellation'), life: 3000 });
                })
                .catch((err) => {
                    switch (err.message) {
                        case enumHttpError.JOBALREADYEXISTS:
                            toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('formation.appellation_already_added_sameLabel'), life: 3000 });
                            break;
                        default:
                            toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
                            break;
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        else {

        }

    }

    const handleAppellationChange = (value) => {
        let _appellation = { ...appellation };
        _appellation.libelle = value;
        setAppellation(_appellation);
    }

    const handleDomaineChange = (e) => {
        setSelectedDomaine(e.value);
        setSelectedFonction(null);
        let _appellation = { ...appellation };
        _appellation.dipMetierId = null;
        setAppellation(_appellation);
    }

    const handleFonctionChange = (e) => {
        setSelectedFonction(e.value);
        let _fonctionId = parseInt(e.value)
        let _appellation = { ...appellation };
        if (_fonctionId) {
            _appellation.dipMetierId = _fonctionId;
        }
        else {
            _appellation.dipMetierId = null;
        }
        setAppellation(_appellation);
    }

    const handleAliasChange = (e) => {
        setSelectedAliases(e.value)
        let _appellation = { ...appellation };
        let _appellationAliasAppellation = []
        e.value.forEach(id => {
            _appellationAliasAppellation.push({ appellationAliasId : id })
        });
        _appellation.appellationAliasAppellation = _appellationAliasAppellation
        setAppellation(_appellation)
    }

    const resetForm = () => {
        setDisplayDialog(false)
        setAppellation(null)
        setSelectedDomaine(null)
        setSelectedFonction(null)
        setSelectedAliases([])
    }

    const searchDomaines = (event) => {
        setLazyLoading(true);
        let query = event?.filter;
        rechercheService.getRechercheDipDomaine(query).then(res => {
            setLazyDomaines(res);
            setLazyLoading(false)
        });
    }

    const timedOutSearchDomaines = (event) => {
        setLazyLoading(true);
        if (filterTimerId) {
            clearTimeout(filterTimerId);
        }
        var _filterTimerId = setTimeout(() => {
            searchDomaines(event)
        }, 1000)
        setFilterTimerId(_filterTimerId);
    }

    const searchFonctions = (event) => {
        setLazyLoading(true);
        let query = event?.filter;
        let _id = parseInt(selectedDomaine)
        rechercheService.getRechercheDipMetierSingleDipDomaine(query, _id).then(res => {
            setLazyFonctions(res);
            setLazyLoading(false)
        });
    }

    const timedOutSearchFonctions = (event) => {
        setLazyLoading(true);
        if (filterTimerId) {
            clearTimeout(filterTimerId);
        }
        var _filterTimerId = setTimeout(() => {
            searchFonctions(event)
        }, 1000)
        setFilterTimerId(_filterTimerId);
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label={t('general.cancel')} icon="pi pi-times" onClick={() => resetForm()} className="p-button-text" />
                <Button label={t('general.save')} icon="pi pi-check" onClick={() => addAppellation()} className="p-button-text" />
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="ajout-picklist-item">
                <Tooltip target=".speeddial-right .p-speeddial-action" position="top" />
                <SpeedDial model={itemsAddAppellation} id="ajoutAppellation" showIcon="pi pi-ellipsis-v" hideIcon="pi pi-ellipsis-h" direction="up" className="speeddial-right" buttonClassName="p-button-warning" />
                <Dialog header={t('appellation.create_new_metier')} visible={displayDialog} style={{ width: '50vw', height: '80vh' }} footer={renderFooter} onHide={() => resetForm()}>
                    <div className='p-grid'>
                        <div className='p-col-12 p-fluid'>
                            <label><Trans i18nKey="appellation.metier" />*</label>
                            <InputText
                                autoFocus
                                disabled={loading}
                                value={appellation?.libelle}
                                onChange={(e) => handleAppellationChange(e.target.value)}
                                className={classNames({ 'p-invalid': submitted && !appellation?.libelle })}
                            />
                            {submitted && !appellation?.libelle && <small className="p-error"><Trans i18nKey="general.required" /></small>}
                        </div>
                        <Divider />
                        <div className='p-col-12 p-fluid'>
                            <label><Trans i18nKey="appellation.domain" /></label>
                            <Dropdown
                                disabled={loading}
                                value={selectedDomaine}
                                options={lazyDomaines}
                                optionLabel="label"
                                optionValue="value"
                                panelClassName={lazyLoading && "lmsr-panel-loading"}
                                onChange={(e) => handleDomaineChange(e)}
                                filter
                                onShow={searchDomaines}
                                onFilter={timedOutSearchDomaines}
                                appendTo="self"
                            />
                        </div>
                        <div className='p-col-12 p-fluid'>
                            <label><Trans i18nKey="appellation.function" /></label>
                            <Dropdown
                                disabled={loading}
                                value={selectedFonction}
                                options={lazyFonctions}
                                optionLabel="label"
                                optionValue="value"
                                panelClassName={lazyLoading && "lmsr-panel-loading"}
                                onChange={(e) => handleFonctionChange(e)}
                                filter
                                onShow={searchFonctions}
                                onFilter={timedOutSearchFonctions}
                                appendTo="self"
                            />
                        </div>
                        <Divider />
                        <div className='p-col-12 p-fluid'>
                            <label><Trans i18nKey="appellation.alias" /></label>
                            <MultiSelect
                                disabled={loading}
                                className='active-search-multi-select'
                                value={selectedAliases}
                                options={optionsAlias}
                                optionLabel="libelle"
                                optionValue="id"
                                maxSelectedLabels={0}
                                showSelectAll={false}
                                onChange={(e) => handleAliasChange(e)}
                                filter
                                selectedItemsLabel={t('primereact.selectedItemsLabel')}
                                appendTo="self"
                            />
                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    )
}