import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog"
import { InputSwitch } from "primereact/inputswitch"
import { useContext, useState, useEffect, useRef } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import parse from 'html-react-parser';
import "./entrepriseProjetThumbnail.css"
import OffreEmploiService from "../../services/offreEmploiService"
import { Divider } from "primereact/divider"
import { Dialog } from "primereact/dialog"
import { EntrepriseProjetForm } from "../../pages/entreprise/entrepriseProjetForm"
import { Button } from "primereact/button"
import { Tooltip } from "primereact/tooltip"
import { enumCategorieRecherche } from "../../enums/enumCategorieRecherche"
import ProjetService from "../../services/projetService"

export const EntrepriseProjetThumbnail = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [secondConfirmDeleteDialog, setSecondConfirmDeleteDialog] = useState(false)
    const [displayAddProject, setDisplayAddProject] = useState(false);
    const [loading, setLoading] = useState(false)
    const projetService = new ProjetService();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;

    const [isTruncated, setIsTruncated] = useState(false);
    const descriptionRef = useRef();

    useEffect(() => {
        const element = descriptionRef.current;

        if (element) {
            // Vérifier si le texte est tronqué
            setIsTruncated(element.scrollHeight > element.clientHeight + 1);
        }
    }, [props.projet?.description]);

    const onEditClick = () => {
        setDisplayAddProject(true);
    }

    const onConfirmAllDeleteDialog = () => {
        confirmDialog({
            message: 'Les éléments profils et formations seront supprimés.',
            header: 'Voulez-vous également supprimer tous les éléments qui étaient uniquement liés à ce projet ?',
            rejctClassName: "dip-btn-red-outline",
            acceptClassName: 'dip-btn-red-bg',
            accept: () => {
                // all delete
                setLoading(true)
                projetService.deleteProjet(props.projet.id, true)
                    .then(
                        (data) => {
                            let _lazyParams = { ...props.contextRecherche.lazyParams };
                            _lazyParams.first = 0;
                            _lazyParams.page = 1;
                            props.contextRecherche.setLazyParams(_lazyParams);
                            props.contextRecherche.rechercheApi(_lazyParams);
                        })
                    .finally(
                        () => {
                            setLoading(false)
                        })
            },
            reject: () => {
                // only projet delete
                setLoading(true)
                projetService.deleteProjet(props.projet.id, false)
                    .then(
                        (data) => {
                            let _lazyParams = { ...props.contextRecherche.lazyParams };
                            _lazyParams.first = 0;
                            _lazyParams.page = 1;
                            props.contextRecherche.setLazyParams(_lazyParams);
                            props.contextRecherche.rechercheApi(_lazyParams);
                        })
                    .finally(
                        () => {
                            setLoading(false)
                        })
            },
            acceptLabel: "Supprimer",
            rejectLabel: "Conserver"
        });
    }

    const onDeleteClick = () => {
        confirmDialog({
            message: 'Etes-vous sûr de vouloir supprimer ce projet ?',
            header: 'Confirmez-vous la suppression du projet ' + props.projet.nom + ' ?',
            rejctClassName: "dip-btn-red-outline",
            acceptClassName: 'dip-btn-red-bg',
            accept: () => {
                // setSecondConfirmDeleteDialog(true)
                onConfirmAllDeleteDialog()
                // setLoading(true)
                // projetService.deleteProjet(props.projet.id)
                //     .then(
                //         (data) => {
                //             let _lazyParams = { ...props.contextRecherche.lazyParams };
                //             _lazyParams.first = 0;
                //             _lazyParams.page = 1;
                //             props.contextRecherche.setLazyParams(_lazyParams);
                //             props.contextRecherche.rechercheApi(_lazyParams);
                //         })
                //     .finally(
                //         () => {
                //             setLoading(false)
                //         })
            },
            reject: () => { },
            acceptLabel: "Supprimer",
            rejectLabel: "Annuler"
        });
    }

    const handleChangeStatut = (e) => {
        setLoading(true)
        projetService.toggleStatut(props.projet.id)
            .then(
                (data) => {
                    let _lazyParams = { ...props.contextRecherche.lazyParams };
                    _lazyParams.first = 0;
                    _lazyParams.page = 1;
                    props.contextRecherche.setLazyParams(_lazyParams);
                    props.contextRecherche.rechercheApi(_lazyParams);
                })
            .finally(
                () => {
                    setLoading(false)
                })
    }

    const onHideAddProject = () => {
        setDisplayAddProject(false)
    }

    const onProfilsClick = () => {
        history.push({
            pathname: "/seeked_profiles",
            state: {
                filters:
                    [
                        { value: `${props.projet.id}`, label: props.projet.nom, categorie: enumCategorieRecherche.Projet }
                    ],
                adminImpersonatingRecruteurId: adminImpersonatingRecruteurId

            }
        });
    }

    const onOffresClick = () => {
        history.push({
            pathname: "/offers",
            state: {
                filters:
                    [
                        { value: `${props.projet.id}`, label: props.projet.nom, categorie: enumCategorieRecherche.Projet }
                    ],
                adminImpersonatingRecruteurId: adminImpersonatingRecruteurId
            }
        });
    }

    const onFormationsClick = () => {
        history.push({
            pathname: "/formationsFavorites",
            state: {
                filters:
                    [
                        { value: `${props.projet.id}`, label: props.projet.nom, categorie: enumCategorieRecherche.Projet }
                    ],
                adminImpersonatingRecruteurId: adminImpersonatingRecruteurId
            }
        });
    }


    return (
        <>
            <div className={`eoet-main-card p-mb-3 eoet-border-card ${loading && "fai-disabled"}`}>
                <div className="ept-description">
                    <div className="eoet-top-line dip-jc-between">
                        <div></div>
                        <div className="dip-flex-row dip-jc-between">
                            <div className="dip-flex-row dip-ai-center dip-ai-start">
                                <div className="dip-flex-row dip-ai-center">
                                    <label className="field-checkbox sp-checkbox-label dip-fw-700">
                                        Ouvert
                                    </label>
                                    <InputSwitch
                                        className="p-mr-3"
                                        disabled={loading}
                                        checked={props.projet.estOuvert == true}
                                        onChange={(e) => handleChangeStatut(e)}
                                    />
                                </div>
                                <div className="p-mr-2 eoet-pointer" onClick={onEditClick}><i className="pi pi-pencil" style={{ color: 'var(--terra-cotta)', fontSize: '1.3rem' }} /></div>
                                <div className="eoet-pointer" onClick={onDeleteClick}><i className="pi pi-trash" style={{ color: 'var(--terra-cotta)', fontSize: '1.3rem' }} /></div>
                            </div>
                        </div>
                    </div>
                    <div className="ept-title">
                        {props.projet.nom}
                    </div>
                    <div style={{ position: 'relative' }}>
                        <p
                            className="ept-description-container"
                            ref={descriptionRef}
                            data-pr-tooltip={isTruncated ? props.projet?.description : null}
                            data-pr-at="left top"
                        >
                            {props.projet?.description && parse(props.projet?.description)}
                        </p>
                        {isTruncated && <Tooltip className="ept-tooltip" target=".ept-description-container" position="top" />}
                    </div>
                </div>
                <Divider />
                <div className="p-grid">
                    <div className="p-col-6 p-sm-6 p-lg-4">
                        <Button className="ept-button" onClick={onProfilsClick}>
                            <div className="p-mb-2">Profils</div>
                            <i className="pi pi-user" style={{ fontSize: '2rem' }} />
                            <div className="ept-compteur">{props.projet.compteurProjetEtudiants}</div>
                        </Button>
                    </div>
                    <div className="p-col-6 p-sm-6 p-lg-4">
                        <Button className="ept-button" onClick={onOffresClick}>
                            <div className="p-mb-2">Offres</div>
                            <i className="pi pi-file" style={{ fontSize: '2rem' }} />
                            <div className="ept-compteur">{props.projet.compteurProjetOffreEmplois}</div>
                        </Button>
                    </div>
                    <div className="p-col-6 p-sm-6 p-lg-4">
                        <Button className="ept-button" onClick={onFormationsClick}>
                            <div className="p-mb-2">Formations</div>
                            <i className="pi pi-briefcase" style={{ fontSize: '2rem' }} />
                            <div className="ept-compteur">{props.projet.compteurProjetFormations}</div>
                        </Button>
                    </div>
                    <div className="p-col-6 p-sm-6 p-lg-4">
                        <Button className="ept-button" disabled>
                            <div className="p-mb-2">Recherches</div>
                            <i className="pi pi-share-alt" style={{ fontSize: '2rem' }} />
                            <div className="ept-compteur">0</div>
                        </Button>
                    </div>
                    <div className="p-col-6 p-sm-6 p-lg-4">
                        <Button className="ept-button" disabled>
                            <div className="p-mb-2">Alertes</div>
                            <i className="pi pi-star" style={{ fontSize: '2rem' }} />
                            <div className="ept-compteur">0</div>
                        </Button>
                    </div>
                </div>
            </div>
            <Dialog header={t('project.edit')} className="ep-confirm-dialog" visible={displayAddProject} onHide={onHideAddProject}>
                <EntrepriseProjetForm onSave={props.onSave} projet={props.projet} />
            </Dialog>
            <ConfirmDialog className="eoet-confirm-dialog" visible={deleteDialog} style={{ width: '50vw' }} onHide={() => setDeleteDialog(false)} rejectLabel="Annuler" acceptLabel="Supprimer">
            </ConfirmDialog>
            <ConfirmDialog className="eoet-confirm-dialog" visible={secondConfirmDeleteDialog} style={{ width: '50vw' }} onHide={() => setSecondConfirmDeleteDialog(false)} rejectLabel="Annuler" acceptLabel="Supprimer" />
        </>
    )
}