import axios from 'axios';
import BaseService from './baseService';

export default class MessagerieService extends BaseService {

  getMyInfo(currentUser) {
    const options = {
      headers: { Authorization: 'Bearer ' + currentUser.token }
    };
    return axios.get(window.API_URL + '/api/messagerie/GetMyInfo', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  GetUserInfo(currentUser, userId) {
    const options = {
      headers: { Authorization: 'Bearer ' + currentUser.token }
    };
    return axios.get(window.API_URL + '/api/messagerie/GetUserInfo/' + userId, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

 
  getMyThread2(currentUser) {
    const options = {
      headers: { Authorization: 'Bearer ' + currentUser.token }
    };
    return axios.get(window.API_URL + '/api/messagerie/GetMyThread2', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getMyThreadInfo(currentUser, threads) {
    const options = {
      headers: { Authorization: 'Bearer ' + currentUser.token},
    };

    var queryParams = super.getQueryParams({threads : threads})

    return axios.get(window.API_URL + '/api/messagerie/GetMyThreadInfo?' + queryParams, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  openThread(currentUser, destUserId) {
    const options = {
      headers: { Authorization: 'Bearer ' + currentUser.token }
    };
    return axios.get(window.API_URL + '/api/messagerie/OpenThreadWith/' + destUserId, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  closeThread(currentUser, threadId) {
    const options = {
      headers: { Authorization: 'Bearer ' + currentUser.token }
    };
    return axios.get(window.API_URL + '/api/messagerie/CloseThread/' + threadId, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }




}
