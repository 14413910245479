import BaseService from './baseService';

export default class ContratService extends BaseService {
    getAllContratForProfilEtudiant() {
        return this.axiosApiInstance.get(window.API_URL + '/api/contrat/GetAllContratForProfilEtudiant')
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getAllContratForAnnonceInterne() {
        return this.axiosApiInstance.get(window.API_URL + '/api/contrat/GetAllContratForAnnonceInterne')
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getAllContratForRechercheAffinee() {
        return this.axiosApiInstance.get(window.API_URL + '/api/contrat/GetAllContratForRechercheAffinee')
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }
}