import { Avatar } from "primereact/avatar"
import { useEffect, useState } from "react"
import { DisplayProfileImage } from "../image/displayProfileImage"
import "./avatarRenderer.css"

import ImageService from '../../services/imageService';


export const AvatarRenderer = (props) => {

    const [imageUrl, setImageUrl] = useState(props.imageUrl)
    const [color, setColor] = useState(null)
    const [colorHexa, setColorHexa] = useState(null)

    useEffect(() => {
        if (props.colorSeed) {
            let reste = props.colorSeed % 5
            switch (reste) {
                case 0:
                    setColor('green')
                    setColorHexa('#22C55E')
                    break;
                case 1:
                    setColor('blue')
                    setColorHexa('#3B82F6')
                    break;
                case 2:
                    setColor('yellow')
                    setColorHexa('#F59E0B')
                    break;
                case 3:
                    setColor('purple')
                    setColorHexa('#A855F7')
                    break;
                case 4:
                    setColor('red')
                    setColorHexa('#EF4444')
                    break;
                default:
                    setColor('')
                    setColorHexa('#9bc1bc')
                    break;
            }
        }

        
    }, [])

    useEffect(() => {
        if(props.imageId != null && props.imageId !== "0")
        {
        var imageService = new ImageService();
        imageService.getProfileImage(props.imageId)
          .then(response => {
            let blob = new Blob(
              [response.data],
              { type: response.headers['content-type'] }
            )
            let image = URL.createObjectURL(blob);
            setImageUrl(image);
          });
    
          return function cleanup() {
            URL.revokeObjectURL(imageUrl);
          };
        }
    
      }, props.imageId);

    return (
        <div className="avatar-renderer-container-2" onClick={props.onClick}>
            {/* { {props.display == "image" && props.imageId && <DisplayProfileImage imageId={props.imageId} />}
            {props.display == "initials" && props.prenom && props.nom && props.colorSeed && <div className={`${"avatar-renderer-" + color} avatar-renderer-initials`}><div>{props.prenom[0]}{props.nom[0]}</div></div>}
            {!props.display && <Avatar icon="pi pi-user" size={props.size != null ? props.size : "large"} shape={props.shape != null ? props.shape : "large"} ></Avatar>} } */}

            {(props.text == null || props.text == "") && (imageUrl == null || imageUrl == "") &&  <Avatar icon="pi pi-user" size={props.size != null ? props.size : "large"} shape={props.shape != null ? props.shape : "circle"} style={{ backgroundColor: "var(--opal)", color: "#ffffff" }}></Avatar>}
            {(props.text != null && props.text != "") && (imageUrl == null || imageUrl == "") &&  <Avatar label={props.text} size={props.size != null ? props.size : "large"} shape={props.shape != null ? props.shape : "circle"} style={{ backgroundColor: "var(--opal)", color: "#ffffff" }}></Avatar>}
            {(imageUrl != null && imageUrl != "") &&  <Avatar image={imageUrl} size={props.size != null ? props.size : "large"} shape={props.shape != null ? props.shape : "circle"}></Avatar>}
       </div>
    )
}