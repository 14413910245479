import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuthState } from "../../context/context";
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import PicEntreprise from "../../images/accueil/ecole/entrepriseRetexLogo.webp";
import PicEntreprisePortalPreview from "../../images/savoir-plus/entreprise/entreprisePortalPreview.png";

import RoleHelper from "../../utils/roleHelper";
import { enumRoles } from '../../enums/enumRoles';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./savoirplus_entreprise.css";

export const SavoirPlus_Entreprise = (props) => {

    const currentUser = useAuthState();

    const history = useHistory();
    const { t } = useTranslation();

    const onTableauDeBord = () => {
        history.push('/dashboard');
    };

    const onRetourCreationEntrepriseClick = () => {
        history.push({ pathname: '/register?User=Company', state: { userType: enumRoles.ENTREPRISE, previousUrl: history.location.pathname } })
    }

    return (
        <div className={props.className}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Entreprise - DiplomAdvisor</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content={t("metadesc.about-companies")} />
            </Helmet>
            <div className="savoirplus-entreprise-container">
                <div className="savoirplus-entreprise-head p-grid">
                    <div className="p-col-12">
                        {/* <h1>{parse(t("information.savoirplus.entreprise.Title1"))}</h1>
                        <h2>{parse(t("information.savoirplus.entreprise.Title2"))}</h2> */}
                        <h1 className="dip-color-tc">{"Disponible très bientôt".toUpperCase()}</h1>
                    </div>

                </div>

                <div className="p-grid">
                    <div className="imagecard-entreprise p-md-12 p-lg-6 p-col-align-center" /*title="Simple Card"*/>
                        {/* <img src={PicEntreprise} /> */}
                        <img src={PicEntreprisePortalPreview} />
                    </div>
                    <div className="p-md-12 p-lg-6 p-col-align-center p-col-12" /*title="Simple Card"*/ >
                        {/* <h3 className="text-huge-entreprise">{t("information.savoirplus.entreprise.TextPresentation1")}</h3> */}
                        {/* <h3 className="text-huge-entreprise2">{t("information.savoirplus.entreprise.TextPresentation2")}</h3> */}
                        <h3 className="text-huge-entreprise2">Nouvelle version de la plateforme entreprise disponible prochainement</h3>
                    </div>
                </div>

                {/* <div className="creer-un-compte-button-entreprise">
                    {(RoleHelper.isEntreprise(currentUser)) ? (<Button label={t("information.savoirplus.entreprise.BouttonUserEntreprise")} onClick={(e) => onTableauDeBord()}></Button>)
                        : (<Button label={t("information.savoirplus.entreprise.BouttonUserNonEntreprise")} onClick={(e) => onRetourCreationEntrepriseClick()}  ></Button>)}
                </div> */}

                <div className="accueil-button-entreprise">
                    <Link to="/"><Button label="Accueil"></Button></Link>
                </div>
            </div>
        </div>
    )
}