import React, { useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import UserService from '../../services/userService';
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "./dt_delete_etudiant.css";

export const Dt_delete_etudiant = (props) => {

    const [loading, setLoading] = useState(false);
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
    const [detailEtudiant, setDetailEtudiant] = useState(null);
    const { t } = useTranslation();
    const toast = useRef(null);
    const userService = new UserService();

    const statutOptions = [
        { label: "Nouveau candidat", value: 1 },
        { label: "Candidat en attente", value: 2 },
        { label: "Candidat contacté", value: 3 },
        { label: "Entretien passé", value: 4 },
        { label: "Candidat recruté", value: 5 },
        { label: "Candidat rejeté", value: 6 }
    ]

    const onPopupDelete = () => {
        loadLazyData();
        setConfirmDeleteVisible(true);
    }

    const loadLazyData = () => {
        setLoading(true);
        userService.getInfoBeforeDeleteStudent(props.etudiant.id)
            .then(_data => {
                setDetailEtudiant(_data);
            })
            .catch(err => showError())
            .finally(() => setLoading(false))
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }
 
    const deleteCompteEtudiant = () => {
        toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 })
        userService.hardDelete(props.etudiant)
            .then(user => {
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.entity_suppression_success'), life: 3000 });
                props.onEtudiantDelete();
                setConfirmDeleteVisible(false);
            }
            )
            .catch((err) =>
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.entity_suppression_failed'), life: 3000 })
            )
    }

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-mr-2" onClick={() => onPopupDelete()} />
            <Dialog className='dee-confirm-dialog-container' visible={confirmDeleteVisible} onHide={() => setConfirmDeleteVisible(false)} header={t('general.confirm')} accept={deleteCompteEtudiant}>
                {loading ? <div className="dip-ta-center"><ProgressSpinner /></div> : 
                    <div className='dee-confirm-message-container'>
                        <p><Trans i18nKey="student.are_you_sure_delete_student" /> {props.etudiant.prenom + " " +props.etudiant.nom} ?</p>
                        <p><Trans i18nKey="profile.lastconnexion" /> : {new Date(props.etudiant.lastConnexionDate).toLocaleDateString()}</p>
                        
                        {detailEtudiant?.entrepriseEtudiants?.length > 0 || detailEtudiant?.offreEmploiEtudiants?.length > 0 || detailEtudiant?.projetsEtudiants?.length > 0 ?
                            <p><Trans i18nKey="student.student_in" /> :</p>
                            : 
                            <p><Trans i18nKey="student.student_not_present" /></p>
                        }

                        {detailEtudiant?.entrepriseEtudiants?.length > 0 &&
                            <div className='dde-datatable-container'>
                                {/* <b className='dde-datatable-title'>Entreprises :</b> */}
                                <DataTable className='dde-datatable-popup' value={detailEtudiant?.entrepriseEtudiants} paginator rows={2}>
                                    <Column header={t("general.company")} body={(rowData) => <p>{rowData.entreprise.nom}</p>} />
                                    <Column header={t("student.added_on")} body={(rowData) => <p>{new Date(rowData.dateAjout).toLocaleDateString()}</p>} />
                                    <Column header={t("general.status")} body={(rowData) => rowData.statut ? <p>{statutOptions.find(statut => statut.value === rowData.statut).label}</p> : <p></p>} />
                                </DataTable>
                            </div>
                        }

                        {detailEtudiant?.offreEmploiEtudiants?.length > 0 &&
                            <div className='dde-datatable-container'>
                                {/* <b className='dde-datatable-title'>Offres Emploi :</b> */}
                                <DataTable className='dde-datatable-popup' value={detailEtudiant?.offreEmploiEtudiants} paginator rows={2}>
                                    <Column header={t("offers.offer")} body={(rowData) => <p>{rowData.offreEmploi.intitule}</p>} />
                                    <Column header={t("offers.last_update")} body={(rowData) => <p>{new Date(rowData.offreEmploi.dateActualisation).toLocaleDateString()}</p>} />
                                </DataTable>
                            </div>
                        }

                        {detailEtudiant?.projetEtudiants?.length > 0 &&
                            <div className='dde-datatable-container'>
                                {/* <b className='dde-datatable-title'>Projets :</b> */}
                                <DataTable className='dde-datatable-popup' value={detailEtudiant?.projetEtudiants} paginator rows={2}>
                                    <Column header={t("project.projects")} body={(rowData) => <p>{rowData.projet.nom}</p>} />
                                    <Column header={t("student.added_on")} body={(rowData) => <p>{new Date(rowData.dateAjout).toLocaleDateString()}</p>} />
                            </DataTable>
                        </div>
                        }
                        <div className='p-mt-5 dee-confirm-buttons-container'>
                            <Button label={t('general.no')} className='p-button-rounded p-button-raised primary-color-border' onClick={() => setConfirmDeleteVisible(false)} />
                            <Button label={t('general.yes')} className='p-ml-2 p-button-rounded p-button-raised primary-color-border dip-btn-red-bg' onClick={deleteCompteEtudiant} />
                        </div>
                    </div>
                }
            </Dialog>
        </React.Fragment>
    );
}