import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import FormationService from "../../services/formationService";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import './dt_demandeFormation_etudiants.css';
import { Dropdown } from 'primereact/dropdown';
import { enumDemandeFormationArchive } from "../../enums/enumDemandeFormationArchive";
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';

export const DT_DemandeFormation_Etudiant = () => {

    let emptyEtudiant = {
        id: 0,
        nom: '',
        prenom: '',
        email: '',
        ecole: [],
        nbFormations: 0
    };

    const { t } = useTranslation();
    const [etudiants, setEtudiants] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [defaultEtudiants, setDefaultEtudiants] = useState([]);
    const [formations, setFormations] = useState([]);
    const [confirmDeleteFormationEtudiantDialog, setConfirmDeleteFormationEtudiantDialog] = useState(false);
    const [deleteFormationEtudiant, setDeleteFormationEtudiant] = useState(null);
    const [etudiantDialog, setEtudiantDialog] = useState(false);
    const [validateFormationEtudiantDialog, setValidateFormationEtudiantDialog] = useState(false);
    const [dialogViewComment, setDialogViewComment] = useState(false);
    const [dialogViewCommentContent, setDialogViewCommentContent] = useState({
        id: 0,
        commentaire: null
    });
    const [loadingDialogViewCommentContent, setLoadingDialogViewCommentContent] = useState(false);
    const [globalFilterFormations, setGlobalFilterFormations] = useState(null);
    const [idFormationCliquee, setIdFormationCliquee] = useState(null);
    const [etudiant, setEtudiant] = useState(emptyEtudiant);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedOption, setSelectedOption] = useState(enumDemandeFormationArchive.NON_ARCHIVED);
    const toast = useRef(null);
    const dt = useRef(null);
    const formationService = new FormationService();
    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 25,
        page: 0,
        sortField: 'dateCreation',
        sortOrder: -1
    });
    const isMounted = useRef(false);




    useEffect(() => {
        isMounted.current = true;
        loadLazyData();
    }, [lazyParams, selectedOption]);


    const loadLazyData = () => {
        setLoading(true);

        // formationService.getDemandeFormationEtudiants().then(_etudiants => {
        formationService.getDtAdminFormationsDemandees(lazyParams, selectedOption).then(_etudiants => {
            _etudiants.items.forEach((item) => {
                item.dateCreation = new Date(item.dateCreation).toLocaleDateString();
                item.nomComplet = item.etudiant.nom + ' ' + item.etudiant.prenom;
            });
            setTotalRecords(_etudiants.totalCount);
            setEtudiants(_etudiants.items);
            setDefaultEtudiants(_etudiants);
        })
            .finally(() => setLoading(false));

        // formationService
        //     .getFormationsEtudiantProfile()
        //     .then((_formations) => {
        //         setFormations(_formations);
        //         setLoading(false);
        //     });

    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        _lazyParams['page'] = 0;
        _lazyParams['first'] = 0;
        setLazyParams(_lazyParams);
    }

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    // const confirmValidateFormationEtudiant = (e) => {
    //     setIdFormationCliquee(e.id);
    //     setValidateFormationEtudiantDialog(true);
    // }

    const validateFormationEtudiant = (e) => {

        setSubmitted(true);

        const formationId = idFormationCliquee;
        const demandeFormationId = etudiant.demandeFormationId;

        formationService.affecterFormationEtudiant(formationId, demandeFormationId).then(response => {
            let _etudiants = etudiants.filter(val => val.demandeFormationId !== demandeFormationId);
            setEtudiants(_etudiants);
            setValidateFormationEtudiantDialog(false);
            setEtudiantDialog(false);
            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('student.assigned_course'), life: 3000 });

        })

    }

    const updateViewCommentDialog = () => {
        setLoadingDialogViewCommentContent(true);
        // on met à jour le commentaire dans la base de données grace à la variable dialogViewCommentContent
        formationService.updateCommentaireDemandeFormationEtudiant(dialogViewCommentContent.id, dialogViewCommentContent.commentaire)
        .then(response => {
            // on met à jour le commentaire dans le tableau des etudiants
            let index = etudiants.findIndex(val => val.id === dialogViewCommentContent.id);
            etudiants[index].commentaire = dialogViewCommentContent.commentaire;
            // on met à jour le commentaire dans le tableau par défaut
            let indexDefault = defaultEtudiants.items.findIndex(val => val.id === dialogViewCommentContent.id);
            defaultEtudiants.items[indexDefault].commentaire = dialogViewCommentContent.commentaire;
            setDefaultEtudiants(defaultEtudiants);
            setDialogViewComment(false);
            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('student.comment_updated'), life: 3000 });
        })
        .catch(error => {
            toast.current.show({ severity: 'error', summary: t('general.error'), detail: t('student.comment_not_updated'), life: 3000 });
        })
        .finally(() => setLoadingDialogViewCommentContent(false));
    }

    const archiveFormationEtudiant = () => {
        setSubmitted(true);
        if (deleteFormationEtudiant) {
            archiverDemandeFormationEtudiant(deleteFormationEtudiant);
        }
    }

    const archiverDemandeFormationEtudiant = (e) => {
        setSubmitted(true);
        const demandeFormationId = e.id;

        if (e.isArchived === false || e.isArchived === null) {
            formationService.archiverDemandeFormationEtudiant(demandeFormationId).then(response => {
                // on recupere l'index de l'etudiant dans le tableau etudiants et on modifie son attribut isArchived
                let index = etudiants.findIndex(val => val.id === demandeFormationId);
                etudiants[index].isArchived = true;
                // on recupere le tableau des etudiants non archivés
                let _etudiants = [...etudiants].filter(val => val.id !== demandeFormationId);

                setEtudiants(_etudiants);
                // setEtudiantDialog(false);
                setConfirmDeleteFormationEtudiantDialog(false);
                setTotalRecords(totalRecords - 1);
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('student.archived_course'), life: 3000 });
            })
        } else {
            formationService.desarchiverDemandeFormationEtudiant(demandeFormationId).then(response => {
                // on recupere l'index de l'etudiant dans le tableau etudiants et on modifie son attribut isArchived
                let index = etudiants.findIndex(val => val.id === demandeFormationId);
                etudiants[index].isArchived = false;
                // on recupere le tableau des etudiants non archivés
                let _etudiants = [...etudiants].filter(val => val.id !== demandeFormationId);

                setEtudiants(_etudiants);
                // setEtudiantDialog(false);
                setConfirmDeleteFormationEtudiantDialog(false);
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('student.unarchived_course'), life: 3000 });
            })
        }

    }

    // const hideDialog = () => {
    //     setSubmitted(false);
    //     setEtudiantDialog(false);
    // }

    const hideValidateFormationEtudiantDialog = () => {
        setValidateFormationEtudiantDialog(false);
    }

    const hideConfirmDeleteFormationEtudiantDialog = () => {
        setConfirmDeleteFormationEtudiantDialog(false);
    }

    const hideViewCommentDialog = () => {
        setDialogViewComment(false);
    }

    // const validateEtudiant = (etudiant) => {
    //     setEtudiant({ ...etudiant });
    //     setEtudiantDialog(true);
    // }

    const lienProfilEtudiant = (demande) => {
        localStorage.setItem("adminSeeProfileEtudes", true);
        localStorage.setItem("adminImpersonatingEtudiantId", demande.etudiantId);

        var win = window.open("profile_student", "_blankProfilEtudiant" + etudiant.id)
        win.focus();
    }

    const handleClickDialogViewComment = (demande) => {
        setDialogViewCommentContent({
            id: demande.id,
            commentaire: demande.commentaire
        });
        setDialogViewComment(true);
    }

    const handleClickConfirmDeleteFormationEtudiant = (e) => {
        setDeleteFormationEtudiant(e);
        setConfirmDeleteFormationEtudiantDialog(true);
    }

    const truncateComment = (comment, maxLength = 100) => {
        if (!comment) return 'Aucun commentaire';
            return comment.length > maxLength ? comment.substring(0, maxLength) + '...' : comment;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => validateEtudiant(rowData)} /> */}
                <Tooltip target=".comment-tooltip" showDelay={500} position="left" className='dt-df-e-custom-tooltip' />
                <Button icon="pi pi-comment" className="comment-tooltip p-button-rounded p-button-info p-mr-1" onClick={() => handleClickDialogViewComment(rowData)} data-pr-tooltip={truncateComment(rowData.commentaire)} />
                <Button icon="pi pi-user-edit" className="p-button-rounded p-button-help p-mr-1" onClick={() => lienProfilEtudiant(rowData)} />
                <Button icon={rowData.isArchived === false || rowData.isArchived === null ? "pi pi-trash" : "pi pi-undo"} className={`p-button-rounded ${rowData.isArchived === false || rowData.isArchived === null ? 'p-button-danger' : 'p-button-warning'}`} onClick={() => handleClickConfirmDeleteFormationEtudiant(rowData)} />               
            </React.Fragment>
        );
    }

    const options = [
        { label: t('student.non_archived'), value: enumDemandeFormationArchive.NON_ARCHIVED },
        { label: t('student.archived'), value: enumDemandeFormationArchive.ARCHIVED },
        { label: t('skills.all'), value: enumDemandeFormationArchive.ALL }
    ];

    const handleChangeArchive = (e) => {
        if (e.value === enumDemandeFormationArchive.NON_ARCHIVED)
            setSelectedOption(enumDemandeFormationArchive.NON_ARCHIVED);
        else if (e.value === enumDemandeFormationArchive.ARCHIVED)
            setSelectedOption(enumDemandeFormationArchive.ARCHIVED);
        else if (e.value === enumDemandeFormationArchive.ALL)
            setSelectedOption(enumDemandeFormationArchive.ALL);
        else
            setSelectedOption(enumDemandeFormationArchive.NON_ARCHIVED);
    }

    const header = (
        <div className="table-header">
            <h5 className="title"><Trans i18nKey="student.coursesRequests_students_management.full" /></h5>
            <Dropdown options={options} value={selectedOption} onChange={(e) => handleChangeArchive(e)} placeholder="archive" />
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
            </span>
        </div>
    );
    const etudiantDialogFooter = (
        <React.Fragment>
            {/*<Button label={t('general.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={t('general.save')} icon="pi pi-check" className="p-button-text" onClick={saveEtudiant} />*/}
        </React.Fragment>
    );
    const actionViewCommentDialogFooter = (
        <React.Fragment>
            <Button label={t('general.close')} icon="pi pi-times" className="p-button-text" onClick={hideViewCommentDialog} />
            <Button label={t('general.save')} icon="pi pi-check" className="p-button-text" onClick={updateViewCommentDialog} />
        </React.Fragment>
    );
    const validateFormationEtudiantDialogFooter = (
        <React.Fragment>
            <Button label={t('general.no')} icon="pi pi-times" className="p-button-text" onClick={hideValidateFormationEtudiantDialog} />
            <Button label={t('general.yes')} icon="pi pi-check" className="p-button-text" onClick={validateFormationEtudiant} />
        </React.Fragment>
    );
    const deleteFormationEtudiantDialogFooter = (
        <React.Fragment>
            <Button label={t('general.no')} icon="pi pi-times" className="p-button-text" onClick={hideConfirmDeleteFormationEtudiantDialog} />
            <Button label={t('general.yes')} icon="pi pi-check" className="p-button-text" onClick={archiveFormationEtudiant} />
        </React.Fragment>
    );

    // const searchbarFormations = (
    //     <div>
    //         <span className="p-input-icon-left responsive">
    //             <i className="pi pi-search" />
    //             <InputText type="search" onInput={(e) => setGlobalFilterFormations(e.target.value)} placeholder={t('general.search')} />
    //         </span>
    //     </div>
    // )

    // const actionBodyTemplateFormations = (rowData) => {
    //     return (
    //         <Button icon="pi pi-check" className="p-button-rounded p-button-warning align-right" onClick={() => confirmValidateFormationEtudiant(rowData)} />
    //     );
    // }

    return (
        <div className="datatable-demandeFormation_etudiants">
            <Toast ref={toast} />

            <div className="card">
                <DataTable ref={dt} emptyMessage={t('student.no_requests_to_show')} filterDelay={1000} value={etudiants}
                    totalRecords={totalRecords} onPage={onPage}
                    onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                    onFilter={onFilter} filters={lazyParams.filters} loading={loading}
                    dataKey="id" paginator first={lazyParams.first} rows={lazyParams.rows} 
                    rowsPerPageOptions={[25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={t('demande.footer_count_pagin')}
                    globalFilter={globalFilter}
                    header={header}
                    lazy
                    columnResizeMode="expand"
                    resizableColumns
                    responsiveLayout="auto"
                    autoLayout
                >
                    <Column field="nomComplet" header={t('general.student')} sortable></Column>
                    <Column field="diplomeDemandee" header={t('student.diploma')} headerStyle={{ width: '10rem' }} sortable></Column>
                    <Column field="formationDemandee" header={t('student.requested_course')} headerStyle={{ width: '20rem' }} sortable></Column>
                    <Column field="ecoleDemandee" header={t('student.requested_school')} sortable></Column>
                    <Column field="compteFormationsSimilaire" header={t('student.submitted_number')} headerStyle={{ width: '6rem' }} sortable></Column>
                    <Column field="dateCreation" header={t('student.request_date')} headerStyle={{ width: '9rem' }} sortable></Column>
                    {/* <Column field="formationEnCours" header={t('profile.formationInProgress')} sortable></Column>
                    <Column field="anneeDemandee" header={t('profile.obtained_year')} sortable></Column> */}
                    <Column body={actionBodyTemplate} headerStyle={{ width: '10rem' }}></Column>
                </DataTable>
            </div>

            {/* <Dialog visible={etudiantDialog} style={{ width: '80rem' }} header={t('student.request_assignment')} modal className="p-fluid" footer={etudiantDialogFooter} onHide={hideDialog}>
                <div className="p-fluid p-formgrid p-grid p-col-12">
                    <label className="p-mb-3" style={{ fontWeight: '500' }}><Trans i18nKey="formation.Formations" /></label>
                    <div className="p-field">
                        <DataTable value={formations}
                            emptyMessage={t('formation.no_formation_to_show')} loading={loading}
                            dataKey="id"
                            globalFilter={globalFilterFormations}
                            paginator rows={5} rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={t('formation.footer_count_pagin')}
                        >
                            <Column field="nomPourEtudiant" header={t('profile.formation.singular')} sortable></Column>
                            <Column field="composantesFormation" header={t('general.component')} sortable></Column>
                            <Column field="ecoleFormation" header={t('profile.school')} sortable></Column>
                            <Column header={searchbarFormations} headerStyle={{ width: '15rem' }} body={actionBodyTemplateFormations}></Column>
                        </DataTable>
                    </div>
                </div>
            </Dialog> */}
            
            <Dialog visible={dialogViewComment} style={{ width: '80rem' }} header={t('student.comment')} modal footer={actionViewCommentDialogFooter} onHide={hideViewCommentDialog}>
                <div className="confirmation-content">
                    { loadingDialogViewCommentContent ? <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" /> :
                    <div className="p-mb-3 p-fluid p-formgrid p-grid">
                        <InputTextarea 
                            id='commentaire'
                            value={dialogViewCommentContent.commentaire}
                            rows={10}
                            autoResize
                            onChange={(e) => setDialogViewCommentContent({ ...dialogViewCommentContent, commentaire: e.target.value })}
                        />
                    </div>
                    }
                </div>
            </Dialog>

            <Dialog visible={validateFormationEtudiantDialog} style={{ width: '50rem' }} header={t('general.confirm')} modal footer={validateFormationEtudiantDialogFooter} onHide={hideValidateFormationEtudiantDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {etudiant && <span><Trans i18nKey="student.are_you_sure_validate_affect_course_student" /><b>{etudiant.email}</b><Trans i18nKey="general.question_symbol" /></span>}
                </div>
            </Dialog>

            <Dialog visible={confirmDeleteFormationEtudiantDialog} style={{ width: '50rem' }} header={t('general.confirm')} modal footer={deleteFormationEtudiantDialogFooter} onHide={hideConfirmDeleteFormationEtudiantDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {deleteFormationEtudiant && <span>{t('student.confirmation_archive_request')}</span>}
                </div>
            </Dialog>
        </div>
    );
}
