import { Card } from "primereact/card"
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog"
import { InputSwitch } from "primereact/inputswitch"
import { useContext, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import parse from 'html-react-parser';
import "./entrepriseCandidatThumbnail.css"
import OffreEmploiService from "../../services/offreEmploiService"
import RechercheEntrepriseCandidats from "../../pages/entreprise/RechercheEntrepriseCandidats"
import { LieuResidenceFormater } from "../formater/lieuResidenceFormater"
import { DisplayProfileImage } from "../image/displayProfileImage"
import { Avatar } from "primereact/avatar"
import DocumentService from "../../services/documentService"
import { Button } from "primereact/button"
import Helper from "../../utils/helper"
import { CandidatStatutDropdown } from "../dropdown/candidatStatutDropdown";
import { Dialog } from "primereact/dialog"
import { Wysiwyg } from "../wysiwyg/wysiwyg"
import EntrepriseService from "../../services/entrepriseService"
import { useAuthState } from "../../context/context"
import RoleHelper from "../../utils/roleHelper"
import { ProjetOverlayPanel } from "../overlayPanel/projetOverlayPanel"
import { LikeEtudiant } from "../like/likeEtudiant"

export const EntrepriseCandidatThumbnail = (props) => {

    const { t } = useTranslation();
    const currentUser = useAuthState();
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [notesDialog, setNotesDialog] = useState(false)
    const [wysiwygContent, setWysiwygContent] = useState(props.candidat.notes)
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    const offreEmploiService = new OffreEmploiService();
    const documentService = new DocumentService();
    const entrepriseService = new EntrepriseService();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;

    const contextRecherche = useContext(RechercheEntrepriseCandidats);

    const onDeleteClick = () => {
        confirmDialog({
            message: 'Toutes les informations renseignées liées à ce candidat seront perdues.',
            header: 'Vous souhaitez supprimer ce candidat ?',
            rejctClassName: "dip-btn-red-outline",
            acceptClassName: 'dip-btn-red-bg',
            accept: () => {
                let httpPromise = props.candidat.offreEmploiId ?
                    offreEmploiService.deleteOffreEmploiEtudiant(props.candidat.offreEmploiId, props.candidat.etudiantId)
                    :
                    entrepriseService.unlike(props.candidat.etudiantId);
                httpPromise
                    .then(
                        (data) => {
                            let _lazyParams = { ...contextRecherche.lazyParams };
                            _lazyParams.first = 0;
                            _lazyParams.page = 0;
                            contextRecherche.setLazyParams(_lazyParams);
                            contextRecherche.rechercheApi(_lazyParams);
                        })
                    .finally(
                        () => {
                            setLoading(false)
                        })
            },
            reject: () => { },
            acceptLabel: "Supprimer",
            rejectLabel: "Annuler"
        });
    }

    const handleChangeStatut = (e) => {
        let _candidat = {}
        let httpPromise;
        _candidat.etudiantId = props.candidat.etudiantId
        _candidat.statut = e.value
        _candidat.notes = props.candidat.notes
        if (props.candidat.offreEmploiId) {
            _candidat.offreEmploiId = props.candidat.offreEmploiId
            httpPromise = offreEmploiService.updateOffreEmploiEtudiant(_candidat)
        }
        else {
            httpPromise = Promise.resolve();
            httpPromise = httpPromise
                .then(() => entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId))
                .then((id) => {
                    _candidat.entrepriseId = id;
                    return entrepriseService.updateEntrepriseEtudiant(_candidat);
                });
        }
        httpPromise
            .then((data) => {
                let _lazyParams = { ...contextRecherche.lazyParams };
                _lazyParams.first = 0;
                _lazyParams.page = 0;
                contextRecherche.setLazyParams(_lazyParams);
                contextRecherche.rechercheApi(_lazyParams);
                if (props.onStatutChange) {
                    props.onStatutChange(e.value)
                }
            })
            .finally(
                () => {
                    setLoading(false)
                })
    }

    const onShowNotes = () => {
        setLoading(true)
        entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then(id => {
            entrepriseService.getNotes(props.candidat.etudiantId, id)
                .then(notes => {
                    setWysiwygContent(notes)
                    setNotesDialog(true)
                })
                .catch(

            )
                .finally(
                    () => { setLoading(false) }
                )
        })
    }

    const onWysiwygChange = (content, editor) => {
        setWysiwygContent(content);
    }

    const onWysiwygSave = () => {
        setLoading(true);
        entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then(id => {
            entrepriseService.postNotes(props.candidat.etudiantId, wysiwygContent, id)
                .then((data) => {
                    setNotesDialog(false)
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }

    const showCVPdf = () => {
        documentService.TelechargerDocumentCandidat(props.candidat.etudiant.fichierCvPdf.id).then(blob => {
            Helper.openWindowBlobFile(blob, props.candidat.etudiant.fichierCvPdf.name);
        });
    }

    const showPortfolio = () => {
        documentService.TelechargerDocumentCandidat(props.candidat.etudiant.fichierPortfolio.id).then(blob => {
            Helper.openWindowBlobFile(blob, props.candidat.etudiant.fichierPortfolio.name);
        });
    }

    const onCandidatClick = () => {
        if (props.candidat.offreEmploiId) {
            history.push({ pathname: '/Candidat/' + props.candidat.etudiant.urlCode + "/" + props.candidat.etudiant.prenom + "-" + props.candidat.etudiant.nom, state: { etudiantId: props.candidat.etudiantId, offreEmploiId: props.candidat.offreEmploiId, offreEmploiIntitule: props.candidat.offreEmploi?.intitule } })
        }
        else if (props.candidat.entrepriseId) {
            history.push({ pathname: '/Candidat/' + props.candidat.etudiant.urlCode + "/" + props.candidat.etudiant.prenom + "-" + props.candidat.etudiant.nom, state: { etudiantId: props.candidat.etudiantId, entrepriseId: props.candidat.entrepriseId, offreEmploiIntitule: props.candidat.offreEmploi?.intitule, adminImpersonatingRecruteurId: adminImpersonatingRecruteurId ? adminImpersonatingRecruteurId : null } })
        }

    }


    return (
        <>
            <div className={`ect-main-card p-mb-3 ${props.isInAList && "ect-border"} `}>
                <div className="dip-flex">
                    <div className="etudiant-thumbnail-profile-image dip-ai-center">
                        {props.candidat.etudiant.fichierPhoto && <DisplayProfileImage imageId={props.candidat.etudiant.fichierPhoto.id} />}
                        {!props.candidat.etudiant.fichierPhoto && <Avatar icon="pi pi-user" shape="circle" />}
                    </div>
                    <div className="dip-flex dip-jc-between dip-w-100">
                        <div>
                            <div className="ect-top-line dip-jc-between">
                                <div><i><Trans i18nKey="recruitment.contacted_on" values={{ date: props.candidat.dateAjout }} /></i></div>

                            </div>
                            <div className={props.isInAList && "dip-pointer"} onClick={props.isInAList && onCandidatClick}>
                                <div className="dip-color-tc p-mt-2 p-mb-3">
                                    <h2 className="dip-m-0">{props.candidat.etudiant.prenom} {props.candidat.etudiant.nom}</h2>
                                </div>
                                {props.candidat.etudiant.niveauEtude &&
                                    <h3 className="dip-m-0">
                                        <span><Trans i18nKey={"label.studie_level"} />{props.candidat.etudiant.niveauEtude}</span>
                                    </h3>
                                }
                                <div>
                                    <LieuResidenceFormater etudiant={props.candidat.etudiant} />
                                </div>
                                <p className="ect-description-container">
                                    {props.candidat.etudiant.presentation && parse(props.candidat.etudiant.presentation)}
                                </p >
                                <div>{props.candidat.etudiant.rechercheActiveCalendrier && <i><Trans i18nKey="profile.available_until" /> {props.candidat.etudiant.rechercheActiveCalendrier}</i>}</div>
                            </div>
                        </div>
                        <div className="dip-ta-right dip-flex-column dip-jc-between">
                            <div>
                                <div className="dip-flex-row dip-ai-center dip-jc-end">
                                    {RoleHelper.isEntreprise(currentUser) && <div className="p-mr-2"><ProjetOverlayPanel etudiantId={props.candidat.etudiant.id} refreshPage={props.loadOffreEmploi} lazyParams={props.lazyParams} /></div>}
                                    {props.isInAList && <div className="p-mr-2 ect-pointer" onClick={() => onShowNotes(true)}><i className="pi pi-file" style={{ color: 'var(--terra-cotta)', fontSize: '1.3rem' }} /></div>}
                                    {props.candidat.statut == 6 && <div className="ect-pointer" onClick={onDeleteClick}><i className="pi pi-trash" style={{ color: 'var(--terra-cotta)', fontSize: '1.3rem' }} /></div>}
                                    {<LikeEtudiant etudiant={props.candidat.etudiant} toast={props.toast} loadOffreEmploi={props.loadOffreEmploi} fontSize="1.5rem" />}
                                </div>
                                <div className="p-mt-1">
                                    {props.candidat.etudiant.fichierCvPdf?.dateValidation && <Button label={t('student.cv')} icon="pi pi-file-pdf" className="etudiant-thumbnail-button" onClick={showCVPdf} />}
                                </div>
                                <div className="p-mt-1">
                                    {props.candidat.etudiant.fichierPortfolio?.dateValidation && <Button label={t('student.portfolio')} icon="pi pi-file-pdf" className="etudiant-thumbnail-button" onClick={showPortfolio} />}
                                </div>
                            </div>
                            <div>
                                <CandidatStatutDropdown value={props.candidat.statut} onChange={(e) => handleChangeStatut(e)} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Dialog header="Notes" className="ect-confirm-dialog" visible={notesDialog} style={{ width: '50vw' }} onHide={() => { setNotesDialog(false); }} >
                <Wysiwyg value={wysiwygContent} onChange={onWysiwygChange} />
                <div className="dip-ta-center p-mt-2">
                    <Button
                        label={"Enregistrer"}
                        className={`offset-md-3 listelibelle-thumbnail-button-padding dip-btn-red-bg`}
                        onClick={onWysiwygSave}
                    />
                </div>
            </Dialog>
            <ConfirmDialog className="ect-confirm-dialog" visible={deleteDialog} style={{ width: '50vw' }} onHide={() => setDeleteDialog(false)} rejectLabel="Annuler" acceptLabel="Supprimer">
            </ConfirmDialog>
        </>
    )
}