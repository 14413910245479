import React, { useEffect, useRef } from 'react';
import { Avatar } from 'primereact/avatar';
import { useHistory } from 'react-router-dom';
import { useAuthDispatch, useAuthState } from '../../../context/context';
import { logout } from '../../../context/actions';
import { Menu } from 'primereact/menu';
import './headerAuthProfile.css'
import '../../menu/menuSmallPopUp.css'
import { useTranslation } from 'react-i18next';
import { DisplayProfileImage } from '../../image/displayProfileImage';
import RoleHelper from '../../../utils/roleHelper';

export const HeaderAuthProfile = (props) => {
    const { t } = useTranslation();
    const dispatch = useAuthDispatch();
    const currentUser = useAuthState();
    const history = useHistory();
    const menu = useRef(null);

    useEffect(() => {
    }, [props.nom, props.prenom])

    const handleLogout = () => {
        logout(dispatch)
        if(RoleHelper.isEntreprise(currentUser))
        {
            history.push('/entreprise')
        }
        else{
            history.push('/')
        }
        
    }

    let itemsEntrepriseEtudiant = [
        {
            label:
                RoleHelper.isEtudiant(currentUser) && t('general.myProfile.full') ||
                RoleHelper.isEntreprise(currentUser) && t('general.myAccount.full') ||
                t('general.dashboard')
            ,
            command: () => {
                if (RoleHelper.isEtudiant(currentUser))
                    history.push("/profile_student");
                else if (RoleHelper.isEntreprise(currentUser))
                    history.push("/profile_company");
                else
                    history.push("/dashboard");
            }
        }
        //CHAT-COMMENTE
        // ,
        // {
        //     label: t('general.messagerie'),
        //     command: () => { history.push("/Messagerie") }
        // }
        ,{
            label: t('general.disconnect'),
            command: () => handleLogout()
        }
    ];

    let itemsOther = [
        {
            label: t('general.dashboard'),
            command: () => {
                history.push("/dashboard");
            }
        },
        {
            label: t('general.disconnect'),
            command: () => handleLogout()
        }
    ];

    let items = RoleHelper.isEtudiant(currentUser) || RoleHelper.isEntreprise(currentUser) ? itemsEntrepriseEtudiant : itemsOther;

    return (
        <div className={props.className}>
            <Menu className="menu-small-pop-up" model={items} popup ref={menu}>
            </Menu>
            <div className={`profile-header-style header-img-profil-etudiant-${props.theme ? props.theme : "iris"}`}>
                {currentUser.user.avatarId ? (<DisplayProfileImage imageId={currentUser.user.avatarId} onClick={(e) => menu.current.toggle(e)} />) : (<Avatar icon="pi pi-user" size="large" shape="circle" onClick={(e) => menu.current.toggle(e)}></Avatar>)}
            </div>
        </div>
    );

}