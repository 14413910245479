import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { SpeedDial } from "primereact/speeddial";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { enumCompetenceType } from "../../enums/enumCompetenceType";
import { enumHttpError } from "../../enums/enumHttpError";
import { enumTypeCompetence } from "../../enums/enumTypeCompetence";
import CompetenceService from "../../services/competenceService";
import Helper from "../../utils/helper";
import { DragNDrop } from "../dragdrop/dragNDrop";
import axios from "axios"
import RoleHelper from "../../utils/roleHelper";
import { useAuthState } from "../../context/context";

export const PickListCompetencesSavoirFaire = (props) => {

    const { t } = useTranslation();

    const toast = useRef(null);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let cancel;

    const currentUser = useAuthState();
    const [competencesSavoirFaire, setCompetencesSavoirFaire] = useState(null)
    const [loading, setLoading] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const [refreshToken, setRefreshToken] = useState(new Date())
    const [filterTimerId, setFilterTimerId] = useState(null)

    const competenceService = new CompetenceService();

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: 'libelle',
        sortOrder: 1
    });

    useEffect(() => {
        setLoading(true);

        if (cancel) {
            source.cancel();
        }
        cancel = source.token;

        competenceService.getPaginated(lazyParams, enumTypeCompetence.SAVOIRFAIRE, { cancelToken: cancel })
            .then(data => {
                setTotalRecords(data.totalCount);
                setCompetencesSavoirFaire(data.items);
                setLoading(false);
            })
            .catch(err => {
                if (err.message != enumHttpError.CANCELED) {
                    showError();
                    setLoading(false);
                }
            })
            return () => source.cancel();

    }, [lazyParams, refreshToken])

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        if (filterTimerId) {
            clearTimeout(filterTimerId);
        }
        var _filterTimerId = setTimeout(() => {
            let _lazyParams = { ...lazyParams, ...event };
            _lazyParams['page'] = 0;
            _lazyParams['first'] = 0;
            setLazyParams(_lazyParams);
            setFilterTimerId(null);
        }, 1000)
        setFilterTimerId(_filterTimerId);
    }

    const classeObjetDejaAjoute = "p-button-rounded p-button-secondary align-right";
    const classeObjetPourAjouter = "p-button-rounded p-button-info align-right";

    const itemsAddCompetenceSavoirFaire = [
        {
            label: t('formation.add_competence'),
            icon: 'pi pi-plus',
            command: (event) => {
                addCompetence("SavoirFaire");
            }
        },
    ]

    const searchbarCompetencesSavoirFaire = (
        <div>
            <label>{t('skills.skills')} ({t('skills.expertise')})</label>
            <span className="p-input-icon-left responsive">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => props.setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
            </span>
        </div>
    )
    const ajoutCompetencesSavoirFaire = (
        (RoleHelper.isAdmin(currentUser) || RoleHelper.isSuperAdmin(currentUser)) && <div className="ajout-picklist-item">
            <Tooltip target=".speeddial-right .p-speeddial-action" position="top" />
            <SpeedDial className="speeddial-right" disabled={Helper.isEmptyOrSpaces(props.globalFilter)} model={itemsAddCompetenceSavoirFaire} id="ajoutCompetenceSavoirFaire" showIcon="pi pi-ellipsis-v" hideIcon="pi pi-ellipsis-h" direction="up" buttonClassName="p-button-warning" />
        </div>
    )



    const addCompetence = (typeCompetence) => {
        let _competence = { libelle: props.globalFilter, typeCompetence: typeCompetence };
        competenceService
            .addCompetence(_competence)
            .then((_competence) => {
                setRefreshToken(new Date());
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('formation.added_competence'), life: 3000 });
            })
            .catch((err) => {
                switch (err.message) {
                    case enumHttpError.SKILLALREADYEXISTS:
                        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('formation.competence_already_added_sameLabel'), life: 3000 });
                        break;
                    default:
                        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
                        break;
                }
            })
            .finally(() => {

            })
    }

    const pickListCompSavoirFaireActionBodyTemplate = (rowData) => {
        const bDejaAjoute = props.isCompetenceAlreadyInAnOtherList(rowData);
        return (
            <Button icon="pi pi-arrow-right" className={bDejaAjoute ? classeObjetDejaAjoute : classeObjetPourAjouter} onClick={() => props.onCompSavoirFaireAdd(rowData, 0)} />
        );
    }

    return (
        <div style={props.show === true ? ({ display: 'block' }) : ({ display: 'none' })}>
            <Toast ref={toast} />
            <div className="p-grid">
                <div className="p-col">
                    <DataTable value={competencesSavoirFaire}
                        lazy
                        filterDelay={1000}
                        emptyMessage={t('skills.empty')}
                        loading={loading}
                        dataKey="id"
                        globalFilter={props.globalFilter}
                        paginator first={lazyParams.first} rows={lazyParams.rows} rowsPerPageOptions={[5, 10, 25]}
                        totalRecords={totalRecords}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={t('skills.footer_count_pagin')}
                        onPage={onPage}
                        onFilter={onFilter}
                    >
                        <Column field="libelle" header={searchbarCompetencesSavoirFaire} headerStyle={{ width: '15rem' }} sortable></Column>
                        <Column headerStyle={{ width: '1rem' }} header={ajoutCompetencesSavoirFaire} body={pickListCompSavoirFaireActionBodyTemplate}></Column>
                    </DataTable>
                </div>
                <div className="p-col">
                    <div>
                        <h2>{props.competenceType == enumCompetenceType.DEBASE && <Trans i18nKey="formation.base_skills" /> || props.competenceType == enumCompetenceType.SPECIFIQUE && <Trans i18nKey="formation.specific_skills" />} (<Trans i18nKey="skills.expertise" />)</h2>
                        <DragNDrop list={props.selectedCompSavoirFaire} setList={props.setSelectedCompSavoirFaire}></DragNDrop>
                    </div>
                </div>
            </div>
        </div>
    )
}