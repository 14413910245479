import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const DurationFormater = (props) => {

    const { t } = useTranslation();
    const [contrat, setContrat] = useState(null);
    const [dureeValeur, setDureeValeur] = useState(null);
    const [dureeMesure, setDureeMesure] = useState(null);
    const [indefiniteDuration, setIndefiniteDuration] = useState(null);

    useEffect(() => {
        if (props.offreEmploi?.providerId == 1) {
            setDureeMesure('Mois')
            setDureeValeur(props.offreEmploi?.dureeContrat)
            switch (props.offreEmploi?.natureContrat?.code) {
                case 'E1':
                    if(props.offreEmploi?.typeContrat?.code == "CDI"){
                        setIndefiniteDuration(true)
                    }
                    else if (props.offreEmploi?.typeContrat?.code == "CDD"){
                        setIndefiniteDuration(false)
                    }
                    setContrat(props.offreEmploi?.typeContrat?.libelle)
                    break;
                case 'E2':case 'FS':
                    if(props.offreEmploi?.dureeContrat){
                        setIndefiniteDuration(false)
                    }
                    else{
                        setIndefiniteDuration(true)
                    }
                    break;
                case 'ST':
                    setContrat(props.offreEmploi?.natureContrat.libelle)
                    setIndefiniteDuration(false)
                    break;
                default:
                    setContrat("Contrat inconnu");
                    setIndefiniteDuration(true);
                    break;
            }
        }
        else {
            let _splittedInfo = props.offreEmploi?.typeContratLibelle.split(' - ');
            if (_splittedInfo !== undefined && _splittedInfo !== null) {
                setContrat(_splittedInfo[0]);
                if (_splittedInfo.length == 1) {
                    setIndefiniteDuration(true);
                } else if (_splittedInfo.length == 2) {
                    setIndefiniteDuration(false);
                    let _durationInfo = _splittedInfo[1].split(' ');
                    setDureeValeur(_durationInfo[0]);
                    if (_durationInfo.length > 1) {
                        setDureeMesure(_durationInfo[1]);
                    }
                }
            }
        }
    }, [props.offreEmploi])

    return(
        <>
            <div className="oe-text p-mb-2">{indefiniteDuration ? '' : <><i className="pi pi-calendar p-mr-2" />{dureeValeur + ' ' + dureeMesure}</>}</div>
        </>
    )
}