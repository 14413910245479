import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import logoWhite from '../../logo.webp';
import dipLogoEntrepriseTC from '../../images/dipLogo/dipLogoEntrepriseTC.webp'
import dipLogoEntrepriseW from '../../images/dipLogo/dipLogoEntrepriseW.webp'
import { NewHeaderAccessButtons } from './newHeaderAccessButtons';
import { HeaderAuth } from './headerAuth';
import { useTranslation } from 'react-i18next';
import { enumHeaderTheme } from "../../enums/enumHeaderTheme";
import { useAuthState } from '../../context/context';
import RoleHelper from '../../utils/roleHelper';
import { enumRoles } from '../../enums/enumRoles';
import "./header.css"
import { useEffect } from 'react';

export const Header = (props) => {
    const currentUser = useAuthState()
    const history = useHistory();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;

    const { t } = useTranslation();

    useEffect(() => {
        
    }, [history.location.pathname])
    

    return (
        <div className={(`header-main-style-${history.location.pathname.toLowerCase() == "/register?user=company" || (history.location.pathname == "/register" && history.location.search.toLowerCase() == "?user=company") ? 'blue' : ((RoleHelper.isEntreprise(currentUser) || (adminImpersonatingRecruteurId && RoleHelper.isAdminImpersonatingEntreprise(currentUser,history.location.pathname))) ? 'white' : (props.theme ? props.theme : "iris"))}`)}>
            <div className={`header-main-container ${props.className}`}>
                <nav className={`h-navbar`}>
                    <NewHeaderAccessButtons />
                    <div className='h-container-logo-link-compte'>
                        <div className="navbar__logo">
                            <NavLink activeClassName=""
                                to={RoleHelper.isEntreprise(currentUser) ? (
                                    '/offers'
                                ) : (
                                    history.location.pathname == "/entreprise" || (history.location.pathname.toLowerCase() == "/register?user=company" || (history.location.pathname == "/register" && history.location.search.toLowerCase() == "?user=company"))  ? "/entreprise" : "/"
                                )}
                            >
                                <img src={props.theme == enumHeaderTheme.BLUE ? (dipLogoEntrepriseW) : (RoleHelper.isEntreprise(currentUser) || (adminImpersonatingRecruteurId && RoleHelper.isAdminImpersonatingEntreprise(currentUser,history.location.pathname)) ? dipLogoEntrepriseTC : logoWhite)} className="header_logo" alt={"Logo DiplomAdvisor en toutes lettres"} />
                            </NavLink>
                        </div>
                        <div className={`h-container-link-compte h-link-compte-connect-${props.theme == enumHeaderTheme.BLUE && RoleHelper.isEntreprise(currentUser) ? 'blue' : (RoleHelper.isEntreprise(currentUser) || (adminImpersonatingRecruteurId && RoleHelper.isAdminImpersonatingEntreprise(currentUser,history.location.pathname)) ? 'white' : (props.theme ? props.theme : "iris"))}`}>
                            <HeaderAuth className="auth-style" showOnboarding={props.showOnboarding} theme={RoleHelper.isEntreprise(currentUser) ||(adminImpersonatingRecruteurId && RoleHelper.isAdminImpersonatingEntreprise(currentUser,history.location.pathname)) ? 'white' : props.theme} userType={history.location.pathname == "/entreprise" ? enumRoles.ENTREPRISE : enumRoles.ETUDIANT} />
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

