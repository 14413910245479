import BaseService from './baseService';


export default class RecrutementService extends BaseService {

    createOrUpdateRecrutement(recrutement) {
        const jsonRecrutemennt = JSON.stringify(recrutement, null, 2);
        return this.axiosApiInstance.post(window.API_URL + '/api/recrutement/Recrutement', jsonRecrutemennt)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }


    getRecrutementEnCours() {
        return this.axiosApiInstance.get(window.API_URL + '/api/recrutement/GetRecrutementEnCours')
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }
    getRecrutementArchives() {
        return this.axiosApiInstance.get(window.API_URL + '/api/recrutement/GetRecrutementArchives')
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    archiver(idRecrutement) {
        return this.axiosApiInstance.post(window.API_URL + '/api/recrutement/archiver/' + idRecrutement)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    reactiver(idRecrutement) {
        return this.axiosApiInstance.post(window.API_URL + '/api/recrutement/reactiver/' + idRecrutement)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getCandidatsRecrutement(idRecrutement, status) {
        return this.axiosApiInstance.get(window.API_URL + '/api/recrutement/GetCandidatsRecrutement/' + idRecrutement + '?candidatStatus=' + status)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getPagedCandidatsRecrutement(idRecrutement, lazyParams, status) {
        const params = super.getAPIPagedQuery(lazyParams);
        let recherche = {}
        recherche.status = status;
        const interm = { ...params, ...recherche }
        const queryParams = super.getQueryParams(interm)

        return this.axiosApiInstance.get(window.API_URL + `/api/recrutement/GetPagedCandidatsRecrutement/${idRecrutement}?` + queryParams)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getRecrutement(idRecrutement) {
        return this.axiosApiInstance.get(window.API_URL + '/api/recrutement/GetRecrutement/' + idRecrutement)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }


    setCandidatsRecrutementStatus(recruitmentId, studentId, studentStatus) {
        return this.axiosApiInstance.post(window.API_URL + '/api/recrutement/SetCandidatsRecrutementStatus/?recrutementId=' + recruitmentId + '&candidatId=' + studentId + '&candidatStatus=' + studentStatus)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    setCandidatsRecrutementStatus(recruitmentId, studentId, studentStatus) {
        return this.axiosApiInstance.post(window.API_URL + '/api/recrutement/SetCandidatsRecrutementStatus/?recrutementId=' + recruitmentId + '&candidatId=' + studentId + '&candidatStatus=' + studentStatus)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    setCandidatsRecrutementNotes(recruitmentId, studentId, notes) {
        return this.axiosApiInstance.post(window.API_URL + '/api/recrutement/SetCandidatsRecrutementNotes/?recrutementId=' + recruitmentId + '&candidatId=' + studentId + '&notes=' + notes)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    deleteRecrutementEtudiant(recrutementId,etudiantId) {
        return this.axiosApiInstance.delete(window.API_URL + '/api/recrutement/DeleteRecrutementEtudiant/' + recrutementId + '/' + etudiantId)
          .then(res => res.data)
          .catch(err => super.handleHttpError(err));
      }
    setRecrutementsRechercheFilters(recrutements,filters) {
        const jsonRecrutementsAndFilters = JSON.stringify({recrutements,filters});
        return this.axiosApiInstance.post(window.API_URL + `/api/recrutement/SetRecrutementsRechercheFilters`, jsonRecrutementsAndFilters)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getAllTypeContratRecrutement() {
        return this.axiosApiInstance.get(window.API_URL + '/api/recrutement/GetAllTypeContratRecrutement')
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getFiltersByRecrutementId(recrutementId) {
        return this.axiosApiInstance.get(window.API_URL + '/api/recrutement/GetFiltersByRecrutementId/' + recrutementId)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }
}
