import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import UserService from '../../services/userService';
import { useAuthState } from "../../context/context";
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';

export const DT_AdministrateursEcole_actions = (props) => {


    const Administrateur = props.Administrateur;
    const [deleteAdministrateurDialog, setDeleteAdministrateurDialog] = useState(false);
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
    const { t } = useTranslation();
    const toast = useRef(null);
    const currentUser = useAuthState();
    const userService = new UserService();

    const checkAdministrateur = () => {
        var win = window.open("/vueAdministrateur?AdministrateurId=" + `${Administrateur.id}`)
        win.focus();
    }

    // Toast calls

    const showCantFindAdministrateur = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_find_Administrateur'), life: 3000 });
    }



    /* */

    const editAdministrateur = (Administrateur, copie) => {
        props.setLoadingAdministrateur(true);
        props.setAdministrateurDialog(true);
        userService.getAdministrateur(Administrateur.id)
            .then(res => {
                let _Administrateur = { ...res };
                if (_Administrateur.composanteEcole.length > 0) {
                    var _composantes = [...props.composantes];
                    var _ddlComposantes = _composantes.filter(o => o.ecoleId === _Administrateur.ecole.id);
                    props.setDdlComposantes(_ddlComposantes);
                }
                props.setViewTypeAdmin(_Administrateur.composanteEcole.length > 0 ? t('administrator.composanteEcole_admin') : t('administrator.ecole_admin'))
                props.setViewTypesAdmin(_Administrateur.composanteEcole.length > 0 ? [t('administrator.composanteEcole_admin')] : [t('administrator.ecole_admin')])
                props.setAdministrateur(_Administrateur);
                props.setLoadingAdministrateur(false);
            })
            .catch(err => {
                showCantFindAdministrateur();
                props.setAdministrateurDialog(false);
                // props.setDescriptionAdministrateur([{titre:'',contenu:'',AdministrateurId:''}, {titre:'',contenu:'',AdministrateurId:''}, {titre:'',contenu:'',AdministrateurId:''}, {titre:'',contenu:'',AdministrateurId:''}]);
                // props.setListeCampus([]);
                props.setLoadingAdministrateur(false);
            });
    }

    /* */




    const deleteAdministrateur = () => {
        toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 })

        userService.delete(Administrateur)
            .then(() => {
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.entity_suppression_success'), life: 3000 });
                props.onAdministrateurDelete();
            }
            )
            .catch((err) =>
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.entity_suppression_failed'), life: 3000 })
            )
    }


    return (
        <div style={{ textAlign: 'right' }}>
            <Toast ref={toast} />
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editAdministrateur(Administrateur, false)} />
            <Button icon="pi pi-trash" className="p-button-rounded p-button-warning p-mr-2" onClick={() => setConfirmDeleteVisible(true)} />

            <ConfirmDialog visible={confirmDeleteVisible} onHide={() => setConfirmDeleteVisible(false)} message={t('administrator.are_you_sure_delete_admin', { Administrateur })}
                header={t('general.confirm')} icon="pi pi-exclamation-triangle" accept={deleteAdministrateur} acceptLabel={t('general.yes')} rejectLabel={t('general.no')} />
        </div>
    );
}