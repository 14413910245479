import axios from "axios";

export async function loginUser(dispatch, loginPayload) {
  const requestOptions = {
    url: window.API_URL + '/api/auth/login',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    data: loginPayload,
  };

  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    await axios(requestOptions).then(response => {
      let user = response.data;
      user.isAuthenticated = true;
      dispatch({ type: 'LOGIN_SUCCESS', payload: user });
      localStorage.setItem('currentUser', JSON.stringify(user));
    }, error => {
      dispatch({ type: 'LOGIN_ERROR', error: error });
    })
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
}

export async function RenewUser(dispatch) {
  let requestOptions = {
    url: window.API_URL + '/api/auth/RefreshToken',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }

  const currentUser = localStorage.getItem('currentUser');
  if (currentUser != null) {
    const token = JSON.parse(currentUser).token
    requestOptions = {
      url: window.API_URL + '/api/auth/RefreshToken',
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },

    };
  }
  
  try {
    dispatch({ type: 'REQUEST_RENEW' });
    await axios(requestOptions).then(response => {
      let user = response.data;
      user.isAuthenticated = true;
      dispatch({ type: 'RENEW_SUCCESS', payload: user });
      localStorage.setItem('currentUser', JSON.stringify(user));
    }, error => {
      dispatch({ type: 'RENEW_ERROR', error: error });
    })
  } catch (error) {
    dispatch({ type: 'RENEW_ERROR', error: error });
  }
}

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('currentUser');
}