import React, { useState } from "react";
import "./cardPresentationEntrepriseThumbnail.css";
import { Card } from 'primereact/card';
import { DisplayProfileImage } from "../image/displayProfileImage";
import { Button } from "primereact/button";
import parse from 'html-react-parser';

export const CardPresentationEntrepriseThumbnail = (props) => {

    const [showFullDescription, setShowFullDescription] = useState(false);

    return (
        // Si l'entreprise n'a pas de description & d'image, affiche t-on la card ?
        <Card className="voe-card p-shadow-4">
            <div className="">
                <h2 className="dip-color-id">
                    {props.entreprise?.nom}
                </h2>
                {(props.entreprise?.logoEntreprise?.id != null && props.entreprise?.description != null ) ? (
                    <div className="p-grid">
                        <div className="p-col-4 p-md-4 cpat-logo-container">
                            <DisplayProfileImage imageId={props.entreprise?.logoEntreprise?.id} />
                        </div>
                        <div className="p-col-8 p-md-8 cpet-description-container">
                            {props.entreprise?.description?.length > 1000 ? (
                                <>
                                    {showFullDescription ? (
                                        <>
                                            <div>{parse(props.entreprise?.description)}</div>
                                            <div className="cpet-button-full-description">
                                                <Button className="dip-button p-button-text" onClick={() => setShowFullDescription(false)}>Voir moins</Button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div>{parse(props.entreprise?.description?.slice(0, 1000))}</div> 
                                            <div className="cpet-button-full-description">                 
                                                <Button className="dip-button p-button-text" onClick={() => setShowFullDescription(true)}>Voir plus</Button>
                                            </div>  
                                        </>
                                    )}
                                </>
                            ) : (
                                <div>{parse(props.entreprise?.description)}</div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div>{parse(props.entreprise?.description)}</div>
                        </div>
                    </div>
                )}
            </div>
        </Card>
    );
};