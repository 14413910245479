import axios from 'axios';
import BaseService from './baseService';

export default class EntrepriseService extends BaseService {

    getEntreprisesOfRecruteur(recruteurId) {
        const options = {
            params: {
                recruteurId: recruteurId
            }
        }
        return this.axiosApiInstance.get(window.API_URL + '/api/entreprise/GetEntreprisesOfRecruteur',options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getEntreprisesPaginated(currentUser, lazyParams) {
        const options = {
          headers: { Authorization: 'Bearer ' + currentUser.token },
        };
        const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))
    
        return axios.get(window.API_URL + '/api/entreprise/GetEntreprisesPaginated?' + queryParams, options)
          .then(res => res.data)
          .catch(err => super.handleHttpError(err));
      }

    getEntreprisePresentation(entrepriseId) {
        return this.axiosApiInstance.get(window.API_URL + '/api/entreprise/GetEntreprisePresentation' + (entrepriseId ? ("/" + entrepriseId) : ""))
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getEntrepriseEtudiant(entrepriseId) {
        return this.axiosApiInstance.get(window.API_URL + '/api/entreprise/GetEntrepriseEtudiant' + (entrepriseId ? ("/" + entrepriseId) : ""))
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getEntrepriseAbonnement(entrepriseId) {
        const options = {
            params: {
                entrepriseId: entrepriseId
            }
        }
        return this.axiosApiInstance.get(window.API_URL + '/api/entreprise/GetEntrepriseAbonnement', options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getAbonnementEntreprise() {
        return this.axiosApiInstance.get(window.API_URL + '/api/entreprise/GetAbonnementEntreprise')
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getExtendEntrepriseProfil(recruteurId) {
        const options = {
            params: {
                recruteurId: recruteurId
            }
        }
        return this.axiosApiInstance.get(window.API_URL + '/api/entreprise/GetExtendRecruteurProfil', options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getAbonnementEntrepriseIsFree(entrepriseId, currentUser) {
        const options = {
            headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + currentUser.token }
        };
        return this.axiosApiInstance.get(window.API_URL + '/api/entreprise/GetAbonnementEntrepriseIsFree=' + entrepriseId, options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }


    updateAbonnementEntreprise(entrepriseId, abonnement) {
        const jsonAbonnement = JSON.stringify(abonnement, null, 2);
        return this.axiosApiInstance.post(window.API_URL + '/api/entreprise/UpdateAbonnementEntreprise/' + entrepriseId, jsonAbonnement)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    like(etudiantId, entrepriseId) {
        const options = {
            params: {
                etudiantId: etudiantId,
                entrepriseId: entrepriseId
            }
        }
        return this.axiosApiInstance.post(window.API_URL + '/api/entreprise/like', null, options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    unlike(etudiantId, entrepriseId) {
        const options = {
            params: {
                etudiantId: etudiantId,
                entrepriseId: entrepriseId
            }
        }
        return this.axiosApiInstance.post(window.API_URL + '/api/entreprise/unlike', null, options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    updateEntrepriseEtudiant(entrepriseEtudiant) {
        const jsonEE = JSON.stringify(entrepriseEtudiant, null, 2);
        return this.axiosApiInstance.post(window.API_URL + '/api/entreprise/UpdateEntrepriseEtudiant', jsonEE)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getFicheCandidatForEntreprise(etudiantId, entrepriseId) {
        const options = {
            headers: { 'Content-Type': 'application/json' },
            params: {
                etudiantId: etudiantId,
                entrepriseId: entrepriseId
            }
        };
        return this.axiosApiInstance.get(window.API_URL + '/api/entreprise/GetFicheCandidatForEntreprise', options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getNotes(etudiantId, entrepriseId) {
        const options = {
            params: {
                etudiantId: etudiantId,
                entrepriseId: entrepriseId
            }
        };
        return this.axiosApiInstance.get(window.API_URL + `/api/entreprise/GetNotes`, options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    postNotes(etudiantId, notes, entrepriseId) {
        const options = {
            params: {
                etudiantId: etudiantId,
                entrepriseId: entrepriseId
            }
        };
        const model = JSON.stringify(notes, null, 2);
        return this.axiosApiInstance.post(window.API_URL + `/api/entreprise/PostNotes`, model, options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getCollaborateursEntreprise(entrepriseId) {
        const options = {
            params: {
                entrepriseId: entrepriseId
            }
        };
        return this.axiosApiInstance.get(window.API_URL + `/api/entreprise/GetCollaborateursEntreprise`, options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    deleteCollaborateurEntreprise(recruteurId, entrepriseId) {
        const options = {
            params: {
                recruteurId: recruteurId,
                entrepriseId: entrepriseId
            }
        };
        return this.axiosApiInstance.delete(window.API_URL + `/api/entreprise/DeleteCollaborateurEntreprise`, options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }
}