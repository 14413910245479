const Pays = [
    {alpha2:"AF", libelle:"Afghanistan"},
    {alpha2:"ZA", libelle:"Afrique du Sud"},
    {alpha2:"AL", libelle:"Albanie"},
    {alpha2:"DZ", libelle:"Algérie"},
    {alpha2:"DE", libelle:"Allemagne"},
    {alpha2:"AD", libelle:"Andorre"},
    {alpha2:"AO", libelle:"Angola"},
    {alpha2:"AI", libelle:"Anguilla"},
    {alpha2:"AQ", libelle:"Antarctique"},
    {alpha2:"AG", libelle:"Antigua-et-Barbuda"},
    {alpha2:"AN", libelle:"Antilles néerlandaises"},
    {alpha2:"SA", libelle:"Arabie saoudite"},
    {alpha2:"AR", libelle:"Argentine"},
    {alpha2:"AM", libelle:"Arménie"},
    {alpha2:"AW", libelle:"Aruba"},
    {alpha2:"AU", libelle:"Australie"},
    {alpha2:"AT", libelle:"Autriche"},
    {alpha2:"AZ", libelle:"Azerbaïdjan"},
    {alpha2:"BS", libelle:"Bahamas"},
    {alpha2:"BH", libelle:"Bahreïn"},
    {alpha2:"BD", libelle:"Bangladesh"},
    {alpha2:"BB", libelle:"Barbade"},
    {alpha2:"BY", libelle:"Bélarus"},
    {alpha2:"BE", libelle:"Belgique"},
    {alpha2:"BZ", libelle:"Belize"},
    {alpha2:"BJ", libelle:"Bénin"},
    {alpha2:"BM", libelle:"Bermudes"},
    {alpha2:"BT", libelle:"Bhoutan"},
    {alpha2:"BO", libelle:"Bolivie"},
    {alpha2:"BA", libelle:"Bosnie-Herzégovine"},
    {alpha2:"BW", libelle:"Botswana"},
    {alpha2:"BR", libelle:"Brésil"},
    {alpha2:"BN", libelle:"Brunéi Darussalam"},
    {alpha2:"BG", libelle:"Bulgarie"},
    {alpha2:"BF", libelle:"Burkina Faso"},
    {alpha2:"BI", libelle:"Burundi"},
    {alpha2:"KH", libelle:"Cambodge"},
    {alpha2:"CM", libelle:"Cameroun"},
    {alpha2:"CA", libelle:"Canada"},
    {alpha2:"CV", libelle:"Cap-Vert"},
    {alpha2:"EA", libelle:"Ceuta et Melilla"},
    {alpha2:"CL", libelle:"Chili"},
    {alpha2:"CN", libelle:"Chine"},
    {alpha2:"CY", libelle:"Chypre"},
    {alpha2:"CO", libelle:"Colombie"},
    {alpha2:"KM", libelle:"Comores"},
    {alpha2:"CG", libelle:"Congo-Brazzaville"},
    {alpha2:"KP", libelle:"Corée du Nord"},
    {alpha2:"KR", libelle:"Corée du Sud"},
    {alpha2:"CR", libelle:"Costa Rica"},
    {alpha2:"CI", libelle:"Côte d’Ivoire"},
    {alpha2:"HR", libelle:"Croatie"},
    {alpha2:"CU", libelle:"Cuba"},
    {alpha2:"DK", libelle:"Danemark"},
    {alpha2:"DG", libelle:"Diego Garcia"},
    {alpha2:"DJ", libelle:"Djibouti"},
    {alpha2:"DM", libelle:"Dominique"},
    {alpha2:"EG", libelle:"Égypte"},
    {alpha2:"SV", libelle:"El Salvador"},
    {alpha2:"AE", libelle:"Émirats arabes unis"},
    {alpha2:"EC", libelle:"Équateur"},
    {alpha2:"ER", libelle:"Érythrée"},
    {alpha2:"ES", libelle:"Espagne"},
    {alpha2:"EE", libelle:"Estonie"},
    {alpha2:"VA", libelle:"État de la Cité du Vatican"},
    {alpha2:"FM", libelle:"États fédérés de Micronésie"},
    {alpha2:"US", libelle:"États-Unis"},
    {alpha2:"ET", libelle:"Éthiopie"},
    {alpha2:"FJ", libelle:"Fidji"},
    {alpha2:"FI", libelle:"Finlande"},
    {alpha2:"FR", libelle:"France"},
    {alpha2:"GA", libelle:"Gabon"},
    {alpha2:"GM", libelle:"Gambie"},
    {alpha2:"GE", libelle:"Géorgie"},
    {alpha2:"GS", libelle:"Géorgie du Sud et les îles Sandwich du Sud"},
    {alpha2:"GH", libelle:"Ghana"},
    {alpha2:"GI", libelle:"Gibraltar"},
    {alpha2:"GR", libelle:"Grèce"},
    {alpha2:"GD", libelle:"Grenade"},
    {alpha2:"GL", libelle:"Groenland"},
    {alpha2:"GP", libelle:"Guadeloupe"},
    {alpha2:"GU", libelle:"Guam"},
    {alpha2:"GT", libelle:"Guatemala"},
    {alpha2:"GG", libelle:"Guernesey"},
    {alpha2:"GN", libelle:"Guinée"},
    {alpha2:"GQ", libelle:"Guinée équatoriale"},
    {alpha2:"GW", libelle:"Guinée-Bissau"},
    {alpha2:"GY", libelle:"Guyana"},
    {alpha2:"GF", libelle:"Guyane française"},
    {alpha2:"HT", libelle:"Haïti"},
    {alpha2:"HN", libelle:"Honduras"},
    {alpha2:"HU", libelle:"Hongrie"},
    {alpha2:"BV", libelle:"Île Bouvet"},
    {alpha2:"CX", libelle:"Île Christmas"},
    {alpha2:"CP", libelle:"Île Clipperton"},
    {alpha2:"AC", libelle:"Île de l'Ascension"},
    {alpha2:"IM", libelle:"Île de Man"},
    {alpha2:"NF", libelle:"Île Norfolk"},
    {alpha2:"AX", libelle:"Îles Åland"},
    {alpha2:"KY", libelle:"Îles Caïmans"},
    {alpha2:"IC", libelle:"Îles Canaries"},
    {alpha2:"CC", libelle:"Îles Cocos - Keeling"},
    {alpha2:"CK", libelle:"Îles Cook"},
    {alpha2:"FO", libelle:"Îles Féroé"},
    {alpha2:"HM", libelle:"Îles Heard et MacDonald"},
    {alpha2:"FK", libelle:"Îles Malouines"},
    {alpha2:"MP", libelle:"Îles Mariannes du Nord"},
    {alpha2:"MH", libelle:"Îles Marshall"},
    {alpha2:"UM", libelle:"Îles Mineures Éloignées des États-Unis"},
    {alpha2:"SB", libelle:"Îles Salomon"},
    {alpha2:"TC", libelle:"Îles Turks et Caïques"},
    {alpha2:"VG", libelle:"Îles Vierges britanniques"},
    {alpha2:"VI", libelle:"Îles Vierges des États-Unis"},
    {alpha2:"IN", libelle:"Inde"},
    {alpha2:"ID", libelle:"Indonésie"},
    {alpha2:"IQ", libelle:"Irak"},
    {alpha2:"IR", libelle:"Iran"},
    {alpha2:"IE", libelle:"Irlande"},
    {alpha2:"IS", libelle:"Islande"},
    {alpha2:"IL", libelle:"Israël"},
    {alpha2:"IT", libelle:"Italie"},
    {alpha2:"JM", libelle:"Jamaïque"},
    {alpha2:"JP", libelle:"Japon"},
    {alpha2:"JE", libelle:"Jersey"},
    {alpha2:"JO", libelle:"Jordanie"},
    {alpha2:"KZ", libelle:"Kazakhstan"},
    {alpha2:"KE", libelle:"Kenya"},
    {alpha2:"KG", libelle:"Kirghizistan"},
    {alpha2:"KI", libelle:"Kiribati"},
    {alpha2:"KW", libelle:"Koweït"},
    {alpha2:"LA", libelle:"Laos"},
    {alpha2:"LS", libelle:"Lesotho"},
    {alpha2:"LV", libelle:"Lettonie"},
    {alpha2:"LB", libelle:"Liban"},
    {alpha2:"LR", libelle:"Libéria"},
    {alpha2:"LY", libelle:"Libye"},
    {alpha2:"LI", libelle:"Liechtenstein"},
    {alpha2:"LT", libelle:"Lituanie"},
    {alpha2:"LU", libelle:"Luxembourg"},
    {alpha2:"MK", libelle:"Macédoine"},
    {alpha2:"MG", libelle:"Madagascar"},
    {alpha2:"MY", libelle:"Malaisie"},
    {alpha2:"MW", libelle:"Malawi"},
    {alpha2:"MV", libelle:"Maldives"},
    {alpha2:"ML", libelle:"Mali"},
    {alpha2:"MT", libelle:"Malte"},
    {alpha2:"MA", libelle:"Maroc"},
    {alpha2:"MQ", libelle:"Martinique"},
    {alpha2:"MU", libelle:"Maurice"},
    {alpha2:"MR", libelle:"Mauritanie"},
    {alpha2:"YT", libelle:"Mayotte"},
    {alpha2:"MX", libelle:"Mexique"},
    {alpha2:"MD", libelle:"Moldavie"},
    {alpha2:"MC", libelle:"Monaco"},
    {alpha2:"MN", libelle:"Mongolie"},
    {alpha2:"ME", libelle:"Monténégro"},
    {alpha2:"MS", libelle:"Montserrat"},
    {alpha2:"MZ", libelle:"Mozambique"},
    {alpha2:"MM", libelle:"Myanmar"},
    {alpha2:"NA", libelle:"Namibie"},
    {alpha2:"NR", libelle:"Nauru"},
    {alpha2:"NP", libelle:"Népal"},
    {alpha2:"NI", libelle:"Nicaragua"},
    {alpha2:"NE", libelle:"Niger"},
    {alpha2:"NG", libelle:"Nigéria"},
    {alpha2:"NU", libelle:"Niue"},
    {alpha2:"NO", libelle:"Norvège"},
    {alpha2:"NC", libelle:"Nouvelle-Calédonie"},
    {alpha2:"NZ", libelle:"Nouvelle-Zélande"},
    {alpha2:"OM", libelle:"Oman"},
    {alpha2:"UG", libelle:"Ouganda"},
    {alpha2:"UZ", libelle:"Ouzbékistan"},
    {alpha2:"PK", libelle:"Pakistan"},
    {alpha2:"PW", libelle:"Palaos"},
    {alpha2:"PA", libelle:"Panama"},
    {alpha2:"PG", libelle:"Papouasie-Nouvelle-Guinée"},
    {alpha2:"PY", libelle:"Paraguay"},
    {alpha2:"NL", libelle:"Pays-Bas"},
    {alpha2:"PE", libelle:"Pérou"},
    {alpha2:"PH", libelle:"Philippines"},
    {alpha2:"PN", libelle:"Pitcairn"},
    {alpha2:"PL", libelle:"Pologne"},
    {alpha2:"PF", libelle:"Polynésie française"},
    {alpha2:"PR", libelle:"Porto Rico"},
    {alpha2:"PT", libelle:"Portugal"},
    {alpha2:"QA", libelle:"Qatar"},
    {alpha2:"HK", libelle:"R.A.S. chinoise de Hong Kong"},
    {alpha2:"MO", libelle:"R.A.S. chinoise de Macao"},
    {alpha2:"QO", libelle:"régions éloignées de l’Océanie"},
    {alpha2:"CF", libelle:"République centrafricaine"},
    {alpha2:"CD", libelle:"République démocratique du Congo"},
    {alpha2:"DO", libelle:"République dominicaine"},
    {alpha2:"CZ", libelle:"République tchèque"},
    {alpha2:"RE", libelle:"Réunion"},
    {alpha2:"RO", libelle:"Roumanie"},
    {alpha2:"GB", libelle:"Royaume-Uni"},
    {alpha2:"RU", libelle:"Russie"},
    {alpha2:"RW", libelle:"Rwanda"},
    {alpha2:"EH", libelle:"Sahara occidental"},
    {alpha2:"BL", libelle:"Saint-Barthélémy"},
    {alpha2:"KN", libelle:"Saint-Kitts-et-Nevis"},
    {alpha2:"SM", libelle:"Saint-Marin"},
    {alpha2:"MF", libelle:"Saint-Martin"},
    {alpha2:"PM", libelle:"Saint-Pierre-et-Miquelon"},
    {alpha2:"VC", libelle:"Saint-Vincent-et-les Grenadines"},
    {alpha2:"SH", libelle:"Sainte-Hélène"},
    {alpha2:"LC", libelle:"Sainte-Lucie"},
    {alpha2:"WS", libelle:"Samoa"},
    {alpha2:"AS", libelle:"Samoa américaines"},
    {alpha2:"ST", libelle:"Sao Tomé-et-Principe"},
    {alpha2:"SN", libelle:"Sénégal"},
    {alpha2:"RS", libelle:"Serbie"},
    {alpha2:"CS", libelle:"Serbie-et-Monténégro"},
    {alpha2:"SC", libelle:"Seychelles"},
    {alpha2:"SL", libelle:"Sierra Leone"},
    {alpha2:"SG", libelle:"Singapour"},
    {alpha2:"SK", libelle:"Slovaquie"},
    {alpha2:"SI", libelle:"Slovénie"},
    {alpha2:"SO", libelle:"Somalie"},
    {alpha2:"SD", libelle:"Soudan"},
    {alpha2:"LK", libelle:"Sri Lanka"},
    {alpha2:"SE", libelle:"Suède"},
    {alpha2:"CH", libelle:"Suisse"},
    {alpha2:"SR", libelle:"Suriname"},
    {alpha2:"SJ", libelle:"Svalbard et Île Jan Mayen"},
    {alpha2:"SZ", libelle:"Swaziland"},
    {alpha2:"SY", libelle:"Syrie"},
    {alpha2:"TJ", libelle:"Tadjikistan"},
    {alpha2:"TW", libelle:"Taïwan"},
    {alpha2:"TZ", libelle:"Tanzanie"},
    {alpha2:"TD", libelle:"Tchad"},
    {alpha2:"TF", libelle:"Terres australes françaises"},
    {alpha2:"IO", libelle:"Territoire britannique de l'océan Indien"},
    {alpha2:"PS", libelle:"Territoire palestinien"},
    {alpha2:"TH", libelle:"Thaïlande"},
    {alpha2:"TL", libelle:"Timor oriental"},
    {alpha2:"TG", libelle:"Togo"},
    {alpha2:"TK", libelle:"Tokelau"},
    {alpha2:"TO", libelle:"Tonga"},
    {alpha2:"TT", libelle:"Trinité-et-Tobago"},
    {alpha2:"TA", libelle:"Tristan da Cunha"},
    {alpha2:"TN", libelle:"Tunisie"},
    {alpha2:"TM", libelle:"Turkménistan"},
    {alpha2:"TR", libelle:"Turquie"},
    {alpha2:"TV", libelle:"Tuvalu"},
    {alpha2:"UA", libelle:"Ukraine"},
    {alpha2:"EU", libelle:"Union européenne"},
    {alpha2:"UY", libelle:"Uruguay"},
    {alpha2:"VU", libelle:"Vanuatu"},
    {alpha2:"VE", libelle:"Venezuela"},
    {alpha2:"VN", libelle:"Viêt Nam"},
    {alpha2:"WF", libelle:"Wallis-et-Futuna"},
    {alpha2:"YE", libelle:"Yémen"},
    {alpha2:"ZM", libelle:"Zambie"},
    {alpha2:"ZW", libelle:"Zimbabwe"}
]

export default Pays