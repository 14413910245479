import { Button } from "primereact/button"
import { OverlayPanel } from "primereact/overlaypanel"
import { useRef } from "react"
import "./menuSearchTooltip.css"

export const MenuSearchTooltip = (props) => {

    const tooltip = useRef(null)

    return (
        <div className={props.className}>
            <i className="pi pi-info-circle mst-icon" onClick={(e) => tooltip.current.toggle(e)} ></i>

            <OverlayPanel className="location-panel" ref={tooltip}>
                {props.text}
            </OverlayPanel>
        </div>
    )
}