import React, { Fragment, useEffect, useState, useRef } from "react"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { Trans, useTranslation } from "react-i18next"
import { Toast } from "primereact/toast";
import LangueService from "../../../../services/langueService"
import FormationService from "../../../../services/formationService"
import { LangueSelector } from "../../../selector/langueSelector"
import './personnalInformation.css'
import './competenceSection.css'
import { FormNavigationButtons } from "../../formNavigationButtons"
import { FormationListItemForStudies } from "../../../listItem/formationListItemForStudies"
import { MultiSelect } from "primereact/multiselect"
import { ProgressSpinner } from "primereact/progressspinner"

export const CompetenceSection = (props) => {

    const { t } = useTranslation();
    const [ddlNiveauxLangue, setDdlNiveauxLangue] = useState(null);
    const [ddlFormationsComptences, setDdlFormationsComptences] = useState([]);
    const [selectedCompetences, setSelectedCompetences] = useState([]);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);

    const langueService = new LangueService();
    const formationService = new FormationService();

    useEffect(() => {
        if (!props.loadingContent && props.etudiant?.etudiantCompetences) {
            loadData();
        }
        return () => {
            // cleanup
            setDdlNiveauxLangue(null);
        };
    }, [props.loadingContent]);

    const loadData = () => {
        langueService
        .getNiveauxLangue()
        .then((_langues) => {
            setDdlNiveauxLangue(_langues);
        });
        formationService
            .getAllowedFormationsCompetencesEtudiant(props.etudiant.id)
            .then((_formationCompetence) => {
                setDdlFormationsComptences(_formationCompetence);
                //trie des valeurs en fonctions de si elles sont prisent en compte ou non
                let _selectedCompetences = [];

                _formationCompetence?.map((formationCompetence) => {
                    // trier les compétences en fonction de l'ordre de la formation
                    formationCompetence.competenceFormation.sort((a, b) => (a.ordre > b.ordre) ? 1 : -1)

                    let tmp_selectedCompetences = [];
                    formationCompetence.competenceFormation.map((competence) => {
                        // on récupère les compétences de la formation si elle sont présente dans les compétences de l'étudiant et si elle sont prit en compte
                        if (props.etudiant?.etudiantCompetences?.some(o => o.competenceId === competence.competenceId && o.prisEnCompte == true)) {
                            tmp_selectedCompetences.push(competence.competenceId);
                        }
                    });
                    _selectedCompetences.push(tmp_selectedCompetences);
                });
                setSelectedCompetences(_selectedCompetences);
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleLangueChange = (index, e, name) => {
        props.setFormIsDirty(true);
        let _etudiant = { ...props.etudiant };
        let _etudiantLangue = [..._etudiant.etudiantLangue];
        let val = (e.target && e.target.value) || '';
        let _langue = _etudiantLangue[index];
        _langue[`${name}`] = val;
        _langue[`${name}Id`] = val?.id;
        _etudiantLangue[index] = _langue;
        _etudiant.etudiantLangue = _etudiantLangue;
        props.setEtudiant(_etudiant);
    };

    const handleAddLangue = () => {
        props.setFormIsDirty(true);
        let _etudiant = { ...props.etudiant };
        let _etudiantLangue = [..._etudiant.etudiantLangue];
        _etudiantLangue.push({ langue: '', niveauLangue: '', etudiantId: props.etudiant.id });
        _etudiant.etudiantLangue = _etudiantLangue;
        props.setEtudiant(_etudiant);
    };

    const handleRemoveLangue = index => {
        props.setFormIsDirty(true);
        let _etudiant = { ...props.etudiant };
        let _etudiantLangue = [..._etudiant.etudiantLangue];
        _etudiantLangue.splice(index, 1);
        _etudiant.etudiantLangue = _etudiantLangue;
        props.setEtudiant(_etudiant);
    };

    const handleCompetenceChange = (e, indexFormation) => {
        props.setFormIsDirty(true);
        // on récupère la compétence sélectionnée et en fonction de si elle est déjà sélectionnée ou non, on l'ajoute ou la supprime de selectedCompetences
        let _selectedCompetences = [...selectedCompetences];
        _selectedCompetences[indexFormation] = e.value;
        setSelectedCompetences(_selectedCompetences);

        let _etudiant = { ...props.etudiant };
        let _unselectedCompetences = [];
        let _onselectedCompetences = [];
        _selectedCompetences.map((selectedCompetence, index) => {
            let _formationCompetence = ddlFormationsComptences[index];
            _formationCompetence.competenceFormation.map((competence) => {
                // on récupère les compétencesId de la formation
                if (selectedCompetence.some(x => x === competence.competenceId)) {
                    _onselectedCompetences.push(competence);
                } else {
                    _unselectedCompetences.push(competence);
                }
            });
        }
        );
        // on récupère les compétences qui sont sélectionnées sur les différentes formations
        let _etudiantCompetences = [];
        _onselectedCompetences.forEach(a => {
            _etudiantCompetences.push({ etudiantId: props.etudiant.id, competenceId: a.competenceId, prisEnCompte: true, ordre: a.ordre, competence: a.competence })
        }
        );
        // on récupère les compétences qui ne sont pas sélectionnées sur les différentes formations
        _unselectedCompetences.forEach(a => {
            _etudiantCompetences.push({ etudiantId: props.etudiant.id, competenceId: a.competenceId, prisEnCompte: false, ordre: a.ordre, competence: a.competence })
        }
        );
        _etudiant.etudiantCompetences = _etudiantCompetences;
        props.setEtudiant(_etudiant);
    };


    return (
        props.loadingContent ? (
            <div className="dip-ta-center">
                <ProgressSpinner />
            </div>
        ) : (
            <div>
            <Toast ref={toast} position="top-right" />
            <div className="personnal-info-subtitle"><Trans i18nKey="profile.languages" /></div>
            <div className="form-row" >
                <div className="p-fluid p-formgrid p-grid">
                    {/* <div className="p-col-12 p-sm-12 p-lg-6 info-langue-display"><label><Trans i18nKey="general.language" /></label></div> */}
                    {/* <div className="p-col-12 p-sm-12 p-lg-6 info-niveau-langue-display"><label><Trans i18nKey="general.level" /></label></div> */}
                </div>
                {/* Langues */}
                {props.etudiant?.etudiantLangue?.map((etulangue, indexLangue) => (
                    <Fragment key={`langue~${indexLangue}`}>
                        <div className="p-fluid p-formgrid p-grid responsive-langue-display">
                            <div className="p-field p-col-12 p-sm-12 p-lg-6">
                                <LangueSelector
                                    value={etulangue.langue}
                                    onChange={event => handleLangueChange(indexLangue, event, 'langue')}
                                    placeholder={t('general.language')}
                                    className={classNames({ 'p-invalid': props.submitted && !etulangue.langue })}
                                />
                                {props.submitted && !etulangue.langue && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                            </div>
                            <div className="p-field p-col-10 p-sm-10 p-md-11 p-lg-5">
                                <Dropdown
                                    value={etulangue.niveauLangue}
                                    options={ddlNiveauxLangue}
                                    onChange={event => handleLangueChange(indexLangue, event, 'niveauLangue')}
                                    placeholder={t('general.level')}
                                    className={classNames({ 'p-invalid': props.submitted && !etulangue.niveauLangue })}
                                />
                                {props.submitted && !etulangue.niveauLangue && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                            </div>
                            <div className="p-field p-col-2 p-sm-2 p-md-1 p-lg-1 delete-langue-btn">
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-danger"
                                    onClick={() => handleRemoveLangue(indexLangue)}
                                />
                            </div>
                        </div>
                    </Fragment>
                ))}
                <div className="p-field add-langue-btn">
                    <Button
                        icon="pi pi-plus"
                        className="p-button-rounded p-button-success p-mr-2"
                        onClick={() => handleAddLangue()}
                    />
                    <b><Trans i18nKey='profile.add_language' /></b>
                </div>
            </div>
            {/* Compétences */}
            <div className="personnal-info-subtitle"><Trans i18nKey="skills.professionnal" /></div>
            <span className="cs-caption-formation-desc"><Trans i18nKey="profile.skill_caption_has_formation" /></span>
            {loading ? (
                <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
            ) : (
                props.formations.filter(x => x.statut != "Formation à venir").map((formation, indexFormation) => (
                    <React.Fragment key={`${formation?.id}~${indexFormation}`}>
                        <FormationListItemForStudies
                            formation={formation}
                        />
                        <MultiSelect
                            className="cs-multi-select-competences"
                            panelClassName="cs-multi-select-competences-panel"
                            options={ddlFormationsComptences[indexFormation]?.competenceFormation}
                            value={selectedCompetences[indexFormation]}
                            optionLabel="libelle"
                            optionValue="competenceId"
                            placeholder={t('skills.select_skills')}
                            onChange={(e) => handleCompetenceChange(e, indexFormation)}
                            display="chip"
                        />
                    </React.Fragment>
                ))
            )}

            <FormNavigationButtons
                isVisualizationAllowed={props.etudiant?.id}
                previousStep={props.previousStep}
                nextStep={props.nextStep}
                onSave={props.onSave}
                setOnSave={props.setOnSave}
                isSubmitting={props.isSubmitting}
            />
        </div >
    )
    );
}