import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DT_Composante } from '../../../components/datatable/dt_composantes';


export const AdministrationComposanteEcolesPage = () => {
    const { t } = useTranslation();

    return (
        <div>
            <h1>{t('composante.composante_management.full')}</h1>
            <DT_Composante />
        </div>
    );
}