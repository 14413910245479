import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { FormationListItemForStudies } from "../../../../listItem/formationListItemForStudies"
import { FormNavigationButtons } from "../../../formNavigationButtons"
import { FormCoursePopUp } from "./formCoursePopUp"
import { ProgressSpinner } from "primereact/progressspinner"
import FormationService from "../../../../../services/formationService"
import "./studies.css"

export const Studies = (props) => {

    const { t } = useTranslation();

    const [displayForm, setDisplayForm] = useState(false)
    const [currentFormation, setCurrentFormation] = useState(null)
    const [doesCurrentFormationExistInDatabase, setDoesCurrentFormationExistInDatabase] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(null)
    const [ddlNiveauxEtude, setDdlNiveauxEtude] = useState(null);
    const formationService = new FormationService();

    useEffect(() => {
        loadData()
        return () => {
            // cleanup
            setDdlNiveauxEtude(null)
        }
    }, [])

    const loadData = () => {
        formationService.getNiveauxEtude()
            .then((_niveaux) => {
                setDdlNiveauxEtude(_niveaux);
            });
    }

    const onShow = () => {
        setCurrentIndex(null);
        setCurrentFormation(null);
        setDoesCurrentFormationExistInDatabase(false)
        setDisplayForm(true);
    }

    const onHide = () => {
        setDisplayForm(false);
    }

    const onEdit = (formation, index) => {
        let _formation = { ...formation }
        _formation.diplome = formation.diplome
        _formation.formation = { id: formation.id, nomComplet: formation.nomComplet }
        setCurrentIndex(index)
        setCurrentFormation(_formation);
        setDoesCurrentFormationExistInDatabase(true)
        setDisplayForm(true);
    }

    const onEditDemande = (formation, index) => {
        setCurrentIndex(index)
        setCurrentFormation(formation);
        setDoesCurrentFormationExistInDatabase(false)
        setDisplayForm(true);
    }

    const handleEditFormation = (formation, currentIndex) => {
        let doesCurrentFormationExistInDatabaseBeforeUpdate = doesCurrentFormationExistInDatabase
        let doesCurrentFormationExistInDatabaseAfterUpdate = formation.id ? (true) : (false)
        props.handleEditFormation(formation, currentIndex, doesCurrentFormationExistInDatabaseBeforeUpdate, doesCurrentFormationExistInDatabaseAfterUpdate)
    }

    const handleEditDemandeFormation = (formation, currentIndex) => {
        let doesCurrentFormationExistInDatabaseBeforeUpdate = doesCurrentFormationExistInDatabase
        let doesCurrentFormationExistInDatabaseAfterUpdate = formation.id ? (true) : (false)
        props.handleEditDemandeFormation(formation, currentIndex, doesCurrentFormationExistInDatabaseBeforeUpdate, doesCurrentFormationExistInDatabaseAfterUpdate)
    }

    return (
        props.loadingContent ? (
            <div className="dip-ta-center">
                <ProgressSpinner />
            </div>
        ) : (
        <div>
            {/* study level */}
            <div>
                <div className="personnal-info-subtitle p-col-12">
                    <Trans i18nKey='general.study_level_actually' />*
                </div>
                <div className="p-field studies-input-container p-pt-0 p-col-12 p-sm-4 p-lg-4">
                    <Dropdown
                        id='niveauEtude'
                        value={props.etudiant?.niveauEtude}
                        placeholder={t('profile.please_select_your_level')}
                        options={ddlNiveauxEtude}
                        onChange={(e) => props.onInputChange(e, 'niveauEtude')}
                    />
                    {props.submitted && !props.etudiant?.niveauEtude && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                </div>
            </div>
            {/* formation */}
            <div className="personnal-info-subtitle p-col-12">
                <Trans i18nKey="profile.formation.plural" />
            </div>
            <div className="p-mb-2 p-pt-0 p-col-12 studies-formation-desc"><Trans i18nKey="profile.studies_formation_description" /></div>
            <div className="p-field add-formation-btn p-col-12">
                <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-success p-mr-2"
                    onClick={() => onShow()}
                />
                <b><Trans i18nKey='profile.add_formation' /></b>
            </div>
            {/* formation attention desc */}
            {props.formations.length == 0 && props.demandeFormation.length == 0 &&
                <div className="p-mb-2 p-pt-0 p-col-12 studies-formation-attention"><Trans i18nKey="profile.studies_formation_attention" /></div>
            }
            <FormCoursePopUp
                displayForm={displayForm}
                setDisplayForm={setDisplayForm}
                currentFormation={currentFormation}
                currentIndex={currentIndex}
                onHide={onHide}
                onAdd={props.handleAddFormation}
                onAddDemande={props.handleAddDemandeFormation}
                handleEditFormation={handleEditFormation}
                handleEditDemandeFormation={handleEditDemandeFormation}
            />
            {/* <Divider /> */}
            {props.formations.map((formation, indexFormation) => (
                <React.Fragment key={`${formation?.id}~${indexFormation}`}>
                    <FormationListItemForStudies
                        formation={formation}
                        onEdit={() => onEdit(formation, indexFormation)}
                        onRemove={() => props.handleRemoveFormation(indexFormation)}
                    />
                </React.Fragment>
            ))}
            {/* personnal formation */}
            {props.demandeFormation?.length > 0 &&
                <div>
                    <div className="studies-title p-col-12">
                        <Trans i18nKey="profile.customFormation.plural" />
                    </div>
                    <div className="p-mb-2 p-pt-0 p-col-12 studies-formation-attention">
                        <Trans i18nKey="profile.studies_personnal_formation_attention" />
                    </div>
                </div>
            }
            {props.demandeFormation?.map((formation, indexFormation) => (
                <React.Fragment key={`${formation?.id}~${indexFormation}`}>
                    <FormationListItemForStudies formation={formation}
                        onEdit={() => onEditDemande(formation, indexFormation)}
                        onRemove={() => props.handleRemoveDemandeFormation(indexFormation)}
                    />
                </React.Fragment>
            ))}
            <FormNavigationButtons
                isVisualizationAllowed={props.etudiant?.id}
                previousStep={props.previousStep}
                nextStep={props.nextStep}
                onSave={props.onSave}
                setOnSave={props.setOnSave}
                isSubmitting={props.isSubmitting}
            />
        </div>
        )
    )
}