import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { DisplayImage } from "../../components/image/displayImage";
import { IoLogoLinkedin } from 'react-icons/io5';
import { IoLogoFacebook } from 'react-icons/io5';
import { IoLogoInstagram } from 'react-icons/io5';
import { IoLogoTwitter } from 'react-icons/io5';
import EcoleService from "../../services/ecoleService"
import "./vueOrganismeFormation.css";
import { CampusLocationFormater } from "../../components/formater/campusLocationFormater";
import { useHistory } from "react-router-dom";
import { Mailto } from "../../components/mailto/mailto";
import { NoLongerAvailable } from "../../scenes/errors/noLongerAvailable";
import { enumHttpError } from "../../enums/enumHttpError";
import { WysiwygRenderer } from "../../components/wysiwyg/wysiwygRenderer";
import { WysiwygResponsiveRenderer } from "../../components/wysiwyg/wysiwygResponsiveRenderer";
import ComposanteService from "../../services/composanteService";
import { enumOrganismeFormationCategorie } from "../../enums/enumOrganismeFormationCategorie";
import { BackgroundImageBottomEllipsis } from "../../components/background/backgroundImageBottomEllipsis";
import { useAuthState } from "../../context/context";
import RoleHelper from "../../utils/roleHelper";
import {Helmet} from "react-helmet";

export const VueOrganismeFormation = (props) => {

    const { t } = useTranslation()
    const history = useHistory();
    const currentUser = useAuthState();

    const [organismeFormation, setOrganismeFormation] = useState(null)
    const [mainTitle, setMainTitle] = useState(null)
    const [subTitle, setSubTitle] = useState(null)
    const [logoId, setLogoId] = useState(null)
    const [campus, setCampus] = useState([])
    const [loading, setLoading] = useState(true)
    const [available, setAvailable] = useState(false)
    const [showMoreCampus, setShowMoreCampus] = useState(false);
    const [description, setDescription] = useState(null)

    const ecoleService = new EcoleService();
    const composanteEcoleService = new ComposanteService();

    const toast = useRef(null)

    useEffect(() => {
        loadData()
    }, [])

    const abonnements = {
        SANSABONNEMENT: "Sans abonnement",
        PARTNERSHIP: "Partnership",
        CLASSIC: "Classic",
        ANALYTICS: "Analytics"
    }

    const campusAlphaSort = (a, b) => {

        if (a.commune && b.commune) {
            return a.commune.libelle.localeCompare(b.commune.libelle, 'en', { sensitivity: 'base' })
        }
        else if (a.commune && b.ville) {
            return a.commune.libelle.localeCompare(b.ville, 'en', { sensitivity: 'base' })

        }
        else if (a.ville && b.commune) {
            return a.ville.localeCompare(b.commune.libelle, 'en', { sensitivity: 'base' })

        }
        return 0;
    }

    const campusFilterDuplicate = (campus) => {
        const filtered = [];

        if (campus) {
            for (let i = 0; i < campus.length; i++) {
                var tmpLibelle = "";

                if (campus[i].commune) {
                    tmpLibelle = campus[i].commune.libelle;
                }
                else if (campus[i].ville) {
                    tmpLibelle = campus[i].ville;
                }

                if (tmpLibelle != "" && !filtered.some(campu => campu.commune?.libelle === tmpLibelle) && !filtered.some(campu => campu.ville === tmpLibelle)) {
                    filtered.push(campus[i]);
                }
            }
        }
        return filtered;
    }

    const loadData = () => {
        if (props.organismeFormationCategorie == enumOrganismeFormationCategorie.ECOLE) {
            ecoleService.getEcoleFicheExtended(props.organismeFormationId)
                .then(ecole => {
                    setOrganismeFormation(ecole)
                    setLogoId(ecole.logoId)
                    if (ecole.acronyme) {
                        setMainTitle(ecole.nomEcole + ' (' + ecole.acronyme + ')')
                    }
                    else {
                        setMainTitle(ecole.nomEcole)
                    }
                    setCampus(campusFilterDuplicate(ecole.campus?.sort(campusAlphaSort)))
                    setDescription(ecole.descriptionEcole)
                    setAvailable(true)
                    setLoading(false)
                })
                .catch(err => {
                    if (err.message == enumHttpError.NOLONGERAVAILABLE) {
                        setAvailable(false)
                        setLoading(false)
                    } else {
                        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 });
                    }
                })
        }
        else if (props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE) {
            composanteEcoleService.getComposanteEcoleFicheExtended(props.organismeFormationId)
                .then(composanteEcole => {
                    setOrganismeFormation(composanteEcole)
                    if (composanteEcole.logoId) {
                        setLogoId(composanteEcole.logoId)
                    }
                    else {
                        setLogoId(composanteEcole.ecole.logoId)
                    }
                    if (composanteEcole.acronyme) {
                        setMainTitle(composanteEcole.nom + ' (' + composanteEcole.acronyme + ')')
                    }
                    else {
                        setMainTitle(composanteEcole.nom)
                    }
                    if (composanteEcole.ecole.acronyme) {
                        setSubTitle(composanteEcole.ecole.nomEcole + ' (' + composanteEcole.ecole.acronyme + ')')
                    }
                    else {
                        setSubTitle(composanteEcole.ecole.nomEcole)
                    }
                    setCampus(campusFilterDuplicate(composanteEcole.ecole.campus?.sort(campusAlphaSort)))
                    setDescription(composanteEcole.descriptionComposanteEcole)
                    setAvailable(true)
                    setLoading(false)
                })
                .catch(err => {
                    if (err.message == enumHttpError.NOLONGERAVAILABLE) {
                        setAvailable(false)
                        setLoading(false)
                    } else {
                        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 });
                    }
                })
        } else {
            toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 });
        }

    }

    const goToOurCourses = () => {
        let filters = []
        if (props.organismeFormationCategorie == enumOrganismeFormationCategorie.ECOLE) {
            filters.push({ label: organismeFormation.nomEcole, value: organismeFormation.id.toString(), categorie: 9 })
        }
        else if (props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE) {
            filters.push({ label: organismeFormation.nom, value: organismeFormation.id.toString(), categorie: 8 })
        }
        history.push({
            pathname: '/rechercheAffinee',
            state: { filters }
        });
    }

    const goToWebsite = () => {
        var win = window.open(organismeFormation.siteInternetOfficiel)
        win.focus();
    }

    const onLinkedinClick = () => {
        var win = window.open(organismeFormation.pageLinkedin)
        win.focus();
    }

    const onTwitterClick = () => {
        var win = window.open(organismeFormation.pageTwitter)
        win.focus();
    }

    const onFacebookClick = () => {
        var win = window.open(organismeFormation.pageFacebook)
        win.focus();
    }

    const onInstagramClick = () => {
        var win = window.open(organismeFormation.pageInstagram)
        win.focus();
    }

    return (
        <div>
                {!loading ? <Helmet>
                <meta charSet="utf-8" />
                <meta name="robots" content="all" ></meta>
                <title>{mainTitle} - DiplomAdvisor</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content={t('metadesc.school', { ecole: mainTitle})}/>
            </Helmet> : ''}
            <Toast ref={toast} />
            {loading ? (
                <div style={{ textAlign: 'center', marginTop: '20%', marginBottom: '20%' }}><ProgressSpinner></ProgressSpinner></div>
            ) : (
                available ? (
                    <div className="ecole-global">
                        {organismeFormation.imageCouvertureId ? (
                            <BackgroundImageBottomEllipsis imageId={organismeFormation?.imageCouvertureId} alt={t('meta-img.alt-picto-metiers') + " " + mainTitle}/>
                        ) : (
                            <>
                            </>
                        )}
                        <div className={`${organismeFormation.imageCouvertureId && "cards-override-picture"} ${props.className} ecole-content`}>
                            <Card className="p-shadow-4 card-for-ecole-header">
                                <div className="vof-ecole-header">
                                    <div className="vof-ecole-left-container">
                                        {logoId && <div className="item-centering div-image-resized">
                                            <DisplayImage imageId={logoId} />
                                        </div>}
                                        <div className="ecole-info">
                                            <h1 className="vof-ecole-title dip-color-lb">{mainTitle}</h1>
                                            {subTitle && <h2 className="vof-ecole-title">{subTitle}</h2>}
                                            <div className="vof-ecole-socials-container">
                                                {organismeFormation?.pageLinkedin && <div ><IoLogoLinkedin className="dip-color-tc vof-io-logo p-mr-2" onClick={(e) => onLinkedinClick()} /></div>}
                                                {organismeFormation?.pageTwitter && <div><IoLogoTwitter className="dip-color-tc vof-io-logo  p-mr-2" onClick={(e) => onTwitterClick()} /></div>}
                                                {organismeFormation?.pageFacebook && <div><IoLogoFacebook className="dip-color-tc vof-io-logo  p-mr-2" onClick={(e) => onFacebookClick()} /></div>}
                                                {organismeFormation?.pageInstagram && <div><IoLogoInstagram className="dip-color-tc vof-io-logo  p-mr-2" onClick={(e) => onInstagramClick()} /></div>}
                                            </div>
                                            <div className="vof-course-container">
                                                <Button className="p-button p-button-rounded p-button-raised primary-color-border" onClick={(e) => goToOurCourses()} ><Trans i18nKey="school.check_our_courses" /></Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ecole-right-container">
                                        <div className="p-p-1  item-centering">
                                            {organismeFormation.referent
                                                && ((props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE && organismeFormation?.abonnementType != abonnements.SANSABONNEMENT)
                                                    || (props.organismeFormationCategorie == enumOrganismeFormationCategorie.ECOLE && organismeFormation?.composanteEcole && organismeFormation?.composanteEcole[0]?.abonnementType != abonnements.SANSABONNEMENT))
                                                && <Mailto
                                                    label={t('general.contact')}
                                                    className="p-button p-button-raised p-button-rounded primary-color-border dip-bg-color-tc dip-color-white"
                                                    object={""}
                                                    body={""}
                                                    to={organismeFormation.referent}
                                                />}
                                        </div>
                                        <div className="p-p-1  item-centering">
                                            {organismeFormation.siteInternetOfficiel && (
                                                RoleHelper.isSuperAdmin(currentUser)
                                                || RoleHelper.isAdmin(currentUser)
                                                || (props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE && organismeFormation?.abonnementType != abonnements.SANSABONNEMENT)
                                                || (props.organismeFormationCategorie == enumOrganismeFormationCategorie.ECOLE && organismeFormation?.composanteEcole && organismeFormation?.composanteEcole[0]?.abonnementType != abonnements.SANSABONNEMENT)
                                            )
                                                && <Button className="p-button p-button-rounded p-button-raised primary-color-border" onClick={(e) => goToWebsite()} ><Trans i18nKey="school.official_site" /></Button>}
                                        </div>
                                    </div>
                                </div>
                            </Card>

                            <br />

                            <Card className="p-shadow-4 vof-card">
                                <WysiwygResponsiveRenderer contentPC={description?.contenu} contentMobile={description?.contenuMobile} />
                            </Card>

                            <br />

                            <Card className="p-shadow-4 vof-card">
                                <h2 className="dip-color-id dip-m-0"><Trans i18nKey="school.campus" /></h2>
                                <p>
                                    {campus.map((campu, indexCampu) => (
                                        <Fragment key={`${campu}~${indexCampu}`}>
                                            {indexCampu < 5 && <div><CampusLocationFormater campus={campu} /></div>}
                                        </Fragment>
                                    ))}
                                    {showMoreCampus && campus.map((campu, indexCampu) => (
                                        <Fragment key={`${campu}~${indexCampu}`}>
                                            {indexCampu >= 5 && <div><CampusLocationFormater campus={campu} /></div>}
                                        </Fragment>
                                    ))}
                                    {campus.length > 5 && showMoreCampus == false && <div className="oespt-center">
                                        <Button className="p-button-raised dip-btn-red-bg" label={t('general.see_more')} onClick={() => setShowMoreCampus(true)} />
                                    </div>}
                                </p>
                            </Card>
                            <br />
                        </div>
                    </div>
                ) : (
                    <NoLongerAvailable></NoLongerAvailable>
                ))
            }


        </div>
    )
}