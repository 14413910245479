import React, { useState, useContext } from 'react';
import { Fragment } from 'react';
import { Button } from 'primereact/button';
import RechercheContext from './RechercheContext';
import Helper from '../../utils/helper';
import { AutoComplete } from 'primereact/autocomplete';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import "./listeElements.css"
import { enumCategorieRecherche } from '../../enums/enumCategorieRecherche';


export const ListeElements = (props) => {

    const { t } = useTranslation();
    const contextRecherche = useContext(RechercheContext);
    const [selectedValues, setSelectedValues] = useState(null);

    const handleRemoveValeur = (valeur) => {
        let _filters = []
        if(contextRecherche.lazyParams.filters){
            _filters = [...contextRecherche.lazyParams.filters];
        }
        const indexSupprime = Helper.findIndexByValueAndCategorie(_filters, valeur.value, valeur.categorie);
        _filters.splice(indexSupprime, 1);
        // si filtre est une école supprimer les diplomes et les formations
        if (valeur.categorie == contextRecherche.categorieRecherche.Composante || valeur.categorie == contextRecherche.categorieRecherche.Ecole) {
            let _indexToRemove = [];
            // seek diplome and formatio, to remove
            _filters.forEach((element, index) => {
                if (element.categorie == contextRecherche.categorieRecherche.Diplome || element.categorie == contextRecherche.categorieRecherche.Formation) {
                    _indexToRemove.push(index)
                }
            });
            //remove existing filte
            for (var i = _indexToRemove.length - 1; i >= 0; i--) {
                _filters.splice(_indexToRemove[i], 1);
            }
        }
        // si filtre est un diplome supprimer les formations
        if (valeur.categorie == contextRecherche.categorieRecherche.Diplome) {
            let _indexToRemove = [];
            // seek formation, to remove
            _filters.forEach((element, index) => {
                if (element.categorie == element.categorie == contextRecherche.categorieRecherche.Formation) {
                    _indexToRemove.push(index)
                }
            });
            //remove existing filte
            for (var i = _indexToRemove.length - 1; i >= 0; i--) {
                _filters.splice(_indexToRemove[i], 1);
            }
        }
        const _lazyParams = { ...contextRecherche.lazyParams };
        _lazyParams.filters = _filters;

        contextRecherche.setLazyParams(_lazyParams);
        contextRecherche.rechercheApi(_lazyParams);
    }

    const handleRemoveAllValues = () => {
        let _filters = [...contextRecherche.lazyParams.filters];
        _filters = _filters.filter(function (obj) {
            return props.values.indexOf(obj.categorie) == -1;
        });
        const _lazyParams = { ...contextRecherche.lazyParams };
        _lazyParams.filters = _filters;

        contextRecherche.setLazyParams(_lazyParams);
        contextRecherche.rechercheApi(_lazyParams);
    }

    const handleChange = (value) => {
        setSelectedValues(value);

    }

    const handleSearch = () => {
        if(selectedValues){
            let _filter = {label : selectedValues, value : selectedValues, categorie:enumCategorieRecherche.Formation}
            props.onChange(_filter)
            setSelectedValues('')
        }
        
    }

    return (
        <div>
            <div className='p-grid'>
                <div className='p-col-12 p-fluid'>
                    <div className="p-inputgroup le-input">
                        <InputText
                            minLength="3"
                            value={selectedValues}
                            placeholder={props.placeholder}
                            onChange={(e) => handleChange(e.target.value)}
                        />
                        <Button
                            icon="pi pi-search"
                            className="dip-btn-iris-bg"
                            onClick={handleSearch}
                        />
                    </div>

                </div>
                {contextRecherche.lazyParams.filters?.filter(o => props.values.includes(o.categorie)).length > 0 && props.canDeleteAll && <div className='p-col-12'>
                    <Button
                        className='ler-delete-all'
                        icon="pi pi-times"
                        label={t('search.erase_all')}
                        onClick={(e) => handleRemoveAllValues()}></Button>
                </div>}
                {contextRecherche.lazyParams.filters?.filter(o => props.values.includes(o.categorie)).length > 0 && <div className='p-col-12'>
                    {contextRecherche.lazyParams.filters?.filter(o => props.values.includes(o.categorie))?.sort((a, b) => (a.label > b.label) ? 1 : -1).map((valeur, indexValeur) => (
                        <Fragment key={`${valeur}~${indexValeur}`}>
                            <div className='dms-filter-item'>
                                {/* <div> */}
                                <Button
                                    label={valeur?.label}
                                    icon="pi pi-times"
                                    iconPos="right"
                                    className="p-button-rounded p-button-text p-mr-2 dmp-button-style"
                                    onClick={(e) => handleRemoveValeur(valeur)}
                                />
                                {/* </div> */}
                                {/* <span className='ler-filter-item-value'>{valeur?.label}</span> */}
                            </div>
                        </Fragment>
                    ))}
                </div>}
            </div>
        </div>
    );

}