import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DT_Formation } from "../../../components/datatable/dt_formation"
import { FormationDialog } from "../../../components/dialog/formationDialog"

export const FormationsOrphelinesPage = () => {

    const { t } = useTranslation();
    const [formationDialog, setFormationDialog] = useState(false);
    const [refreshToken, setRefreshToken] = useState(new Date());
    const [formationId, setFormationId] = useState(0);

    useEffect(() => {
    }, [])

    const hideDialog = () => {
        setFormationDialog(false);
    }

    const openEditFormationDialog = (formationId) => {
        setFormationId(formationId);
        setFormationDialog(true);
    }

    const triggerDataTableRefresh = () => {
        setRefreshToken(new Date());
    }
    
    return (
        <div>
            <FormationDialog visible={formationDialog} onHide={hideDialog} formationId={formationId} isDuplicate={false} onSave={triggerDataTableRefresh} canSaveAndVisualize />

            <DT_Formation
                ecoleId={null}
                title={t('formation.orphans_management.full')}
                openEditFormationDialog={openEditFormationDialog}
                refreshToken={refreshToken}
            />
        </div>
    )
}