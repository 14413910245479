import { Dropdown } from "primereact/dropdown"

export const CandidatStatutDropdown = (props) => {

    const statutOptions = [
        { label: "Nouveau candidat", value: 1 },
        { label: "Candidat en attente", value: 2 },
        { label: "Candidat contacté", value: 3 },
        { label: "Entretien passé", value: 4 },
        { label: "Candidat recruté", value: 5 },
        { label: "Candidat rejeté", value: 6 }
    ]

    return (
        <div>
            <div>Statut du candidat</div>
            <Dropdown className="ect-input" value={props.value} options={statutOptions} onChange={(e) => props.onChange(e)} optionLabel="label" />
        </div>
    )
}