import { SelectButton } from "primereact/selectbutton"
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next"
import { Wysiwyg } from "./wysiwyg";
import "./wysiwygMultiView.css";

export const WysiwygMultiView = (props) => {

    const { t } = useTranslation();

    const [selectValue, setSelectValue] = useState(1)

    const options = [
        {  value:1,name: t("wysiwyg.pc_view")},
        {  value: 2,name: t("wysiwyg.mobile_view") }
    ]

    return (
        <>
            <div className="dip-flex">
                <SelectButton className="wysiwyg-sb" value={selectValue} options={options} optionLabel="name" onChange={(e) => setSelectValue(e.value)} />
                <div className="dip-as-center"><Trans i18nKey="wysiwyg.view_explanation" /></div>
            </div>
            <br />
            {selectValue == 1 && <Wysiwyg entityType={props.entityType} entityId={props.entityId} value={props.valuePC} onChange={props.onChangePC} Conf={props.Conf} />}
            {selectValue == 2 && <Wysiwyg entityType={props.entityType} entityId={props.entityId} value={props.valueMobile} onChange={props.onChangeMobile} Conf={props.Conf} />}
        </>
    )
}