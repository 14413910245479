import { enumStatutFormation } from "../enums/enumStatutFormation";
import Helper from "./helper";

export default class FormationHelper {

    static nomCompletFormater(formation, parcoursSeparator) {
        if (formation) {
            return  (formation.diplomeLabel ? (formation.diplomeLabel) : (formation.diplome.nom)) + ' ' + formation.mention + (formation.mention !== formation.parcours && !Helper.isEmptyOrSpaces(formation?.parcours) ? (', ' + parcoursSeparator + formation?.parcours) : (''))
        }
        else {
            return "";
        }
    }

    static unifyAndOrderAllStatutFormation(formationAVenir, formationEnCours, formationSuivie, parcoursSeparator) {
        let formations = [];
        formationAVenir?.forEach(f => {
            let formation = {};
            formation.id = f.formation.id;
            formation.diplome = f.formation.diplome;
            formation.statut = enumStatutFormation.AVENIR;
            formation.anneeDiplome = null;
            formation.nomComplet = this.nomCompletFormater(f.formation, parcoursSeparator)
            formation.organismeFormation = f.formation.composanteEcole[0]
            if (formation.organismeFormation) {
                formation.organismeFormation.type = 1;
            }
            formation.formationEtudiantId = f.id;
            formations.push(formation);
        })
        formationEnCours?.forEach(f => {
            let formation = {};
            formation.id = f.id;
            formation.diplome = f.diplome;
            formation.statut = enumStatutFormation.ENCOURS;
            formation.anneeDiplome = null;
            formation.nomComplet = this.nomCompletFormater(f, parcoursSeparator)
            formation.organismeFormation = f.composanteEcole[0]
            if (formation.organismeFormation) {
                formation.organismeFormation.type = 1;
            }
            formations.push(formation);
        });
        formationSuivie.sort((a, b) =>
            b.anneeDiplome - a.anneeDiplome
        )
        formationSuivie?.forEach(f => {
            let formation = {};
            formation.id = f.formation.id;
            formation.diplome = f.formation.diplome;
            formation.statut = enumStatutFormation.SUIVIE;
            formation.anneeDiplome = f.anneeDiplome;
            formation.nomComplet = this.nomCompletFormater(f.formation, parcoursSeparator)
            formation.organismeFormation = f.formation.composanteEcole[0]
            if (formation.organismeFormation) {
                formation.organismeFormation.type = 1;
            }
            formation.formationEtudiantId = f.id;
            formations.push(formation);
        });
        return formations
    }

    static unifyDemandeFormation(demandeFormation) {
        let demandeFormationUnifyFormat = []
        demandeFormation?.forEach(f => {
            let formation = {};
            formation.id = f.id;
            formation.organismeFormation = f.ecoleDemandee;
            formation.diplome = f.diplomeDemandee;
            formation.formation = f.formationDemandee
            formation.statut = f.statut;
            formation.anneeDiplome = f.anneeDemandee || null;
            formation.dateCreation = f.dateCreation;
            demandeFormationUnifyFormat.push(formation);
        });
        return demandeFormationUnifyFormat;
    }

    static dispatchFormationsOnEtudiant(etudiant, formations, formationDemande) {
        let formationSuivie = [];
        let formationEnCours = [];
        let formationAVenir = [];
        let demandeFormation = [];
        formations.forEach(f => {
            switch (f.statut) {
                case enumStatutFormation.SUIVIE:
                    formationSuivie.push(
                        {
                            Id: f.formationEtudiantId,
                            etudiantId: etudiant.id,
                            anneeDiplome: f.anneeDiplome,
                            formationId: f.id
                        }
                    )
                    break;
                case enumStatutFormation.ENCOURS:
                    formationEnCours.push(
                        {
                            Id: f.id
                        }
                    )
                    break;
                case enumStatutFormation.AVENIR:
                    formationAVenir.push(
                        {
                            Id: f.formationEtudiantId,
                            etudiantId: etudiant.id,
                            formationId: f.id
                        }
                    )
                    break;
                default:
                    break;
            }
        });
        formationDemande.forEach(f => {
            demandeFormation.push({ id: f.id || 0, ecoleDemandee: f.organismeFormation, diplomeDemandee: f.diplome, formationDemandee: f.formation, anneeDemandee: f.anneeDiplome, statut: f.statut, dateCreation: f.dateCreation, etudiantId: etudiant.id });
        });
        etudiant.demandeFormation = demandeFormation;
        etudiant.formationSuivie = formationSuivie
        etudiant.formationEnCours = formationEnCours
        etudiant.formationAVenir = formationAVenir
        return etudiant
    }



    static nomCompletFormaterUrl(formation) {
        if (formation) {
            return formation.diplome.nom + ' ' + formation.mention + (formation.mention !== formation.parcours && !Helper.isEmptyOrSpaces(formation?.parcours) ? ("_Parcours_" + formation?.parcours) : (''))
        }
        else {
            return "";
        }
    }

    static nomCompletFormaterUrlFlat(formation) {
        if (formation) {
            return formation.diplome + ' ' + formation.mention + (formation.mention !== formation.parcours && !Helper.isEmptyOrSpaces(formation?.parcours) ? ("_Parcours_" + formation?.parcours) : (''))
        }
        else {
            return "";
        }
    }
    static buildFormationPath(composante, title) {
        var composantLocal = "Inconnue";
        var titleLocal = "-";

        if (composante && composante != "") {
            composantLocal = composante.replace(/\s+/g, '_').normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/[\W_]/g, "_").replace(/[#_]/g, '_').substring(0, 1000)
        }

        if (title && title != "") {
            titleLocal = title.replace(/\s+/g, '_').normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/[\W_]/g, "_").replace(/[#_]/g, '_').substring(0, 1000)
        }

        return "/" + composantLocal + "/" + titleLocal;
    }
}
