import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import { EntrepriseOffreBreadCrumb } from "../../components/breadCrumb/EntrepriseOffreBreadCrumb";
import { FilterPanelOffers } from "../../components/filterPanel/filterPanelOffers";
import { EntrepriseOffreEmploiThumbnail } from "../../components/thumbnail/entrepriseOffreEmploiThumbnail";
import OffreEmploiService from "../../services/offreEmploiService";
import Helper from "../../utils/helper";
import "./entrepriseOffres.css"
import RechercheEntrepriseOffres from "./RechercheEntrepriseOffres";
import { ThumbnailPaginator } from "../../components/paginator/thumbnailPaginator";
import UserService from "../../services/userService";
import { useAuthState } from "../../context/context";
import RoleHelper from "../../utils/roleHelper";
import EntrepriseService from "../../services/entrepriseService";

export const EntrepriseOffres = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const userService = new UserService()
    const currentUser = useAuthState();
    const [offres, setOffres] = useState([])
    const [totalOffresRecords, setTotalOffresRecords] = useState(null)
    const [tokenResetFilters, setTokenResetFilters] = useState(null);
    const [displayFilterPanel, setDisplayFilterPanel] = useState(false)
    const [displayConfirmPaiment, setDisplayConfirmPaiment] = useState(false)
    const [loading, setLoading] = useState(true)
    const [displayDialog, setDisplayDialog] = useState(false);
    const [entreprise, setEntreprise] = useState(null)
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 6,
        page: 1,
        sortField: 'Pertinence',
        sortOrder: 1,
        filters: history.location?.state?.filters || []
    });
    const offreEmploiService = new OffreEmploiService();
    const entrepriseService = new EntrepriseService();
    const toast = useRef(null)

    useEffect(() => {
        getEntrepriseAndAbonnementInfo();
        if (history.location.state?.confirmPaiment)
            setDisplayConfirmPaiment(true);
    }, [])
    useEffect(() => {
        loadLazyData(lazyParams);
    }, [lazyParams])

    const onAddClick = () => {
        history.push({ pathname: '/edition_annonce', state: { adminImpersonatingRecruteurId: adminImpersonatingRecruteurId } })
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const loadLazyData = (_lazyParams) => {
        setLoading(true);
        setTotalOffresRecords(null);
        entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id) => {
            offreEmploiService.getOffreEmploiPaginated(_lazyParams, id)
                .then((data) => {
                    data.items.forEach(offer => {
                        offer.dateCreation = Helper.dateTimeToLocaleDateTime(offer.dateCreation)
                    });
                    setOffres(data.items);
                    setTotalOffresRecords(data.totalCount);
                })
                .catch(err => showError())
                .finally(() => setLoading(false));
        })
            .catch(err => {
                showError()
                setLoading(false)
            })

    }

    const getEntrepriseAndAbonnementInfo = () => {
        entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id) => {
            userService.getEntreprise(id)
                .then((_entreprise) => {
                    setEntreprise(_entreprise)
                    // si c'est une première connection on affiche une popup
                    if (RoleHelper.isEntreprise(currentUser)) {
                        // if (_entreprise.isRegistrationOnboardingCompleted == false) {
                        //     setDisplayDialog(true);
                        //     _entreprise.isRegistrationOnboardingCompleted = true;
                        //     userService.updateEntreprise(currentUser, _entreprise)
                        //         .then((_entreprise) => {
                        //             // à modifier et optimiser
                        //         })
                        // }
                    }
                    else if (history.location.hash === '#payment_success') {
                        setDisplayDialog(true);
                    }
                });
        })
    }

    const contextRecherche = {
        lazyParams,
        setLazyParams,
        rechercheApi: loadLazyData,
        totalOffresRecords,
        tokenResetFilters
    }

    return (
        <RechercheEntrepriseOffres.Provider value={contextRecherche}>
            <div className={props.className}>
                {/* first connection dialog */}
                <Dialog className="fcp-popup-first-connection" header={t('profile.first_connection_title_offers')} visible={displayDialog} style={{ width: '50vw' }} onHide={() => setDisplayDialog(false)}>
                    <div className="p-ml-3 p-mr-4 eo-popup-message">
                        <Trans i18nKey="profile.first_connection_message_offers" />
                    </div>
                    <div className='p-d-flex p-jc-end p-mt-2'>
                        <Button label={t('general.ok')} className="p-mt-4 fcp-button-popup-first-connection" onClick={() => setDisplayDialog(false)} />
                    </div>
                </Dialog>
                {/* confirm paiment dialog  A MODIFIER */}
                <Dialog className="fcp-popup-first-connection" header={t('profile.first_connection_title_offers')} visible={displayConfirmPaiment} style={{ width: '50vw' }} onHide={() => setDisplayConfirmPaiment(false)}>
                    <div className="p-ml-3 p-mr-4 eo-popup-message">
                        <Trans i18nKey="profile.first_connection_message_offers" />
                    </div>
                    <div className='p-d-flex p-jc-end p-mt-2'>
                        <Button label={t('general.ok')} className="p-mt-4 fcp-button-popup-first-connection" onClick={() => setDisplayConfirmPaiment(false)} />
                    </div>
                </Dialog>
                <Toast ref={toast} />
                <EntrepriseOffreBreadCrumb step={0} />
                <div className="dip-flex" >
                    <div className="eo-filter-panel-margin eo-only-pc">
                        <FilterPanelOffers />
                    </div>
                    <div className="dip-w-100" >
                        <div className="dip-ta-right">
                            {/* <i className="dip-color-grey">1/1 offre(s) d'emploi créée(s)</i> */}
                        </div>
                        <div className="dip-flex-row dip-jc-between p-mb-3">
                            <div id="top"><h1 className="dip-color-res-blue eo-title"><Trans i18nKey="entreprise.myOffers" /> {totalOffresRecords && <span>({totalOffresRecords})</span>}</h1></div>
                            <div className="dip-as-center dip-flex-column">
                                <Button className="dip-btn-red-bg dip-only-pc" label="Ajouter une offre" icon="pi pi-plus" onClick={onAddClick} />
                                <Button className="dip-btn-red-bg dip-only-mobile" label="Ajouter" icon="pi pi-plus" onClick={onAddClick} />
                            </div>
                        </div>
                        <div>
                            {loading ? (
                                <div className="dip-ta-center">
                                    <ProgressSpinner />
                                </div>
                            ) : offres?.length > 0 ? (
                                <div>
                                    {offres?.map((offre, indexOffre) => (
                                        <Fragment key={`${offre.id}~${indexOffre}`}>
                                            <EntrepriseOffreEmploiThumbnail offreEmploi={offre} isInAList contextRecherche={contextRecherche} />
                                        </Fragment>
                                    ))}
                                    <ThumbnailPaginator lazyParams={lazyParams} setLazyParams={setLazyParams} totalRecords={totalOffresRecords} />
                                </div>
                            ) : t('search.no_results_matching')}
                        </div>

                    </div>
                </div>
                <div className='p-jc-center p-mt-2 refined-search-float-button'>
                    <div>
                        <Button className="mspf-button-label p-shadow-8" onClick={() => setDisplayFilterPanel(true)} >
                            <span >{t('general.filters')}</span>
                        </Button>
                        <Dialog visible={displayFilterPanel} className="mpsf-dialog" onHide={() => setDisplayFilterPanel(false)}>
                            <FilterPanelOffers />
                        </Dialog>
                    </div>
                </div>
            </div>
        </RechercheEntrepriseOffres.Provider>
    )
}