export const enumRoles = {
    USER : "User",
    FRESHUSER : "FreshUser",
    SUPERADMINISTRATEUR : "SuperAdministrateur",
    ADMINISTRATEUR : "Administrateur",
    ENTREPRISE : "Entreprise",
    ADMINISTRATEURECOLE : "AdministrateurEcole",
    EDITEURECOLE : "EditeurEcole",
    ETUDIANT : "Etudiant",
    ADMINISTRATEURCOMPOSANTE : "AdministrateurComposante",
    ENTREPRISE : "Entreprise"
}