import React, { useState, useEffect, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import UserService from '../../services/userService';
import { useAuthState } from "../../context/context";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import './dt_editeurs.css';

export const DT_Editeur = () => {

    let emptyEditeur = {
        id: 0,
        nom: '',
        prenom: '',
        email: '',
        phoneNumber: '',
        fonctions: '',
        ecole: [],
        nbFormations: 0
    };

    const { t } = useTranslation();
    const [newFormationDialog, setNewFormationDialog] = useState(true);
    const [editeurs, setEditeurs] = useState([]);
    const [editeurDialog, setEditeurDialog] = useState(false);
    const [deleteEditeurDialog, setDeleteEditeurDialog] = useState(false);
    const [deleteEditeursDialog, setDeleteEditeursDialog] = useState(false);
    const [editeur, setEditeur] = useState(emptyEditeur);
    const [selectedEditeurs, setSelectedEditeurs] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const userService = new UserService();
    const currentUser = useAuthState();
    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    });
    const isMounted = useRef(false);



    useEffect(() => {
        isMounted.current = true;
        loadLazyData();
    }, [lazyParams]);


    const loadLazyData = () => {
        setLoading(true);

        userService.getEditeurs(currentUser, { lazyEvent: JSON.stringify(lazyParams) }).then(_editeurs => {

            setEditeurs(_editeurs);

            setLoading(false);

            setNewFormationDialog(false);
        });
    }

    const validate = (editor) => {
        let errors = {};

        if (!editor.nom) {
            errors.nom = <Trans i18nKey="editor.lastname_required" />;
        }

        if (!editor.prenom) {
            errors.prenom = <Trans i18nKey="editor.firstname_required" />;
        }

        if (!editor.email) {
            errors.email = <Trans i18nKey="editor.email_required" />;
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(editor.email)) {
            errors.email = <Trans i18nKey="general.invalid_email" />;
        }

        return errors;
    };

    const openNew = () => {
        setEditeur(emptyEditeur);
        setSubmitted(false);
        setEditeurDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEditeurDialog(false);
    }

    const hideDeleteEditeurDialog = () => {
        setDeleteEditeurDialog(false);
    }

    const hideDeleteEditeursDialog = () => {
        setDeleteEditeursDialog(false);
    }

    const saveEditeur = () => {
        setSubmitted(true);

        if (editeur.nom.trim() && editeur.prenom.trim() && editeur.email.trim() && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(editeur.email))) {
            let _editeurs = [...editeurs];
            let _editeur = { ...editeur };
            if (editeur.id) {
                (async function updateAdmin() {
                    await userService.edit(_editeur);
                    const index = findIndexById(editeur.id);
                    _editeurs[index] = _editeur;

                    setEditeurs(_editeurs);
                    setEditeurDialog(false);
                    setEditeur(emptyEditeur);

                    toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('editor.updated_editor'), life: 3000 });

                })();
            }
            else {

                (async function addAdmin() {
                    let _editor = await userService.createOrUpdateEditeur(currentUser, editeur);
                    if (_editor != undefined) {
                        _editeur.id = _editor.id;
                        //console.log(JSON.stringify(_editeur));
                        _editeurs.push(_editeur);
                        setEditeurs(_editeurs);

                        setEditeurDialog(false);
                        setEditeur(emptyEditeur);

                        toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('editor.created_editor'), life: 3000 });

                    }
                    else {
                        setEditeurDialog(false);
                        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('editor.not_created_editor'), life: 3000 });

                    }

                })();


            }

        }
    }

    const editEditeur = (editeur) => {
        setEditeur({ ...editeur });
        setEditeurDialog(true);
    }

    const confirmDeleteEditeur = (editeur) => {
        setEditeur(editeur);
        setDeleteEditeurDialog(true);
    }

    const deleteEditeur = () => {
        (async function deleteAdmin() {
            let _editeur = { ...editeur };
            await userService.delete(_editeur);
            let _editeurs = editeurs.filter(val => val.id !== editeur.id);
            setEditeurs(_editeurs);
            setDeleteEditeurDialog(false);
            setEditeur(emptyEditeur);
            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('editor.deleted_editor'), life: 3000 });

        })();
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < editeurs.length; i++) {
            if (editeurs[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const confirmDeleteSelected = () => {
        setDeleteEditeursDialog(true);
    }

    const deleteSelectedEditeurs = () => {
        (async function deleteAdmin() {
            let _editeurs = editeurs.filter(val => !selectedEditeurs.includes(val));
            selectedEditeurs.forEach(editor => {
                userService.delete(editor);
            });
            setEditeurs(_editeurs);
            setDeleteEditeursDialog(false);
            setSelectedEditeurs(null);
            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('editor.deleted_editors'), life: 3000 });
        })();
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _editeur = { ...editeur };
        _editeur[`${name}`] = val;

        setEditeur(_editeur);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label={t('editor.new_editor')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} disabled={newFormationDialog} />
                <Button label={t('general.delete')} icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedEditeurs || !selectedEditeurs.length} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editEditeur(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteEditeur(rowData)} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="title"><Trans i18nKey="editor.editors_management.full" /></h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
            </span>
        </div>
    );
    const editeurDialogFooter = (
        <React.Fragment>
            <Button label={t('general.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={t('general.save')} icon="pi pi-check" className="p-button-text" onClick={saveEditeur} />
        </React.Fragment>
    );
    const deleteEditeurDialogFooter = (
        <React.Fragment>
            <Button label={t('general.no')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteEditeurDialog} />
            <Button label={t('general.yes')} icon="pi pi-check" className="p-button-text" onClick={deleteEditeur} />
        </React.Fragment>
    );
    const deleteEditeursDialogFooter = (
        <React.Fragment>
            <Button label={t('general.no')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteEditeursDialog} />
            <Button label={t('general.yes')} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedEditeurs} />
        </React.Fragment>
    );

    return (
        <div className="datatable-editeurs">
            <Toast ref={toast} />

            <div className="card">
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>

                <DataTable ref={dt} emptyMessage={t('editor.no_editor_to_show')} value={editeurs} selection={selectedEditeurs} onSelectionChange={(e) => setSelectedEditeurs(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={t('editor.footer_count_pagin')}
                    globalFilter={globalFilter}
                    header={header}
                    loading={loading}
                >

                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="nom" header={t('editor.lastname')} sortable></Column>
                    <Column field="fonctions" header={t('editor.functions')} sortable></Column>
                    <Column field="nbFormations" header={t('editor.formationsNb')} sortable></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </div>

            <Dialog visible={editeurDialog} style={{ width: '50rem' }} header={t('editor.details_editor')} modal className="p-fluid" footer={editeurDialogFooter} onHide={hideDialog}>
                {editeur.image && <img src={`showcase/demo/images/editeur/${editeur.image}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={editeur.image} className="editeur-image" />}
                <div className="p-field">
                    <label htmlFor="nom"><Trans i18nKey="editor.lastname" /></label>
                    <InputText id="nom" value={editeur.nom} onChange={(e) => onInputChange(e, 'nom')} required autoFocus className={classNames({ 'p-invalid': submitted && !editeur.nom })} />
                    {submitted && !editeur.nom && <small className="p-error"><Trans i18nKey="general.required" /></small>}
                </div>
                <div className="p-field">
                    <label htmlFor="prenom"><Trans i18nKey="editor.firstname" /></label>
                    <InputText id="prenom" value={editeur.prenom} onChange={(e) => onInputChange(e, 'prenom')} required rows={3} cols={20} className={classNames({ 'p-invalid': submitted && !editeur.prenom })} />
                    {submitted && !editeur.prenom && <small className="p-error"><Trans i18nKey="general.required" /></small>}
                </div>
                <div className="p-field">
                    <label htmlFor="fonctions"><Trans i18nKey="editor.functions" /></label>
                    <InputText id="fonctions" value={editeur.fonctions} onChange={(e) => onInputChange(e, 'fonctions')} rows={3} cols={20} />
                </div>
                <div className="p-field">
                    <label htmlFor="email"><Trans i18nKey="editor.email" /></label>
                    <InputText id="email" value={editeur.email} onChange={(e) => onInputChange(e, 'email')} required rows={3} cols={20} className={classNames({ 'p-invalid': submitted && (!editeur.email || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(editeur.email))) })} />
                    {submitted && !editeur.email && <small className="p-error"><Trans i18nKey="general.required" /> </small>}
                    {submitted && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(editeur.email)) && <small className="p-error"><Trans i18nKey="general.invalid_email" /></small>}
                </div>
                <div className="p-field">
                    <label htmlFor="phoneNumber"><Trans i18nKey="editor.phone" /></label>
                    <InputText id="phoneNumber" value={editeur.phoneNumber} onChange={(e) => onInputChange(e, 'phoneNumber')} rows={3} cols={20} />
                </div>
            </Dialog>

            <Dialog visible={deleteEditeurDialog} style={{ width: '50rem' }} header={t('general.confirm')} modal footer={deleteEditeurDialogFooter} onHide={hideDeleteEditeurDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {editeur && <span><Trans i18nKey="editor.are_you_sure_delete_editor" /><b>{editeur.prenom} {editeur.nom.toUpperCase()} ({editeur.email})</b><Trans i18nKey="general.question_symbol" /></span>}
                </div>
            </Dialog>

            <Dialog visible={deleteEditeursDialog} style={{ width: '450rem50px' }} header={t('general.confirm')} modal footer={deleteEditeursDialogFooter} onHide={hideDeleteEditeursDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {editeur && <span><Trans i18nKey="editor.are_you_sure_delete_selected_editors" /></span>}
                </div>
            </Dialog>
        </div>
    );
}