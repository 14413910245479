import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import './App.css';
import './theme/theme.css'
import './theme/form.css'
import './theme/buttons.css'
import './theme/dip-theme.css'
import './theme/dip-wysiwyg.css'
import PrimeReact, { addLocale } from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import routes from './config/routes';
import { AuthProvider } from './context/context';
import { AppRoutes } from './components/appRoutes';
import ScrollToTop from './components/scrollToTop';
import { StudentRegistrationOnboardingDialog } from './components/RegistrationOnboarding/StudentRegistrationOnboardingDialog';
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module';

PrimeReact.ripple = true;
PrimeReact.autoZIndex = true;

export const Page = () => {

  addLocale('fr', {
    firstDayOfWeek: 1,
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
    dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'decembre'],
    monthNamesShort: ['jan', 'fev', 'mar', 'avr', 'mai', 'jun', 'jul', 'aou', 'sep', 'oct', 'nov', 'dec'],
    today: 'Ajourd\'hui',
    clear: 'Vider'
  });

  const [onBoardingDialogVisible, setOnBoardingDialogVisible] = useState(false)

  useEffect(() => {

    if (process.env.REACT_APP_GTM_ID) {
      window.GTM_ID = process.env.REACT_APP_GTM_ID;
    }
    if (window.GTM_ID) {

      const tagManagerArgs = {
        gtmId: window.GTM_ID
      }
      console.log("GTM-INIT");
      TagManager.initialize(tagManagerArgs)
    }
  }, []);

  return (
    <AuthProvider>
      <Helmet htmlAttributes={{lang:'fr'}}>
        <title>DiplomAdvisor</title>
        <meta charset="utf-8" />
        <meta name="robots" content="nofollow" />
        <meta name="description" content="Le 1er moteur de recherche de formations de l'enseignement supérieur" />
      </Helmet>
      <Router>
        {/* <CookiesDialog></CookiesDialog> */}

        <ScrollToTop >
          <Switch>
            {routes.map((route) => (
              <AppRoutes
                key={route.path}
                path={route.path}
                component={route.component}
                needsLogin={route.needsLogin}
                needsRoles={route.needsRoles}
                hasDashboard={route.hasDashboard}
                hasDashboardOnlyMobile={route.hasDashboardOnlyMobile}
                className={route.className}
                hasFooter={route.hasFooter}
                hasWidthLimit={route.hasWidthLimit}
                redirect={route.redirect}
                showOnboarding={() => setOnBoardingDialogVisible(true)}
                headerTheme={route.headerTheme}
                backgroundTheme={route.backgroundTheme}
              />
            ))}
          </Switch>
          {onBoardingDialogVisible && <StudentRegistrationOnboardingDialog visible={onBoardingDialogVisible} setVisible={setOnBoardingDialogVisible} />}
        </ScrollToTop >
      </Router>
    </AuthProvider>
  );
}

// here app catches the suspense from page in case translations are not yet loaded
export const App = () => {
  return (
      <Page />
  );
}

