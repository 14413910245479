import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Button } from 'primereact/button';
import recrutementsLogo from '../../../images/accueil/entreprise/recrutementsLogo.png'
import tdbLogo from '../../../images/accueil/entreprise/tdbLogo.png'
import messagerieLogo from '../../../images/accueil/entreprise/messagerieLogo.png'
import { useHistory } from 'react-router';

export const BodyHomeEntreprise = () => {

    const { t } = useTranslation();
    const history = useHistory();

    const handleClickMyRecruitments = () => {
        history.push('/recrutements');
    }

    const handleClickMyDashboard = () => {
        history.push('/dashboard');
    }

    const handleClickMyMessaging = () => {
        history.push('/dashboard');
    }

    const handleClickFormations = () => {
        history.push('/rechercheAffinee');
    }

    return (
        <div className="catchphrase-modules">
            <div className="catchphrase-logo">
                <img src={recrutementsLogo} alt="" />
            </div>
            <div className="catchphrase-logo">
                <img src={tdbLogo} alt="" />
            </div>
            <div className="catchphrase-logo">
                <img src={messagerieLogo} alt="" />
            </div>
            <div className="catchphrase-button">
                <Button label={t('home.my_recruitments')} className="p-button-outlined p-button-danger p-button-rounded bhe-button" onClick={() => handleClickMyRecruitments()} />
            </div>
            <div className="catchphrase-button">
                <Button label={t('home.my_dashboard')} className="p-button-outlined p-button-danger p-button-rounded bhe-button" onClick={() => handleClickMyDashboard()} />
            </div>
            <div className="catchphrase-button">
                <Button label={t('home.the_courses')} className="p-button-outlined p-button-danger p-button-rounded bhe-button" onClick={() => handleClickFormations()} />
            </div>
        </div>
    )
};