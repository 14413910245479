import React, { useEffect, useState, useRef } from 'react';

import './register.css';
import { enumRoles } from '../../enums/enumRoles';
import { useHistory } from 'react-router-dom';
import boyStudyingDesk from '../../images/accueil/boyStudyingDesk.png';
import companyPicture from '../../images/3D/3d-casual-life-looking-through-resumes.png';
import { TabPanel, TabView } from 'primereact/tabview';
import { FormLogin } from '../../components/form/formLogin';
import { FormRegister } from '../../components/form/formRegister';
import { Trans, useTranslation } from 'react-i18next';
import { ButtonGoBack } from '../../components/buttonGoBack/buttonGoBack';

export const Register = (props) => {

    const [userType, setUserType] = useState(null);
    const [preSetFormRegister, setPreSetFormRegister] = useState(null);
            
            
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState(history.location.pathname.toLowerCase() == "/login" ? 0 : 1);
    const [urlCandidate, setUrlCandidate] = useState(null)
    const { t } = useTranslation();

    const op = useRef(null)

    useEffect(() => {
        const query = new URLSearchParams(history.location.search);
        const userType = query.get('User');

        switch (history.location.state?.userType) {
            case enumRoles.ETUDIANT:
                setUserType(enumRoles.ETUDIANT);
                break;
            case enumRoles.ENTREPRISE:
                setUserType(enumRoles.ENTREPRISE);
                break;
            default:
                if (userType && userType?.toLowerCase() == "student") {
                    setUserType(enumRoles.ETUDIANT);
                    if(query.get('Email')){
                        setPreSetFormRegister({
                            email: decodeURIComponent(query.get('Email')),
                            prenom: query.get('Prenom'),
                            nom: query.get('Nom'),
                            telephone: decodeURIComponent(query.get('Telephone'))
                        })
                    }
                }
                else if (userType && userType?.toLowerCase() == "company") {
                    setUserType(enumRoles.ENTREPRISE);

                    const nomEntrepriseUrl = query.get('NomEntreprise') ? decodeURIComponent(query.get('NomEntreprise')) : null;
                    const emailUrl = query.get('Email') ? decodeURIComponent(query.get('Email')) : null;
                    if(nomEntrepriseUrl && emailUrl){
                        setPreSetFormRegister({nomEntreprise: nomEntrepriseUrl, email: emailUrl})
                    }
                }
                else {
                    history.push('/');
                }
                break;
        }

        if(history.location.state?.candidatUrl){
            setActiveIndex(0)
            setUrlCandidate(history.location.state?.candidatUrl)
        }
    }, [history.location.state])

    return (
        <div className={`main  ${props.className}`}>
            <ButtonGoBack backgroundColor={props.backgroundTheme} />
            <div className='p-grid container-register'>
                <div className='p-col p-mb-4 ss-container-register'>
                    {userType == enumRoles.ETUDIANT && <p>{t('role.youre_not_connected_etudiant_title')}</p>}
                    {userType == enumRoles.ENTREPRISE && <p>{t('role.youre_not_connected_entreprise_title')}</p>}
                    <h1>{t('role.youre_not_connected_subscribe')}</h1>
                </div>
                <div className='p-col'>
                    {userType == enumRoles.ETUDIANT && <img src={boyStudyingDesk} />}
                    {userType == enumRoles.ENTREPRISE && <img src={companyPicture} />}
                </div>
                <div className='p-col'>
                    <div className={`p-d-flex frs-style r-form-container`}>
                        <div className={`r-container-card hal-${userType?.toLowerCase()}`}>
                            <TabView className={`auth-tab-container r-title-content hal-${userType?.toLowerCase()}`} activeIndex={activeIndex} setActiveIndex={(e) => setActiveIndex(e.index)}>
                                <TabPanel header={t('login.log_in')}>
                                    <FormLogin op={op} showOnboarding={props.showOnboarding} userType={userType} urlBackLogin={urlCandidate} />
                                </TabPanel>
                                <TabPanel header={t('login.create_account')}>
                                    <FormRegister className="auth-fieldset-nullifier" op={op} userType={userType} showOnboarding={props.showOnboarding} preSetFormRegister={preSetFormRegister} />
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
