import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import { EntrepriseOffreBreadCrumb } from "../../components/breadCrumb/EntrepriseOffreBreadCrumb";
import { FilterPanelCandidates } from "../../components/filterPanel/filterPanelCandidates";
import { EntrepriseCandidatThumbnail } from "../../components/thumbnail/entrepriseCandidatThumbnail";
import Helper from "../../utils/helper";
import RechercheEntrepriseCandidats from "./RechercheEntrepriseCandidats";
import EntrepriseService from "../../services/entrepriseService";
import ProjetService from "../../services/projetService";
import { CandidatStatutSelectButton } from "../../components/selectButton/candidatStatutSelectButton";
import { EntrepriseProfilBreadCrumb } from "../../components/breadCrumb/EntrepriseProfilBreadCrumb";

export const ProfilsRecherche = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [offreEmploiEtudiants, setOffreEmploiEtudiants] = useState([])
    const [totalCandidatsRecords, setTotalCandidatsRecords] = useState(null);
    const [statutFilter, setStatutFilter] = useState(history.location?.state?.statut);
    const [tokenResetFilters, setTokenResetFilters] = useState(null);
    const [displayFilterPanel, setDisplayFilterPanel] = useState(false);
    const [loading, setLoading] = useState(true);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 6,
        page: 0,
        sortField: 'Pertinence',
        sortOrder: 1,
        filters: history.location?.state?.filters || []
    });
    const entrepriseService = new EntrepriseService();
    const projetService = new ProjetService();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;

    const toast = useRef(null)

    useEffect(() => {
        loadOffreEmploi(lazyParams);
    }, [])

    function formatDate(inputDate) {
        const date = new Date(inputDate);

        // Obtenez le jour, le mois et l'année de la date
        const day = date.getDate();
        const month = date.getMonth() + 1
        const year = date.getFullYear();

        // Formatez les parties de la date pour qu'elles aient toujours deux chiffres
        const formattedDay = String(day).padStart(2, '0');
        const formattedMonth = String(month).padStart(2, '0');

        // Créez la chaîne de date au format 'JJ/MM/AAAA'
        const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

        return formattedDate;
    }

    function parseDate(dateString) {
        const parts = dateString.split("/");
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
    }

    const loadOffreEmploi = (_lazyParams) => {
        setLoading(true)
        entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id) => {
            entrepriseService.getEntrepriseEtudiant(id)
                .then(ee => {
                    ee.forEach(e => {
                        e.dateAjout = Helper.dateTimeToLocaleDateTime(e.dateAjout)
                        if (e.etudiant.rechercheActiveCalendrier)
                            e.etudiant.rechercheActiveCalendrier = Helper.dateTimeToLocaleDateTime(e.etudiant.rechercheActiveCalendrier)
                    });

                    if (_lazyParams.filters.length > 0) {
                        // alors on filtre en fonction de la categorie présente dans chaque tableau
                        _lazyParams.filters.forEach(f => {
                            switch (f.categorie) {
                                case 17:
                                    // calendrier
                                    const formattedDate = formatDate(f.value);
                                    ee = ee.filter(e => {
                                        if (!e.etudiant.rechercheActiveCalendrier) {
                                            // Si l'étudiant n'a pas de disponibilité définie, le filtrer
                                            return false;
                                        }
                                        return parseDate(e.etudiant.rechercheActiveCalendrier) <= parseDate(formattedDate);
                                    });
                                    break;
                                case 206:
                                    // projet 
                                    projetService.getEtudiantByProjet(f.value)
                                        .then((data) => {
                                            // on tri les etudiants en fonction de leur projet
                                            ee = ee.filter(e => data.some(d => d.etudiantId === e.etudiantId));
                                            setTotalCandidatsRecords(ee.length)
                                            setOffreEmploiEtudiants(ee)
                                        })
                                    break;
                                default:
                                    break;
                            }
                        })
                    }
                    setTotalCandidatsRecords(ee.length)
                    setOffreEmploiEtudiants(ee)
                })
                .finally(() => setLoading(false))
        })
    }

    const contextRecherche = {
        lazyParams,
        setLazyParams,
        rechercheApi: loadOffreEmploi,
        totalCandidatsRecords,
        tokenResetFilters
    }

    return (
        <RechercheEntrepriseCandidats.Provider value={contextRecherche}>
            <div className={props.className}>
                <Toast ref={toast} />
                <EntrepriseProfilBreadCrumb step={0} />
                <div className="dip-flex">
                    <div className="eo-filter-panel-margin eo-only-pc">
                        <FilterPanelCandidates />
                    </div>
                    <div className="dip-w-100">
                        <div className="dip-flex-row dip-jc-between p-mb-1">
                            <div><h1 className="dip-color-res-blue">
                                <Trans i18nKey="company.profile.plural" /> {<span>({offreEmploiEtudiants?.filter(oee => { if (statutFilter) return oee.statut == statutFilter; else return true }).length})</span>}</h1></div>
                        </div>
                        <div>
                            {loading ? (
                                <div className="dip-ta-center">
                                    <ProgressSpinner />
                                </div>
                            ) : (
                                <>
                                    <CandidatStatutSelectButton
                                        entity={offreEmploiEtudiants}
                                        value={statutFilter}
                                        setValue={(value) => setStatutFilter(value)}
                                    />
                                    <br />
                                    {offreEmploiEtudiants?.filter(oee => { if (statutFilter) return oee.statut == statutFilter; else return true }).map((oee, index) => (
                                        <Fragment key={`${index}`}>
                                            <EntrepriseCandidatThumbnail candidat={oee} loadOffreEmploi={e => loadOffreEmploi(e)} lazyParams={lazyParams} isInAList />
                                        </Fragment>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className='p-jc-center p-mt-2 refined-search-float-button'>
                    <div>
                        <Button className="mspf-button-label p-shadow-8" onClick={() => setDisplayFilterPanel(true)} >
                            <span >{t('general.filters')}</span>
                        </Button>
                        <Dialog visible={displayFilterPanel} className="mpsf-dialog" onHide={() => setDisplayFilterPanel(false)}>
                            <FilterPanelCandidates />
                        </Dialog>
                    </div>
                </div>
            </div>
        </RechercheEntrepriseCandidats.Provider>
    )
}