import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { DisplayProfileImage } from "../image/displayProfileImage"
import "./offreEmploiPrimaryThumbnail.css"
import { Tag } from 'primereact/tag';
import { Trans, useTranslation } from "react-i18next";
import { LikeOffreEmploi } from "../likeoffreemploi/likeoffreemploi";
import { JobLocationFormater } from "../formater/jobLocationFormater";
import logoPE from '../../images/logoPE.png'
import logoFranceTravail from '../../images/France-travail-2023.png'
import { useAuthState } from "../../context/context";
import RoleHelper from "../../utils/roleHelper";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";


export const OffreEmploiPrimaryThumbnail = (props) => {

    const history = useHistory();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;
    const { t } = useTranslation();
    const currentUser = useAuthState();
    const [showRightPart, setShowRightPart] = useState(false)

    useEffect(() => {
        let _splittedTypeContrat = []
        if (props.offreEmploi?.typeContratLibelle) {
            _splittedTypeContrat = props.offreEmploi?.typeContratLibelle.split(' - ');
        }
        if (props.offreEmploi.dateDebutSouhaite ||
            props.offreEmploi.dureeContrat ||
            _splittedTypeContrat.length == 2 ||
            props.offreEmploi.tempsTravailHebdo ||
            props.offreEmploi.dureeTravailLibelle ||
            props.offreEmploi.alternance) {
            setShowRightPart(true)
        }

    }, [props])

    const onShareClick = (rowData) => {
        navigator.clipboard.writeText(window.location.origin + '/vueOffreEmploi?offreEmploiId=' + rowData.id)
        props.toast.current.show({ severity: 'success', summary: t('advert.share_advert_success'), life: 3000 });
    }

    const onEditClick = (rowData) => {
        history.push({ pathname: '/edition_annonce', state: { id: rowData } })
    }

    const renderEntreprise = () => {
        if (props.offreEmploi?.providerId == 1 && props.offreEmploi?.entreprise?.nomEntreprise) {
            return <>{props.offreEmploi?.entreprise?.nomEntreprise + ' - '}</>
        } else if (props.offreEmploi?.providerId == 2 && props.offreEmploi?.entreprisePE) {
            return <>{props.offreEmploi?.entreprisePE + ' -  '}</>
        } else {
            return <></>
        }
    }

    const renderTypeContrat = () => {
        if (props.offreEmploi.alternance) {
            return <Tag className="tag-alternance" value={t('offers.alternance').toUpperCase()} />;
        }
        else if (props.offreEmploi.natureContrat?.code == "ST") {
            return <Tag className="tag-stage" value={t('offers.traineeship').toUpperCase()} />;
        } else if (props.offreEmploi.natureContrat?.code == "E1") {
            if (props.offreEmploi.typeContrat?.code == "CDI") {
                return <Tag className="tag-emploi" value={"CDI".toUpperCase()} />
            }
            else {
                return <Tag className="tag-emploi" value={"CDD".toUpperCase()} />;
            }
        }
        else {
            return <span></span>
        }
    }

    const renderNiveauEtude = () => {
        let _exigence;
        if (props.offreEmploi?.providerId == 1) {
            switch (props.offreEmploi?.niveauDiplomeExigence) {
                case 1:
                    _exigence = t('requirement.wished.m').toLowerCase()
                    break;
                case 2:
                    _exigence = t('requirement.required.m').toLowerCase()
                    break;
                default:
                    break;
            }
            switch (props.offreEmploi?.niveauDiplome) {
                case 0:
                    return <><Trans i18nKey="advert.study_level" /> {_exigence} : Collège / Lycée</>;
                case 3:
                    return <><Trans i18nKey="advert.study_level" /> {_exigence} : CAP / BEP</>;
                case 4:

                    return <><Trans i18nKey="advert.study_level" /> {_exigence} : Bac</>;
                case 5:

                    return <><Trans i18nKey="advert.study_level" /> {_exigence} : Bac+2</>;
                case 6:

                    return <><Trans i18nKey="advert.study_level" /> {_exigence} : Bac+3</>;
                case 7:

                    return <><Trans i18nKey="advert.study_level" /> {_exigence} : Bac+5</>;
                case 8:

                    return <><Trans i18nKey="advert.study_level" /> {_exigence} : Bac+8</>;
                case 9:

                    return <><Trans i18nKey="advert.study_level" /> {_exigence} : Bac+1</>;
                case 10:

                    return <><Trans i18nKey="advert.study_level" /> {_exigence} : Bac+4</>;
                case 11:

                    return <><Trans i18nKey="advert.study_level" /> {_exigence} : Bac+6</>;
                case 12:

                    return <><Trans i18nKey="advert.study_level" /> {_exigence} : Bac+7</>;

                default:
                    return <></>;
            }
        }
        else {
            if (props.offreEmploi?.offreEmploiFormationPoleEmploi?.length > 0) {
                switch (props.offreEmploi?.offreEmploiFormationPoleEmploi[0].exigence) {
                    case 1:
                        _exigence = t('requirement.wished.m').toLowerCase()
                        break;
                    case 2:
                        _exigence = t('requirement.required.m').toLowerCase()
                        break;
                    default:
                        break;
                }
                return <><Trans i18nKey="advert.study_level" /> {_exigence} : {props.offreEmploi?.offreEmploiFormationPoleEmploi[0].formationPoleEmploi.niveauLibelle}</>
            }
            else {
                return <></>
            }
        }
    }

    const renderExperience = () => {
        if (props.offreEmploi?.experienceLibelle) {
            switch (props.offreEmploi?.experienceExigeOffreEmploi) {
                case 1:
                    return <><Trans i18nKey="offers.experience" /> {t('requirement.wished.f').toLowerCase()} : <span>{props.offreEmploi?.experienceLibelle}</span></>;
                case 2:
                    return <><Trans i18nKey="offers.experience" /> {t('requirement.required.f').toLowerCase()} : <span>{props.offreEmploi?.experienceLibelle}</span></>;
                default:
                    return <></>;
            }
        }
    }

    const renderDureeContrat = () => {
        if (props.offreEmploi.providerId == 1) {
            if (props.offreEmploi.dureeContrat) {
                return <span><b><Trans i18nKey="offers.duration.colons" /></b>{props.offreEmploi.dureeContrat} <Trans i18nKey="general.months" /></span>
            } else {
                return <></>
            }

        }
        else {
            let _splittedInfo = props.offreEmploi?.typeContratLibelle.split(' - ');
            if (_splittedInfo !== undefined && _splittedInfo !== null) {
                if (_splittedInfo.length == 1) {
                    return <></>
                } else if (_splittedInfo.length == 2) {
                    return <span><b><Trans i18nKey="offers.duration.colons" /></b>{_splittedInfo[1]}</span>
                }
            }
            else {
                return <></>
            }
        }
    }

    const renderTempsTravail = () => {
        if (props.offreEmploi.providerId == 1) {
            switch (props.offreEmploi.tempsTravailHebdo) {
                case 1:
                    return <span><b><Trans i18nKey="advert.work_time.colons" /></b><Trans i18nKey="advert.full_time" /></span>;
                case 2:
                    return <span><b><Trans i18nKey="advert.work_time.colons" /></b><Trans i18nKey="advert.part_time" /></span>;
                default:
                    return <></>;
            }
        }
        else {
            if (props.offreEmploi.dureeTravailLibelle) {
                return <span><b><Trans i18nKey="advert.work_time.colons" /></b>{props.offreEmploi.dureeTravailLibelle}</span>;
            }
            else {
                return <></>;
            }
        }
    }

    const renderAlternance = () => {
        if (props.offreEmploi.alternance) {
            return <span><b className="p-mr-1"><Trans i18nKey="formation.alternation.colons" /></b>{props.offreEmploi.natureContrat.libelle}</span>;
        } else {
            return <></>
        }
    }


    return (
        <div className={props.className}>
            <Card className="oept-card p-shadow-5">
                <div className={`oept-main-container ${showRightPart ? ("oept-grey-bg") : ("oept-white-bg")}`}>
                    <div className={`oept-profile`}>
                        {RoleHelper.isEtudiant(currentUser) && <div className={`oept-like-in-title`}><LikeOffreEmploi offreEmploi={props.offreEmploi} toast={props.toast} /></div>}
                        <div className="oept-logo">
                            {props.offreEmploi.providerId == 1 ? (
                                <>
                                    {props.offreEmploi.entreprise?.logoId && <DisplayProfileImage className="logo-max-size" imageId={props.offreEmploi.entreprise?.logoId} />}
                                </>
                            ) : (
                                <>
                                    {props.offreEmploi.origineOffre?.origine == 1 && <img className="logo-max-size" src={logoFranceTravail}></img>}
                                    {props.offreEmploi.origineOffre?.origine == 2 && <img className="logo-max-size" src={props.offreEmploi.origineOffre.partenaire[0]?.logo}></img>}
                                    {!props.offreEmploi.origineOffre?.partenaire[0]?.logo && props.offreEmploi.origine == 2 && <img src="Error.src"></img>}
                                </>
                            )}
                        </div>

                        <div className={`oept-info-container `}>
                            <div className="oept-title-container">
                                <h1>{props.offreEmploi.intitule}</h1>
                            </div>
                            <div className="oept-subtitle">{renderEntreprise()}<JobLocationFormater offreEmploi={props.offreEmploi}></JobLocationFormater></div>

                            <div className="oept-contract-container">{renderTypeContrat()}</div>
                            <div>{renderNiveauEtude()}</div>
                            <div>{renderExperience()}</div>
                            <br />
                            <div className="p-mt-1 oept-italic">
                                <span><Trans i18nKey="offers.published_on" values={{ date: props.offreEmploi.dateCreation }} /></span>
                            </div>

                        </div>
                    </div>
                    <div className="oept-right-container dip-flex-column">
                        <div className="oept-like-container">
                            {RoleHelper.isEtudiant(currentUser) &&
                                <LikeOffreEmploi offreEmploi={props.offreEmploi} toast={props.toast} />
                            }
                            <Button icon="pi pi-share-alt" className="p-button-rounded p-button-warning" onClick={() => onShareClick(props.offreEmploi)} disabled={props.offreEmploi.statut == "Archivee"} />
                            {(RoleHelper.isEntreprise(currentUser) || adminImpersonatingRecruteurId) &&
                                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-ml-1" onClick={() => onEditClick(props.offreEmploi.id)} disabled={props.offreEmploi.statut == "Archivee"} />
                            }
                        </div>
                        <div>{props.offreEmploi.dateDebutSouhaite && <span><b><Trans i18nKey="offers.from_date" /></b>{props.offreEmploi.dateDebutSouhaite}</span>}</div>
                        <div>{renderDureeContrat()}</div>
                        <div>{renderTempsTravail()}</div>
                        <div>{renderAlternance()}</div>
                        <div className="oept-postuler-container dip-ml-auto">
                            <Button
                                className='dip-btn-red-outline p-button-rounded p-pr-5 p-pl-5 p-mr-1 dip-only-mobile'
                                label={t('offers.matching_courses.light')}
                                onClick={props.handleConsulterFormations} />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}
