import axios from 'axios';
import BaseService from './baseService';

export default class ImportExportService extends BaseService {

  getFormations() {
    return this.axiosApiInstance.get(window.API_URL + '/api/importExport/Formations')
      .then(res => super.downloadFile(res.data, 'Export formation.csv'))
      .catch(err => super.handleHttpError(err));
  }

  getOffreEmplois() {
    return this.axiosApiInstance.get(window.API_URL + '/api/importExport/OffresEmploi')
      .then(res => super.downloadFile(res.data, 'Export offre emplois.csv'))
      .catch(err => super.handleHttpError(err));
  }

  importROME(currentUser,csvROME) {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + currentUser.token
      }
    };
    const formData = new FormData();
    formData.append('csvRomeCustom', new Blob([csvROME], { type: 'text/csv' }), csvROME.name);
    return axios.post(window.API_URL + '/api/importExport/ImportRomeCustom', formData, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRebuildIndexFunnelMetier() {
    return this.axiosApiInstance.get(window.API_URL + '/api/importExport/RebuildFunnelMetierIndex')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  importAppellationCompetence(currentUser,csvAC) {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + currentUser.token
      }
    };
    const formData = new FormData();
    formData.append('csvAC', new Blob([csvAC], { type: 'text/csv' }), csvAC.name);
    return axios.post(window.API_URL + '/api/importExport/ImportAppellationCompetence', formData, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  importDuplicationFormation(currentUser,csvF) {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + currentUser.token
      }
    };
    const formData = new FormData();
    formData.append('csvF', new Blob([csvF], { type: 'text/csv' }), csvF.name);
    return axios.post(window.API_URL + '/api/importExport/ImportDuplicationFormation', formData, options)
    .then(res => super.downloadFile(res.data, 'ImportDuplicationResultat.csv'))
      .catch(err => super.handleHttpError(err));
  }

}
