import React, { useRef, useState, useEffect, Fragment } from 'react';
import './offresCompatibles.css';
import { useHistory } from "react-router";
import { ProgressSpinner } from 'primereact/progressspinner';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { Trans, useTranslation } from 'react-i18next';
import { enumHttpError } from '../enums/enumHttpError.js';
import OffreEmploiService from '../services/offreEmploiService.js';
import Helper from '../utils/helper.js';
import parse from 'html-react-parser';
import { OffreEmploiThumbnail } from '../components/thumbnail/offreEmploiThumbnail.js';
import { ThumbnailPaginator } from '../components/paginator/thumbnailPaginator';


export const OffresCompatibles = (props) => {
    const history = useHistory();
    const toast = useRef(null);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [offresEmploi, setOffresEmploi] = useState([]);
    const [error, setError] = useState(null);

    const offreEmploiService = new OffreEmploiService();

    const urlParams = new URLSearchParams(window.location.search);
    const search = parseInt(urlParams.get('pageProfil'));
    const itemsPerPage = 12;
    const firstItemIndex = (search && !isNaN(search)) ? (itemsPerPage * (search - 1)) : 0;
    const adminImpersonatingEtudiantId = history.location?.state?.adminImpersonatingEtudiantId;

    const [lazyParams, setLazyParams] = useState({
        first: firstItemIndex,
        rows: itemsPerPage,
        page: search && !isNaN(search) ? parseInt(search) : 1,
        sortField: 'dateCreation'
    })

    const [urlPagination, setUrlPagination] = useState({
        profil: search ? search : 1,
        formation: 1,
        favorites: 1
    });

    const viewOptions = [
        { value: 'profil', name: t('offers.matching_on_profile') },
        { value: 'formation', name: t('offers.matching_on_formation') },
        { value: 'favorites', name: t('offers.favorites_offers') }
    ];

    const [view, setView] = useState(viewOptions[0].value);

    useEffect(() => {
        // on vérifie si la recherche est présente dans l'url et que la valeur est un chiffre
        if (!search){
            window.history.pushState({}, '', window.location.pathname + '?pageProfil=1&pageFormation=1&pageFavorites=1');
        }
        else{
            // si search est un chiffre on met à jour les paramètres de la recherche
            setLazyParams({
                ...lazyParams,
                page: search && !isNaN(search) ? parseInt(search) : 1,
                first: firstItemIndex
            });
        }

        return () => {
            // cleanup
            setOffresEmploi([]);
        }
    }, []);

    useEffect(() => {
        loadLazyData();
    }, [view, lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        let httpPromise;

        // on met à jour l'url avec la page courante
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(paginationSwitchName(view), lazyParams.page);
        const updatedUrl = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({}, '', updatedUrl);
        setUrlPagination({
            ...urlPagination,
            [view]: lazyParams.page
        });    

        if (view === 'profil')
            httpPromise = offreEmploiService.getMatchingOffersOnProfile(lazyParams, adminImpersonatingEtudiantId);
        else if (view === 'favorites')
            httpPromise = offreEmploiService.getMatchingOffersOnFavorite(lazyParams, adminImpersonatingEtudiantId);
        else
            httpPromise = offreEmploiService.getMatchingOffersOnFormation(lazyParams, adminImpersonatingEtudiantId);



        httpPromise
            .then(_pagedjobOffers => {
                _pagedjobOffers.items.forEach(offer => {
                    offer.dateCreation = Helper.dateTimeToLocaleDateTime(offer.dateCreation)
                });
                setTotalCount(_pagedjobOffers.totalCount)
                setOffresEmploi(_pagedjobOffers.items);
                setLoading(false);
            })
            .catch((err) => {
                switch (err.message) {
                    // case enumHttpError.USERNOTINACTIVESEARCH:
                    //     setError(enumHttpError.USERNOTINACTIVESEARCH)
                    //     setLoading(false);
                    //     break;
                    case enumHttpError.CANCELED:
                        setError(enumHttpError.CANCELED)
                        break;
                    default:
                        setError('failed');
                        setLoading(false);
                        break;
                }
            });
        // .finally(() => setLoading(false));
    }

    const changeTab = (viewName) => {
        setView(viewName);
        setOffresEmploi([]);
        setError(null)
        const urlParams = new URLSearchParams(window.location.search);
        const currentUrlPage = parseInt(urlParams.get(paginationSwitchName(viewName)));
        if (currentUrlPage && !isNaN(currentUrlPage)) {
            setLazyParams({
                ...lazyParams,
                page: currentUrlPage,
                first: itemsPerPage * (currentUrlPage - 1)
            });
        } else {
            setLazyParams({
                ...lazyParams,
                page: 1,
                first: 0
            });
        }
    }

    const paginationSwitchName = (viewName) => {
        switch (viewName) {
            case 'profil':
                return 'pageProfil';
            case 'formation':
                return 'pageFormation';
            case 'favorites':
                return 'pageFavorites';
            default:
                return 'pageProfil';
        }
    }

    const onUnlike = (indexOE) => {
        if (view === 'favorites') {
            let _offresEmploi = [...offresEmploi]
            _offresEmploi.splice(indexOE, 1);
            setOffresEmploi(_offresEmploi)
            setTotalCount(totalCount - 1);
        }
    }

    const gtNbOffreEmploi = () => {
        var date = new Date();

        return 270000 + (Number(date.getFullYear()) + (Number(date.getMonth()) * 123) + Number(date.getDay()))
    }

    return (
        <div className={props.className}>
            <h2><Trans i18nKey='offers.page_title.full' /></h2>
            <div className="p-grid mt-5 p-mb-4" id='top'>
                <SelectButton className="p-col-12 select-matching" unselectable={false} style={{ textAlign: 'center' }} value={view} options={viewOptions} optionLabel="name" onChange={(e) => changeTab(e.value)} />
            </div>
            <div>
                <div>
                    <div className='p-grid'>
                        {offresEmploi.map((offreEmploi, indexOE) => (
                            <Fragment key={`${offreEmploi.id}~${indexOE}`}>
                                <div className='p-col-12 p-sm-12 p-md-6 p-lg-3'>
                                    <OffreEmploiThumbnail offreEmploi={offreEmploi} onUnlike={e => onUnlike(indexOE)} urlPagination={urlPagination} />
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
                {offresEmploi?.length > 0 &&
                    <ThumbnailPaginator lazyParams={lazyParams} setLazyParams={setLazyParams} totalRecords={totalCount} />
                }
                
                {loading && view !== 'favorites' && <div className="center-element-oc"><div className="col-md-12">{parse(t("offers.looking_into_x_offer", { nbOffreEmploi: gtNbOffreEmploi() }))}</div><div className="col-md-12"><ProgressSpinner /></div></div>}
                {loading && view === 'favorites' && <div className="center-element-oc"><ProgressSpinner /></div>}
                {/* {!loading && offresEmploi.length == 0 && error == enumHttpError.USERNOTINACTIVESEARCH && <div className="center-element-oc "><Trans i18nKey="offers.change_active_search_status" /></div>} */}
                {!loading && offresEmploi.length == 0 && error == 'failed' && <div className="center-element-oc"><Trans i18nKey="offers.server_connexion_error" /></div>}
                {!loading && offresEmploi.length == 0 && view === 'formation' && error != 'failed' && error != enumHttpError.USERNOTINACTIVESEARCH && error != enumHttpError.CANCELED && <div className="center-element-oc">{parse(t("offers.no_match"))}</div>}
                {!loading && offresEmploi.length == 0 && view === 'favorites' && error != 'failed' && error != enumHttpError.USERNOTINACTIVESEARCH && error != enumHttpError.CANCELED && <div className="center-element-oc">{parse(t("offers.no_favorites"))}</div>}
            </div>
            <Toast ref={toast} />
        </div >
    )
}
