import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ComposanteService from "../../services/composanteService";
import { DT_Composantes_actions } from "./dt_composantes_actions";


export const DT_Composante = (props) => {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalCount] = useState(0);
    const [data, setData] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 25,
        page: 0,
        sortField: 'nom',
        sortOrder: 1
    });

    const dt = useRef(null);

    const { t } = useTranslation();

    const composanteService = new ComposanteService();

    useEffect(() => {
        loadLazyData();
    }, [lazyParams, props.refreshToken]) // eslint-disable-line react-hooks/exhaustive-deps


    const loadLazyData = () => {
        setLoading(true);
        composanteService.getComposantesPaginated(lazyParams,props.ecoleId)
            .then(data => {
                setTotalCount(data.totalCount);
                setData(data.items)
            })
            .catch(err => showError())
            .finally(() => setLoading(false))

    }

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        _lazyParams['page'] = 0;
        _lazyParams['first'] = 0;
        setLazyParams(_lazyParams);
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <DT_Composantes_actions
                    composanteEcole={rowData}
                />
            </>
        )
    }

    const headerTemplate = (
        <div className="table-header">
            <h5 className="title">{props.title}</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
            </span>
        </div>
    );

    return (
        <div>
            <Toast ref={toast} />
            <div>
                <div className="card">
                    <DataTable ref={dt} value={data} lazy
                        filterDelay={1000}
                        paginator first={lazyParams.first} rows={lazyParams.rows} totalRecords={totalRecords} onPage={onPage}
                        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                        onFilter={onFilter} filters={lazyParams.filters} loading={loading}
                        emptyMessage={t('composante.no_composante_to_show')}
                        globalFilter={globalFilter} header={headerTemplate}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                        currentPageReportTemplate={t('composante.footer_count_pagin')}
                    >
                        <Column field="nom" header={t('school.name')} sortable></Column>
                        <Column field="nbFormations" header={t('school.formationsNb')} sortable></Column>
                        <Column field="nbEtudiants" header={t('school.studentsNb')} sortable></Column>
                        <Column headerStyle={{ width: '14rem' }} body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div >
    );

}
