import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import { EntrepriseOffreBreadCrumb } from "../../components/breadCrumb/EntrepriseOffreBreadCrumb";
import { FilterPanelCandidates } from "../../components/filterPanel/filterPanelCandidates";
import { EntrepriseCandidatThumbnail } from "../../components/thumbnail/entrepriseCandidatThumbnail";
import { EntrepriseOffreEmploiThumbnail } from "../../components/thumbnail/entrepriseOffreEmploiThumbnail";
import OffreEmploiService from "../../services/offreEmploiService";
import Helper from "../../utils/helper";
import "./entrepriseCandidats.css"
import RechercheEntrepriseCandidats from "./RechercheEntrepriseCandidats";
import { useAuthState } from "../../context/context"
import EntrepriseService from "../../services/entrepriseService";

export const EntrepriseCandidats = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const currentUser = useAuthState();
    const [offre, setOffre] = useState(null);
    const [offreEmploiEtudiants, setOffreEmploiEtudiants] = useState([])
    const [totalCandidatsRecords, setTotalCandidatsRecords] = useState(null);
    const [statutFilter, setStatutFilter] = useState(history.location?.state?.statut);
    const [tokenResetFilters, setTokenResetFilters] = useState(null);
    const [displayFilterPanel, setDisplayFilterPanel] = useState(false);
    const [loading, setLoading] = useState(true);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 6,
        page: 0,
        sortField: 'Pertinence',
        sortOrder: 1,
        filters: []
    });
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId
    const offreEmploiService = new OffreEmploiService();
    const entrepriseService = new EntrepriseService();

    const toast = useRef(null)

    useEffect(() => {
        setLoading(true)
        loadOffreEmploi(lazyParams);
    }, [])

    const loadOffreEmploi = (_lazyParams) => {
        offreEmploiService.getOffreEmploiEntepriseVignette(history.location.state?.id)
            .then(_offre => {
                entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id) => {
                    offreEmploiService.getOffreEmploiEtudiantsOfOffreEmploi(history.location.state.id, _lazyParams, id).then(oee => {
                        oee.forEach(e => {
                            e.dateAjout = Helper.dateTimeToLocaleDateTime(e.dateAjout)
                            if (e.etudiant.rechercheActiveCalendrier)
                                e.etudiant.rechercheActiveCalendrier = Helper.dateTimeToLocaleDateTime(e.etudiant.rechercheActiveCalendrier)
                        });
                        _offre.dateCreation = _offre?.dateCreation ? (Helper.dateTimeToLocaleDateTime(_offre?.dateCreation)) : null
                        setTotalCandidatsRecords(oee.length)
                        _offre.offreEmploiEtudiants = oee
                        setOffre(_offre)
                        setOffreEmploiEtudiants(oee)
                    })
                    .finally(() => setLoading(false))
                })
            })

    }

    const contextRecherche = {
        lazyParams,
        setLazyParams,
        rechercheApi: loadOffreEmploi,
        totalCandidatsRecords,
        tokenResetFilters
    }

    return (
        <RechercheEntrepriseCandidats.Provider value={contextRecherche}>
            <div>
                <Toast ref={toast} />
                <EntrepriseOffreBreadCrumb step={1} />
                <div className="dip-flex">
                    <div className="eo-filter-panel-margin eo-only-pc">
                        <FilterPanelCandidates offreEmploiId={offre?.id} />
                    </div>
                    <div className="dip-w-100">
                        <div className="dip-flex-row dip-jc-between p-mb-1">
                            <div><h1 className="dip-color-res-blue"><Trans i18nKey="company.candidate.plural" /> {<span>({offreEmploiEtudiants?.filter(oee => { if (statutFilter) return oee.statut == statutFilter; else return true }).length})</span>}</h1></div>

                        </div>
                        <div>
                            {loading ? (
                                <div className="dip-ta-center">
                                    <ProgressSpinner />
                                </div>
                            ) : (
                                <>
                                    <EntrepriseOffreEmploiThumbnail offreEmploi={offre} contextRecherche={contextRecherche} setStatutFilter={setStatutFilter} />
                                    {offreEmploiEtudiants?.filter(oee => { if (statutFilter) return oee.statut == statutFilter; else return true }).map((oee, index) => (
                                        <Fragment key={`${index}`}>
                                            <EntrepriseCandidatThumbnail candidat={oee} toast={toast} isInAList />
                                        </Fragment>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className='p-jc-center p-mt-2 refined-search-float-button'>
                    <div>
                        <Button className="mspf-button-label p-shadow-8" onClick={() => setDisplayFilterPanel(true)} >
                            <span >{t('general.filters')}</span>
                        </Button>
                        <Dialog visible={displayFilterPanel} className="mpsf-dialog" onHide={() => setDisplayFilterPanel(false)}>
                            <FilterPanelCandidates offreEmploiId={offre?.id} />
                        </Dialog>
                    </div>
                </div>
            </div>
        </RechercheEntrepriseCandidats.Provider>
    )
}