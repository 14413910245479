import { useEffect } from "react";
import { useLocation } from "react-router";

export const DisplayImage = (props) => {
  
  useEffect(() => {  
  });

  return (
      <img className={props.className} src={window.API_URL +  "/api/image/" + props.imageId} alt={props.alt}/>
  );
};
