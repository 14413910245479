import React, { useState, useEffect, useRef } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import Helper from '../../utils/helper';
import FormationService from '../../services/formationService';

export const FormationSelector = (props) => {

    const service = new FormationService();
    const { t } = useTranslation();
    const toast = useRef(null);

    const [filteredFormation, setFilteredFormation] = useState([props.value]);

    const nomCompletFormater = (formation) => {
        if (formation) {
            return formation.diplome.nom + ' ' + formation.mention + (formation.mention !== formation.parcours && !Helper.isEmptyOrSpaces(formation?.parcours) ? (', ' + t("formation.parcours_separator") + formation?.parcours) : (''))
        }
        else {
            return "";
        }
    }

    const searchFormation = (event) => {
        if (typeof (props.organismeFormation) === 'object' && typeof (props.diplome) === 'object') {
            service.getFormationFiltered(event.query, props.organismeFormation, props.diplome)
                .then(res => {
                    res.forEach(f => {
                        f.nomComplet = nomCompletFormater(f);
                    });
                    if (res.length == 0) {
                        props.onFound(false);
                    }
                    else {
                        props.onFound(true);
                    }
                    setFilteredFormation(res);
                })
                .catch((err) => {
                    props.onFound(false)
                    toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 });
                })
                .finally(() => {
                }
                );
        } else {
            setFilteredFormation([]);
        }
    }

    const onFormationChange = (val) => {
        props.onChange(val);

    }

    const template = (item) => {
        return (
            <span className="ler-item" >
                {item?.nomComplet}
            </span>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <AutoComplete
                className={props.className}
                value={props.value}
                suggestions={filteredFormation}
                panelClassName="ler-autcomplete-panel"
                forceSelection={props.forceSelection}
                dropdown={typeof (props.organismeFormation) === 'object' && typeof (props.diplome) === 'object'}
                completeMethod={searchFormation}
                onChange={e => onFormationChange(e.value)}
                field='nomComplet'
                placeholder={props.placeholder}
                disabled={props.disabled}
                itemTemplate={template}
                minLength={3}
                appendTo="self"
            />
        </>
    );

}