import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Trans } from 'react-i18next';
import 'primeflex/primeflex.css';
import { Dialog } from 'primereact/dialog';
import { useHistory } from 'react-router';
import UserService from '../services/userService';
import computerIcon from '../../src/images/GlassComputer.svg';
import AuthService from '../services/authService';
import {ActivateAccountDialog} from  '../scenes/activateAccountDialog'


export const ActivateAccount = (props) => {
    const [showMessage, setShowMessage] = useState(false);
    const [showMessageError, setShowMessageError] = useState(false);
    const [showDialogActivation, setShowDialogActivation] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const search = props.location.search;
    const params = new URLSearchParams(search);
    //const email = params.get('email');
    //const token = params.get('token');
    const token = encodeURIComponent(params.get('token'));
    const email = encodeURIComponent(params.get('email'));
    const urlRedirect = encodeURIComponent(params.get('urlRedirect'));

    const authService = new AuthService();
    const userService = new UserService();
    const [loading, setLoading] = useState(false);
    const isMounted = useRef(false);






    const loadLazyData = () => {
        setLoading(true);
        if(token == "null" || email == "null")
        {
            redirectHome();
            return;
        }
        userService.activateaccount(email, token)
        .then(reponse => {
            setShowMessage(true);

        })
        .catch(error => {
            setShowMessageError(true);

        });
    };

    useEffect(() => {
        isMounted.current = true;
        loadLazyData();
    }, []);

    const redirectHome = () => {
        setShowMessage(false);

        if (urlRedirect != null && urlRedirect != "" && urlRedirect != "null") { 
            history.push(decodeURIComponent(urlRedirect)); 
        }
        else {
            history.push('/');
        }
    }

    const redirectNewMail = () => {
        setShowMessageError(false);

        if (urlRedirect != null && urlRedirect != "" && urlRedirect != "null") { 
            history.push(decodeURIComponent(urlRedirect)); 
        }
        else {
            history.push('/');
        }
    }


    const askValidationEmail =() => {
        setShowMessageError(false)
        setShowDialogActivation(true);
        authService.askValidationEmail(decodeURIComponent(email))
        .then(async response => {
        })
        .catch(error => {
            props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.try_again') });
        });
    }

    const askValidationEmailCLose =() => {
        setShowDialogActivation(false);
        if (urlRedirect != null && urlRedirect != "" && urlRedirect != "null") { 
            history.push(decodeURIComponent(urlRedirect)); 
        }
        else {
            history.push('/');
        }
    }


    const dialogFooter = <div className="plain-button p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => redirectHome()} /></div>;
    const dialogFooterError = <div className="plain-button p-d-flex p-jc-center"><Button label="Recevoir un lien d'activation" className="p-button-text" autoFocus onClick={() => askValidationEmail()} /></div>;

    return (
        <div className="form-activate-account">
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5><Trans i18nKey="general.successful_registration" /></h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        <Trans i18nKey="register.activated_account" />
                    </p>
                </div>
            </Dialog>
            {/* <Dialog visible={showMessageError} onHide={() => askValidationEmail()} position="top" footer={dialogFooterError} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
                <embed   src={computerIcon} ></embed >
                    <h5><Trans i18nKey="register.activated_account_fail" /></h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        <Trans i18nKey="register.activated_account_fail_detail" />
                    </p>
                </div>
            </Dialog> */}

            { showMessageError  && <div>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
                <h2 className='' style={{color: 'var(--terra-cotta)' }}><Trans i18nKey="register.activated_account_fail" /></h2>
                <embed   src={computerIcon} ></embed >
                    {/* <i className="pi pi-envelope" style={{ fontSize: '5rem', color: 'var(--terra-cotta)' }}></i> */}
                   
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        <Trans i18nKey="register.activated_account_fail_detail" />
                    </p>
                
                </div>
                {dialogFooterError}
            </div>}

            <ActivateAccountDialog show={showDialogActivation} email={decodeURIComponent(email)} closeEvent={() => askValidationEmailCLose()}></ActivateAccountDialog>
        </div>
    );
}
