import React, { useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Trans, useTranslation } from "react-i18next";
import { useAuthState } from "../../context/context";
import 'primeflex/primeflex.css';
import { MenuSearchTooltip } from '../../scenes/rechercheAffinee/menu/menuSearchTooltip';
import { ProgressSpinner } from 'primereact/progressspinner';
import "./importExport.css"
import { Import } from './import';

export const ImportExport = (props) => {
    const toast = useRef(null);

    const { t } = useTranslation();

    return (
        <div>
            <Toast ref={toast}></Toast>
            <div className='p-grid'>
                <div className='p-col-12 p-sm-12 p-lg-6'>
                    {/* <div className="table-header">
                        <h5 className="title dip-flex-row">{props.importTitle}{props.tooltipText && <MenuSearchTooltip className="p-ml-2" text={t('administrator.import_rome_tooltip')} />}</h5>
                    </div>
                    <div>
                        <div className={loading && "dip-disabled"}  style={{ position: 'relative' }}>
                            {loading && <div className='ie-loader-container'>
                                <ProgressSpinner />
                            </div>}
                            <FileUpload
                                disabled={loading}
                                ref={fileUploaderRef}
                                chooseLabel={t('general.choose_file')}
                                uploadLabel={t('general.upload')}
                                cancelLabel={t('general.cancel')}
                                name="fuCsvROME"
                                customUpload
                                accept=".csv"
                                maxFileSize={10000000}
                                uploadHandler={onUploadFile}
                                emptyTemplate={<p className="p-m-0"><Trans i18nKey="general.drag_drop_to_import_file" /></p>} />
                        </div>
                    </div> */}
                    <Import
                        promise={props.promise}
                        thenPromise={props.thenPromise}
                        importTitle={props.importTitle}
                        tooltipText={props.tooltipText}
                    />
                </div>
                <div className='p-col-12 p-sm-12 p-lg-6'>
                    <div className="table-header">
                        <h5 className="title">{props.exportTitle}</h5>
                    </div>
                    <div>
                        <Button
                            label={t('administrator.generate_export')}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}





