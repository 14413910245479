import { Toast } from "primereact/toast";
import React, { useRef, useEffect } from "react";
import "./formationThumbnail.css"
import "../menu/menuSmallPopUp.css"
import StatistiquesService from '../../services/statistiquesService';
import { enumContextFormationThumbnail } from "../../enums/enumContextFormationThumbnail";
import { useAuthState } from "../../context/context";
import { useHistory } from 'react-router-dom';
import { CardFormationThumbnail } from "./cardFormationThumbnail";


export const NewFormationThumbnail = (props) => {
    const toast = useRef(null);
    const history = useHistory();

    const currentUser = useAuthState();

    const statistiqueService = new StatistiquesService();

    useEffect(() => {
        updateStats();
    }, [])

    const updateStats = () => {
        //Stats
        if (currentUser) {
            if (props.context == enumContextFormationThumbnail.RECHERCHE) { statistiqueService.StatFormationResultat(currentUser, props.formation?.id, history) }
            if (props.context == enumContextFormationThumbnail.ACCUEIL) { statistiqueService.StatFormationAccueil(currentUser, props.formation?.id, history) }
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <div className={props.className}>
                <div className="margin-formation-campus p-shadow-4">
                    <CardFormationThumbnail formation={props.formation} toast={toast} onUnlike={props.onUnlike} adminImpersonatingRecruteurId={props.adminImpersonatingRecruteurId} loadLazyData={props.loadLazyData} lazyParams={props.lazyParams} />
                </div>
            </div>
        </>
    )
}
