import { useTranslation } from "react-i18next"
import { EntrepriseOffreBreadCrumb } from "../../components/breadCrumb/EntrepriseOffreBreadCrumb"
import { FormAnnonceInterne } from "../../components/form/annonce/formAnnonceInterne"
import "./annonceInterne.css"

export const AnnonceInterne = () => {

  const { t } = useTranslation();

  return (
    <div>
      <EntrepriseOffreBreadCrumb step={1} label={t('advert.create_offer')} />
      <FormAnnonceInterne />
    </div>
  )
}