import { IoHeartOutline, IoHeart } from "react-icons/io5";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RoleHelper from "../../utils/roleHelper";
import { useAuthState } from "../../context/context";
import EntrepriseService from "../../services/entrepriseService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


export const LikeEtudiant = (props) => {

    const service = new EntrepriseService();
    const currentUser = useAuthState();
    const history = useHistory();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;
    const [liked, setLiked] = useState(props.etudiant?.isFavorite);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const like = () => {
        if (RoleHelper.isNotConnected(currentUser)) {
            props.toast.current.show({ severity: 'info', summary: t('role.youre_not_connected_summary'), detail: t('role.youre_not_connected_company_detail'), life: 3000 })
        }
        else {
            setIsLoading(true)
            service.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id) => {
                service.like(props.etudiant.id, id)
                    .then(() => {
                        setLiked(true)
                        props.toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('favorite.profile_like'), life: 3000 })
                    })
                    .catch(() => props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 }))
                    .finally(() => {
                        setIsLoading(false)
                        if(props.loadOffreEmploi)
                        {
                            props.loadOffreEmploi(
                                {
                                    first: 0,
                                    rows: 6,
                                    page: 0,
                                    sortField: 'Pertinence',
                                    sortOrder: 1,
                                    filters: history.location?.state?.filters || []
                                }
                            )
                        }
                    });
            })
        }

    }

    const unlike = () => {
        setIsLoading(true)
        service.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id) => {
            service.unlike(props.etudiant.id, id)
                .then(() => {
                    setLiked(false)
                    props.toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('favorite.profile_unlike'), life: 3000 })
                    if (props.onUnlike) {
                        props.onUnlike();
                    }
                })
                .catch(() => { props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 })})
                .finally(() => {
                    setIsLoading(false)
                    if(props.loadOffreEmploi)
                    {
                        props.loadOffreEmploi(
                            {
                                first: 0,
                                rows: 6,
                                page: 0,
                                sortField: 'Pertinence',
                                sortOrder: 1,
                                filters: history.location?.state?.filters || []
                            }
                        )
                    }
                });
        })
    }

    return (
        (RoleHelper.isEntreprise(currentUser)) ?
            <>
                {isLoading && <i className="p-button p-component p-disabled p-button-rounded p-button-danger p-button-text p-button-icon-only pi pi-spin pi-spinner" style={props.fontSize ? { fontSize: props.fontSize } : { fontSize: '1.2rem' }}></i>}
                {!isLoading && !liked &&
                    <div className="p-button p-component p-button-rounded p-button-danger p-button-text p-button-icon-only" onClick={like} style={props.fontSize ? { fontSize: props.fontSize } : { fontSize: '1.2rem' }}>
                        <IoHeartOutline />
                    </div>
                }
                {!isLoading && liked &&

                    <div className="p-button p-component p-button-rounded p-button-danger p-button-text p-button-icon-only" onClick={unlike} style={props.fontSize ? { fontSize: props.fontSize } : { fontSize: '1.2rem' }}>
                        <IoHeart />
                    </div>
                }
            </> :
            <>
                <br />
                <br />
            </>
    );

}

