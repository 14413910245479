import axios from "axios"
import { Accordion, AccordionTab } from "primereact/accordion"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"
import { useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { enumHttpError } from "../../enums/enumHttpError"
import { enumTypeCompetence } from "../../enums/enumTypeCompetence"
import CompetenceService from "../../services/competenceService"
import { DragNDrop } from "../dragdrop/dragNDrop"

export const PickListCompetencesSavoirEtre = (props) => {

    const { t } = useTranslation();

    const toast = useRef(null);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let cancel;

    const [globalFilter, setGlobalFilter] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0)
    const [competencesSavoirEtre, setCompetencesSavoirEtre] = useState(null);
    const [loading, setLoading] = useState(false)
    const [filterTimerId, setFilterTimerId] = useState(null)

    const competenceService = new CompetenceService();

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: 'libelle',
        sortOrder: 1
    });

    useEffect(() => {
        setLoading(true);
        if (cancel) {
            source.cancel();
        }
        cancel = source.token;
        competenceService.getPaginated(lazyParams, enumTypeCompetence.SAVOIRETRE, { cancelToken: cancel })
            .then(data => {
                setTotalRecords(data.totalCount);
                setCompetencesSavoirEtre(data.items);
                setLoading(false);
            })
            .catch(err => {
                if (err.message != enumHttpError.CANCELED) {
                    showError();
                    setLoading(false);
                }
            })
        return () => source.cancel();
    }, [lazyParams])

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        if (filterTimerId) {
            clearTimeout(filterTimerId);
        }
        var _filterTimerId = setTimeout(() => {
            let _lazyParams = { ...lazyParams, ...event };
            _lazyParams['page'] = 0;
            _lazyParams['first'] = 0;
            setLazyParams(_lazyParams);
            setFilterTimerId(null);
        }, 1000)
        setFilterTimerId(_filterTimerId);
    }

    const classeObjetDejaAjoute = "p-button-rounded p-button-secondary align-right";
    const classeObjetPourAjouter = "p-button-rounded p-button-info align-right";

    const pickListCompSavoirEtreActionBodyTemplate = (rowData) => {
        const bDejaAjoute = props.list[0]?.filter(o => o.id === rowData.id.toString()).length > 0;
        return (
            <Button icon="pi pi-arrow-right" className={bDejaAjoute ? classeObjetDejaAjoute : classeObjetPourAjouter} onClick={() => props.onAdd(rowData, 0)} />
        );
    }
    const searchbar = (
        <div>
            <label>{t('skills.skills')} ({t('skills.social')})</label>
            <span className="p-input-icon-left responsive">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
            </span>
        </div>
    )

    return (
        <>
            <Toast ref={toast} />
            <div className="p-grid">
                <div className="p-col">
                    <DataTable value={competencesSavoirEtre}
                        lazy
                        filterDelay={1000}
                        emptyMessage={t('skills.empty')}
                        dataKey="id"
                        globalFilter={globalFilter}
                        paginator first={lazyParams.first} rows={lazyParams.rows} rowsPerPageOptions={[5, 10, 25]}
                        totalRecords={totalRecords}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={t('skills.footer_count_pagin')}
                        onPage={onPage}
                        onFilter={onFilter}
                        loading={loading}
                    >
                        <Column field="libelle" header={searchbar} headerStyle={{ width: '15rem' }} sortable></Column>
                        <Column headerStyle={{ width: '1rem' }} body={pickListCompSavoirEtreActionBodyTemplate}></Column>
                    </DataTable>
                </div>
                <div className="p-col">
                    <div>
                        <h2><Trans i18nKey="skills.soft" /> (<Trans i18nKey="skills.social" />)</h2>
                        <DragNDrop list={props.list} setList={props.setList}></DragNDrop>
                    </div>
                </div>
            </div>
        </>
    )
}