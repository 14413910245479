import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { SelectButton } from "primereact/selectbutton"
import { Toast } from "primereact/toast"
import { classNames } from "primereact/utils"
import { Fragment, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import LangueService from "../../../services/langueService"
import { LangueSelector } from "../../selector/langueSelector"
import FormationService from "../../../services/formationService"
import { AutoComplete } from "primereact/autocomplete"
import RechercheService from "../../../services/rechercheService"

export const CaracteristiquesProfilRecherche = (props) => {

    const { t } = useTranslation();

    const [ddlNiveauxDiplome, setDdlNiveauxDiplome] = useState([])
    const [ddlNiveauxLangue, setDdlNiveauxLangue] = useState([])
    const [filteredValues, setFilteredValues] = useState(null);
    const [selectedValues, setSelectedValues] = useState(null);
    const toast = useRef(null)
    const langueService = new LangueService();
    const formationService = new FormationService();
    const rechercheService = new RechercheService();

    const exigenceMasculinOptions = [
        { name: t('requirement.required.m'), value: 2 },
        { name: t('requirement.wished.m'), value: 1 }
    ]

    const exigenceFemininOptions = [
        { name: t('requirement.required.f'), value: 2 },
        { name: t('requirement.wished.f'), value: 1 }
    ]

    const experienceOptions = [
        '1-3 ans',
        '4-7 ans',
        '+7 ans'
    ]

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        langueService
            .getNiveauxLangue()
            .then((_langues) => {
                setDdlNiveauxLangue(_langues);
            });
        formationService.getNiveauxEntreesDiplome()
            .then((_diplomes) => {
                setDdlNiveauxDiplome(_diplomes);
            })
    }

    const searchValues = (event) => {
        let query = event.query;
        rechercheService.getRechercheCompetence(query).then(res => setFilteredValues(res));
    }

    const handleChange = (competence) => {
        setSelectedValues(competence);
        if (typeof (competence) === 'object') {
            setSelectedValues(null);
        }
        if (typeof (competence) === "object" && competence !== null) {
            let _competenceId = parseInt(competence.value);
            let _annonce = { ...props.annonce }
            if (_annonce.competenceOffreEmploi.length == 0 || _annonce.competenceOffreEmploi?.map(e => e.competenceId).indexOf(_competenceId) == -1) {
                let _competence = {
                    id: _competenceId,
                    libelle: competence.label
                }
                let _coe = {
                    competenceId: _competence.id,
                    competence: _competence,
                    offreEmploiId: _annonce.id
                }
                _annonce.competenceOffreEmploi.push(_coe)
                props.setAnnonce(_annonce);
            }
        }
    }

    const template = (item) => {
        return (
            <span className="ler-item" >
                {item.label}
            </span>
        );
    }

    const handleRemoveValeur = (competenceId) => {
        let _annonce = { ...props.annonce }
        let _coe = _annonce.competenceOffreEmploi.filter(coe => coe.competenceId != competenceId)
        _annonce.competenceOffreEmploi = _coe
        props.setAnnonce(_annonce);
    }

    return (
        <div>
            <Toast ref={toast}></Toast>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-sm-12 p-lg-12">
                    <h2><Trans i18nKey="advert.seeked_profile" /></h2>
                </div>
                <div className="p-field p-col-12 p-sm-12 p-lg-8">
                    <div className="p-formgrid p-grid">
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='niveauDiplome'>
                                <Trans i18nKey="advert.minimal_study_level" />
                            </label>
                            <div className="dip-flex-row">
                                <Dropdown id='niveauDiplome'
                                    value={props.annonce?.niveauDiplome}
                                    options={ddlNiveauxDiplome}
                                    showClear
                                    onChange={(e) => props.onInputChange(e, 'niveauDiplome')}
                                    required
                                    className="dip-flex-grow-1"
                                />
                                <SelectButton
                                    value={props.annonce?.niveauDiplomeExigence}
                                    onChange={(e) => props.onInputChange(e, 'niveauDiplomeExigence')}
                                    options={exigenceMasculinOptions}
                                    optionLabel="name"
                                    unselectable={false}
                                    className={`fai-selectbutton  p-ml-2`}
                                />
                            </div>
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='experienceLibelle'>
                                <Trans i18nKey="advert.asked_experience" />
                            </label>
                            <div className="dip-flex-row">
                                <Dropdown
                                    id='experienceLibelle'
                                    options={experienceOptions}
                                    showClear
                                    value={props.annonce?.experienceLibelle}
                                    onChange={(e) => props.onInputChange(e, 'experienceLibelle')}
                                    required
                                    className="dip-flex-grow-1"
                                />
                                <SelectButton
                                    value={props.annonce?.experienceExigeOffreEmploi}
                                    onChange={(e) => props.onInputChange(e, 'experienceExigeOffreEmploi')}
                                    options={exigenceFemininOptions}
                                    optionLabel="name"
                                    unselectable={false}
                                    className={`fai-selectbutton  p-ml-2`}
                                />
                            </div>
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-lg-12">
                            <label><Trans i18nKey="advert.language_s" /></label>
                            {props.annonce?.offreEmploiLangue.map((langue, indexLangue) => (
                                <Fragment key={`${langue.id}~${indexLangue}`}>
                                    <div className="p-fluid p-formgrid p-grid responsive-langue-display">
                                        <div className="p-field p-col-12 p-sm-12 p-lg-4">
                                            <LangueSelector value={langue.langue} onChange={event => props.handleLangueChange(indexLangue, event, 'langue')} placeholder={t('profile.please_select_language')} className={classNames({ 'p-invalid': props.submitted && !langue.langue })} />
                                            {props.submitted && !langue.langue && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                                        </div>
                                        <div className="p-field p-col-12 p-sm-12 p-md-12 p-lg-4">
                                            <Dropdown value={langue.niveau} options={ddlNiveauxLangue} onChange={event => props.handleLangueChange(indexLangue, event, 'niveau')} placeholder={t('profile.please_select_level')} className={classNames({ 'p-invalid': props.submitted && !langue.niveau })} />
                                            {props.submitted && !langue.niveau && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                                        </div>
                                        <div className="p-field p-col-10 p-sm-10 p-md-10 p-lg-3">
                                            <SelectButton
                                                value={langue.exigence}
                                                onChange={(e) => props.handleLangueChange(indexLangue, e, 'exigence')}
                                                options={exigenceMasculinOptions}
                                                optionLabel="name"
                                                unselectable={false}
                                                className={`fai-selectbutton`}
                                            />
                                        </div>
                                        <div className="p-field p-col-2 p-sm-2 p-md-2 p-lg-1 delete-langue-btn">
                                            <Button
                                                icon="pi pi-trash"
                                                className="p-button-rounded p-button-danger"
                                                onClick={() => props.handleRemoveLangue(indexLangue)}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            ))}
                            <div className="dip-w-auto">
                                {/* <Button
                                    icon="pi pi-plus"
                                    className="p-button-rounded p-button-success p-mr-2"
                                    onClick={() => props.handleAddLangue()}
                                />
                                <b><Trans i18nKey='profile.add_language' /></b> */}
                                <Button
                                    icon="pi pi-plus"
                                    className="dip-btn-red-bg fai-langue-btn"
                                    label={t('profile.add_language')}
                                    onClick={() => props.handleAddLangue()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-field p-col-12 p-sm-12 p-lg-4">
                    <label><Trans i18nKey="skills.seeked" /></label>
                    <AutoComplete
                        panelClassName="ler-autcomplete-panel"
                        minLength="3"
                        value={selectedValues}
                        suggestions={filteredValues}
                        completeMethod={searchValues}
                        placeholder="Choisissez des compétences"
                        delay="500"
                        itemTemplate={template}
                        onChange={(e) => handleChange(e.value)}
                    />
                    {props.annonce?.competenceOffreEmploi?.length > 0 && <div className="p-mt-2 fai-competence-container">
                        {props.annonce?.competenceOffreEmploi?.sort((a, b) => (a.competence?.libelle > b.competence?.libelle) ? 1 : -1).map((coe, index) => (
                            <Fragment key={`coe~${index}`}>
                                <div className='fai-item'>
                                    <span>{coe.competence?.libelle}</span>
                                    <div>
                                        <Button icon="pi pi-times-circle" className="p-button-rounded p-button-danger p-button-text p-mr-2" onClick={(e) => handleRemoveValeur(coe.competenceId)} />
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>}
                </div>
            </div>

        </div >
    )
}