import BaseService from './baseService';

export default class DocumentService extends BaseService {

  telechargerDocument(documentId) {
    return this.axiosApiInstance.get(window.API_URL + '/api/document/Telecharger/' + documentId, { responseType: 'arraybuffer' })
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  TelechargerDocumentPersonnel(documentId,documentCategory) {
    return this.axiosApiInstance.get(window.API_URL + '/api/document/TelechargerDocumentPersonnel/' + documentCategory + '/' + documentId, { responseType: 'arraybuffer' })
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  TelechargerDocumentCandidat(documentId){
    return this.axiosApiInstance.get(window.API_URL + "/api/document/TelechargerDocumentCandidat/" + documentId, {responseType : 'arraybuffer'})
    .then(res => res.data)
    .catch(err => super.handleHttpError(err));
  }

  supprimerDocument(documentId) {
    return this.axiosApiInstance.delete(window.API_URL + '/api/document/Supprimer/' + documentId)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  supprimerDocumentValidationEtudiant(documentId, etudiantId) {
    return this.axiosApiInstance.delete(window.API_URL + '/api/document/Supprimer/' + documentId + "/ValidationEtudiant/" + etudiantId)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  validerDocument(documentId, etudiantId) {
    return this.axiosApiInstance.post(window.API_URL + '/api/document/Valider/' + documentId + "/ValidationEtudiant/" + etudiantId, null)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }


}
