export const enumRaisonArchivage = {
    EMPLOITROUVE: "J'ai trouvé un emploi et je ne souhaite plus utiliser la plateforme",
    DOUBLON: "Ce compte a été créé en doublon",
    INUTILE: "Je ne trouve plus DiplomAdvisor utile",
    AIMEPAS: "Je n'aime pas DiplomAdvisor",
    CONFIDENTIALITE: "Problème de confidentialité",
    CHANGEMENTEMAIL: "Je change d'adresse email",
    TROPEMAIL:"Je reçois trop d'emails",
    PIRATAGE: "Compte piraté",
    AUTRE: "Autre",
}