import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { Toast } from "primereact/toast"
import { classNames } from "primereact/utils"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import { useAuthState } from "../../context/context"
import { enumHttpError } from "../../enums/enumHttpError"
import { enumInfoQuestion } from "../../enums/enumInfoQuestion"
import FormationService from "../../services/formationService"
import UserService from "../../services/userService"
import RoleHelper from "../../utils/roleHelper"
import { Wysiwyg } from "../wysiwyg/wysiwyg"
import './formCourseContact.css'
import { enumConfWysiwyg } from "../../enums/enumConfWysiwyg"

export const FormCourseContact = (props) => {
    const userService = new UserService();
    const formationService = new FormationService();

    const currentUser = useAuthState();
    const { t } = useTranslation();
    const [form, setForm] = useState(null)
    const [ddlNiveauxEtude, setDdlNiveauxEtude] = useState([])
    const [ddlRaison, setDdlRaison] = useState([
        { label: t(`enums.eInfoQuestion.${enumInfoQuestion.INFOCONTENU}`), value: enumInfoQuestion.INFOCONTENU },
        { label: t(`enums.eInfoQuestion.${enumInfoQuestion.PREREQUIS}`), value: enumInfoQuestion.PREREQUIS },
        { label: t(`enums.eInfoQuestion.${enumInfoQuestion.DATEDEBUT}`), value: enumInfoQuestion.DATEDEBUT },
        { label: t(`enums.eInfoQuestion.${enumInfoQuestion.ADISTANCE}`), value: enumInfoQuestion.ADISTANCE },
        { label: t(`enums.eInfoQuestion.${enumInfoQuestion.LANGUE}`), value: enumInfoQuestion.LANGUE },
        { label: t(`enums.eInfoQuestion.${enumInfoQuestion.COUT}`), value: enumInfoQuestion.COUT },
        { label: t(`enums.eInfoQuestion.${enumInfoQuestion.AUTRE}`), value: enumInfoQuestion.AUTRE },
    ])
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const toast = useRef(null)


    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        formationService
            .getNiveauxEtude()
            .then((_niveaux) => {
                setDdlNiveauxEtude(_niveaux);
            });
        if (RoleHelper.isEtudiant(currentUser)) {
            userService.getFormulaireContactPreFilled()
                .then((form) => {
                    setForm(form)
                })
        }
    }

    const onInputChange = (e, name) => {
        let val = (e.value || e.target.value) || (typeof e?.target?.getContent === "function" && e?.target?.getContent()) || '';
        if (val == '') {
            val = null;
        }
        let _form = { ...form };
        _form[`${name}`] = val;
        if (name == "infoQuestion") {
            _form.infoTexte = null;
        }
        setForm(_form);
    };

    const onEditorChange = (content, editor) => {
        let _form = { ...form };
        _form.infoTexte = content

        setForm(_form)
    }

    const submitForm = () => {
        setSubmitted(true);
        if (form?.nom && form?.prenom && form?.formationCampus?.length > 0 && form?.email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(form?.email) && ((form?.infoQuestion == 7 && form?.infoTexte) || form?.infoQuestion < 7)) {
            toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 })
            setIsSubmitting(true);
            userService.postFormulaireContact(form)
                .then(() => {
                    let _form = { ...form }
                    if (currentUser.isAuthenticated) {
                        _form.infoQuestion = null
                        _form.infoTexte = null
                        _form.formationCampus = []
                    }
                    else {
                        _form = null
                    }
                    setForm(_form);
                    setSubmitted(false);
                    toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('messagerie.sent_message_confirmation'), life: 3000 });
                })
                .catch((err) => {
                    switch (err.message) {
                        case enumHttpError.PLEASELOGIN:
                            toast.current.show({ severity: 'error', summary: t('register.mail_exist'), detail: t('register.mail_exist_detail'), life: 3000 });
                            break;
                        default:
                            toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.unknown'), life: 3000 });
                            break;
                    }
                })
                .finally(() => {
                    setIsSubmitting(false);
                })
        }
        else {
            toast.current.show({ severity: 'error', summary: t('errors.incomplete_summary'), detail: t('errors.incomplete_detail'), life: 3000 })
        }
    }

    const template = (item) => {
        return (
            <span className='ssb-item'>
                {item.label}
            </span>
        );
    }

    return (
        <div id={props.id} className="fcc-main-container">
            <div className=" app-width-limit">
                <Toast ref={toast} />
                <h3 className="dip-fs-2 dip-fw-700">
                    <Trans i18nKey='formation.ask_more_info' />
                </h3>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-sm-12 p-lg-6">
                        <label htmlFor='infoQuestion'>
                            <Trans i18nKey='formation.which_info' />*
                        </label>
                        <Dropdown
                            id='infoQuestion'
                            value={form?.infoQuestion}
                            options={ddlRaison}
                            disabled={isSubmitting}
                            onChange={(e) => onInputChange(e, 'infoQuestion')}
                            className={classNames({ 'p-invalid': submitted && !form?.infoQuestion })}
                            itemTemplate={template}
                        />
                        {submitted && !form?.infoQuestion && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                    </div>
                    <div className="p-field p-col-12 p-sm-12 p-lg-6">
                        {props.campus && <>
                            <label htmlFor='campusId'>
                                <Trans i18nKey='formation.which_campus' />*
                            </label>
                            <MultiSelect
                                value={form?.formationCampus}
                                className={classNames({ 'p-invalid': submitted && (form?.formationCampus?.length == 0 || !form?.formationCampus) })}
                                options={props.campus}
                                disabled={isSubmitting}
                                onChange={(e) => onInputChange(e, 'formationCampus')}
                                optionLabel="campus.libelle"
                                selectedItemsLabel={t('primereact.selectedItemsLabel')}
                                showSelectAll={false}
                                maxSelectedLabels={0}
                            />
                            {submitted && (form?.formationCampus?.length == 0 || !form?.formationCampus) && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                        </>}
                    </div>
                    {form?.infoQuestion == 7 && <div className="p-field p-col-12 p-sm-12 p-lg-12">
                        <Wysiwyg value={form?.infoTexte} onChange={onEditorChange} Conf={enumConfWysiwyg.BASIC} defaultHeight={300}></Wysiwyg>
                        {submitted && !form?.infoTexte && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                    </div>}
                    <div className="p-field p-col-12 p-sm-12 p-lg-6">
                        <label htmlFor='nom'>
                            <Trans i18nKey='general.lastname' />*
                        </label>
                        <InputText id='nom'
                            value={form?.nom}
                            onChange={(e) => onInputChange(e, 'nom')}
                            disabled={isSubmitting}
                            required
                            maxLength="80"
                            className={classNames({ 'p-invalid': submitted && !form?.nom })} />
                        {submitted && !form?.nom && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                    </div>
                    <div className="p-field p-col-12 p-sm-12 p-lg-6">
                        <label htmlFor='prenom'>
                            <Trans i18nKey='general.firstname' />*
                        </label>
                        <InputText id='prenom'
                            value={form?.prenom}
                            onChange={(e) => onInputChange(e, 'prenom')}
                            disabled={isSubmitting}
                            required
                            maxLength="80"
                            className={classNames({ 'p-invalid': submitted && !form?.prenom })} />
                        {submitted && !form?.prenom && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                    </div>
                    <div className="p-field p-col-12 p-sm-12 p-lg-6">
                        <label htmlFor='niveauEtude'>
                            <Trans i18nKey='general.study_level' />
                        </label>
                        <Dropdown
                            id='niveauEtude'
                            value={form?.niveauEtude}
                            disabled={isSubmitting}
                            options={ddlNiveauxEtude}
                            onChange={(e) => onInputChange(e, 'niveauEtude')}
                        />
                    </div>
                    <div className="p-field p-col-12 p-sm-12 p-lg-6">
                        <label htmlFor='localisation'>
                            <Trans i18nKey='general.location' />
                        </label>
                        <InputText id='localisation'
                            value={form?.localisation}
                            disabled={isSubmitting}
                            onChange={(e) => onInputChange(e, 'localisation')}
                        />
                    </div>
                    <div className="p-field p-col-12 p-sm-12 p-lg-6">
                        <label htmlFor='phoneNumber'>
                            <Trans i18nKey='general.phone_number' />
                        </label>
                        <InputText
                            id="phoneNumber"
                            type="tel"
                            value={form?.phoneNumber}
                            disabled={isSubmitting}
                            onChange={(e) => onInputChange(e, 'phoneNumber')}
                            maxLength="13"
                        />
                    </div>
                    <div className="p-field p-col-12 p-sm-12 p-lg-6">
                        <label htmlFor='email'>
                            <Trans i18nKey='login.email' />*
                        </label>
                        <InputText id='email'
                            value={form?.email}
                            onChange={(e) => onInputChange(e, 'email')}
                            disabled={currentUser.isAuthenticated || isSubmitting}
                            maxLength="255"
                            className={classNames({ 'p-invalid': submitted && (!form?.email || (form?.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(form?.email))) })}
                        />
                        {submitted && !form?.email && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                        {submitted && form?.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(form?.email) && <small className="p-error"><Trans i18nKey="general.invalid_email" />.</small>}
                    </div>
                </div>
                <div className="dip-ta-right">
                    <Button
                        label={t('general.upload')}
                        className="dip-btn-red-bg"
                        disabled={isSubmitting}
                        onClick={submitForm}
                    />
                </div>
            </div>
        </div>
    )
}