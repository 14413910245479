import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Divider } from 'primereact/divider';
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from "primereact/radiobutton"
import { Skeleton } from "primereact/skeleton"
import { SplitButton } from "primereact/splitbutton"
import { FileUpload } from 'primereact/fileupload';
import { TabPanel, TabView } from "primereact/tabview"
import React, { useRef, useState, Fragment, useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import Helper from "../../utils/helper"
import FormationService from '../../services/formationService';
import { useAuthState } from '../../context/context';
import allowedImageFileType from '../../valueContol/allowedImageFileType.json';
import LangueService from "../../services/langueService";
import ComposanteService from "../../services/composanteService";
import UserService from "../../services/userService";
import { Toast } from "primereact/toast";
import { DtFormationsDemandees } from "../datatable/dtFormationsDemandees";
import RoleHelper from "../../utils/roleHelper";
import { PickListAppellations } from "../pickList/pickListAppellations";
import { PickListCompetencesSavoirSavoirFaireSplitted } from "../pickList/pickListCompetencesSavoirSavoirFaireSplitted";
import { enumCompetenceType } from "../../enums/enumCompetenceType";
import { PickListCompetencesSavoirEtre } from "../pickList/pickListCompetencesSavoirEtre";
import { PickListEnvironnementDeTravail } from "../pickList/pickListEnvironnementDeTravail";
import { PickListSecteurActivite } from "../pickList/pickListSecteurActivite";
import { Wysiwyg } from "../wysiwyg/wysiwyg";
import FormationHelper from "../../utils/formationHelper"
import { classNames } from "primereact/utils";
import CampusService from "../../services/campusService";
import { MenuSearchTooltip } from "../../scenes/rechercheAffinee/menu/menuSearchTooltip";
import { Calendar } from "primereact/calendar";
import "./formationDialog.css"
import { enumConfWysiwyg } from "../../enums/enumConfWysiwyg";

export const FormationDialog = (props) => {

    let emptyFormation = {
        id: 0,
        diplome: '',
        mention: null,
        parcours: null,
        siteWeb: null,
        formationActive: null,
        nbEtudiants: 0,
        editeurPlateforme: [],
        editeurEcole: [],
        formationCampus: [],
        composanteEcole: [],
        niveauEntree: null,
        niveauSortie: null,
        langue: null,
        formationAppellation: [],
        competenceFormation: [],
        environnementDeTravail: [],
        secteurActivite: [],
        descriptionVueEtudiant: null,
        descriptionVueEntreprise: null,
        descriptionVueEtudiantMobile: null,
        descriptionVueEntrepriseMobile: null,
        structureProgramme: null,
        structureProgrammeMobile: null,
        prerequis: null,
        prerequisMobile: null,
        modalitesCandidature: null,
        modalitesCandidatureMobile: null,
        calendrier: null,
        calendrierMobile: null,
        descriptionAlternance: null,
        descriptionAlternanceMobile: null,
        profilEtudiants: null,
        profilEtudiantsMobile: null,
        descriptionFormation: [
            {
                titre: null,
                contenu: null,
                contenuMobile: null,
                formationId: 0
            }, {
                titre: null,
                contenu: null,
                contenuMobile: null,
                formationId: 0
            }, {
                titre: null,
                contenu: null,
                contenuMobile: null,
                formationId: 0
            }, {
                titre: null,
                contenu: null,
                contenuMobile: null,
                formationId: 0
            }]
    };

    const { t } = useTranslation();

    const months = [
        { calendrierStage: 1, label: t('months.january'), },
        { calendrierStage: 2, label: t('months.february'), },
        { calendrierStage: 3, label: t('months.march'), },
        { calendrierStage: 4, label: t('months.april'), },
        { calendrierStage: 5, label: t('months.may'), },
        { calendrierStage: 6, label: t('months.june'), },
        { calendrierStage: 7, label: t('months.july'), },
        { calendrierStage: 8, label: t('months.august'), },
        { calendrierStage: 9, label: t('months.september'), },
        { calendrierStage: 10, label: t('months.october'), },
        { calendrierStage: 11, label: t('months.november'), },
        { calendrierStage: 12, label: t('months.december'), }
    ]

    const tagDDOptions = [
        { label: '0', value: 1 },
        { label: '1', value: 2 },
        { label: '2', value: 3 }
    ]

    const currentYear = new Date().getFullYear()

    const composanteService = new ComposanteService();
    const formationService = new FormationService();
    const campusService = new CampusService();
    const langueService = new LangueService();
    const userService = new UserService();

    const currentUser = useAuthState();

    const toast = useRef(null);
    const hasLoadedData = useRef(false);

    const [selectedEcole, setSelectedEcole] = useState(null)
    const [selectedCampus, setSelectedCampus] = useState([]);
    const [ddlEditeurs, setDdlEditeurs] = useState([]);
    const [ddlNiveauxEntreesDiplome, setDdlNiveauxEntreesDiplome] = useState(null)
    const [ddlNiveauxSortiesDiplome, setDdlNiveauxSortiesDiplome] = useState(null)

    const [addDiplomeVisibility, setAddDiplomeVisibility] = useState(false)
    const [filteredDiplomes, setFilteredDiplomes] = useState(null)
    const [filteredLangues, setFilteredLangues] = useState(null)
    const [filteredEcoles, setFilteredEcoles] = useState(null)
    const [filteredCampus, setFilteredCampus] = useState(null)
    const [filteredComposanteEcole, setFilteredComposanteEcole] = useState(null)

    const [lazyLoadingDdlEditeurs, setLazyLoadingDdlEditeurs] = useState(false);

    const [submitted, setSubmitted] = useState(false);
    const [formation, setFormation] = useState(emptyFormation);
    const [selectedAppellations, setSelectedAppellations] = useState([[]]);
    const [selectedCompBaseSavoir, setSelectedCompBaseSavoir] = useState([[]]);
    const [selectedCompBaseSavoirFaire, setSelectedCompBaseSavoirFaire] = useState([[]]);
    const [selectedCompSpecifiqueSavoir, setSelectedCompSpecifiqueSavoir] = useState([[]]);
    const [selectedCompSpecifiqueSavoirFaire, setSelectedCompSpecifiqueSavoirFaire] = useState([[]]);
    const [selectedCompSavoirEtre, setSelectedCompSavoirEtre] = useState([[]]);
    const [selectedStructures, setSelectedStructures] = useState([]);
    const [selectedSecteurs, setSelectedSecteurs] = useState([]);
    const [selectedDemandeFormation, setSelectedDemandeFormation] = useState([]);
    const [selectedRentreeDecalee, setSelectedRentreeDecalee] = useState([[]]);


    const [validImageType, setValidImageType] = useState(false);
    const fileUploaderImageFormation = useRef('');

    const [loadingFormation, setLoadingFormation] = useState(false);
    const [loadingSaveFormation, setLoadingSaveFormation] = useState(false);

    const [loadDraftFormationDisabled, setLoadDraftFormationDisabled] = useState(localStorage.getItem('formationDraftData') ? false : true);

    const [erreurImage, setErreurImage] = useState(null)


    useEffect(() => {
        if (!hasLoadedData.current && props.visible) {
            loadData();
            hasLoadedData.current = true;
        }
        setSubmitted(false);
        setSelectedDemandeFormation([]);
        retrieveFormation()
    }, [props.formationId, props.isDuplicate, props.visible])

    const retrieveFormation = () => {
        if (props.formationId) {
            setLoadingFormation(true);
            formationService.getFormation(props.formationId)
                .then(res => {
                    let _formation = { ...res };

                    _formation = fillFormationForm(_formation)

                    if (props.isDuplicate === true) {
                        _formation.id = 0;
                        let _mentionCopie = "Copie de ";
                        const _mention = _formation.mention;
                        if (_formation.image !== null) {
                            _formation.image.copie = true;
                        }

                        if (_mention !== undefined) {
                            _mentionCopie += _mention;
                        }
                        if (_formation.composanteEcole.length != 0) {
                            _formation.composanteEcole.forEach(ce => {
                                ce.formationId = 0
                                ce.formation = null
                                ce.ecole = null
                            });
                        }
                        if (_formation.formationCampus.length != 0) {
                            _formation.formationCampus.forEach(fc => {
                                fc.Id = 0
                                fc.formationId = 0
                                fc.formation = null
                                fc.campus = null
                            });
                        }
                        if (_formation.descriptionFormation.length != 0) {
                            _formation.descriptionFormation.forEach(d => {
                                d.Id = 0;
                                d.formationId = 0
                            })
                        }
                        _formation.mention = _mentionCopie
                    }
                    if (_formation.composanteEcole.length == 0) {
                        setSelectedEcole(null);
                    }
                    setFormation(_formation);
                })
                .catch(err => {
                    showCantFindFormation();
                    props.onHide();
                })
                .finally(() => {
                    setLoadingFormation(false);
                });
        }
        else {
            setFormation(emptyFormation)
            setSelectedEcole(null);
            setSelectedCampus([]);
            setSelectedCompBaseSavoir([[]]);
            setSelectedCompBaseSavoirFaire([[]]);
            setSelectedCompSpecifiqueSavoir([[]]);
            setSelectedCompSpecifiqueSavoirFaire([[]]);
            setSelectedCompSavoirEtre([[]]);
            setSelectedAppellations([[]]);
            setSelectedStructures([]);
            setSelectedSecteurs([]);
            setLoadingFormation(false);
        }
    }

    const showAlreadyInList = () => {
        toast.current.show({ severity: 'warn', summary: t('general.already_used_summary'), detail: t('general.already_used_detail'), life: 3000 })
    }

    const showSelectCampus = () => {
        toast.current.show({ severity: 'warn', summary: t('campus.cant_add'), detail: t('campus.please_select'), life: 3000 })
    }

    const showNoCampus = () => {
        toast.current.show({ severity: 'warn', summary: t('campus.cant_find'), detail: t('campus.please_add'), life: 3000 })
    }

    const showCantFindFormation = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_find_formation'), life: 3000 });
    }


    // load data

    const loadData = () => {
        formationService.getNiveauxEntreesDiplome().then(_niveaux => {
            setDdlNiveauxEntreesDiplome(_niveaux);
        })
        formationService.getNiveauxSortiesDiplome().then(_niveaux => {
            setDdlNiveauxSortiesDiplome(_niveaux);
        })
    }

    // Template

    let itemsSave = [
        {
            label: <Trans i18nKey="general.save" />,
            icon: 'pi pi-check',
            command: (e) => {
                saveFormation(false, false);
            }
        }
    ]

    let itemsDraftFormation = [
        {
            label : <Trans i18nKey="formation.load_draft" />,
            icon: 'pi pi-upload',
            disabled: loadDraftFormationDisabled,
            command: (e) => {
                loadDraftFormation();
            }
        },        
        {
            label: <Trans i18nKey="general.remove_draft" />,
            icon: 'pi pi-trash',
            command: (e) => {
                removeDraftFormation();
            }
        }
    ]

    if (props.canSaveAndVisualize) {
        itemsSave.push(
            {
                label: <Trans i18nKey="general.save_and_visualize" />,
                icon: 'pi pi-eye',
                command: (e) => {
                    saveFormation(false, true);
                }
            }
        )
    }

    const headerCustomDescription = (descript, indexDescription) => {
        return (
            <Fragment>
                <Inplace closable>
                    <InplaceDisplay>
                        {descript.titre || <label style={{ fontStyle: "italic" }}><Trans i18nKey="general.click_to_edit" /></label>}
                    </InplaceDisplay>
                    <InplaceContent>
                        <InputText value={descript.titre} onChange={(e) => handleDescriptionTitleChange(indexDescription, e, 'titre')} autoFocus />
                    </InplaceContent>
                </Inplace>
            </Fragment>
        )
    }

    // reorder

    const affectOrder = (_formation) => {
        let newAppellation = [];
        _formation.formationAppellation = [...selectedAppellations][0]
        _formation.formationAppellation.forEach(a => {
            let _appellation = new Object
            _appellation.appellationId = a.id;
            _appellation.formationId = _formation.id;
            _appellation.ordre = _formation.formationAppellation.indexOf(a);
            newAppellation.push(_appellation);
        });
        _formation.formationAppellation = newAppellation

        let _competences = [];
        let _selectedCompBaseSavoir = [...selectedCompBaseSavoir][0]
        let _selectedCompBaseSavoirFaire = [...selectedCompBaseSavoirFaire][0]
        let _selectedCompSpecifiqueSavoir = [...selectedCompSpecifiqueSavoir][0]
        let _selectedCompSpecifiqueSavoirFaire = [...selectedCompSpecifiqueSavoirFaire][0]
        let _selectedCompSavoirEtre = [...selectedCompSavoirEtre][0]

        _selectedCompBaseSavoir.forEach(c => {
            let _competence = new Object
            _competence.competenceId = c.id;
            _competence.ordre = _selectedCompBaseSavoir.indexOf(c);
            _competence.competenceType = c.competenceType
            _competence.formationId = _formation.id;
            _competences.push(_competence);
        })
        _selectedCompBaseSavoirFaire.forEach(c => {
            let _competence = new Object
            _competence.competenceId = c.id;
            _competence.ordre = _selectedCompBaseSavoirFaire.indexOf(c);
            _competence.competenceType = c.competenceType
            _competence.formationId = _formation.id;
            _competences.push(_competence);
        })
        _selectedCompSpecifiqueSavoir.forEach(c => {
            let _competence = new Object
            _competence.competenceId = c.id;
            _competence.ordre = _selectedCompSpecifiqueSavoir.indexOf(c);
            _competence.competenceType = c.competenceType
            _competence.formationId = _formation.id;
            _competences.push(_competence);
        })
        _selectedCompSpecifiqueSavoirFaire.forEach(c => {
            let _competence = new Object
            _competence.competenceId = c.id;
            _competence.ordre = _selectedCompSpecifiqueSavoirFaire.indexOf(c);
            _competence.competenceType = c.competenceType
            _competence.formationId = _formation.id;
            _competences.push(_competence);
        })
        _selectedCompSavoirEtre.forEach(c => {
            let _competence = new Object
            _competence.competenceId = c.id;
            _competence.ordre = _selectedCompSavoirEtre.indexOf(c);
            _competence.competenceType = c.competenceType
            _competence.formationId = _formation.id;
            _competences.push(_competence);
        })
        _formation.competenceFormation = _competences
        return _formation;
    }

    const affectCampusMonths = (_formation) => {
        _formation.formationCampus?.forEach(fc => {
            fc.calendrierStage = fc.calendrierStage?.calendrierStage;
        });
        return _formation;
    }

    const cleanupDescriptions = (_formation) => {
        var re = /^&nbsp;( &nbsp;)*$/;
        if (_formation.descriptionVueEtudiant != null) {
            if (_formation.descriptionVueEtudiant.match(re)) {
                _formation.descriptionVueEtudiant = null;
            }
        }
        if (_formation.descriptionVueEntreprise != null) {
            if (_formation.descriptionVueEntreprise?.match(re)) {
                _formation.descriptionVueEntreprise = null;
            }
        }
        if (_formation.descriptionVueEtudiantMobile != null) {
            if (_formation.descriptionVueEtudiantMobile.match(re)) {
                _formation.descriptionVueEtudiantMobile = null;
            }
        }
        if (_formation.descriptionVueEntrepriseMobile != null) {
            if (_formation.descriptionVueEntrepriseMobile?.match(re)) {
                _formation.descriptionVueEntrepriseMobile = null;
            }
        }
        if (_formation.structureProgramme != null) {
            if (_formation.structureProgramme?.match(re)) {
                _formation.structureProgramme = null;
            }
        }
        if (_formation.structureProgrammeMobile != null) {
            if (_formation.structureProgrammeMobile?.match(re)) {
                _formation.structureProgrammeMobile = null;
            }
        }
        if (_formation.prerequis != null) {
            if (_formation.prerequis?.match(re)) {
                _formation.prerequis = null;
            }
        }
        if (_formation.prerequisMobile != null) {
            if (_formation.prerequisMobile?.match(re)) {
                _formation.prerequisMobile = null;
            }
        }
        if (_formation.modalitesCandidature != null) {
            if (_formation.modalitesCandidature?.match(re)) {
                _formation.modalitesCandidature = null;
            }
        }
        if (_formation.modalitesCandidatureMobile != null) {
            if (_formation.modalitesCandidatureMobile?.match(re)) {
                _formation.modalitesCandidatureMobile = null;
            }
        }
        if (_formation.calendrier != null) {
            if (_formation.calendrier?.match(re)) {
                _formation.calendrier = null;
            }
        }
        if (_formation.calendrierMobile != null) {
            if (_formation.calendrierMobile?.match(re)) {
                _formation.calendrierMobile = null;
            }
        }
        if (_formation.descriptionAlternance != null) {
            if (_formation.descriptionAlternance?.match(re)) {
                _formation.descriptionAlternance = null;
            }
        }
        if (_formation.descriptionAlternanceMobile != null) {
            if (_formation.descriptionAlternanceMobile?.match(re)) {
                _formation.descriptionAlternanceMobile = null;
            }
        }
        if (_formation.profilEtudiants != null) {
            if (_formation.profilEtudiants?.match(re)) {
                _formation.profilEtudiants = null;
            }
        }
        if (_formation.profilEtudiantsMobile != null) {
            if (_formation.profilEtudiantsMobile?.match(re)) {
                _formation.profilEtudiantsMobile = null;
            }
        }
        return _formation
    }

    // Search functions

    const searchDiplomes = (event) => {
        let query = event.query;

        formationService.getDiplomeFiltered(query).then(_diplomes => {
            setFilteredDiplomes(_diplomes);
            addDiplomeVisibilityCheck(_diplomes);
        });
    }

    const searchLangues = (event) => {
        let query = event.query;

        langueService.getLanguesFiltered(query).then(_langues => {
            setFilteredLangues(_langues);
        });
    }

    const searchCampus = (event) => {
        let _idEcole = selectedEcole?.id;
        let query = event.query;

        campusService.getCampusFiltered(query, _idEcole).then(_campus => {
            setFilteredCampus(_campus);
            if (_campus.length < 1 && query == "") {
                showNoCampus();
            }
        });
    }

    const searchComposanteEcole = (event) => {
        let _idEcole = selectedEcole?.id;
        let query = event.query;

        composanteService.getComposanteEcoleFiltered(query, _idEcole).then(_ce => {
            setFilteredComposanteEcole(_ce);
        });
    }

    const searchEcoles = (event) => {
        let query = event.query;

        formationService.getEcolesFiltered(query).then(_ecoles => {
            setFilteredEcoles(_ecoles);
        });
    }

    // DnD Crud (Appellation + Competences)

    const isCompetenceSavoirAlreadyInAnOtherList = (competence) => {
        return selectedCompBaseSavoir[0].concat(selectedCompSpecifiqueSavoir[0]).filter(o => o.id === competence.id.toString()).length > 0
    }

    const isCompetenceSavoirFaireAlreadyInAnOtherList = (competence) => {
        return selectedCompBaseSavoirFaire[0].concat(selectedCompSpecifiqueSavoirFaire[0]).filter(o => o.id === competence.id.toString()).length > 0
    }

    const addToSelectedAppellations = (appellation, ind) => {
        let _selectedAppellations = [...selectedAppellations]
        let _list = selectedAppellations[ind];
        if (_list.find(a => a.id == appellation.id) == undefined) {
            let _item = new Object;
            _item.id = String(appellation.id);
            _item.libelle = appellation.libelle;
            _selectedAppellations[0].push(_item)
            setSelectedAppellations(_selectedAppellations);
        }
        else {
            showAlreadyInList();
        }
    }

    const addToSelectedCompBaseSavoir = (competence, ind) => {
        let _selectedCompBaseSavoir = [...selectedCompBaseSavoir]
        let _list = selectedCompBaseSavoir[ind];
        let _list2 = selectedCompSpecifiqueSavoir[ind];
        if (_list.find(a => a.id == competence.id) == undefined && _list2.find(a => a.id == competence.id) == undefined) {
            let _item = new Object;
            _item.id = String(competence.id);
            _item.libelle = competence.libelle;
            _item.typeCompetence = "Savoir";
            _item.competenceType = 0;
            _selectedCompBaseSavoir[0].push(_item)
            setSelectedCompBaseSavoir(_selectedCompBaseSavoir);
        }
        else {
            showAlreadyInList();
        }
    }
    const addToSelectedCompBaseSavoirFaire = (competence, ind) => {
        let _selectedCompBaseSavoirFaire = [...selectedCompBaseSavoirFaire]
        let _list = selectedCompBaseSavoirFaire[ind];
        let _list2 = selectedCompSpecifiqueSavoirFaire[ind];
        if (_list.find(a => a.id == competence.id) == undefined && _list2.find(a => a.id == competence.id) == undefined) {
            let _item = new Object;
            _item.id = String(competence.id);
            _item.libelle = competence.libelle;
            _item.typeCompetence = "SavoirFaire";
            _item.competenceType = 0;
            _selectedCompBaseSavoirFaire[0].push(_item)
            setSelectedCompBaseSavoirFaire(_selectedCompBaseSavoirFaire);
        }
        else {
            showAlreadyInList();
        }
    }

    const addToSelectedCompSpecifiqueSavoir = (competence, ind) => {
        let _selectedCompSpecifiqueSavoir = [...selectedCompSpecifiqueSavoir]
        let _list = selectedCompSpecifiqueSavoir[ind];
        let _list2 = selectedCompBaseSavoir[ind];
        if (_list.find(a => a.id == competence.id) == undefined && _list2.find(a => a.id == competence.id) == undefined) {
            let _item = new Object;
            _item.id = String(competence.id);
            _item.libelle = competence.libelle;
            _item.typeCompetence = "Savoir";
            _item.competenceType = 1;
            _selectedCompSpecifiqueSavoir[0].push(_item)
            setSelectedCompSpecifiqueSavoir(_selectedCompSpecifiqueSavoir);
        }
        else {
            showAlreadyInList();
        }
    }

    const addToSelectedCompSpecifiqueSavoirFaire = (competence, ind) => {
        let _selectedCompSpecifiqueSavoirFaire = [...selectedCompSpecifiqueSavoirFaire]
        let _list = selectedCompSpecifiqueSavoirFaire[ind];
        let _list2 = selectedCompBaseSavoirFaire[ind];
        if (_list.find(a => a.id == competence.id) == undefined && _list2.find(a => a.id == competence.id) == undefined) {
            let _item = new Object;
            _item.id = String(competence.id);
            _item.libelle = competence.libelle;
            _item.typeCompetence = "SavoirFaire";
            _item.competenceType = 1;
            _selectedCompSpecifiqueSavoirFaire[0].push(_item)
            setSelectedCompSpecifiqueSavoirFaire(_selectedCompSpecifiqueSavoirFaire);
        }
        else {
            showAlreadyInList();
        }
    }

    const addToSelectedCompSavoirEtre = (competence, ind) => {
        let _selectedCompSavoirEtre = [...selectedCompSavoirEtre]
        let _list = selectedCompSavoirEtre[ind];
        if (_list.find(a => a.id == competence.id) == undefined) {
            let _item = new Object;
            _item.id = String(competence.id);
            _item.libelle = competence.libelle;
            _item.typeCompetence = "SavoirEtre";
            _selectedCompSavoirEtre[0].push(_item)
            setSelectedCompSavoirEtre(_selectedCompSavoirEtre);
        }
        else {
            showAlreadyInList();
        }
    }

    // Structures ciblées

    const addToSelectedStructures = (structure) => {
        let _formation = { ...formation };
        let _structuresCibles = [...formation.environnementDeTravail];
        if (_structuresCibles.find(a => a.id == structure.id) == undefined) {
            let _item = new Object;
            _item.id = structure.id;
            _item.libelle = structure.libelle;
            _structuresCibles.push(_item)
            setSelectedStructures(_structuresCibles);

            _formation.environnementDeTravail = _structuresCibles;
            setFormation(_formation);
        }
    }
    const handleRemoveStructure = (e, id) => {
        let _formation = { ...formation };
        let _structures = [..._formation.environnementDeTravail]

        let indexRemove = _structures.findIndex(x => x.id === id);

        _structures.splice(indexRemove, 1);
        setSelectedStructures(_structures);

        _formation.environnementDeTravail = _structures;
        setFormation(_formation);
    };

    // Secteurs ciblés

    const addToSelectedSecteurs = (secteur, ind) => {
        let _formation = { ...formation };
        let _secteursVises = [...formation.secteurActivite];
        if (_secteursVises.find(a => a.id == secteur.id) == undefined) {
            let _item = new Object;
            _item.id = secteur.id;
            _item.libelle = secteur.libelle;
            _secteursVises.push(_item)
            setSelectedSecteurs(_secteursVises);

            _formation.secteurActivite = _secteursVises;
            setFormation(_formation);
        }
    }
    const handleRemoveSecteur = (e, id) => {
        let _formation = { ...formation };
        let _secteurs = [..._formation.secteurActivite]

        let indexRemove = _secteurs.findIndex(x => x.id === id);

        _secteurs.splice(indexRemove, 1);
        setSelectedSecteurs(_secteurs);

        _formation.secteurActivite = _secteurs;
        setFormation(_formation);
    };

    // Campus

    const handleAddCampus = (campus) => {
        if (campus != null && campus.id != undefined) {
            let _formation = { ...formation };
            let _campus = [...formation.formationCampus];
            let _campu = {};
            _campu.campusId = campus.id;
            _campu.libelle = campus.libelle;
            _campu.stagePrevu = false;
            _campu.dureeStage = null;
            _campu.calendrierStage = null;
            _campu.presentiel = false;
            _campu.accessibleADistance = false;
            _campu.formationInitiale = false;
            _campu.formationContinue = false;
            _campu.alternance = false;
            _campu.contratProfessionnalisation = false;
            _campu.contratApprentissage = false;
            if (_formation.id != 0 && _formation.id != null && _formation.id != undefined) {
                _campu.formationId = _formation.id
            }
            _campus.push(_campu);

            _formation.formationCampus = _campus;
            setFormation(_formation);
        }
        else {
            showSelectCampus();
        }
    };

    const handleRemoveCampus = campus => {
        let _formation = { ...formation };
        let _campus = [...formation.formationCampus];
        let index = _campus.findIndex(x => x.campusId === campus.id)
        _campus.splice(index, 1);

        _formation.formationCampus = _campus;
        setFormation(_formation);
    };

    // File
    const onUploadImageFormation = ({ files }) => {
        const _formation = { ...formation }
        _formation.fileImage = files[0];
        controllImageTypeValidity(files[0].type)
        setFormation(_formation);
        fileUploaderImageFormation.current.clear();
    };

    const onSelectImageFormation = ({ files }) => {
        setErreurImage(files[0].size > 5000000);
    };

    const handleRemoveImage = () => {
        let _formation = { ...formation };
        _formation.fileImage = null;
        setFormation(_formation);
    }

    const handleRemoveFichierImage = () => {
        let _formation = { ...formation };
        _formation.image = null;
        setFormation(_formation);
    }

    // onChange functions

    const onEditorChange = (content, editor, name) => {
        let _formation = { ...formation };
        _formation[`${name}`] = content

        setFormation(_formation)
    }


    const onInputChange = (e, name) => {
        const val = e.value || (e.target && e.target.value) || null;
        let _formation = { ...formation };
        _formation[`${name}`] = val;
        setFormation(_formation);
    }

    const handleDescriptionChange = (index, content, editor, name) => {
        let _formation = { ...formation };
        let _descriptions = [..._formation.descriptionFormation];
        let _description = _descriptions[index];
        _description[`${name}`] = content;
        _descriptions[index] = _description;
        _formation.descriptionFormation = _descriptions;
        setFormation(_formation);
    };

    const handleDescriptionTitleChange = (index, e, name) => {
        let _formation = { ...formation };
        let _descriptions = _formation.descriptionFormation;
        let val = (e.target && e.target.value) || '';
        let _desription = _descriptions[index];
        _desription[`${name}`] = val;
        _descriptions[index] = _desription;
        _formation.descriptionFormation = _descriptions;
        setFormation(_formation);
    };

    const onEditeursChange = (e) => {
        var _formation = { ...formation };
        var _editeurs = [];
        e.value.forEach(editeur => {
            _editeurs.push(editeur);
        });
        _formation.editeurEcole = _editeurs;
        setFormation(_formation);

    }


    const onEcoleChange = (e) => {

        const val = (e.target && e.target.value) || '';
        let _formation = { ...formation };
        setSelectedEcole(val)

        setSelectedCampus([]);
        _formation.composanteEcole = [];
        _formation.formationCampus = [];
        setFormation(_formation);
    }

    const onComposanteChange = (e, name) => {
        if (Helper.containsDuplicates(e.value) == false) {
            onInputChange(e, name);
        }
    }

    const onCampusChange = (index, e, name) => {
        let _formation = { ...formation };
        let _formationCampus = _formation.formationCampus;

        let val = e.value || (e.target && e.target.value) || null;
        // if(name=='calendrierStage'){
        //     val = val.calendrierStage;
        // }

        let _campu = _formationCampus[index];
        if (name == 'rentreeDecalleeDatesList') {
            let _rentree = [...selectedRentreeDecalee];
            _rentree[index] = val;
            setSelectedRentreeDecalee(_rentree);
            if (val.length > 0) {
                _campu.rentreeDecalleeDatesList = val.join(';')
            }
            else {
                _campu.rentreeDecalleeDatesList = null;
            }
        }
        else {
            _campu[`${name}`] = val;
        }
        _formationCampus[index] = _campu;
        _formation.formationCampus = _formationCampus;
        setFormation(_formation);
    }

    const onRBFChange = (e, name, valeurOui) => {
        const val = (e.target.name == valeurOui);
        let _formation = { ...formation };
        _formation[`${name}`] = val;
        setFormation(_formation);
    }

    const onRBChange = (index, e, name, valeurOui) => {
        let _formation = { ...formation };
        let _formationCampus = [..._formation.formationCampus];

        let _campu = _formationCampus[index];
        _campu[`${name}`] = (e.target.name == valeurOui);
        if (name == 'stagePrevu') {
            _campu['dureeStage'] = null;
            _campu['calendrierStage'] = null;
        }
        if (name == 'alternance') {
            _campu['rythme'] = null;
            _campu['rythmeEnEcole'] = null;
            _campu['rythmeEnEntreprise'] = null;
        }
        _formationCampus[index] = _campu;

        _formation.formationCampus = _formationCampus;
        setFormation(_formation);
    }

    const handleCampusChange = (e) => {
        if (Helper.containsDuplicates(e.value) == false) {
            let _listeOldCampus = [...selectedCampus];
            let _listeNewCampus = e.value;

            if (_listeNewCampus.length > _listeOldCampus.length) {
                var campusToAdd = _listeNewCampus.filter(function (nc) {
                    return !_listeOldCampus.some(function (oc) {
                        return nc.id === oc.id;
                    })
                })[0];
                handleAddCampus(campusToAdd);
            }
            else if (_listeNewCampus.length < _listeOldCampus.length) {
                var campusToRemove = _listeOldCampus.filter(function (oc) {
                    return !_listeNewCampus.some(function (nc) {
                        return nc.id === oc.id;
                    })
                })[0];
                handleRemoveCampus(campusToRemove);
            }
            setSelectedCampus(e.value);
        }
    }

    const onSelectedDemandeFormationChange = (e, rowData) => {
        let _selectedDemandeFormation = [...selectedDemandeFormation]
        let val = (e.checked) || false;

        if (val) {
            _selectedDemandeFormation.push(rowData);
        }
        else {
            _selectedDemandeFormation = _selectedDemandeFormation.filter(df => {
                return df.id !== rowData.id
            })
        }
        setSelectedDemandeFormation(_selectedDemandeFormation);
    }

    const fillFormationForm = (formation) => {

        setFilteredDiplomes([formation.diplome])
        setFilteredLangues([formation.langue])
        setFilteredComposanteEcole(formation.composanteEcole)
        setFilteredEcoles([formation.composanteEcole[0]?.ecole])
        setFilteredCampus([formation.formationCampus])

        let _
        formation.formationCampus.forEach(fc => {

        });
        if (formation.image) {
            controllImageTypeValidity(formation.image.format);
        }

        while (formation.descriptionFormation.length < 4) {
            formation.descriptionFormation.push({
                titre: null,
                contenu: null,
                contenuMobile: null,
                formationId: formation.id
            })
        }
        // Pre select in the months tab and campus names
        let _selectedCampus = []
        let _selectedRentreeDecallee = []
        formation.formationCampus.forEach(c => {
            let _rentree = []
            if (c.rentreeDecalleeDatesList) {
                _rentree = c.rentreeDecalleeDatesList.split(';')
                _rentree.forEach((e, i) => {
                    _rentree[i] = parseInt(e)
                });
            }
            _selectedRentreeDecallee.push(_rentree)
            if (c.stagePrevu == true && c.calendrierStage != null) {
                c.calendrierStage = months[c.calendrierStage - 1]
            }
            if (c.dateDebutStage) {
                c.dateDebutStage = new Date(Date.parse(c.dateDebutStage))
            }
            if (c.dateFinStage) {
                c.dateFinStage = new Date(Date.parse(c.dateFinStage))
            }
            _selectedCampus.push(c.campus);
        });
        setSelectedRentreeDecalee(_selectedRentreeDecallee)
        setSelectedCampus(_selectedCampus);

        if (formation.composanteEcole[0]?.ecole) {
            setSelectedEcole(formation.composanteEcole[0]?.ecole);
        }

        // Fill the ddl options with known values
        setDdlEditeurs(formation.editeurEcole);

        // Ordonner Compétences
        let _selectedCompBaseSavoir = [[]];
        let _selectedCompBaseSavoirFaire = [[]];
        let _selectedCompSpecifiqueSavoir = [[]];
        let _selectedCompSpecifiqueSavoirFaire = [[]];
        let _selectedCompSavoirEtre = [[]];
        let _competences = formation.competenceFormation
        _competences.forEach(comp => {
            let _comp = new Object;
            _comp.id = comp.competenceId.toString();
            _comp.libelle = comp.libelle;
            _comp.competenceType = comp.competenceType;
            _comp.typeCompetence = comp.typeCompetence;
            _comp.ordre = comp.ordre;
            if (comp.typeCompetence == "Savoir" && comp.competenceType == 0) {
                _selectedCompBaseSavoir[0].push(_comp)
            }
            else if (comp.typeCompetence == "SavoirFaire" && comp.competenceType == 0) {
                _selectedCompBaseSavoirFaire[0].push(_comp)
            }
            else if (comp.typeCompetence == "Savoir" && comp.competenceType == 1) {
                _selectedCompSpecifiqueSavoir[0].push(_comp)
            }
            else if (comp.typeCompetence == "SavoirFaire" && comp.competenceType == 1) {
                _selectedCompSpecifiqueSavoirFaire[0].push(_comp)
            }

            else if (comp.typeCompetence == "SavoirEtre") {
                _selectedCompSavoirEtre[0].push(_comp)
            }
        })
        _selectedCompBaseSavoir[0].sort((a, b) => a.ordre - b.ordre);
        _selectedCompBaseSavoirFaire[0].sort((a, b) => a.ordre - b.ordre);
        _selectedCompSpecifiqueSavoir[0].sort((a, b) => a.ordre - b.ordre);
        _selectedCompSpecifiqueSavoirFaire[0].sort((a, b) => a.ordre - b.ordre);
        _selectedCompSavoirEtre[0].sort((a, b) => a.ordre - b.ordre);

        setSelectedCompBaseSavoir(_selectedCompBaseSavoir);
        setSelectedCompBaseSavoirFaire(_selectedCompBaseSavoirFaire);
        setSelectedCompSpecifiqueSavoir(_selectedCompSpecifiqueSavoir);
        setSelectedCompSpecifiqueSavoirFaire(_selectedCompSpecifiqueSavoirFaire);
        setSelectedCompSavoirEtre(_selectedCompSavoirEtre);
        setSelectedStructures(formation.environnementDeTravail);
        setSelectedSecteurs(formation.secteurActivite)

        //Ordonner Appellations
        let _selectedAppellation = [[]];
        let _appellations = formation.formationAppellation
        _appellations.forEach(app => {
            let _appellation = new Object;
            _appellation.id = app.appellationId.toString();
            _appellation.libelle = app.libelle;
            _appellation.ordre = app.ordre;
            _selectedAppellation[0].push(_appellation)
        })
        _selectedAppellation[0].sort((a, b) => a.ordre - b.ordre);
        setSelectedAppellations(_selectedAppellation);

        return formation;
    }

    const loadDraftFormation = () => {
        setLoadingSaveFormation(true);

        const draftDataJSON = localStorage.getItem('formationDraftData');
        if (draftDataJSON) {

            const draftData = JSON.parse(draftDataJSON);
    
            // Vérifier et mettre à jour les variables d'état
            if (draftData.selectedEcole !== undefined) setSelectedEcole(draftData.selectedEcole);
            if (draftData.selectedCampus !== undefined) setSelectedCampus(draftData.selectedCampus);
            if (draftData.ddlEditeurs !== undefined) setDdlEditeurs(draftData.ddlEditeurs);
            if (draftData.ddlNiveauxEntreesDiplome !== undefined) setDdlNiveauxEntreesDiplome(draftData.ddlNiveauxEntreesDiplome);
            if (draftData.ddlNiveauxSortiesDiplome !== undefined) setDdlNiveauxSortiesDiplome(draftData.ddlNiveauxSortiesDiplome);
            if (draftData.addDiplomeVisibility !== undefined) setAddDiplomeVisibility(draftData.addDiplomeVisibility);
            if (draftData.filteredDiplomes !== undefined) setFilteredDiplomes(draftData.filteredDiplomes);
            if (draftData.filteredLangues !== undefined) setFilteredLangues(draftData.filteredLangues);
            if (draftData.filteredEcoles !== undefined) setFilteredEcoles(draftData.filteredEcoles);
            if (draftData.filteredCampus !== undefined) setFilteredCampus(draftData.filteredCampus);
            if (draftData.filteredComposanteEcole !== undefined) setFilteredComposanteEcole(draftData.filteredComposanteEcole);
            if (draftData.lazyLoadingDdlEditeurs !== undefined) setLazyLoadingDdlEditeurs(draftData.lazyLoadingDdlEditeurs);
            if (draftData.submitted !== undefined) setSubmitted(draftData.submitted);
            if (draftData.formation !== undefined) setFormation(draftData.formation);
            if (draftData.selectedAppellations !== undefined) setSelectedAppellations(draftData.selectedAppellations);
            if (draftData.selectedCompBaseSavoir !== undefined) setSelectedCompBaseSavoir(draftData.selectedCompBaseSavoir);
            if (draftData.selectedCompBaseSavoirFaire !== undefined) setSelectedCompBaseSavoirFaire(draftData.selectedCompBaseSavoirFaire);
            if (draftData.selectedCompSpecifiqueSavoir !== undefined) setSelectedCompSpecifiqueSavoir(draftData.selectedCompSpecifiqueSavoir);
            if (draftData.selectedCompSpecifiqueSavoirFaire !== undefined) setSelectedCompSpecifiqueSavoirFaire(draftData.selectedCompSpecifiqueSavoirFaire);
            if (draftData.selectedCompSavoirEtre !== undefined) setSelectedCompSavoirEtre(draftData.selectedCompSavoirEtre);
            if (draftData.selectedStructures !== undefined) setSelectedStructures(draftData.selectedStructures);
            if (draftData.selectedSecteurs !== undefined) setSelectedSecteurs(draftData.selectedSecteurs);
            if (draftData.selectedDemandeFormation !== undefined) setSelectedDemandeFormation(draftData.selectedDemandeFormation);
            if (draftData.selectedRentreeDecalee !== undefined) setSelectedRentreeDecalee(draftData.selectedRentreeDecalee);
            if (draftData.validImageType !== undefined) setValidImageType(draftData.validImageType);
    
        }

        // Simulation opération comme API
        setTimeout(() => {
            setLoadingSaveFormation(false);
            if(draftDataJSON) {
                setLoadDraftFormationDisabled(false);
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('formation.draft_loaded'), life: 3000 });
                //'Le brouillon à fini de charger'
            }
            else {
                toast.current.show({ severity: 'error', summary: t('general.error'), detail: t('formation.draft_not_found'), life: 3000 });
            }
        }, 1000);
    };     
    
    const removeDraftFormation = () => {
        localStorage.removeItem('formationDraftData');
        setLoadDraftFormationDisabled(true);
        toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('formation.draft_removed'), life: 3000 });
    };

    const saveInDraftFormation = () => {
        setLoadingSaveFormation(true);
        
        const draftData = {
            selectedEcole,
            selectedCampus,
            ddlEditeurs,
            ddlNiveauxEntreesDiplome,
            ddlNiveauxSortiesDiplome,
            addDiplomeVisibility,
            filteredDiplomes,
            filteredLangues,
            filteredEcoles,
            filteredCampus,
            filteredComposanteEcole,
            lazyLoadingDdlEditeurs,
            submitted,
            formation,
            selectedAppellations,
            selectedCompBaseSavoir,
            selectedCompBaseSavoirFaire,
            selectedCompSpecifiqueSavoir,
            selectedCompSpecifiqueSavoirFaire,
            selectedCompSavoirEtre,
            selectedStructures,
            selectedSecteurs,
            selectedDemandeFormation,
            selectedRentreeDecalee,
            validImageType
        };

        const draftDataJSON = JSON.stringify(draftData);
        localStorage.setItem('formationDraftData', draftDataJSON);
    
        // Simulation opération comme API
        setTimeout(() => {
            setLoadingSaveFormation(false);
            setLoadDraftFormationDisabled(false);
            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('formation.draft_saved'), life: 3000 });
        }, 1000);
    };    

    const saveFormation = (quit, visualize) => {
        setSubmitted(true);
        setLoadingSaveFormation(true);
        if (
            (formation.diplome && formation.diplome?.nom) &&
            formation.mention !== null && 
            formation.mention.trim() !== "" &&
            formation.composanteEcole.length > 0 &&
            formation.niveauEntree &&
            formation.niveauSortie &&
            ddlNiveauxEntreesDiplome.indexOf(formation.niveauEntree) <= ddlNiveauxSortiesDiplome.indexOf(formation.niveauSortie) + 1 &&
            formation.langue &&
            formation.formationActive != null &&
            formation.isVisible != null &&
            [...selectedAppellations][0].length > 0 &&
            [...selectedCompBaseSavoir][0].length > 0 &&
            [...selectedCompBaseSavoirFaire][0].length > 0 &&
            formation.environnementDeTravail.length > 0 &&
            formation.formationCampus.length > 0 &&
            !(
                (Helper.isEmptyOrSpaces(formation.descriptionFormation[0].titre) && (!Helper.isEmptyOrSpaces(formation.descriptionFormation[0].contenu) || !Helper.isEmptyOrSpaces(formation.descriptionFormation[0].contenuMobile))) ||
                (!Helper.isEmptyOrSpaces(formation.descriptionFormation[0].titre) && (Helper.isEmptyOrSpaces(formation.descriptionFormation[0].contenu) && Helper.isEmptyOrSpaces(formation.descriptionFormation[0].contenuMobile))) ||
                (Helper.isEmptyOrSpaces(formation.descriptionFormation[1].titre) && (!Helper.isEmptyOrSpaces(formation.descriptionFormation[1].contenu) || !Helper.isEmptyOrSpaces(formation.descriptionFormation[1].contenuMobile))) ||
                (!Helper.isEmptyOrSpaces(formation.descriptionFormation[1].titre) && (Helper.isEmptyOrSpaces(formation.descriptionFormation[1].contenu) && Helper.isEmptyOrSpaces(formation.descriptionFormation[1].contenuMobile))) ||
                (Helper.isEmptyOrSpaces(formation.descriptionFormation[2].titre) && (!Helper.isEmptyOrSpaces(formation.descriptionFormation[2].contenu) || !Helper.isEmptyOrSpaces(formation.descriptionFormation[2].contenuMobile))) ||
                (!Helper.isEmptyOrSpaces(formation.descriptionFormation[2].titre) && (Helper.isEmptyOrSpaces(formation.descriptionFormation[2].contenu) && Helper.isEmptyOrSpaces(formation.descriptionFormation[2].contenuMobile))) ||
                (Helper.isEmptyOrSpaces(formation.descriptionFormation[3].titre) && (!Helper.isEmptyOrSpaces(formation.descriptionFormation[3].contenu) || !Helper.isEmptyOrSpaces(formation.descriptionFormation[3].contenuMobile))) ||
                (!Helper.isEmptyOrSpaces(formation.descriptionFormation[3].titre) && (Helper.isEmptyOrSpaces(formation.descriptionFormation[3].contenu) && Helper.isEmptyOrSpaces(formation.descriptionFormation[3].contenuMobile)))
            ) &&
            formation.formationCampus.every(x => !x.addresseCourrielResponsable?.trim() || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}(;[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12})*$/i.test(x.addresseCourrielResponsable)) &&
            !(!formation.fileImage && !formation.image) &&
            validImageType
        ) {
            toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 })
            let _formation = { ...formation };
            affectOrder(_formation);
            affectCampusMonths(_formation);
            cleanupDescriptions(_formation);
            setFormation(_formation);

            _formation.formationCampus.forEach(campus => {
                campus.dateDebutStage = campus.dateDebutStage != null ? campus.dateDebutStage.toLocaleString("en-US") : null;
                campus.dateFinStage = campus.dateFinStage != null ? campus.dateFinStage.toLocaleString("en-US") : null;
            })

            formationService.createOrUpdateFormation(currentUser, _formation, selectedDemandeFormation).then(res => {
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('formation.updated'), life: 3000 });
                setLoadingFormation(true)
                let _formationReturned = { ...res }
                let _formationFilled = fillFormationForm(_formationReturned);
                setFormation(_formationFilled);
                setSelectedDemandeFormation([]);
                if (quit) {
                    props.onHide()
                }
                if (visualize) {
                    var win = window.open("/course/" + `${res.id}` + FormationHelper.buildFormationPath(formation.composanteEcole[0]?.nom, FormationHelper.nomCompletFormaterUrl(formation)))
                    win.focus();
                }
                props.onSave();
            }
            )
                .catch((err) => {
                    toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_save'), life: 3000 })
                }
                )
                .finally(() => {
                    setLoadingSaveFormation(false);
                    setLoadingFormation(false);
                })

        }
        else {
            toast.current.show({ severity: 'error', summary: t('errors.incomplete_summary'), detail: t('errors.incomplete_detail'), life: 3000 })
            setLoadingSaveFormation(false);
        }
    }
    // Editeur

    const onChargementDdlEditeurs = (event) => {

        userService.getEditeursLight(event.query)
            .then(res => {
                const _editeurs = [...res];
                setDdlEditeurs(_editeurs);
            });
    }

    // Helper ?

    const controllImageTypeValidity = (type) => {
        if (allowedImageFileType.indexOf(type) > -1) {
            setValidImageType(true);
        }
        else {
            setValidImageType(false)
        }
    }

    const addDiplomeVisibilityCheck = (diplomes) => {
        if (typeof (formation.diplome) === 'string' && formation.diplome != '') {
            if (!diplomes.some(e => e.nom.toLowerCase() === formation.diplome.trim().toLowerCase())) {
                setAddDiplomeVisibility(true)
            }
            else {
                setAddDiplomeVisibility(false)
            }
        }
        else {
            setAddDiplomeVisibility(false)
        }

    }


    const addDiplome = () => {

        if (typeof (formation.diplome) === 'string' && formation.diplome != '') {
            let _diplome = { Nom: formation.diplome, Id: 0 };
            formationService
                .addDiplome(_diplome)
                .then((_diplome) => {

                    let _formation = formation;
                    formation.diplome = _diplome
                    setFilteredDiplomes([_diplome])
                    setFormation(_formation);
                    setAddDiplomeVisibility(false);
                    toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('formation.add_diploma_ok'), life: 3000 });
                })
                .catch((err) => {
                    toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('formation.add_diploma_fail'), life: 3000 });
                })
                .finally(() => {

                })
        }
        else {
            toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('formation.diplome_already_exist'), life: 3000 });
        }
    }

    const formationDialogFooter = (
        <div className="p-grid save-formation fd-footer-content">
            <Button label={t('general.cancel')} icon={"pi pi-times"} className="p-button-text" onClick={props.onHide} loading={loadingSaveFormation} loadingOptions={{ position: 'right' }} />
            <SplitButton label={t('general.save_in_draft')} className="p-button-text save-button-formation p-mr-0 p-pl-4 p-pr-2" icon={!loadingSaveFormation ? "pi pi-save" : "p-button-loading-icon pi pi-spinner pi-spin p-button-icon-left"} onClick={e => saveInDraftFormation()} model={itemsDraftFormation} disabled={loadingSaveFormation} loading={loadingSaveFormation} loadingOptions={{ position: 'right' }} />
            <SplitButton label={t('general.save_and_quit')} className="p-button-text save-button-formation p-mr-0 p-pl-4 p-pr-2" menuClassName="menu-save-button-formation" icon={!loadingSaveFormation ? "pi pi-sign-out" : "p-button-loading-icon pi pi-spinner pi-spin p-button-icon-left"} onClick={e => saveFormation(true, false)} model={itemsSave} disabled={loadingSaveFormation} loading={loadingSaveFormation} loadingOptions={{ position: 'right' }}></SplitButton>
        </div>
    );

    return (
        <div>
            <Toast ref={toast} />
            <Dialog baseZIndex={1000} visible={props.visible} style={{ maxHeight: "99%", width: "100%" }} header={t('formation.details_formation')} modal closeOnEscape={false} className="p-fluid" footer={formationDialogFooter} onHide={props.onHide}>
                {loadingFormation ? (<div style={{ textAlign: 'center' }}><ProgressSpinner /></div>) : (
                    <div className="p-grid">
                        <div className={`p-field ${(RoleHelper.isAdmin(currentUser) || RoleHelper.isSuperAdmin(currentUser)) ? "p-col-11 p-lg-8" : "p-col-12 p-lg-9"}`}>
                            <label htmlFor="diplome"><Trans i18nKey="formation.diploma" />*</label>
                            <AutoComplete value={formation.diplome} suggestions={filteredDiplomes} completeMethod={searchDiplomes} minLength={3} virtualScrollerOptions={{ itemSize: 31 }} field="nom" dropdown onChange={(e) => onInputChange(e, 'diplome')} required autoFocus />
                            {submitted && (!formation.diplome || !formation.diplome?.nom) && <small className="p-error"><Trans i18nKey="formation.diplome_required" /></small>}
                        </div>
                        {(RoleHelper.isAdmin(currentUser) || RoleHelper.isSuperAdmin(currentUser)) && <div className="p-col-1 p-lg-1" style={{ marginTop: "1.6rem" }}>
                            <Button icon="pi pi-plus" className="p-button-rounded p-button-success" tooltip={t('formation.add_diploma')} tooltipOptions={{ position: 'bottom' }} onClick={addDiplome} disabled={!addDiplomeVisibility && (RoleHelper.isAdmin(currentUser) || RoleHelper.isSuperAdmin(currentUser))} />
                        </div>}
                        <div className="p-field p-col-12 p-lg-3">
                            <label htmlFor="identifiantRNCP"><Trans i18nKey="formation.rncp_id" /></label>
                            <InputNumber id="identifiantRNCP" value={formation.identifiantRNCP} onChange={(e) => onInputChange(e, 'identifiantRNCP')} />
                        </div>
                        <div className="p-field p-col-12 p-lg-9">
                            <label htmlFor="mention"><Trans i18nKey="formation.name" />*</label>
                            <InputText id="mention" value={formation.mention} onChange={(e) => onInputChange(e, 'mention')} required />
                            {submitted && !formation.mention && <small className="p-error"><Trans i18nKey="formation.mention_required" /></small>}
                        </div>
                        <div className="p-field p-col-12 p-lg-3">
                            <label htmlFor="identifiantInterne"><Trans i18nKey="formation.internal_id" /></label>
                            <InputText id="identifiantInterne" value={formation.identifiantInterne} onChange={(e) => onInputChange(e, 'identifiantInterne')} />
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="parcours"><Trans i18nKey="formation.course" /></label>
                            <InputText id="parcours" value={formation.parcours} onChange={(e) => onInputChange(e, 'parcours')} />
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="siteWeb"><Trans i18nKey="formation.website" /></label>
                            <InputText id="siteWeb" value={formation?.siteWeb} onChange={(e) => onInputChange(e, 'siteWeb')} />
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="ddlEcole"><Trans i18nKey="formation.school" /></label>
                            <AutoComplete value={selectedEcole} suggestions={filteredEcoles} forceSelection={true} completeMethod={searchEcoles} minLength={3} virtualScrollerOptions={{ itemSize: 31 }} field="nomEcole" dropdown onChange={(e) => onEcoleChange(e, 'ecole')} placeholder={t('formation.select_school')} disabled={RoleHelper.isAdminEcole(currentUser) || RoleHelper.isAdminComposante(currentUser)} />
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="ddlComposante"><Trans i18nKey="formation.components" />*</label>
                            <AutoComplete value={formation.composanteEcole} suggestions={filteredComposanteEcole} completeMethod={searchComposanteEcole} minLength={3} virtualScrollerOptions={{ itemSize: 31 }} field="nom" dropdown onChange={(e) => onComposanteChange(e, 'composanteEcole')} multiple placeholder={t('formation.select_component')} disabled={!selectedEcole || RoleHelper.isAdminComposante(currentUser)} />
                            {submitted && formation.composanteEcole.length < 1 && <small className="p-error"><Trans i18nKey="formation.composanteEcole_required" /></small>}
                        </div>
                        <div className="p-field p-col-12 p-lg-6">
                            <label htmlFor="ddlNiveauDiplomeEntree"><Trans i18nKey="formation.diplom_level_entrance" />*</label>
                            <Dropdown id="ddlNiveauDiplomeEntree" value={formation.niveauEntree} options={ddlNiveauxEntreesDiplome} onChange={(e) => onInputChange(e, 'niveauEntree')} placeholder={t('formation.select_diplom_level_entrance')} />
                            {submitted && !formation.niveauEntree && <small className="p-error"><Trans i18nKey="formation.niveauEntree_required" /></small>}
                        </div>
                        <div className="p-field p-col-12 p-lg-6">
                            <label htmlFor="ddlNiveauDiplomeSortie"><Trans i18nKey="formation.diplom_level_exit" />*</label>
                            <Dropdown id="ddlNiveauDiplomeSortie" value={formation.niveauSortie} options={ddlNiveauxSortiesDiplome} onChange={(e) => onInputChange(e, 'niveauSortie')} placeholder={t('formation.select_diplom_level_exit')} />
                            {submitted && !formation.niveauSortie && <small className="p-error"><Trans i18nKey="formation.niveauSortie_required" /></small>}
                            {submitted && ddlNiveauxEntreesDiplome.indexOf(formation.niveauEntree) > ddlNiveauxSortiesDiplome.indexOf(formation.niveauSortie) + 1 && <small className="p-error"><Trans i18nKey="formation.diploma_too_low" /></small>}
                        </div>
                        <div className="p-field p-col-12 p-lg-6">
                            <label htmlFor="ddlLangue"><Trans i18nKey="formation.language.base" />*</label>
                            <AutoComplete value={formation.langue} suggestions={filteredLangues} completeMethod={searchLangues} minLength={3} forceSelection virtualScrollerOptions={{ itemSize: 31 }} field="libelle" dropdown onChange={(e) => onInputChange(e, 'langue')} />
                            {submitted && !formation.langue && <small className="p-error"><Trans i18nKey="formation.langue_required" /></small>}
                        </div>
                        <div className="p-field p-col-12 p-lg-6">
                            <label htmlFor="duree"><Trans i18nKey="formation.duration" /></label>
                            <InputNumber id="duree" value={formation.duree} onChange={(e) => onInputChange(e, 'duree')} />
                        </div>
                        <div className="p-field p-col-6 p-lg-3">
                            <label><Trans i18nKey="formation.parcourSup" /></label>
                            <div className="dip-flex-row">
                                <div className="p-field-radiobutton p-mr-5">
                                    <RadioButton inputId="ouiIsFromParcoursup" name="ouiIsFromParcoursup" value={formation.isFromParcoursup} onChange={(e) => onRBFChange(e, 'isFromParcoursup', 'ouiIsFromParcoursup')} checked={formation.isFromParcoursup} />
                                    <label htmlFor="ouiIsFromParcoursup"><Trans i18nKey="general.yes" /></label>
                                </div>
                                <div className="p-field-radiobutton">
                                    <RadioButton inputId="nonIsFromParcoursup" name="nonIsFromParcoursup" value={formation.isFromParcoursup} onChange={(e) => onRBFChange(e, 'isFromParcoursup', 'ouiIsFromParcoursup')} checked={formation.isFromParcoursup == false} />
                                    <label htmlFor="nonIsFromParcoursup"><Trans i18nKey="general.no" /></label>
                                </div>
                            </div>
                        </div>
                        <div className="p-field p-col-6 p-lg-3">
                            <label><Trans i18nKey="formation.monMaster" /></label>
                            <div className="dip-flex-row">
                                <div className="p-field-radiobutton p-mr-5">
                                    <RadioButton inputId="ouiIsFromMonMaster" name="ouiIsFromMonMaster" value={formation.isFromMonMaster} onChange={(e) => onRBFChange(e, 'isFromMonMaster', 'ouiIsFromMonMaster')} checked={formation.isFromMonMaster} />
                                    <label htmlFor="ouiIsFromMonMaster"><Trans i18nKey="general.yes" /></label>
                                </div>
                                <div className="p-field-radiobutton">
                                    <RadioButton inputId="nonIsFromMonMaster" name="nonIsFromMonMaster" value={formation.isFromMonMaster} onChange={(e) => onRBFChange(e, 'isFromMonMaster', 'ouiIsFromMonMaster')} checked={formation.isFromMonMaster == false} />
                                    <label htmlFor="nonIsFromMonMaster"><Trans i18nKey="general.no" /></label>
                                </div>
                            </div>
                        </div>
                        <div className="p-field p-col-6 p-lg-3">
                            <label><Trans i18nKey="formation.active_formation" />*</label>
                            <div className="dip-flex-row">
                                <div className="p-field-radiobutton p-mr-5">
                                    <RadioButton inputId="ouiFormationActive" name="ouiFormationActive" value={formation.formationActive} onChange={(e) => onRBFChange(e, 'formationActive', 'ouiFormationActive')} checked={formation.formationActive} />
                                    <label htmlFor="ouiFormationActive"><Trans i18nKey="general.yes" /></label>
                                </div>
                                <div className="p-field-radiobutton">
                                    <RadioButton inputId="nonFormationActive" name="nonFormationActive" value={formation.formationActive} onChange={(e) => onRBFChange(e, 'formationActive', 'ouiFormationActive')} checked={formation.formationActive == false} />
                                    <label htmlFor="nonFormationActive"><Trans i18nKey="general.no" /></label>
                                </div>
                            </div>
                            {submitted && formation.formationActive == null && <small className="p-error"><Trans i18nKey="formation.rb_required" /></small>}
                        </div>
                        <div className="p-field p-col-6 p-lg-3">
                            <label><Trans i18nKey="formation.visible" />*</label>
                            <div className="dip-flex-row">
                                <div className="p-field-radiobutton p-mr-5">
                                    <RadioButton inputId="ouiIsVisible" name="ouiIsVisible" value={formation.isVisible} onChange={(e) => onRBFChange(e, 'isVisible', 'ouiIsVisible')} checked={formation.isVisible} />
                                    <label htmlFor="ouiIsVisible"><Trans i18nKey="general.yes" /></label>
                                </div>
                                <div className="p-field-radiobutton">
                                    <RadioButton inputId="nonIsVisible" name="nonIsVisible" value={formation.isVisible} onChange={(e) => onRBFChange(e, 'isVisible', 'ouiIsVisible')} checked={formation.isVisible == false} />
                                    <label htmlFor="nonIsVisible"><Trans i18nKey="general.no" /></label>
                                </div>
                            </div>
                            {submitted && formation.isVisible == null && <small className="p-error"><Trans i18nKey="formation.rb_required" /></small>}
                        </div>
                        <div className="p-field p-col-6 p-lg-3">
                            <label htmlFor="tagDeveloppementDurable"><Trans i18nKey="formation.tagDD" /></label>
                            <Dropdown
                                id="tagDeveloppementDurable"
                                value={formation.tagDeveloppementDurable}
                                optionLabel="label"
                                optionValue="value"
                                options={tagDDOptions}
                                onChange={e => onInputChange(e, 'tagDeveloppementDurable')}
                            />

                        </div>
                        <div className="p-field p-col-12" style={{ display: "inline-flex" }}>
                            <label htmlFor="ddlCampus" style={{ fontWeight: '500' }}><Trans i18nKey="formation.campus" />*</label>
                        </div>
                        <div className="p-field p-col-12">
                            <AutoComplete value={selectedCampus} suggestions={filteredCampus} completeMethod={searchCampus} minLength={3} field="libelle" dropdown onChange={(e) => handleCampusChange(e)} placeholder={t('formation.select_campus')} multiple disabled={!selectedEcole} />
                        </div>
                        <div className="p-col-12">
                            {submitted && formation.formationCampus.length < 1 && <small className="p-error"><Trans i18nKey="formation.campus_required" /></small>}
                        </div>
                        {formation.formationCampus?.map((campu, indexCampus) => (
                            <Fragment key={`${campu}~${indexCampus}`}>
                                <>
                                    <div className="p-field p-col-12 p-md-10">
                                        <label htmlFor="ddlCampus" className="p-mb-3" style={{ fontWeight: '500', marginRight: '1rem' }}><Trans i18nKey="campus.libelle" /></label>
                                        <span>{campu.libelle}</span>
                                    </div>
                                    <div className="p-field p-col-12 p-md-10">
                                        <label style={{ display: "flex" }} htmlFor="addresseCourrielResponsable"><Trans i18nKey="formation.mail" /><MenuSearchTooltip className="p-ml-2 msf-tooltip" text={t('formation.mail_multiple_tooltip')}></MenuSearchTooltip></label>
                                        <InputText
                                            id="addresseCourrielResponsable"
                                            value={campu.addresseCourrielResponsable}
                                            onChange={event => onCampusChange(indexCampus, event, 'addresseCourrielResponsable')}
                                            className={classNames({ 'p-invalid': submitted && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}(;[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12})*$/i.test(campu.addresseCourrielResponsable) && campu.addresseCourrielResponsable?.trim() })}

                                        />
                                        {submitted && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}(;[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12})*$/i.test(campu.addresseCourrielResponsable) && campu.addresseCourrielResponsable?.trim() && <small className="p-error"><Trans i18nKey="general.invalid_email" /></small>}
                                    </div>
                                    <div className="p-field p-col-12 p-lg-6">
                                        <label htmlFor="coutAnnuel"><Trans i18nKey="formation.annual_cost" /></label>
                                        <InputNumber
                                            id="coutAnnuel"
                                            value={campu.coutAnnuel}
                                            onChange={e => onCampusChange(indexCampus, e, 'coutAnnuel')}
                                            mode="currency"
                                            currency="EUR"
                                            locale="fr-FR"
                                            minFractionDigits={2}
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-lg-6">
                                        <label htmlFor="effectif"><Trans i18nKey="formation.effective" /></label>
                                        <InputNumber
                                            id="effectif"
                                            value={campu.effectif}
                                            onChange={e => onCampusChange(indexCampus, e, 'effectif')}
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-lg-6">
                                        <label><Trans i18nKey="formation.back_to_school_off" /></label>
                                        <div className="dip-flex-row">
                                            <div className="p-field-radiobutton p-mr-5">
                                                <RadioButton inputId="ouiHasRentreeDecalee" name="ouiHasRentreeDecalee" value={campu.hasRentreeDecalee} onChange={e => onRBChange(indexCampus, e, 'hasRentreeDecalee', 'ouiHasRentreeDecalee')} checked={campu.hasRentreeDecalee} />
                                                <label htmlFor="ouiHasRentreeDecalee"><Trans i18nKey="general.yes" /></label>
                                            </div>
                                            <div className="p-field-radiobutton">
                                                <RadioButton inputId="nonHasRentreeDecalee" name="nonHasRentreeDecalee" value={campu.hasRentreeDecalee} onChange={e => onRBChange(indexCampus, e, 'hasRentreeDecalee', 'ouiHasRentreeDecalee')} checked={campu.hasRentreeDecalee == false} />
                                                <label htmlFor="nonHasRentreeDecalee"><Trans i18nKey="general.no" /></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field p-col-12 p-lg-6">
                                        <label><Trans i18nKey="formation.selective" /></label>
                                        <div className="dip-flex-row">
                                            <div className="p-field-radiobutton p-mr-5">
                                                <RadioButton inputId="ouiIsSelective" name="ouiIsSelective" value={campu.isSelective} onChange={e => onRBChange(indexCampus, e, 'isSelective', 'ouiIsSelective')} checked={campu.isSelective} />
                                                <label htmlFor="ouiIsSelective"><Trans i18nKey="general.yes" /></label>
                                            </div>
                                            <div className="p-field-radiobutton">
                                                <RadioButton inputId="nonIsSelective" name="nonIsSelective" value={campu.isSelective} onChange={e => onRBChange(indexCampus, e, 'isSelective', 'ouiIsSelective')} checked={campu.isSelective == false} />
                                                <label htmlFor="nonIsSelective"><Trans i18nKey="general.no" /></label>
                                            </div>
                                        </div>
                                    </div>
                                    {campu.hasRentreeDecalee &&
                                        <>
                                            <div className="p-field p-col-12 p-lg-6">
                                                <label htmlFor="rentreeDecalleeDatesList"><Trans i18nKey="formation.back_to_school_period" /></label>
                                                <MultiSelect
                                                    id="RentreeDecalleeDatesList"
                                                    value={selectedRentreeDecalee[indexCampus]}
                                                    options={months}
                                                    optionValue="calendrierStage"
                                                    onChange={(e) => onCampusChange(indexCampus, e, 'rentreeDecalleeDatesList')}
                                                    display="chip"
                                                    filter={true}
                                                    appendTo="self"
                                                />
                                            </div>
                                            <div className="p-field p-col-12 p-lg-6"></div>
                                        </>
                                    }
                                    <div className="p-field p-col-12 p-lg-6">
                                        <label htmlFor="rbStagePrevu"><Trans i18nKey="formation.internship_planned" /></label>
                                        <div className="dip-flex-row">
                                            <div className="p-field-radiobutton p-mr-5">
                                                <RadioButton inputId="ouiStagePrevu" name="ouiStagePrevu" value={campu.stagePrevu} onChange={event => onRBChange(indexCampus, event, 'stagePrevu', 'ouiStagePrevu')} checked={campu.stagePrevu} />
                                                <label htmlFor="ouiStagePrevu"><Trans i18nKey="general.yes" /></label>
                                            </div>
                                            <div className="p-field-radiobutton">
                                                <RadioButton inputId="nonStagePrevu" name="nonStagePrevu" value={campu.stagePrevu} onChange={event => onRBChange(indexCampus, event, 'stagePrevu', 'ouiStagePrevu')} checked={!campu.stagePrevu} />
                                                <label htmlFor="nonStagePrevu"><Trans i18nKey="general.no" /></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field p-col-12 p-lg-6"></div>
                                    {campu.stagePrevu &&
                                        <>
                                            <div className="p-field p-col-12 p-lg-4" >
                                                <label htmlFor="dureeStage"><Trans i18nKey="formation.internship_duration_in_months" /></label>
                                                <InputNumber id="dureeStage" inputId="integeronly" value={campu.dureeStage} onValueChange={event => onCampusChange(indexCampus, event, 'dureeStage')} disabled={!formation.formationCampus[indexCampus].stagePrevu} />
                                            </div>
                                            {/* <div className="p-field p-col-12 p-md-5">
                                                <label htmlFor="calendrierStage"><Trans i18nKey="formation.internship_period" /></label>
                                                <Dropdown id="calendrierStage" value={campu.calendrierStage} options={months} onChange={event => onCampusChange(indexCampus, event, 'calendrierStage')} disabled={!formation.formationCampus[indexCampus].stagePrevu} />
                                            </div> */}
                                            <div className="p-field p-col-12 p-lg-4">
                                                <label htmlFor="dateDebutStage"><Trans i18nKey="formation.internship_beginning" /></label>
                                                <Calendar
                                                    id="dateDebutStage"
                                                    placeholder=''
                                                    locale="fr"
                                                    value={campu.dateDebutStage}
                                                    onChange={event => onCampusChange(indexCampus, event, 'dateDebutStage')}
                                                    monthNavigator
                                                    showIcon
                                                    yearNavigator yearRange={`${currentYear}:${currentYear + 40}`}
                                                    dateFormat='dd/mm/yy'
                                                />
                                            </div>
                                            <div className="p-field p-col-12 p-lg-4">
                                                <label htmlFor="dateFinStage"><Trans i18nKey="formation.internship_end" /></label>
                                                <Calendar
                                                    id='dateFinStage'
                                                    placeholder=''
                                                    locale="fr"
                                                    value={campu.dateFinStage}
                                                    onChange={event => onCampusChange(indexCampus, event, 'dateFinStage')}
                                                    monthNavigator
                                                    showIcon
                                                    yearNavigator yearRange={`${currentYear}:${currentYear + 40}`}
                                                    dateFormat='dd/mm/yy'
                                                />
                                            </div>
                                        </>
                                    }

                                    <div className="p-field p-col-12 p-lg-6">
                                        <label><Trans i18nKey="formation.remotely_accessible.base" /></label>
                                        <div className="dip-flex-row">
                                            <div className="p-field-radiobutton p-mr-5">
                                                <RadioButton inputId="ouiAccessibleADistance" name="ouiAccessibleADistance" value={campu.accessibleADistance} onChange={event => onRBChange(indexCampus, event, 'accessibleADistance', 'ouiAccessibleADistance')} checked={campu.accessibleADistance} />
                                                <label htmlFor="ouiAccessibleADistance"><Trans i18nKey="general.yes" /></label>
                                            </div>
                                            <div className="p-field-radiobutton">
                                                <RadioButton inputId="nonAccessibleADistance" name="nonAccessibleADistance" value={campu.accessibleADistance} onChange={event => onRBChange(indexCampus, event, 'accessibleADistance', 'ouiAccessibleADistance')} checked={!campu.accessibleADistance} />
                                                <label htmlFor="nonAccessibleADistance"><Trans i18nKey="general.no" /></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field p-col-12 p-lg-6">
                                        <label><Trans i18nKey="formation.presence" /></label>
                                        <div className="dip-flex-row">
                                            <div className="p-field-radiobutton p-mr-5">
                                                <RadioButton inputId="ouiPresentiel" name="ouiPresentiel" value={campu.presentiel} onChange={event => onRBChange(indexCampus, event, 'presentiel', 'ouiPresentiel')} checked={campu.presentiel} />
                                                <label htmlFor="ouiPresentiel"><Trans i18nKey="general.yes" /></label>
                                            </div>
                                            <div className="p-field-radiobutton">
                                                <RadioButton inputId="nonPresentiel" name="nonPresentiel" value={campu.presentiel} onChange={event => onRBChange(indexCampus, event, 'presentiel', 'ouiPresentiel')} checked={!campu.presentiel} />
                                                <label htmlFor="nonPresentiel"><Trans i18nKey="general.no" /></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field p-col-12 p-lg-6">
                                        <label><Trans i18nKey="formation.initial_training" /></label>
                                        <div className="dip-flex-row">
                                            <div className="p-field-radiobutton p-mr-5">
                                                <RadioButton inputId="ouiFormationInitiale" name="ouiFormationInitiale" value={campu.formationInitiale} onChange={event => onRBChange(indexCampus, event, 'formationInitiale', 'ouiFormationInitiale')} checked={campu.formationInitiale} />
                                                <label htmlFor="ouiFormationInitiale"><Trans i18nKey="general.yes" /></label>
                                            </div>
                                            <div className="p-field-radiobutton">
                                                <RadioButton inputId="nonFormationInitiale" name="nonFormationInitiale" value={campu.formationInitiale} onChange={event => onRBChange(indexCampus, event, 'formationInitiale', 'ouiFormationInitiale')} checked={!campu.formationInitiale} />
                                                <label htmlFor="nonFormationInitiale"><Trans i18nKey="general.no" /></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field p-col-12 p-lg-6">
                                        <label><Trans i18nKey="formation.continuous_training" /></label>
                                        <div className="dip-flex-row">
                                            <div className="p-field-radiobutton p-mr-5">
                                                <RadioButton inputId="ouiFormationContinue" name="ouiFormationContinue" value={campu.formationContinue} onChange={event => onRBChange(indexCampus, event, 'formationContinue', 'ouiFormationContinue')} checked={campu.formationContinue} />
                                                <label htmlFor="ouiFormationContinue"><Trans i18nKey="general.yes" /></label>
                                            </div>
                                            <div className="p-field-radiobutton">
                                                <RadioButton inputId="nonFormationContinue" name="nonFormationContinue" value={campu.formationContinue} onChange={event => onRBChange(indexCampus, event, 'formationContinue', 'ouiFormationContinue')} checked={!campu.formationContinue} />
                                                <label htmlFor="nonFormationContinue"><Trans i18nKey="general.no" /></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field p-col-12 p-lg-6">
                                        <label><Trans i18nKey="formation.alternation.base" /></label>
                                        <div className="dip-flex-row">
                                            <div className="p-field-radiobutton p-mr-5">
                                                <RadioButton inputId="ouiAlternance" name="ouiAlternance" value={campu.alternance} onChange={event => onRBChange(indexCampus, event, 'alternance', 'ouiAlternance')} checked={campu.alternance} />
                                                <label htmlFor="ouiAlternance"><Trans i18nKey="general.yes" /></label>
                                            </div>
                                            <div className="p-field-radiobutton">
                                                <RadioButton inputId="nonAlternance" name="nonAlternance" value={campu.alternance} onChange={event => onRBChange(indexCampus, event, 'alternance', 'ouiAlternance')} checked={!campu.alternance} />
                                                <label htmlFor="nonAlternance"><Trans i18nKey="general.no" /></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field p-col-12 p-lg-6">
                                    </div>
                                    <div className="p-field p-col-12 p-lg-6">
                                        <label><Trans i18nKey="formation.professionalization_contract" /></label>
                                        <div className="dip-flex-row">
                                            <div className="p-field-radiobutton p-mr-5">
                                                <RadioButton inputId="ouiContratProfessionnalisation" name="ouiContratProfessionnalisation" value={campu.contratPro} onChange={event => onRBChange(indexCampus, event, 'contratProfessionnalisation', 'ouiContratProfessionnalisation')} checked={campu.contratProfessionnalisation} />
                                                <label htmlFor="ouiContratProfessionnalisation"><Trans i18nKey="general.yes" /></label>
                                            </div>
                                            <div className="p-field-radiobutton">
                                                <RadioButton inputId="nonContratProfessionnalisation" name="nonContratProfessionnalisation" value={campu.contratPro} onChange={event => onRBChange(indexCampus, event, 'contratProfessionnalisation', 'ouiContratProfessionnalisation')} checked={!campu.contratProfessionnalisation} />
                                                <label htmlFor="nonContratProfessionnalisation"><Trans i18nKey="general.no" /></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field p-col-12 p-lg-6">
                                        <label><Trans i18nKey="formation.apprenticeship_contract" /></label>
                                        <div className="dip-flex-row">
                                            <div className="p-field-radiobutton p-mr-5">
                                                <RadioButton inputId="ouiContratApprentissage" name="ouiContratApprentissage" value={campu.contratApprentissage} onChange={event => onRBChange(indexCampus, event, 'contratApprentissage', 'ouiContratApprentissage')} checked={campu.contratApprentissage} />
                                                <label htmlFor="ouiContratApprentissage"><Trans i18nKey="general.yes" /></label>
                                            </div>
                                            <div className="p-field-radiobutton">
                                                <RadioButton inputId="nonContratApprentissage" name="nonContratApprentissage" value={campu.contratApprentissage} onChange={event => onRBChange(indexCampus, event, 'contratApprentissage', 'ouiContratApprentissage')} checked={!campu.contratApprentissage} />
                                                <label htmlFor="nonContratApprentissage"><Trans i18nKey="general.no" /></label>
                                            </div>
                                        </div>
                                    </div>
                                    {campu.alternance &&
                                        <>
                                            <div className="p-field p-col-12 p-lg-6">
                                                <label><Trans i18nKey="formation.alternance_rythme" /></label>
                                                <div className="dip-flex-row">
                                                    <div className="p-field-radiobutton p-mr-5">
                                                        <RadioButton inputId="daily" name="rythme" value={1} onChange={event => onCampusChange(indexCampus, event, 'rythme')} checked={campu.rythme === 1} />
                                                        <label htmlFor="daily"><Trans i18nKey="general.daily" /></label>
                                                    </div>
                                                    <div className="p-field-radiobutton">
                                                        <RadioButton inputId="weekly" name="rythme" value={2} onChange={event => onCampusChange(indexCampus, event, 'rythme')} checked={campu.rythme === 2} />
                                                        <label htmlFor="weekly"><Trans i18nKey="general.weekly" /></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-field p-col-12 p-lg-6">
                                            </div>
                                            <div className="p-field p-col-12 p-lg-6">
                                                <label htmlFor="rythmeEnEcole"><Trans i18nKey="formation.number_at_school" /></label>
                                                <InputNumber
                                                    id="rythmeEnEcole"
                                                    value={campu.rythmeEnEcole}
                                                    onChange={e => onCampusChange(indexCampus, e, 'rythmeEnEcole')}
                                                />
                                            </div>
                                            <div className="p-field p-col-12 p-lg-6">
                                                <label htmlFor="rythmeEnEntreprise"><Trans i18nKey="formation.number_at_company" /></label>
                                                <InputNumber
                                                    id="rythmeEnEntreprise"
                                                    value={campu.rythmeEnEntreprise}
                                                    onChange={e => onCampusChange(indexCampus, e, 'rythmeEnEntreprise')}
                                                />
                                            </div>
                                        </>
                                    }
                                </>
                                <Divider />
                            </Fragment>
                        ))}
                        <div className="p-col-12">
                            <Accordion>
                                <AccordionTab header={<label>{t('formation.target_jobs')}* {submitted && selectedAppellations[0].length < 1 && <small className="p-error"><Trans i18nKey="formation.formationAppellation_required" /></small>}</label>}>
                                    <PickListAppellations
                                        list={selectedAppellations}
                                        setList={setSelectedAppellations}
                                        listHeader={t("formation.target_jobs")}
                                        onAdd={addToSelectedAppellations}
                                    />
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <div className="p-col-12">
                            <Accordion>
                                <AccordionTab header={<label>{t('formation.base_skills')}* {submitted && (selectedCompBaseSavoir[0].length < 1 || selectedCompBaseSavoirFaire[0].length < 1) && <small className="p-error"><Trans i18nKey="formation.baseSkills_required" /></small>}</label>}>
                                    <PickListCompetencesSavoirSavoirFaireSplitted
                                        competenceType={enumCompetenceType.DEBASE}
                                        selectedCompSavoir={selectedCompBaseSavoir}
                                        setSelectedCompSavoir={setSelectedCompBaseSavoir}
                                        selectedCompSavoirFaire={selectedCompBaseSavoirFaire}
                                        setSelectedCompSavoirFaire={setSelectedCompBaseSavoirFaire}
                                        onCompSavoirAdd={addToSelectedCompBaseSavoir}
                                        onCompSavoirFaireAdd={addToSelectedCompBaseSavoirFaire}
                                        isCompetenceSavoirAlreadyInAnOtherList={isCompetenceSavoirAlreadyInAnOtherList}
                                        isCompetenceSavoirFaireAlreadyInAnOtherList={isCompetenceSavoirFaireAlreadyInAnOtherList}
                                        submitted={submitted}
                                    />
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <div className="p-col-12">
                            <Accordion>
                                <AccordionTab header={t('formation.specific_skills')}>
                                    <PickListCompetencesSavoirSavoirFaireSplitted
                                        competenceType={enumCompetenceType.SPECIFIQUE}
                                        selectedCompSavoir={selectedCompSpecifiqueSavoir}
                                        setSelectedCompSavoir={setSelectedCompSpecifiqueSavoir}
                                        selectedCompSavoirFaire={selectedCompSpecifiqueSavoirFaire}
                                        setSelectedCompSavoirFaire={setSelectedCompSpecifiqueSavoirFaire}
                                        onCompSavoirAdd={addToSelectedCompSpecifiqueSavoir}
                                        onCompSavoirFaireAdd={addToSelectedCompSpecifiqueSavoirFaire}
                                        isCompetenceSavoirAlreadyInAnOtherList={isCompetenceSavoirAlreadyInAnOtherList}
                                        isCompetenceSavoirFaireAlreadyInAnOtherList={isCompetenceSavoirFaireAlreadyInAnOtherList}
                                    />
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <div className="p-col-12">
                            <Accordion>
                                <AccordionTab header={t('skills.soft')}>
                                    <PickListCompetencesSavoirEtre
                                        list={selectedCompSavoirEtre}
                                        setList={setSelectedCompSavoirEtre}
                                        onAdd={addToSelectedCompSavoirEtre}
                                    />
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <div className="p-col-12">
                            <Accordion>
                                <AccordionTab header={<label>{t('formation.target_structures')}* {submitted && selectedStructures.length == 0 && <small className="p-error"><Trans i18nKey="formation.environnementDeTravail_required" /></small>}</label>}>
                                    <PickListEnvironnementDeTravail
                                        list={selectedStructures}
                                        setList={setSelectedStructures}
                                        onAdd={addToSelectedStructures}
                                        onRemove={handleRemoveStructure}
                                    />
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <div className="p-col-12">
                            <Accordion>
                                <AccordionTab header={t('formation.target_sectors')}>
                                    <PickListSecteurActivite
                                        list={selectedSecteurs}
                                        setList={setSelectedSecteurs}
                                        onAdd={addToSelectedSecteurs}
                                        onRemove={handleRemoveSecteur}
                                    />
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <Divider />
                        <div className="p-col-12">
                            <Accordion multiple>
                                <AccordionTab header={t('formation.description_for_student')}>
                                    <TabView className="vertical-tabview">

                                        <TabPanel header={<label htmlFor="descriptionVueEtudiant"><Trans i18nKey="formation.description" /></label>}>
                                            <TabView className="vertical-tabview">
                                                <TabPanel header={<label htmlFor="descriptionVueEtudiant"><Trans i18nKey="general.view_pc" /></label>}>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.descriptionVueEtudiant} onChange={(content, editor) => onEditorChange(content, editor, 'descriptionVueEtudiant')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                                <TabPanel header={<label htmlFor="descriptionVueEtudiantMobile"><Trans i18nKey="general.view_mobile" /></label>}>
                                                    <small><Trans i18nKey="formation.wysiwyg_same_default_content" /></small>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.descriptionVueEtudiantMobile} onChange={(content, editor) => onEditorChange(content, editor, 'descriptionVueEtudiantMobile')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                            </TabView>
                                        </TabPanel>

                                        <TabPanel header={<label htmlFor="structureProgramme"><Trans i18nKey="formation.programme_structure" /></label>}>
                                            <TabView className="vertical-tabview">
                                                <TabPanel header={<label htmlFor="structureProgramme"><Trans i18nKey="general.view_pc" /></label>}>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.structureProgramme} onChange={(content, editor) => onEditorChange(content, editor, 'structureProgramme')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                                <TabPanel header={<label htmlFor="structureProgrammeMobile"><Trans i18nKey="general.view_mobile" /></label>}>
                                                    <small><Trans i18nKey="formation.wysiwyg_same_default_content" /></small>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.structureProgrammeMobile} onChange={(content, editor) => onEditorChange(content, editor, 'structureProgrammeMobile')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                            </TabView>
                                        </TabPanel>

                                        <TabPanel header={<label htmlFor="prerequis"><Trans i18nKey="formation.prerequisites" /></label>}>
                                            <TabView className="vertical-tabview">
                                                <TabPanel header={<label htmlFor="prerequis"><Trans i18nKey="general.view_pc" /></label>}>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.prerequis} onChange={(content, editor) => onEditorChange(content, editor, 'prerequis')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                                <TabPanel header={<label htmlFor="prerequisMobile"><Trans i18nKey="general.view_mobile" /></label>}>
                                                    <small><Trans i18nKey="formation.wysiwyg_same_default_content" /></small>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.prerequisMobile} onChange={(content, editor) => onEditorChange(content, editor, 'prerequisMobile')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                            </TabView>
                                        </TabPanel>

                                        <TabPanel header={<label htmlFor="modalitesCandidature"><Trans i18nKey="formation.application_procedures" /></label>}>
                                            <TabView className="vertical-tabview">
                                                <TabPanel header={<label htmlFor="modalitesCandidature"><Trans i18nKey="general.view_pc" /></label>}>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.modalitesCandidature} onChange={(content, editor) => onEditorChange(content, editor, 'modalitesCandidature')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                                <TabPanel header={<label htmlFor="modalitesCandidatureMobile"><Trans i18nKey="general.view_mobile" /></label>}>
                                                    <small><Trans i18nKey="formation.wysiwyg_same_default_content" /></small>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.modalitesCandidatureMobile} onChange={(content, editor) => onEditorChange(content, editor, 'modalitesCandidatureMobile')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                            </TabView>
                                        </TabPanel>

                                        <TabPanel header={<label htmlFor="calendrier"><Trans i18nKey="formation.calendar" /></label>}>
                                            <TabView className="vertical-tabview">
                                                <TabPanel header={<label htmlFor="calendrier"><Trans i18nKey="general.view_pc" /></label>}>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.calendrier} onChange={(content, editor) => onEditorChange(content, editor, 'calendrier')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                                <TabPanel header={<label htmlFor="calendrierMobile"><Trans i18nKey="general.view_mobile" /></label>}>
                                                    <small><Trans i18nKey="formation.wysiwyg_same_default_content" /></small>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.calendrierMobile} onChange={(content, editor) => onEditorChange(content, editor, 'calendrierMobile')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                            </TabView>
                                        </TabPanel>

                                        <TabPanel header={<label htmlFor="descriptionAlternance"><Trans i18nKey="formation.alternation.base" /></label>}>
                                            <TabView className="vertical-tabview">
                                                <TabPanel header={<label htmlFor="descriptionAlternance"><Trans i18nKey="general.view_pc" /></label>}>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.descriptionAlternance} onChange={(content, editor) => onEditorChange(content, editor, 'descriptionAlternance')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                                <TabPanel header={<label htmlFor="descriptionAlternanceMobile"><Trans i18nKey="general.view_mobile" /></label>}>
                                                    <small><Trans i18nKey="formation.wysiwyg_same_default_content" /></small>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.descriptionAlternanceMobile} onChange={(content, editor) => onEditorChange(content, editor, 'descriptionAlternanceMobile')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                            </TabView>
                                        </TabPanel>
                                    </TabView>
                                </AccordionTab>
                                <AccordionTab header={<label>{t('formation.description_for_company')} {submitted &&
                                    (
                                        (Helper.isEmptyOrSpaces(formation.descriptionFormation[0].titre) && (!Helper.isEmptyOrSpaces(formation.descriptionFormation[0].contenu) || !Helper.isEmptyOrSpaces(formation.descriptionFormation[0].contenuMobile))) ||
                                        (!Helper.isEmptyOrSpaces(formation.descriptionFormation[0].titre) && (Helper.isEmptyOrSpaces(formation.descriptionFormation[0].contenu) && Helper.isEmptyOrSpaces(formation.descriptionFormation[0].contenuMobile))) ||
                                        (Helper.isEmptyOrSpaces(formation.descriptionFormation[1].titre) && (!Helper.isEmptyOrSpaces(formation.descriptionFormation[1].contenu) || !Helper.isEmptyOrSpaces(formation.descriptionFormation[1].contenuMobile))) ||
                                        (!Helper.isEmptyOrSpaces(formation.descriptionFormation[1].titre) && (Helper.isEmptyOrSpaces(formation.descriptionFormation[1].contenu) && Helper.isEmptyOrSpaces(formation.descriptionFormation[1].contenuMobile))) ||
                                        (Helper.isEmptyOrSpaces(formation.descriptionFormation[2].titre) && (!Helper.isEmptyOrSpaces(formation.descriptionFormation[2].contenu) || !Helper.isEmptyOrSpaces(formation.descriptionFormation[2].contenuMobile))) ||
                                        (!Helper.isEmptyOrSpaces(formation.descriptionFormation[2].titre) && (Helper.isEmptyOrSpaces(formation.descriptionFormation[2].contenu) && Helper.isEmptyOrSpaces(formation.descriptionFormation[2].contenuMobile))) ||
                                        (Helper.isEmptyOrSpaces(formation.descriptionFormation[3].titre) && (!Helper.isEmptyOrSpaces(formation.descriptionFormation[3].contenu) || !Helper.isEmptyOrSpaces(formation.descriptionFormation[3].contenuMobile))) ||
                                        (!Helper.isEmptyOrSpaces(formation.descriptionFormation[3].titre) && (Helper.isEmptyOrSpaces(formation.descriptionFormation[3].contenu) && Helper.isEmptyOrSpaces(formation.descriptionFormation[3].contenuMobile)))
                                    ) &&
                                    <small className="p-error"><Trans i18nKey="formation.description_isIncomplete" /></small>}</label>}>

                                    <TabView className="vertical-tabview">
                                        <TabPanel header={<label htmlFor="descriptionVueEntreprise"><Trans i18nKey="formation.description" /></label>}>
                                            <TabView className="vertical-tabview">
                                                <TabPanel header={<label htmlFor="descriptionVueEntreprise"><Trans i18nKey="general.view_pc" /></label>}>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.descriptionVueEntreprise} onChange={(content, editor) => onEditorChange(content, editor, 'descriptionVueEntreprise')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                                <TabPanel header={<label htmlFor="descriptionVueEntrepriseMobile"><Trans i18nKey="general.view_mobile" /></label>}>
                                                    <small><Trans i18nKey="formation.wysiwyg_same_default_content" /></small>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.descriptionVueEntrepriseMobile} onChange={(content, editor) => onEditorChange(content, editor, 'descriptionVueEntrepriseMobile')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                            </TabView>
                                        </TabPanel>

                                        <TabPanel header={<label htmlFor="profilEtudiants"><Trans i18nKey="formation.students_profile" /></label>}>
                                            <TabView className="vertical-tabview">
                                                <TabPanel header={<label htmlFor="profilEtudiants"><Trans i18nKey="general.view_pc" /></label>}>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.profilEtudiants} onChange={(content, editor) => onEditorChange(content, editor, 'profilEtudiants')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                                <TabPanel header={<label htmlFor="profilEtudiantsMobile"><Trans i18nKey="general.view_mobile" /></label>}>
                                                    <small><Trans i18nKey="formation.wysiwyg_same_default_content" /></small>
                                                    <Wysiwyg entityType={'formation'} entityId={formation.id} value={formation.profilEtudiantsMobile} onChange={(content, editor) => onEditorChange(content, editor, 'profilEtudiantsMobile')} Conf={enumConfWysiwyg.ULTRA} />
                                                </TabPanel>
                                            </TabView>
                                        </TabPanel>

                                        {formation.descriptionFormation?.map((descript, indexDescription) => (
                                            <TabPanel header={headerCustomDescription(descript, indexDescription)}>
                                                <TabView className="vertical-tabview">
                                                    <TabPanel header={t("general.view_pc")}>
                                                        <Wysiwyg entityType={'formation'} entityId={formation.id} value={descript.contenu} onChange={(content, editor) => handleDescriptionChange(indexDescription, content, editor, 'contenu')} Conf={enumConfWysiwyg.ULTRA} />
                                                        {
                                                            submitted &&
                                                            ((Helper.isEmptyOrSpaces(descript.titre) && !Helper.isEmptyOrSpaces(descript.contenu)) ||
                                                                (!Helper.isEmptyOrSpaces(descript.titre) && Helper.isEmptyOrSpaces(descript.contenu))) &&
                                                            <small className="p-error"><Trans i18nKey="formation.description_incomplete" /></small>
                                                        }
                                                    </TabPanel>
                                                    <TabPanel header={t("general.view_mobile")}>
                                                        <small><Trans i18nKey="formation.wysiwyg_same_default_content" /></small>
                                                        <Wysiwyg entityType={'formation'} entityId={formation.id} value={descript.contenuMobile} onChange={(content, editor) => handleDescriptionChange(indexDescription, content, editor, 'contenuMobile')} Conf={enumConfWysiwyg.ULTRA} />
                                                        {
                                                            submitted &&
                                                            ((Helper.isEmptyOrSpaces(descript.titre) && !Helper.isEmptyOrSpaces(descript.contenuMobile)) ||
                                                                (!Helper.isEmptyOrSpaces(descript.titre) && Helper.isEmptyOrSpaces(descript.contenuMobile))) &&
                                                            <small className="p-error"><Trans i18nKey="formation.description_incomplete" /></small>
                                                        }
                                                    </TabPanel>
                                                </TabView>
                                            </TabPanel>
                                        ))}
                                    </TabView>
                                </AccordionTab>
                            </Accordion>

                            <br/>

                            {(RoleHelper.isAdmin(currentUser) || RoleHelper.isSuperAdmin(currentUser))&& <Accordion className="fd-red-tab">
                                <AccordionTab header={t('formation.admin_notes')}>
                                    <Wysiwyg entityType={'formation'} entityId={formation.id}  value={formation.notes} onChange={(content, editor) => onEditorChange(content, editor, 'notes')} Conf={enumConfWysiwyg.STANDARD_UPLOAD_IMG} />
                                </AccordionTab>
                            </Accordion>}
                        </div>
                        <div className="p-field p-col-12 p-md-8">
                            <h4><Trans i18nKey='profile.files' />*</h4>
                            <FileUpload
                                ref={fileUploaderImageFormation}
                                mode="basic"
                                auto
                                customUpload={true}
                                maxFileSize={5000000}
                                invalidFileSizeMessageSummary={t('profile.invalidFileSizeMessageSummary')}
                                invalidFileSizeMessageDetail={t('profile.invalidFileSizeMessageDetail_5mb')}
                                chooseLabel={t('profile.photo_import')}
                                className="p-mr-2 p-d-inline-block"
                                name="imageFormation"
                                url=""
                                uploadHandler={onUploadImageFormation}
                                onSelect={onSelectImageFormation}
                                accept=".png,.jpg,.jpeg" />
                            {formation?.fileImage?.name &&
                                <span>{formation?.fileImage?.name}<Button
                                    icon="pi pi-times"
                                    className="p-button-rounded p-button-danger"
                                    onClick={() => handleRemoveImage()}
                                    style={{ left: "1em" }}
                                /></span>
                                ||
                                (formation?.image?.name &&
                                    <span>{formation?.image?.name}<Button
                                        icon="pi pi-times"
                                        className="p-button-rounded p-button-danger"
                                        onClick={() => handleRemoveFichierImage()}
                                        style={{ left: "1em" }}
                                    /></span>
                                )}
                        </div>
                        <div className="p-col-12">
                            {submitted && !formation.fileImage && !formation.image && <small className="p-error"><Trans i18nKey="formation.image_required" /></small>}
                            {!validImageType && formation.fileImage && <small className="p-error"><Trans i18nKey="formation.image_bad_type" /></small>}
                            {erreurImage && <small className="p-error"><Trans i18nKey="profile.invalidFileSizeMessageDetail_5mb" /></small>}
                        </div>
                        <div className="p-field p-col-12">
                            <h4><Trans i18nKey='editor.editors' /></h4>
                            {/* <MultiSelect id="ddlEditeurs" value={formation.editeur} options={ddlEditeurs} onChange={(e) => onEditeursChange(e)} optionLabel="nom" placeholder={t('formation.select_editor')} display="chip" filter={true} /> */}
                            {/* à vérifier que ce soit visible que pour les admins ! */}
                            <MultiSelect value={formation?.editeurEcole} options={ddlEditeurs} onChange={(e) => onEditeursChange(e)} optionLabel="nomComplet" placeholder={t('editor.select_one_or_many')}
                                virtualScrollerOptions={{
                                    lazy: true, onLazyLoad: onChargementDdlEditeurs, itemSize: 34, showLoader: true, loading: lazyLoadingDdlEditeurs, delay: 250, loadingTemplate: (options) => {
                                        return (
                                            <div className="p-d-flex p-ai-center p-p-2" style={{ height: '34px' }}>
                                                <Skeleton width={options.even ? '70%' : '60%'} height="1.5rem" />
                                            </div>
                                        )
                                    }
                                }} showSelectAll={true} />
                        </div>
                        {RoleHelper.isAdmin(currentUser) && <div className="p-col-12">
                            <DtFormationsDemandees selectedDemandeFormation={selectedDemandeFormation} onChange={onSelectedDemandeFormationChange} />
                        </div>}
                    </div>)}
            </Dialog>
        </div>
    )
}