import React, { useReducer } from "react";
import jwt_decode from "jwt-decode";

export const checkTokenExpiration = () => {
  const token = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")).token
    : "";
  if (token !== '') {
    if (jwt_decode(token).exp < Date.now() / 1000) {
      localStorage.clear();
    }
  }

};



//localStorage.clear();
checkTokenExpiration();

let currentUserStorage = localStorage.getItem("currentUser");
let user = currentUserStorage
  ? JSON.parse(currentUserStorage).user
  : "";
let token = currentUserStorage
  ? JSON.parse(currentUserStorage).token
  : "";
let isAuthenticated = currentUserStorage
  ? JSON.parse(currentUserStorage).isAuthenticated
  : false;

let roles = [];
if (token !== '') {
  Object.keys(jwt_decode(token)).forEach(key => {
    if (key.match(/.*role$/)) {
      let result = jwt_decode(token)[key]
      if (typeof result === 'string' || result instanceof String) {
        roles.push(result);
      }
      else {
        roles = result;
      }
    }
  });
}

export const initialState = {
  user: "" || user,
  token: "" || token,
  roles: "" || roles,
  loading: false,
  errorMessage: null,
  isAuthenticated: isAuthenticated,
};



export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
        isAuthenticated: false,
      };
      case "REQUEST_RENEW":
      return {
        ...initialState,
        loading: true
      };
      case "LOGIN_SUCCESS" :
      case "RENEW_SUCCESS" :
      let _roles = [];
      if (action.payload.token !== '') {
        Object.keys(jwt_decode(action.payload.token)).forEach(key => {
          if (key.match(/.*role$/)) {
            let result = jwt_decode(action.payload.token)[key];
            if (typeof result === 'string' || result instanceof String) {
              _roles.push(result);
            }
            else {
              _roles = result;
            }

          }
        });
      }
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.token,
        roles: _roles,
        loading: false,
        isAuthenticated: true,
      };
    case "LOGOUT":
      return {
        ...initialState,
        user: "",
        token: "",
        roles: "",
        errorMessage: "",
        isAuthenticated: false,
      };

  case "LOGIN_ERROR":
    return {
      ...initialState,
      loading: false,
      errorMessage: action.error,
      isAuthenticated: false,
    };
    case "RENEW_ERROR":
      return {
        ...initialState,
        loading: false
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

