import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import FormationService from '../../services/formationService';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import { DT_Formation_actions } from './dt_formation_actions';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';



export const DT_Formation = (props) => {

    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalCount] = useState(0);
    const [data, setData] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 25,
        page: 0,
        sortField: props.ecoleId ? 'composante' :'diplome',
        sortOrder: 1
    });

    const dt = useRef(null);

    const { t } = useTranslation();

    const formationService = new FormationService();

    useEffect(() => {
        loadLazyData();
    }, [lazyParams, props.refreshToken]) // eslint-disable-line react-hooks/exhaustive-deps


    const loadLazyData = () => {
        setLoading(true);
        if(props.ecoleId==null){
            formationService.getFormationsOrphelines(lazyParams)
            .then(data => {
                setTotalCount(data.totalCount);
                setData(data.items)
            })
            .catch(err => showError())
            .finally(() => setLoading(false))
        }else{
            formationService.getFormationForEcole(props.ecoleId, lazyParams)
            .then(data => {
                setTotalCount(data.totalCount);
                setData(data.items);
            })
            .catch(err => showError())
            .finally(() => setLoading(false))
        }
        
    }

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        _lazyParams['page'] = 0;
        _lazyParams['first'] = 0;
        setLazyParams(_lazyParams);
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <DT_Formation_actions formation={rowData}
                    onFormationDelete={loadLazyData}
                    openDuplicateFormationDialog={props.openDuplicateFormationDialog}
                    openEditFormationDialog={props.openEditFormationDialog}
                />
            </>
        )
    }

    const headerTemplate = (
        <div className="table-header">
            <h5 className="title">{props.title}</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
            </span>
        </div>
    );

    return (
        <div>
            <Toast ref={toast} />
            <div>
                <div className="card">
                    <DataTable ref={dt} value={data} lazy
                        filterDelay={1000}
                        paginator first={lazyParams.first} rows={lazyParams.rows} totalRecords={totalRecords} onPage={onPage}
                        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                        onFilter={onFilter} filters={lazyParams.filters} loading={loading}
                        emptyMessage={t('formation.no_formation_to_show')}
                        globalFilter={globalFilter} header={headerTemplate}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    currentPageReportTemplate={t('formation.footer_count_pagin')} 
                    >
                        {props.ecoleId && <Column field="composante" header={t('general.component')} sortable></Column>}
                        <Column field="diplome" header={t('formation.diploma')} sortable></Column>
                        <Column field="mention" header={t('formation.name')} sortable></Column>
                        <Column field="parcours" header={t('formation.course')} sortable></Column>
                        {/*
                         <Column field="editeurPlateforme" header={t('formation.platform_publishers')} sortable></Column>
                        <Column field="editeurEcole" header={t('formation.school_publishers')} sortable></Column> */}
                        <Column field="nbEtudiants" header={t('formation.students_number')} sortable></Column>
                        <Column field="annoncesLiees" header={t('formation.linked_ads')} sortable></Column>
                        <Column headerStyle={{ width: '6rem' }} body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div >
    );

}
