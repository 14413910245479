import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImportExport } from '../components/fileUploader/importExport';
import ImportExportService from '../services/importExportService';
import { Import } from '../components/fileUploader/import';

export const ImportExportAdminPage = () => {

    const { t } = useTranslation();

    const importExportService = new ImportExportService();

    return (
        <div className='p-grid'>
            <div className='p-col-12'>
                <ImportExport
                    importTitle={t("administrator.import_rome")}
                    exportTitle={t("administrator.export_rome")}
                    tooltipText={t('administrator.import_rome_tooltip')}
                    promise={importExportService.importROME}
                    thenPromise={importExportService.getRebuildIndexFunnelMetier}
                />
            </div>
            <div className='p-col-12'>
                <ImportExport
                    importTitle={t("administrator.import_ac")}
                    exportTitle={t("administrator.export_ac")}
                    promise={importExportService.importAppellationCompetence}
                />
            </div>
            <div className='p-col-12 p-sm-12 p-lg-6'>
                <Import
                    importTitle={t("administrator.import_formation")}
                    promise={importExportService.importDuplicationFormation}
                />
            </div>
        </div>
    )
}