import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { DisplayImage } from '../../../components/image/displayImage';
import { useAuthState } from '../../../context/context';
import "./ecoleUserHeader.css"

export const EcoleUserHeader = (props) => {
    const currentUser = useAuthState();
    const isAdministrateurEcole = currentUser.roles.indexOf('AdministrateurEcole') > -1;
    const isAdministrateurComposante = currentUser.roles.indexOf('AdministrateurComposante') > -1;
    const isEditeur = currentUser.roles.indexOf('Editeur') > -1;
    const [hasImage, setHasImage] = useState(isAdministrateurEcole && props.user.ecole?.logoId || isAdministrateurComposante && props.user.composanteEcole[0]?.logoId);


    return (
        <div className={props.className}>
            <div className="p-grid">
                {hasImage && <div className="p-col-12 p-sm-12 p-lg-3 logo-for-school">
                    {isAdministrateurEcole && props.user.ecole?.logoId && <DisplayImage  imageId={props.user.ecole?.logoId} />}
                    {isAdministrateurComposante && props.user.composanteEcole[0]?.logoId && <DisplayImage imageId={props.user.composanteEcole[0]?.logoId} />}
                </div>}
                <div className={hasImage ? ("p-col-12 p-sm-12 p-lg-9") : ("p-col-12")}>
                    <div className="p-grid profile-header-content">
                        <div className="p-col-12 credentials"><span>{props.user?.prenom} {props.user?.nom}</span></div>
                        <div className="p-col-12 role-display">
                            <span>
                                {(isAdministrateurEcole || isAdministrateurComposante) && <Trans i18nKey="role.administrator" />}
                                {isEditeur && <Trans i18nKey="role.editor" />}
                            </span>
                        </div>
                        <div className="p-col-12">
                            {isAdministrateurComposante && <span>{props.user?.composanteEcole[0]?.nom}</span>}
                        </div>
                        <div className="p-col-12">
                            <span className="school-name">{props.user?.ecole?.nomEcole}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}