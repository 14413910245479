import React, { useEffect, useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import Password_Forgot from '../password/password_forgot';
import 'primeflex/primeflex.css';
import './formLoginStudent.css';
import './formLoginCompany.css';
import { useHistory } from 'react-router-dom';
import { useAuthState, useAuthDispatch } from '../../context/context';
import { loginUser } from '../../context/actions';
import { Toast } from 'primereact/toast';
import 'primeicons/primeicons.css';
import { enumRoles } from '../../enums/enumRoles';
import { enumHttpError } from '../../enums/enumHttpError';
import RoleHelper from '../../utils/roleHelper';

export const FormLogin = (props) => {
    const [userEmail, setUserEmail] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();
    const dispatch = useAuthDispatch();
    const { loading, errorMessage } = useAuthState();
    const toast = useRef(null);
    const [cssType, setCssType] = useState('fl-etudiant');

    const { t } = useTranslation();

    useEffect (() => {
        if (props.userType == enumRoles.ETUDIANT) {
            setCssType('fl-etudiant');
        }
        else if (props.userType == enumRoles.ENTREPRISE) {
            setCssType('fl-entreprise');
        }
    }, [props.userType]);


    const showFailedLogin = () => {
        toast.current.show({ severity: 'error', summary: t('login.login_failed'), detail: t('login.wrong_credentials'), life: 3000 });
    }

    const showDisabled = () => {
        toast.current.show({ severity: 'info', summary: t('login.login_failed'), detail: t('login.disabled'), life: 3000 });
    }

    const showArchived = () => {
        toast.current.show({ severity: 'warn', summary: t('login.login_failed'), detail: t('login.archived'), life: 3000 });
    }

    const handleLogin = async (e) => {
        let payload = { userEmail, password }
        try {
            await loginUser(dispatch, payload);
            let _currentUser = JSON.parse(localStorage.getItem("currentUser"))
            if (_currentUser?.user) {
                let _roles = RoleHelper.decodeRoles(_currentUser.token);
                _currentUser.roles = _roles
                if (RoleHelper.isEtudiant(_currentUser) && (history.location.pathname == "/entreprise" || history.location.pathname == "/")) {
                    history.push('/offresCompatibles');
                }
                else if (RoleHelper.isEtudiant(_currentUser) && _currentUser.user.isRegistrationOnboardingCompleted == false) {
                    history.push('/offresCompatibles');
                    props.showOnboarding();
                }
                else if(RoleHelper.isEtudiant(_currentUser) && history.location.pathname =="/register?User=Student" && _currentUser.user.isRegistrationOnboardingCompleted == true){
                    history.push('/offresCompatibles');
                }
                else if (RoleHelper.isEntreprise(_currentUser))
                {
                    if(props.urlBackLogin || history.location.pathname.match(/\/Candidat\/[a-zA-Z0-9]+\/[a-zA-Z0-9-_]+/) != null)
                        history.push(props.urlBackLogin ? props.urlBackLogin : history.location.pathname);
                    else
                        history.push('/offers')
                }
            }
            let _errorMessage = { ...errorMessage };
            if (_errorMessage.response.data.message == enumHttpError.BADLOGIN) {
                showFailedLogin();
            }
            else if (_errorMessage.response.data.message == enumHttpError.ACCOUNTDISABLED) {
                showDisabled();
            }
            else if (_errorMessage.response.data.message == enumHttpError.ACCOUNTARCHIVED) {
                showArchived();
            }
            else {
                history.push('/');
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div>
            <Toast ref={toast} />
            <div className={`p-fluid fl-form ${cssType}`} >
                <div className="p-field">
                    <label htmlFor="userEmail"><Trans i18nKey="login.email" /></label><label>*</label>
                    <InputText 
                        name="userEmail" 
                        // placeholder="email" 
                        value={userEmail} 
                        onChange={(e) => setUserEmail(e.target.value)} 
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="password"><Trans i18nKey="login.password" /></label><label>*</label>
                    <Password 
                        name="password" 
                        // placeholder="password" 
                        type="password" 
                        feedback="" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                    />
                </div>

                <Button className="p-button-raised p-button-rounded center" disabled={loading} onClick={handleLogin}>
                    <Trans i18nKey="login.log_in" />
                </Button>
                <div>
                    &nbsp;
                </div>
                <Password_Forgot />
            </div>
        </div>
    )
}
