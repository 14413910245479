import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import FormationService from '../../services/formationService';
import { useAuthState } from "../../context/context";
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import EcoleService from '../../services/ecoleService';
import { useHistory } from 'react-router-dom';


export const DT_Composantes_actions = (props) => {

    const history = useHistory()

    const { t } = useTranslation();

    const onEditFiche = () => {
        history.push('/edition_fiche_composante', { composanteEcoleId: props.composanteEcole.id })
    }

    return (
        <React.Fragment>
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={onEditFiche} tooltip={t('general.edit_profile')} />
        </React.Fragment>
    );
}