import { enumOrganismeFormationCategorie } from "../../enums/enumOrganismeFormationCategorie"
import { VueOrganismeFormation } from "./vueOrganismeFormation"

export const VueComposanteEcole = (props) => {

    return (
        <VueOrganismeFormation
            className={props.className}
            organismeFormationId={props.match.params.Id}
            organismeFormationCategorie={enumOrganismeFormationCategorie.COMPOSANTEECOLE}
        />
    )
}