import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import RegistrationOnboardingService from '../../services/registrationOnboardingService';
import { Trans, useTranslation } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import './StudentRegistrationOnboardingDialog.css';
import { useHistory } from 'react-router-dom';
import { enumUserOnboardingStepName } from '../../enums/enumUserOnboardingStepName';
import { RobEnd } from './steps/RobEnd';
import FormationHelper from '../../utils/formationHelper';
import { RobIntro } from './steps/RobIntro';
import { Dialog } from 'primereact/dialog';
import { RobChoix } from './steps/RobChoix';
import { RobContrat } from './steps/RobContrat';
import { RobFormation } from './steps/RobFormation';
import { RobFooterButtons } from './RobFooterButtons';
import CommuneService from '../../services/communeService';


export const StudentRegistrationOnboardingDialog = (props) => {
    const toast = useRef(null);
    const { t } = useTranslation();

    const history = useHistory()

    const registrationOnboardingService = new RegistrationOnboardingService();

    const communeService = new CommuneService();

    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false)
    const [etudiant, setEtudiant] = useState(null);
    const [stepIterator, setStepIterator] = useState(0)
    const [maxStep, setMaxStep] = useState(3)
    const [currentStepName, setCurrentStepName] = useState(enumUserOnboardingStepName.INTRO);
    const [mobiliteTree, setMobiliteTree] = useState([])
    const [selectedMobilites, setSelectedMobilites] = useState(null)
    const [formationToAdd, setFormationToAdd] = useState(null);
    const [isFormationSelected, setIsFormationSelected] = useState(false);



    useEffect(() => {
        registrationOnboardingService.getEtudiantOnboardingSteps()
            .then(data => {
                setEtudiant(updateContractTranslation(data))
                setupSelectedMobilites(data)
                setMaxStep(data.onboardingChoice == 2 ? (setMaxStep(3)) : (setMaxStep(2)))
                setupCurrentStep(data)
                setLoading(false)
            })
            .catch(err => {
                history.push("/")
            })
        communeService.getMobiliteAsTree()
            .then((res) => {
                setupMobiliteTree(res)
            })
    }, [])

    useEffect(() => {
        window.scroll(0, 0);
    }, [currentStepName])

    const setupSelectedMobilites = (etudiant) => {
        let _selectedMobilites = {}
        if (etudiant.mobiliteInternationale == true) {
            _selectedMobilites.International = { checked: true, partialChecked: false }
        }
        if (etudiant.mobiliteFrancaise == true) {
            _selectedMobilites.France = { checked: true, partialChecked: false }
        }
        if (etudiant.mobiliteDepartement.length > 0 || etudiant.mobiliteRegion.length > 0) {
            _selectedMobilites.France = { checked: false, partialChecked: true }
        }
        etudiant.mobiliteRegion.forEach(r => {
            _selectedMobilites[`${r.id}`] = { checked: true, partialChecked: false }
        });
        etudiant.mobiliteDepartement.forEach(r => {
            _selectedMobilites[`${r.regionId}`] = { checked: false, partialChecked: true };
            _selectedMobilites[`${r.regionId}-${r.id}`] = { checked: true, partialChecked: false };
        });
        setSelectedMobilites(_selectedMobilites);
    }

    const setupMobiliteTree = (regions) => {
        let tree = [];
        tree.push({ key: "International", label: t('geography.international_except_france'), data: "International" });
        let france = { key: "France", label: t('geography.france'), data: "France" };
        let childrenFrance = [];
        regions.forEach(r => {
            let region = { key: `${r.id}`, label: r.libelle, data: r.id }
            let childrenRegion = []
            r.departements.forEach(d => {
                let departement = { key: r.id + "-" + d.id, label: d.code + " - " + d.libelle, data: d.id }
                childrenRegion.push(departement);
            });
            region.children = childrenRegion;
            childrenFrance.push(region);
        });
        france.children = childrenFrance;
        tree.push(france);
        setMobiliteTree(tree);
    }

    const setupCurrentStep = (etudiant) => {
        if (etudiant.userOnboardingSteps.length > 0) {
            let lastStep = etudiant.userOnboardingSteps.reduce((max, obj) => (max.stepOrder > obj.stepOrder) ? max : obj);
            switch (lastStep.stepName) {
                case enumUserOnboardingStepName.INTRO:
                    setCurrentStepName(enumUserOnboardingStepName.CHOIX);
                    updateProgression(enumUserOnboardingStepName.CHOIX);
                    break;
                case enumUserOnboardingStepName.CHOIX:
                    if (etudiant.onboardingChoice == 1) {
                        setCurrentStepName(enumUserOnboardingStepName.FORMATION);
                        updateProgression(enumUserOnboardingStepName.FORMATION, etudiant);
                    }
                    else if (etudiant.onboardingChoice == 2) {
                        setCurrentStepName(enumUserOnboardingStepName.CONTRAT);
                        updateProgression(enumUserOnboardingStepName.CONTRAT);
                    }
                    break;
                case enumUserOnboardingStepName.CONTRAT:
                    setCurrentStepName(enumUserOnboardingStepName.FORMATION);
                    updateProgression(enumUserOnboardingStepName.FORMATION, etudiant);
                    break;
                case enumUserOnboardingStepName.FORMATION:
                    setCurrentStepName(enumUserOnboardingStepName.FIN);
                    updateProgression(enumUserOnboardingStepName.FIN, etudiant);
                case enumUserOnboardingStepName.FIN:
                    setCurrentStepName(enumUserOnboardingStepName.FIN);
                    updateProgression(enumUserOnboardingStepName.FIN, etudiant);
                    break;
            }
        }
        else {
            setCurrentStepName(enumUserOnboardingStepName.INTRO)
            updateProgression(enumUserOnboardingStepName.INTRO);
        }

    }


    const onFormationClick = () => {
        setSubmitting(true)
        setMaxStep(2)
        let _etudiant = { ...etudiant }
        _etudiant.onboardingChoice = 1;
        let model = {}
        model.etudiant = _etudiant
        model.userOnBoardingStep = {
            userId: _etudiant.id,
            onboardingType: 1,
            stepName: enumUserOnboardingStepName.CHOIX,
            onboardingCompletion: 2
        }
        registrationOnboardingService.etudiantRegistrationStepForward(model)
            .then(data => {
                setEtudiant(updateContractTranslation(data))
                setCurrentStepName(enumUserOnboardingStepName.FORMATION);
                updateProgression(enumUserOnboardingStepName.FORMATION, data);
            })
            .catch(err => {

            }).finally(() => {
                setSubmitting(false)
            });
    }

    const onEmploiClick = () => {
        setSubmitting(true)
        setMaxStep(3)
        let _etudiant = { ...etudiant }
        _etudiant.onboardingChoice = 2;
        _etudiant.rechercheActive = true;
        let model = {}
        model.etudiant = _etudiant
        model.userOnBoardingStep = {
            userId: _etudiant.id,
            onboardingType: 1,
            stepName: enumUserOnboardingStepName.CHOIX,
            onboardingCompletion: 2
        }
        registrationOnboardingService.etudiantRegistrationStepForward(model)
            .then(data => {
                setEtudiant(updateContractTranslation(data))
                setCurrentStepName(enumUserOnboardingStepName.CONTRAT);
                updateProgression(enumUserOnboardingStepName.CONTRAT);
            })
            .catch(err => {

            }).finally(() => {
                setSubmitting(false)
            });
    }

    const onAddFormationClick = () => {
        setSubmitting(true);
        let _etudiant = {...etudiant}
        let model = {}
        let _userOnBoardingStep = {
            userId: _etudiant.id,
            onboardingType: 1,
            stepName: enumUserOnboardingStepName.FORMATION,
        }
        if (isFormationSelected) {
            _userOnBoardingStep.onboardingCompletion = 2
            let _formation = { ...formationToAdd }
            if (typeof (_formation.organismeFormation) === 'object' && typeof (_formation.diplome) === 'object' && typeof (_formation.formation) === 'object') {
                _formation.organismeFormation = _formation.organismeFormation.nom
                _formation.diplome = _formation.diplome.nom
                _formation.nomComplet = _formation.formation.nomComplet
                _formation.id = _formation.formation.id
                _etudiant = FormationHelper.dispatchFormationsOnEtudiant({ ...etudiant }, [_formation], [])

            }
            else {
                if (typeof (_formation.organismeFormation) === 'object') {
                    _formation.organismeFormation = _formation.organismeFormation.nom
                }
                if (typeof (_formation.diplome) === 'object') {
                    _formation.diplome = _formation.diplome.nom
                }
                _formation.id = null
                _formation.nomComplet = _formation.formation;
                _etudiant = FormationHelper.dispatchFormationsOnEtudiant({ ...etudiant }, [], [_formation])
            }


        } else {
            _userOnBoardingStep.onboardingCompletion = 1
        }
        model.etudiant = _etudiant
        model.userOnBoardingStep = _userOnBoardingStep
        registrationOnboardingService.etudiantRegistrationStepForward(model)
            .then(data => {
                setEtudiant(updateContractTranslation(data))
                setCurrentStepName(enumUserOnboardingStepName.FIN);
                updateProgression(enumUserOnboardingStepName.FIN, data);
            })
            .catch(err => {

            }).finally(() => {
                setSubmitting(false)
            });
    }

    const onPreviousClick = () => {
        setSubmitting(true)
        registrationOnboardingService.RegistrationStepBackward()
            .then(data => {
                if (data.stepName) {
                    setCurrentStepName(data.stepName);
                    updateProgression(data.stepName,data);
                }
                else {
                    setCurrentStepName(enumUserOnboardingStepName.INTRO);
                    updateProgression(enumUserOnboardingStepName.INTRO);
                }
            })
            .catch(err => {

            }).finally(() => {
                setSubmitting(false)
            });
    }

    const onNextClick = () => {
        switch (currentStepName) {
            case enumUserOnboardingStepName.CONTRAT:
                onContratValidation();
                break;
            case enumUserOnboardingStepName.FORMATION:
                onAddFormationClick();
                break;
            case enumUserOnboardingStepName.FIN:
                if (etudiant.onboardingChoice == 1) {
                    setStepIterator(3)
                }
                else if (etudiant.onboardingChoice == 2) {
                    setStepIterator(4)
                }
                break;
            default:
                break;
        }
    }

    const updateProgression = (currentStep, etudiant) => {
        switch (currentStep) {
            case enumUserOnboardingStepName.INTRO:
                setStepIterator(0)
                break;
            case enumUserOnboardingStepName.CHOIX:
                setStepIterator(0)
                break;
            case enumUserOnboardingStepName.CONTRAT:
                setStepIterator(1)
                break;
            case enumUserOnboardingStepName.FORMATION:
                if (etudiant.onboardingChoice == 1) {
                    setStepIterator(1)
                }
                else if (etudiant.onboardingChoice == 2) {
                    setStepIterator(2)
                }
                else {
                    setStepIterator(stepIterator - 1)
                }
                break;
            case enumUserOnboardingStepName.FIN:
                if (etudiant.onboardingChoice == 1) {
                    setStepIterator(2)
                }
                else if (etudiant.onboardingChoice == 2) {
                    setStepIterator(3)
                }
                else {
                    setStepIterator(stepIterator - 1)
                }
                break;
            default:
                setStepIterator(0)
                break;
        }
    }

    const updateContractTranslation = (etudiant) => {
        etudiant.paireContrats.forEach(e => {
            e.libelle = t(`enums.paireContrat.${e.code}`);
        });
        return etudiant
    }

    const onLetsGoClick = () => {
        setSubmitting(true)
        let _etudiant = { ...etudiant }
        let model = {}
        model.etudiant = _etudiant
        model.userOnBoardingStep = {
            userId: _etudiant.id,
            onboardingType: 1,
            stepName: enumUserOnboardingStepName.INTRO,
            onboardingCompletion: 2
        }
        registrationOnboardingService.etudiantRegistrationStepForward(model)
            .then(data => {
                setEtudiant(updateContractTranslation(data))
                setCurrentStepName(enumUserOnboardingStepName.CHOIX);
                updateProgression(enumUserOnboardingStepName.CHOIX);
            })
            .catch(err => {

            }).finally(() => {
                setSubmitting(false)
            });
    }

    const onContratValidation = () => {
        setSubmitting(true)
        let _etudiant = { ...etudiant }
        _etudiant.mobiliteInternationale = false;
        _etudiant.mobiliteFrancaise = false;
        let _mobiliteRegion = [];
        let _mobiliteDepartement = [];
        Object.entries(selectedMobilites).forEach(row => {
            if (row[1].checked == true) {
                if (row[0] == "International") {
                    _etudiant.mobiliteInternationale = true;
                } else if (row[0] == "France") {
                    _etudiant.mobiliteFrancaise = true;
                } else {
                    let parts = row[0].split('-')
                    if (parts.length == 1) {
                        let _id = parseInt(parts[0])
                        _mobiliteRegion.push({ id: _id });
                    }
                    else if (parts.length == 2) {
                        let _id = parseInt(parts[1])
                        _mobiliteDepartement.push({ id: _id });
                    }
                }
            }

        })
        _etudiant.mobiliteRegion = _mobiliteRegion;
        _etudiant.mobiliteDepartement = _mobiliteDepartement;
        let model = {}
        model.etudiant = _etudiant
        model.userOnBoardingStep = {
            userId: _etudiant.id,
            onboardingType: 1,
            stepName: enumUserOnboardingStepName.CONTRAT,
            onboardingCompletion: 2
        }
        registrationOnboardingService.etudiantRegistrationStepForward(model)
            .then(data => {
                setEtudiant(updateContractTranslation(data))
                setCurrentStepName(enumUserOnboardingStepName.FORMATION);
                updateProgression(enumUserOnboardingStepName.FORMATION,data);
            })
            .catch(err => {

            }).finally(() => {
                setSubmitting(false)
            });
    }

    const onReachingOnboardingEnd = () => {
        setSubmitting(true)
        let _etudiant = { ...etudiant }
        _etudiant.isRegistrationOnboardingCompleted = true
        let _currentUser = JSON.parse(localStorage.getItem("currentUser"))
        _currentUser.user.isRegistrationOnboardingCompleted = true
        localStorage.setItem("currentUser", JSON.stringify(_currentUser))
        let model = {}
        model.etudiant = _etudiant
        model.userOnBoardingStep = {
            userId: _etudiant.id,
            onboardingType: 1,
            stepName: enumUserOnboardingStepName.FIN,
            onboardingCompletion: 2
        }
        registrationOnboardingService.etudiantRegistrationStepForward(model)
            .then(data => {
                setEtudiant(updateContractTranslation(data))
            })
            .catch(err => {
            }).finally(() => {
                setSubmitting(false)
            });
    }

    const onSetEndTracking = () => {
        props.setVisible(false)
    }

    const footer = (
        currentStepName != enumUserOnboardingStepName.INTRO && currentStepName != enumUserOnboardingStepName.CHOIX &&
        <RobFooterButtons
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
            onEndClick={() => onSetEndTracking()}
            submitting={submitting}
            isEnd={currentStepName == enumUserOnboardingStepName.FIN}
        />
    )

    return (
        <div>
            <Dialog visible={props.visible} className="rob-dialog" modal onHide={() => props.setVisible(false)} footer={footer} blockScroll closable={currentStepName !== enumUserOnboardingStepName.FIN}>
                <Toast ref={toast} />
                <div className={`dip-h-100 dip-flex-column ${currentStepName !== enumUserOnboardingStepName.FORMATION && currentStepName !== enumUserOnboardingStepName.FIN && "dip-pc-center"}`}>
                    {loading ? (
                        <div className="dip-ta-center">
                            <ProgressSpinner />
                        </div>
                    ) : (
                        <>
                            {currentStepName != enumUserOnboardingStepName.INTRO && <div className='dip-ta-center'>
                                <h1 className='rob-header'><Trans i18nKey="onboarding.complete_your_profile" /></h1>
                                <ProgressBar
                                    className="p-mb-4 rob-progress-bar"
                                    value={stepIterator * 100 / maxStep}
                                    showValue={false}
                                />
                            </div>}
                            <div className={`dip-h-100 dip-flex-column ${currentStepName !== enumUserOnboardingStepName.FORMATION && currentStepName !== enumUserOnboardingStepName.FIN && "dip-pc-center"}`}>
                                {currentStepName == enumUserOnboardingStepName.INTRO && <RobIntro
                                    onNextClick={onLetsGoClick}
                                    submitting={submitting}
                                />}
                                {currentStepName == enumUserOnboardingStepName.CHOIX && <RobChoix
                                    yesClick={onEmploiClick}
                                    noClick={onFormationClick}
                                    submitting={submitting}
                                />}
                                {currentStepName == enumUserOnboardingStepName.CONTRAT && <RobContrat
                                    etudiant={etudiant}
                                    setEtudiant={setEtudiant}
                                    mobiliteTree={mobiliteTree}
                                    setMobiliteTree={setMobiliteTree}
                                    selectedMobilites={selectedMobilites}
                                    setSelectedMobilites={setSelectedMobilites}
                                    submitting={submitting}
                                />}
                                {currentStepName == enumUserOnboardingStepName.FORMATION && <RobFormation
                                    etudiant={etudiant}
                                    setEtudiant={setEtudiant}
                                    formationToAdd={formationToAdd}
                                    setFormationToAdd={setFormationToAdd}
                                    isFormationSelected={isFormationSelected}
                                    setIsFormationSelected={setIsFormationSelected}
                                    submitting={submitting}
                                />}
                                {currentStepName == enumUserOnboardingStepName.FIN && <RobEnd
                                    onboardingChoice={etudiant.onboardingChoice}
                                    onReachingOnboardingEnd={onReachingOnboardingEnd}
                                    onEndClick={() => onSetEndTracking()}
                                    etudiant={etudiant}
                                />}
                            </div>
                        </>)}
                </div>
            </Dialog>
        </div>


    )
}
