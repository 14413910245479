import { Card } from "primereact/card"
import { useTranslation } from "react-i18next"
import './listeLibelleThumbnail.css'
import { Listelibelle } from "../list/listeLibelle"

export const ListeLibelleThumbnail = (props) => {

    const { t } = useTranslation();

    const header =
        <h1 className="vue-candidat-card-title">
            {props.tabLibelles.length > 0 && props.titleplural ? props.titleplural : props.title}
        </h1>


    return (
        <>
            <Card className="p-shadow-5 listelibelle-thumbnail-card" header={header}>
                {props.tabLibelles.map((l, indexL) => {
                    return (
                        <>
                            {props.subTitles ? (<h4 className="listelibelle-thumbnail-subtitle">{props.subTitles[indexL]}</h4>) : ('')}
                            <Listelibelle
                                display={props.display}
                                preview={props.preview}
                                libelles={props.tabLibelles[indexL]}
                            />
                            {indexL != props.tabLibelles.length - 1 && <br />}
                        </>
                    )
                })}

            </Card>
        </>
    )
}