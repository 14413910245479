export const LieuResidenceFormater = (props) => {

    if (props.etudiant.etranger) {
        return props.etudiant.lieuResidence + " (" + props.etudiant.pays + ")"
    }
    else {
        if (props.etudiant.commune) {
            return props.etudiant.commune?.libelle + " (" + props.etudiant.commune?.departement?.code + ")"
        }
        else return ""
    }

}