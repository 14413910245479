import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { HowItMatch } from '../headerHome/howItMatch/howItMatch';
import './bodyHomeNotConnected.css'
import { LetsGoModule } from './letsGoModule';

export const BodyHomeNotConnected = () => {

    return (
        <div className='p-mt-4 p-mb-2'>
            <HowItMatch />
            <LetsGoModule />
        </div>
    )
};