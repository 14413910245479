import React from 'react';
import { useTranslation } from 'react-i18next';
import "./menuSearchFilter.css"
import "./../../../pages/recrutement/menu/menuSearchFilter.css"
import { MenuAccordionSearchFilter } from './menuAccordionSearchFilter';
import { Button } from 'primereact/button';

export const MenuSearchFilter = (props) => {
    
    const { t } = useTranslation();
    

    return (
        <div className={props.className}>
                <div className='msf-accordion'>
                    <div className="tab-main-title-filter">{t('general.filters')}</div>
                    <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label={t('search.erase_all_filters')}
                        className="p-button-rounded msf-erase-filters"
                        onClick={props.handleClicEffacerFiltres} 
                    />
                    <MenuAccordionSearchFilter activeIndex={props.activeIndex} setActiveIndex={props.setActiveIndex} isInFavoris={props.isInFavoris}/>
                </div>
        </div>
    )
}
