import { Trans, useTranslation } from "react-i18next";
import { JobLocationFormater } from "../formater/jobLocationFormater";
import { LikeOffreEmploi } from "../likeoffreemploi/likeoffreemploi";
import { Link, useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { DurationFormater } from "../formater/durationFormater";
import { DipTag } from "../tag/dipTag";

export const OffreEmploiThumbnail = (props) => {

    const {t} = useTranslation();
    const history = useHistory();

    const renderTypeContrat = () => {
        if (props.offreEmploi.alternance) {
            return <DipTag className="dip-tag-orange dip-tag-p-sm"label={t('offers.alternance')} />;
        }
        else if (props.offreEmploi.natureContrat?.code == "ST") {
            return <DipTag className="dip-tag-orange dip-tag-p-sm" label={t('offers.traineeship')}/>;
        } else if (props.offreEmploi.natureContrat?.code == "E1") {
            if (props.offreEmploi.typeContrat?.code == "CDI") {
                return <DipTag className="dip-tag-orange dip-tag-p-sm" label={"CDI"}/>
            }
            else {
                return <DipTag className="dip-tag-orange dip-tag-p-sm" label={"CDD"}/>;
            }
        }
        else {
            return <span></span>
        }
    }

    return (
        <div className="slc-oe-item-container p-shadow-4" >
            <div className='oe-container-text-like'>
                <div className='oe-container-text'>
                    <div className="oe-titre p-mb-2">{props.offreEmploi.intitule}</div>
                    <div className="p-mb-4">
                        {renderTypeContrat()}
                    </div>
                    <div className="oe-text p-mb-2"><i className="pi pi-map-marker p-mr-2" />{props.offreEmploi.providerId === 2 ? <JobLocationFormater offreEmploi={props.offreEmploi} /> : props.offreEmploi.lieuTravail.libelle}</div>
                    {/* <div className="oe-text p-mb-2"><i className="pi pi-calendar p-mr-2" />{props.offreEmploi.dureeContrat + ' ' + t('general.months')}</div> */}
                    <DurationFormater offreEmploi={props.offreEmploi} />
                </div>
                <div>
                    <LikeOffreEmploi offreEmploi={props.offreEmploi} onUnlike={props.onUnlike} toast={props.toast} />
                </div>
            </div>
            <div className="oe-container-text-like-button oe-text"><Trans i18nKey="offers.published_on" values={{date:props.offreEmploi.dateCreation}} /></div>
            <div className="p-p-2  dip-flex-row dip-jc-end">
                <Link to={{pathname: '/vueOffreEmploi', search: `?offreEmploiId=${props.offreEmploi.id}`, state: {pageProfil: props.urlPagination?.profil, pageFormation: props.urlPagination?.formation, pageFavorites: props.urlPagination?.favorites}}}>
                    <Button id="consulted-offer" className="dip-btn-iris-bg p-button-rounded dip-ai-center">
                        {(t('general.see_offer'))}
                        {/* <i className="pi pi-arrow-right p-ml-2" /> */}
                    </Button>
                </Link>
            </div>
        </div>
    );
}