import React, { useState, useEffect, useRef, useContext } from 'react';
import "./listeElementsRechercheAvecClic.css";
import { Fragment } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import RechercheContext from './RechercheContext';
import Helper from '../../utils/helper';
import { AutoComplete } from 'primereact/autocomplete';
import { InputSwitch } from 'primereact/inputswitch';
import { useTranslation } from 'react-i18next';


export const ListeElementsRechercheAvecClic = (props) => {

    const { t } = useTranslation();

    const contextRecherche = useContext(RechercheContext);
    const [filteredValues, setFilteredValues] = useState(null);
    const [selectedValues, setSelectedValues] = useState(null);
    let remove = false;

    const handleRemoveValeur = (e, valeur) => {
        e.preventDefault()
        let _filters = [...contextRecherche.lazyParams.filters];
        const indexSupprime = Helper.findIndexByValueAndCategorie(_filters, valeur.value, valeur.categorie);
        _filters.splice(indexSupprime, 1);
        const _lazyParams = { ...contextRecherche.lazyParams };
        _lazyParams.filters = _filters;

        contextRecherche.setLazyParams(_lazyParams);
        contextRecherche.rechercheApi(_lazyParams);
        remove = true;
    }

    const searchValues = (event) => {
        let query = event.query;
        props.onSearch(query).then(res => setFilteredValues(res));
    }

    const handleChange = (value) => {
        setSelectedValues(value);
        if (typeof (value) === 'object') {
            setSelectedValues(null);
        }
        props.onChange(value)
    }

    const handleRemoveAllValues = () => {
        let _filters = [...contextRecherche.lazyParams.filters];
        _filters = _filters.filter(function (obj) {
            return props.values.indexOf(obj.categorie) == -1;
        });
        const _lazyParams = { ...contextRecherche.lazyParams };
        _lazyParams.filters = _filters;

        contextRecherche.setLazyParams(_lazyParams);
        contextRecherche.rechercheApi(_lazyParams);
    }

    const template = (item) => {
        return (
            <span className="ler-item" >
                {item.label}
            </span>
        );
    }

    return (
        <div className='p-grid p-fluid'>
            <div className='p-col-12 p-fluid ler-autocomplete-container'>
                <AutoComplete
                    className='ler-autcomplete'
                    minLength="3"
                    value={selectedValues}
                    suggestions={filteredValues}
                    completeMethod={searchValues}
                    placeholder={props.placeholder}
                    field={props.field}
                    itemTemplate={template}
                    delay="1000"
                    onChange={(e) => handleChange(e.value)} 
                />
                {props.icon && <div className='ler-autocomplete-icon'>
                    <i className={props.icon}></i>
                </div>}
            </div>
            {contextRecherche.lazyParams.filters?.filter(o => props.values.includes(o.categorie)).length > 0 && props.canDeleteAll && <div className='p-col-12 lerav-exige' >
                <div>
                    <Button
                        className='ler-delete-all'
                        icon="pi pi-times"
                        label={t('search.erase_all')}
                        onClick={(e) => handleRemoveAllValues()} 
                    />
                </div>
            </div>}
            {contextRecherche.lazyParams.filters?.filter(o => props.values.includes(o.categorie)).length > 0 && <div className='p-col-12'>
                {contextRecherche.lazyParams.filters?.filter(o => props.values.includes(o.categorie))?.sort((a, b) => (a.label > b.label) ? 1 : -1).map((valeur, indexValeur) => (
                    <Fragment key={`${valeur}~${indexValeur}`}>
                        <div className='dms-filter-item'>
                            <Button 
                                label={valeur?.label}
                                icon="pi pi-times"
                                iconPos="right" 
                                className="p-button-rounded p-button-text p-mr-2 dmp-button-style" 
                                onClick={(e) => handleRemoveValeur(e, valeur)} 
                            />
                        </div>
                    </Fragment>
                ))}</div>}
        </div>
    );

}
