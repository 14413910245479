import { WysiwygRenderer } from "./wysiwygRenderer"
import "./wysiwygResponsiveRenderer.css"

export const WysiwygResponsiveRenderer = (props) => {
    return (
        <>
            <div className={`${props.contentPC && props.contentMobile && "wysiwyg-mobile-renderer"}`}>
                <WysiwygRenderer content={props.contentMobile} />
            </div>
            <div className={`${props.contentPC && props.contentMobile && "wysiwyg-pc-renderer"}`}>
                <WysiwygRenderer content={props.contentPC} />
            </div>
        </>
    )
}