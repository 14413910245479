import { Card } from "primereact/card"
import { FileUpload } from "primereact/fileupload"
import { InputText } from "primereact/inputtext"
import { ProgressSpinner } from "primereact/progressspinner"
import { classNames } from "primereact/utils"
import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom";
import allowedImageFileType from '../../valueContol/allowedImageFileType.json';
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import EcoleService from "../../services/ecoleService"
import { Button } from "primereact/button"
import { Wysiwyg } from "../wysiwyg/wysiwyg"
import "./formFicheOrganismeFormation.css"
import { Toast } from "primereact/toast"
import { useAuthState } from "../../context/context"
import RoleHelper from "../../utils/roleHelper"
import { SplitButton } from "primereact/splitbutton"
import { InputSwitch } from "primereact/inputswitch"
import ComposanteService from "../../services/composanteService"
import { enumOrganismeFormationCategorie } from "../../enums/enumOrganismeFormationCategorie"
import { WysiwygMultiView } from "../wysiwyg/wysiwygMultiView"
import { enumBreakpoint } from "../../enums/enumBreakpoint"
import { enumConfWysiwyg } from "../../enums/enumConfWysiwyg"

export const FormFicheOrganismeFormation = (props) => {

    const currentUser = useAuthState()

    const history = useHistory()
    const ecoleService = new EcoleService()
    const composanteService = new ComposanteService()
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [organismeFormation, setOrganismeFormation] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    const fileUploaderCouverture = useRef(null);
    const [erreurImageCouverture, setErreurImageCouverture] = useState(null)
    const [validImageCouvertureType, setValidImageCouvertureType] = useState(false);

    const { t } = useTranslation();

    const toast = useRef(null);


    const saveItemsAdmin = [
        {
            label: t('general.save_and_quit'),
            icon: 'pi pi-sign-out',
            command: () => {
                updateFiche(true, false)
            }
        },
        {
            label: t('general.save_and_visualize'),
            icon: 'pi pi-eye',
            command: () => {
                updateFiche(false, true)
            }
        }
    ]

    const saveItemsEcole = [
        {
            label: t('general.save_and_visualize'),
            icon: 'pi pi-eye',
            command: () => {
                updateFiche(false, true)
            }
        }
    ]


    useEffect(() => {
        loadFiche();
    }, [])

    const loadFiche = () => {
        if (props.organismeFormationCategorie == enumOrganismeFormationCategorie.ECOLE) {
            ecoleService.getEcoleFiche(history.location.state?.ecoleId)
                .then(ecole => {
                    if(ecole.descriptionEcole === null){
                        ecole.descriptionEcole = {}
                    }
                    setOrganismeFormation(ecole)
                    if (ecole.imageCouverture) {
                        controllImageCouvertureTypeValidity(ecole.imageCouverture.format);
                    }
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 });
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        else if (props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE) {
            composanteService.getComposanteEcoleFiche(history.location.state?.composanteEcoleId)
                .then(composanteEcole => {
                    if(composanteEcole.descriptionComposanteEcole === null){
                        composanteEcole.descriptionComposanteEcole = {}
                    }
                    setOrganismeFormation(composanteEcole)
                    if (composanteEcole.imageCouverture) {
                        controllImageCouvertureTypeValidity(composanteEcole.imageCouverture.format);
                    }
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 });
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        else {
            toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 });
        }

    }

    const updateFiche = (saveandquit, visualize) => {
        setSubmitted(true)
        if (props.organismeFormationCategorie == enumOrganismeFormationCategorie.ECOLE) {
            if (organismeFormation.nomEcole.trim() &&
                (organismeFormation.fileImageCouverture || organismeFormation.imageCouverture) &&
                validImageCouvertureType &&
                (organismeFormation.descriptionEcole.contenu || organismeFormation.descriptionEcole.contenuMobile) &&
                (organismeFormation.referent?.trim() == null || organismeFormation.referent?.trim() == '' || (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(organismeFormation.referent?.trim())))
            ) {
                setSubmitting(true)
                toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 })
                ecoleService.updateEcoleFiche(currentUser, organismeFormation)
                    .then((ecole) => {
                        setOrganismeFormation(ecole)
                        if (ecole.imageCouverture) {
                            controllImageCouvertureTypeValidity(ecole.imageCouverture.format);
                        }
                        toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('school.profile_updated'), life: 3000 });
                        if (saveandquit) {
                            history.push('/ecoles')
                        }
                        if (visualize) {
                            history.push('/school/' + ecole.id.toString())
                        }
                    })
                    .catch((err) => {
                        toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_save'), life: 3000 })
                    })
                    .finally(() => {
                        setSubmitting(false)
                    })
                setSubmitted(false)
            }
            else {
                toast.current.show({ severity: 'error', summary: t('errors.incomplete_summary'), detail: t('errors.incomplete_detail'), life: 3000 })
            }
        }
        else if (props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE) {
            if (organismeFormation.ecole.nomEcole.trim() &&
                organismeFormation.nom.trim() &&
                (organismeFormation.fileImageCouverture || organismeFormation.imageCouverture) &&
                validImageCouvertureType &&
                (organismeFormation.descriptionComposanteEcole.contenu || organismeFormation.descriptionComposanteEcole.contenuMobile) &&
                (organismeFormation.referent?.trim() == null || organismeFormation.referent?.trim() == '' || (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(organismeFormation.referent?.trim())))
            ) {
                setSubmitting(true)
                toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 })
                composanteService.updateComposanteEcoleFiche(currentUser, organismeFormation)
                    .then((composanteEcole) => {
                        setOrganismeFormation(composanteEcole)
                        if (composanteEcole.imageCouverture) {
                            controllImageCouvertureTypeValidity(composanteEcole.imageCouverture.format);
                        }
                        toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('school.profile_updated'), life: 3000 });
                        if (saveandquit) {
                            history.push('/ecoles')
                        }
                        if (visualize) {
                            history.push('/schoolEntity/' + composanteEcole.id.toString())
                        }
                    })
                    .catch((err) => {
                        toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_save'), life: 3000 })
                    })
                    .finally(() => {
                        setSubmitting(false)
                    })
                setSubmitted(false)
            }
            else {
                toast.current.show({ severity: 'error', summary: t('errors.incomplete_summary'), detail: t('errors.incomplete_detail'), life: 3000 })
            }
        }
        else {
            toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_load'), life: 3000 })
        }

    }

    const onInputChange = (e, name) => {
        let val = e.target.value;
        let _organismeFormation = { ...organismeFormation };
        _organismeFormation[`${name}`] = val;
        setOrganismeFormation(_organismeFormation);
    };

    // File
    const onUploadCouverture = ({ files }) => {
        const _organismeFormation = { ...organismeFormation }
        _organismeFormation.fileImageCouverture = files[0];
        controllImageCouvertureTypeValidity(files[0].type)
        setOrganismeFormation(_organismeFormation);
        fileUploaderCouverture.current.clear();
    };

    const onSelectCouverture = ({ files }) => {
        setErreurImageCouverture(files[0].size > 5000000);
    };

    const handleRemoveCouverture = () => {
        let _organismeFormation = { ...organismeFormation };
        _organismeFormation.fileImageCouverture = null;
        setOrganismeFormation(_organismeFormation);
    }

    const handleRemoveFichierCouverture = () => {
        let _organismeFormation = { ...organismeFormation };
        _organismeFormation.imageCouverture = null;
        setOrganismeFormation(_organismeFormation);
    }

    const controllImageCouvertureTypeValidity = (type) => {
        if (allowedImageFileType.indexOf(type) > -1) {
            setValidImageCouvertureType(true);
        }
        else {
            setValidImageCouvertureType(false)
        }
    }

    const onEditorChange = (content, editor, view) => {
        let _organismeFormation = { ...organismeFormation };
        if (props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE) {
            if (view == enumBreakpoint.PC) {
                _organismeFormation.descriptionComposanteEcole.contenu = content
            }
            else if (view == enumBreakpoint.MOBILE) {
                _organismeFormation.descriptionComposanteEcole.contenuMobile = content
            }
        }
        else if(props.organismeFormationCategorie == enumOrganismeFormationCategorie.ECOLE){
            if (view == enumBreakpoint.PC) {
                _organismeFormation.descriptionEcole.contenu = content
            }
            else if (view == enumBreakpoint.MOBILE) {
                _organismeFormation.descriptionEcole.contenuMobile = content
            }
        }
        setOrganismeFormation(_organismeFormation)
    }

    const onReturnClick = () => {
        if (RoleHelper.isAdmin(currentUser) || RoleHelper.isSuperAdmin(currentUser)) {
            history.push('/ecoles')
        } else {
            if (props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE) {
                history.push('/composantes')
            }
            else {
                history.push('/dashboard')
            }

        }
    }

    return (
        <div>
            <Toast ref={toast} />
            <h1>
                {props.organismeFormationCategorie == enumOrganismeFormationCategorie.ECOLE && <Trans i18nKey="general.school" />}
                {props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE && <Trans i18nKey="general.component" />}
            </h1>
            {loading ? (
                <div className="dip-ta-center">
                    <ProgressSpinner />
                </div>
            ) : (
                <Card>
                    <div className={`${submitting && 'fsp-disabled'}`}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field-checkbox p-col-12 p-sm-12 p-lg-12">
                                <label htmlFor='emploiActuel' className="field-checkbox sp-checkbox-label  ffof-no-margin-left">
                                    <Trans i18nKey='school.visible_to_users' />
                                </label>
                                <InputSwitch
                                    checked={organismeFormation?.estVisible}
                                    onChange={(e) => onInputChange(e, 'estVisible')}
                                />
                            </div>
                            <div className="p-field p-col-12 p-sm-12 p-lg-8">
                                <label htmlFor='nomEcole'>
                                    <Trans i18nKey='school.school_name' />*
                                </label>
                                <InputText id='nomEcole'
                                    value={props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE ? (organismeFormation?.ecole.nomEcole) : (organismeFormation?.nomEcole)}
                                    disabled
                                    className={classNames({ 'p-invalid': submitted && !organismeFormation?.nomEcole && !organismeFormation?.ecole?.nomEcole })} />
                                {submitted && !organismeFormation?.nomEcole && !organismeFormation?.ecole?.nomEcole && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                            </div>
                            {
                                props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE &&
                                <>
                                    <div className="p-field p-col-12 p-sm-12 p-lg-4">
                                    </div>
                                    <div className="p-field p-col-12 p-sm-12 p-lg-8">
                                        <label htmlFor='nom'>
                                            <Trans i18nKey='composante.composante_name' />*
                                        </label>
                                        <InputText id='nom'
                                            value={organismeFormation?.nom}
                                            onChange={(e) => onInputChange(e, 'nom')}
                                            className={classNames({ 'p-invalid': submitted && !organismeFormation?.nom })} />
                                        {submitted && !organismeFormation?.nom && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                                    </div>
                                </>
                            }
                            <div className="p-field p-col-12 p-sm-12 p-lg-4">
                                <label htmlFor='acronyme'>
                                    <Trans i18nKey='school.acronym' />
                                </label>
                                <InputText id='acronyme'
                                    value={organismeFormation?.acronyme}
                                    onChange={(e) => onInputChange(e, 'acronyme')}
                                />
                            </div>
                            <div className="p-field p-col-12 p-sm-12 p-lg-6">
                                <label htmlFor='referent'>
                                    <Trans i18nKey='school.referent_mail' />
                                </label>
                                <InputText id='referent'
                                    value={organismeFormation?.referent}
                                    onChange={(e) => onInputChange(e, 'referent')}
                                    className={classNames({ 'p-invalid': submitted && (organismeFormation.referent && (!organismeFormation.referent && organismeFormation.referent?.trim() != '' || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(organismeFormation.referent)))) })}
                                />
                                {submitted && (organismeFormation.referent && (organismeFormation.referent?.trim() != '' && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(organismeFormation.referent)))) && <small className="p-error"><Trans i18nKey="general.invalid_email" /></small>}
                            </div>
                            <div className="p-field p-col-12 p-sm-12 p-lg-6">
                                <label htmlFor='siteInternetOfficiel'>
                                    <Trans i18nKey='school.official_website' />
                                </label>
                                <InputText id='siteInternetOfficiel'
                                    value={organismeFormation?.siteInternetOfficiel}
                                    onChange={(e) => onInputChange(e, 'siteInternetOfficiel')}
                                />
                            </div>
                            <div className="p-field p-col-12 p-sm-12 p-lg-6">
                                <label htmlFor='pageFacebook'>
                                    <Trans i18nKey='school.facebook_page' />
                                </label>
                                <InputText id='pageFacebook'
                                    value={organismeFormation?.pageFacebook}
                                    onChange={(e) => onInputChange(e, 'pageFacebook')}
                                />
                            </div>
                            <div className="p-field p-col-12 p-sm-12 p-lg-6">
                                <label htmlFor='pageInstagram'>
                                    <Trans i18nKey='school.instagram_page' />
                                </label>
                                <InputText id='pageInstagram'
                                    value={organismeFormation?.pageInstagram}
                                    onChange={(e) => onInputChange(e, 'pageInstagram')}
                                />
                            </div>
                            <div className="p-field p-col-12 p-sm-12 p-lg-6">
                                <label htmlFor='pageTwitter'>
                                    <Trans i18nKey='school.twitter_page' />
                                </label>
                                <InputText id='pageTwitter'
                                    value={organismeFormation?.pageTwitter}
                                    onChange={(e) => onInputChange(e, 'pageTwitter')}
                                />
                            </div>
                            <div className="p-field p-col-12 p-sm-12 p-lg-6">
                                <label htmlFor='pageLinkedin'>
                                    <Trans i18nKey='school.linkedin_page' />
                                </label>
                                <InputText id='pageLinkedin'
                                    value={organismeFormation?.pageLinkedin}
                                    onChange={(e) => onInputChange(e, 'pageLinkedin')}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <div>
                                    <label><Trans i18nKey='school.cover_picture' />* <Trans className="" i18nKey='school.cover_picture_recommandation' /></label>
                                </div>
                                <FileUpload
                                    ref={fileUploaderCouverture}
                                    mode="basic"
                                    auto
                                    customUpload={true}
                                    maxFileSize={5000000}
                                    invalidFileSizeMessageSummary={t('profile.invalidFileSizeMessageSummary')}
                                    invalidFileSizeMessageDetail={t('profile.invalidFileSizeMessageDetail_5mb')}
                                    chooseLabel={t('profile.photo_import')}
                                    className="p-mr-2 p-d-inline-block"
                                    name="couverture"
                                    url=""
                                    uploadHandler={onUploadCouverture}
                                    onSelect={onSelectCouverture}
                                    accept=".png,.jpg,.jpeg" />
                                {organismeFormation?.fileImageCouverture?.name &&
                                    <span>{organismeFormation?.fileImageCouverture?.name}<Button
                                        icon="pi pi-times"
                                        className="p-button-rounded p-button-danger"
                                        onClick={() => handleRemoveCouverture()}
                                        style={{ left: "1em" }}
                                    /></span>
                                    ||
                                    (organismeFormation?.imageCouverture?.name &&
                                        <span>{organismeFormation?.imageCouverture?.name}<Button
                                            icon="pi pi-times"
                                            className="p-button-rounded p-button-danger"
                                            onClick={() => handleRemoveFichierCouverture()}
                                            style={{ left: "1em" }}
                                        /></span>
                                    )}
                                {submitted && !organismeFormation.fileImageCouverture && !organismeFormation.imageCouverture && <small className="p-error"><Trans i18nKey="formation.image_required" /></small>}
                                {!validImageCouvertureType && organismeFormation.fileImageCouverture && <small className="p-error"><Trans i18nKey="formation.image_bad_type" /></small>}
                                <div>
                                    {erreurImageCouverture && <small className="p-error"><Trans i18nKey="profile.invalidFileSizeMessageDetail_5mb" /></small>}
                                </div>
                            </div>
                            <div className="p-col-12 p-md-12 p-mb-2">
                                <label className="p-mb-2"><Trans i18nKey="profile.presentation" />*</label>
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <WysiwygMultiView
                                    entityType={props.organismeFormationCategorie}
                                    entityId={organismeFormation.id}
                                    valuePC={props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE ? (organismeFormation.descriptionComposanteEcole?.contenu || null) : (organismeFormation.descriptionEcole?.contenu || null)}
                                    valueMobile={props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE ? (organismeFormation.descriptionComposanteEcole?.contenuMobile || null) : (organismeFormation.descriptionEcole?.contenuMobile || null)}
                                    onChangePC={(content, editor) => onEditorChange(content, editor, enumBreakpoint.PC)}
                                    onChangeMobile={(content, editor) => onEditorChange(content, editor, enumBreakpoint.MOBILE)}
                                    Conf={enumConfWysiwyg.ULTRA}
                                />
                                {submitted && ((props.organismeFormationCategorie == enumOrganismeFormationCategorie.COMPOSANTEECOLE && !organismeFormation.descriptionComposanteEcole.contenu && !organismeFormation.descriptionComposanteEcole.contenuMobile) || (props.organismeFormationCategorie == enumOrganismeFormationCategorie.ECOLE && !organismeFormation.descriptionEcole.contenu && !organismeFormation.descriptionEcole.contenuMobile)) && <small className="p-error"><Trans i18nKey="general.required" /></small>}
                            </div>
                            <div className="p-col-12 p-sm-12 p-lg-6">
                                <div className="ffof-btn-cancel">
                                    <Button
                                        icon="pi pi-times"
                                        label={t('general.cancel')}
                                        loading={submitting}
                                        loadingOptions={{ position: 'right' }}
                                        onClick={onReturnClick} />
                                </div>
                            </div>
                            <div className="p-col-12 p-sm-12 p-lg-6">
                                <div className="ffof-btn-submit">
                                    <SplitButton
                                        icon={!submitting ? "pi pi-save" : "p-button-loading-icon pi pi-spinner pi-spin p-button-icon-left"}
                                        label={t('general.save')}
                                        loading={submitting}
                                        loadingOptions={{ position: 'right' }}
                                        disabled={submitting}
                                        onClick={() => updateFiche(false, false)}
                                        model={RoleHelper.isSuperAdmin(currentUser) || RoleHelper.isAdmin(currentUser) ? (saveItemsAdmin) : (saveItemsEcole)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            )}
        </div>
    )
}