import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuthState } from '../../context/context';
import RoleHelper from '../../utils/roleHelper';
import { BurgerMenu } from '../burgerMenu/burgerMenu';
import './newHeaderAccessButtons.css'

export const NewHeaderAccessButtons = () => {
    const currentUser = useAuthState()
    const { t } = useTranslation();
    const history = useHistory();


    //TODO: Ajouter les commandes pour rediriger au bon endroit
    const itemsMenuLoggin = [
        {
            label: t('menu.home'),
            link: "/"
        },
        {
            label: t('menu.search_Formations'),
            link: "/rechercheAffinee"
        },
        {
            label: t('menu.my_favorites_formations'),
            link: "/formationsFavorites"
        },
        {
            label: t('menu.my_offers'),
            link: "/offresCompatibles"
        },
        {
            label: t('menu.my_candidatures'),
            link: "/candidatures"
        },

    ]

    //TODO: Ajouter les commandes pour rediriger au bon endroit
    const itemsMenuNotConnected = [
        {
            label: t('menu.home'),
            link: "/"
        },
        {
            label: t('menu.formations'),
            link: "/rechercheAffinee"
        },
        {
            label: t('menu.acces_entreprise'),
            link: "/entreprise"
        },
        // {
        //     label: t('menu.acces_ecole'),
        //     link: "/about-schools"
        // },
    ]


    return (
        <>
            {history.location.pathname != "/entreprise" && <div className='dip-flex dip-as-center'>
                <div className="nhab-mobile-container">
                    {RoleHelper.isNotConnected(currentUser) ?
                        <BurgerMenu itemsMenu={itemsMenuNotConnected} />
                        :
                        RoleHelper.isEtudiant(currentUser) ?
                            <BurgerMenu itemsMenu={itemsMenuLoggin} /> : ""
                    }
                </div>
            </div>}
        </>
    )
}