import { Button } from "primereact/button"
import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Dropdown } from 'primereact/dropdown';
import "./cardFormationThumbnail.css"
import { DisplayImage } from "../image/displayImage";
import { LikeFormation } from "../likeformation/likeformation";
import { Link } from 'react-router-dom';
import building from "../../images/icons/building.svg"
import { DipTag } from "../tag/dipTag";
import RoleHelper from "../../utils/roleHelper";
import { useAuthState } from "../../context/context";
import { ProjetOverlayPanel } from "../overlayPanel/projetOverlayPanel";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const CardFormationThumbnail = (props) => {
    const dureeFormationTemporaire = "";
    const currentUser = useAuthState();
    const [selectedCampus, setSelectedCampus] = useState(null)
    const [campusOptions, setCampusOptions] = useState([])
    const { t } = useTranslation();
    const history = useHistory();
    
    const adminImpersonatingRecruteurId = props.adminImpersonatingRecruteurId;

    useEffect(() => {
        buildCampusDropdown();
    }, [])


    function buildCampusDropdown() {
        let _options = []
        props.formation?.formationCampus?.forEach(fc => {
            if (fc.campus.isOnline) {
                _options.push({ nom: t("general.online_en"), stagePrevu: fc.stagePrevu, alternance: fc.alternance, isOnline: fc.campus.isOnline });
            }
            else if (fc.campus.commune) {
                _options.push({ nom: fc.campus.commune.libelle, stagePrevu: fc.stagePrevu, alternance: fc.alternance, isOnline: fc.campus.isOnline });
            } else {
                _options.push({ nom: fc.campus.ville, stagePrevu: fc.stagePrevu, alternance: fc.alternance, isOnline: fc.campus.isOnline });
            }
        });
        _options.sort();
        setCampusOptions(_options);
    }
    
    const openVueFormationCandidat = (setCandidat) => {
        const pathname = `/course/${props.formation?.id}${setCandidat ? '#candidates' : ''}`;
        RoleHelper.windowOpenadminImpersonatingRecruteurIdStorage(pathname, adminImpersonatingRecruteurId);
    }

    return (
        <div className='p-p-4 cfc-container'>
            <div>
                <div className="formation-logo-container">
                    <div className="formation-logo-sub-container">
                        {props.formation?.composanteEcole[0]?.logoId != null ? (
                            <DisplayImage imageId={props.formation?.composanteEcole[0]?.logoId} alt={t('meta-img.alt-logo-organisme-formation') + " " + props.formation?.composanteEcole[0]?.nom}></DisplayImage>
                        ) : (
                            props.formation?.composanteEcole[0]?.ecole?.logoId != null ? (
                                <DisplayImage imageId={props.formation?.composanteEcole[0]?.ecole?.logoId} alt={t('meta-img.alt-logo-organisme-formation') + " " + props.formation?.composanteEcole[0]?.ecole?.nomEcole}></DisplayImage>
                            ) : (
                                <div className="dip-flex-row dip-ta-center dip-jc-center">{props.formation?.composanteEcole[0]?.nom}</div>
                            )
                        )}
                    </div>
                </div>
                <div className="cfc-tags-container">
                    <div>
                        <DipTag
                            className="dip-tag-yellow dip-fw-700 p-mb-2"
                            hasLetterSpacing
                            label={props.formation.diplome == "LICENCE PROFESSIONNELLE" ? "LICENCE PRO" : props.formation.diplome}
                        />
                    </div>
                    <div>
                        <DipTag
                            className="dip-tag-blue dip-tag-fs-sm dip-fw-600"
                            hasLetterSpacing
                            label={props.formation.niveauSortie}
                        />
                        {(!selectedCampus && props.formation.formationCampus.some(e => e.campus.isOnline === true) || selectedCampus?.isOnline) &&
                            <DipTag
                                className="p-ml-2 dip-tag-pink dip-tag-fs-sm dip-fw-600"
                                hasLetterSpacing
                                label={t('formation.hundred_percent_online')}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className="dip-flex-row dip-jc-end dip-ai-end">
                {(RoleHelper.isEntreprise(currentUser) || adminImpersonatingRecruteurId) && <ProjetOverlayPanel formationId={props.formation.id} refreshPage={props.loadLazyData} lazyParams={props.lazyParams} />}
                <LikeFormation formation={props.formation} toast={props.toast} onUnlike={props.onUnlike} />
            </div>
            <div className='p-mt-3 cfc-container-text dip-jc-between'>
                <div>
                    {props.formation.mention == props.formation.parcours ?
                        <div>
                            <div className="cfc-mention">
                                <b>{props.formation.mention}</b>
                            </div>
                            <div className="cfc-parcours"></div>
                        </div>
                        :
                        <div>
                            <div className={props.formation.parcours != "" ? "cfc-mention cfc-mention-limit-text" : "cfc-mention"}>
                                <b>{props.formation.mention}</b>
                            </div>
                            <div className="cfc-parcours">
                                {props.formation.parcours}
                            </div>
                        </div>
                    }
                    <div>
                        {(!selectedCampus && props.formation.formationCampus.some(e => e.stagePrevu === true) || selectedCampus?.stagePrevu) &&
                            <DipTag
                                className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2"}
                                hasLetterSpacing
                                label={t('general.internship')}
                            />}
                        {(!selectedCampus && props.formation.formationCampus.some(e => e.alternance === true) || selectedCampus?.alternance) &&
                            <DipTag
                                className={"dip-tag-blue dip-tag-fs-sm dip-fw-600"}
                                hasLetterSpacing
                                label={t('formation.alternation.base')}
                            />}
                    </div>

                </div>
                <div className="dip-flex-row dip-ai-end dip-jc-between">
                    <div className="cfc-details-container">

                        <div className={dureeFormationTemporaire ? "p-mb-2" : "dip-visibility-hidden p-mb-2"}>
                            <i className="pi pi-calendar p-mr-3"
                                style={{ fontSize: '1rem' }}
                            />
                            {/*TODO: Connecter les mois*/}
                            <span>{dureeFormationTemporaire}</span>
                        </div>
                        {props.formation.composanteEcole[0]?.ecole?.nomEcole && <div className="dip-ai-center p-mb-2">
                            <img className="p-mr-3" src={building}
                            />
                            <span>{props.formation.composanteEcole[0]?.ecole?.nomEcole}</span>
                        </div>}
                        <>
                            <div className="dip-flex-row dip-ai-center">
                                <div className="cfc-icon-campus">
                                    <i className="pi pi-map-marker p-mr-3"
                                        style={{ fontSize: '1rem' }}
                                    />
                                    {props.formation.formationCampus.length > 1 ?
                                        <Dropdown
                                            value={selectedCampus}
                                            options={campusOptions}
                                            optionLabel="nom"
                                            onChange={(event) => setSelectedCampus(event.value)}
                                            placeholder={`${props.formation.formationCampus.length} campus`}
                                        // {t('administrator.select_school')} 
                                        /> :
                                        <span>{props.formation.formationCampus[0]?.campus?.isOnline == true ? <Trans i18nKey={"general.online_en"} /> : props.formation.formationCampus[0]?.campus?.commune ? props.formation.formationCampus[0].campus.commune.libelle : props.formation?.formationCampus[0]?.campus.ville}</span>
                                    }
                                </div>
                            </div>
                        </>
                        <div className="dip-flex-row dip-jc-between p-mt-2 p-mb-2">
                            {RoleHelper.isEntreprise(currentUser) || adminImpersonatingRecruteurId ? (
                                // <Link
                                //     className={!props.formation?.nbEtudiants && "dip-visibility-hidden"}
                                //     // to={{ pathname: `/course/${props.formation?.id}#candidates` }} target={"_blank"}>
                                //     // to={ adminImpersonatingRecruteurId ? { pathname: `/course/${props.formation?.id}#candidates`, state: { adminImpersonatingRecruteurId: adminImpersonatingRecruteurId } } : { pathname: `/course/${props.formation?.id}#candidates` }} target={"_blank"}>
                                //     to={{ pathname: `/course/${props.formation?.id}#candidates`, state: { adminImpersonatingRecruteurId: adminImpersonatingRecruteurId } }} target={"_blank"}>
                                //     <Button className="dip-btn-iris-outline dip-ai-center cfc-offer-button" label={`${props.formation?.nbEtudiants} ${props.formation?.nbEtudiants == 1 ? "candidat" : "candidats"}`} />

                                // </Link>

                                <div className={!props.formation?.nbEtudiants && "dip-visibility-hidden"}>
                                    <Button className="dip-btn-iris-outline dip-ai-center cfc-offer-button" label={`${props.formation?.nbEtudiants} ${props.formation?.nbEtudiants == 1 ? "candidat" : "candidats"}`} onClick={() => openVueFormationCandidat(true)} />
                                </div>
                            ) : (
                                (!selectedCampus && props.formation.formationCampus.some(e => e.alternance === true) || selectedCampus?.alternance) ?
                                    <Link
                                        id="see-formation-alternance-offers"
                                        className={!props.formation?.annoncesLiees && "dip-visibility-hidden"}
                                        to={{ pathname: `/course/${props.formation?.id}#offers` }} target={"_blank"}
                                    >
                                        <Button className="dip-btn-iris-outline dip-ai-center cfc-offer-button" label={`${props.formation?.compteurAlternance} offres d'alternance`} />
                                    </Link>
                                    :
                                    <Link
                                        id="see-formation-job-offers"
                                        className={!props.formation?.annoncesLiees && "dip-visibility-hidden"}
                                        to={{ pathname: `/course/${props.formation?.id}#offers` }} target={"_blank"}
                                    >
                                        <Button className="dip-btn-iris-outline dip-ai-center cfc-offer-button" label={`${props.formation?.annoncesLiees} offres d'emploi`} />
                                    </Link>
                            )}

                            {/* <Link
                                to={{ pathname: `/course/${props.formation?.id}` }} target={"_blank"}
                                // to={ adminImpersonatingRecruteurId ? { pathname: `/course/${props.formation?.id}`, state: { adminImpersonatingRecruteurId: adminImpersonatingRecruteurId } } : `/course/${props.formation?.id}`} target={"_blank"}
                            // to={`/vueOffreEmploi?offreEmploiId=${offreEmploi.id}`}
                            >
                                <Button className="dip-btn-iris-bg dip-ai-center ">
                                    <i className="pi pi-arrow-right" />
                                </Button>
                            </Link> */}
                            <div>
                                <Button id="see-formation" className="dip-btn-iris-bg dip-ai-center cft-button-see-formation" onClick={() => openVueFormationCandidat(false)}>
                                    <i className="pi pi-arrow-right" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
