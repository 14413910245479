import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { enumStatutFormation } from "../../../../../enums/enumStatutFormation";
import FormationHelper from "../../../../../utils/formationHelper";
import { DiplomeSelector } from "../../../../selector/diplomeSelector";
import { FormationSelector } from "../../../../selector/formationSelector";
import { OrganismeFormationSelector } from "../../../../selector/organismeFormationSelector";
import { FormCourse } from "./formCourse";
import "./formCoursePopUp.css"

export const FormCoursePopUp = (props) => {

    const { t } = useTranslation();

    const [formationToAdd, setFormationToAdd] = useState(props.currentFormation)
    const [isOrganismeFormationFound, setIsOrganismeFormationFound] = useState(true)
    const [isOrganismeFormationSelected, setIsOrganismeFormationSelected] = useState(false)
    const [isOrganismeFormationLoading, setIsOrganismeFormationLoading] = useState(false)
    const [isDiplomeFound, setIsDiplomeFound] = useState(true);
    const [isDiplomeSelected, setIsDiplomeSelected] = useState(false);
    const [isDiplomeLoading, setIsDiplomeLoading] = useState(false);
    const [isFormationFound, setIsFormationFound] = useState(true);
    const [isFormationSelected, setIsFormationSelected] = useState(false);
    const [isFormationLoading, setIsFormationLoading] = useState(false);
    const [showUrlField, setShowUrlField] = useState(props.currentFormation?.statut ? (true) : (false));
    const currentYear = new Date().getFullYear()
    const years = Array.from({ length: 100 }).map((_, i) => ({ label: `${currentYear - i}`, value: currentYear - i }));

    const statuts = [
        enumStatutFormation.SUIVIE,
        enumStatutFormation.ENCOURS,
        enumStatutFormation.AVENIR
    ]

    useEffect(() => {
        if (typeof (props.currentIndex) === 'number') {
            setIsOrganismeFormationFound(true);
            setIsOrganismeFormationSelected(true);
            setIsDiplomeFound(true);
            setIsDiplomeSelected(true);
            setIsFormationFound(true);
            setIsFormationSelected(true);
            if (props.currentFormation.url) {
                setShowUrlField(true);
            }
            else {
                setShowUrlField(false);
            }

        }
        else {
            setIsOrganismeFormationFound(true);
            setIsOrganismeFormationSelected(false);
            setIsDiplomeFound(true);
            setIsDiplomeSelected(false);
            setIsFormationFound(true);
            setIsFormationSelected(false);
            if (props.currentFormation?.url) {
                setShowUrlField(true);
            }
            else {
                setShowUrlField(false);
            }
        }
        setFormationToAdd(props.currentFormation)
    }, [props.currentFormation, props.currentIndex])

    const onAdd = () => {
        let _formation = { ...formationToAdd }
        if (typeof (_formation.organismeFormation) === 'object' && typeof (_formation.diplome) === 'object' && typeof (_formation.formation) === 'object') {
            _formation.organismeFormation = _formation.organismeFormation.nom
            _formation.diplome = _formation.diplome.nom
            _formation.nomComplet = _formation.formation.nomComplet
            _formation.id = _formation.formation.id
            if (typeof (props.currentIndex) === 'number') {
                props.handleEditFormation(_formation, props.currentIndex)
            } else {
                props.onAdd(_formation);
            }

        }
        else {
            if (typeof (_formation.organismeFormation) === 'object') {
                _formation.organismeFormation = _formation.organismeFormation.nom
            }
            if (typeof (_formation.diplome) === 'object') {
                _formation.diplome = _formation.diplome.nom
            }
            _formation.id = null
            _formation.nomComplet = _formation.formation;
            if (typeof (props.currentIndex) === 'number') {
                props.handleEditDemandeFormation(_formation, props.currentIndex)
            } else {
                props.onAddDemande(_formation);
            }
        }
        props.setDisplayForm(false);
        setFormationToAdd(null)
    }

    const onOrganismeFormationContinue = () => {
        setIsOrganismeFormationSelected(true)
        setIsDiplomeSelected(true)
        setIsFormationSelected(true)
        setShowUrlField(true)
    }

    const onDiplomeContinue = () => {
        setIsDiplomeSelected(true)
        setIsFormationSelected(true)
        setShowUrlField(true)
    }

    const onFormationContinue = () => {
        setIsFormationSelected(true)
        setShowUrlField(true)
    }

    const onFormationToAddChange = (val, name) => {
        let _formationToAdd = { ...formationToAdd }
        let _isOrganismeFormationSelected = isOrganismeFormationSelected
        let _isDiplomeSelected = isDiplomeSelected
        let _isFormationSelected = isFormationSelected
        _formationToAdd[`${name}`] = val
        if (name == 'organismeFormation') {
            _formationToAdd.formation = null;
            _formationToAdd.diplome = null;
            _isDiplomeSelected = false;
            _isFormationSelected = false;
            setIsDiplomeFound(true);
            setIsFormationFound(true);
            if (typeof (val) === 'object') {
                _isOrganismeFormationSelected = true
            }
            else {
                _isOrganismeFormationSelected = false
            }
        }
        if (name == 'diplome') {
            _formationToAdd.formation = null;
            if (_formationToAdd.formation) {
                _isFormationSelected = false;
                setIsFormationFound(true);
            }
            if (typeof (val) === 'object' || (typeof (val) === 'string' && typeof (_formationToAdd.organismeFormation) === 'string')) {
                _isDiplomeSelected = true
            }
            else {
                _isDiplomeSelected = false
            }
        }
        if (name == "formation") {
            if (typeof (val) === 'object') {
                _isFormationSelected = true
            }
            else {
                _isFormationSelected = false
            }
        }
        //showUrlCheck
        if ((typeof (_formationToAdd.organismeFormation) === "string" && _isOrganismeFormationSelected) ||
            (typeof (_formationToAdd.diplome) === "string" && _isDiplomeSelected) ||
            (typeof (_formationToAdd.formation) === "string" && _isFormationSelected)
        ) {
            setShowUrlField(true);
        }
        else {
            setShowUrlField(false);
        }
        setIsOrganismeFormationSelected(_isOrganismeFormationSelected)
        setIsDiplomeSelected(_isDiplomeSelected)
        setIsFormationSelected(_isFormationSelected)
        setFormationToAdd(_formationToAdd)
    }

    return (
        <div>
            <Dialog header={t('profile.add_formation')}
            visible={props.displayForm}
            className="form-course-pop-up"
            onHide={() => props.onHide()}
            closeOnEscape={false}
            blockScroll={true}>
                <div className="formCourse-main-container">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-sm-12 p-lg-12">
                            <label htmlFor='organisme'>
                                <Trans i18nKey='school.highschool_school_university' />*
                            </label>
                            <OrganismeFormationSelector
                                value={formationToAdd?.organismeFormation}
                                onChange={val => onFormationToAddChange(val, 'organismeFormation')}
                                onFound={setIsOrganismeFormationFound}
                                onSelect={setIsOrganismeFormationSelected}
                                onLoading={setIsOrganismeFormationLoading}
                            />
                            {!isOrganismeFormationLoading && !isOrganismeFormationSelected && formationToAdd?.organismeFormation && <div>
                                {!isOrganismeFormationFound && <div>
                                    <small className="p-error">{t('profile.error.organisme_not_found')}</small>
                                </div>}
                                <div className="fcpu-btn-continue">
                                    <Button
                                        label={t('profile.error.organisme_continue')}
                                        onClick={() => onOrganismeFormationContinue(true)}
                                    />
                                </div>
                            </div>}
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-lg-12">
                            <label className={isOrganismeFormationSelected ? ('') : ("disabled-label")} htmlFor='diplome'>
                                <Trans i18nKey='formation.diploma' />*
                            </label>
                            <DiplomeSelector
                                disabled={!isOrganismeFormationSelected}
                                organismeFormation={formationToAdd?.organismeFormation} value={formationToAdd?.diplome}
                                onChange={val => onFormationToAddChange(val, 'diplome')}
                                onFound={setIsDiplomeFound}
                                onLoading={setIsDiplomeLoading}
                            />
                            {!isDiplomeLoading && !isDiplomeSelected && formationToAdd?.diplome &&
                                <div >
                                    {!isDiplomeFound && <div>
                                        <small className="p-error">{t('profile.error.diplome_not_found')}</small>
                                    </div>}
                                    <div className="fcpu-btn-continue">
                                        <Button
                                            label={t('profile.error.diplome_continue')}
                                            onClick={() => onDiplomeContinue()}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-lg-12">
                            <label className={isDiplomeSelected ? ('') : ("disabled-label")} htmlFor='nomFormation'>
                                <Trans i18nKey='formation.formationName' />*
                            </label>
                            <FormationSelector
                                disabled={!isDiplomeSelected}
                                organismeFormation={formationToAdd?.organismeFormation}
                                diplome={formationToAdd?.diplome}
                                value={formationToAdd?.formation}
                                onChange={val => onFormationToAddChange(val, 'formation')}
                                onFound={setIsFormationFound}
                                onLoading={setIsFormationLoading}
                            />
                            {!isFormationLoading && !isFormationSelected && formationToAdd?.formation &&
                                <div>
                                    {!isFormationFound && <div>
                                        <small className="p-error">{t('profile.error.formation_not_found')}</small>
                                    </div>}
                                    <div className="fcpu-btn-continue">
                                        <Button
                                            label={t('profile.error.formation_continue')}
                                            onClick={() => onFormationContinue()}
                                        />
                                    </div>
                                </div>}
                        </div>
                        {/* input formation web page */}
                        {/* {showUrlField && <div className="p-field p-col-12 p-sm-12 p-lg-12">
                            <label htmlFor='webPage'>
                                <Trans i18nKey='profile.formationWebPage' />
                            </label>
                            <InputText value={formationToAdd?.url} onChange={e => onFormationToAddChange(e.value, 'url')} />
                        </div>} */}
                        <div className="p-col-12 p-sm-12 p-lg-6">
                            <div className={`p-field`}>
                                <label htmlFor='statut'>
                                    <Trans i18nKey='profile.formation_status' />*
                                </label>
                                <Dropdown value={formationToAdd?.statut} appendTo="self" options={statuts} onChange={e => onFormationToAddChange(e.value, 'statut')} />
                            </div>
                        </div>
                        <div className="p-col-12 p-sm-12 p-lg-4">
                            {formationToAdd?.statut == enumStatutFormation.SUIVIE && <div className="p-field">
                                <label htmlFor='anneeObtention'>
                                    <Trans i18nKey='profile.obtained_year' />*
                                </label>
                                <Dropdown className='year-dropdown-style' appendTo="self" value={formationToAdd?.anneeDiplome} options={years} onChange={e => onFormationToAddChange(e.value, 'anneeDiplome')} optionLabel="label" />
                            </div>}
                        </div>
                        <div className="p-col-12 p-sm-12 p-lg-6">
                            <div className="fscpu-btn-cancel">
                                <Button
                                    label={t('general.cancel')}
                                    onClick={() => props.onHide()} />
                            </div>
                        </div>
                        <div className="p-col-12 p-sm-12 p-lg-6">
                            <div className={!formationToAdd?.organismeFormation || !formationToAdd?.diplome || !formationToAdd?.formation || (!isFormationSelected && typeof (formationToAdd.organismeFormation) === 'object' && typeof (formationToAdd.diplome) === 'object') || !formationToAdd?.statut || (formationToAdd?.statut == enumStatutFormation.SUIVIE && !formationToAdd.anneeDiplome) ? ("fscpu-btn-submit-invalid") : ("fscpu-btn-submit")}>
                                <Button
                                    disabled={!formationToAdd?.organismeFormation || !formationToAdd?.diplome || !formationToAdd?.formation || (!isFormationSelected && typeof (formationToAdd.organismeFormation) === 'object' && typeof (formationToAdd.diplome) === 'object') || !formationToAdd?.statut || (formationToAdd?.statut == enumStatutFormation.SUIVIE && !formationToAdd.anneeDiplome)}
                                    label={(typeof (props.currentIndex) === 'number') ? (t('profile.apply_changes')) : (t('profile.add_to_my_profile'))}
                                    onClick={() => onAdd()} />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
