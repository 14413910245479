import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuthState } from "../../context/context";
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import PicEcole from "../../images/accueil/ecole/analyseLogo.webp"
import RoleHelper from "../../utils/roleHelper";
import { Mailto } from "../../components/mailto/mailto";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import "./savoirplus_ecole.css";
import 'primeflex/primeflex.css';

export const SavoirPlus_Ecole = (props) => {

    const currentUser = useAuthState();

    const history = useHistory();
    const { t } = useTranslation();


    const onRetourAccueilClick = () => {
        history.push('/');
    }

    const onTableauDeBord = () => {
        history.push('/dashboard');
    }


    return (
        <div className={props.className}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ecole - DiplomAdvisor</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description"  content={t("metadesc.about-schools")} />
            </Helmet>
            <div className="savoirplus-ecole-container">

                <div className="savoirplus-ecole-head p-grid">
                    <div className="p-col-12">
                        <h1>{parse(t("information.savoirplus.ecole.Title1"))}</h1>
                    </div>
                </div>

                <div className="p-grid savoirplus-block">
                    <div className="p-md-12 p-lg-6 p-col-align-center p-col-12" /*title="Simple Card"*/ >
                        <h2 className="text-huge-ecole">{t("information.savoirplus.ecole.TextPresentation")}</h2>
                    </div>
                    <div className="imagecard-ecole p-md-12 p-lg-6 p-col-align-center" /*title="Simple Card"*/>
                        <img src={PicEcole} />
                    </div>
                </div>
                <div className="text-descriptions p-grid">
                    <h3 className="text-description-left p-lg-6 p-col-12">{t("information.savoirplus.ecole.TextDescription1")}</h3>
                    <h3 className="text-description-right p-lg-6 p-col-12">{t("information.savoirplus.ecole.TextDescription2")}</h3>
                </div>

                <div className="savoirplus-block blk-footer">
                    <div className="contactez-nous-button-ecole">
                        {(RoleHelper.isAdminEcole(currentUser) || RoleHelper.isAdminComposante(currentUser)) ? (<Button label={t("information.savoirplus.ecole.BouttonContactUserEcole")} onClick={(e) => onTableauDeBord()}></Button>) : (<Mailto
                            label={t('information.savoirplus.ecole.BouttonContactUserNonEcole')}
                            className="p-button"
                            object={t('footer.school_mail_object')}
                            body={encodeURIComponent(t('footer.school_mail_body'))}
                            to="contact@diplomadvisor.com"
                        />)}
                    </div>
                </div>
                <div className="savoirplus-block blk-footer">
                    <div className="accueil-button-ecole">
                        <Link to="/"><Button label="Accueil"></Button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}