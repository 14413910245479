import React, { useState, useEffect, useRef, Fragment } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import EcoleService from '../../services/ecoleService';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Trans, useTranslation } from 'react-i18next';
import './dt_ecoles.css';

export const DT_Ecole = (props) => {

    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalCount] = useState(0);
    const [data, setData] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 25,
        page: 0,
        sortField: 'nomEcole',
        sortOrder: 1
    });

    const dt = useRef(null);

    const { t } = useTranslation();

    const ecoleService = new EcoleService();

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadLazyData = () => {
        setLoading(true);
        ecoleService.getEcolesPaginated(lazyParams)
            .then(data => {
                setTotalCount(data.totalCount);
                setData(data.items);
            })
            .catch(err => showError())
            .finally(() => setLoading(false))
    }


    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        _lazyParams['page'] = 0;
        _lazyParams['first'] = 0;
        setLazyParams(_lazyParams);
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const headerTemplate = (
        <div className="table-header">
            <h5 className="title"><Trans i18nKey="formation.formations_management" /></h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
            </span>
        </div>
    );

    return (
        <div>
            <Toast ref={toast} />
            <div>
                <div className="card">
                    <DataTable ref={dt} value={data} lazy
                        dataKey="id"
                        filterDelay={1000}
                        paginator first={lazyParams.first} rows={lazyParams.rows} totalRecords={totalRecords} onPage={onPage}
                        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                        onFilter={onFilter} filters={lazyParams.filters} loading={loading}
                        emptyMessage={t('school.no_school_to_show')}
                        globalFilter={globalFilter} header={headerTemplate}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        expandedRows={expandedRows}
                        rowExpansionTemplate={props.rowExpansionTemplate}
                    >
                        <Column expander style={{ width: '3em' }} />
                        <Column field="nomEcole" header={t('school.name')}></Column>
                    </DataTable>
                </div>
            </div>
        </div >
    );
}