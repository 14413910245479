import BaseService from './baseService';

export default class StripeService extends BaseService {

  createCustomer() {
    return this.axiosApiInstance.post(window.API_URL + `/api/Stripe/create-customer`)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  createSubscription(priceId) {
    const body = JSON.stringify({
      priceId: priceId
    })
    return this.axiosApiInstance.post(window.API_URL + `/api/Stripe/create-subscription`, body)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getPartenariatInfo(codePartenariat) {
    return this.axiosApiInstance.get(window.API_URL + `/api/Stripe/GetPartenariatInfo`, { params: { codePartenariat: codePartenariat } })
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getFactures(entrepriseId) {
    const options = {
      params: {
        entrepriseId: entrepriseId
      }
    }
    return this.axiosApiInstance.get(window.API_URL + `/api/Stripe/GetFactures`, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }
}
