import React, { useEffect, useState } from 'react';
import { Card } from "primereact/card"
import { Chart } from "primereact/chart"
import { ProgressSpinner } from 'primereact/progressspinner';

export const PieChartCard = (props) => {

    const [options] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                },
                position: 'bottom'
            }
        }
    });

    return (
        <div>
            <Card className='p-shadow-4'>
                {
                    props.loading ? (
                        <div className='dip-ta-center'>
                            <ProgressSpinner />
                        </div>
                    ) : (
                        <>
                            <span>{props.title}</span>
                            <div className='dip-flex dip-jc-center'>
                                <Chart id={props.id} type="pie" data={props.data} options={options} style={{ width: '75%' }}></Chart>
                            </div>
                        </>
                    )
                }
            </Card>
        </div >
    )
}