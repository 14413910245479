import React, { useEffect, useState } from 'react';
import "./sousDomainesInspireMe.css";
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import RechercheService from "../../../../services/rechercheService";
import { Checkbox } from 'primereact/checkbox';
import ArrayHelper from '../../../../utils/arrayHelper';

export const SousDomainesInspireMe = ({ setActiveStep, domainesSelected, sousDomainesSelected, setSousDomainesSelected, setListSousDomainesReconstitues }) => {
    const rechercheService = new RechercheService();
    const [listeSousDomaines, setListeSousDomaines] = useState([])
    const [listeSousDomainesOrder, setListeSousDomainesOrder] = useState([])
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 0,
        page: 0,
        sortField: 'libelle',
        sortOrder: 1,
        filters: []
    });
    const { t } = useTranslation();

    useEffect(() => {
        searchSousDomaines();
    }, [])

    const searchSousDomaines = () => {
        let _lazyParamsTemp = { ...lazyParams };
        _lazyParamsTemp.filters = setCategorie();
        setLazyParams(_lazyParamsTemp);
        rechercheService.getRechercheDipMetier(null, _lazyParamsTemp).then(res => {
            setListeSousDomaines(res);
            groupByType(res);
        });
    }

    function setCategorie() {
        let _listeTemp = [];

        domainesSelected.map((domaine) => {
            _listeTemp.push({
                categorie: 25,
                value: domaine
            });
        });
        return _listeTemp;
    }

    function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    }

    function groupByType(data) {
        // Créer un objet vide pour stocker les groupes par type
        const groupedData = groupBy(data, ({ type }) => type);
        const groupedDataArray = Array.from(groupedData, ([key, value]) => ({
            key,
            value,
          }));

          const sortedGroupedDataArray = ArrayHelper.sortGroupedDataArray(groupedDataArray);
         
        // Retourner l'objet regroupé par type
        setListeSousDomainesOrder(sortedGroupedDataArray);
    }

    const onSousDomaineChange = (e) => {

        let _sousDomainesSelected = [...sousDomainesSelected];

        if (e.checked) {
            _sousDomainesSelected.push(e.value);
        }
        else {
            for (let i = 0; i < _sousDomainesSelected.length; i++) {
                const sousDomaineSelected = _sousDomainesSelected[i];

                if (sousDomaineSelected === e.value) {
                    _sousDomainesSelected.splice(i, 1);
                    break;
                }
            }
        }
        setSousDomainesSelected(_sousDomainesSelected);
    }

    function sousDomainesReconstitues() {
        // Permet de récupérer les informations liées au domaine et reconstituer la liste avec toutes les infos
        const result = listeSousDomaines.filter((ssDomaine) => {

            return sousDomainesSelected.includes(ssDomaine.label);
        });

        // Permet de constituer l'objet seulement avec les informations nécessaires  pour la requête au back
        let _resultFilterTemp = [];
        result.map(ssDomaine => {
            _resultFilterTemp.push({
                label: ssDomaine.label,
                value: ssDomaine.value,
                categorie: ssDomaine.categorie
            })
        });

        setListSousDomainesReconstitues(_resultFilterTemp);
    }

    function nextStep() {
        sousDomainesReconstitues();
        setActiveStep(3);
    }

    return (
        <>
            <h1 className='dip-ta-center dip-color-ed'><Trans i18nKey="inspire-me.sous-domaines-title" /></h1>
            <div>
                {listeSousDomainesOrder.map((domaine) => (
                    <div key={domaine.key} className="container-global-domaines ">
                        <h2>{domaine.key}</h2>
                        {domaine.value.map((sousDomaine) => (
                            <div key={sousDomaine.value} className="field-checkbox dip-flex-row">
                                <Checkbox
                                    inputId={sousDomaine.value}
                                    name="sousDomaine"
                                    value={sousDomaine.label}
                                    onChange={onSousDomaineChange}
                                    checked={sousDomainesSelected.some((item) =>
                                        item === sousDomaine.label
                                    )} />
                                <label className="dip-flex-grow-1" htmlFor={sousDomaine.value}>{sousDomaine.label}</label>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {/*Button*/}
            <div className='dip-flex dip-jc-end p-mt-4 p-mr-1'>
                <Button
                    className="dip-btn-iris-bg p-button-rounded dip-ai-center dip-jc-center dip-ff-muslish"
                    disabled={sousDomainesSelected.length === 0}
                    onClick={() => nextStep()}>
                    {(t('general.next'))}
                </Button>
            </div>
        </>
    )
}
