import React, { useRef, useState, useEffect } from 'react';
import { Menu } from 'primereact/menu';
import { Trans, useTranslation } from 'react-i18next';
import { Editor } from 'react-simple-wysiwyg';
import { useAuthState } from "../../context/context";
import { Toast } from 'primereact/toast';
import AdministrationService from '../../services/administrationService';
import ImportExportService from '../../services/importExportService';
import EtudiantService from '../../services/etudiantService';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

export const MenuAdministration = () => {
    const menu = useRef(null);
    const toast = useRef(null);
    const { t } = useTranslation();

    const administrationService = new AdministrationService();
    const etudiantService = new EtudiantService();
    const importExportService = new ImportExportService();

    const [loadingDialog, setLoadingDialog] = useState(false);
    const [etudiantCount, setEtudiantCount] = useState(null);
    const [etudiantNotValidateDialog, setEtudiantNotValidateDialog] = useState(false);

    useEffect(() => {
        loadLazyData();
    }, [])

    const loadLazyData = () => {
        administrationService.getEtudiantCountWithNotEmailValidate()
            .then((data) => {
                setEtudiantCount(data)
            })
    }

    const refreshCompteurs = () => {
        toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 });

        administrationService.refreshCompteurs()
            .then(() => {
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.success'), life: 3000 });
            }
            )
            .catch((err) =>
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 })
            )
    }

    const refreshDepartementJobOfferLink = () => {
        toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 });

        administrationService.refreshDepartementJobOfferLink()
            .then(() => {
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.success'), life: 3000 });
            }
            )
            .catch((err) =>
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 })
            )
    }

    

    const getExportOffresEmploi = () => {
        toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 });

        importExportService.getOffreEmplois()
            .then(() => {
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.download_begins'), life: 3000 });
            }
            )
            .catch((err) =>
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 })
            )
    }

    const getExportFormations = () => {
        toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 });

        importExportService.getFormations()
            .then(() => {
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.download_begins'), life: 3000 });
            }
            )
            .catch((err) =>
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 })
            )
    }

    const confirmSendEmailToUnvalidatedStudents = () => {
        setLoadingDialog(true);
        administrationService.sendEmailToUnvalidatedStudents()
            .then((response) => {
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('administrator.email_sent_to_unvalidated_students'), life: 3000 });
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 });
            })
            .finally(() => {
                setLoadingDialog(false);
                hideEtudiantNotValidateDialog();
            });
    }


    const items = [
        {
            label: t('administrator.refresh_compteur'),
            command: () => {
                refreshCompteurs()
            }
        },
        {
            label: t('administrator.refresh_departement_joboffer_link'),
            command: () => {
                refreshDepartementJobOfferLink()
            }
        },
        {
            label: t('administrator.export_offre_emploi'),
            command: () => {
                getExportOffresEmploi()
            }
        },
        {
            label: t('administrator.export_formation'),
            command: () => {
                getExportFormations()
            }
        },
        {
            label: t('administrator.send_email_to_unvalidated_students'),
            command: () => {
                visibleEtudiantNotValidateDialog()
            }
        },
    ];

    const visibleEtudiantNotValidateDialog = () => {
        setEtudiantNotValidateDialog(true);
    }

    const hideEtudiantNotValidateDialog = () => {
        setEtudiantNotValidateDialog(false);
    }

    const etudiantNotValidateDialogFooter = (
        <div>
            <Button label={t('general.yes')} icon="pi pi-check" onClick={confirmSendEmailToUnvalidatedStudents} />
            <Button label={t('general.no')} icon="pi pi-times" onClick={hideEtudiantNotValidateDialog} />
        </div>
    );

    return (
        <div>
            <Toast ref={toast} />
            <div className="card">
                <h5 style={{marginTop:"0%"}}>Administration de la plateforme</h5>
                <Menu model={items} />
            </div>
            <Dialog visible={etudiantNotValidateDialog} style={{ width: '50rem' }} header={t('general.confirm')} modal footer={etudiantNotValidateDialogFooter} onHide={hideEtudiantNotValidateDialog}>
                {loadingDialog ? <ProgressSpinner/> : 
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {etudiantCount && <span>{t('administrator.confirm_send_email_to_unvalidated_students', { count: etudiantCount })}</span>}
                    </div>
                }
            </Dialog>
        </div>
    )
}
