import BaseService from './baseService';

export default class AppellationService extends BaseService {
    getAllowedAppellationsForEtudiant(etudiantId) {
        const options = {
            params: { etudiantId: etudiantId }
          };
        return this.axiosApiInstance.get(window.API_URL + '/api/appellation/AllowedAppellationsForEtudiant',options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getAppellationAlias(){
        return this.axiosApiInstance.get(window.API_URL + '/api/appellation/AppellationsAlias')
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    addAppellation(appellation){
        const jsonAppellation = JSON.stringify(appellation, null, 2);
        return this.axiosApiInstance.post(window.API_URL + '/api/appellation/AddAppellation',jsonAppellation)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getAllowedAppellationsCompetenceEtudiant(etudiantId){
        const options = {
            params: { etudiantId: etudiantId }
            };
        return this.axiosApiInstance.get(window.API_URL + '/api/appellation/AllowedAppellationsCompetenceEtudiant',options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    // addCompetence(competence) {
    //     const jsonCompetence = JSON.stringify(competence, null, 2);
    //     return this.axiosApiInstance.post(window.API_URL + '/api/Competence/AddCompetence', jsonCompetence)
    //       .then(res => res.data)
    //       .catch(err => super.handleHttpError(err));
    //   }

}