import React, { useState, useEffect, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useAuthState } from "../../context/context";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { enumCompanyStatus } from '../../enums/enumCompanyStatus';

import './dt_entreprises.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EntrepriseService from '../../services/entrepriseService';

export const DT_Entreprise = () => {

    const history = useHistory();
    const { t } = useTranslation();
    const [entreprises, setEntreprises] = useState([]);
    const [selectedEntreprises, setSelectedEntreprises] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [totalRecords, setTotalCount] = useState(0);
    const toast = useRef(null);
    const dt = useRef(null);
    const entrepriseService = new EntrepriseService();
    const currentUser = useAuthState();
    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: 'dateCreation',
        sortOrder: 0
    });
    const isMounted = useRef(false);




    useEffect(() => {
        isMounted.current = true;
        loadLazyData();
    }, [lazyParams]);


    const loadLazyData = () => {
        setLoading(true);
        entrepriseService.getEntreprisesPaginated(currentUser, lazyParams).then(_entreprises => {
            _entreprises.items.forEach(e => {
                e.nomEntreprise = e.recruteurEntreprises[0]?.entreprise?.nom
            });
            setEntreprises(_entreprises.items);
            setTotalCount(_entreprises.totalCount);
        })
        .catch(err => showError())
        .finally(() => setLoading(false))
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        _lazyParams['page'] = 0;
        _lazyParams['first'] = 0;
        setLazyParams(_lazyParams);
    }

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const lienAbonnementEntreprise = (recruteur) => {
        history.push({pathname:'/subscription_admin_company',state:{adminImpersonatingRecruteurId:recruteur.id}});
    }

    const lienPortailEntreprise = (recruteur) => {
        history.push({pathname:'/offers',state:{adminImpersonatingRecruteurId:recruteur.id}});
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-dollar" className="p-button-rounded p-button-warning p-mr-2" onClick={() => lienAbonnementEntreprise(rowData)} />
                <Button icon="pi pi-eye" className="p-button-rounded p-button-info p-mr-2" onClick={() => lienPortailEntreprise(rowData)} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="title"><Trans i18nKey="company.companies_management.full" /></h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
            </span>
        </div>
    );
   
    const statusBodyTemplate = (rowData) => {
        return <span >{t('enums.enumCompanyStatus.'+ enumCompanyStatus[rowData.status])}</span>;
    }
    const lastConnexionDateBodyTemplate = (rowData) => {
        return <span >{rowData.lastConnexionDate && new Date(rowData.lastConnexionDate).toLocaleString()}</span>;
    }
    const creationDateBodyTemplate = (rowData) => {
        return <span >{rowData.creationDate && new Date(rowData.creationDate).toLocaleString()}</span>;
    }

    return (
        <div className="datatable-entreprises">
            <Toast ref={toast} />

            <div className="card">
                <DataTable 
                    ref={dt} 
                    value={entreprises} 
                    lazy         
                    filterDelay={1000}
                    paginator 
                    first={lazyParams.first}
                    rows={lazyParams.rows} 
                    emptyMessage={t('company.no_company_to_show')} 
                    selection={selectedEntreprises} 
                    onSelectionChange={(e) => setSelectedEntreprises(e.value)}
                    dataKey="id" 
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={t('company.footer_count_pagin')}
                    globalFilter={globalFilter}
                    header={header}
                    loading={loading}
                    columnResizeMode="expand"
                    resizableColumns 
                    responsiveLayout="auto"
                    autoLayout
                    onSort={onSort} 
                    sortField={lazyParams.sortField} 
                    sortOrder={lazyParams.sortOrder}
                    onPage={onPage}
                    onFilter={onFilter}
                    filters={lazyParams.filters}
                    totalRecords={totalRecords} 
                >
                    <Column field="nomEntreprise" header={t('general.company')} sortable></Column>
                    <Column field="nom" header={t('general.lastname')} sortable></Column>
                    <Column field="prenom" header={t('general.firstname')} sortable></Column>
                    <Column field="status" header={t('general.status')} body={statusBodyTemplate} sortable></Column>
                    <Column field="creationDate" dataType="date" header={t('date.creation')} body={creationDateBodyTemplate} sortable></Column>
                    <Column field="lastConnexionDate" dataType="date" header={t('profile.lastconnexion')} body={lastConnexionDateBodyTemplate} sortable></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </div>

        </div>
    );
}