export const enumHttpError = {
    CANCELED : "Canceled",
    SKILLALREADYEXISTS : "Http Error Skill already exists.",
    JOBALREADYEXISTS : "Http Error Job already exists.",
    CANTFINDCOMPANY : "Http Error Can't find company.",
    BADLOGIN : "Votre compte ou mot de passe est incorrect.",
    ACCOUNTDISABLED : "Votre compte n'est pas activé. Veuillez consulter votre boîte mail.",
    ACCOUNTARCHIVED : "Votre compte est archivé. Veuillez contacter un administrateur de la plateforme.",
    USERNOTINACTIVESEARCH : "Http Error User is not in active search.",
    USERISNOTALLOWEDTOLOOKFORCANDIDATES : 'Http Error User is not allowed to search candidates.',
    NOLONGERAVAILABLE : 'Http Error This page is no longer available.',
    PLEASELOGIN : "Http Error Email exists. Please login.",
    ALREADYMAILEXIST : "Http Error Something goes wrong.User already exists."
}