import React from 'react';
import "./preferencesInspireMe.css";
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { Checkbox } from 'primereact/checkbox';
import { enumCategorieRecherche } from '../../../../enums/enumCategorieRecherche';
import { DipTag } from '../../../../components/tag/dipTag';

export const PreferencesInspireMe = ({ seeResults, niveauSortieOptions, itemsLevels, setItemsLevels, selectedFormationsType, setSelectedFormationsType }) => {
    const { t } = useTranslation();

    const onFormationChange = (e) => {
        let _selectedFormationsType = [...selectedFormationsType];

        if (e.checked) {
            _selectedFormationsType.push(e.value);
        } else {
            for (let i = 0; i < _selectedFormationsType.length; i++) {
                const selectedFormationType = _selectedFormationsType[i];

                if (selectedFormationType.categorie === e.value.categorie) {
                    _selectedFormationsType.splice(i, 1);
                    break;
                }
            }
        }
        setSelectedFormationsType(_selectedFormationsType);
    }

    const itemsFormationsOptions = [
        {
            label: t('formation.internship_presence.base'),
            value: true,
            categorie: enumCategorieRecherche.Stage
        },
        {
            label: t('search.with_alternance'),
            value: true,
            categorie: enumCategorieRecherche.Alternance
        },
        {
            label: t('formation.distance_training'),
            value: true,
            categorie: enumCategorieRecherche.AccessibleADistance
        },
        {
            label: t('formation.only_online_formation'),
            value: true,
            categorie: enumCategorieRecherche.EnLigne
        },
        {
            label: t('formation.continuous_training'),
            value: true,
            categorie: enumCategorieRecherche.FormationInitiale
        },
    ];


    const itemsLevelsTemplate = (level) => {
        return (
            <div className=''>
                <p>{level.label}</p>
            </div>
        )
    }

    function handleChangeLevel(e) {
        setItemsLevels(e.target.value)
    }

    return (
        <>
            <h1 className='dip-ta-center dip-color-ed'>
                <Trans i18nKey="inspire-me.preferences-title" />
            </h1>
            <div className="container-global-domaines">
                <p className='im-txt-bold'>
                    <Trans i18nKey="inspire-me.level-target" />
                </p>
                <SelectButton className='btn-diplome'
                    options={niveauSortieOptions}
                    value={itemsLevels}
                    optionLabel="label"
                    optionValue="value"
                    multiple
                    itemTemplate={itemsLevelsTemplate}
                    onChange={(e) => handleChangeLevel(e)}
                />
                <p className='im-txt-bold'>
                    <Trans i18nKey="inspire-me.formation-type" />
                </p>
                {itemsFormationsOptions.map((formation) => {
                    return (
                        <div key={formation.categorie} className="field-checkbox dip-flex-row dip-ai-center">
                            <Checkbox
                                inputId={formation.categorie}
                                name="formation"
                                value={formation}
                                onChange={onFormationChange}
                                checked={selectedFormationsType.some((item) =>
                                    item.label === formation.label
                                )} />
                            <label className="dip-flex-grow-1" htmlFor={formation.categorie}>{formation.label}</label>
                            {formation.categorie === enumCategorieRecherche.EnLigne &&
                                <DipTag className="dip-tag-pink dip-fw-700 dip-tag-p-md"
                                    label={t('formation.hundred_percent_online')}
                                />
                            }
                        </div>
                    )
                })
                }
                <div className='dip-flex dip-jc-end p-mt-4 p-mr-1'>
                    <Button
                        className="dip-btn-iris-bg p-button-rounded dip-ai-center dip-jc-center dip-ff-muslish"
                        onClick={() => seeResults()}>
                        {(t('search.see_results'))}
                    </Button>
                </div>
            </div>
        </>
    )
}
