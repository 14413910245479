import "./formationHeaderCard.css"
import { Card } from "primereact/card"
import Helper from "../../utils/helper"
import { Trans, useTranslation } from "react-i18next"
import { LikeFormation } from "../likeformation/likeformation"
import { DisplayImage } from "../image/displayImage"
import { useHistory } from "react-router-dom"
import { Button } from "primereact/button"
import { useAuthState } from "../../context/context"
import RoleHelper from "../../utils/roleHelper"
import ScrollToAnchor from "../scroll/scrollToAnchor"
import { DipTag } from "../tag/dipTag"
import { useState } from "react"
import { Dropdown } from "primereact/dropdown"
import { useEffect } from "react"
import building from '../../images/icons/building.svg'
import calendarSchedule from '../../images/icons/calendarSchedule.svg'
import language from '../../images/icons/language.svg'
import lawyer from '../../images/icons/lawyer.svg'
import { ProjetOverlayPanel } from "../overlayPanel/projetOverlayPanel"
import EntrepriseService from "../../services/entrepriseService"

export const FormationHeaderCard = (props) => {

    const history = useHistory();
    const { t } = useTranslation();
    const currentUser = useAuthState();
    const [selectedCampus, setSelectedCampus] = useState(null)
    const [campusOptions, setCampusOptions] = useState([])
    const [entrepriseIsFree, setEntrepriseIsFree] = useState(true)
    const entrepriseService = new EntrepriseService()

    const abonnements = {
        SANSABONNEMENT: "Sans abonnement",
        PARTNERSHIP: "Partnership",
        CLASSIC: "Classic",
        ANALYTICS: "Analytics"
    }

    useEffect(() => {
        buildCampusDropdown()
        accessEntreprise()
    }, [])

    const buildCampusDropdown = () => {
        let _options = []
        props.formation?.formationCampus?.forEach(fc => {
            let _campus = {
                stagePrevu: fc.stagePrevu,
                dureeStage: fc.dureeStage,
                alternance: fc.alternance,
                contratProfessionnalisation: fc.contratProfessionnalisation,
                contratApprentissage: fc.contratApprentissage,
                formationContinue: fc.formationContinue,
                formationInitiale: fc.formationInitiale,
                coutAnnuel: fc.coutAnnuel,
                isOnline: fc.campus.isOnline
            }
            if (fc.campus.isOnline) {
                _campus.nom = 'Online'
            }
            else if (fc.campus.commune) {
                _campus.nom = fc.campus.commune.libelle
            } else {
                _campus.nom = fc.campus.ville
            }
            _options.push(_campus)
        });
        _options.sort();
        setCampusOptions(_options);
    }

    const accessEntreprise = () => {
        if(RoleHelper.isEntreprise(currentUser)) {
            entrepriseService.getExtendEntrepriseProfil(currentUser.user.id)
                .then(_recruteur => {
                    if(!_recruteur.recruteurEntreprises[0]?.entreprise?.abonnementEntreprise?.isFree) {
                        setEntrepriseIsFree(false)
                    }
                })
        }
    }

    const handleOragnismeFormationClick = () => {
        if (props.formation.composanteEcole[0]?.estVisible) {
            goToComposanteEcoleFiche();
        }
        else if (props.formation.composanteEcole[0]?.ecole?.estVisible) {
            goToEcoleFiche();
        }
    }

    const goToEcoleFiche = () => {
        if (props.formation?.composanteEcole[0].ecole.estVisible) {
            history.push('/school/' + props.formation.composanteEcole[0]?.ecole?.id);
        }

    }

    const goToComposanteEcoleFiche = () => {
        if (props.formation?.composanteEcole[0].estVisible) {
            history.push('/schoolEntity/' + props.formation.composanteEcole[0]?.id);
        }
    }

    return (
        <div
            style={props.formation.imageId && {
                backgroundImage: `url(${window.API_URL}/api/image/${props.formation.imageId})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}
            className="fh-margin-compensator"
        >
            <div className="fh-card-header app-width-limit">
                <div className="p-p-4 dip-flex">
                    <div className="fh-logo-container">
                        <div className={`fh-logo-sub-container ${(props.formation.composanteEcole[0]?.estVisible || props.formation.composanteEcole[0]?.ecole?.estVisible) ? "dip-pointer" : ""}`} onClick={handleOragnismeFormationClick}>
                            {props.formation?.composanteEcole[0]?.logoId != null ? (
                                <DisplayImage imageId={props.formation?.composanteEcole[0]?.logoId} alt={t('meta-img.alt-logo-organisme-formation') + " " + props.formation?.composanteEcole[0]?.nom}></DisplayImage>
                            ) : (
                                props.formation?.composanteEcole[0]?.ecole?.logoId != null ? (
                                    <DisplayImage imageId={props.formation?.composanteEcole[0]?.ecole?.logoId} alt={t('meta-img.alt-logo-organisme-formation') + " " + props.formation?.composanteEcole[0]?.ecole?.nomEcole}></DisplayImage>
                                ) : (
                                    <div className="dip-flex-row dip-ta-center dip-jc-center">{props.formation?.composanteEcole[0]?.nom}</div>
                                )
                            )}
                        </div>
                    </div>
                    <div className="fh-info-container">
                        <div className="fh-tags-container">
                            <DipTag
                                className="dip-tag-orange dip-tag-fs-sm dip-fw-600"
                                hasLetterSpacing
                                label={props.formation.diplome}
                            />
                            <DipTag
                                className="dip-tag-orange dip-tag-fs-sm dip-fw-600"
                                hasLetterSpacing
                                label={props.formation.niveauSortie}
                            />
                        </div>
                        <div className="fh-card-title-container">
                            <div className="fh-card-title">
                                <h1>{props.formation?.mention}</h1>
                                <h2>{props.formation?.mention !== props.formation?.parcours && props.formation?.parcours}</h2>
                            </div>
                            <div className="dip-flex-row">
                                {RoleHelper.isEntreprise(currentUser) && <div className="p-mr-2"><ProjetOverlayPanel formationId={props.formation?.id} /></div>}
                                <LikeFormation formation={props.formation} toast={props.toast}></LikeFormation>
                            </div>
                        </div>
                        <div className="fh-tags-container p-mt-2">
                            {(!selectedCampus && props.formation.formationCampus.some(e => e.stagePrevu === true) || selectedCampus?.stagePrevu) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2"}
                                    hasLetterSpacing
                                    label={t('general.internship')}
                                />}
                            {(!selectedCampus && props.formation.formationCampus.some(e => e.alternance === true) || selectedCampus?.alternance) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600"}
                                    hasLetterSpacing
                                    label={t('formation.alternation.base')}
                                />}
                            {(!selectedCampus && props.formation.formationCampus.some(e => e.contratProfessionnalisation === true) || selectedCampus?.contratProfessionnalisation) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600"}
                                    hasLetterSpacing
                                    label={t('formation.pro_contract')}
                                />}
                            {(!selectedCampus && props.formation.formationCampus.some(e => e.contratApprentissage === true) || selectedCampus?.contratApprentissage) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600"}
                                    hasLetterSpacing
                                    label={t('formation.app_contract')}
                                />}

                            {(!selectedCampus && props.formation.formationCampus.some(e => e.formationInitiale === true) || selectedCampus?.formationInitiale) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600"}
                                    hasLetterSpacing
                                    label={t('formation.initial_training')}
                                />}
                            {(!selectedCampus && props.formation.formationCampus.some(e => e.formationContinue === true) || selectedCampus?.formationContinue) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600"}
                                    hasLetterSpacing
                                    label={t('formation.continuous_training')}
                                />}
                            {(!selectedCampus && props.formation.formationCampus.some(e => e.campus.isOnline === true) || selectedCampus?.isOnline) &&
                                <DipTag
                                    className={"dip-tag-pink dip-tag-fs-sm dip-fw-600"}
                                    hasLetterSpacing
                                    label={t('formation.only_online')}
                                />}
                        </div>
                        <div className="dip-flex dip-jc-between">
                            <div className="fh-campus-container">
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6">
                                        {props.formation.duree && <div className="dip-flex-row dip-ai-center">
                                            <img className="p-mr-3" src={calendarSchedule}
                                            />
                                            <span>{props.formation.duree > 1 ? <Trans i18nKey={t('formation.duration_years', { count: props.formation.duree })} /> : <Trans i18nKey={t('formation.duration_year', { count: props.formation.duree })} />}</span>
                                        </div>}
                                    </div>
                                    <div className="p-col-12 p-lg-6">
                                        <div className="dip-flex-row dip-ai-center">
                                            <img className="p-mr-3" src={language}
                                            />
                                            <span>{props.formation.langue.libelle}</span>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-6">
                                        <div className={`dip-flex-row dip-ai-center ${(props.formation.composanteEcole[0]?.estVisible || props.formation.composanteEcole[0]?.ecole?.estVisible) ? "dip-hover-color-tc" : ""}`} onClick={handleOragnismeFormationClick}>
                                            <img className="p-mr-3" src={building}
                                            />
                                            <span>{props.formation.composanteEcole[0]?.ecole?.nomEcole}</span>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-lg-6">
                                        {(!selectedCampus && props.formation.formationCampus.some(e => e.stagePrevu === true && e.dureeStage != null) || selectedCampus?.stagePrevu) && <div className="dip-flex-row dip-ai-center">
                                            <img className="p-mr-3" src={lawyer}
                                            />
                                            {props.formation.formationCampus.length == 1 || selectedCampus ? <span><Trans i18nKey={"general.internship"} /> : {props.formation.formationCampus.length == 1 ? props.formation.formationCampus[0].dureeStage : selectedCampus.dureeStage} <Trans i18nKey={"general.months"} /></span> :
                                                <span><Trans i18nKey={"formation.internship_by_campus"} /></span>}
                                        </div>}
                                    </div>
                                    <div className="p-col-12 p-lg-6">
                                        <div className="dip-flex-row dip-ai-center">
                                            <i className="pi pi-map-marker p-mr-4"
                                                style={{ fontSize: '1rem' }}
                                            />
                                            {props.formation.formationCampus.length > 1 &&
                                                <Dropdown
                                                    value={selectedCampus}
                                                    options={campusOptions}
                                                    optionLabel="nom"
                                                    onChange={(event) => setSelectedCampus(event.value)}
                                                    placeholder={`${props.formation.formationCampus.length} campus`}
                                                // {t('administrator.select_school')} 
                                                />
                                            }
                                            {props.formation.formationCampus.length == 1 &&
                                                <span>{props.formation.formationCampus[0]?.campus?.isOnline == true ? <Trans i18nKey={"general.online_en"} /> : props.formation.formationCampus[0]?.campus?.commune ? props.formation.formationCampus[0].campus.commune.libelle : props.formation?.formationCampus[0].campus.ville}</span>
                                            }
                                        </div>
                                    </div>
                                    {props.formation.formationCampus[0]?.coutAnnuel && !RoleHelper.isEntreprise(currentUser) &&
                                        <div className="p-col-12 p-lg-6">
                                            {(!selectedCampus && props.formation.formationCampus.some(e => e.coutAnnuel != null) || selectedCampus?.coutAnnuel) && <div className="dip-flex-row dip-ai-center">
                                                <i className="pi pi-euro p-mr-4"
                                                    style={{ fontSize: '1rem' }}
                                                />
                                                {props.formation.formationCampus.length == 1 || selectedCampus ? <span> {props.formation.formationCampus.length == 1 ? Helper.formatNumberMoney(props.formation.formationCampus[0].coutAnnuel) : Helper.formatNumberMoney(selectedCampus.coutAnnuel)} <Trans i18nKey="monnaie.euro_per_year" /></span> :
                                                    <span><Trans i18nKey={"formation.cost_by_campus"} /></span>}
                                            </div>}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="formation-header-action-buttons">


                                {/* {props.view === 'company' ? (
                                <div className="p-p-1 ">
                                    <Button className="p-button p-button-rounded p-button-raised primary-color-border" label={t('formation.post_annonce')} />
                                </div>
                            ) : ("")} */}
                                {props.showContact && <div className="p-p-1">
                                    <ScrollToAnchor btnId={"contact"} label={t('formation.contact_school')} className="dip-btn-iris-bg" type="button" />
                                </div>}
                                <div className="p-p-1">
                                    {RoleHelper.isEntreprise(currentUser) ?
                                        !entrepriseIsFree && props.formation?.siteWeb && <a href={props.formation.siteWeb} target={"_blank"}><Button className="dip-btn-iris-outline"><label>{t('school.official_site')}</label></Button></a>
                                        : 
                                        (RoleHelper.isSuperAdmin(currentUser) || RoleHelper.isAdmin(currentUser) || (props.formation?.composanteEcole[0]?.abonnementType != abonnements.SANSABONNEMENT)) && props.formation?.siteWeb &&
                                        <a href={props.formation.siteWeb} target={"_blank"}><Button className="dip-btn-iris-outline"><label>{t('school.official_site')}</label></Button></a>
                                    }
                                </div>
                                <div className="p-p-1">
                                    {RoleHelper.isEntreprise(currentUser) ?
                                        <>
                                            <ScrollToAnchor btnId={"candidates"} label={props.formation.nbEtudiants ? (props.formation.nbEtudiants == 1 ? t('formation.candidate_count', { count: props.formation.nbEtudiants }) : t('formation.candidates_count', { count: props.formation.nbEtudiants })) : t('company.candidate.plural')} className="dip-btn-iris-outline" redirect={props.redirect} type="button" />
                                        </>
                                        :
                                        <>
                                            {(!selectedCampus && props.formation.formationCampus.some(e => e.alternance === true) || selectedCampus?.alternance) ?
                                                <ScrollToAnchor btnId={"offers"} label={props.formation.compteurAlternance ? t('formation.alternation_offers_count', { count: props.formation.compteurAlternance }) : t('search.alternation_offers')} className="dip-btn-iris-outline" redirect={props.redirect} type="button" />
                                                :
                                                <ScrollToAnchor btnId={"offers"} label={props.formation.annoncesLiees ? t('formation.offers', { count: props.formation.annoncesLiees }) : t('search.job_offers')} className="dip-btn-iris-outline" redirect={props.redirect} type="button" />}
                                        </>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}