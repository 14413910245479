import { useEffect, useState } from 'react';
import { Button } from 'primereact/button'
import { Trans, useTranslation } from 'react-i18next'
import "./abonnementCard.css";
import { IoCheckmark, IoClose } from "react-icons/io5";
import Description from "../../utils/description";
import { InputSwitch } from 'primereact/inputswitch';

export const AbonnementCard = (props) => {

    const { t } = useTranslation();
    const [inputSwitchLabel, setInputSwitchLabel] = useState('');

    useEffect(() => {
        if (props.subscriptionActive == true)
            setInputSwitchLabel('Actif');
        else
            setInputSwitchLabel('Désactivé');
    }, [props.creationDate])

    const onChangeInputSwitch = (e) => {
        if (e.value === true) {
            setInputSwitchLabel('Actif');
        } else {
            setInputSwitchLabel('Désactivé');
        }
    }

    return (
        <div className="abonnement-card">
            {props.partnershipName === 'UPE06' &&
                <div className="partner-banner"><Trans i18nKey={"subscription.offer_upe06"} /></div>
            }
            <div className=' dip-flex-column dip-h-100 dip-jc-between'>
                <div>
                    <div className='ac-title-container'>
                        <h1>{props.title}</h1>
                    </div>
                    <div>
                        <div className='dip-ta-center ac-description-general-container'>{props.description}</div>
                        <div className='dip-ta-center p-mt-4 ac-second-title-container'>{props.descriptionTitle}</div>
                        {props.descriptionYear && <div className='dip-ta-center ac-thrid-title-container'>{props.descriptionYear}</div>}
                        <div className='p-mb-4'>
                            {/* {props.showCount && <div className='dip-ta-center dip-color-grey'>
                                <i>1/1 offre(s) d'emploi créée(s)</i>
                            </div>} */}
                        </div>
                        {props.checkDescription?.map((check, index) => {
                            return (
                                <div key={index} className='p-grid p-m-2 ac-description-container'> {/* className='dip-flex-row dip-pc-center p-m-2' */}
                                    <div>
                                        {(check.descriptionChecked === true) ?
                                            <IoCheckmark className='ac-icon-style ac-check-color p-mr-2' />
                                            :
                                            <IoClose className='ac-icon-style ac-close-color p-mr-2' />
                                        }
                                    </div>
                                    <div>{Description[check.indexDescription]}</div>
                                </div>
                            )
                        })}
                    </div>
                    {props.free ?
                        <>
                            <div className='dip-ta-center ac-green-container p-mt-4 p-mb-3'><Trans i18nKey={"subscription.free"} /></div>
                        </>
                        :
                        <>
                            <div className={props.buttonLabel ? 'p-grid p-mt-3 ac-price-button-contain' : 'p-grid p-mx-2 p-mt-3 ac-price-alone-contain'}>
                                {/* {props.tarif && <div className='ac-price-contain ac-green-container'>{props.tarif + t("monnaie.euro_ht_per_month")}</div>} */}
                                {(props.partnershipCode && props.tarif) ?
                                    <>
                                        {(props.partnershipCode.percentOff &&
                                            <div className='dip-flex-row'>
                                                <div className='ac-price-contain ac-base-container'>{props.tarif + t("monnaie.euro")}</div>
                                                <div className='ac-price-contain ac-green-container p-mx-2'>
                                                    {
                                                        Number.isInteger(props.tarif - (props.tarif * props.partnershipCode.percentOff / 100))
                                                            ? (props.tarif - (props.tarif * props.partnershipCode.percentOff / 100)) + t("monnaie.euro_ht_per_month")
                                                            : Number.parseFloat(props.tarif - (props.tarif * props.partnershipCode.percentOff / 100)).toFixed(2) + t("monnaie.euro_ht_per_month")
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {props.partnershipCode.amountOff &&
                                            <div className='dip-flex-row'>
                                                <div className='ac-price-contain ac-base-container'>{props.tarif + t("monnaie.euro")}</div>
                                                <div className='ac-price-contain ac-green-container p-mx-2'>
                                                    {
                                                        Number.isInteger(props.tarif - props.partnershipCode.amountOff)
                                                            ? (props.tarif - props.partnershipCode.amountOff) + t("monnaie.euro_ht_per_month")
                                                            : Number.parseFloat(props.tarif - props.partnershipCode.amountOff).toFixed(2) + t("monnaie.euro_ht_per_month")
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </>
                                :
                                    props.tarif &&
                                    <div className='dip-flex-row'>
                                        {props.reducePriceDefault &&
                                            <div className='ac-price-contain ac-base-container'>{props.reducePriceDefault + t("monnaie.euro")}</div>
                                        }
                                        <div className='ac-price-contain ac-green-container p-mx-2'>{props.tarif + t("monnaie.euro_ht_per_month")}</div>
                                    </div>
                                }
                                {props.buttonLabel && <div>
                                    <Button
                                        className='abonnement-card-button'
                                        label={props.buttonLabel}
                                        onClick={props.onClick}
                                    />
                                </div>}
                            </div>
                            {props.inputSwitchChecked &&
                                <div className='dip-flex-row dip-pc-center p-my-3'>
                                    <div className='ac-input-switch-container ac-input-switch-label p-mr-2'>{inputSwitchLabel}</div>
                                    <InputSwitch
                                        className='ac-input-switch'
                                        checked={inputSwitchLabel === 'Actif' ? true : false}
                                        onChange={onChangeInputSwitch}
                                        />
                                </div>
                            }
                            {props.abonnementEntreprise &&
                                <div className='dip-ta-center'>{inputSwitchLabel === 'Actif' ? <Trans i18nKey={"subscription.subscription_term"} /> : <Trans i18nKey={"subscription.subscription_end"} />} : {new Date(props.abonnementEntreprise).toLocaleDateString()}</div>
                            }
                            {props.descriptionSubscription && <div className='dip-ta-center ac-description-subscription'><strong>{props.descriptionSubscription}</strong></div>}
                        </>
                    }
                </div>
                {/* <div className='dip-flex-column dip-ta-right'>
                    {props.buttonLabel && <div>
                        <Button 
                            className='abonnement-card-button' 
                            label={props.buttonLabel} 
                            onClick={props.onClick}
                        />
                    </div>}
                    {props.buttonResiliation && <div>
                        <Button
                            className='ac-abonnement-card-button'
                            label={props.buttonResiliation}
                            onClick={props.onClickResiliation}
                        />
                    </div>}
                </div> */}
            </div>
        </div>
    )
}