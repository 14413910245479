import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import './dt_recrutements.css';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { enumContextEtudiantThumbnail } from '../../enums/enumContextEtudiantThumbnail';
import StripeService from '../../services/stripeService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Helper from '../../utils/helper';
import EntrepriseService from '../../services/entrepriseService';

export const DtFactures = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dt = useRef(null);
    const [factures, setFactures] = useState([])
    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 5,
        page: 0,
    });
    const stripeService = new StripeService();
    const entrepriseService = new EntrepriseService();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id => {
            stripeService.getFactures(id).then(_factures => {
                _factures?.map(f => { f.effectiveAt = Helper.dateTimeToLocaleDateTime(f.effectiveAt) })
                setFactures(_factures);
            }).finally(() => {
                setLoading(false)
            }
            );
        }))
    }

    const amountPaidBodyTemplate = (rowData) => {
        return <span >{rowData.amountPaid} €</span>;
    }

    const pdfLinkBodyTemplate = (rowData) => {
        return <span >{rowData.invoicePdf && <Button icon="pi pi-download" className='p-button-rounded p-button-success' onClick={() => pdfLinkOpen(rowData.invoicePdf)} />}</span>;
    }

    const pdfLinkOpen = (link) => {
        window.open(link)
    }

    return (
        <div>
            <DataTable
                ref={dt}
                emptyMessage={t('invoice.no_item_to_show')}
                value={factures}
                // paginator rows={5} rowsPerPageOptions={[5, 10, 25]}
                // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                // currentPageReportTemplate={t('recruitment.label.footer_count_pagin')}
                loading={loading}
            >
                <Column field="effectiveAt" header="Date"></Column>
                <Column field="amountPaid" header="Montant payé" body={amountPaidBodyTemplate}></Column>
                <Column field="invoicePdf" header="Lien PDF" body={pdfLinkBodyTemplate}></Column>
            </DataTable>
        </div>
    );
}