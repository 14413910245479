export default class StripeHelper {
    static addPrefilledEmail = (link,email) => {
        let encoded = encodeURIComponent(email)
        return link + "?prefilled_email=" + encoded
    }

    static addPrefilledPromoCode = (link,code) => {
        let encoded = encodeURIComponent(code)
        return link + "&prefilled_promo_code=" + encoded
    }
}