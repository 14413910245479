import React, { useState, useRef } from 'react';
import { useAuthState } from "../../context/context";
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import EcoleService from '../../services/ecoleService';


export const DT_Ecole_Bis_actions = (props) => {

    const ecole = props.ecole;
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
    const { t } = useTranslation();
    const toast = useRef(null);
    const currentUser = useAuthState();
    const ecoleService = new EcoleService();

    const EditEcole = () => {
        ecoleService
            .getEcoleComplete(ecole)
            .then((cEcole) => {
                props.onEditEcole(cEcole);
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
            })

    }

    // const EditFiche = () => {
    //     props.onEditFiche(props.ecole);
    // }


    const deleteEcole = () => {
        toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 })
        ecoleService.delete(ecole)
            .then(cEcole => {
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.entity_suppression_success'), life: 3000 });
                props.onEcoleDelete();
            }
            )
            .catch((err) =>
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.entity_suppression_failed'), life: 3000 })
            )
    }

    return (

        <React.Fragment>
            <Toast ref={toast} />
            <Button icon="pi pi-eye" className="p-button-rounded p-button-info p-mr-2" onClick={EditEcole} tooltip={t('general.edit_account')} tooltipOptions={{position: 'bottom'}}/>
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={props.onEditFiche} tooltip={t('general.edit_profile')} tooltipOptions={{position: 'bottom'}}/>
            {(currentUser.roles.indexOf("SuperAdministrateur") >= 0) && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning p-mr-2" onClick={() => setConfirmDeleteVisible(true)} />}

            <ConfirmDialog visible={confirmDeleteVisible} onHide={() => setConfirmDeleteVisible(false)} message={t('ecole.are_you_sure_delete_ecole', { ecole })}
                header={t('general.confirm')} icon="pi pi-exclamation-triangle" accept={deleteEcole} acceptLabel={t('general.yes')} rejectLabel={t('general.no')} />
        </React.Fragment>
    );
}