import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import DocumentService from "../../services/documentService";
import Helper from "../../utils/helper";
import { DisplayImage } from "../image/displayImage";
import { DisplayProfileImage } from "../image/displayProfileImage";
import './fileUploader.css'

export const FileUploader = (props) => {

    const documentService = new DocumentService();

    const { t } = useTranslation();
    let chooseOptions = {};

    if (props.buttonSetLabel && !props.entity[`${props.documentName}`]?.name) {
        chooseOptions = {
            label: props.buttonSetLabel,
            icon: "pi pi-plus",
            className: `p-button fu-button-cv`
        };
    } else {
        chooseOptions = {
            iconOnly: true,
            icon: `pi ${(props.entity[`${props.fileName}`]?.name || props.entity[`${props.documentName}`]?.name) ? (!props.isLight && 'pi-pencil') : ('pi-plus')}`,
            className: `p-button p-button-rounded p-button-icon-only ${(props.entity[`${props.fileName}`]?.name || props.entity[`${props.documentName}`]?.name) ? (props.isLight ? 'dip-display-none' : 'p-mr-1 p-button-info') : ('p-button-success')}`
        };
    }

    const fileUploadRef = useRef(null);
    const toast = useRef(null);
    const [errorSize, setErrorSize] = useState(false);

    const onUploadFile = ({ files }) => {
        if (props.allowedType.indexOf(files[0].type) == -1) {
            toast.current.show({ severity: 'error', summary: t('errors.invalidType_summary'), detail: t('errors.invalidType_detail', { type: props.accept }), life: 3000 });
        }
        else if (files[0].size > props.maxFileSize) {
            toast.current.show({ severity: 'error', summary: t('profile.invalidFileSizeMessageSummary'), detail: t('profile.invalidFileSizeMessageDetail_maxMo', { size: props.maxFileSize / 1000000 }), life: 3000 });
        }
        else {
            let _entity = { ...props.entity }
            let _file = files[0];
            let objectURL = URL.createObjectURL(_file)
            _file.objectURL = objectURL;
            _entity[`${props.fileName}`] = files[0];
            _entity[`${props.documentName}`] = null;
            props.setEntity(_entity);
            fileUploadRef.current.clear();
        }
        if(props.onChange){
            props.onChange();
        }
    };

    const onViewDownloadFile = () => {
        documentService.TelechargerDocumentPersonnel(props.entity[`${props.documentName}`].id,props.documentCategory).then(blob => {
            if(props.documentName == "fichierPhoto")
                Helper.openWindowPictureBlobFile(blob, props.entity[`${props.documentName}`].name);
            else
                Helper.openWindowBlobFile(blob, props.entity[`${props.documentName}`].name);
        });
    }

    const handleRemoveDocument = () => {
        let _entity = { ...props.entity };
        _entity[`${props.fileName}`] = null;
        props.setEntity(_entity);
        if(props.onChange){
            props.onChange();
        }
    }

    const handleRemoveFile = () => {
        let _entity = { ...props.entity };
        URL.revokeObjectURL(props.documentName.objectURL);
        _entity[`${props.documentName}`] = null;
        props.setEntity(_entity);
        if(props.onChange){
            props.onChange();
        }
    }

    const headerTemplate = (options) => {
        const { chooseButton } = options;

        return <div>
            {(props.entity[`${props.fileName}`]?.name && !props.isLight &&
                <a className="p-button-rounded p-button-warning" href={props.entity[`${props.fileName}`].objectURL} download={props.entity[`${props.fileName}`].name}>
                    <Button
                        icon="pi pi-eye"
                        className="p-mr-1 p-button-rounded p-button-warning"
                    />
                </a>)
                ||
                (props.entity[`${props.documentName}`]?.name && !props.isLight &&
                    <Button
                        icon="pi pi-eye"
                        className="p-mr-1 p-button-rounded p-button-warning"
                        onClick={() => onViewDownloadFile()}
                    />
                )}
            {chooseButton}
            {(props.entity[`${props.fileName}`]?.name &&
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => handleRemoveDocument()}
                />)
                ||
                (props.entity[`${props.documentName}`]?.name &&
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger"
                        onClick={() => handleRemoveFile()}
                    />
                )}
        </div>
    }

    const onSelectSizeError = ({ files }) => {
        setErrorSize(files[0].size > props.maxFileSize);
    }

    return (
        <div className={props.className}>
            <Toast ref={toast} />
            <div className="flavor-picture-style">
                {props.hasPreview ? (
                    props.entity[`${props.fileName}`]?.name && <img src={props.entity[`${props.fileName}`].objectURL} /> ||
                    props.entity[`${props.documentName}`]?.name && <DisplayProfileImage imageId={props.entity[`${props.documentName}`].id} /> ||
                    props.flavorPicture && <img src={props.flavorPicture} /> ||
                    props.flavorText && <div className="flavor-text-style"><span>{props.flavorText}</span></div>

                ) : (
                    props.flavorPicture && <img src={props.flavorPicture} /> ||
                    props.flavorText && <div>{props.flavorText}</div>
                )}
            </div>
            <FileUpload
                ref={fileUploadRef}
                auto
                customUpload={true}
                maxFileSize={props.maxFileSize}
                invalidFileSizeMessageSummary={t('profile.invalidFileSizeMessageSummary')}
                invalidFileSizeMessageDetail={t('profile.invalidFileSizeMessageDetail_5mb')}
                chooseOptions={chooseOptions}
                headerTemplate={headerTemplate}
                className="uploader-custom-template"
                name={props.fileName}
                url=""
                uploadHandler={onUploadFile}
                onSelect={onSelectSizeError}
                accept={props.accept} />
            {!props.isLight && <small>{props.tooltip}</small>}
            {!props.isLight && <div>
                {(props.entity[`${props.fileName}`]?.name &&
                    <span>
                        {props.entity[`${props.fileName}`].name}
                    </span>)
                    ||
                    (props.entity[`${props.documentName}`]?.name &&
                        // limitation de la taille du nom du fichier de 30 caractères
                        <span>
                            {props.entity[`${props.documentName}`].name.length > 30 ? props.entity[`${props.documentName}`].name.substring(0, 30) + '...' : props.entity[`${props.documentName}`].name}
                        </span>

                    )}
            </div>}
            <div>
                {errorSize && <small className="p-error">{props.maxFileSize > 5000000 ? <Trans i18nKey="profile.invalidFileSizeMessageDetail_10mb" /> : <Trans i18nKey="profile.invalidFileSizeMessageDetail_5mb" />}</small>}
            </div>
            {!props.entity[`${props.documentName}`]?.dateValidation && props.entity[`${props.documentName}`]?.id && <div className="waiting-for-validation">
                <Trans i18nKey={'profile.pending_validation'} />
            </div>}


        </div>
    )
}