import React, { useState, useEffect } from 'react';
import ImageService from '../../services/imageService';

export const DisplayProfileImage = (props) => {

  const [source, setSource] = useState();


  useEffect(() => {
    var imageService = new ImageService();
    imageService.getProfileImage(props.imageId)
      .then(response => {
        let blob = new Blob(
          [response.data],
          { type: response.headers['content-type'] }
        )
        let image = URL.createObjectURL(blob);
        setSource(image);
      });

    return function cleanup() {
      URL.revokeObjectURL(source);
    };

  }, [props.imageId]);

  return (
    <img src={source} onClick={props.onClick} />
  );
};
