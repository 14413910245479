import React, { useState } from "react";
import { useRef } from "react";
import { Trans, useTranslation } from "react-i18next"
import { MenuSearchTooltip } from "../../scenes/rechercheAffinee/menu/menuSearchTooltip";
import { ProgressSpinner } from "primereact/progressspinner";
import { FileUpload } from "primereact/fileupload";
import { useAuthState } from "../../context/context";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export const Import = (props) => {

    const { t } = useTranslation();
    const fileUploaderRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const currentUser = useAuthState()
    const toast = useRef(null)
    const [importFileDialog, setImportFileDialog] = useState(false);

    const onUploadFile = ({ files }) => {
        setLoading(true);
        toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 });
        props.promise(currentUser, files[0])
            .then(data => {
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.file_imported') });
                if (props.thenPromise) {
                    props.thenPromise();
                }
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_import'), life: 3000 })
            })
            .finally(() => {
                setImportFileDialog(false);
                setLoading(false);
                fileUploaderRef.current.clear();
            })
    }

    const hideImportFileDialog = () => {
        setImportFileDialog(false);
    };

    const importFileDialogFooter = (
        <React.Fragment>
            <Button label={t('general.no')} icon="pi pi-times" className="p-button-text" onClick={hideImportFileDialog} />
            <Button label={t('general.yes')} icon="pi pi-check" className="p-button-text" onClick={onUploadFile} />
        </React.Fragment>
    );

    return (
        <>
            <Toast ref={toast} />
            <div className="table-header">
                <h5 className="title dip-flex-row">{props.importTitle}{props.tooltipText && <MenuSearchTooltip className="p-ml-2" text={t('administrator.import_rome_tooltip')} />}</h5>
            </div>
            <div>
                <div className={loading && "dip-disabled"} style={{ position: 'relative' }}>
                    {loading && <div className='ie-loader-container'>
                        <ProgressSpinner />
                    </div>}
                    <FileUpload
                        disabled={loading}
                        ref={fileUploaderRef}
                        chooseLabel={t('general.choose_file')}
                        uploadLabel={t('general.upload')}
                        cancelLabel={t('general.cancel')}
                        name="fuCsvROME"
                        customUpload
                        accept=".csv"
                        maxFileSize={10000000}
                        uploadHandler={onUploadFile}
                        emptyTemplate={<p className="p-m-0"><Trans i18nKey="general.drag_drop_to_import_file" /></p>} />
                </div>
            </div>
            <Dialog visible={importFileDialog} style={{ width: '50rem' }} header={t('general.confirm')} modal footer={importFileDialogFooter} onHide={hideImportFileDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {<span><Trans i18nKey="import.are_you_sure_import" /><Trans i18nKey="general.question_symbol" /></span>}
                </div>
            </Dialog>
        </>
    )
}