export const enumNiveauEtude = {
        COLLEGELYCEE : "0",
        BAC : "1",
        BAC1: "2",
        BAC2 : "3",
        BAC3 : "4",
        BAC4 : "5",
        BAC5 : "6",
        BAC6 : "7",
        BAC8 : "8",
}