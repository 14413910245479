import React, { useState, useRef } from 'react';
import { useAuthState } from "../../context/context";
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import EcoleService from '../../services/ecoleService';
import UserService from '../../services/userService';
import RoleHelper from '../../utils/roleHelper';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


export const Dt_etudiants_action = (props) => {

    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();
    const toast = useRef(null);
    const currentUser = useAuthState();
    const userService = new UserService();
    

    const lienProfilEtudiant = (etudiant) => {
        history.push({pathname:'/profile_student',state:{adminImpersonatingEtudiantId:etudiant.id}})
    }

    const deleteCompteInvite = () => {
        toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 })
        userService.hardDelete(props.etudiant)
            .then(user => {
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.entity_suppression_success'), life: 3000 });
                props.onEtudiantDelete();
            }
            )
            .catch((err) =>
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.entity_suppression_failed'), life: 3000 })
            )
    }

    return (
        <React.Fragment>
            <Toast ref={toast} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => lienProfilEtudiant(props.etudiant)} />
                {(RoleHelper.isSuperAdmin(currentUser) && props.etudiant.isInvited) && <><Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-mr-2" onClick={() => setConfirmDeleteVisible(true)} />
                    <ConfirmDialog visible={confirmDeleteVisible} onHide={() => setConfirmDeleteVisible(false)} message={t('student.are_you_sure_delete_invited', { prenom: props.etudiant.prenom, nom: props.etudiant.nom })}
                        header={t('general.confirm')} icon="pi pi-exclamation-triangle" accept={deleteCompteInvite} acceptLabel={t('general.yes')} rejectLabel={t('general.no')} />
                </>}
            </React.Fragment>
    );
}