import React, { useRef} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAuthState } from "../../context/context";
import { enumRoles } from '../../enums/enumRoles';
import "./menuDashboard.css";
import { Link, NavLink } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { IoSchoolOutline, IoBusinessOutline, IoDocumentTextOutline, IoPerson, IoBriefcaseSharp, IoSearchOutline, IoMailOutline } from "react-icons/io5";
import RoleHelper from '../../utils/roleHelper';

export const MenuDashboard = (props) => {
    const { t } = useTranslation();
    const toast = useRef(null);
    const currentUser = useAuthState();
    const isAdmin = currentUser?.roles?.indexOf(enumRoles.ADMINISTRATEUR) > -1;
    const isSuperAdmin = currentUser?.roles?.indexOf(enumRoles.SUPERADMINISTRATEUR) > -1;
    const isAdminEcole = currentUser?.roles?.indexOf(enumRoles.ADMINISTRATEURECOLE) > -1;
    const isAdministrateurComposante = RoleHelper.isAdminComposante(currentUser);
    const isEditeur = currentUser?.roles?.indexOf(enumRoles.EDITEURECOLE) > -1;
    const isEtudiant = currentUser?.roles?.indexOf(enumRoles.ETUDIANT) > -1;
    const isEntreprise = currentUser?.roles?.indexOf(enumRoles.ENTREPRISE) > -1;

    const gestionFormations = {
        label: t('formation.formations_management'),
        lightLabel: t('formation.formations'),
        path: 'AdminFormations',
        icon: <IoDocumentTextOutline></IoDocumentTextOutline>
    }

    const ecole = {
        label: t('general.school'),
        lightLabel: t('general.school'),
        path: 'edition_fiche_ecole',
        icon: <IoDocumentTextOutline></IoDocumentTextOutline>
    }

    const composantes = {
        label: t('school.components'),
        lightLabel: t('school.components'),
        path: 'composantes',
        icon: <IoBusinessOutline></IoBusinessOutline>
    }

    const gestionAdministrateurPlateforme = {
        label: t('administrator.admins_management.full'),
        lightLabel: t('administrator.admins_management.light'),
        path: 'administrateurs'
    }

    const gestionFormationsOrphelines = {
        label: t('formation.orphans_management.full'),
        lightLabel: t('formation.orphans_management.light'),
        path: 'formationsOrphelines'
    }

    const gestionEcoleEtComposante = {
        label: t('school.school_management.full'),
        lightLabel: t('school.school_management.light'),
        path: 'ecoles',
    }

    const gestionAdminsEcoleComposante = {
        label: t('administrator.admins_composante_ecole_management.full'),
        lightLabel: t('administrator.admins_composante_ecole_management.light'),
        path: 'administrateursComposanteEtEcole',
    }

    const gestionEditeur = {
        label: t('editor.editors_management.full'),
        lightLabel: t('editor.editors_management.light'),
        path: 'editeurs',
        icon: <IoPerson></IoPerson>
    }

    const gestionEtudiants = {
        label: t('student.students_management.full'),
        lightLabel: t('student.students_management.light'),
        path: 'etudiants'
    }

    const gestionCVEtudiants = {
        label: t('student.cv_students_management.full'),
        lightLabel: t('student.cv_students_management.light'),
        path: 'cv_etudiants',
    }

    const gestionDemandesDeFormations = {
        label: t('student.coursesRequests_students_management.full'),
        lightLabel: t('student.coursesRequests_students_management.light'),
        path: 'demandeFormation_etudiants'
    }

    const gestionEntreprises = {
        label: t('company.companies_management.full'),
        lightLabel: t('company.companies_management.light'),
        path: 'entreprises',
        icon: <IoBusinessOutline></IoBusinessOutline>
    }

    const importExport = {
        label: t('general.import_export.full'),
        lightLabel: t('general.import_export.light'),
        path: 'import_export'
    }




    let itemsAdminEcole = [ecole,composantes, gestionFormations]

    let itemsAdminComposante = [composantes,gestionFormations]

    let itemsAdmin = [gestionFormations,gestionFormationsOrphelines,gestionEcoleEtComposante,gestionAdminsEcoleComposante,gestionEditeur,
        gestionEtudiants,gestionCVEtudiants,gestionDemandesDeFormations,gestionEntreprises,importExport]

    let itemsSuperAdmin = [gestionAdministrateurPlateforme,gestionFormations,gestionFormationsOrphelines,gestionEcoleEtComposante,
        gestionAdminsEcoleComposante,gestionEditeur,gestionEtudiants,gestionCVEtudiants,gestionDemandesDeFormations,gestionEntreprises,importExport]

    let itemsEtudiant = [
        {
            label: t('offers.matching_offers.full'),
            lightLabel: t('offers.matching_offers.light'),
            path: 'offresCompatibles',
            icon: <IoDocumentTextOutline></IoDocumentTextOutline>
        },
        {
            label: t('application.my_applications.full'),
            lightLabel: t('application.my_applications.light'),
            path: 'candidatures',
            icon: <IoBriefcaseSharp></IoBriefcaseSharp>
        },
        {
            label: t('profile.formation.reshearch'),
            lightLabel: t('profile.formation.plural'),
            path: 'rechercheAffinee',
            icon: <IoSchoolOutline></IoSchoolOutline>
        },
        {
            label: t('general.myProfile.full'),
            lightLabel: t('general.myProfile.light'),
            path: 'profile_student',
            icon: <IoPerson></IoPerson>
        }
        // {
        //     label: t('favorite.myFavorites.full'),
        //     lightLabel: t('favorite.myFavorites.light'),
        //     path: 'formationsFavorites',
        //     icon: <IoSchoolOutline></IoSchoolOutline>
        // }
        //CHAT-COMMENTE
        // ,
        // {
        //     label: t('Messagerie'),
        //     lightLabel: t('Messagerie'),
        //     path: 'Messagerie',
        //     icon: <IoMailOutline></IoMailOutline>
        // }
    ]

    let itemsEntreprise = [
        {
            label: t('general.myAccount.full'),
            lightLabel: t('general.myAccount.light'),
            path: 'profile_company',
            icon: <IoPerson></IoPerson>
        },
        {
            label: t('search.formations_search_short.full'),
            lightLabel: t('search.formations_search_short.light'),
            path: 'rechercheAffinee',
            newTab: true,
            icon: <IoSearchOutline></IoSearchOutline>
        },
        {
            label: t('favorite.myFavorites.full'),
            lightLabel: t('favorite.myFavorites.light'),
            path: 'formationsFavorites',
            icon: <IoSchoolOutline></IoSchoolOutline>
        },
        {
            label: t('company.recruitments.full'),
            lightLabel: t('company.recruitments.light'),
            path: 'recrutements',
            icon: <IoBriefcaseSharp></IoBriefcaseSharp>
        }
        //CHAT-COMMENTE
        // ,
        // {
        //     label: t('Messagerie'),
        //     lightLabel: t('Messagerie'),
        //     path: 'Messagerie',
        //     icon: <IoMailOutline></IoMailOutline>
        // }
    ]

    let activeItems = isSuperAdmin ?
        (itemsSuperAdmin)
        :
        (isAdmin ?
            (
                itemsAdmin
            )
            :
            (isAdminEcole ?
                (
                    itemsAdminEcole
                )
                :
                (isAdministrateurComposante ? (
                    itemsAdminComposante
                ) : (
                    isEtudiant ? (
                        itemsEtudiant
                    ) : (
                        isEntreprise ? (
                            itemsEntreprise
                        ) : (
                            []
                        )
                    )
                )
                )
            )
        )

    return (
        <>
            <Toast ref={toast}></Toast>
            <div className={`dashboard-menu-theme ${props.hasDashboardOnlyMobile ? "dashboard-menu-theme-only-mobile" : ""}`}>
                <h1>{t('general.myDashboard').toUpperCase()}</h1>
                {activeItems.map((item) =>
                    <React.Fragment key={item.path}>
                        <NavLink to={`${item.path}`} target={item.newTab ? '_blank' : undefined} className="partial-border">
                            {typeof item.icon == 'string' && <img src={item.icon}></img>}
                            {typeof item.icon == 'object' && item.icon}
                            <span className="small-display">{item.lightLabel.toUpperCase()}</span>
                            <span className="regular-display">{item.label.toUpperCase()}</span>
                        </NavLink>
                    </React.Fragment>
                )}
            </div>
        </>
    )
}
