import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { Trans, useTranslation } from "react-i18next"
import "./companyInfo.css";
import {  useRef } from "react"
import { Toast } from "primereact/toast"
import { Mailto } from "../../../mailto/mailto"
import { useAuthState } from "../../../../context/context"
import { Wysiwyg } from "../../../wysiwyg/wysiwyg"
import { EntrepriseEntityCard } from "../../../card/entrepriseEntityCard"
import { Checkbox } from "primereact/checkbox"
import { CommuneSelector } from "../../../location-selector/communeSelector"
import { Dropdown } from "primereact/dropdown"
import { enumConfWysiwyg } from "../../../../enums/enumConfWysiwyg"

export const CompanyInfo = (props) => {

    // const gouvService = new GouvService();
    // const [visibleDialog, setVisibleDialog] = useState(false)

    const toast = useRef(null)
    const { t } = useTranslation()
    const tailleOptions = [
        'Moins de 10',
        'Entre 10 et 20',
        'Entre 20 et 50',
        'Entre 50 et 250',
        'Plus de 250'
    ]
    const currentUser = useAuthState();

    // const onRechercheClick = () => {
    //     setVisibleDialog(true)
    //     setTimeout(function () {
    //         gouvService.getRechercheEntrepriseAPISirene(props.entreprise?.siret)
    //             .then(
    //                 (res) => {
    //                     let _entreprise = { ...props.entreprise }
    //                     _entreprise.nomEntreprise = res.nomEntreprise;
    //                     _entreprise.taille = res.taille;
    //                     _entreprise.localisation = res.localisation;
    //                     _entreprise.secteurActivite = res.secteurActivite;
    //                     props.setEntreprise(_entreprise);
    //                     toast.current.show({ severity: 'success', summary: 'Opération réussie', detail: 'Données récupérées avec succès', life: 3000 })
    //                     setVisibleDialog(false)
    //                     props.setSiretIsValid(true);
    //                 }
    //             )
    //             .catch((err) => {
    //                 switch (err.message) {
    //                     case enumHttpError.CANTFINDCOMPANY:
    //                         toast.current.show({ severity: 'warn', summary: t('entreprise.cant_find_company_summary'), detail: t('entreprise.cant_find_company_detail'), life: 3000 });
    //                         break;
    //                     default:
    //                         toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    //                         break;
    //                 }
    //                 props.setSiretIsValid(false);
    //                 setVisibleDialog(false)
    //             })
    //     }, 1000)
    // }


    return (
        <div className="dip-bg-color-pb fcp-card-container">
            <Toast ref={toast} />
            {/* <Dialog visible={visibleDialog} style={{ width: '60vw', textAlign: 'center' }} closable={false}>
                <div>
                    <ProgressSpinner />
                </div>
                <br />
                <div><Trans i18nKey='entreprise.fetching_data' /></div>
                <div><Trans i18nKey='entreprise.please_wait' /></div>
                <br />
                <small><i><Trans i18nKey='entreprise.may_take_one_minute' /></i></small>
            </Dialog> */}
            <div className="dip-flex">
                <div className="ec-margin">
                    <EntrepriseEntityCard
                        entreprise={props.entreprise}
                        setEntreprise={props.setEntreprise}
                    />
                    <p className="ec-help dip-ta-center">
                        <span className=" dip-color-res-blue"><Trans i18nKey='entreprise.contact_difficultes_compte_message' /></span>
                        <Mailto
                            label={t('entreprise.contact_difficultes_compte_label')}
                            className="mailto-text-style"
                            object={t('entreprise.contact_difficultes_compte_mail_object')}
                            body={encodeURIComponent(t('entreprise.contact_difficultes_compte_mail_body', { nom: currentUser.user.nom.toUpperCase(), prenom: currentUser.user.prenom }))}
                            to="contact@diplomadvisor.com"
                        />
                    </p>
                </div>

                <div>
                </div>
                <div className="fcp-right-container">
                    <h1><Trans i18nKey="entreprise.myCompany" /></h1>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='nomEntreprise'><Trans i18nKey='entreprise.name' /></label><label>*</label>
                            <InputText id='nomEntreprise'
                                value={props.entreprise?.nom}
                                disabled={props.isDisabled}
                                onChange={(e) => props.onInputChange(e, 'nom')}
                                className={classNames({ 'p-invalid': props.submitted && !props.entreprise?.nom })}
                            />
                            {props.submitted && !props.entreprise?.nom && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='phoneNumberEntreprise'><Trans i18nKey='general.phone' /></label>
                            <InputText id='phoneNumberEntreprise'
                                value={props.entreprise?.phoneNumber}
                                disabled={props.isDisabled}
                                onChange={(e) => props.onInputChange(e, 'phoneNumber')}
                                maxLength="13"
                                keyfilter={/^[0-9]+$/}
                            />
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='siret'><Trans i18nKey='entreprise.siret' /></label>
                            <InputText id='siret'
                                value={props.entreprise?.siret}
                                disabled={props.isDisabled}
                                onChange={(e) => props.onInputChange(e, 'siret')}
                                keyfilter={/^[0-9]+$/}
                                maxLength="14"
                                className={classNames({ 'p-invalid': props.submitted && props.entreprise?.siret && props.entreprise?.siret?.length != 14 })} />
                            {props.submitted && props.entreprise?.siret && props.entreprise?.siret?.length != 14 && <small className="p-error"><Trans i18nKey="company.invalid_siret" /></small>}
                        </div>
                        {/* <div className="p-field p-col-12 p-sm-12 p-lg-6 api-button-style">
                             <Tooltip target={props.entreprise?.siret?.length != 14 && ".api-button-style"} mouseTrack mouseTrackLeft={10}>
                                <Trans i18nKey='entreprise.please_enter_valid_siret' />
                            </Tooltip>
                            <Button
                                label="Récupérer les données de l'entreprise"
                                icon="pi pi-download"
                                disabled={props.entreprise?.siret?.length != 14}
                                className="frs-button p-mb-1"
                                onClick={e => onRechercheClick()}
                            /> 
                        </div> */}
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='secteurActivite'>
                                <Trans i18nKey='entreprise.activitySector' />
                            </label>
                            <InputText id='secteurActivite'
                                value={props.entreprise?.secteurActivite}
                                disabled={props.isDisabled}
                                onChange={(e) => props.onInputChange(e, 'secteurActivite')}
                            />
                        </div>
                        {/* <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='localisation'>
                                <Trans i18nKey='entreprise.location' />
                            </label>
                            <InputText id='localisation'
                                value={props.entreprise?.localisation}
                                onChange={(e) => props.onInputChange(e, 'localisation')}
                                //disabled
                            />
                        </div> */}
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='commune'>
                                <Trans i18nKey='geography.city' />
                            </label>
                            {!props.entreprise?.etranger && <CommuneSelector disabled={props.isDisabled} value={props.entreprise?.commune?.id ? (props.entreprise?.commune) : ('')} onChange={e => props.onInputChange(e, 'commune')} />}
                            {props.entreprise?.etranger && <InputText id='lieuResidence'
                                value={props.entreprise?.lieuResidence}
                                disabled={props.isDisabled}
                                onChange={(e) => props.onInputChange(e, 'lieuResidence')}
                                required
                                className={classNames({ 'p-invalid': props.submitted && !props.entreprise?.lieuResidence })} />}
                            {props.entreprise?.etranger && props.submitted && !props.entreprise?.lieuResidence && props.entreprise?.pays && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                            <div className="p-field-checkbox p-mt-2">
                                <Checkbox inputId="etranger" disabled={props.isDisabled} checked={props.entreprise?.etranger} onChange={e => props.onEtrangerChange(e)} />
                                <span htmlFor="etranger" className="p-ml-2 dip-color-res-blue"><Trans i18nKey="L'entreprise est localisée à l'étranger" /></span>
                            </div>
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            {props.entreprise?.etranger &&
                                <div className="p-field"><label htmlFor="pays"><Trans i18nKey="campus.country" /></label>
                                    <InputText id="pays" name="pays"
                                        value={props.entreprise?.pays}
                                        disabled={props.isDisabled}
                                        onChange={e => props.onInputChange(e, 'pays')}
                                        className={classNames({ 'p-invalid': props.submitted && !props.entreprise.pays })}
                                    />
                                    {props.submitted && !props.entreprise?.pays && props.entreprise?.lieuResidence && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                                </div>
                            }
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='taille'>
                                <Trans i18nKey='entreprise.size' />
                            </label>
                            <Dropdown id='taille'
                                value={props.entreprise?.taille}
                                disabled={props.isDisabled}
                                options={tailleOptions}
                                onChange={(e) => props.onInputChange(e, 'taille')}
                            />
                        </div>
                        <div className="p-field p-col-12">
                            <label>
                                <Trans i18nKey='entreprise.company_description' />
                            </label>
                            <Wysiwyg value={props.entreprise?.description} disabled={props.isDisabled} onChange={props.onWysiwygChange} Conf={enumConfWysiwyg.BASIC} defaultHeight={300} />
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='codePartenariat'><Trans i18nKey='entreprise.partnership_code' /></label>
                            <InputText id='codePartenariat'
                                value={props.entreprise?.codePartenariat}
                                disabled={props.isDisabled}
                                onChange={(e) => props.onInputChange(e, 'codePartenariat')}
                                className={classNames({ 'p-invalid': props.submitted && !props.entreprise?.codePartenariat })}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}