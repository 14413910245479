import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Toast } from "primereact/toast";
import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export const Listelibelle = (props) => {
    const { t } = useTranslation();
    const [voirPlus, setVoirPlus] = useState(false)
    const toast = useRef(null)

    const seeMore = () => {
        setVoirPlus(!voirPlus)
    }

    return (
        <>
            <Toast ref={toast} />
            {props.libelles.length > 0 &&
                props.preview > 0 && props.libelles.slice(0, props.preview).map((item, indexL) => (
                    <Fragment key={`${item}~${indexL}`}>
                        {props.display == "chip" ? (
                            <Chip className={`p-mb-3 ${props.chipClassName}`} label={item}></Chip>
                        ) : 
                        (<div className="listelibelle-thumbnail-lineheight">
                            <span>{item}</span>
                        </div>
                        )}
                    </Fragment>
                ))
            }

            {(props.preview == 0 || !props.preview) && props.libelles.map((item, indexL) => (
                <Fragment key={`${item}~${indexL}`}>
                    {props.display == "chip" ? (
                            <Chip className={`p-mb-3 ${props.chipClassName}`} label={item}></Chip>
                        ) : 
                        (<div className="listelibelle-thumbnail-lineheight">
                            <span>{item}</span>
                        </div>
                        )}
                </Fragment>
            ))}

            {(props.preview > 0 && (props.libelles.length > props.preview && voirPlus)) && props.libelles.slice(props.preview).map((item, indexL) => (
                <Fragment key={`${item}~${indexL}`}>
                    {props.display == "chip" ? (
                            <Chip className={`p-mb-3 ${props.chipClassName}`} label={item}></Chip>
                        ) : 
                        (<div className="listelibelle-thumbnail-lineheight">
                            <span>{item}</span>
                        </div>
                        )}
                </Fragment>
            ))}

            {props.preview > 0 && props.libelles.length > props.preview &&
                <div className="col-md-12 listelibelle-thumbnail-seemore">
                    <Button
                        label={!voirPlus ? t('general.see_more') : t('general.see_less')}
                        className={`offset-md-3 listelibelle-thumbnail-button-padding ${voirPlus ? ("dip-btn-red-outline") : ("dip-btn-red-bg")} `}
                        onClick={seeMore} />
                </div>
            }

        </>
    )
}
