import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const CampusLocationFormater = (props) => {

    return (
        <>
            {(props.campus.commune?.libelle || props.campus.ville || props.campus.pays) && <i className="pi pi-map-marker p-mr-1"></i>}
            {props.campus.commune?.libelle ? (
                <>
                    <span>{props.campus.commune?.libelle}</span>
                </>
            ) : (
                <>
                    <span>{props.campus.ville}</span>
                </>
            )}
            {(props.campus.commune?.libelle || props.campus.ville) && props.campus.pays && <Trans i18nKey="campus.location_separator"></Trans>}
            {props.campus.pays && <span style={{ fontStyle: 'italic' }}>{props.campus.pays}</span>}
        </>
    )
}