import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from "react-i18next"

import bannerRectangle from '../../../images/accueil/bannerRectangle.png';
import accueilEcole from "../../../images/accueil/ecole/accueilEcole.jpg";


export const HeaderHomeEcole = () => {

    const { t } = useTranslation()

    return (
        <>
            <div className='dip-only-pc dip-pc-center dip-flex-column  banner-container banner-ecole catchphrase-first'>
                <div className=' catchphrase-bloc'>
                    <img className="dip-ml-auto" src={bannerRectangle}/>
                    <div className='catchphrase-text dip-flex-column dip-h-100' >
                        <div><span className='p-mr-2'>Améliorez la visibilité de vos</span><span className='p-mr-2 dip-color-tc'>formations</span></div>
                        <div>auprès des étudiants</div>
                        <div>et des entreprises</div>
                    </div>
                </div>
            </div>
            <div className='dip-only-mobile catchphrase-first dip-flex-column dip-ta-center' >
                <img className='dip-h-auto dip-w-100' src={accueilEcole}  alt={t('meta-img.alt-header-home-ecole')}/>
                <div className='p-m-3'>
                    <div>
                        <span className='p-mr-2'>Améliorez la visibilité des vos</span>
                        <span className='dip-color-tc'>formations</span>
                    </div>
                    <div>Auprès des étudiants</div>
                    <div>et des entreprises</div>
                </div>
            </div>
        </>
    )
};