import { DisplayImage } from "../image/displayImage"
import "./backgroundImageBottomEllipsis.css"

export const BackgroundImageBottomEllipsis = (props) => {
    return (
        <div className='header-background-picture-container'>
            {props.imageId && <DisplayImage className="header-background-picture" imageId={props.imageId} alt={props.alt}/>}
            <div className='header-background-picture-border'>
                <br></br>
            </div>
        </div>
    )
}