import { Button } from "primereact/button"
import { useTranslation } from "react-i18next"
import dip_terra_cotta from "../../images/dipLogo/dip_terra_cotta.png"

export const RobFooterButtons = (props) => {

    const { t } = useTranslation()
    return (
        <div className={`rob-footer-buttons-container ${props.isEnd && 'dip-only-pc'} `}>
            <div className="dip-ta-center p-m-2">
                <img src={dip_terra_cotta}></img>
            </div>
            <div className="dip-ta-center">
                {props.isEnd ? (
                    <Button
                        id="endOnboardingEtudiantFooter"
                        className="dip-btn-red-bg"
                        label={t('general.finish')}
                        onClick={props.onEndClick}
                    />
                ) : (
                    <>
                        <Button
                            loading={props.submitting}
                            icon="pi pi-angle-left"
                            className={`dip-btn-red-outline dip-color-tc p-mr-2`}
                            label={t('general.previous')}
                            onClick={props.onPreviousClick}
                        />
                        <Button
                            loading={props.submitting}
                            icon="pi pi-angle-right"
                            className="dip-btn-red-bg"
                            label={t('general.next')}
                            onClick={props.onNextClick}
                            iconPos="right"
                        />
                    </>
                )}
            </div>
        </div>
    )
}