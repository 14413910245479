import React from 'react';
import './headerHome.css';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from 'primereact/button';
import RoleHelper from '../../../utils/roleHelper';
import { useAuthState } from "../../../context/context";
import { enumRoles } from "../../../enums/enumRoles";

export const HeaderHome = (props) => {

    const { t } = useTranslation();
    const currentUser = useAuthState();

    return (
        <div className={`container-home-student-header  ${props.appWidthLimit}`}>
            <div className="dip-pc-center dip-flex-column  banner-container banner-home-student">
                <div className='catchphrase-bloc-student-home'>
                    <div className='catchphrase-text-title dip-flex-column dip-h-100' >
                        <h1 className='p-mr-2'>
                            {t('home.catchphrase_home_students_first') + " "}
                            <p className='catchphrase-text-subtitle'>{t('home.catchphrase_home_students_second')}</p>
                        </h1>
                        <div className="container-buttons-home-student app-width-limit">
                            <div>
                                <Button className="p-button-raised p-mr-3 p-button-rounded Header_Auth_Login dip-btn-white-bg" onClick={() => props.setVisible(true)} label={t('general.searchFormation')} type="button" aria-haspopup aria-controls="overlay_panel" />
                            </div>
                            {RoleHelper.isEtudiant(currentUser) ?
                                <Link to="/offresCompatibles">
                                    <Button className="p-button-raised p-button-rounded Header_Auth_Login dip-btn-white-bg" label={t('general.searchAlternance')} type="button" aria-haspopup aria-controls="overlay_panel" />
                                </Link> :
                                RoleHelper.isNotConnected(currentUser) ?
                                    <Link to={{ pathname: "/register?User=Student", state: { userType: enumRoles.ETUDIANT } }}>
                                        <Button id='search-alternance-homepage' className="p-button-raised p-button-rounded Header_Auth_Login dip-btn-white-bg" type="button" aria-haspopup aria-controls="overlay_panel">
                                            {t('general.searchAlternance')}
                                        </Button>
                                    </Link> : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};