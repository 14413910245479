import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import UserService from '../../services/userService';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import { DT_AdministrateursEcole_actions } from './dt_administrateursEcole_actions';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';



export const DT_AdministrateursEcole = (props) => {

    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalCount] = useState(0);
    const [data, setData] = useState(null);
    const [selectedAdministrateurs, setSelectedAdministrateurs] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: 'composante',
        sortOrder: 1
    });

    const refDtAdministrateurs = useRef(null);

    const { t } = useTranslation();

    const userService = new UserService();

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadLazyData = () => {
        setLoading(true);
        userService.getAdministrateursComposanteEtEcole(props.ecoleId, lazyParams)
            .then(_data => {
                setTotalCount(_data.totalCount);
                setData(_data.items);
            })
            .catch(err => showError())
            .finally(() => setLoading(false))
    }


    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        _lazyParams['page'] = 0;
        _lazyParams['first'] = 0;
        setLazyParams(_lazyParams);
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <DT_AdministrateursEcole_actions Administrateur={rowData}
                    onAdministrateurDelete={loadLazyData}
                    editAdministrateur={props.editAdministrateur}
                    setLoadingAdministrateur={props.setLoadingAdministrateur}
                    setAdministrateurDialog={props.setAdministrateurDialog}
                    setAdministrateur={props.setAdministrateur}
                    setViewTypesAdmin={props.setViewTypesAdmin}
                    setViewTypeAdmin={props.setViewTypeAdmin}
                    composantes={props.composantes}
                    setDdlComposantes={props.setDdlComposantes}
                />
            </>
        )
    }

    const header = (      
        <div style={{textAlign:"right"}}>            
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
            </span>
        </div>  
    );


    const composantesTemplate = (rowData) => {
        return rowData.composanteEcole.map((cp) => <li>{cp.nom}</li>);
    }

    return (
        <div>
            <Toast ref={toast} />
            <div>
                <div className="card">
                    <DataTable ref={refDtAdministrateurs}
                        value={data} lazy
                        filterDelay={1000}
                        paginator first={lazyParams.first} rows={10} totalRecords={totalRecords} onPage={onPage}
                        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                        onFilter={onFilter} filters={lazyParams.filters} loading={loading}
                        emptyMessage={t('administrator.no_admin_to_show')}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                        currentPageReportTemplate={t('administrator.footer_count_pagin')} 
                        globalFilter={globalFilter} header={header}
                    >
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="composanteEcole" header={t('formation.components')} body={composantesTemplate} sortable></Column>
                        <Column field="nom" header={t('administrator.lastname')} sortable></Column>
                        <Column field="prenom" header={t('administrator.firstname')} sortable></Column>
                        <Column field="email" header={t('administrator.email')} sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div >
    );

}
