import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Trans } from 'react-i18next';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Field, Form } from 'react-final-form';
import 'primeflex/primeflex.css';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';
import { useHistory } from 'react-router';
import AuthService from '../services/authService';


export const PasswordReset = (props) => {
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const { t } = useTranslation();
    const history = useHistory();
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const token = encodeURIComponent(params.get('resetToken'));
    const email = encodeURIComponent(params.get('email'));
    const toast = useRef(null);
    const authService = new AuthService();

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.try_again'), life: 3000 });
    }

    const validate = (data) => {
        let errors = {};
        if (!data.password) {
            errors.password = <Trans i18nKey="general.required" />;
        }
        else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(data.password)) {
            errors.password = <Trans i18nKey="general.invalid_password" />;
        }

        if (data.password != data.confirmPassword) {
            errors.confirmPassword = <Trans i18nKey="general.invalid_passwords" />;
        }
        else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(data.confirmPassword)) {
            errors.confirmPassword = <Trans i18nKey="general.invalid_password" />;
        }
        return errors;
    };

    const onSubmit = (data, form) => {
        setFormData(data);

        authService.resetPassword(data).then(response => {
            if (response == null) {
                form.restart();
                showError();
            }
            else {
                setShowMessage(true);
                form.restart();
                history.push('/');
            }

        });

    };

    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };

    const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;
    const passwordHeader = <h5><Trans i18nKey="general.pick_password" /></h5>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="p-mt-2"><Trans i18nKey="general.suggestions" /></p>
            <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: '1.5' }}>
                <li><Trans i18nKey="general.least_lowercase" /></li>
                <li><Trans i18nKey="general.least_uppercase" /></li>
                <li><Trans i18nKey="general.least_numeric" /></li>
                <li><Trans i18nKey="general.minimum_caracters" /></li>
            </ul>
        </React.Fragment>
    );

    return (
        <div className="form-password-rest">
            <Toast ref={toast} />
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5><Trans i18nKey="general.successful_registration" /></h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        <Trans i18nKey="register.complete_message" /><b>{formData.email}</b>
                    </p>
                </div>
            </Dialog>

            <div className="p-d-flex p-jc-center">
                <div className="card">
                    <h3 className="p-text-center"><Trans i18nKey="password.reset_password_header" /></h3>
                    <Form onSubmit={onSubmit} initialValues={{ email: email, token: token, password: '', confirmPassword: '' }} validate={validate} render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="p-fluid">
                            <input type="hidden" value="something" />
                            <Field name="password" render={({ input, meta }) => (
                                <div className="p-field field_margin">
                                    <span className="p-float-label">
                                        <Password id="password" {...input} toggleMask className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            header={passwordHeader} footer={passwordFooter}
                                            promptLabel={t('general.enter_password')} weakLabel={t('general.weak')}
                                            mediumLabel={t('general.medium')} strongLabel={t('general.strong')} />
                                        <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid(meta) })}><Trans i18nKey="general.password" />*</label>
                                    </span>
                                    {getFormErrorMessage(meta)}
                                </div>
                            )} />
                            <Field name="confirmPassword" render={({ input, meta }) => (
                                <div className="p-field field_margin">
                                    <span className="p-float-label">
                                        <Password id="confirmPassword" {...input} toggleMask className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            header={passwordHeader} footer={passwordFooter}
                                            promptLabel={t('general.enter_password')} weakLabel={t('general.weak')}
                                            mediumLabel={t('general.medium')} strongLabel={t('general.strong')} />
                                        <label htmlFor="confirmPassword" className={classNames({ 'p-error': isFormFieldValid(meta) })}><Trans i18nKey="general.confirm_password" />*</label>
                                    </span>
                                    {getFormErrorMessage(meta)}
                                </div>
                            )} />
                            <Button type="submit" label={t('password.reset_password_button')} className="p-mt-2" />
                        </form>
                    )} />
                </div>
            </div>
        </div>
    );
}