import React, { useState, useEffect } from 'react';
import "./dialogInspireMe.css";
import { Dialog } from 'primereact/dialog';
import { Stepper } from './stepper/stepper';
import { DomainesInspireMe } from './domainesInspireMe/domainesInspireMe.js';
import { SousDomainesInspireMe } from './sousDomainesInspireMe/sousDomainesInspireMe';
import { PreferencesInspireMe } from './preferencesInspireMe/preferencesInspireMe';
import { Button } from 'primereact/button';
import { Trans, useTranslation } from 'react-i18next';
import { IoBulbOutline } from 'react-icons/io5';
import { IoBookSharp } from "react-icons/io5";
import RechercheService from "../../../services/rechercheService";
import { enumDomaines } from '../../../enums/enumTypeRechercheAffinee';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { enumCategorieRecherche } from "../../../enums/enumCategorieRecherche";
import { enumNiveauDiplome } from "../../../enums/enumNiveauDiplome";
import { useAuthState } from "../../../context/context";
import { enumRoles } from "../../../enums/enumRoles";
import banque from "../../../images/accueil/pictosDomaines/banque.png"
import business from "../../../images/accueil/pictosDomaines/business.png"
import culture from "../../../images/accueil/pictosDomaines/culture.png"
import digital from "../../../images/accueil/pictosDomaines/digital.png"
import droit from "../../../images/accueil/pictosDomaines/droit.png"
import marketing from "../../../images/accueil/pictosDomaines/marketing.png"
import sante from "../../../images/accueil/pictosDomaines/sante.png"
import science from "../../../images/accueil/pictosDomaines/science.png"

export const DialogInspireMe = ({visible, setVisible}) => {

    const [activeStep, setActiveStep] = useState(1);
    const [domainesSelected, setDomainesSelected] = useState([]);
    const [sousDomainesSelected, setSousDomainesSelected] = useState([])
    const [listeDomaines, setListeDomaines] = useState([]);
    const [listDomainesReconstitues, setListDomainesReconstitues] = useState([]);
    const [listSousDomainesReconstitues, setListSousDomainesReconstitues] = useState([]);
    const [listItemsReconstitues, setListItemsReconstitues] = useState([]);
    const [itemsLevels, setItemsLevels] = useState([]);
    const [selectedFormationsType, setSelectedFormationsType] = useState([]);

    const currentUser = useAuthState();
    const history = useHistory();
    const { t } = useTranslation();
    const rechercheService = new RechercheService();


    useEffect(() => {
        searchDomaine();
    }, [])

    const niveauSortieOptions = [
        { label: 'Bac+1', value: enumNiveauDiplome.BAC1, categorie: enumCategorieRecherche.NiveauSortie },
        { label: 'Bac+2', value: enumNiveauDiplome.BAC2, categorie: enumCategorieRecherche.NiveauSortie },
        { label: 'Bac+3', value: enumNiveauDiplome.BAC3, categorie: enumCategorieRecherche.NiveauSortie },
        { label: 'Bac+4', value: enumNiveauDiplome.BAC4, categorie: enumCategorieRecherche.NiveauSortie },
        { label: 'Bac+5 ou plus', value: enumNiveauDiplome.BAC5, categorie: enumCategorieRecherche.NiveauSortie }
    ]

    //Permet de reconstituer la liste des niveaux avec toutes les infos car on a que la value
    function seeResults() {
        // Permet de récupérer les informations liées au niveau et reconstituer la liste avec toutes les infos
        const result = niveauSortieOptions.filter((level) => {

            return itemsLevels.includes(level.value);
        });

        // Permet de constituer l'objet seulement avec les informations nécessaires  pour la requête au back
        let _resultItemsReconstitues = [];
        result.map(level => {
            _resultItemsReconstitues.push({
                label: level.label,
                value: level.value,
                categorie: level.categorie
            })
        });

        setListItemsReconstitues(_resultItemsReconstitues);

        const filtersComplete = listDomainesReconstitues.concat(listSousDomainesReconstitues, _resultItemsReconstitues, selectedFormationsType);
        history.push({ pathname: '/rechercheAffinee', state: { filters: filtersComplete } })
    }

    const searchDomaine = () => {
        rechercheService.getRechercheDipDomaine(null).then((res) => {
            setItemsDomainesOptions(res);
        });
    };


    function setItemsDomainesOptions(liste) {
        let listeDomainesTemp = [];
        liste.map(domaine => {
            switch (domaine.label) {
                case enumDomaines.BANQUE:
                    listeDomainesTemp.push({
                        categorie: domaine.categorie,
                        label: domaine.label,
                        type: domaine.type,
                        value: domaine.value,
                        image: banque,
                        ordre: 1
                    })
                    break;
                case enumDomaines.BUSINESS:
                    listeDomainesTemp.push({
                        categorie: domaine.categorie,
                        label: domaine.label,
                        type: domaine.type,
                        value: domaine.value,
                        image: business,
                        ordre: 2
                    })
                    break;
                case enumDomaines.CULTURE:
                    listeDomainesTemp.push({
                        categorie: domaine.categorie,
                        label: domaine.label,
                        type: domaine.type,
                        value: domaine.value,
                        image: culture,
                        ordre: 3
                    })
                    break;
                case enumDomaines.DIGITAL:
                    listeDomainesTemp.push({
                        categorie: domaine.categorie,
                        label: domaine.label,
                        type: domaine.type,
                        value: domaine.value,
                        image: digital,
                        ordre: 4
                    })
                    break;
                case enumDomaines.DROIT:
                    listeDomainesTemp.push({
                        categorie: domaine.categorie,
                        label: domaine.label,
                        type: domaine.type,
                        value: domaine.value,
                        image: droit,
                        ordre: 5
                    })
                    break;
                case enumDomaines.MARKETING:
                    listeDomainesTemp.push({
                        categorie: domaine.categorie,
                        label: domaine.label,
                        type: domaine.type,
                        value: domaine.value,
                        image: marketing,
                        ordre: 6
                    })
                    break;
                case enumDomaines.SANTE:
                    listeDomainesTemp.push({
                        categorie: domaine.categorie,
                        label: domaine.label,
                        type: domaine.type,
                        value: domaine.value,
                        image: sante,
                        ordre: 7
                    })
                    break;
                case enumDomaines.SCIENCES:
                    listeDomainesTemp.push({
                        categorie: domaine.categorie,
                        label: domaine.label,
                        type: domaine.type,
                        value: domaine.value,
                        image: science,
                        ordre: 8
                    })
                    break;
                default:
                    break;
            }
        })

        listeDomainesTemp.sort((a, b) => a.ordre - b.ordre);
        setListeDomaines(listeDomainesTemp);
    }

    const handleClickRedirectCompatibleOffers = () => {
        history.push('/offresCompatibles');
    }

    const steps = [
        {
            number: 1,
            name: t('search.domainesLabel'),
        },
        {
            number: 2,
            name: t('search.sousDomainesLabel'),
        },
        {
            number: 3,
            name: t('search.preferencesLabel'),
        },
    ];

    return (
        <>
        {/* Button Phone */}
            <div className=' btn-inspire-me dip-only-mobile'>
                {currentUser && currentUser.roles[0] === enumRoles.ETUDIANT ?
                    (
                        <Button className="dip-btn-iris-bg p-button-rounded dip-ai-center dip-ff-muslish " onClick={() => handleClickRedirectCompatibleOffers()}>
                            {(t('menu.my_offers'))}
                            <span className=' dip-flex dip-ai-center p-ml-2'><IoBookSharp /></span>
                        </Button>
                    )
                    :
                    (
                        <Button className="dip-btn-iris-bg p-button-rounded dip-ai-center dip-ff-muslish " onClick={() => setVisible(true)}>
                            {(t('general.inspire_me'))}
                            <div className="p-ml-2">
                                < IoBulbOutline />
                            </div>
                        </Button>
                    )
                }
            </div>
        {/* Button PC */}
            <div className=' btn-inspire-me dip-only-pc'>
                {currentUser && currentUser.roles[0] === enumRoles.ETUDIANT ?
                    (
                        <Button className="dip-btn-iris-bg p-button-rounded dip-ai-center dip-ff-muslish " onClick={() => handleClickRedirectCompatibleOffers()}>
                            <div className="">
                                <span className='dip-flex dip-ai-center'><Trans i18nKey='menu.my_offers' /><span className=' dip-flex dip-ai-center p-ml-2'><IoBookSharp /></span></span>
                            </div>
                        </Button>
                    )
                    :
                    (
                        <Button className="dip-btn-iris-bg p-button-rounded dip-ai-center dip-ff-muslish" onClick={() => setVisible(true)}>
                        {(t('general.inspire_me_text'))}
                            <div className="p-mt-2">
                            <span className='dip-flex dip-ai-center'>{(t('general.inspire_me'))}    < IoBulbOutline /></span>
                            
                            </div>
                        </Button>
                    )
                }
            </div>
            <Dialog header={<Stepper steps={steps} setActiveStep={setActiveStep} activeStep={activeStep} />} visible={visible} className="container-dialog" onHide={() => setVisible(false)}>
                <div className="dialog-wrapper-content">
                    {activeStep === 1 ?
                        <DomainesInspireMe
                            setActiveStep={setActiveStep}
                            domainesSelected={domainesSelected}
                            setDomainesSelected={setDomainesSelected}
                            listeDomaines={listeDomaines}
                            listDomainesReconstitues={listDomainesReconstitues}
                            setListDomainesReconstitues={setListDomainesReconstitues} />
                        : activeStep === 2 ?
                            <SousDomainesInspireMe
                                setActiveStep={setActiveStep}
                                domainesSelected={domainesSelected}
                                sousDomainesSelected={sousDomainesSelected}
                                setSousDomainesSelected={setSousDomainesSelected}
                                setListSousDomainesReconstitues={setListSousDomainesReconstitues}
                            /> : <PreferencesInspireMe
                                niveauSortieOptions={niveauSortieOptions}
                                itemsLevels={itemsLevels}
                                setItemsLevels={setItemsLevels}
                                selectedFormationsType={selectedFormationsType}
                                setSelectedFormationsType={setSelectedFormationsType}
                                seeResults={seeResults} />}
                </div>

            </Dialog>
        </>

    )
}
