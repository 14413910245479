import { useAuthState } from "../context/context";
import Helper from "../utils/helper";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";


import MessagerieService from "../services/messagerieService";
import { IoBriefcaseSharp, IoChevronBackCircle } from "react-icons/io5";
import './messagerie-box.css';
import { AzureCommunicationTokenCredential, CommunicationUserIdentifier } from '@azure/communication-common';
import {
  ChatAdapter,
  ChatComposite,
  CompositeLocale,
  createAzureCommunicationChatAdapter, 
  useAzureCommunicationChatAdapter,
  fromFlatCommunicationIdentifier, 
  DEFAULT_COMPONENT_ICONS, 
  COMPOSITE_LOCALE_FR_FR
} from '@azure/communication-react';
import { PartialTheme, Theme } from '@fluentui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { registerIcons } from '@fluentui/react';




const messagerieService = new MessagerieService();

export const Messagerie_Box = (props) => {
  const { t } = useTranslation();
  const currentUser = useAuthState();
  const [loading, setLoading] = useState(false);
  const [adapter, setAdapter] = useState();
  const [informationComplementaire, setInformationComplementaire] = useState(null);

  registerIcons({ icons: DEFAULT_COMPONENT_ICONS });
  initializeIcons();


  const credential = useMemo(() => {
    try {

      return new AzureCommunicationTokenCredential(props.token);
    } catch {
      console.error('Failed to construct token credential');
      return undefined;
    }
  }, [props.token]);





  useEffect(() => {

    if (!!credential && props && props.threadId) {
      setLoading(true);
      createAzureCommunicationChatAdapter({
        endpoint: props.endpointUrl,
        userId: fromFlatCommunicationIdentifier(props.userId),
        displayName: props.displayName,
        credential,
        threadId: props.threadId,
        maxPageSize: 5

      })
        .then(_datas => {
          _datas.on("messageReceived", props.MessageReceivedListener)
          _datas.on("messageSent", props.MessageSentListener)
          // _datas.fetchInitialData();
          // _datas.loadPreviousChatMessages(10);
          setAdapter(_datas);
          loadInformationComplementaire(props.threadId)
        })
        .catch((err) => {
        })
        .finally(() => {
          setLoading(false);
        }
        );
    }
  }, [props, credential]);



  const loadInformationComplementaire = (threadsId) => {
    if (threadsId) {
        //setInformationComplementaireLoading(true);
        setInformationComplementaire(null)
        let httpPromise;
        httpPromise = messagerieService.getMyThreadInfo(currentUser, [threadsId]);
        httpPromise
            .then(_compInfo => {
                setInformationComplementaire(_compInfo[0]);
            })
            .catch((err) => {
                props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 })
            })
            //.finally(() => setInformationComplementaireLoading(false));
    }
}

const seeProfile = () => {
  var win = window.open("/Candidat/" + `${informationComplementaire.interlocuteur.urlCode}` + "/" + Helper.clearString(informationComplementaire.interlocuteur.prenom) + "-" + Helper.clearString(informationComplementaire.interlocuteur.nom))
  win.focus();
}

  const onFetchAvatarPersonaData = (userId) => {
    if (informationComplementaire) {
      return { imageUrl: window.API_URL + "/api/image/getProfileImage/" + informationComplementaire.interlocuteur.pictureId };
    }
    else {

      return messagerieService.GetUserInfo(currentUser, userId).then(_chatUser => {

        if (_chatUser && _chatUser.pictureId) {
          return { imageUrl: window.API_URL + "/api/image/getProfileImage/" + _chatUser.pictureId };
        }
      })
    }

  }

    const teamsTheme = {
      palette: {
        // themePrimary: 'var(--terra-cotta)',
        themeLight: '#FADAD6',
        neutralLighter: '#E6EFEE',       
      }
    };

      if (adapter) {
        
        return (
          <div>
            {loading ? (
              <div className="chat-loading">
                <ProgressSpinner />
              </div>
            ) : (
              <div className="chatbox-main">
                <div className="p-dataview">
                    <div className="chatbox-header p-dataview-header">

                        <div className="chatbox-header-info-container">
                        <div className="thread-list-back"><Button className="p-button-raised p-button-rounded check-button" onClick={props.ToggleBar} ><label><IoChevronBackCircle></IoChevronBackCircle></label></Button></div>
                        <div className="chatbox-header-info">
                          {informationComplementaire && informationComplementaire.interlocuteur.typeUser == 0 && <><div><span className="chatbox-nom">{informationComplementaire.interlocuteur.nom} {informationComplementaire.interlocuteur.prenom}</span></div></>}

                          {informationComplementaire && informationComplementaire.interlocuteur.typeUser == 1 && <><div><span className="chatbox-nom">{informationComplementaire.interlocuteur.nom} {informationComplementaire.interlocuteur.prenom}</span> <span className="chatbox-nom-comp">{informationComplementaire.interlocuteur.nomEntreprise}</span></div></>}
                          {informationComplementaire && informationComplementaire.offreEmploi && informationComplementaire.offreEmploi.length > 0 && <><div className="chatbox-emplois p-col-12"><IoBriefcaseSharp /> <span>{informationComplementaire.offreEmploi?.map(e => e.intitule).join(',')}</span></div></>}
                        </div>
                      </div>

                      <div>
                        {informationComplementaire && informationComplementaire.interlocuteur.typeUser == 0 && <>
                          <Button className="p-button-raised p-button-rounded check-button" onClick={() => seeProfile()} ><label><i className="pi pi-eye p-mr-1" />{t('general.profile')}</label></Button></>}
                      </div>
                    </div>
                </div>
                <div className="chatbox-conv">
                  <ChatComposite
                    className={"chatbox-composite"}
                    adapter={adapter}
                    onFetchAvatarPersonaData={onFetchAvatarPersonaData}
                    fluentTheme={teamsTheme}
                    //style={styles}
                    //loadPreviousChatMessages={true}
                    locale={COMPOSITE_LOCALE_FR_FR}
                    options={{
                      errorBar: true,
                      participantPane: true,
                      topic: false,
                      maxPageSize: 5
                    }}
                  />
                </div>
              </div>)}
          </div>
        )
      }
      if (credential === undefined) {
        return <>Failed to construct credential. Provided token is malformed.</>;
      }
      return <>Initializing...</>;
}