import BaseService from './baseService';

export default class ProjetService extends BaseService {
    createOrUpdateProjet(projet,entrepriseId) {
        const jsonProjet = JSON.stringify(projet, null, 2);
        return this.axiosApiInstance.post(window.API_URL + '/api/Projet/CreateOrUpdateProjet' + (entrepriseId ? "/" + entrepriseId:""), jsonProjet)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    deleteProjet(projetId, allDeleteProjet) {
        return this.axiosApiInstance.delete(window.API_URL + '/api/Projet/DeleteProjet/' + projetId + '/' + allDeleteProjet)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getProjetsPaginated(lazyParams,entrepriseId) {
        const data = super.getAPIPagedQuery(lazyParams)
        const options = {
            headers: { 'Content-Type': 'application/json' },
        };
        return this.axiosApiInstance.post(window.API_URL + '/api/Projet/GetProjetsPaginated' + (entrepriseId ? ("/" +entrepriseId) : ""), JSON.stringify(data), options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }
    

    toggleStatut(projetId) {
        return this.axiosApiInstance.post(window.API_URL + '/api/Projet/ToggleStatut/' + projetId)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getProjetsForEtudiant(etudiantId,entrepriseId) {
        const options = {
            params : {
                etudiantId:etudiantId,
                entrepriseId:entrepriseId
            }
        }
        return this.axiosApiInstance.get(window.API_URL + '/api/Projet/GetProjetsForEtudiant',options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getProjetsForFormation(formationId,entrepriseId) {
        const options = {
            params : {
                formationId:formationId,
                entrepriseId:entrepriseId
            }
        }
        return this.axiosApiInstance.get(window.API_URL + '/api/Projet/GetProjetsForFormation',options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getProjetsForOffreEmploi(offreEmploiId,entrepriseId) {
        const options = {
            params : {
                offreEmploiId:offreEmploiId,
                entrepriseId:entrepriseId
            }
        }
        return this.axiosApiInstance.get(window.API_URL + '/api/Projet/GetProjetsForOffreEmploi/',options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    updateProjetEtudiants(etudiantId,projetIds, entrepriseId){
        const options = {
            params : {
                etudiantId:etudiantId,
                entrepriseId:entrepriseId
            }
        }
        const jsonProjetIds = JSON.stringify(projetIds, null, 2);
        return this.axiosApiInstance.post(window.API_URL + '/api/Projet/UpdateProjetEtudiants', jsonProjetIds,options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    updateProjetFormations(formationId,projetIds, entrepriseId){
        const options = {
            params : {
                formationId:formationId,
                entrepriseId:entrepriseId
            }
        }
        const jsonProjetIds = JSON.stringify(projetIds, null, 2);
        return this.axiosApiInstance.post(window.API_URL + '/api/Projet/UpdateProjetFormations', jsonProjetIds,options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    updateProjetOffreEmplois(offreEmploiId,projetIds,entrepriseId){
        const options = {
            params : {
                offreEmploiId:offreEmploiId,
                entrepriseId:entrepriseId
            }
        }
        const jsonProjetIds = JSON.stringify(projetIds, null, 2);
        return this.axiosApiInstance.post(window.API_URL + '/api/Projet/UpdateProjetOffreEmplois', jsonProjetIds,options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getEtudiantByProjet(projetId){
        return this.axiosApiInstance.get(window.API_URL + '/api/Projet/GetEtudiantByProjet/' + projetId)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }
}