import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton'
import "./vueFormation.css";
import { Accordion, AccordionTab } from 'primereact/accordion';
import FormationService from '../../services/formationService';
import { useHistory } from 'react-router-dom';
import { useAuthState } from '../../context/context';
import { useTranslation } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chip } from 'primereact/chip';
import { Fragment } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toast } from 'primereact/toast';
import RoleHelper from '../../utils/roleHelper';
import { WysiwygResponsiveRenderer } from '../../components/wysiwyg/wysiwygResponsiveRenderer';
import StatistiquesService from '../../services/statistiquesService'; // lazy loaded possible ?
import { FormationHeaderCard } from '../../components/card/formationHeaderCard';
import { Listelibelle } from '../../components/list/listeLibelle';
import { Helmet } from "react-helmet";
import { FormCourseContact } from '../../components/form/formCourseContact';
import { JobThumbnailForCourseList } from '../../components/thumbnailList/jobThumbnailForCourseList';
import { CandidateThumbnailForCourseList } from '../../components/thumbnailList/candidateThumbnailForCourseList';
import { FormationDialog } from '../../components/dialog/formationDialog';

export const VueFormation = (props) => {

    const currentUser = useAuthState();
    const { t } = useTranslation();

    const toast = useRef(null);
    const history = useHistory();

    const [loading, setLoading] = useState(true);

    const [formation, setFormation] = useState(null);
    const [canEditFormation, setCanEditFormation] = useState(false);
    const [refreshToken, setRefreshToken] = useState(null);
    const [redirect, setRedirect] = useState(null)
    const [view, setView] = useState(RoleHelper.isEntreprise(currentUser) || RoleHelper.isAdminImpersonatingEntreprise(currentUser,history.location.pathname) ? "company" : "student");

    const [appellation, setAppellation] = useState(null)
    const [compBaseSavoir, setCompBaseSavoir] = useState(null)
    const [compBaseSavoirFaire, setCompBaseSavoirFaire] = useState(null)
    const [compSpecifiqueSavoir, setCompSpecifiqueSavoir] = useState(null)
    const [compSpecifiqueSavoirFaire, setCompSpecifiqueSavoirFaire] = useState(null)
    const [compSavoirEtre, setCompSavoirEtre] = useState(null)
    const [activeIndexEtudiant, setActiveIndexEtudiant] = useState(0);
    const [activeIndexEntreprise, setActiveIndexEntreprise] = useState(0);
    const [formationDialog, setFormationDialog] = useState(false);


    const viewOptions = [
        { label: t('general.student'), value: "student" },
        { label: t('general.company'), value: "company" },
    ];

    const abonnements = {
        SANSABONNEMENT: "Sans abonnement",
        PARTNERSHIP: "Partnership",
        CLASSIC: "Classic",
        ANALYTICS: "Analytics"
    }

    const formationService = new FormationService();
    const statistiqueService = new StatistiquesService();
    const id = props.match.params.formationId;

    const [viewCompetencesDeBase, setViewCompetencesDeBase] = useState('savoir');
    const [viewCompetencesSpecifique, setViewCompetencesSpecifique] = useState('savoir');
    const viewCompetencesOptions = [
        { label: t('skills.knowledge'), value: "savoir" },
        { label: t('skills.expertise'), value: "savoir-faire" },
    ];

    useEffect(() => {
        // si l'utilisateur est un AdminImpersonatingEntreprise, on récupère son adminImpersonatingRecruteurId pour le placer dans le state courant
        if (RoleHelper.isAdminImpersonatingEntreprise(currentUser, history.location.pathname)) {
            const storedState = sessionStorage.getItem('myState');
            const state = storedState ? JSON.parse(storedState) : null;
            if (state && state.adminImpersonatingRecruteurId) {
                history.replace(history.location.pathname, { ...history.location.state, adminImpersonatingRecruteurId: state.adminImpersonatingRecruteurId });
            }
            // on supprime le state de la session storage
            sessionStorage.removeItem('myState');
        }

        //récupérer la formation avec l'id
        setLoading(true);
        statistiqueService.StatFormationConsultation(currentUser, id, history); // doit il être forcément dans le useEffect ? Effectuer si possible un chargement en parallèle
        formationService.getFormationView(id)
            .then(_formation => {
                setFormation(_formation.formation);
                setCanEditFormation(_formation.canEditFormation);
                dispatchCompetencesAndAppellations(_formation.formation)
                setLoading(false);
                if (history.location.hash == "#offers" || history.location.hash == "#candidates") {
                    setRedirect(new Date());
                }
            });
    }, [currentUser.token, refreshToken])


    const dispatchCompetencesAndAppellations = (formation) => {
        const _appellations = [...formation.formationAppellation].sort((a, b) => a.ordre - b.ordre);
        const _competences = formation.competenceFormation;
    
        const groupByTypeAndCompetence = (typeCompetence, competenceType) => {
            return _competences
                .filter(comp => comp.typeCompetence === typeCompetence && comp.competenceType === competenceType)
                .sort((a, b) => a.ordre - b.ordre)
                .map(comp => comp.libelle);
        };
    
        const _compBaseSavoir = groupByTypeAndCompetence("Savoir", 0);
        const _compBaseSavoirFaire = groupByTypeAndCompetence("SavoirFaire", 0);
        const _compSpecifiqueSavoir = groupByTypeAndCompetence("Savoir", 1);
        const _compSpecifiqueSavoirFaire = groupByTypeAndCompetence("SavoirFaire", 1);
        const _compSavoirEtre = groupByTypeAndCompetence("SavoirEtre", null);
    
        setAppellation(_appellations.map(item => item.libelle));
        setCompBaseSavoir(_compBaseSavoir);
        setCompBaseSavoirFaire(_compBaseSavoirFaire);
        setCompSpecifiqueSavoir(_compSpecifiqueSavoir);
        setCompSpecifiqueSavoirFaire(_compSpecifiqueSavoirFaire);
        setCompSavoirEtre(_compSavoirEtre);
    };    

    const hasAbonnement = (composantes) => {
        let res = false;
        composantes.forEach(c => {
            if (c.abonnementType == abonnements.PARTNERSHIP || c.abonnementType == abonnements.CLASSIC || c.abonnementType == abonnements.ANALYTICS) {
                res = true;
            }
        });
        return res;
    }

    const editFormation = () => {
        setFormationDialog(true);
    }

    const hideDialog = () => {
        setFormationDialog(false);
    }

    const triggerViewRefresh = () => {
        setRefreshToken(new Date());
    }

    return (
        <div>
            {!loading ? <Helmet>
                <meta charSet="utf-8" />
                <meta name="robots" content="all" ></meta>
                <title>{formation?.diplome} {formation?.mention} - DiplomAdvisor</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content={t('metadesc.course', { formation: formation?.diplome + " " + formation?.mention, ecole: formation?.composanteEcole[0]?.nom })} />
            </Helmet> : ''}
            <Toast ref={toast} />
            {loading ? (
                <div style={{ textAlign: 'center', marginTop: '20%', marginBottom: '20%' }}><ProgressSpinner></ProgressSpinner></div>
            ) : (
                <div className="formation-global">
                    <div className={`formation-content`}>
                        {(RoleHelper.isSuperAdmin(currentUser) || RoleHelper.isAdmin(currentUser) || RoleHelper.isAdminComposante(currentUser) || RoleHelper.isAdminEcole(currentUser)) &&
                            <div className="view-formation-action-buttons">
                                {canEditFormation && <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editFormation()} />}
                                <SelectButton value={view} options={viewOptions} unselectable={false} onChange={(e) => setView(e.value)} />
                            </div>
                        }
                        <FormationHeaderCard
                            redirect={redirect}
                            formation={formation}
                            view={view}
                            toast={toast}
                            showContact={hasAbonnement(formation?.composanteEcole) && !formation?.formationCampus.every(x => (x.addresseCourrielResponsable == null || x.addresseCourrielResponsable == "")) && (RoleHelper.isEtudiant(currentUser) || RoleHelper.isNotConnected(currentUser))}
                        />
                        <div className={props.className}>
                            {hasAbonnement(formation?.composanteEcole) || RoleHelper.isSuperAdmin(currentUser) || RoleHelper.isAdmin(currentUser) || RoleHelper.isAdminComposante(currentUser) || RoleHelper.isAdminEcole(currentUser) ?
                                <>
                                    <br />
                                    <div>
                                        {view === 'student' ? (
                                            <TabView className="vertical-tabview vf-panel-title" activeIndex={activeIndexEtudiant} onTabChange={(e) => setActiveIndexEtudiant(e.index)}>
                                                {
                                                    [
                                                        (formation.descriptionVueEtudiant || formation.descriptionVueEtudiantMobile) &&
                                                        <TabPanel header={t('formation.description')}>
                                                            <WysiwygResponsiveRenderer contentPC={formation.descriptionVueEtudiant} contentMobile={formation.descriptionVueEtudiantMobile} />
                                                        </TabPanel>,
                                                        (formation.structureProgramme || formation.structureProgrammeMobile) &&
                                                        <TabPanel header={t('formation.programme_structure')}>
                                                            <WysiwygResponsiveRenderer contentPC={formation.structureProgramme} contentMobile={formation.structureProgrammeMobile} />
                                                        </TabPanel>,
                                                        (formation.prerequis || formation.prerequisMobile) &&
                                                        <TabPanel header={t('formation.prerequisites')}>
                                                            <WysiwygResponsiveRenderer contentPC={formation.prerequis} contentMobile={formation.prerequisMobile} />
                                                        </TabPanel>,
                                                        (formation.modalitesCandidature || formation.modalitesCandidatureMobile) &&
                                                        <TabPanel header={t('formation.application_procedures')}>
                                                            <WysiwygResponsiveRenderer contentPC={formation.modalitesCandidature} contentMobile={formation.modalitesCandidatureMobile} />
                                                        </TabPanel>,
                                                        (formation.calendrier || formation.calendrierMobile) &&
                                                        <TabPanel header={t('formation.calendar')}>
                                                            <WysiwygResponsiveRenderer contentPC={formation.calendrier} contentMobile={formation.calendrierMobile} />
                                                        </TabPanel>,
                                                        (formation.descriptionAlternance || formation.descriptionAlternanceMobile) &&
                                                        <TabPanel header={t('formation.alternation.base')}>
                                                            <WysiwygResponsiveRenderer contentPC={formation.descriptionAlternance} contentMobile={formation.descriptionAlternanceMobile} />
                                                        </TabPanel>,
                                                    ].filter(x => x != null && x !== '' && x != '<br>')
                                                }
                                            </TabView>
                                        ) : (
                                            ((formation.descriptionVueEntreprise || formation.descriptionVueEntrepriseMobile) || (formation.profilEtudiants || formation.profilEtudiantsMobile) || formation.descriptionFormation.filter(o => o.titre && (o.contenu || o.contenuMobile)).length > 0) &&
                                            <TabView activeIndex={activeIndexEntreprise} className="vertical-tabview" onTabChange={(e) => setActiveIndexEntreprise(e.index)}>
                                                {
                                                    [
                                                        (formation.descriptionVueEntreprise || formation.descriptionVueEntrepriseMobile) &&
                                                        <TabPanel header={t('formation.description')}>
                                                            <WysiwygResponsiveRenderer contentPC={formation.descriptionVueEntreprise} contentMobile={formation.descriptionVueEntrepriseMobile} />
                                                        </TabPanel>,
                                                        (formation.profilEtudiants || formation.profilEtudiantsMobile) &&
                                                        <TabPanel header={t('formation.students_profile')}>
                                                            <WysiwygResponsiveRenderer contentPC={formation.profilEtudiants} contentMobile={formation.profilEtudiantsMobile} />
                                                        </TabPanel>,
                                                    ].filter(x => x != null && x !== '' && x != '<br>')
                                                }
                                                {formation.descriptionFormation.filter(o => o.titre && (o.contenu || o.contenuMobile)).map((d) => (
                                                    <TabPanel header={d.titre}>
                                                        <WysiwygResponsiveRenderer contentPC={d.contenu || ""} contentMobile={d.contenuMobile || ""} />
                                                    </TabPanel>
                                                ))}
                                            </TabView>
                                        )}
                                    </div>
                                </> : ""
                            }
                            <br />
                            {(appellation.length > 0) && <Accordion activeIndex={appellation.length > 0 && 0} className="accordion-for-formation">
                                <AccordionTab header={t('formation.target_jobs')}>
                                    <ScrollPanel className="custombar">
                                        <div className='view-formation-mobile-display'>
                                            <Listelibelle
                                                display="chip"
                                                preview={5}
                                                libelles={appellation}
                                            />
                                        </div>
                                        <div className='view-formation-pc-display'>
                                            <Listelibelle
                                                display="chip"
                                                libelles={appellation}
                                            />
                                        </div>
                                    </ScrollPanel>
                                </AccordionTab>
                            </Accordion>
                            }
                            {(compBaseSavoir.length > 0 || compBaseSavoirFaire.length > 0) && <Accordion activeIndex={(compBaseSavoir.length == 0 && compBaseSavoirFaire.length > 0) ? [1] : [0]} multiple className="accordion-for-formation">
                                <AccordionTab header={t("formation.base_skills")}>
                                    <SelectButton className="select-button-centering select-button-skills" value={viewCompetencesDeBase} options={viewCompetencesOptions} unselectable={false} onChange={(e) => setViewCompetencesDeBase(e.value)} />
                                    <ScrollPanel className="custombar">
                                        {viewCompetencesDeBase === 'savoir' && (<>
                                            <div className='view-formation-mobile-display'>
                                                <Listelibelle
                                                    display="chip"
                                                    preview={5}
                                                    libelles={compBaseSavoir}
                                                />
                                            </div>
                                            <div className='view-formation-pc-display'>
                                                <Listelibelle
                                                    display="chip"
                                                    libelles={compBaseSavoir}
                                                />
                                            </div>
                                        </>)}

                                        {viewCompetencesDeBase === 'savoir-faire' && (<>
                                            <div className='view-formation-mobile-display'>
                                                <Listelibelle
                                                    display="chip"
                                                    preview={5}
                                                    libelles={compBaseSavoirFaire}
                                                />
                                            </div>
                                            <div className='view-formation-pc-display'>
                                                <Listelibelle
                                                    display="chip"
                                                    libelles={compBaseSavoirFaire}
                                                />
                                            </div>
                                        </>)}
                                    </ScrollPanel>
                                </AccordionTab>
                            </Accordion>
                            }

                            {(compSpecifiqueSavoir.length > 0 || compSpecifiqueSavoirFaire.length > 0) && <Accordion activeIndex={(compSpecifiqueSavoir.length == 0 && compSpecifiqueSavoirFaire.length > 0) ? [1] : [0]} multiple className="accordion-for-formation">
                                <AccordionTab header={t('formation.specific_skills')}>
                                    <SelectButton className="select-button-centering select-button-skills" value={viewCompetencesSpecifique} options={viewCompetencesOptions} unselectable={false} onChange={(e) => setViewCompetencesSpecifique(e.value)} />
                                    <ScrollPanel className="custombar">
                                        {viewCompetencesSpecifique === 'savoir' && <>
                                            <div className='view-formation-mobile-display'>
                                                <Listelibelle
                                                    display="chip"
                                                    preview={5}
                                                    libelles={compSpecifiqueSavoir}
                                                />
                                            </div>
                                            <div className='view-formation-pc-display'>
                                                <Listelibelle
                                                    display="chip"
                                                    libelles={compSpecifiqueSavoir}
                                                />
                                            </div>
                                        </>}
                                        {viewCompetencesSpecifique === 'savoir-faire' && <>
                                            <div className='view-formation-mobile-display'>
                                                <Listelibelle
                                                    display="chip"
                                                    preview={5}
                                                    libelles={compSpecifiqueSavoirFaire}
                                                />
                                            </div>
                                            <div className='view-formation-pc-display'>
                                                <Listelibelle
                                                    display="chip"
                                                    libelles={compSpecifiqueSavoirFaire}
                                                />
                                            </div>
                                        </>}
                                    </ScrollPanel>
                                </AccordionTab>
                            </Accordion>
                            }

                            {compSavoirEtre.length > 0 && <Accordion activeIndex={(compSavoirEtre.length > 0) && 0} className="accordion-for-formation">
                                <AccordionTab header={t('skills.soft')}>
                                    <ScrollPanel className="custombar">
                                        <>
                                            <div className='view-formation-mobile-display'>
                                                <Listelibelle
                                                    display="chip"
                                                    preview={5}
                                                    libelles={compSavoirEtre}
                                                />
                                            </div>
                                            <div className='view-formation-pc-display'>
                                                <Listelibelle
                                                    display="chip"
                                                    libelles={compSavoirEtre}
                                                />
                                            </div>
                                        </>
                                    </ScrollPanel>
                                </AccordionTab>
                            </Accordion>
                            }
                            {formation.environnementDeTravail.length > 0 && <Accordion className="accordion-for-formation">
                                <AccordionTab header={t('formation.target_structures')}>
                                    <ScrollPanel className="custombar">
                                        {formation.environnementDeTravail?.map((edt, indexEdt) => (
                                            <Fragment key={`${edt}~${indexEdt}`}>
                                                <Chip label={<div style={{ display: "inline-flex" }} ><span style={{ alignSelf: 'center' }}>{edt.libelle}</span></div>} className="p-field" ></Chip>
                                            </Fragment>
                                        ))}
                                    </ScrollPanel>
                                </AccordionTab>
                            </Accordion>
                            }
                            {formation.secteurActivite.length > 0 && <Accordion className="accordion-for-formation">
                                <AccordionTab header={t('formation.target_sectors')}>
                                    <ScrollPanel className="custombar">
                                        {formation.secteurActivite?.map((secteur, indexSecteur) => (
                                            <Fragment key={`${secteur}~${indexSecteur}`}>
                                                <Chip label={<div style={{ display: "inline-flex" }} ><span style={{ alignSelf: 'center' }}>{secteur.libelle}</span></div>} className="p-field" ></Chip>
                                            </Fragment>
                                        ))}
                                    </ScrollPanel>
                                </AccordionTab>
                            </Accordion>
                            }
                        </div>
                        {hasAbonnement(formation?.composanteEcole) && !formation?.formationCampus.every(x => (x.addresseCourrielResponsable == null || x.addresseCourrielResponsable == "")) && (RoleHelper.isEtudiant(currentUser) || RoleHelper.isNotConnected(currentUser)) && view =='student' &&
                            <FormCourseContact campus={formation.formationCampus.filter((x) => (x.addresseCourrielResponsable != null && x.addresseCourrielResponsable != ""))} id="contact" />}
                        <div className={props.className}>
                            {(!RoleHelper.isEntreprise(currentUser)) && view =='student' && <JobThumbnailForCourseList id="offers" formationId={formation?.id} />}
                            {(RoleHelper.isEntreprise(currentUser) || RoleHelper.isAdmin(currentUser) || RoleHelper.isSuperAdmin(currentUser)) && view =='company' && 
                                <CandidateThumbnailForCourseList id="candidates" formationId={formation?.id} />
                            }
                        </div>
                    </div >
                </div >)
            }
            {canEditFormation && 
            <FormationDialog visible={formationDialog} onHide={hideDialog} formationId={formation?.id} isDuplicate={false} onSave={triggerViewRefresh} />
            }
        </div >


    )
}
