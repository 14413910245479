export const Tel = (props) => {

    const buildTel = () => 
    {
        var toRet =  `tel:${props.to}`;
        return toRet;
    }

    return (<>
        <a className={props.className} href={buildTel()} >{props.label}</a>
    </>)
}