import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Trans } from 'react-i18next';
import 'primeflex/primeflex.css';
import { Dialog } from 'primereact/dialog';
import { useHistory } from 'react-router';
import UserService from '../services/userService';


export const ActivateAccountAdmin = (props) => {
    const [showMessage, setShowMessage] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const search = props.location.search;
    const params = new URLSearchParams(search);
    //const email = params.get('email');
    //const token = params.get('token');
    const token = encodeURIComponent(params.get('token'));
    const email = encodeURIComponent(params.get('email'));

    const userService = new UserService();
    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });
    const isMounted = useRef(false);






    const loadLazyData = () => {
        setLoading(true);
        userService.activateaccount(email, token, { lazyEvent: JSON.stringify(lazyParams) }).then(reponse => {
            setShowMessage(true);
            setLoading(false);
        });
    };

    useEffect(() => {
        isMounted.current = true;
        loadLazyData();
    }, [lazyParams]);

    const redirectHome = () => {
        setShowMessage(false);
        history.push('/');
    }

    const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => redirectHome()} /></div>;

    return (
        <div className="form-activate-account">
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5><Trans i18nKey="general.activated_account" /></h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        <Trans i18nKey="register.activated_admin_account" />
                    </p>
                </div>
            </Dialog>
        </div>
    );
}
