import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue} from "react-cookie-consent";
import { useTranslation } from "react-i18next"
import StatistiquesService from '../../services/statistiquesService';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom'
import './cookiesDialog.css';
import parse from 'html-react-parser';

export const CookiesDialog = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory()
    const statistiqueService = new StatistiquesService();

    const [displayCookie, setDisplayCookies] = useState(getCookieConsentValue() === undefined && location.pathname != "/confidentiality");
    const [wasResetCookie, setWasResetCookies] = useState(false);

    useEffect(()=> {
         tryInitTagManager()
        if(props.cookieReset)
            onReset();
    }, [props.cookieReset])

    const tryInitTagManager = () => {
        if (process.env.REACT_APP_GTM_ID) {
            window.GTM_ID = process.env.REACT_APP_GTM_ID;
        }

        if (window.GTM_ID && getCookieConsentValue() === 'true') {

            const tagManagerArgs = {
                gtmId: window.GTM_ID
            }
            console.log("GTM-INIT");
            TagManager.initialize(tagManagerArgs)
        }
    }

    const onReset = () => {
        resetCookieConsentValue()
        setDisplayCookies(true);
        setWasResetCookies(true);
    }

    const onShow = () => {
        setDisplayCookies(true);
    }

    const onHide = () => {
        setDisplayCookies(false);
        console.log(getCookieConsentValue() === 'true' ? t('consent.cookiesAccepted') : t('consent.cookiesRefused'));
    }

    const onAccept = () => {
        onHide();
        tryInitTagManager();
        statistiqueService.StatCookieChoose(props.currentUser, true, !wasResetCookie, history);
    }

    const onDecline = () => {
        onHide();
        clearCookies();
        statistiqueService.StatCookieChoose(props.currentUser, false, !wasResetCookie, history);
    }

    const clearCookies = () => { 
        if(getCookieConsentValue() !== 'true')
        {
            var cookies = document.cookie.split(';');
            cookies.forEach(element => {
                const [key, val] = element.trim().split('=').map(decodeURIComponent)
                //Suppression des cookies analitics
                if (key.startsWith('_ga') || key.startsWith('_hj') || key.startsWith('_fbp') || key.includes('facebook.com')) {
                    document.cookie = key + '=; Max-Age=-99999999;';
                }
            });
        }
    }

    const headerTemplate = () => {
        return (
            <div className='cookieconsent-header'>
                <div className="dialog-title">
                    {t('consent.dialogTilte')}
                </div>
            </div>
        )
    }

    return (
        <div>
            <Dialog className="cookiesconsent-dialog" header={headerTemplate} visible={displayCookie} closeOnEscape={false} closable={false} onHide={() => onHide()} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw' }}>
                <CookieConsent containerClasses="NotACookieOverlay" onAccept={() => onAccept()} disableStyles={true} declineButtonClasses={"p-button p-component ccb-cancel"} buttonClasses={"p-button p-component ccb-ok p-ml-2 p-mr-2"}
                    buttonText={t('consent.acceptall')} onDecline={() => onDecline()} enableDeclineButton={true} declineButtonText={t('consent.denyall')} debug={false} expires={182}>
                    <div className="ta-left">{parse(t('consent.dialogText1'))}</div>

                    {/* <Accordion>
                        <AccordionTab header={parse(t('consent.dialogAccordionTitle'))}>
                            <div className="ta-left">{parse(t('consent.dialogAccordionText'))}</div>
                        </AccordionTab>
                    </Accordion> */}

                    <div className="ta-left">{parse(t('consent.dialogText2'))}</div>
                </CookieConsent>
            </Dialog>
        </div>
    )
}