import BaseService from './baseService';
import axios from 'axios'

export default class FormationService extends BaseService {


  getFormation(id) {
    const options = {
      params: { formationId: id }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/formation', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getFormationExtraLight(id) {
    const options = {
      params: { formationId: id }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/FormationExtraLight', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getFormationView(id) {
    const options = {
      params: { formationId: id }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/FormationView', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  likeFormation(formationId) {
    return this.axiosApiInstance.post(window.API_URL + '/api/formation/like/' + formationId)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  likeEntrepriseFormation(formationId, entrepriseId) {
    return this.axiosApiInstance.post(window.API_URL + '/api/formation/likeEntrepriseFormation/' + formationId + '/' + entrepriseId)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  unlikeFormation(formationId) {
    return this.axiosApiInstance.post(window.API_URL + '/api/formation/unlike/' + formationId)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  unlikeEntrepriseFormation(formationId, entrepriseId) {
    return this.axiosApiInstance.post(window.API_URL + '/api/formation/unlikeEntrepriseFormation/' + formationId + '/' + entrepriseId)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getFormationsEtudiantProfile() {
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/formationsetudiantprofile')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getFormationsOrphelines(lazyParams) {
    const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))
    return this.axiosApiInstance.get(window.API_URL + `/api/formation/getFormationsOrphelines?` + queryParams)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getFormationForEcole(ecoleId, lazyParams) {
    const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))

    return this.axiosApiInstance.get(window.API_URL + `/api/formation/getFormationForEcole/${ecoleId}?` + queryParams)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  createOrUpdateFormation(currentUser, formation, demandeFormation) {
    const jsonFormation = JSON.stringify(formation, null, 2);
    const jsonDemandeFormation = JSON.stringify(demandeFormation, null, 2);
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + currentUser.token
      }
    };
    const formData = new FormData()
    formData.append('formation', jsonFormation);
    if (formation.fileImage !== undefined)
      formData.append('fileImage', new Blob([formation.fileImage], { type: formation.fileImage.type }), formation.fileImage.name);
    formData.append('demandeFormation', jsonDemandeFormation);
    return axios.post(window.API_URL + '/api/formation/Formation', formData, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  delete(formation) {
    return this.axiosApiInstance.delete(window.API_URL + '/api/formation/' + formation.id)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getNiveauxDiplome() {
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/niveauxdiplome')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getNiveauxEntreesDiplome() {
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/niveauxentreesdiplome')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getNiveauxSortiesDiplome() {
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/niveauxsortiesdiplome')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getNiveauxEtude() {
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/niveauxetude')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getMobilite() {
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/mobilite')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getFrequenceReception() {
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/frequencereception')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getAlternanceType() {
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/alternancetype')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getEcolesFiltered(query) {
    const options = {
      params: { query: query }
    }
    return this.axiosApiInstance.get(window.API_URL + '/api/ecole/ecolesFiltered', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getAppellationsPaginated(lazyParams, cancelToken) {
    const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))
    return this.axiosApiInstance.get(window.API_URL + `/api/Appellation/GetPaginated?${queryParams}`, cancelToken)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getDemandeFormationEtudiants() {
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/EtudiantsDemandeFormation')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getOrganismeFormationFiltered(query) {
    const params = { query: query }
    const queryParams = super.getQueryParams(params);
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/OrganismeFormationFiltered?' + queryParams)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getDiplomeFiltered(query, organismeFormation) {
    const params = { query: query, ofId: organismeFormation != undefined ? organismeFormation.id : '', ofType: organismeFormation != undefined ? organismeFormation.type : '' }
    const queryParams = super.getQueryParams(params);
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/GetDiplomeFiltered?' + queryParams)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getFormationFiltered(query, organismeFormation, diplome) {
    const params = { query: query, ofId: organismeFormation.id, ofType: organismeFormation.type, diplome: diplome.nom }
    const queryParams = super.getQueryParams(params);
    return this.axiosApiInstance.get(window.API_URL + '/api/formation/GetFormationFiltered?' + queryParams)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  updateCommentaireDemandeFormationEtudiant(demandeFormationId, commentaire) {
    const options = {
      params: { commentaire: commentaire }
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/formation/demande/' + demandeFormationId + '/commentaire', null, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getDtAdminFormationsDemandees(lazyParams, archivedFilters) {
    const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))
    const options = {
      params: { archivedFilters: archivedFilters }
    };
    return this.axiosApiInstance.get(window.API_URL + `/api/formation/getDtAdminFormationsDemandees?` + queryParams, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getFormationsDemandees(lazyParams) {
    const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))

    return this.axiosApiInstance.get(window.API_URL + `/api/formation/getFormationsDemandees?` + queryParams)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  affecterFormationEtudiant(formationId, demandeFormationId) {
    return this.axiosApiInstance.post(window.API_URL + '/api/formation/demande/' + demandeFormationId + "/formation/" + formationId, null)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  archiverDemandeFormationEtudiant(demandeFormationId) {
    return this.axiosApiInstance.post(window.API_URL + '/api/formation/demande/archiver/' + demandeFormationId, null)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  desarchiverDemandeFormationEtudiant(demandeFormationId) {
    return this.axiosApiInstance.post(window.API_URL + '/api/formation/demande/desarchiver/' + demandeFormationId, null)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  addDiplome(diplome) {
    //const jsonDiplome = JSON.stringify(diplome);
    const formData = new FormData()
    formData.append('Id', diplome.Id);
    formData.append('Nom', diplome.Nom);

    const options = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    return this.axiosApiInstance.post(window.API_URL + '/api/formation/CreateOrUpdateDiplome/', formData, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getAllowedFormationsCompetencesEtudiant(etudiantId) {
    const options = {
      params: { etudiantId: etudiantId }
      };
  return this.axiosApiInstance.get(window.API_URL + '/api/formation/getAllowedFormationsCompetencesEtudiant', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

}
