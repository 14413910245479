import { Trans } from "react-i18next"
import { useHistory } from "react-router-dom"
import RoleHelper from "../../utils/roleHelper"

export const EntrepriseProfilBreadCrumb = (props) => {

    const history = useHistory()

    const onCandidatesClick = () => {
        RoleHelper.redirectWithImpersonationCheck(history,"/seeked_profiles",{entrepriseId:history.location.state.entrepriseId})
    }

    return(
        <div className="dip-color-res-blue dip-pointer">
            <span className={props.step == 0 && "dip-fw-700"} onClick={props.step >= 1 && onCandidatesClick}><Trans i18nKey="entreprise.myProfiles" /></span>
            {props.step >= 1 && <>{" > "}<span className={props.step == 1 && "dip-fw-700"}><Trans i18nKey="company.file_candidate" /></span></>}
        </div>
    )
}