import React, { Children } from 'react';
import './howItMatch.css';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from 'primereact/button';
import { useAuthState } from "../../../../context/context";
import { enumRoles } from '../../../../enums/enumRoles';

export const HowItMatch = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const currentUser = useAuthState();

    const itemsNumbersSteps = [
        {
            number: "1.",
            line: t('lets_go_module.title1') + " " + t('lets_go_module.text1')
        },
        {
            number: "2.",
            line: t('lets_go_module.title2') + " " + t('lets_go_module.text2')
        },
        {
            number: "3.",
            line: t('lets_go_module.title3_1') + " " + t('lets_go_module.title3_2') + " " + t('lets_go_module.text3')
        }
    ]

    return (
        <div className='dip-flex-column container-him dip-only-mobile'>
            <div className='dip-flex-column container-him-intro'>
                <h1>{t('general.howItMatch')}</h1>
                <span>{t('general.howItMatch_description')}</span>
            </div>
            <div className='dip-flex-column container-global-number'>
                {itemsNumbersSteps.map((step) => {
                    return (
                        <div key={step.number} className='dip-flex-row container-numbers'>
                            <span className='number-style-number'>{step.number}</span>
                            <span className='number-style-others-lines'>{step.line}</span>
                        </div>
                    )
                })}
            </div>
            <div className='p-mt-3 dip-flex'>
                <div className=' dip-ta-center'>
                    <Link to={{ pathname: "/register?User=Student", state: { userType: enumRoles.ETUDIANT } }}>
                        <Button className="p-button-raised p-mr-3 p-button-rounded Header_Auth_Login dip-btn-iris-bg dip-ff-muslish" label={t('general.iCreateMyAccount')} />
                    </Link>
                </div>
            </div>
        </div>
    )
};