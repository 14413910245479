import { SelectButton } from "primereact/selectbutton"
import { useContext, useState } from "react"
import { enumCategorieRecherche } from "../../enums/enumCategorieRecherche"
import RechercheEntrepriseOffres from "../../pages/entreprise/RechercheEntrepriseOffres"
import "./filterPanelOffers.css"
import { Trans } from "react-i18next"

export const FilterPanelProjects = () => {

    const [statut, setStatut] = useState('none');
    const contextRecherche = useContext(RechercheEntrepriseOffres);


    const statutOptions = [
        { name: 'Tous', value: 'none' },
        { name: 'Ouverts', value: 'true' },
        { name: 'Fermés', value: 'false' }
    ]
 
    const majResults = (_lazyParams) => {
        contextRecherche.setLazyParams(_lazyParams);
        contextRecherche.rechercheApi(_lazyParams);
    }

    const handleChangeStatut = (e) => {
        e.preventDefault()
        try {
            let _filters = [...contextRecherche.lazyParams.filters];

            let _newFilters = _filters.filter((e) => {
                return e.categorie !== enumCategorieRecherche.StatutRecrutement
            })
            if (e.value && e.value != "none") {
                _newFilters.push({ categorie: enumCategorieRecherche.StatutRecrutement, value: e.value })
            }
            let _lazyParams = { ...contextRecherche.lazyParams };
            _lazyParams.first = 0;
            _lazyParams.page = 1;
            _lazyParams.filters = _newFilters;
            majResults(_lazyParams);
            setStatut(e.value)
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div className="fpo-main">
            <h1><Trans i18nKey="offers.filter_by" /></h1>
            <div>
                <label><Trans i18nKey="entreprise.recruitmentStatus" /></label>
                <SelectButton
                    className='fpo-selectbutton p-mt-2'
                    options={statutOptions}
                    value={statut}
                    optionLabel="name"
                    unselectable={false}
                    multiple={false}
                    onChange={(e) => handleChangeStatut(e)}
                />
            </div>
        </div>
    )
}