
import axios from 'axios';
import { enumHttpError } from '../enums/enumHttpError';


export default class BaseService {

  axiosApiInstance

  constructor() {
    this.axiosApiInstance = axios.create();

    // Request interceptor for API calls
    this.axiosApiInstance.interceptors.request.use(
      async config => {
        const currentUser = localStorage.getItem('currentUser');
        if (currentUser == null)
          return config;
        const token = JSON.parse(currentUser).token
        config.headers = {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
        return config;
      },
      error => {
        Promise.reject(error)
      });
  }


  getAPIPagedQuery(lazyEvent) {
    let _first = (lazyEvent.first == null ? 0 : lazyEvent.first);
    let _firstPageSize = (lazyEvent.firstRows == null ? 0 : lazyEvent.firstRows);
    let _pageSize = (lazyEvent.rows == null ? 0 : lazyEvent.rows);
    let _pageIndex = (lazyEvent.page == null ? 0 : lazyEvent.page);

    //let pageIndex = (_first / _pageSize).toString();
    let pageIndex = _pageIndex.toString();
    let firstPageSize = _firstPageSize.toString();
    let pageSize = _pageSize.toString();
    let sortBy = (lazyEvent.sortField == null ? "" : lazyEvent.sortField.toString());
    let sortAsc = (lazyEvent.sortOrder === 1 ? true : false).toString();


    let filters = [];
    if (lazyEvent.filters != null) {
      for (var property in lazyEvent.filters) {
        if (lazyEvent.filters[property].categorie !== undefined)
          filters.push({
            field: property,
            matchMode: lazyEvent.filters[property].matchMode,
            value: lazyEvent.filters[property].value,
            label: lazyEvent.filters[property].label,
            categorie: lazyEvent.filters[property].categorie,
            exige: lazyEvent.filters[property].exige === undefined ? false : lazyEvent.filters[property].exige,
            disabled: lazyEvent.filters[property].disabled === undefined ? false : lazyEvent.filters[property].disabled,
          })
        else
          filters.push({
            field: property,
            matchMode: lazyEvent.filters[property].matchMode,
            value: lazyEvent.filters[property].value,
          })
      }
    }

    return {
      filters,
      pageIndex,
      firstPageSize,
      pageSize,
      sortBy,
      sortAsc
    }
  }

  getQueryParamsFromArray(params, arrayName) {
    var parameters = [];
    params.forEach((v, k) => {
    if(typeof(v) === 'object')
    {
      for (var property in v) {
        if (v.hasOwnProperty(property)) {
          parameters.push(encodeURIComponent(`${arrayName}[${k}].${property}`) + '=' + encodeURIComponent(`${v[property]}`));
        }
      }
    }
    else{
      parameters.push(encodeURIComponent(`${arrayName}[${k}]`) + '=' + encodeURIComponent(`${v}`));
    }
    });

    return parameters;
  }

  getQueryParams(params) {
    let string = [];
    for (const [key, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        string = string.concat(this.getQueryParamsFromArray(value, key));
      } else {
        string.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
      }
    }

    return string.join('&');
  }

  handleHttpError(error) {
    if (axios.isCancel(error)) {
      throw new Error(enumHttpError.CANCELED);
    }
    else {
      console.log(error);
      throw new Error("Http Error " + error?.response?.data?.message);
    }

  }

  downloadFile(data, filename) {
    const binaryData = [data];
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
