import React, { useState, useEffect, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import UserService from '../../services/userService';
import DocumentService from '../../services/documentService';
import Helper from '../../utils/helper';
import { useAuthState } from "../../context/context";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import './dt_cv_etudiants.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const DT_CV_Etudiant = () => {


    const { t } = useTranslation();
    const [etudiants, setEtudiants] = useState([]);
    const [etudiantDialog, setEtudiantDialog] = useState(false);
    const [validatePortfolioEtudiantDialog, setValidatePortfolioEtudiantDialog] = useState(false);
    const [deletePortfolioEtudiantDialog, setDeletePortfolioEtudiantDialog] = useState(false);
    const [validateCvPdfEtudiantDialog, setValidateCvPdfEtudiantDialog] = useState(false);
    const [deleteCvPdfEtudiantDialog, setDeleteCvPdfEtudiantDialog] = useState(false);
    const [etudiant, setEtudiant] = useState(null);
    const [selectedEtudiants, setSelectedEtudiants] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();

    const userService = new UserService();
    const documentService = new DocumentService();

    const currentUser = useAuthState();
    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    });
    const isMounted = useRef(false);




    useEffect(() => {
        isMounted.current = true;
        loadLazyData();
    }, [lazyParams]);


    const loadLazyData = () => {
        setLoading(true);

        userService.getEtudiantsDocumentAValider(currentUser, { lazyEvent: JSON.stringify(lazyParams) }).then(_etudiants => {

            setEtudiants(_etudiants);

            setLoading(false);

            setEtudiantDialog(false);
        });

    }

    const saveEtudiant = () => {

    }
    
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < etudiants.length; i++) {
            if (etudiants[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };

    const validateCvPdfEtudiant = () => {
        documentService.validerDocument(etudiant.fichierCvPdf.id, etudiant.id).then(_document => {

            const _etudiant = { ...etudiant };
            _etudiant.fichierCvPdf = null;
            setEtudiant(_etudiant);
            const index = findIndexById(_etudiant.id);
            const _etudiants = [...etudiants];
            _etudiants[index] = _etudiant;
            setEtudiants(_etudiants);

            hideValidateCvPdfEtudiantDialog();
            if (((_etudiant.fichierCvPdf === null || _etudiant.fichierCvPdf?.dateValidation !== null) && (_etudiant.fichierPortfolio === null || _etudiant.fichierPortfolio?.dateValidation !== null))) {
                setEtudiantDialog(false);
                const _etudiants = [...etudiants].filter(val => val.id !== _etudiant.id);
                setEtudiants(_etudiants);
            }
            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.validated_file'), life: 3000 });
        });
    }

    const validatePortfolioEtudiant = () => {
        documentService.validerDocument(etudiant.fichierPortfolio.id, etudiant.id).then(_document => {

            const _etudiant = { ...etudiant };
            _etudiant.fichierPortfolio = null;
            setEtudiant(_etudiant);
            const index = findIndexById(_etudiant.id);
            const _etudiants = [...etudiants];
            _etudiants[index] = _etudiant;
            setEtudiants(_etudiants);

            hideValidatePortfolioEtudiantDialog();
            if (((_etudiant.fichierCvPdf === null || _etudiant.fichierCvPdf?.dateValidation !== null) && (_etudiant.fichierPortfolio === null || _etudiant.fichierPortfolio?.dateValidation !== null))) {
                setEtudiantDialog(false);
                const _etudiants = [...etudiants].filter(val => val.id !== _etudiant.id);
                setEtudiants(_etudiants);
            }
            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.validated_file'), life: 3000 });
        });
    }

    const deleteCvPdfEtudiant = () => {
        documentService.supprimerDocumentValidationEtudiant(etudiant.fichierCvPdf.id, etudiant.id).then(_document => {

            const _etudiant = { ...etudiant };
            _etudiant.fichierCvPdf = null;
            setEtudiant(_etudiant);
            const index = findIndexById(_etudiant.id);
            const _etudiants = [...etudiants];
            _etudiants[index] = _etudiant;
            setEtudiants(_etudiants);

            hideDeleteCvPdfEtudiantDialog();
            if (((_etudiant.fichierCvPdf === null || _etudiant.fichierCvPdf?.dateValidation !== null) && (_etudiant.fichierPortfolio === null || _etudiant.fichierPortfolio?.dateValidation !== null))) {
                setEtudiantDialog(false);
                const _etudiants = [...etudiants].filter(val => val.id !== _etudiant.id);
                setEtudiants(_etudiants);
            }
            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.deleted_file'), life: 3000 });
        });

    }

    const deletePortfolioEtudiant = () => {
        documentService.supprimerDocumentValidationEtudiant(etudiant.fichierPortfolio.id, etudiant.id).then(_document => {

            const _etudiant = { ...etudiant };
            _etudiant.fichierPortfolio = null;
            setEtudiant(_etudiant);
            const index = findIndexById(_etudiant.id);
            const _etudiants = [...etudiants];
            _etudiants[index] = _etudiant;
            setEtudiants(_etudiants);

            hideDeletePortfolioEtudiantDialog();
            if (((_etudiant.fichierCvPdf === null || _etudiant.fichierCvPdf?.dateValidation !== null) && (_etudiant.fichierPortfolio === null || _etudiant.fichierPortfolio?.dateValidation !== null))) {
                setEtudiantDialog(false);
                const _etudiants = [...etudiants].filter(val => val.id !== _etudiant.id);
                setEtudiants(_etudiants);
            }
            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.deleted_file'), life: 3000 });
        });
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEtudiantDialog(false);
    }

    const hideValidateCvPdfEtudiantDialog = () => {
        setValidateCvPdfEtudiantDialog(false);
    }

    const hideDeleteCvPdfEtudiantDialog = () => {
        setDeleteCvPdfEtudiantDialog(false);
    }

    const hideValidatePortfolioEtudiantDialog = () => {
        setValidatePortfolioEtudiantDialog(false);
    }

    const hideDeletePortfolioEtudiantDialog = () => {
        setDeletePortfolioEtudiantDialog(false);
    }

    const validateEtudiant = (etudiant) => {
        setEtudiant({ ...etudiant });
        setEtudiantDialog(true);
    }

    const lienProfilEtudiant = (etudiant) => {
        history.push({pathname:'/profile_student',state:{adminImpersonatingEtudiantId:etudiant.id}})
    }


    const onDownloadCvPdf = () => {
        documentService.telechargerDocument(etudiant.fichierCvPdf.id).then(blob => {

            Helper.openWindowBlobFile(blob, etudiant.fichierCvPdf.name);
        });
    }

    const onConfirmDeleteCvPdf = () => {
        setDeleteCvPdfEtudiantDialog(true);
    }

    const onConfirmValidateCvPdf = () => {
        setValidateCvPdfEtudiantDialog(true);
    }


    const onDownloadPortfolio = () => {
        documentService.telechargerDocument(etudiant.fichierPortfolio.id).then(blob => {

            Helper.openWindowBlobFile(blob, etudiant.fichierPortfolio.name);
        });
    }

    const onConfirmDeletePortfolio = () => {
        setDeletePortfolioEtudiantDialog(true);
    }

    const onConfirmValidatePortfolio = () => {
        setValidatePortfolioEtudiantDialog(true);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => validateEtudiant(rowData)} />
                <Button icon="pi pi-user-edit" className="p-button-rounded p-button-help p-mr-2" onClick={() => lienProfilEtudiant(rowData)} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="title"><Trans i18nKey="student.cv_students_management.full" /></h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
            </span>
        </div>
    );
    const etudiantDialogFooter = (
        <React.Fragment>

        </React.Fragment>
    );
    const validateCvPdfEtudiantDialogFooter = (
        <React.Fragment>
            <Button label={t('general.no')} icon="pi pi-times" className="p-button-text" onClick={hideValidateCvPdfEtudiantDialog} />
            <Button label={t('general.yes')} icon="pi pi-check" className="p-button-text" onClick={validateCvPdfEtudiant} />
        </React.Fragment>
    );
    const deleteCvPdfEtudiantDialogFooter = (
        <React.Fragment>
            <Button label={t('general.no')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteCvPdfEtudiantDialog} />
            <Button label={t('general.yes')} icon="pi pi-check" className="p-button-text" onClick={deleteCvPdfEtudiant} />
        </React.Fragment>
    );
    const validatePortfolioEtudiantDialogFooter = (
        <React.Fragment>
            <Button label={t('general.no')} icon="pi pi-times" className="p-button-text" onClick={hideValidatePortfolioEtudiantDialog} />
            <Button label={t('general.yes')} icon="pi pi-check" className="p-button-text" onClick={validatePortfolioEtudiant} />
        </React.Fragment>
    );
    const deletePortfolioEtudiantDialogFooter = (
        <React.Fragment>
            <Button label={t('general.no')} icon="pi pi-times" className="p-button-text" onClick={hideDeletePortfolioEtudiantDialog} />
            <Button label={t('general.yes')} icon="pi pi-check" className="p-button-text" onClick={deletePortfolioEtudiant} />
        </React.Fragment>
    );

    return (
        <div className="datatable-cv_etudiants">
            <Toast ref={toast} />

            <div className="card">

                <DataTable ref={dt} emptyMessage={t('student.no_student_to_show')} value={etudiants} onSelectionChange={(e) => setSelectedEtudiants(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={t('student.footer_count_pagin')}
                    globalFilter={globalFilter}
                    header={header}
                    loading={loading}
                >
                    <Column field="nom" header={t('general.lastname')} sortable></Column>
                    <Column field="prenom" header={t('general.firstname')} sortable></Column>
                    <Column field="email" header={t('general.email')} sortable></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </div>

            <Dialog visible={etudiantDialog} style={{ width: '50rem' }} header={t('student.details_student')} modal className="p-fluid" footer={etudiantDialogFooter} onHide={hideDialog}>
                <div className="p-fluid">
                    {etudiant?.fichierCvPdf?.dateValidation === null ? (
                        <div className="p-field">
                            <div className="p-field">
                                <b><label htmlFor="cvPdf"><Trans i18nKey="student.cv_pdf" /></label></b>
                            </div>
                            <div className="form-row">
                                {etudiant.fichierCvPdf?.name}
                                {etudiant.fichierCvPdf?.name.trim() ?
                                    (<div><Button icon="pi pi-eye" className="p-button-info p-mr-2" onClick={onDownloadCvPdf} />
                                        <Button icon="pi pi-trash" className="p-button-danger p-mr-2" onClick={onConfirmDeleteCvPdf} />
                                        <Button icon="pi pi-check" className="p-button-success p-mr-2" onClick={onConfirmValidateCvPdf} /></div>)
                                    : ("")}
                            </div>
                        </div>) : ("")}
                    {etudiant?.fichierPortfolio?.dateValidation === null ? (<div className="p-field">
                        <div className="p-field">
                            <b><label htmlFor="portfolio"><Trans i18nKey="student.portfolio" /></label></b>
                        </div>
                        <div className="form-row" >
                            {etudiant.fichierPortfolio?.name}
                            {etudiant.fichierPortfolio?.name.trim() ?
                                (<div><Button icon="pi pi-eye" className="p-button-info p-mr-2" onClick={e => onDownloadPortfolio(e)} />
                                    <Button icon="pi pi-trash" className="p-button-danger p-mr-2" onClick={onConfirmDeletePortfolio} />
                                    <Button icon="pi pi-check" className="p-button-success p-mr-2" onClick={onConfirmValidatePortfolio} /></div>)
                                : ("")}
                        </div>
                    </div>) : ("")}

                </div>
            </Dialog>

            <Dialog visible={validateCvPdfEtudiantDialog} style={{ width: '50rem' }} header={t('general.confirm')} modal footer={validateCvPdfEtudiantDialogFooter} onHide={hideValidateCvPdfEtudiantDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {etudiant && <span><Trans i18nKey="general.are_you_sure_validate_file" /></span>}
                </div>
            </Dialog>

            <Dialog visible={deleteCvPdfEtudiantDialog} style={{ width: '50rem' }} header={t('general.confirm')} modal footer={deleteCvPdfEtudiantDialogFooter} onHide={hideDeleteCvPdfEtudiantDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {etudiant && <span><Trans i18nKey="general.are_you_sure_delete_file" /></span>}
                </div>
            </Dialog>

            <Dialog visible={validatePortfolioEtudiantDialog} style={{ width: '50rem' }} header={t('general.confirm')} modal footer={validatePortfolioEtudiantDialogFooter} onHide={hideValidatePortfolioEtudiantDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {etudiant && <span><Trans i18nKey="general.are_you_sure_validate_file" /></span>}
                </div>
            </Dialog>

            <Dialog visible={deletePortfolioEtudiantDialog} style={{ width: '50rem' }} header={t('general.confirm')} modal footer={deletePortfolioEtudiantDialogFooter} onHide={hideDeletePortfolioEtudiantDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {etudiant && <span><Trans i18nKey="general.are_you_sure_delete_file" /></span>}
                </div>
            </Dialog>
        </div>
    );
}