import BaseService from "./baseService";

export default class AdministrationService extends BaseService {

  refreshCompteurs() {
    return this.axiosApiInstance.get(window.API_URL + '/api/Administration/RefreshCompteurs')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  refreshDepartementJobOfferLink() {
    return this.axiosApiInstance.get(window.API_URL + '/api/Administration/RefreshDepartementJobOfferLink')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getEtudiantCountWithNotEmailValidate() {
    return this.axiosApiInstance.get(window.API_URL + '/api/Administration/GetEtudiantCountWithNotEmailValidate')
        .then(res => res.data)
        .catch(err => super.handleHttpError(err));
  }

  sendEmailToUnvalidatedStudents() {
    return this.axiosApiInstance.get(window.API_URL + '/api/Administration/SendEmailToUnvalidatedStudents')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

}