import { enumRoles } from '../enums/enumRoles';
import jwt_decode from "jwt-decode";

export default class RoleHelper {
    static isSuperAdmin = (currentUser) => {
        if (currentUser.roles.indexOf(enumRoles.SUPERADMINISTRATEUR) > -1) {
            return true;
        }
        else {
            return false;
        }
    }

    static isAdmin = (currentUser) => {
        if (currentUser.roles.indexOf(enumRoles.SUPERADMINISTRATEUR) != -1 || currentUser.roles.indexOf(enumRoles.ADMINISTRATEUR) != -1) {
            return true;
        }
        else {
            return false;
        }
    }

    static isAdminEcole = (currentUser) => {
        if (currentUser.roles.indexOf(enumRoles.SUPERADMINISTRATEUR) == -1 && currentUser.roles.indexOf(enumRoles.ADMINISTRATEURECOLE) != -1) {
            return true;
        }
        else {
            return false;
        }

    }

    static isAdminComposante = (currentUser) => {
        if (currentUser.roles.indexOf(enumRoles.SUPERADMINISTRATEUR) == -1 && currentUser.roles.indexOf(enumRoles.ADMINISTRATEURCOMPOSANTE) != -1) {
            return true;
        }
        else {
            return false;
        }
    }

    static isEtudiant = (currentUser) => {
        if (currentUser?.roles?.indexOf(enumRoles.SUPERADMINISTRATEUR) == -1 && currentUser?.roles?.indexOf(enumRoles.ETUDIANT) != -1) {
            return true;
        }
        else {
            return false;
        }
    }

    static isEntreprise = (currentUser) => {
        if (currentUser.roles.indexOf(enumRoles.SUPERADMINISTRATEUR) == -1 && currentUser.roles.indexOf(enumRoles.ENTREPRISE) != -1) {
            return true;
        }
        else {
            return false;
        }
    }

    static isNotConnected = (currentUser) => {
        if (currentUser.isAuthenticated) {
            return false;
        }
        else {
            return true;
        }
    }

    static isAdminImpersonatingEntreprise = (currentUser, pathname) => {
        if (this.isAdmin(currentUser)
            && (
                pathname == "/offers"
                || pathname == "/projects"
                || pathname == "/seeked_profiles"
                || pathname == "/formationsFavorites"
                || pathname == "/profile_company"
                || pathname == "/candidates"
                || pathname == "/candidate_file"
                || pathname == "/edition_annonce"
                || pathname == "/formationsFavorites"
                || pathname == "/rechercheAffinee"
                || pathname == "/vueOffreEmploi"
                || pathname.match(/\/course\/\d+/) 
                || pathname.match(/\/Candidat\/[A-Z0-9]{8}\/.*/)
            )
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    static isAdminImpersonatingEtudiant = (currentUser, pathname) => {
        if (this.isAdmin(currentUser)
            && (
                pathname == "/offresCompatibles"
                || pathname == "/candidatures"
                || pathname == "/formationsFavorites"
                || pathname == "/profile_student"
            )
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    static redirectWithImpersonationCheck = (history,pathname,state) => {
        let _state = {}
        if(state){
            _state = state
        }
        if(history.location?.state?.adminImpersonatingRecruteurId){
            _state.adminImpersonatingRecruteurId=history.location?.state?.adminImpersonatingRecruteurId
        }
        history.push({pathname:pathname,state:_state})
    }

    static windowOpenadminImpersonatingRecruteurIdStorage = (pathname, adminImpersonatingRecruteurId) => {

        const state = {};
        if(adminImpersonatingRecruteurId) {
            state.adminImpersonatingRecruteurId = adminImpersonatingRecruteurId;
        }

        if (JSON.stringify(state) !== '{}') {
            sessionStorage.removeItem('myState');
            sessionStorage.setItem('myState', JSON.stringify(state));
        }
        let win = window.open(pathname, '_blank');
        win.focus();
    }

    static decodeRoles = (token) => {
        let roles = [];
        if (token) {
            Object.keys(jwt_decode(token)).forEach(key => {
                if (key.match(/.*role$/)) {
                    let result = jwt_decode(token)[key];
                    if (typeof result === 'string' || result instanceof String) {
                        roles.push(result);
                    }
                    else {
                        roles = result;
                    }
                }
            });
        }
        return roles;
    }
}