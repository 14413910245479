import React, { useRef, useContext } from 'react';
import { useTranslation } from "react-i18next"
import "./nbResultsAndOrderBar.css";
import recrutementRechercheContext from '../recrutement-RechercheContext';
import { Button } from 'primereact/button';
import { useAuthState } from '../../../context/context';
import RoleHelper from '../../../utils/roleHelper';
import { Toast } from 'primereact/toast';
import { useHistory } from 'react-router-dom';
import { Tag } from 'primereact/tag';

export const NbResultsAndOrder = (props) => {

    const { t } = useTranslation();
    const toast = useRef(null);
    const contextRecherche = useContext(recrutementRechercheContext);
    const currentUser = useAuthState();
    const history = useHistory();

    const goToFiltres = () => {
        history.push({
            pathname: '/recrutement-recherche',
            state: {
                idRecrutement: history.location.state.idRecrutement,
                filters: history.location.state.filters
            }
        });
    }

    const renderCountCandidats = () => {
        return (contextRecherche.totalCandidatsRecords > 1 ? (contextRecherche.totalCandidatsRecords + ' ' + t('company.candidate.plural').toUpperCase()) : (contextRecherche.totalCandidatsRecords + ' ' + t('company.candidate.singular').toUpperCase()))
    }

    return (
        <>
            <Toast ref={toast} />
            <div className={props.className}>
                <div className="card nrao-main">
                    <div className="nrao-submain">
                        <div className='nrao-left-buttons'>
                            <Tag className="nrao-tag p-mr-1" >{renderCountCandidats()}</Tag>
                            {RoleHelper.isEntreprise(currentUser) &&
                                <div className='p-ml-1'>
                                    <Button
                                        label={t('search.change_filters')}
                                        className="p-button-rounded dip-btn-red-outline"
                                        onClick={goToFiltres} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};