import { Dropdown } from "primereact/dropdown"
import { ProgressSpinner } from "primereact/progressspinner"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { FormationHeaderCard } from "../../components/card/formationHeaderCard"
import { PieChartCard } from "../../components/chart/pieChartCard"
import FormationService from "../../services/formationService"
import StatistiquesService from "../../services/statistiquesService"
import "./statistiquesFormation.css"

export const StatistiquesFormation = (props) => {

    const { t } = useTranslation();
    const [formation, setFormation] = useState(null)
    const [loading, setLoading] = useState(true)
    const [fetching, setFetching] = useState(true)
    const [values, setValues] = useState([])
    const [selectedValue, setSelectedValue] = useState("last_30_days")
    const [dataAccueil, setDataAccueil] = useState(null)
    const [dataResultat, setDataResultat] = useState(null)
    const [dataConsultation, setDataConsultation] = useState(null)
    const [totalAccueil, setTotalAccueil] = useState(0)
    const [totalConsultation, setTotalConsultation] = useState(0)
    const [totalResultat, setTotalResultat] = useState(0)
    const formationService = new FormationService();
    const statistiquesService = new StatistiquesService();
    const history = useHistory();


    const id = props.match.params.formationId;

    useEffect(() => {
        setLoading(true);
        formationService.getFormationExtraLight(id)
            .then(_formation => {
                setFormation(_formation);
                buildValues(_formation)
            })
            .catch((err) => {
                history.push("/");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [])

    useEffect(() => {
        if (formation?.id) {
            setFetching(true);
            if (selectedValue == "last_30_days") {
                statistiquesService.GetFormationByLast30Jours(formation.id)
                    .then(data => {
                        formatingStats(data);
                    })
                    .catch((err) => {
                        history.push("/");
                    })
                    .finally(() => {
                        setFetching(false);
                    });
            }
            else {
                statistiquesService.GetStatFormationByMois(formation.id, selectedValue)
                    .then(data => {
                        formatingStats(data);
                    })
                    .catch((err) => {
                        history.push("/");
                    })
                    .finally(() => {
                        setFetching(false);
                    });
            }
        }
    }, [selectedValue, formation])

    const buildValues = (formation) => {
        let date = new Date();
        let endDate = new Date(formation.dateCreation);
        let maxEndDate = new Date("July 1 2022");
        let resultList = [];
        var monthNameList = [t('months.january'), t('months.february'), t('months.march'), t('months.april'), t('months.may'), t('months.june'), t('months.july'), t('months.august'), t('months.september'), t('months.october'), t('months.november'), t('months.december')];
        while (date >= endDate && date >= maxEndDate) {
            var stringDate = monthNameList[date.getMonth()] + " " + date.getFullYear();
            let _date = new Date(date);
            resultList.push({ label: stringDate, value: _date });
            date.setMonth(date.getMonth() - 1);
        }
        resultList.unshift({ label: t('statistics.last_30_days'), value: "last_30_days" })
        setValues(resultList);
    }

    const formatingStats = (data) => {
        // let vuesInconnuAccueil = 0
        // let vuesEtudiantAccueil = 0
        // let vuesEntrepriseAccueil = 0
        let vuesInconnuResultat = 0
        let vuesEtudiantResultat = 0
        let vuesEntrepriseResultat = 0
        let vuesInconnuConsultation = 0
        let vuesEtudiantConsultation = 0
        let vuesEntrepriseConsultation = 0
        data.forEach(e => {
            switch (e.consultationType) {
                // case 1:
                //     vuesInconnuAccueil = vuesInconnuAccueil + e.vuesInconnu
                //     vuesEtudiantAccueil = vuesEtudiantAccueil + e.vuesEtudiant
                //     vuesEntrepriseAccueil = vuesEntrepriseAccueil + e.vuesEntreprise
                //     break;
                case 2:
                    vuesInconnuResultat = vuesInconnuResultat + e.vuesInconnu
                    vuesEtudiantResultat = vuesEtudiantResultat + e.vuesEtudiant
                    vuesEntrepriseResultat = vuesEntrepriseResultat + e.vuesEntreprise
                    break;
                case 3:
                    vuesInconnuConsultation = vuesInconnuConsultation + e.vuesInconnu
                    vuesEtudiantConsultation = vuesEtudiantConsultation + e.vuesEtudiant
                    vuesEntrepriseConsultation = vuesEntrepriseConsultation + e.vuesEntreprise
                    break;
                default:
                    break;
            }
        });
        // setTotalAccueil(vuesInconnuAccueil + vuesEtudiantAccueil + vuesEntrepriseAccueil);
        setTotalResultat(vuesInconnuResultat + vuesEtudiantResultat + vuesEntrepriseResultat);
        setTotalConsultation(vuesInconnuConsultation + vuesEtudiantConsultation + vuesEntrepriseConsultation);
        // setDataAccueil({
        //     label:"1",
        //     labels: [t('statistics.students', { number: vuesEtudiantAccueil }), t('statistics.companies', { number: vuesEntrepriseAccueil }), t('statistics.user_not_connected', { number: vuesInconnuAccueil })],
        //     datasets: [
        //         {
        //             data: [vuesEtudiantAccueil, vuesEntrepriseAccueil, vuesInconnuAccueil],
        //             backgroundColor: [
        //                 "#ed6a5a",
        //                 "#04486c",
        //                 "#6c757d"
        //             ]
        //         }
        //     ]
        // })
        setDataResultat({
            label:"2",
            labels: [t('statistics.students', { number: vuesEtudiantResultat }), t('statistics.companies', { number: vuesEntrepriseResultat }), t('statistics.user_not_connected', { number: vuesInconnuResultat })],
            datasets: [
                {
                    data: [vuesEtudiantResultat, vuesEntrepriseResultat, vuesInconnuResultat],
                    backgroundColor: [
                        "#ed6a5a",
                        "#04486c",
                        "#6c757d"
                    ]
                }
            ]
        })
        setDataConsultation({
            label:"3",
            labels: [t('statistics.students', { number: vuesEtudiantConsultation }), t('statistics.companies', { number: vuesEntrepriseConsultation }), t('statistics.user_not_connected', { number: vuesInconnuConsultation })],
            datasets: [
                {
                    data: [vuesEtudiantConsultation, vuesEntrepriseConsultation, vuesInconnuConsultation],
                    backgroundColor: [
                        "#ed6a5a",
                        "#04486c",
                        "#6c757d"
                    ]
                }
            ]
        })
    }

    return (
        loading ? (
            <div className="dip-flex dip-ta-center">
                <ProgressSpinner />
            </div>
        ) : (
            <div className={props.className}>
                <FormationHeaderCard
                    formation={formation}
                />
                <div className="p-grid">
                    <div className="p-col-12 p-sm-12 p-lg-6">
                        <h1><Trans i18nKey="statistics.statistics" /></h1>
                    </div>
                    <div className="p-col-12 p-sm-12 p-lg-6 sf-time-dropdown">
                        <span className="p-mr-2"><Trans i18nKey="statistics.period" /></span>
                        <Dropdown
                            value={selectedValue}
                            options={values}
                            onChange={(e) => setSelectedValue(e.value)}
                        />
                    </div>
                    {/* <div className="p-col-12 p-sm-12 p-lg-6">
                        <PieChartCard
                            id="1"
                            title={t('statistics.home_title', { number: totalAccueil })}
                            loading={fetching}
                            data={dataAccueil}
                        />
                    </div> */}
                    <div className="p-col-12 p-sm-12 p-lg-6">
                        <PieChartCard
                            id="2"
                            title={t('statistics.consultation_title', { number: totalConsultation })}
                            loading={fetching}
                            data={dataConsultation}
                        />
                    </div>
                    <div className="p-col-12 p-sm-12 p-lg-6">
                        <PieChartCard
                            id="3"
                            title={t('statistics.results_title', { number: totalResultat })}
                            loading={fetching}
                            data={dataResultat}
                        />
                    </div>
                </div>
                <br />
                <p><Trans i18nKey="statistics.view_explanation"/></p>

            </div >
        )

    )
}