import React from 'react';
import { MenuAdministration } from '../../components/menu/menuAdministration';
import { useAuthState } from "../../context/context";
import { enumRoles } from '../../enums/enumRoles';
import { OffresCompatibles } from '../offresCompatibles';
import { DashboardEcoles } from './dashboardEcoles';


export const Dashboard = () => {

    const currentUser = useAuthState();
    const isAdmin = currentUser?.roles?.indexOf(enumRoles.ADMINISTRATEUR) > -1;
    const isAdminEcole = currentUser?.roles?.indexOf(enumRoles.ADMINISTRATEURECOLE) > -1 && !isAdmin;
    const isAdminComposante = currentUser?.roles?.indexOf(enumRoles.ADMINISTRATEURCOMPOSANTE) > -1 && !isAdmin;
    const isEtudiant = currentUser?.roles?.indexOf(enumRoles.ETUDIANT) > -1 && !isAdmin;

    return (
        <div>
            {isAdmin &&
                <MenuAdministration />
            }
            {(isAdminEcole||isAdminComposante) &&
                <DashboardEcoles />
            }
        </div>
    )
}