import React, { useState, useEffect, useRef } from 'react';
import FormationService from '../../services/formationService';
import { useAuthState } from "../../context/context";
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { enumRoles } from '../../enums/enumRoles';
import FormationHelper from "../../utils/formationHelper"
import DocumentService from '../../services/documentService';
import { SpeedDial } from 'primereact/speeddial';
import "./dt_formation_actions.css"
import RoleHelper from '../../utils/roleHelper';
import { Tooltip } from 'primereact/tooltip';

export const DT_Formation_actions = (props) => {

    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
    const [speedDialClassName, setSpeedDialClassName] = useState(null)
    const [speedDialItems, setSpeedDialItems] = useState([])
    const { t } = useTranslation();
    const toast = useRef(null);
    const currentUser = useAuthState();
    const formationService = new FormationService();
    const [isInitialized, setIsInitialized] = useState(false)

    useEffect(() => {
        buildActionItems();
    }, [props.formation])


    const buildActionItems = () => {
        let _items = []
        if (RoleHelper.isSuperAdmin(currentUser) || RoleHelper.isAdmin(currentUser)) {
            setSpeedDialClassName("admin-order-speeddial")
        }
        else {
            setSpeedDialClassName("default-order-speeddial")
        }
        if (RoleHelper.isSuperAdmin(currentUser) || RoleHelper.isAdmin(currentUser)) {
            _items.push({
                icon: 'pi pi-trash',
                command: () => setConfirmDeleteVisible(true),
            })
        }
        let _statObj = {
            label:"fromage",
            icon: 'pi pi-chart-bar',
            command: () => checkStatistiques(),
        }
        let hasRightAbonnement = props.formation.composanteEcole.some(e => e.abonnementType == 2 || e.abonnementType == 3);
        if ((RoleHelper.isAdminComposante(currentUser) || RoleHelper.isAdminEcole(currentUser)) && !hasRightAbonnement) {
            _statObj.disabled = true
            _statObj.template = (item, options) => {
                return (
                    <span className="dtfa-disabled-button" data-pr-tooltip={t('statistics.disabled_tooltip')}>
                        {options.element}
                    </span>
                );
            }
        }
        _items.push(_statObj);
        if (RoleHelper.isSuperAdmin(currentUser) || RoleHelper.isAdmin(currentUser)) {
            _items.push({
                icon: "pi pi-copy",
                command: () => duplicateFormation()
            })
        }
        _items.push({
            label:"fromage",
            icon: 'pi pi-pencil',
            command: () => editFormation()
        })
        setSpeedDialItems(_items)
        setIsInitialized(true)
    }

    const checkFormation = () => {
        var win = window.open("course/" + `${props.formation.id}` + FormationHelper.buildFormationPath(props.formation.composante, FormationHelper.nomCompletFormaterUrlFlat(props.formation)))
        win.focus();
    }

    const checkStatistiques = () => {
        var win = window.open("/Statistiques/Formation/" + `${props.formation.id}` + FormationHelper.buildFormationPath(props.formation.composante, FormationHelper.nomCompletFormaterUrlFlat(props.formation)))
        win.focus();
    }

    const duplicateFormation = () => {
        props.openDuplicateFormationDialog(props.formation.id);
    }
    const editFormation = () => {
        props.openEditFormationDialog(props.formation.id);

    }
    const deleteFormation = () => {
        toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 })

        formationService.delete(props.formation)
            .then(() => {
                toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('general.entity_suppression_success'), life: 3000 });
                props.onFormationDelete();
            }
            )
            .catch((err) =>
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.entity_suppression_failed'), life: 3000 })
            )
    }

    return (
        <React.Fragment>
            <Toast ref={toast} />
            {isInitialized && <Tooltip target=".dtfa-disabled-button" position="bottom"/>}
            <div className='dip-flex dip-jc-end'>
                <div>
                    <Button icon="pi pi-eye " className="p-button-rounded p-button-info p-mr-2" onClick={() => checkFormation()} />
                </div>
                
                {/* {((currentUser.roles.indexOf(enumRoles.SUPERADMINISTRATEUR) > -1) || (currentUser.roles.indexOf(enumRoles.ADMINISTRATEUR) > -1)) && props.openDuplicateFormationDialog && <Button icon="pi pi-copy" className="p-button-rounded p-button-help p-mr-2" onClick={() => duplicateFormation()} />}
                {((currentUser.roles.indexOf(enumRoles.SUPERADMINISTRATEUR) > -1) || (currentUser.roles.indexOf(enumRoles.ADMINISTRATEUR) > -1)) && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-mr-2" onClick={() => setConfirmDeleteVisible(true)} />} */}
                <SpeedDial className={`${speedDialClassName} formation-actions-speeddial`} model={speedDialItems} direction="left" showIcon="pi pi-ellipsis-v" hideIcon="pi pi-times" />
            </div>

            <ConfirmDialog visible={confirmDeleteVisible} onHide={() => setConfirmDeleteVisible(false)} message={t('formation.are_you_sure_delete_formation', { nomComplet: props.formation.nomComplet })}
                header={t('general.confirm')} icon="pi pi-exclamation-triangle" accept={deleteFormation} acceptLabel={t('general.yes')} rejectLabel={t('general.no')} />
        </React.Fragment>
    );
}