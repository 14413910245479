import { Button } from "primereact/button"
import { Fragment, useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"
import { enumStatutFormation } from "../../../enums/enumStatutFormation"
import FormationService from "../../../services/formationService"
import { FormCourse } from "../../form/formProfileComponents/student/studiesComponents/formCourse"
import { SelectButton } from 'primereact/selectbutton';

export const RobFormation = (props) => {
    const { t } = useTranslation()

    const formationService = new FormationService()

    const [loading, setLoading] = useState(true)
    const [niveauDiplomes, setNiveauDiplomes] = useState([])

    useEffect(() => {
        formationService.getNiveauxEtude()
            .then((data) => {
                setNiveauDiplomes(data);
            })
            .catch(

        )
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const onDiplomaChange = (value) => {
        let _etudiant = { ...props.etudiant };
        _etudiant.niveauEtude = value;
        props.setEtudiant(_etudiant);
    }


    return (
        <div className={`${props.submitting && 'fsp-disabled'}`}>
            <h3 className="dip-mt-0"><Trans i18nKey="onboarding.what_diploma" /></h3>
            {!loading && <div>
                <SelectButton className="dip-slct-btn-spaced" options={niveauDiplomes} value={props.etudiant?.niveauEtude} onChange={(e) => onDiplomaChange(e.value)} />
            </div>}
            <br />
            <h3 className="dip-mt-0"><Trans i18nKey="onboarding.what_course" /></h3>
            <FormCourse
                loading={props.submitting}
                formationToAdd={props.formationToAdd}
                setFormationToAdd={props.setFormationToAdd}
                isFormationSelected={props.isFormationSelected}
                setIsFormationSelected={props.setIsFormationSelected}
            />
        </div>
    )
}