const generateDescription = (descriptionChecked) => {
    return descriptionChecked.map((isChecked, index) => ({
      descriptionChecked: isChecked,
      indexDescription: index
    }));
};

const DescriptionFreemium = generateDescription([true, true, true, true, true, false, false, false]);
const DescriptionCasual = generateDescription([true, true, true, true, true, true, false, false]);
const DescriptionRegular = generateDescription([true, true, true, true, true, true, true, false]);
const DescriptionMajorAccount = generateDescription([true, true, true, true, true, true, true, true]);

export { DescriptionFreemium, DescriptionCasual, DescriptionRegular, DescriptionMajorAccount };