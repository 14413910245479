import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog"
import { InputSwitch } from "primereact/inputswitch"
import { useContext, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import parse from 'html-react-parser';
import "./entrepriseOffreEmploiThumbnail.css"
import OffreEmploiService from "../../services/offreEmploiService"
import { CandidatStatutSelectButton } from "../selectButton/candidatStatutSelectButton"
import { OverlayPanel } from "primereact/overlaypanel"
import { ProjetOverlayPanel } from "../overlayPanel/projetOverlayPanel"
import RoleHelper from "../../utils/roleHelper"
import { Tooltip } from "primereact/tooltip"

export const EntrepriseOffreEmploiThumbnail = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [statut, setStatut] = useState(history.location?.state?.statut)
    const [loading, setLoading] = useState(false)
    const offreEmploiService = new OffreEmploiService();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId

    const onViewClick = () => {
        const pathname = '/vueOffreEmploi?offreEmploiId=' + props.offreEmploi.id;
        RoleHelper.windowOpenadminImpersonatingRecruteurIdStorage(pathname, adminImpersonatingRecruteurId);
    }

    const onDuplicateClick = () => {
        history.push({ pathname: '/edition_annonce', state: { id: props.offreEmploi.id, adminImpersonatingRecruteurId:adminImpersonatingRecruteurId, duplicate: true } })
    }

    const onEditClick = () => {
        history.push({ pathname: '/edition_annonce', state: { id: props.offreEmploi.id, adminImpersonatingRecruteurId:adminImpersonatingRecruteurId } })
    }

    const onDeleteClick = () => {
        confirmDialog({
            message: 'Toutes les informations renseignées liées à cette annonce seront perdues.',
            header: 'Vous souhaitez supprimer votre annonce ?',
            rejctClassName: "dip-btn-red-outline",
            acceptClassName: 'dip-btn-red-bg',
            accept: () => {
                setLoading(true)
                offreEmploiService.deleteOffreEmploi(props.offreEmploi.id)
                    .then(
                        (data) => {
                            let _lazyParams = { ...props.contextRecherche.lazyParams };
                            _lazyParams.first = 0;
                            _lazyParams.page = 1;
                            props.contextRecherche.setLazyParams(_lazyParams);
                            props.contextRecherche.rechercheApi(_lazyParams);
                        })
                    .finally(
                        () => {
                            setLoading(false)
                        })
            },
            reject: () => { },
            acceptLabel: "Supprimer",
            rejectLabel: "Annuler"
        });
    }

    const handleChangeDiffusion = (e) => {
        setLoading(true)
        offreEmploiService.toggleDiffusion(props.offreEmploi.id)
            .then(
                (data) => {
                    let _lazyParams = { ...props.contextRecherche.lazyParams };
                    _lazyParams.first = 0;
                    _lazyParams.page = 1;
                    props.contextRecherche.setLazyParams(_lazyParams);
                    props.contextRecherche.rechercheApi(_lazyParams);
                })
            .finally(
                () => {
                    setLoading(false)
                })
    }

    const handleClickOffer = () => {
        history.push({ pathname: '/candidates', state: { id: props.offreEmploi.id,adminImpersonatingRecruteurId:adminImpersonatingRecruteurId } })
    }

    const onStatutClick = (value) => {
        if (props.isInAList) {
            history.push({ pathname: '/candidates', state: { id: props.offreEmploi.id, statut: value,adminImpersonatingRecruteurId:adminImpersonatingRecruteurId } })
        }
        else {
            props.setStatutFilter(value);
        }
        setStatut(value);
    }


    return (
        <>
            <div className={`eoet-main-card p-mb-3 ${props.isInAList && "eoet-border-card"} ${loading && "fai-disabled"}`}>
                <div className="eoet-top-line dip-jc-between">
                    <div><i><Trans i18nKey="offers.published_on" values={{ date: props.offreEmploi.dateCreation }} /></i></div>
                    <div className="dip-flex-row dip-ai-center dip-jc-between">
                        <div className="dip-flex-row dip-ai-center">
                            <label className="field-checkbox sp-checkbox-label dip-fw-700">
                                <Trans i18nKey='offers.posted' />
                            </label>
                            <InputSwitch
                                className="p-mr-4"
                                disabled={loading}
                                checked={props.offreEmploi.statut == 1}
                                onChange={(e) => handleChangeDiffusion(e)}
                            />
                        </div>
                        <div className="dip-flex-row dip-ai-center">
                            <Tooltip target=".projet-overlay-panel" showDelay={500} position="left" className='dt-df-e-custom-tooltip' content={t("offers.tooltip_link_offer_project")} />
                            <ProjetOverlayPanel className="projet-overlay-panel p-mr-2" offreEmploiId={props.offreEmploi.id} />
                            <Tooltip target=".eoet-view-offer-click" showDelay={500} position="left" className='dt-df-e-custom-tooltip' content={t("offers.tooltip_view_offer")} />
                            <div className="p-mr-2 eoet-pointer eoet-view-offer-click" onClick={onViewClick}><i className="pi pi-eye" style={{ color: 'var(--terra-cotta)', fontSize: '1.5rem' }} /></div>
                            {/*  onDuplicateClick */}
                            <Tooltip target=".eoet-duplicate-offer-click" showDelay={500} position="left" className='dt-df-e-custom-tooltip' content={t("offers.tooltip_duplicate_offer")} />
                            <div className="p-mr-2 eoet-pointer eoet-duplicate-offer-click" onClick={onDuplicateClick}><i className="pi pi-copy" style={{ color: 'var(--terra-cotta)', fontSize: '1.3rem' }} /></div>
                            <Tooltip target=".eoet-edit-offer-click" showDelay={500} position="left" className='dt-df-e-custom-tooltip' content={t("offers.tooltip_edit_offer")} />
                            <div className="p-mr-2 eoet-pointer eoet-edit-offer-click" onClick={onEditClick}><i className="pi pi-pencil" style={{ color: 'var(--terra-cotta)', fontSize: '1.3rem' }} /></div>
                            <Tooltip target=".eoet-delete-offer-click" showDelay={500} position="left" className='dt-df-e-custom-tooltip' content={t("offers.tooltip_delete_offer")} />
                            <div className="eoet-pointer eoet-delete-offer-click" onClick={onDeleteClick}><i className="pi pi-trash" style={{ color: 'var(--terra-cotta)', fontSize: '1.3rem' }} /></div>
                        </div>

                    </div>
                </div>
                <div className="dip-pointer" onClick={() => handleClickOffer()}>
                    <div className="dip-color-tc p-mt-2 p-mb-3">
                        <h2 className="dip-m-0">{props.offreEmploi.intitule}</h2>
                    </div>
                    <h2 className="dip-m-0">
                        {props.offreEmploi.entreprise?.nom}
                    </h2>
                    <div>
                        {props.offreEmploi.lieuTravail?.libelle}
                    </div>
                    <div className="p-mt-2 p-mb-2">
                        <span className="eoet-tag">{props.offreEmploi.typeContratRecrutement}</span>
                    </div>
                    <div className="eoet-description-container">
                        {props.offreEmploi?.description && parse(props.offreEmploi?.description)}
                    </div>
                </div>
                <CandidatStatutSelectButton
                    entity={props.offreEmploi.offreEmploiEtudiants}
                    value={statut}
                    setValue={onStatutClick}
                />
            </div>
            <ConfirmDialog className="eoet-confirm-dialog" visible={deleteDialog} style={{ width: '50vw' }} onHide={() => setDeleteDialog(false)} rejectLabel="Annuler" acceptLabel="Supprimer">
            </ConfirmDialog>
        </>
    )
}