import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';

const ScrollToAnchor = (props) => {

  const [anchorTarget, setAnchorTarget] = useState(null);

  useEffect(() => {
    setAnchorTarget(document.getElementById(props.btnId));
  }, [props.btnId]);

  useEffect(() => {
    if (anchorTarget) {
      anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [props.redirect]);

  const handleClick = event => {
    event.preventDefault();
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    props.type == 'button' ? <a href={`#${props.btnId}`}
      onClick={handleClick}
      aria-label={`Scroll to ${props.btnId}`}
      style={{ textDecoration: 'none' }}
      >
      {props.onClick ? <Button label={props.label} icon={props.icon} type="button" onClick={props.onClick} className={props.className} /> : <Button label={props.label} type="button" className={props.className} />}
    </a>
      :
      <a href={`#${props.btnId}`}
        onClick={handleClick}
        className={props.className}
        ariaLabel={`Scroll to ${props.btnId}`}>
        {props.label}
      </a>
  );
};

export default ScrollToAnchor;