import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { enumHttpError } from "../../enums/enumHttpError";
import OffreEmploiService from "../../services/offreEmploiService";
import { JobThumbnail } from "../thumbnail/jobThumbnail";
import Helper from "../../utils/helper";
import { ThumbnailPaginator } from "../paginator/thumbnailPaginator";
import { SelectButton } from "primereact/selectbutton";
import { enumContratForFormation } from "../../enums/enumContratForFormation";
import "./jobThumbnailForCourseList.css";
import { OffreEmploiThumbnail } from "../thumbnail/offreEmploiThumbnail";

export const JobThumbnailForCourseList = (props) => {
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const toast = useRef(null);
    const [offresEmploi, setOffresEmploi] = useState([]);
    const [error, setError] = useState(null);

    const offreEmploiService = new OffreEmploiService();

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 8,
        page: 1,
        sortField: 'dateCreation'
    })

    const viewOptions = [
        { value: enumContratForFormation.ALTSTAGE, name: t('formation.alternance_stage') },
        { value: enumContratForFormation.CDDCDIINTERIM, name: t('formation.cdd_cdi_interim') }
    ];

    const [view, setView] = useState(viewOptions[0].value);

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        offreEmploiService.getListOffreEmploiByFormationId(lazyParams, props.formationId, view)
            .then(_pagedjobOffers => {
                _pagedjobOffers.items.forEach(offer => {
                    offer.dateCreation = Helper.dateTimeToLocaleDateTime(offer.dateCreation)
                });
                setTotalCount(_pagedjobOffers.totalCount)
                setOffresEmploi(_pagedjobOffers.items);
            })
            .catch((err) => {
                switch (err.message) {
                    case enumHttpError.CANCELED:
                        setError(enumHttpError.CANCELED)
                        break;
                    default:
                        setError('failed');
                        break;
                }
            })
            .finally(() => setLoading(false));
    }

    const changeTab = (viewName) => {
        setView(viewName);
        setOffresEmploi([]);
        setError(null)
        setLazyParams({
            ...lazyParams,
            page: 1,
            first: 0
        });
    }

    return (
        <div id={props.id}>
            <Toast ref={toast} />
            <div id="top">
                <div>
                    <h3 className="dip-fs-2 dip-fw-700 dip-ff-poppins"><Trans i18nKey="formation.compatible_offers" /></h3>
                    <div className="p-grid mt-5 p-mb-4">
                        <SelectButton className="p-col-12 select-matching" unselectable={false} style={{ textAlign: 'center' }} value={view} options={viewOptions} optionLabel="name" onChange={(e) => changeTab(e.value)} />
                    </div>
                    {loading ? (
                        <div className="dip-ta-center loader-height-for-thumbnail-list p-mt-6 p-mb-6">
                            <ProgressSpinner />
                        </div>
                    ) : (
                        <div className='p-grid'>
                            {offresEmploi.map((offreEmploi, indexOE) => (
                                <Fragment key={`${offreEmploi.id}~${indexOE}`}>
                                    <div className="p-col-12 p-sm-12 p-md-6 p-lg-3">
                                        <OffreEmploiThumbnail key={`${offreEmploi}~${indexOE}`} offreEmploi={offreEmploi} showLogo={false} />
                                    </div>
                                </Fragment>
                            ))}
                        </div>)}
                </div>

                {!loading && offresEmploi.length == 0 && error == 'failed' && <div className="dip-ta-center p-mt-6 p-mb-6"><Trans i18nKey="offers.server_connexion_error" /></div>}
                {!loading && offresEmploi.length == 0 && error != 'failed' && error != enumHttpError.CANCELED && <div className="dip-ta-center p-mt-6 p-mb-6"><Trans i18nKey="offers.no_offers" /></div>}

                <ThumbnailPaginator lazyParams={lazyParams} setLazyParams={setLazyParams} totalRecords={totalCount} />
            </div>

        </div>
    )
}