import React, { useState, useEffect, useRef, Fragment } from 'react';
import { classNames } from 'primereact/utils';
import { Divider } from 'primereact/divider';
import EcoleService from '../../../services/ecoleService';
import AbonnementService from '../../../services/abonnementService';
import { useAuthState } from "../../../context/context";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Trans, useTranslation } from 'react-i18next';
import { CommuneSelector } from '../../../components/location-selector/communeSelector'
import './administrationEcolesPage.css';
import { DT_Ecole_BIS } from '../../../components/datatable/dt_ecoles_bis';
import { useHistory } from 'react-router-dom';
import { DT_Composante } from '../../../components/datatable/dt_composantes';


export const AdministrationEcolesPage = () => {
  let emptyCampus = {
    id: 0,
    libelle: null,
    commune: null,
    pays: 'France',
    departement: null,
    ville: null,
    etranger: false,
    ecoleId: 0
  };
  let emptyComposante = {
    id: 0,
    nom: null,
    abonnementType: null,
    ecoleId: 0,
    codeUAIs: [{ code: null }]
  };
  let emptyEcole = {
    id: 0,
    nomEcole: null,
    composanteEcole: [emptyComposante],
    campus: [emptyCampus],
    nbFormations: 0,
    nbEtudiants: 0,
    descriptionEcole: [],
    referent: ''
  };


  const fileUploaderImageEcole = useRef('');
  const fileUploaderImageComposante = useRef([]);
  const { t } = useTranslation();
  const [ecoleDialog, setEcoleDialog] = useState(false);
  const [deleteEcoleDialog, setDeleteEcoleDialog] = useState(false);
  const [ecole, setEcole] = useState(emptyEcole);
  const [ecoleWithCodeUAIStatus, setEcoleWithCodeUAIStatus] = useState(null)
  const [campus, setCampus] = useState(null);
  const [composanteEcole, setComposanteEcole] = useState(null);
  const [erreurImage, setErreurImage] = useState(null);
  const [erreurImageCompo, setErreurImageCompo] = useState([]);

  const [ddlTypesAbonnement, setDdlTypesAbonnement] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useRef(null);
  const ecoleService = new EcoleService();
  const abonnementService = new AbonnementService();
  const history = useHistory();

  useEffect(() => {
    loadLazyData();
  }, []);


  const loadLazyData = () => {
    abonnementService.getTypesAbonnement().then(_ddlTypesAbonnement => {
      setDdlTypesAbonnement(_ddlTypesAbonnement);
    });
  };

  const openNew = () => {
    fileUploaderImageComposante.current.push(React.createRef());
    setEcole(emptyEcole);
    setSubmitted(false);
    setEcoleDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setEcoleDialog(false);
  };

  const saveEcole = () => {
    setSubmitted(true);
    let _ecole = cleanUpEmptyCodeUAI()
    let bOk = true;
    let doublonSaisieUAI = false;
    _ecole.campus.forEach(campu => {
      if (!campu.isOnline) {
        if (campu.libelle?.trim() === '') {
          campu.libelle = null;
        }
        var commune = campu.commune === null;
        var ville = campu.ville?.trim() === '' || campu.ville === null;
        var villeCommune = (commune && ville);
        var pays = campu.pays?.trim() === '' || campu.pays === null;
        var etranger = campu.etranger;
        var paysEtranger = (pays && etranger);
        if (villeCommune || paysEtranger) {
          bOk = false;
        }
      }
    });
    let _codeUAIs = []
    _ecole.composanteEcole?.forEach(composante => {
      if (!composante.nom?.trim() || !composante.abonnementType?.trim()) {
        bOk = false;
      }
      composante.codeUAIs.forEach(codeUAI => {
        if (codeUAI.code?.trim() && !/^[0-9]{7}[A-Z]$/.test(codeUAI?.code)) {
          bOk = false
        }
        if (codeUAI.code?.trim())
          _codeUAIs.push(codeUAI.code);
      });
    });
    let _uniqueCodesUAIs = [...new Set(_codeUAIs)]
    if (_codeUAIs.length != _uniqueCodesUAIs.length) {
      bOk = false
      doublonSaisieUAI = true
    }
    if (_ecole.nomEcole.trim() && bOk) {
      setIsSubmitting(true);
      ecoleService.checkIfCodeUAIsAreAvailable(_ecole).then(_ecoleWithCodeUAIStatus => {
        let _oneCodeIsUnavailable = false
        setEcoleWithCodeUAIStatus(_ecoleWithCodeUAIStatus)
        _ecoleWithCodeUAIStatus.composanteEcole.forEach(ce => {
          ce.codeUAIs.forEach(c => {
            if (c.isUnavailable == true) {
              _oneCodeIsUnavailable = true;
            }
          });
        });
        if (_oneCodeIsUnavailable) {
          toast.current.show({ severity: 'error', summary: t('errors.uai_code_already_exists_summary'), detail: t('errors.uai_code_already_exists_detail'), life: 3000 });
          setIsSubmitting(false);
        } else {
          ecoleService.createOrUpdateEcole(_ecole)
            .then(_ecole => {
              setEcole(emptyEcole);
              setComposanteEcole([emptyComposante]);
              toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('school.created_school'), life: 3000 });

              setEcoleDialog(false);
              loadLazyData();
            })
            .catch((err) => {
              toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 });
            })
            .finally(() => {
              setIsSubmitting(false);
            }
            )

        }
      })
    }
    else if(!bOk && doublonSaisieUAI){
      toast.current.show({ severity: 'error', summary: t('errors.duplicate_summary'), detail: t('errors.duplicate_uai_detail'), life: 3000 })
    }
    else{
      toast.current.show({ severity: 'error', summary: t('errors.incomplete_summary'), detail: t('errors.incomplete_detail'), life: 3000 })
    }
  };

  const cleanUpEmptyCodeUAI = () => {
    let _ecole = { ...ecole };
    _ecole.composanteEcole.forEach((ce, index) => {
      let _tab = ce.codeUAIs.filter(codeUAI => codeUAI.code != null && codeUAI.code?.trim() != '');
      ce.codeUAIs = _tab;
    });
    setEcole(_ecole)
    return _ecole
  };


  const onUploadImageEcole = ({ files }) => {
    let _ecole = { ...ecole }
    _ecole.fileImage = files[0];
    setEcole(_ecole);
    fileUploaderImageEcole.current.clear();
  };

  const onSelectImageEcole = ({ files }) => {
    setErreurImage(files[0].size > 5000000);
  };

  const handleRemoveImageEcole = () => {
    let _ecole = { ...ecole };
    _ecole.fileImage = null;
    setEcole(_ecole);
  }

  const handleRemoveFichierImageEcole = () => {
    let _ecole = { ...ecole };
    _ecole.logo = null;
    setEcole(_ecole);
  }

  const onUploadLogoCampus = (indexComposante, { files }) => {
    const _ecole = { ...ecole }
    let _composante = [...ecole.composanteEcole]
    _composante[indexComposante].fileLogo = files[0];
    _ecole.composanteEcole = _composante;
    setEcole(_ecole);
    fileUploaderImageComposante.current[indexComposante].current.clear();
  };

  const onSelectLogoCampus = (indexComposante, { files }) => {
    var _erreurImageCompo = [...erreurImageCompo];
    let _eC = _erreurImageCompo[indexComposante];
    _eC = files[0].size > 5000000;
    _erreurImageCompo[indexComposante] = _eC;
    setErreurImageCompo(_erreurImageCompo);
  };

  const handleRemoveLogoCampus = (indexComposante) => {
    let _ecole = { ...ecole };
    let _composante = [...ecole.composanteEcole]
    _composante[indexComposante].fileLogo = null;
    _ecole.composanteEcole = _composante;
    setEcole(_ecole);
  }

  const handleRemoveFichierLogoCampus = (indexComposante) => {
    let _ecole = { ...ecole };
    let _composante = [...ecole.composanteEcole]
    _composante[indexComposante].logo = null;
    _composante[indexComposante].logoId = null;
    _ecole.composanteEcole = _composante;
    setEcole(_ecole);
  }



  const editEcole = (ecole) => {
    ecole.composanteEcole.forEach(ce => {
      if (ce.codeUAIs.length == 0) {
        ce.codeUAIs.push({ code: null, composanteEcoleId: ce.id })
      }
    });
    setEcole({ ...ecole });

    // fileUploaderImageComposante.current = fileUploaderImageComposante.current.slice(0, ecole.composanteEcole.length);
    for (let i = 0; i < ecole.composanteEcole.length; i++) {
      fileUploaderImageComposante.current.push(React.createRef());
      var _erreurImageCompo = [...erreurImageCompo];
      _erreurImageCompo.push(false);
      setErreurImageCompo(_erreurImageCompo);
    }
    setEcoleDialog(true);
  };

  const editFicheEcole = (ecole) => {
    history.push('/edition_fiche_ecole', { ecoleId: ecole.id })
  };

  const confirmDeleteEcole = (ecole) => {
    setEcole(ecole);
    setDeleteEcoleDialog(true);
  };

  const onInputEcoleChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _ecole = { ...ecole };
    _ecole[`${name}`] = val;

    setEcole(_ecole);
  };

  const handleCompChange = (index, e, name) => {
    let _ecole = { ...ecole };
    let _composantes = [..._ecole.composanteEcole];

    let val = (e.target && e.target.value) || '';

    let _composante = _composantes[index];
    _composante[`${name}`] = val;
    _composantes[index] = _composante;

    setComposanteEcole(_composantes);

    _ecole.composanteEcole = _composantes;
    setEcole(_ecole);
  };

  const handleAddComp = () => {
    let _ecole = { ...ecole };
    let _composantes = [..._ecole.composanteEcole];
    _composantes.push({ nom: '', abonnementType: '', ecoleId: ecole.id, codeUAIs: [{ code: null }] });
    fileUploaderImageComposante.current.push(React.createRef());
    setComposanteEcole(_composantes);

    _ecole.composanteEcole = _composantes;
    setEcole(_ecole);
  };

  const handleRemoveComp = index => {
    let _ecole = { ...ecole };
    let _composantes = [..._ecole.composanteEcole];
    if (_composantes.length > 1) {

      fileUploaderImageComposante.current.splice(index, 1);

      _composantes.splice(index, 1);
      setComposanteEcole(_composantes);

      _ecole.composanteEcole = _composantes;
      setEcole(_ecole);
    }
  };

  const handleCodeUAIChange = (indexCompo, indexCodeUAI, e, name) => {
    let _ecole = { ...ecole };
    if (ecoleWithCodeUAIStatus) {
      let _ecoleWithCodeUAIStatus = { ...ecoleWithCodeUAIStatus }
      let _codeUAI = _ecoleWithCodeUAIStatus.composanteEcole[indexCompo].codeUAIs[indexCodeUAI]
      if (_codeUAI) {
        _codeUAI.isUnavailable = false;
        _ecoleWithCodeUAIStatus.composanteEcole[indexCompo].codeUAIs[indexCodeUAI] = _codeUAI;
        setEcoleWithCodeUAIStatus(_ecole);
      }
    }

    let val = (e.target && e.target.value) || '';

    let _codeUAI = _ecole.composanteEcole[indexCompo].codeUAIs[indexCodeUAI]
    _codeUAI[`${name}`] = val;

    _ecole.composanteEcole[indexCompo].codeUAIs[indexCodeUAI] = _codeUAI;
    setEcole(_ecole);
  };

  const handleAddCodeUAI = (indexCompo) => {
    let _ecole = { ...ecole };
    let _codeUAIs = [..._ecole.composanteEcole[indexCompo].codeUAIs]
    _codeUAIs.push({ code: null });

    _ecole.composanteEcole[indexCompo].codeUAIs = _codeUAIs;
    setEcole(_ecole);
  };

  const handleRemoveCodeUAI = (indexCompo, indexCode) => {
    let _ecole = { ...ecole };
    let _codeUAIs = [..._ecole.composanteEcole[indexCompo].codeUAIs]
    if (_codeUAIs.length > 1) {
      _codeUAIs.splice(indexCode, 1);
      _ecole.composanteEcole[indexCompo].codeUAIs = _codeUAIs;
      setEcole(_ecole);
    }
  };

  const handleCampChange = (index, e, name) => {
    let _ecole = { ...ecole };
    let _campus = [..._ecole.campus];

    let val = (e.target && e.target.value) || '';

    if (val === '' && name === 'commune')
      val = null;

    let _campu = _campus[index];
    _campu[`${name}`] = val;
    if(val?.id){
      _campu.communeId = val.id
    }
    else{
      _campu.communeId = null;
    }

    if (name == 'commune')
      _campu.ville = null;
    if (name == 'ville')
      _campu.commune = null;

    _campus[index] = _campu;
    _ecole.campus = _campus;
    setEcole(_ecole);
  };

  const handleEtrangerCampChange = (index, e, name) => {
    let _ecole = { ...ecole };
    let _campus = [..._ecole.campus];

    let val = (e.checked) || false;

    let _campu = _campus[index];
    _campu[`${name}`] = val;

    if (val) {
      _campu.ville = null;
      _campu.pays = null;
    }
    else {
      _campu.commune = null;
      _campu.pays = 'France';
    }

    _campus[index] = _campu;

    _ecole.campus = _campus;
    setEcole(_ecole);
  };

  const handleOnlineCampChange = (index, e) => {
    let _ecole = { ...ecole };
    let val = (e.checked) || false;
    let _campu = { ..._ecole.campus[index] };
    _campu.isOnline = val;

    if (val) {
      _campu.ville = null;
      _campu.pays = null;
      _campu.libelle = "Online"
      _campu.commune = null
      _campu.communeId = null
    }
    else {
      _campu.libelle = '';
    }
    _ecole.campus[index] = _campu;
    setEcole(_ecole);
  };

  const handleAddCamp = () => {
    let _ecole = { ...ecole };
    let _campus = [..._ecole.campus];
    _campus.push({ libelle: null, commune: null, etranger: false, ville: null, pays: 'France', ecoleId: ecole.id });

    _ecole.campus = _campus;
    setEcole(_ecole);
  };

  const handleRemoveCamp = index => {
    let _ecole = { ...ecole };
    let _campus = [..._ecole.campus];
    if (_campus.length > 1) {
      _campus.splice(index, 1);
      _ecole.campus = _campus;
      setEcole(_ecole);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label={t('school.new_school')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
      </React.Fragment>
    )
  }
  const ecoleDialogFooter = (
    <React.Fragment>
      <Button label={t('general.cancel')} icon="pi pi-times" className="p-button-text" loading={isSubmitting} onClick={hideDialog} />
      <Button label={t('general.save')} icon="pi pi-check" className="p-button-text" loading={isSubmitting} onClick={saveEcole} />
    </React.Fragment>
  );

  const rowExpansionTemplate = (ecole) => {
    return (
      <div className="formations-subtable">
        <DT_Composante
          ecoleId={ecole.id}
        />
      </div>
    );
  }

  return (
    <div className="datatable-ecoles">
      <Toast ref={toast} />

      <div className="card">
        <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>

        <DT_Ecole_BIS
          EditEcole={editEcole}
          EditFiche={editFicheEcole}
          ConfirmDelete={confirmDeleteEcole}
          rowExpansionTemplate={rowExpansionTemplate}

        />

      </div>

      <Dialog visible={ecoleDialog} style={{ width: '70rem', height: "100%" }} header={t('school.school_account')} modal className="p-fluid" footer={ecoleDialogFooter} onHide={hideDialog}>
        <div>
          <div className="p-field">
            <label htmlFor="nomEcole"><Trans i18nKey="school.school_name" />*</label>
            <InputText id="nomEcole" value={ecole.nomEcole} onChange={(e) => onInputEcoleChange(e, 'nomEcole')} required autoFocus className={classNames({ 'p-invalid': submitted && !ecole.nomEcole })} />
            {submitted && !ecole.nomEcole && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
          </div>
          <div className="p-field">
            <FileUpload
              ref={fileUploaderImageEcole}
              mode="basic"
              auto
              customUpload={true}
              maxFileSize={5000000}
              invalidFileSizeMessageSummary={t('profile.invalidFileSizeMessageSummary')}
              invalidFileSizeMessageDetail={t('profile.invalidFileSizeMessageDetail_5mb')}
              chooseLabel={t('general.add_logo')}
              className="ajoutlogo p-button-info p-mr-2 p-d-inline-block"
              name="imageEcole"
              url=""
              uploadHandler={onUploadImageEcole}
              onSelect={onSelectImageEcole}
              accept="image/*" />
            {ecole?.fileImage?.name &&
              <span>{ecole?.fileImage?.name}<Button
                icon="pi pi-times"
                className="p-button-rounded p-button-danger"
                onClick={() => handleRemoveImageEcole()}
                style={{ left: "1em" }}
              /></span>
              ||
              (ecole?.logo?.name &&
                <span>{ecole?.logo?.name}<Button
                  icon="pi pi-times"
                  className="p-button-rounded p-button-danger"
                  onClick={() => handleRemoveFichierImageEcole()}
                  style={{ left: "1em" }}
                /></span>
              )}
            <div>
              {erreurImage && <small className="p-error"><Trans i18nKey="profile.invalidFileSizeMessageDetail_5mb" /></small>}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div style={{ display: "inline-flex" }}>
            <label style={{ fontWeight: '500' }} className="label-align-margin-right"><Trans i18nKey="school.components" /></label>
            <Button
              icon="pi pi-plus"
              className="p-button-rounded p-button-success p-mr-2"
              onClick={() => handleAddComp()}
              style={{ visibility: ecole.composanteEcole?.length === 0 ? "visible" : "hidden" }}
            />
          </div>
          {ecole.composanteEcole?.map((compo, indexCompo) => (
            <Fragment key={`comp~${indexCompo}`}>
              <div className="p-field">
                <br />
                <div className="p-formgrid p-grid">
                  <div className="p-field p-col-5">
                    <label><Trans i18nKey="component.name" />*</label>
                    <InputText value={compo.nom} onChange={event => handleCompChange(indexCompo, event, 'nom')} className={classNames({ 'p-invalid': submitted && !compo.nom })} />
                    {submitted && !compo.nom && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                  </div>
                  <div className="p-field p-col-5">
                    <label><Trans i18nKey="component.subscription_type" />*</label>
                    <Dropdown value={compo.abonnementType} options={ddlTypesAbonnement} onChange={event => handleCompChange(indexCompo, event, 'abonnementType')} placeholder={t('component.please_select_subscription')} className={classNames({ 'p-invalid': submitted && !compo.abonnementType })} />
                    {submitted && !compo.abonnementType && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                  </div>
                  <div className="p-field p-col-2">
                    <Button
                      icon="pi pi-times"
                      className="p-button-rounded p-button-danger"
                      onClick={() => handleRemoveComp(indexCompo)}
                      style={{ visibility: indexCompo > 0 ? "visible" : "hidden" }}
                    />
                    <Button
                      icon="pi pi-plus"
                      className="p-button-rounded p-button-success p-mr-2"
                      onClick={() => handleAddComp()}
                      style={{ visibility: indexCompo == ecole.composanteEcole?.length - 1 ? "visible" : "hidden" }}
                    />
                  </div>
                  {compo.codeUAIs.map((codeUAI, indexCodeUAI) => (
                    <Fragment key={`${indexCompo}~codeUAI~${indexCodeUAI}`}>
                      <div className="p-field p-col-6">
                        <label htmlFor={`${indexCompo}~codeUAI~${indexCodeUAI}`}><Trans i18nKey="component.uai_code" /></label>
                        <InputText id={`${indexCompo}~codeUAI~${indexCodeUAI}`}
                          maxLength={8}
                          value={codeUAI.code}
                          onChange={event => handleCodeUAIChange(indexCompo, indexCodeUAI, event, 'code')}
                          className={classNames({ 'p-invalid': (submitted && ((ecoleWithCodeUAIStatus?.composanteEcole[indexCompo]?.codeUAIs[indexCodeUAI]?.isUnavailable == true) || (!/^[0-9]{7}[A-Z]$/.test(codeUAI?.code) && codeUAI?.code?.trim()))) })} />

                        {submitted && (!/^[0-9]{7}[A-Z]$/.test(codeUAI?.code) && codeUAI?.code?.trim()) && <small className="p-error"><Trans i18nKey="errors.uai_code_invalid" /></small>}
                        {submitted && ecoleWithCodeUAIStatus?.composanteEcole[indexCompo]?.codeUAIs[indexCodeUAI]?.isUnavailable == true && <small className="p-error"><Trans i18nKey="errors.uai_code_already_exists_summary" /></small>}
                      </div>
                      <div className="p-field p-col-6 dip-ps-end">
                        <Button
                          icon="pi pi-times"
                          className="p-button-rounded p-button-danger"
                          onClick={() => handleRemoveCodeUAI(indexCompo, indexCodeUAI)}
                          style={{ visibility: indexCodeUAI > 0 ? "visible" : "hidden" }}
                        />
                        <Button
                          icon="pi pi-plus"
                          className="p-button-rounded p-button-success p-mr-2"
                          onClick={() => handleAddCodeUAI(indexCompo)}
                          style={{ visibility: indexCodeUAI == ecole.composanteEcole[indexCompo].codeUAIs.length - 1 ? "visible" : "hidden" }}
                        />
                      </div>
                    </Fragment>
                  ))}
                  <div className="p-mt-3 p-field p-col-12">
                    <FileUpload
                      ref={fileUploaderImageComposante.current[indexCompo]}
                      mode="basic"
                      auto
                      customUpload={true}
                      maxFileSize={5000000}
                      invalidFileSizeMessageSummary={t('profile.invalidFileSizeMessageSummary')}
                      invalidFileSizeMessageDetail={t('profile.invalidFileSizeMessageDetail_5mb')}
                      chooseLabel={t('general.add_logo')}
                      className="ajoutlogo p-button-info p-mr-2 p-d-inline-block"
                      name="imageEcole"
                      url=""
                      uploadHandler={event => onUploadLogoCampus(indexCompo, event)}
                      onSelect={event => onSelectLogoCampus(indexCompo, event)}
                      accept="image/*" />
                    {compo?.fileLogo?.name &&
                      <span>{compo?.fileLogo?.name}<Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger"
                        onClick={event => handleRemoveLogoCampus(indexCompo, event)}
                        style={{ left: "1em" }}
                      /></span>
                      ||
                      (compo?.logo?.name &&
                        <span>{compo?.logo?.name}<Button
                          icon="pi pi-times"
                          className="p-button-rounded p-button-danger"
                          onClick={event => handleRemoveFichierLogoCampus(indexCompo, event)}
                          style={{ left: "1em" }}
                        /></span>
                      )}
                    <div>
                      {erreurImageCompo[indexCompo] && <small className="p-error"><Trans i18nKey="profile.invalidFileSizeMessageDetail_5mb" /></small>}
                    </div>
                  </div>
                </div>
                <Divider type="dotted" />
              </div>
            </Fragment>
          ))}
        </div>

        <div className="form-row">
          <div style={{ display: "inline-flex" }}>
            <label style={{ fontWeight: '500' }} className="label-align-margin-right"><Trans i18nKey="school.campus" /></label>
            <Button
              icon="pi pi-plus"
              className="p-button-rounded p-button-success p-mr-2"
              onClick={() => handleAddCamp()}
              style={{ visibility: ecole.campus.length === 0 ? "visible" : "hidden" }}
            />
          </div>

          {ecole.campus.map((campu, indexCampu) => (
            <Fragment key={`camp-${indexCampu}`}>
              <div className="p-field">
                <br />
                <div className="p-formgrid p-grid">
                  <div className="aep-align-check p-field-checkbox p-col-5">
                    <Checkbox inputId={`camp-${indexCampu}-online`} name="online" checked={campu.isOnline} onChange={event => handleOnlineCampChange(indexCampu, event)} />
                    <label htmlFor={`camp-${indexCampu}-online`}><Trans i18nKey="campus.online" /></label>
                  </div>
                  <div className='p-col-7'>
                  </div>
                  <div className="p-field p-col-5">
                    <label htmlFor={`camp-${indexCampu}-libelle`}><Trans i18nKey="campus.libelle" /></label>
                    <InputText id={`camp-${indexCampu}-libelle`} name='libelle' disabled={campu.isOnline} value={campu.libelle} onChange={event => handleCampChange(indexCampu, event, 'libelle')} />
                  </div>
                  {!ecole.campus[indexCampu].isOnline && <><div className="aep-align-check p-field-checkbox p-col-5">
                    <Checkbox inputId={`camp-${indexCampu}-etranger`} name="etranger" checked={campu.etranger} onChange={event => handleEtrangerCampChange(indexCampu, event, 'etranger')} />
                    <label htmlFor={`camp-${indexCampu}-etranger`}><Trans i18nKey="campus.located_abroad" /></label>
                  </div>
                    {ecole.campus[indexCampu].etranger ? <>
                      <div className="p-field p-col-5">
                        <label htmlFor={`camp-${indexCampu}-ville`}><Trans i18nKey="campus.city" />*</label>
                        <InputText id={`camp-${indexCampu}-ville`} value={campu.ville} onChange={event => handleCampChange(indexCampu, event, 'ville')} className={classNames({ 'p-invalid': submitted && !campu.ville })} />
                        {submitted && !campu.ville && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                      </div>
                      <div className="p-field p-col-5">
                        <label htmlFor={`camp-${indexCampu}-pays`}><Trans i18nKey="campus.country" />*</label>
                        <InputText id={`camp-${indexCampu}-pays`} value={campu.pays} onChange={event => handleCampChange(indexCampu, event, 'pays')} className={classNames({ 'p-invalid': submitted && !campu.pays })} />
                        {submitted && !campu.pays && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                      </div>
                    </> : <>
                      <div className="p-field p-col-5">
                        <label><Trans i18nKey="campus.city" />*</label>
                        <CommuneSelector value={campu.commune} onChange={event => handleCampChange(indexCampu, event, 'commune')} className={classNames({ 'p-invalid': submitted && !campu.commune })} />
                        {submitted && !campu.commune && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                      </div>
                      <div className="p-field p-col-5">
                        <label htmlFor={`camp-${indexCampu}-departement`}><Trans i18nKey="campus.department" /></label>
                        <InputText id={`camp-${indexCampu}-departement`} disabled value={campu.commune === null ? '' : campu.commune?.departement?.libelle} />
                      </div>
                    </>}
                  </>}

                  <div className="p-field p-col-2">
                    <Button
                      icon="pi pi-times"
                      className="p-button-rounded p-button-danger"
                      onClick={() => handleRemoveCamp(indexCampu)}
                      style={{ visibility: indexCampu > 0 ? "visible" : "hidden" }}
                    />
                    <Button
                      icon="pi pi-plus"
                      className="p-button-rounded p-button-success p-mr-2"
                      onClick={() => handleAddCamp()}
                      style={{ visibility: indexCampu == ecole.campus.length - 1 ? "visible" : "hidden" }}
                    />
                  </div>
                </div>
                <Divider type="dotted" />
              </div>
            </Fragment>
          ))}
        </div>
      </Dialog>
    </div>
  );
}