
import React, { useState, useEffect, useRef, Fragment } from 'react';
import FormationService from '../../../services/formationService';
import { useAuthState } from '../../../context/context';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Trans, useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { DT_Formation } from '../../../components/datatable/dt_formation';
import { DT_Ecole } from '../../../components/datatable/dt_ecoles';
import './administrationFormationsPage.css';
import { enumRoles } from '../../../enums/enumRoles';
import { FormationDialog } from '../../../components/dialog/formationDialog';


export const AdministrationFormationsPage = () => {
  
  const { t } = useTranslation();
  const currentUser = useAuthState();

  const [formationDialog, setFormationDialog] = useState(false);
  const [deleteFormationsDialog, setDeleteFormationsDialog] = useState(false);

  const [formationIsDuplicate, setFormationIsDuplicate] = useState(false);

  const [formationId, setFormationId] = useState(0);


  const toast = useRef(null);

  const [refreshToken, setRefreshToken] = useState(new Date());

  const openNewFormationDialog = () => {
    setFormationId(0)
    setFormationIsDuplicate(false);
    setFormationDialog(true);
  }

  const openEditFormationDialog = (formationId) => {
    setFormationId(formationId);
    setFormationIsDuplicate(false);
    setFormationDialog(true);
  }

  const openDuplicateFormationDialog = (formationId) => {
    setFormationId(formationId);
    setFormationIsDuplicate(true);
    setFormationDialog(true);
  }

  const hideDialog = () => {
    setFormationDialog(false);
  }

  const triggerDataTableRefresh = () => {
    setRefreshToken(new Date());
  }

  const hideDeleteFormationsDialog = () => {
    setDeleteFormationsDialog(false);
  }

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label={t('formation.new_formation')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNewFormationDialog} />
      </React.Fragment>
    )
  }

  const rowExpansionTemplate = (ecole) => {
    return (
      <div className="formations-subtable">
        <DT_Formation ecoleId={ecole.id}
          openDuplicateFormationDialog={openDuplicateFormationDialog}
          openEditFormationDialog={openEditFormationDialog}
          refreshToken={refreshToken}
        />
      </div>
    );
  }

  return (

    <div className="datatable-rowexpansion-composante">
      <Toast ref={toast} />

      <div className="card">
        {((currentUser.roles.indexOf(enumRoles.SUPERADMINISTRATEUR) > -1) || (currentUser.roles.indexOf(enumRoles.ADMINISTRATEUR) > -1)) && <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>}
        <div className="card">
          <DT_Ecole rowExpansionTemplate={rowExpansionTemplate} />
        </div>
      </div>

      <FormationDialog visible={formationDialog} onHide={hideDialog} formationId={formationId} isDuplicate={formationIsDuplicate} onSave={triggerDataTableRefresh} canSaveAndVisualize/>

    </div >
  );
}
