import React, { useState, Children } from "react";
import "./burgerMenu.css";
import { Link } from 'react-router-dom'
import { Sidebar } from 'primereact/sidebar';
import dip_terra_cotta from "../../images/dipLogo/dip_terra_cotta.png"
import {Trans, useTranslation } from 'react-i18next'

export const BurgerMenu = ({ itemsMenu }) => {

    const { t } = useTranslation();
    const [visibleLeft, setVisibleLeft] = useState(false);

    return (
        <>
            <Sidebar className="bm-sidebar-container" visible={visibleLeft} baseZIndex={1000000} onHide={() => setVisibleLeft(false)}>
                <div className='header-menu-container'>
                    <img className="bm-img-burger-menu" src={dip_terra_cotta}></img>
                </div>
                <ul className="bm-ul-menu">
                    {Children.toArray(itemsMenu.map((item) => (
                        <li><Link className='p-mr-3' to={item.link}><Trans i18nKey={item.label} /><i className="pi pi-angle-right"></i></Link></li>
                    )))}
                </ul>
            </Sidebar>
            <a href="#" id="openBtn" onClick={() => setVisibleLeft(true)}>
                <span className="burger-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </a>
        </>
    )
}