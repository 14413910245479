import React, { useRef, useState, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import "./menuPopUpSearchFilter.css"
import { MenuAccordionSearchFilter } from './menuAccordionSearchFilter';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import RechercheContext from '../RechercheContext';
import { Tag } from 'primereact/tag';

export const MenuPopUpSearchFilter = (props) => {
    const { t } = useTranslation();
    const [displayFilterMenu, setDisplayFilterMenu] = useState(false);
    const contextRecherche = useContext(RechercheContext);

    const onClick = () => {
        setDisplayFilterMenu(true);
    }

    const onHide = () => {
        setDisplayFilterMenu(false);
    }

    const headerTemplate = () => {
        return (
            <div className='mpsf-header'>
                <div className="mpsf-header-main-title">
                    {t('search.search_filters')}
                </div>
                <div className='mpsf-header-main-title'>
                    <Button
                        icon="pi pi-times"
                        iconPos="left"
                        label={t('search.erase_filters')}
                        className="p-button-rounded mpsf-erase-filters"
                        onClick={props.handleClicEffacerFiltres} 
                    />
                </div>
            </div>

        )
    }

    return (
        <div>
            <Button className="mspf-button-label p-shadow-8" onClick={() => onClick()} >
                <span >{t('general.filters')}</span>
                {contextRecherche.lazyParams.filters.length > 0 && <Tag className='p-ml-2'>{contextRecherche.lazyParams.filters.length}</Tag>}
            </Button>
            <Dialog header={headerTemplate} visible={displayFilterMenu} className="mpsf-dialog" onHide={() => onHide()}>
                <MenuAccordionSearchFilter activeIndex={props.activeIndex} setActiveIndex={props.setActiveIndex} isInFavoris={props.isInFavoris} />
            </Dialog>
        </div>
    )
}