import React, { useState, useEffect, useRef } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import LangueService from '../../services/langueService';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import Helper from '../../utils/helper';

export const LangueSelector = (props) => {

    const service = new LangueService();
    const { t } = useTranslation();
    const toast = useRef(null);

    const [selectedLangue, setSelectedLangue] = useState(props.value);
    const [filteredLangue, setFilteredLangue] = useState(props.value != null ? [props.value] : null);

    useEffect(() => {
        setSelectedLangue(props.value)
    }, [props.value])

    const searchLangue = (event) => {
        service.getLanguesFiltered(event.query)
            .then(res => {
                setFilteredLangue(res);
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 });
            })
            .finally(() => {
            }
            );
    }

    const onLangueChange = (e) => {
        setSelectedLangue(e.value);
        if (e.value?.id == null && !Helper.isEmptyOrSpaces(e.value))
            return;
       props.onChange(e);
    }

    return (<>
        <Toast ref={toast} />
        <AutoComplete className={props.className} value={selectedLangue} suggestions={filteredLangue} forceSelection dropdown completeMethod={searchLangue} field={"libelle"} onChange={onLangueChange} placeholder={props.placeholder} />
    </>
    );

}

