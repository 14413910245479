import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from "react-i18next"

import bannerRectangle from '../../../images/accueil/bannerRectangle.png';
import accueilEntreprise from "../../../images/accueil/entreprise/accueilEntreprise.jpg";

export const HeaderHomeEntreprise = () => {

    const { t } = useTranslation()

    return (
        <>
            <div className='dip-only-pc dip-pc-center dip-flex-column  banner-container banner-entreprise catchphrase-first'>
                <div className=' catchphrase-bloc'>
                    <img className="dip-ml-auto" src={bannerRectangle}/>
                    <div className='catchphrase-text dip-flex-column dip-h-100' >
                        <div><span className='p-mr-2'>Définissez les</span><span className='p-mr-2 dip-color-tc'>{t('skills.skills').toLowerCase()}</span></div>
                        <div>dont vous avez besoin</div>
                        <div>et identifiez les candidats</div>
                        <div>les plus adaptés</div>
                    </div>
                </div>
            </div>
            <div className='dip-only-mobile catchphrase-first dip-flex-column dip-ta-center' >
                <img className='dip-h-auto dip-w-100' src={accueilEntreprise}  alt={t('meta-img.alt-header-home-entreprise')}/>
                <div className='p-m-3'>
                    <div>
                        <span className='p-mr-2'>Définissez les</span>
                        <span className='dip-color-tc'>{t('skills.skills').toLowerCase()}</span>
                    </div>
                    <div>dont vous avez besoin</div>
                    <div>et identifiez les candidats</div>
                    <div>les plus adaptés</div>
                </div>
            </div>
        </>
    )
};