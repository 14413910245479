import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { useTranslation } from "react-i18next";
import { useAuthState } from "../../context/context"
import RoleHelper from "../../utils/roleHelper"
import "./formNavigationButtons.css"

export const FormNavigationButtons = (props) => {

    const { t } = useTranslation();
    const currentUser = useAuthState();

    const itemsSave = [
        {
            label: t('general.save_and_visualize'),
            icon: 'pi pi-sign-out',
            command: (e) => {
                props.onSave(props.setOnSave, null, true)
            }
        }
    ]

    return (
        <div className='p-d-flex p-jc-center p-mt-2 float-form-button fnb-container'>
            <Button icon="pi pi-angle-left" className={`fnb-navigation fnb-navigation-left ${props.firstStep && "fnb-invisible"}`} label={t('form.previous_step')} loading={props.isSubmitting} loadingOptions={{ position: 'right' }} onClick={props.previousStep}></Button>
            {(RoleHelper.isSuperAdmin(currentUser) || RoleHelper.isAdmin(currentUser)) && props.isVisualizationAllowed ? (
                <SplitButton label={t('profile.save_changes')} className="frs-button p-ml-2 p-mr-2" icon="pi pi-save" loading={props.isSubmitting} loadingOptions={{ position: 'right' }} onClick={e => props.onSave(props.setOnSave, null, false)} model={itemsSave} />
                ) : (
                <>
                    <Button label={t('profile.save_changes')} className="frs-button fnb-pc p-ml-2 p-mr-2" loading={props.isSubmitting} loadingOptions={{ position: 'right' }} onClick={e => props.onSave(props.setOnSave, null, false)} />
                    <Button icon="pi pi-save" className="p-button-rounded fnb-mobile fnb-save p-ml-3 p-mr-3" loading={props.isSubmitting} loadingOptions={{ position: 'right' }} onClick={e => props.onSave(props.setOnSave, null, false)} />
                </>
            )}
            <Button icon="pi pi-angle-right" className={`fnb-navigation fnb-navigation-right ${props.lastStep && "fnb-invisible"}`} label={t('form.next_step')} loading={props.isSubmitting} loadingOptions={{ position: 'right' }} onClick={props.nextStep} iconPos="right"></Button>
        </div>
    )
}