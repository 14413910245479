export const enumTypeRechercheAffinee = {
    FORMATION : "Formation",
    CANDIDAT : "Candidat"
}

export const enumDomaines = {
    BANQUE : "Banque, Finance, Immobilier",
    BUSINESS : "Business, Management",
    CULTURE : "Culture, Tourisme, Loisirs",
    DIGITAL : "Digital",
    DROIT : "Droit, Fiscalité",
    MARKETING : "Marketing, Communication, Publicité",
    SANTE : "Santé, Sport, Social",
    SCIENCES : "Sciences, Ingénierie, Etudes"
}