import { Button } from "primereact/button"
import { useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import dip_terra_cotta from "../../../images/dipLogo/dip_terra_cotta.png"
import DA_onboarding_photo from "../../../images/onBoarding/DA_onboarding_photo.jpg"
import dip_holding_flag from "../../../images/onBoarding/dip_holding_flag.png"
import { Mailto } from "../../mailto/mailto"

export const RobEnd = (props) => {
    const { t } = useTranslation()
    const history = useHistory();

    useEffect(() => {
        if (props.etudiant.isRegistrationOnboardingCompleted) {
            if (!props.etudiant.isDisabled) {
                if (props.etudiant.urlInscription) {
                    history.push(props.etudiant.urlInscription)
                }
                else {
                    history.push("/")
                }
            }
        }
        else {
            props.onReachingOnboardingEnd();
        }
    }, [])

    // const onRDVClick = () => {
    //     var win = window.open("https://meetings.hubspot.com/patrick-musso/demo-da")
    //     win.focus();
    // }

    return (
        <div className="p-grid rob-outro-fs">
            <div className="p-col-12 p-sm-12 p-lg-8">
                <h1 className="rob-outro-bravo dip-m-0">{t('onboarding.bravo').toUpperCase()}</h1>
                <div className="p-ml-1">
                    <div className="rob-community-container">
                        <b className="p-mr-2">{t('onboarding.you_joined')}</b>
                        <b className="p-mr-2">{t('onboarding.the_community').toLowerCase()}</b>
                        <b className="p-mr-2 dip-color-tc">DiplomAdvisor</b>
                    </div>
                    <br />
                    <div className="rob-outro-enrich-container">
                        <span className="p-mr-2">{t('onboarding.max_your_chance')}</span>
                        <span className="p-mr-2">{props.onboardingChoice == 2 ? (t('onboarding.our_company')) : (t('onboarding.our_school'))}</span>
                        <b className="dip-color-tc">{t('onboarding.completing_your_profile').toLowerCase()}</b>
                    </div>
                    <div className="rob-outro-end-button-container">
                        <Button
                            id="endOnboardingEtudiantInline"
                            className="dip-btn-red-bg"
                            label={t('general.finish')}
                            onClick={props.onEndClick}
                        />
                    </div>
                    <br />
                    <div className="dip-flex-row">
                        {/* <div className="rob-photo-container">
                            <img src={DA_onboarding_photo} />
                        </div> */}
                        <div className="rob-outro-help-container">
                            <div>
                                <span className="p-mr-2">{t('onboarding.need_help')}</span>
                                <b className="dip-color-tc">DiplomAdvisor</b>
                            </div>
                            <br />
                            <div>
                                {/* <Button
                                    className="dip-btn-red-outline dip-color-tc"
                                    label={t('onboarding.contact_us')}
                                    onClick={onRDVClick}
                                /> */}
                                <Mailto
                                    property="email"
                                    label={t('onboarding.contact_us')}
                                    className="dip-btn-red-outline dip-color-tc"
                                    object=""
                                    body=""
                                    to="contact@diplomadvisor.com" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12 p-sm-12 p-lg-4 dip-as-center">
                <img className="rob-outro-img" src={dip_holding_flag} />
            </div>
        </div >
    )
}