import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next"
import ProjetService from "../../services/projetService";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EntrepriseService from "../../services/entrepriseService";

export const EntrepriseProjetForm = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [projet, setProjet] = useState(props.projet)
    const [loadingSave, setLoadingSave] = useState(false)
    const toast = useRef(null);
    const projetService = new ProjetService();
    const entrepriseService = new EntrepriseService();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId

    const onProjetChange = (e, name) => {
        let val = (e.value || e.target.value) || (typeof e?.target?.getContent === "function" && e?.target?.getContent()) || null;
        let _projet = { ...projet };
        _projet[`${name}`] = val;
        setProjet(_projet);
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const saveProject = () => {
        setLoadingSave(true);
        entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then(id => {
            projetService.createOrUpdateProjet(projet, id)
                .then(() => {
                    setProjet(null)
                    if (props.onSave)
                        props.onSave()                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                })
                .catch(err => showError())
                .finally(() => setLoadingSave(false))
        })
    }
    return (
        <>
            <Toast ref={toast} />
            <div className="p-grid p-fluid form-entreprise-theme" >
                <div className="p-field p-col-12">
                    <label>{t('project.name')}</label><label>*</label>
                    <InputText value={projet?.nom} onChange={(e) => onProjetChange(e, "nom")} />
                </div>
                <div className="p-field p-col-12">
                    <label>{t('project.description')}</label>
                    <InputTextarea value={projet?.description} onChange={(e) => onProjetChange(e, "description")} />
                </div>
                <div className="p-field p-col-12">
                    <Button loading={loadingSave} label={t('general.save')} className="dip-btn-red-bg" onClick={saveProject} />
                </div>
            </div>
        </>
    )
}