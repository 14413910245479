import axios from 'axios';
import BaseService from './baseService';

export default class EtudiantService extends BaseService {
    getEtudiantInfoPerso(etudiantId) {
        const options = {
            params: { etudiantId: etudiantId }
          };
        return this.axiosApiInstance.get(window.API_URL + '/api/etudiant/GetEtudiantInfoPerso',options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    postEtudiantInfoPerso(currentUser, etudiant) {
      const jsonEtudiant = JSON.stringify(etudiant, null, 2);
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + currentUser.token
        }
      };
      const formData = new FormData()
      formData.append('etudiant', jsonEtudiant);
      if (etudiant.photo !== undefined)
        formData.append('photo', new Blob([etudiant.photo], { type: etudiant.photo.type }), etudiant.photo.name);
      if (etudiant.cvPdf !== undefined)
        formData.append('cvPdf', new Blob([etudiant.cvPdf], { type: etudiant.cvPdf.type }), etudiant.cvPdf.name);
      if (etudiant.portfolio !== undefined)
        formData.append('portfolio', new Blob([etudiant.portfolio], { type: etudiant.portfolio.type }), etudiant.portfolio.name);
  
      return axios.post(window.API_URL + '/api/etudiant/PostEtudiantInfoPerso', formData, options)
        .then(res => res.data)
        .catch(err => super.handleHttpError(err));
    }

    getEtudiantEtudes(etudiantId) {
        const options = {
            params: { etudiantId: etudiantId }
          };
        return this.axiosApiInstance.get(window.API_URL + '/api/etudiant/GetEtudiantEtudes',options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    postEtudiantEtudes(currentUser, etudiant) {
      const jsonEtudiant = JSON.stringify(etudiant, null, 2);
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + currentUser.token
        }
      };
      const formData = new FormData()
      formData.append('etudiant', jsonEtudiant);
      if (etudiant.photo !== undefined)
        formData.append('photo', new Blob([etudiant.photo], { type: etudiant.photo.type }), etudiant.photo.name);
      if (etudiant.cvPdf !== undefined)
        formData.append('cvPdf', new Blob([etudiant.cvPdf], { type: etudiant.cvPdf.type }), etudiant.cvPdf.name);
      if (etudiant.portfolio !== undefined)
        formData.append('portfolio', new Blob([etudiant.portfolio], { type: etudiant.portfolio.type }), etudiant.portfolio.name);
  
      return axios.post(window.API_URL + '/api/etudiant/PostEtudiantEtudes', formData, options)
        .then(res => res.data)
        .catch(err => super.handleHttpError(err));
    }

    getEtudiantCompetences(etudiantId) {
        const options = {
            params: { etudiantId: etudiantId }
          };
        return this.axiosApiInstance.get(window.API_URL + '/api/etudiant/GetEtudiantCompetences',options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    postEtudiantCompetences(currentUser, etudiant) {
      const jsonEtudiant = JSON.stringify(etudiant, null, 2);
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + currentUser.token
        }
      };
      const formData = new FormData()
      formData.append('etudiant', jsonEtudiant);
      if (etudiant.photo !== undefined)
        formData.append('photo', new Blob([etudiant.photo], { type: etudiant.photo.type }), etudiant.photo.name);
      if (etudiant.cvPdf !== undefined)
        formData.append('cvPdf', new Blob([etudiant.cvPdf], { type: etudiant.cvPdf.type }), etudiant.cvPdf.name);
      if (etudiant.portfolio !== undefined)
        formData.append('portfolio', new Blob([etudiant.portfolio], { type: etudiant.portfolio.type }), etudiant.portfolio.name);
  
      return axios.post(window.API_URL + '/api/etudiant/PostEtudiantCompetences', formData, options)
        .then(res => res.data)
        .catch(err => super.handleHttpError(err));
    }

    getEtudiantSituationPro(etudiantId) {
        const options = {
            params: { etudiantId: etudiantId }
          };
        return this.axiosApiInstance.get(window.API_URL + '/api/etudiant/GetEtudiantSituationPro',options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    postEtudiantSituationPro(currentUser, etudiant) {
      const jsonEtudiant = JSON.stringify(etudiant, null, 2);
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + currentUser.token
        }
      };
      const formData = new FormData()
      formData.append('etudiant', jsonEtudiant);
      if (etudiant.photo !== undefined)
        formData.append('photo', new Blob([etudiant.photo], { type: etudiant.photo.type }), etudiant.photo.name);
      if (etudiant.cvPdf !== undefined)
        formData.append('cvPdf', new Blob([etudiant.cvPdf], { type: etudiant.cvPdf.type }), etudiant.cvPdf.name);
      if (etudiant.portfolio !== undefined)
        formData.append('portfolio', new Blob([etudiant.portfolio], { type: etudiant.portfolio.type }), etudiant.portfolio.name);
  
      return axios.post(window.API_URL + '/api/etudiant/PostEtudiantSituationPro', formData, options)
        .then(res => res.data)
        .catch(err => super.handleHttpError(err));
    }
}