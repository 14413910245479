import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuthState } from "../../context/context";
import { enumRoles } from '../../enums/enumRoles';
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import Pic3 from "../../images/savoir-plus/commun/static-1.webp"
import Pic1 from "../../images/savoir-plus/commun/static-2.webp"
import Pic2 from "../../images/savoir-plus/etudiant/inscription.webp"
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import "./savoirplus_etudiant.css";
import 'primeflex/primeflex.css';

export const SavoirPlus_Etudiant = (props) => {

    const currentUser = useAuthState();
    const isAdmin = currentUser?.roles?.indexOf(enumRoles.ADMINISTRATEUR) > -1;
    const isEtudiant = currentUser?.roles?.indexOf(enumRoles.ETUDIANT) > -1 && !isAdmin;

    const history = useHistory();
    const { t } = useTranslation();

    const onRetourAccueilClick = () => {
        history.push('/');
    }

    const onRetourCreationEtudianClick = () => {
        if (isEtudiant)
            history.push('/dashboard');
        else {
            history.push({ pathname: '/register?User=Student', state :{ userType : enumRoles.ETUDIANT, previousUrl : history.location.pathname} })
        }
    }

    return (
        <div className={props.className}>
             <Helmet>
                <meta charSet="utf-8" />
                <title>Etudiant - DiplomAdvisor</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description"  content={t("metadesc.about-student")} />
            </Helmet>
            <div className="p-grid savoirplus-etudiant-container">
                <div className="savoirplus-etudiant-head p-col-12">
                    <div className="title-en-savoir-plus-etudiant">
                        <h1>{parse(t("information.savoirplus.etudiant.Title1"))}</h1>
                    </div>
                </div>
                <div className="p-grid savoirplus-etudiant-block">
                    <div className="p-md-12 p-lg-6 p-col-12 p-col-align-center" >
                        <h2 className="text-huge-etudiant"><span style={{ color: 'rgb(237,106,90)' }}>Vous cherchez un stage, une alternance ou un emploi ?&nbsp;</span></h2>
                        <h2 className="text-big-etudiant"><span style={{ color: 'rgb(4,72,108)' }}>DiplomAdvisor vous aide à mettre en valeur vos compétences !</span></h2>
                    </div>
                    <div className="imagecard-etudiant p-md-12 p-lg-6 p-col-align-center">
                        <img src={Pic1} />
                    </div>
                </div>
                <div className="p-grid savoirplus-etudiant-block">
                    <div className="imagecard-etudiant p-md-12 p-lg-7 p-col-align-center">
                        <img src={Pic2} />
                    </div>
                    <div className=" p-md-12 p-lg-5 p-col-align-center comment-ca-marche-savoir-plus-etudiant">
                        <p className="text-big-comment-ca-marche"><span style={{ color: 'rgb(4,72,108)' }}>Comment ça marche ?</span></p><br />
                        <ul>
                            <li><h3 style={{ color: 'rgb(4,72,108)' }}>Ouvrez un compte (c’est gratuit !)</h3></li><br />
                            <li><h3 style={{ color: 'rgb(4,72,108)' }}>Indiquez les formations que vous avez suivies</h3></li><br />
                            <li><h3 style={{ color: 'rgb(4,72,108)' }}>Nous créons automatiquement votre profil de compétences professionnelles pour vous rendre visible auprès des entreprises qui recrutent !</h3></li><br />
                            <li><h3 style={{ color: 'rgb(4,72,108)' }}>Vous consultez sur votre tableau de bord les offres d’emploi qui correspondent à votre profil (plus de 250 000 offres mises à jour quotidiennement !)</h3><br />&nbsp;</li><br />
                        </ul>
                        <div className="plain-button p-col-12">
                            <Button label={!isEtudiant ? t("information.savoirplus.etudiant.Boutton1") : t("information.savoirplus.etudiant.Boutton1Alt")} onClick={(e) => onRetourCreationEtudianClick()}></Button></div>
                    </div>
                </div>
                <div className="p-grid savoirplus-etudiant-block">
                    <div className="p-md-12 p-lg-6 p-col-align-center">
                        <p className="text-big-etudiant"><h3 style={{ color: 'rgb(237,106,90)' }}>Vous êtes à la recherche de votre prochaine formation ?</h3></p>
                        <p className="text-big-etudiant"><h3 style={{ color: 'rgb(4,72,108)' }}>Utilisez notre moteur de recherche pour trouver les programmes qui correspondent à vos aspirations pro !</h3></p>

                        <div className="plain-button-trouver-formation">
                        <Link to="/"><Button label={t("information.savoirplus.etudiant.Boutton2")} ></Button></Link>
                        </div>
                    </div>
                    <div className="imagecard-etudiant p-md-12 p-lg-6 p-col-align-center">
                        <img src={Pic3} />
                    </div>
                </div>
                <div className="p-col-12 savoirplus-etudiant-block blk-footer">
                    <div className="accueil-button-etudiant p-col-align-center">
                    <Link to="/"><Button label="Accueil"></Button></Link></div>
                </div>
            </div>
        </div>
    )
}
