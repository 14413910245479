import React, { Component, useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Trans,useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import 'primeflex/primeflex.css';
import AuthService from '../../services/authService';


function Password_Forgot(props) {

    const [displayForgotPW,setDisplayForgotPW] = useState(false);
    const [position,setPosition] = useState('center');
    const [email,setEmail] = useState('');
    const toast = useRef(null);
    const [errorMessage,setErrorMessage] = useState('');
    const [loading,setLoading] = useState(false);
    const { t } = useTranslation();
    const authService = new AuthService();

    const showInfo = () => {
        toast.current.show({ severity: 'info', summary: t('password.mail_reset'), detail: t('general.check_mail') });
    }
    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.try_again') });
    }

    const renderFooter = (name) => {
        return (<div>
            <Button label= {t('general.cancel')} icon="pi pi-times" onClick={() => setDisplayForgotPW(false)} className="p-button-text" />
            <Button label={t('password.reset_password_button')} icon="pi pi-check" onClick={handleForgottenPassword} autoFocus loading={loading} />
        </div>);
    }

    const handleForgottenPassword = () => {
        setLoading(true);
        authService.forgotPassword(email)
            .then(async response => {
                setDisplayForgotPW(false);
                if (response==='') {
                    showInfo();
                }
                else {
                    showError();
                }
                setLoading(false);
            })
            .catch(error => {
                setErrorMessage(error.toString());
                console.error('There was an error!', error);
                setDisplayForgotPW(false);
                showError();
                setLoading(false);
            });
    }

    return (
        <div>
            <Toast ref={toast} />
            <div className='dip-ta-center'>
                <a href="#" onClick={() => setDisplayForgotPW(true)}><Trans i18nKey="login.forgot_password" /></a>
            </div>
            <Dialog header={t('password.reset_password_header')} visible={displayForgotPW} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }} footer={renderFooter('displayResetPW')} onHide={() => setDisplayForgotPW(false)}>
                <div className="p-fluid">
                    <div className="p-field p-fluid">
                        <label htmlFor="email"><Trans i18nKey="login.email" /></label>
                        <InputText name="email" placeholder={t('login.email')} value={email} onChange={(e) => setEmail( e.target.value)} aria-describedby="email-help" />
                        <small id="email-help"><Trans i18nKey="password.help" /></small>
                    </div>
                </div>
            </Dialog>
        </div>
    );


}

export default Password_Forgot;