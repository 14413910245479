import { Button } from "primereact/button";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export const DragNDrop = (props) => {

    // Drag and drop functions
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };
    const grid = 8;
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "#ed6a5a" : "#e6ebe0",

        // styles we need to apply on draggables
        ...draggableStyle
    });
    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? "#c0d8d5" : "white",
        padding: grid,
    });

    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(props.list[sInd], source.index, destination.index);
            const newState = [...props.list];
            newState[sInd] = items;
            props.setList(newState);
        } else {
            const result = move(props.list[sInd], props.list[dInd], source, destination);
            const newState = [...props.list];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];

            props.setList(newState.filter((group) => group.length));
        }
    }





    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {props.list?.map((el, ind) => (
                <Droppable key={ind} droppableId={`${ind}`}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                        >
                            {el?.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <div className="dragdrop-style">
                                                {item.libelle}
                                                <Button
                                                    icon="pi pi-times"
                                                    className="p-button-rounded p-button-danger p-button-text"
                                                    onClick={() => {
                                                        const newState = [...props.list];
                                                        newState[ind].splice(index, 1);
                                                        props.setList(
                                                            newState.filter((group) => group.length)
                                                        );
                                                        if (props.list[ind].length < 1) {
                                                            props.setList([[]])
                                                        }
                                                    }}
                                                >
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            ))}
        </DragDropContext>
    )
}