import { Carousel } from "primereact/carousel"
import { Trans } from "react-i18next"
import "./carouselComponent.css"

export const CarouselComponent = ({value, responsiveOptions, itemTemplate, interval, titreCarousel, numVisible}) => {


    return (
        <>
            <h2 className="dip-ta-center dip-color-eb p-mr-1 p-ml-1"><Trans i18nKey={titreCarousel} /></h2>
            <Carousel
                className="slc-carousel p-m-2"
                value={value}
                responsiveOptions={responsiveOptions}
                circular
                numVisible={numVisible}
                numScroll={1}
                itemTemplate={itemTemplate}
                autoplayInterval={interval}
                page={0}
            />
        </>
    )
}