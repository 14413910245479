import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom'
import { useAuthState } from '../../context/context';
import RoleHelper from '../../utils/roleHelper';
import './headerAccessButtons.css'

export const HeaderAccessButtons = (props) => {
    const currentUser = useAuthState()
    const history = useHistory();
    const { t } = useTranslation();
    const menu = useRef(null);
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;
    const adminImpersonatingEtudiantId = history.location?.state?.adminImpersonatingEtudiantId;

    const itemsPortailCompany = [
        {
            label: t('access.student'),
            command: () => { onEtudiantClick() }
        }
    ]

    const itemsPortailSchool = [
        {
            label: t('access.company'),
            command: () => { onEntrepriseClick() }
        },
        {
            label: t('access.student'),
            command: () => { onEtudiantClick() }
        }
    ]

    const itemsEntreprise = [
        {
            label: t('entreprise.search_candidat'),
            command: () => { onSearchClick()}
        },
        {
            label : t('entreprise.myProjects'),
            commande : () => { onProjectsClick()}
        },
        {
            label: t('entreprise.myOffers'),
            command: () => { onOffersClick() }
        },
        {
            label: t('entreprise.myProfiles'),
            command: () => { onProfilesClick() }
        },
        {
            label: t('entreprise.myFormations'),
            command: () => { onFormationsClick() }
        },
        {
            label: t('general.myAccount.full'),
            command: () => { onMyAccountClick() }
        },
    ]

    const onOffersCompatiblesStudentClick = () => {
        RoleHelper.redirectWithImpersonationCheck(history,'/offresCompatibles', { adminImpersonatingEtudiantId: adminImpersonatingEtudiantId })
    }

    const onCandidaturesStudentClick = () => {
        RoleHelper.redirectWithImpersonationCheck(history,'/candidatures', { adminImpersonatingEtudiantId: adminImpersonatingEtudiantId })
    }

    const onFormationsFavoritesStudentClick = () => {
        RoleHelper.redirectWithImpersonationCheck(history,'/formationsFavorites', { adminImpersonatingEtudiantId: adminImpersonatingEtudiantId })
    }

    const onProfileStudentClick = () => {
        RoleHelper.redirectWithImpersonationCheck(history,'/profile_student', { adminImpersonatingEtudiantId: adminImpersonatingEtudiantId})
    }

    const onOffersClick = () => {
        RoleHelper.redirectWithImpersonationCheck(history,'/offers')
    }

    const onFormationsClick = () => {
        RoleHelper.redirectWithImpersonationCheck(history,'/formationsFavorites')
    }

    const onSearchClick = () => {
        RoleHelper.redirectWithImpersonationCheck(history,'/rechercheAffinee')
    }

    const onProjectsClick = () => {
        RoleHelper.redirectWithImpersonationCheck(history,'/projects')
    }

    const onProfilesClick = () => {
        RoleHelper.redirectWithImpersonationCheck(history,'/seeked_profiles')
    }

    const onEntrepriseClick = () => {
        history.push('/entreprise')
    }

    const onEtudiantClick = () => {
        history.push('/')
    }

    const onMyAccountClick = () => {
        RoleHelper.redirectWithImpersonationCheck(history,'/profile_company')
    }

    return (
        <>
            {history.location.pathname == "/entreprise" || history.location.pathname.toLowerCase() == "/register?user=company" || (history.location.pathname == "/register" && history.location.search.toLowerCase() == "?user=company") ? (
                <>
                    <div className='dip-flex dip-as-center hab-right p-mr-2'>
                        <div className={`hab-pc-container-blue p-mr-5`}>
                            <Link to="/">{t('access.student')}</Link>
                        </div>
                        <div className="hab-mobile-container">
                            <Menu className='menu-small-pop-up' model={itemsPortailCompany} popup ref={menu} id="popup_menu" />
                            <Button className="dip-btn-kashmir-blue-bg" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {history.location.pathname == "/about-schools" ? (
                        <>
                            <div className='dip-flex dip-as-center hab-right p-mr-2'>
                                <div className={`hab-pc-container p-mr-5`}>
                                    <Link className='p-mr-3' to="/entreprise">{t('access.company')}</Link>
                                    <Link to="/">{t('access.student')}</Link>
                                </div>
                                <div className="hab-mobile-container">
                                    <Menu className='menu-small-pop-up' model={itemsPortailSchool} popup ref={menu} id="popup_menu" />
                                    <Button className="dip-btn-kashmir-blue-bg" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
                                </div>
                            </div>
                        </>
                    ) : (<>
                        {RoleHelper.isNotConnected(currentUser) &&
                            <div className="hab-pc-container p-mr-5">
                                <Link className='p-mr-3' to="/entreprise">{t('access.company')}</Link>
                                {/* <Link className='p-mr-3' to="/about-schools">{t('access.school')}</Link> */}
                            </div>}

                        {(RoleHelper.isEtudiant(currentUser) || (adminImpersonatingEtudiantId && RoleHelper.isAdminImpersonatingEtudiant(currentUser, history.location.pathname))) &&
                            <div className="hab-pc-container p-mr-5">
                                <a className='p-mr-6' onClick={onOffersCompatiblesStudentClick}>{t('menu.my_offers')}</a>
                                <a className='p-mr-6' onClick={onCandidaturesStudentClick}>{t('menu.my_candidatures')}</a>
                                <a className='p-mr-6' onClick={onFormationsFavoritesStudentClick}>{t('menu.my_formations')}</a>
                                <a className='p-mr-6' onClick={onProfileStudentClick}>{t('general.myProfile.full')}</a>
                            </div>}

                        {(RoleHelper.isEntreprise(currentUser) || (adminImpersonatingRecruteurId && RoleHelper.isAdminImpersonatingEntreprise(currentUser,history.location.pathname))) && <div className='dip-flex hab-pc-container-entreprise hab-right p-ml-2 p-mr-2'>
                            <div>{/*gestion espace vide pour space-between*/}</div>
                            <div className={`dip-flex hab-pc-container-${props.theme} hab-pc-container-formations-search`}>
                                <a className={`${(history.location.pathname == "/rechercheAffinee") && 'hab-high'}`} onClick={onSearchClick}>{t('entreprise.search_candidat')}</a>
                            </div>
                            <div>{/*gestion espace vide pour space-between*/}</div>
                            <div className={`hab-pc-container-${props.theme} p-mr-5`}>
                                <a className={`p-mr-4 ${(history.location.pathname == "/projects") && 'hab-highlight'}`} onClick={onProjectsClick}>{t('entreprise.myProjects')}</a>
                                <a className={`p-mr-4 ${(history.location.pathname == "/offers" || history.location.pathname == "/candidates" ) && 'hab-highlight'}`} onClick={onOffersClick}>{t('entreprise.myOffers')}</a>
                                <a className={`p-mr-4 ${(history.location.pathname == "/seeked_profiles") && 'hab-highlight'}`} onClick={onProfilesClick}>{t('entreprise.myProfiles')}</a>
                                <a className={`p-mr-4 ${(history.location.pathname == "/formationsFavorites") && 'hab-highlight'}`} onClick={onFormationsClick}>{t('entreprise.myFormations')}</a>
                                <a className={`p-mr-4 ${(history.location.pathname == "/profile_company") && 'hab-highlight'} hab-button-see`} onClick={onMyAccountClick}>{t('general.myAccount.full')}</a>                                
                            </div>
                            <div className="hab-mobile-container">
                                <Menu className='menu-small-pop-up' model={itemsEntreprise} popup ref={menu} id="popup_menu" />
                                <Button icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
                            </div>
                        </div>}
                    </>)}
                </>)}
        </>
    )
}