import { Trans, useTranslation } from "react-i18next"
import { enumStatutFormation } from "../../enums/enumStatutFormation";
import FormationHelper from "../../utils/formationHelper"
import { DisplayImage } from "../image/displayImage";
import Helper from '../../utils/helper';
import "./formationListItem.css"


export const FormationListItem = (props) => {

    const { t } = useTranslation();

    const checkFormation = () => {
        var win = window.open("/course/" + `${props.formation.id}`+ FormationHelper.buildFormationPath(props.formation.composanteEcole[0]?.nom, FormationHelper.nomCompletFormaterUrl(props.formation)))
        win.focus();
    }


    return (
        <div className={props.vertical ? ("formation-list-item-main-container-vertical") : ("formation-list-item-main-container")} onClick={checkFormation}>
            <div>
                {(props.formation.composanteEcole[0]?.logoId || props.formation.composanteEcole[0]?.ecole?.logoId) && <DisplayImage imageId={props.formation.composanteEcole[0]?.logoId ? (
                    props.formation.composanteEcole[0]?.logoId
                    ) : (
                        props.formation.composanteEcole[0]?.ecole?.logoId
                    )} />}
            </div>
            <div className="formation-list-item-info">
                <div className="formation-list-item-composante">
                    <span>{props.formation.composanteEcole[0]?.nom}</span>
                </div>
                <div>
                    <span>{FormationHelper.nomCompletFormater(props.formation, t('formation.parcours_separator'))}</span>
                </div>
                <div className="formation-list-item-obtention">
                    {props.statut == enumStatutFormation.AVENIR && <span className="formation-list-item-a-venir"><Trans i18nKey='profile.formation_not_started'/></span>}
                    {props.statut == enumStatutFormation.ENCOURS && <Trans i18nKey='profile.formationInProgress'/>}
                    {props.statut == enumStatutFormation.SUIVIE && <Trans i18nKey='profile.diploma_passed_in' values={{year:props.anneeDiplome}}/>}
              </div>
            </div>
        </div>
    )
}