import BaseService from './baseService';

export default class CommuneService extends BaseService {

  getCommuneFiltered(query) {
    const options = {
      params: { query },
    }
    return this.axiosApiInstance.get(window.API_URL + '/api/commune/communesFiltered', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getMobiliteAsTree() {
    return this.axiosApiInstance.get(window.API_URL + '/api/commune/GetAllRegionsAndDepartements')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }


}




