import { MultiSelect } from "primereact/multiselect"
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import RechercheService from "../../services/rechercheService";
import Helper from "../../utils/helper";
import { useAuthState } from "../../context/context";
import RoleHelper from "../../utils/roleHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EntrepriseService from "../../services/entrepriseService";

export const FilterMultiSelect = (props) => {

    const { t } = useTranslation()

    const rechercheService = new RechercheService();
    const entrepriseService = new EntrepriseService();


    const currentUser = useAuthState();
    const [options, setOptions] = useState([]);
    const [selectedValues, setSelectedValues] = useState(props.contextRecherche.lazyParams.filters?.filter(o => props.values.includes(o.categorie)).map(v => { return v.value }));
    const [isMounted, setIsMounted] = useState(false)
    const history = useHistory();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;

    useEffect(() => {
        setIsMounted(true)
        loadLazyData();
    }, [])

    useEffect(() => {
        if (isMounted) {
            setSelectedValues([]);
        }
    }, [props.contextRecherche.tokenResetFilters, props.triggerUnselectAll])

    const loadLazyData = () => {
        switch (props.label) {
            case 'diplomeType':
                rechercheService.getRechercheDiplome('', props.contextRecherche.lazyParams)
                    .then((data) => {
                        setOptions(data)
                    })
                break;
            case 'langueType':
                rechercheService.getRechercheLangue('', props.contextRecherche.lazyParams)
                    .then((data) => {
                        setOptions(data)
                    })
                break;
            case 'domaineType':
                rechercheService.getRechercheDipDomaine('', props.contextRecherche.lazyParams)
                    .then((data) => {
                        setOptions(data)
                    })
                break;
            case 'metierType':
                rechercheService.getRechercheDipMetier('', props.contextRecherche.lazyParams)
                    .then((data) => {
                        setOptions(data)
                    })
                break;
            case 'appellationType':
                rechercheService.getRechercheAppellationFunnel('', props.contextRecherche.lazyParams)
                    .then((data) => {
                        setOptions(data)
                    })
                break;
            case 'projetType':
                entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id) => {
                    rechercheService.getRechercheProjetOfEntreprise(id)
                    .then((data) => {
                        setOptions(data)
                    })
                })
                break;
            case 'villeType':
                rechercheService.getRechercheLocalisationOfFormationFavorites()
                    .then((data) => {
                        setOptions(data)
                    })
                break;

            default:
                break;
        }
    }

    const handleRemoveValeur = (valeur) => {
        let _filters = [...props.contextRecherche.lazyParams.filters];
        const indexSupprime = Helper.findIndexByValueAndCategorie(_filters, valeur.value, valeur.categorie);
        _filters.splice(indexSupprime, 1);
        props.contextRecherche.setLazyParams({ ...props.contextRecherche.lazyParams, filters: _filters });

        if (valeur.categorie == props.contextRecherche.categorieRecherche.DipDomaine) {
            let _indexToRemove = [];
            // seek metier and fonctions to remove
            _filters.forEach((element, index) => {
                if (element.categorie == props.contextRecherche.categorieRecherche.DipMetier || element.categorie == props.contextRecherche.categorieRecherche.Appellation || element.categorie == props.contextRecherche.categorieRecherche.CompetenceLiee) {
                    _indexToRemove.push(index)
                }
            });
            //remove existing filter
            for (var i = _indexToRemove.length - 1; i >= 0; i--) {
                _filters.splice(_indexToRemove[i], 1);
            }
            props.setUnselectTokenDipMetier(new Date())
            props.setUnselectTokenAppellation(new Date())
        }
        // si filtre est un metier supprimer les appelations
        if (valeur.categorie == props.contextRecherche.categorieRecherche.DipMetier) {
            let _indexToRemove = [];
            // seek appelation to remove
            _filters.forEach((element, index) => {
                if (element.categorie == props.contextRecherche.categorieRecherche.Appellation || element.categorie == props.contextRecherche.categorieRecherche.CompetenceLiee) {
                    _indexToRemove.push(index)
                }
            });
            //remove existing filter
            for (var i = _indexToRemove.length - 1; i >= 0; i--) {
                _filters.splice(_indexToRemove[i], 1);
            }
            props.setUnselectTokenAppellation(new Date())
        }
        if (valeur.categorie == props.contextRecherche.categorieRecherche.Appellation) {
            let _indexToRemove = [];
            // seek appelation to remove
            _filters.forEach((element, index) => {
                if (element.categorie == props.contextRecherche.categorieRecherche.CompetenceLiee) {
                    _indexToRemove.push(index)
                }
            });
            //remove existing filter
            for (var i = _indexToRemove.length - 1; i >= 0; i--) {
                _filters.splice(_indexToRemove[i], 1);
            }
        }

        const _lazyParams = { ...props.contextRecherche.lazyParams };
        _lazyParams.filters = _filters;
        let _selectedValues = [...selectedValues]
        _selectedValues = _selectedValues.filter(item => item != valeur.value)
        setSelectedValues(_selectedValues);
        props.contextRecherche.setLazyParams(_lazyParams);
        props.contextRecherche.rechercheApi(_lazyParams);
        if (props.onRemoveValeur) {
            props.onRemoveValeur();
        }
    }

    const handleChange = (e) => {
        let _selectedValues = [...selectedValues];
        setSelectedValues(e.value);
        let _mustCleanUp = _selectedValues.length > e.value.length;

        let _seletedLabelValues = options.filter(item => e.value.includes(item.value));
        props.onChange(_seletedLabelValues, _mustCleanUp)
    }

    const searchValues = (e) => {
        let query = e?.filter;
        switch (props.label) {
            case 'domaineType':
                rechercheService.getRechercheDipDomaine(query, props.contextRecherche.lazyParams)
                    .then((data) => {
                        setOptions(data)
                    })
                break;
            case 'metierType':
                rechercheService.getRechercheDipMetier(query, props.contextRecherche.lazyParams)
                    .then((data) => {
                        setOptions(data)
                    })
                break;
            case 'appellationType':
                rechercheService.getRechercheAppellationFunnel(query, props.contextRecherche.lazyParams)
                    .then((data) => {
                        setOptions(data)
                    })
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className={props.className}>
                {RoleHelper.isEntreprise(currentUser) ? (
                    <MultiSelect
                        className='p-mt-2'
                        display="chip"
                        options={options} optionLabel="label"
                        value={selectedValues}
                        placeholder={props.placeholder}
                        onChange={(e) => handleChange(e)}
                        showSelectAll={false}
                        onShow={searchValues}
                    />
                ) : (
                    <>
                        <MultiSelect
                            className='active-search-multi-select'
                            panelClassName='active-search-multi-select-panel'
                            value={selectedValues}
                            options={options} optionLabel="label"
                            placeholder={props.placeholder}
                            onChange={(e) => handleChange(e)}
                            showSelectAll={false}
                            maxSelectedLabels={0}
                            onShow={searchValues}
                            selectedItemsLabel={t('primereact.selectedItemsLabel')}
                        />
                        {props.contextRecherche.lazyParams.filters?.filter(o => props.values.includes(o.categorie)).length > 0 && <div className='p-col-12'>
                            {props.contextRecherche.lazyParams.filters?.filter(o => props.values.includes(o.categorie))?.sort((a, b) => (a.label > b.label) ? 1 : -1).map((valeur, indexValeur) => (
                                <Fragment key={`${valeur}~${indexValeur}`}>
                                    <div className='dms-filter-item'>
                                        <Button
                                            label={valeur?.label}
                                            icon="pi pi-times"
                                            iconPos="right"
                                            className="p-button-rounded p-button-text p-mr-2 dmp-button-style"
                                            onClick={(e) => handleRemoveValeur(valeur)}
                                        />
                                    </div>
                                </Fragment>
                            ))}
                        </div>}
                    </>
                )}
            </div>
        </>
    )
}