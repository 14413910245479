import React, { useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import ProjetService from '../../services/projetService';
import { MultiSelect } from 'primereact/multiselect';
import { Trans, useTranslation } from 'react-i18next';
import "./projetOverlayPanel.css"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EntrepriseService from '../../services/entrepriseService';

export const ProjetOverlayPanel = (props) => {
    const { t } = useTranslation();
    const op = useRef(null);
    const toast = useRef(null);
    const multiSelectRef = useRef(null); // Ajout de la référence pour MultiSelect
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [projetOptions, setProjetOptions] = useState([]);
    const [projetSelected, setProjetSelected] = useState([])
    const [entrepriseId, setEntrepriseId] = useState(null)
    const projetService = new ProjetService();
    const entrepriseService = new EntrepriseService();
    const history = useHistory();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;

    const toggle = (e) => {
        op.current.toggle(e);
        if (op.current.state.visible == false) {
            setLoading(true);
            entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then(id => {
                setEntrepriseId(id)
                if (props.etudiantId) {
                    projetService.getProjetsForEtudiant(props.etudiantId, id)
                        .then((data) => {
                            setProjetOptions(data)
                            let _selectedProject = []
                            data.forEach(p => {
                                if (p.isChecked) {
                                    _selectedProject.push(p.id)
                                }
                            })
                            setProjetSelected(_selectedProject)
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                }
                if (props.formationId) {
                    projetService.getProjetsForFormation(props.formationId, id)
                        .then((data) => {
                            setProjetOptions(data)
                            let _selectedProject = []
                            data.forEach(p => {
                                if (p.isChecked) {
                                    _selectedProject.push(p.id)
                                }
                            })
                            setProjetSelected(_selectedProject)
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                }
                if (props.offreEmploiId) {
                    projetService.getProjetsForOffreEmploi(props.offreEmploiId, id)
                        .then((data) => {
                            setProjetOptions(data)
                            let _selectedProject = []
                            data.forEach(p => {
                                if (p.isChecked) {
                                    _selectedProject.push(p.id)
                                }
                            })
                            setProjetSelected(_selectedProject)
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                }
            })
        }

        setTimeout(() => {
            if (multiSelectRef.current) {
                multiSelectRef.current.show();
            }
        }, 100);
    }

    const updateSelectedProject = (value) => {
        setProjetSelected(value)
        saveProjet(value)
    }

    const saveProjet = (_projetSelected) => {
        setSubmitting(true)
        if (props.etudiantId) {
            projetService.updateProjetEtudiants(props.etudiantId, _projetSelected, entrepriseId)
                .then((data) => {
                    showSuccessEtudiant()
                    op.current.hide()
                })
                .finally(() => {
                    setSubmitting(false)
                    if (props.refreshPage)
                        props.refreshPage(props.lazyParams);
                })
        }
        if (props.formationId) {
            projetService.updateProjetFormations(props.formationId, _projetSelected, entrepriseId)
                .then((data) => {
                    showSuccessFormation()
                    op.current.hide()
                })
                .finally(() => {
                    setSubmitting(false)
                    if (props.refreshPage){
                        props.refreshPage(props.lazyParams);
                    }
                })
        }
        if (props.offreEmploiId) {
            projetService.updateProjetOffreEmplois(props.offreEmploiId, _projetSelected, entrepriseId)
                .then((data) => {
                    showSuccessOffreEmploi()
                    op.current.hide()
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }
    }

    const showSuccessEtudiant = () => {
        toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('project.updated_projects_etudiant'), life: 3000 });
    }

    const showSuccessFormation = () => {
        toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('project.updated_projects_formation'), life: 3000 });
    }

    const showSuccessOffreEmploi = () => {
        toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('project.updated_projects_offreEmploi'), life: 3000 });
    }

    return (
        <div className={props.className}>
            <Toast ref={toast} />
            <Button type="button" className='dip-btn-red-bg p-button-rounded' icon="pi pi-plus" onClick={(e) => toggle(e)} />
            <OverlayPanel className="pop-container" ref={op}>
                {loading ?
                    <div>
                        <ProgressSpinner />
                    </div>
                    :
                    <div>
                        <div><Trans i18nKey="project.select" /></div>
                        <div>
                            <MultiSelect
                                ref={multiSelectRef}
                                className="pop-multiselect"
                                value={projetSelected}
                                options={projetOptions}
                                onChange={(e) => updateSelectedProject(e.value)}
                                optionLabel="nom"
                                optionValue="id"
                                display="chip"
                            />
                        </div>
                    </div>
                }
            </OverlayPanel>
        </div >
    )
}