import { InputSwitch } from "primereact/inputswitch";
import { TreeSelect } from "primereact/treeselect";
import { Trans, useTranslation } from "react-i18next"
import { enumContextContratMultiSelect } from "../../../enums/enumContextContratMultiSelect";
import { ContratMultiSelect } from "../../multiselect/contratMultiSelect";

export const RobContrat = (props) => {
    const { t } = useTranslation()

    const onSelectionChange = (e) => {
        let _tree = [...props.mobiliteTree]
        let _res = { ...e.value };
        if (_res.France?.checked == true) {
            _tree[1].children.forEach(r => {
                r.children.forEach(d => {
                    delete _res[d.key]
                })
                delete _res[r.key]
            });
        }
        Object.entries(_res).forEach(row => {
            if (row[1].checked == true && _tree[1].children?.filter(r => { return r.key == row[0] }).length > 0) {
                let _index = _tree[1].children.map(function (e) { return e.key }).indexOf(row[0])
                _tree[1].children[_index].children.forEach(d => {
                    delete _res[d.key]
                })
            }
        }
        )
        props.setSelectedMobilites(_res)
    }

    const onCheckChange = (value) => {
        let _etudiant = { ...props.etudiant };
        _etudiant.emploiActuel = value;
        props.setEtudiant(_etudiant)
    }

    const onContractChange = (e) => {
        let val = (e.value || e.target.value) || (typeof e?.target?.getContent === "function" && e?.target?.getContent()) || '';
        if (val == '') {
            val = null;
        }
        let _etudiant = { ...props.etudiant };
        _etudiant.paireContrats = val;
        props.setEtudiant(_etudiant);
    };

    return (

        <div className="p-fluid p-grid dip-h-100 rob-contrat-container">
            <div className="p-field-checkbox p-col-12">
                <label htmlFor='emploiActuel' className="field-checkbox sp-checkbox-label dip-ml-0 rob-contrat-font-size">
                    <Trans i18nKey='profile.actual_job' />
                </label>
                <InputSwitch
                    checked={props.etudiant?.emploiActuel}
                    onChange={(e) => onCheckChange(e.value)}
                />
            </div>
            <div className="p-field p-col-12">
                <ContratMultiSelect
                    labelClassName="rob-contrat-font-size"
                    context={enumContextContratMultiSelect.PROFILETUDIANT}
                    label={t('profile.seeked_contract')}
                    value={props.etudiant?.paireContrats}
                    onChange={onContractChange}
                />
                {props.submitted && (props.etudiant?.paireContrats?.length == 0 || !props.etudiant?.paireContrats) && <small className="p-error"><Trans i18nKey="profile.at_least_one_contract" /></small>}
            </div>
            <div className="p-field p-col-12">
                <label className="label-align-margin-right rob-contrat-font-size" htmlFor="mobilite">
                    <Trans i18nKey='profile.mobility.base' />
                </label>
                <TreeSelect className='active-search-tree-select' value={props.selectedMobilites} options={props.mobiliteTree} onChange={(e) => onSelectionChange(e)} metaKeySelection={false} display="chip" selectionMode="checkbox" placeholder={t('profile.pick_mobility')} ></TreeSelect>
                <small className="p-error">{props.submitted && Object.entries(props.selectedMobilites).length == 0 && <Trans i18nKey="profile.at_least_one_mobility" />}</small>
            </div>
        </div>
    )
}