import axios from 'axios';
import BaseService from './baseService';

export default class WysiwygService extends BaseService {

    uploadImageHandler(imageFile, entityType, entityId) {

        return axios.post(window.API_URL + '/api/Wysiwyg/Set/'+entityType+'/'+ entityId , imageFile)
          .then(res => {return res.data})
          .catch(err => super.handleHttpError(err));     

        //   success(json.location);

      }

      removeImageHandler(imageIdArray, success) {
        const formData = new FormData()
        for (var i = 0; i < imageIdArray.length; i++) {
          formData.append('imagesId[]', imageIdArray[i]);
        }
        return axios.post(window.API_URL + '/api/Wysiwyg/Delete', formData)
          .then(res => {return res.data})
          .catch(err => super.handleHttpError(err));     

        //   success(json.location);

      }
      
}
     