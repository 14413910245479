import { Calendar } from "primereact/calendar"
import { Dropdown } from "primereact/dropdown"
import { InputNumber } from "primereact/inputnumber"
import { classNames } from "primereact/utils"
import { useState } from "react"
import { useEffect } from "react"
import { Trans } from "react-i18next"
import "./caracteristiquesContrat.css"
import OffreEmploiService from "../../../services/offreEmploiService"
import RecrutementService from "../../../services/recrutementService"
import EntrepriseService from "../../../services/entrepriseService"
import { useAuthState } from "../../../context/context"
import { enumTypeContratRecrutement } from "../../../enums/enumTypeContratRecrutement"
import Helper from "../../../utils/helper"
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export const CaracteristiquesContrat = (props) => {

    const recrutementService = new RecrutementService()
    const offreEmploiService = new OffreEmploiService()
    const entrepriseService = new EntrepriseService()

    const currentUser = useAuthState()
    const [recruteur, setRecruteur] = useState(null)
    const [typeContratRecrutementOptions, setTypeContratRecrutementOptions] = useState([])
    const [tempsTravailHebdoOptions, setTempsTravailHebdoOptions] = useState([])
    const [teletravailOptions, setTeletravailOptions] = useState([])
    const history = useHistory();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId

    useEffect(() => {
        loadLazyData()
    }, [])

    const loadLazyData = () => {
        recrutementService.getAllTypeContratRecrutement()
            .then(data => {
                entrepriseService.getExtendEntrepriseProfil(adminImpersonatingRecruteurId ? adminImpersonatingRecruteurId : currentUser.user.id, currentUser)
                    .then((_recruteur) => {
                        // on calcule part rapport à la date de création de l'entreprise et _entreprise.abonnementEntreprise.nbSemaineEssai si l'entreprise est en période d'essai ou non
                        let isInPeriodeEssai = Helper.isInPeriodeEssai(_recruteur.creationDate, _recruteur.recruteurEntreprises[0]?.entreprise?.abonnementEntreprise?.nbSemaineEssai);

                        _recruteur.isInPeriodeEssai = isInPeriodeEssai;

                        // si l'entreprise à un abonnement freemium, on ne lui propose tout les types de contrat mais on disable tout sauf le stage
                        //if (_recruteur.recruteurEntreprises[0]?.entreprise?.abonnementEntreprise?.isFree && !isInPeriodeEssai) {
                        //    let typeContratRecrutementOptions = []
                        //    data.forEach(typeContrat => {
                        //        if (typeContrat === enumTypeContratRecrutement.STAGE) {
                        //            typeContratRecrutementOptions.push({label:typeContrat, value: typeContrat, disabled: false })
                        //        } else {
                        //            typeContratRecrutementOptions.push({ label:typeContrat, value: typeContrat, disabled: true })
                        //        }
                        //    })
                        //    setTypeContratRecrutementOptions(typeContratRecrutementOptions)
                        //} else {
                        //    setTypeContratRecrutementOptions(data)
                        //}
						setTypeContratRecrutementOptions(data)
                        setRecruteur(_recruteur);
                    })
            })
        offreEmploiService.getAllTempsTravailHebdo()
            .then(data => {
                setTempsTravailHebdoOptions(data)
            })
        offreEmploiService.getAllTeletravail()
            .then(data => {
                let _tab = []
                data.forEach((e,i) => {
                    _tab.push({label:e,value:i+1})
                });
                setTeletravailOptions(_tab)
            })
    }

    const onCheckTypeContratValid = (e) => {
        // // si l'entreprise à un abonnement freemium, on ne lui propose tout les types de contrat mais on disable tout sauf le stage
        // if (recruteur.recruteurEntreprises[0]?.entreprise?.abonnementEntreprise?.isFree) {
        //     if (e.target.value != enumTypeContratRecrutement.STAGE) {
        //         e.target.value = enumTypeContratRecrutement.STAGE
        //         e.value = enumTypeContratRecrutement.STAGE
        //     }
        // }
        props.onInputChange(e, 'typeContratRecrutement')
    }

    return (
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-sm-12 p-lg-12">
                <h2><Trans i18nKey="advert.proposed_contract" /></h2>
            </div>
            <div className="p-field p-col-12 p-sm-12 p-lg-4">
                <label htmlFor='typeContratRecrutement'><Trans i18nKey='advert.contract' /></label>
                <label>*</label>
                <Dropdown
                    id='typeContratRecrutement'
                    value={props.annonce?.typeContratRecrutement}
                    options={typeContratRecrutementOptions}
                    required
                    onChange={(e) => onCheckTypeContratValid(e)}
                    className={classNames({ 'p-invalid': props.submitted && !props.annonce?.typeContratRecrutement })}
                />
                {props.submitted && !props.annonce?.typeContratRecrutement && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                {/* {(recruteur?.recruteurEntreprises[0]?.entreprise?.abonnementEntreprise?.isFree && !recruteur?.isInPeriodeEssai) &&
                    <i className="cc-italic-text"><Trans i18nKey={"advert.subscription_contract_text"} /> <Link to={{ pathname : '/profile_company', hash: "#abonnement"}}>ici</Link></i>
                } */}
            </div>
            <div className="p-field p-col-12 p-sm-12 p-lg-4">
                <label htmlFor='teletravail'><Trans i18nKey='advert.remote_working.base' /></label>
                <Dropdown id='teletravail'
                    value={props.annonce?.teletravail}
                    options={teletravailOptions}
                    showClear
                    onChange={(e) => props.onInputChange(e, 'teletravail')}
                    required
                />
            </div>
            <div className="p-field p-col-12 p-sm-12 p-lg-4">
                <label htmlFor='tempsTravailHebdo'><Trans i18nKey='advert.weekly_work_time' /></label>
                <Dropdown id='tempsTravailHebdo'
                    value={props.annonce?.tempsTravailHebdo}
                    options={tempsTravailHebdoOptions}
                    onChange={(e) => props.onInputChange(e, 'tempsTravailHebdo')}
                    required
                />
            </div>
            <div className="p-field p-col-12 p-sm-12 p-lg-4">
                <label htmlFor='dateDebutSouhaite'><Trans i18nKey='advert.wished_starting_date' /></label>
                <Calendar id="dateDebutSouhaite"
                    placeholder=''
                    locale="fr"
                    value={props.annonce?.dateDebutSouhaite}
                    onChange={(e) => props.onInputChange(e, 'dateDebutSouhaite')}
                    monthNavigator
                    yearNavigator yearRange="2020:2040"
                    dateFormat='dd/mm/yy'
                />
            </div>
            <div className="p-field p-col-12 p-sm-12 p-lg-4">
                <label className={props.annonce.typeContratRecrutement == 'CDI' && "disabled-label"} htmlFor='dureeContrat'><Trans i18nKey='advert.contract_duration_in_months' /></label>
                <label>*</label>
                <InputNumber id='dureeContrat'
                    value={props.annonce.typeContratRecrutement != 'CDI' ? props.annonce?.dureeContrat : null}
                    onChange={(e) => props.onInputChange(e, 'dureeContrat')}
                    disabled={props.annonce.typeContratRecrutement == 'CDI'}
                    mode="decimal"
                    minFractionDigits={1}
                    maxFractionDigits={1}
                    locale="fr-FR"
                    required
                    className={classNames({ 'p-invalid': props.submitted && !props.annonce?.dureeContrat && props.annonce.typeContratRecrutement != 'CDI' })}
                />
                {props.submitted && !props.annonce?.dureeContrat && props.annonce.typeContratRecrutement != 'CDI' && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
            </div>
        </div>
    )
}