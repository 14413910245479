import { useState, useRef, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CaracteristiquesPoste } from "./caracteristiquesPoste";
import { CaracteristiquesProfilRecherche } from "./caracteristiquesProfilRecherche";
import "./formAnnonceInterne.css";
import { Toast } from 'primereact/toast';
import { useHistory } from "react-router-dom";
import { useAuthState } from "../../../context/context";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { ConfirmDialog } from "primereact/confirmdialog";
import OffreEmploiService from "../../../services/offreEmploiService";
import { ProgressSpinner } from "primereact/progressspinner";
import { LocalisationPoste } from "./localisationPoste";
import { CaracteristiquesContrat } from "./caracteristiquesContrat";
import { PresentationEntrepriseSurOffre } from "./presentationEntrepriseSurOffre";
import EntrepriseService from "../../../services/entrepriseService";

export const FormAnnonceInterne = (props) => {


  const currentUser = useAuthState();
  const offreEmploiService = new OffreEmploiService();
  const entrepriseService = new EntrepriseService();
  const history = useHistory();
  const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)

  const [annonce, setAnnonce] = useState(
    {
      id: 0,
      afficherPresentation: 'true',
      description: null,
      offreEmploiRecrutement: {},
      niveauDiplomeExigence: 1,
      lieuTravail: {
        paysCodeAlpha2: 'FR',
        paysLibelle: 'France',
        libelle: null,
        commune: null,
      },
      salaire: null,
      statut: "Publique",
      experienceExigeOffreEmploi: 1,
      offreEmploiLangue: [],
      competenceOffreEmploi: [],
      offreEmploiAppellations: [],
      raisonArchivage: null,
      teletravail: null
    }
  )
  const [submitted, setSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)

  // const [tokenOuvertureWindow, setTokenOuvertureWindow] = useState(null)
  // const [newTab, setNewTab] = useState(null);

  const toast = useRef(null);



  const itemsSave = [
    {
      label: 'Enregistrer et quitter',
      icon: 'pi pi-sign-out',
      command: () => {
        createOrUpdateAnnonce(false)
      }
    }
  ]

  useEffect(() => {
    if (history.location.state?.id) {
      loadAnnonce(history.location.state.id);
    }
    else {
      loadEntrepriseId();
    }

  }, [])

  const loadEntrepriseId = () => {
    setLoading(true);
    entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then(id => {
      let _annonce = { ...annonce }
      _annonce.entrepriseId = id
      setAnnonce(_annonce);
    })
    .catch((err) => {
      toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_load'), life: 3000 })
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const loadAnnonce = (id) => {
    setLoading(true);
    offreEmploiService.getOffreEmploiEditable(id)
      .then((data) => {
        let _annonce = { ...data }
        if (history.location.state?.duplicate) {
          _annonce = prepareAnnonceForDuplicate(_annonce)
        }
        setupAnnonceAfterGet(_annonce)
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_load'), life: 3000 })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const prepareAnnonceForDuplicate = (data) => {
    data.id = 0;
    data.intitule = t('entreprise.copy_of') + " " + data.intitule;
    data.lieuTravail = {
      paysCodeAlpha2: (data.lieuTravail && data.lieuTravail.paysCodeAlpha2) ? data.lieuTravail.paysCodeAlpha2 : 'FR',
      paysLibelle: (data.lieuTravail && data.lieuTravail.paysLibelle) ? data.lieuTravail.paysLibelle : 'France',
      libelle: (data.lieuTravail && data.lieuTravail.libelle) ? data.lieuTravail.libelle : null,
      commune: (data.lieuTravail && data.lieuTravail.commune) ? data.lieuTravail.commune : null,
    }
    data.salaire = (data.salaire && data.salaire.montantEuro) ? { montantEuro: data.salaire.montantEuro } : null;

    return data;
  }

  const createOrUpdateAnnonce = (visualize) => {
    setSubmitted(true)
    let formIsValid = true;
    annonce.offreEmploiLangue.forEach(l => {
      if (!l.niveau || !l.langue) {
        formIsValid = false;
      }
    });
    if (formIsValid &&
      annonce.intitule &&
      annonce.typeContratRecrutement &&
      (annonce.typeContratRecrutement == "CDI" || annonce.dureeContrat) &&
      annonce.offreEmploiAppellations?.length > 0
    ) {
      toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 })
      setIsSubmitting(true);
      let _annonce = setAnnonceBeforePost()
      offreEmploiService.createOrUpdateOffreEmploi(_annonce)
        .then(data => {
          toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('advert.updated'), life: 3000 });
          setupAnnonceAfterGet(data)
          if (visualize) {
            // setTokenOuvertureWindow(new Date());
            seeOffer(data.id)
          }
          else {
            returnToOffers();
          }
        })
        .catch(err => {
          toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_save'), life: 3000 })
        }).finally(() => {
          setIsSubmitting(false)
          setSubmitted(false)
        })
    }
    else {
      toast.current.show({ severity: 'error', summary: t('errors.incomplete_summary'), detail: t('errors.incomplete_detail'), life: 3000 })
    }
  }

  const setAnnonceBeforePost = () => {
    let _annonce = { ...annonce };
    if (_annonce.afficherPresentation == "true") {
      _annonce.afficherPresentation = true
    }
    else if (_annonce.afficherPresentation == "false") {
      _annonce.afficherPresentation = false
    }
    _annonce.dateDebutSouhaite = _annonce.dateDebutSouhaite != null ? (_annonce.dateDebutSouhaite.toLocaleString("en-US")) : (null);
    return _annonce
  }

  const setupAnnonceAfterGet = (annonce) => {
    if (annonce.dateDebutSouhaite != null) {
      var date = annonce.dateDebutSouhaite;
      annonce.dateDebutSouhaite = new Date(Date.parse(date))
    }
    if (annonce.afficherPresentation == true) {
      annonce.afficherPresentation = "true"
    }
    else if (annonce.afficherPresentation == false) {
      annonce.afficherPresentation = "false"
    }
    setAnnonce(annonce)
  }

  const acceptConfirm = () => {
    returnToOffers();
  }

  const rejectConfirm = () => {
    setConfirmDialogVisible(false);
  }

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || e.value || null;
    let _annonce = { ...annonce };
    if (name == "salaire") {
      if (val == '' || val == null) {
        _annonce.salaire = null
      }
      else {
        if (_annonce.salaire == null) {
          _annonce.salaire = { montantEuro: val }
        }
        else {
          _annonce.salaire.montantEuro = val;
        }
      }
    } else {
      _annonce[`${name}`] = val;
    }
    if (_annonce.typeContratRecrutement == 'CDI') {
      _annonce.dureeContrat = null;
    }
    setAnnonce(_annonce);
  }

  const disabledPresentation = (e) => {
    let _annonce = { ...annonce };
    _annonce.afficherPresentation = e;
    setAnnonce(_annonce);
  }


  const onEditorChange = (content, editor) => {
    let _annonce = { ...annonce };
    _annonce.description = content
    setAnnonce(_annonce)
  }

  const onPaysChange = (e) => {
    const val = (e.target && e.target.value) || null;
    let _annonce = { ...annonce };
    _annonce.lieuTravail.paysCodeAlpha2 = val.alpha2;
    _annonce.lieuTravail.paysLibelle = val.libelle;
    _annonce.lieuTravail.libelle = null;
    _annonce.lieuTravail.commune = null;
    setAnnonce(_annonce);
  }

  const onCityChange = (e) => {
    const val = (e.target && e.target.value) || null;
    let _annonce = { ...annonce };
    if (typeof (val) === 'object' && val != null) {
      _annonce.lieuTravail.libelle = val.fullLibelleCommune;
      _annonce.lieuTravail.commune = val.code;
      _annonce.departementId = val.departementId;
    }
    else {
      _annonce.lieuTravail.libelle = val;
    }
    setAnnonce(_annonce);
  }

  const handleLangueChange = (index, e, name) => {
    let _annonce = { ...annonce };
    let _offreEmploiLangue = [..._annonce.offreEmploiLangue];

    let val = (e.target && e.target.value) || null;

    let _langue = _offreEmploiLangue[index];
    _langue[`${name}`] = val;
    if (name == 'langue') {
      _langue[`${name}Id`] = val?.id;
    }
    _offreEmploiLangue[index] = _langue;
    _annonce.offreEmploiLangue = _offreEmploiLangue;
    setAnnonce(_annonce);
  };

  const handleAddLangue = () => {
    let _annonce = { ...annonce };
    _annonce.offreEmploiLangue.push({ langue: null, niveau: null, exigence: 1, offreEmploiId: annonce.id });
    setAnnonce(_annonce);
  };

  const handleRemoveLangue = index => {
    let _annonce = { ...annonce };
    let _offreEmploiLangue = [..._annonce.offreEmploiLangue];
    _offreEmploiLangue.splice(index, 1);
    _annonce.offreEmploiLangue = _offreEmploiLangue;
    setAnnonce(_annonce);
  };

  const seeOffer = (id) => {
    history.push("/vueOffreEmploi?offreEmploiId=" + id)
  }

  const returnToOffers = () => {
    history.push("/offers")
  }

  return (
    <>
      <Toast ref={toast} />
      {loading ? (
        <div className="fai-loading-container">
          <ProgressSpinner />
        </div>
      ) : (
        <div className={`${isSubmitting && 'fai-disabled'}`}>
          <div className={`form-entreprise-theme with-blue-borders ai-main-container`}>
            <ConfirmDialog
              visible={confirmDialogVisible}
              onHide={() => setConfirmDialogVisible(false)}
              message={t('advert.message.changes_wont_save_confirm')}
              header={t('general.confirm')} icon="pi pi-exclamation-triangle"
              accept={acceptConfirm}
              acceptLabel={t('general.yes')}
              reject={rejectConfirm}
              rejectLabel={t('general.no')}
            />
            <CaracteristiquesPoste
              annonce={annonce}
              setAnnonce={setAnnonce}
              onInputChange={onInputChange}
              onEditorChange={onEditorChange}
              submitted={submitted}
            />
            <LocalisationPoste
              annonce={annonce}
              onPaysChange={onPaysChange}
              onCityChange={onCityChange}
              submitted={submitted}
            />
            <CaracteristiquesProfilRecherche
              annonce={annonce}
              setAnnonce={setAnnonce}
              onInputChange={onInputChange}
              handleLangueChange={handleLangueChange}
              handleAddLangue={handleAddLangue}
              handleRemoveLangue={handleRemoveLangue}
              submitted={submitted}
            />
            <CaracteristiquesContrat
              annonce={annonce}
              onInputChange={onInputChange}
              submitted={submitted}
            />
            <PresentationEntrepriseSurOffre
              annonce={annonce}
              onInputChange={onInputChange}
              disabledPresentation={disabledPresentation}
            />
          </div>
          <div className='p-d-flex p-jc-center p-mt-2'>
            {(annonce.id != 0 ?
              <SplitButton label={t('general.save_and_visualize')} loading={isSubmitting} loadingOptions={{ position: 'right' }} className="frs-button" icon={!isSubmitting ? "pi pi-save" : "p-button-loading-icon pi pi-spinner pi-spin p-button-icon-left"} disabled={isSubmitting} onClick={() => createOrUpdateAnnonce(true)} model={itemsSave} />
              : <Button label={t('general.save_and_visualize')} loading={isSubmitting} loadingOptions={{ position: 'right' }} className="frs-button" icon="pi pi-save" onClick={() => createOrUpdateAnnonce(true)} />
            )}
          </div>
        </div>
      )}
    </>
  )
}