import { Accordion, AccordionTab } from "primereact/accordion";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox } from 'primereact/checkbox';
import FormationService from "../../services/formationService";
import "./dtFormationsDemandees.css"
import { Toast } from "primereact/toast";


export const DtFormationsDemandees = (props) => {

    const { t } = useTranslation();
    const dt = useRef(null)
    const toast = useRef(null);
    const [formationDemandees, setFormationDemandees] = useState(null)
    const [totalRecords, setTotalRecords] = useState(0);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [loading, setLoading] = useState(false);
    const formationService = new FormationService();
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        page: 0,
        sortField: 'dateCreation',
        sortOrder: 1
    });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        formationService.getFormationsDemandees(lazyParams).then(data => {
            setFormationDemandees(data.items);
            setTotalRecords(data.totalCount);
        })
            .catch(err => showError())
            .finally(() => setLoading(false));
    }

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        _lazyParams['page'] = 0;
        _lazyParams['first'] = 0;
        setLazyParams(_lazyParams);
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load_demandes'), life: 3000 });
    }

    const headerTemplate = (
        <div className="table-header">
            <span className="p-input-icon-left dt-fd-search-style">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
            </span>
        </div>
    );

    const tabHeaderTemplate = (
        <label>{loading ? (t("formation.asked_formations_loading")) : (t("formation.asked_formations", { value: totalRecords }))}</label>
    )

    const etudiantTemplate = (rowData) => {
        return (
            <>
                {rowData.etudiant.nom.toUpperCase()} {rowData.etudiant.prenom}
            </>
        )
    }

    const dateTemplate = (rowData) => {
        let _date = new Date(rowData.dateCreation)
        return (
            <>
                {_date.getDate() + "/" + (_date.getMonth() + 1) + "/" + _date.getFullYear()}
            </>
        )
    }

    const formationTemplate = (rowData) => {
        return (
            rowData.formationDemandee
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Checkbox checked={props.selectedDemandeFormation.findIndex(x => x.id === rowData.id) !== -1} onChange={e => props.onChange(e, rowData)} />
            </>
        )
    }

    return (
        <div>
            <Toast ref={toast} />
            <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <AccordionTab header={tabHeaderTemplate}>
                    <DataTable ref={dt} lazy emptyMessage={t('student.no_requests_to_show')} filterDelay={1000} value={formationDemandees}
                        totalRecords={totalRecords} onPage={onPage}
                        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                        onFilter={onFilter} filters={lazyParams.filters} loading={loading}
                        dataKey="id" paginator rows={20} rowsPerPageOptions={[20, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={t('formation.footer_count_pagin')}
                        globalFilter={globalFilter}
                        header={headerTemplate}
                    >
                        <Column field="ecoleDemandee" header={t('formation.school')} sortable></Column>
                        <Column field="diplomeDemandee" header={t('formation.diploma')} sortable></Column>
                        <Column field="formationDemandee" header={t('formation.formation')} body={formationTemplate} sortable></Column>
                        <Column field="etudiant" header={t('general.student')} body={etudiantTemplate} sortable></Column>
                        <Column field="dateCreation" header={t('student.date_entered')} body={dateTemplate} sortable></Column>
                        <Column body={actionBodyTemplate} header={t('formation.link')}></Column>
                    </DataTable>
                </AccordionTab>
            </Accordion>

        </div>
    )
}