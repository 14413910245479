import BaseService from './baseService';

export default class LangueService extends BaseService {
    getNiveauxLangue() {
        return this.axiosApiInstance.get(window.API_URL + '/api/formation/niveaulangue')
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getLangues() {
        return this.axiosApiInstance.get(window.API_URL + '/api/formation/langues')
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getLanguesFiltered(query) {
        const options = {
            params: { query: query }
        }
        return this.axiosApiInstance.get(window.API_URL + '/api/formation/languesFiltered', options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }
}