import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect"
import { ProgressSpinner } from "primereact/progressspinner";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enumContextContratMultiSelect } from "../../enums/enumContextContratMultiSelect";
import ContratService from "../../services/contratService";
import { enumPaireContrat } from "../../enums/enumPaireContrat";

export const ContratProfilMultiSelect = (props) => {

    const {t} = useTranslation()

    const contratService = new ContratService();

    const [ddlContrat, setDdlContrat] = useState([])
    const [ddlLoading, setDdlLoading] = useState(true)

    useEffect(() => {
        loadLazyData();
    }, [])

    const loadLazyData = () => {
        switch (props.context) {
            case enumContextContratMultiSelect.PROFILETUDIANT:
                contratService.getAllContratForProfilEtudiant()
                    .then((data) => {
                        data.forEach(e => {
                            e.libelle = t(`enums.paireContrat.${e.code}`);
                            if(props.value.length==0){
                                // on selectionne les contrats par defaut : Stage, Alternance - contrat d'ap, Alternance - contrat pro, CDD, CDI
                                let e = {value:data.filter(e => e.code == enumPaireContrat.STA || e.code == enumPaireContrat.ALTCA || e.code == enumPaireContrat.ALTCP || e.code == enumPaireContrat.CDD || e.code == enumPaireContrat.CDI)}
                                props.onChange(e,'paireContrats')
                            }
                        });
                        setDdlContrat(data)
                        setDdlLoading(false)
                    })
                break;
            case enumContextContratMultiSelect.ANNONCEINTERNE:
                contratService.getAllContratForAnnonceInterne()
                    .then((data) => {
                        data.forEach(e => {
                            e.libelle = t(`enums.paireContrat.${e.code}`);
                        });
                        setDdlContrat(data)
                        setDdlLoading(false)
                    })
                break;
            case enumContextContratMultiSelect.RECHERCHEAFFINEE:
                contratService.getAllContratForRechercheAffinee()
                    .then((data) => {
                        data.forEach(e => {
                            e.libelle = t(`enums.paireContrat.${e.code}`);
                        });
                        setDdlContrat(data)
                        setDdlLoading(false)
                    })
                break;
            default:
                break;
        }
    }

    const onRemoveValeur = (e, name) => {
        let values = [...props.value]
        values = values.filter((valeur) => valeur.id != e.id)
        let event = { value: values }
        props.onChange(event, name)
    }

    return (
        <>
            {props.label && <label className={props.labelClassName}>{props.label}</label>}
            {ddlLoading ? (<div><ProgressSpinner style={{ width: '1.5rem', height: '1.5rem' }} strokeWidth="8" animationDuration="2s" /></div>) : (<div className="">
                <MultiSelect
                    className='active-search-multi-select'
                    panelClassName='active-search-multi-select-panel'
                    value={props.value}
                    options={ddlContrat} optionLabel="libelle"
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    selectedItemsLabel={t('primereact.selectedItemsLabel')}
                />
                {props.value?.length > 0 && <div className="cpms-filter-button-position">
                    {props.value?.sort((a, b) => (a.libelle > b.libelle) ? 1 : -1).map((valeur, indexValeur) => (
                        <Fragment key={`${valeur}~${indexValeur}`}>
                            <div className='cpms-filter-button-container'>
                                <Button
                                    label={valeur?.libelle}
                                    icon="pi pi-times"
                                    iconPos="right"
                                    className="p-button-rounded p-button-text p-mr-2 cpms-button-style"
                                    onClick={(e) => onRemoveValeur(valeur, 'paireContrats')}
                                />
                            </div>
                        </Fragment>
                    ))}
                </div>}
            </div>)}
        </>
    )
}