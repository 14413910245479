import './mailto.css';

export const Mailto = (props) => {

    const buildMailTo = () => 
    {
        var toRet =  `mailto:${props.to}
        ?subject=${props.subject||props.object}
        &body=${props.body}`;

        if (props.cc != null){
            toRet = toRet.concat(`&cc=${props.cc}`)
        }

        if (props.bcc != null){
            toRet = toRet.concat(`&bcc=${props.bcc}`)
        }

        return toRet;
    }

    return (<>
        <a className={ props.className} href={buildMailTo()} >{props.label}</a>
    </>)
}