import React from 'react';
import './buttonGoBack.css';
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ButtonGoBack = ({ backgroundColor }) => {
    const history = useHistory();
    const { t } = useTranslation();

    function goBack() {
        history.goBack();
    }

    return (
        <Button label={t('general.go_back')} className={`btn-goback-${backgroundColor}`} onClick={() => goBack()} icon="pi pi-arrow-circle-left" iconPos="left" />
    );
}
