import FormationService from "../../services/formationService";
import { IoHeartOutline, IoHeart } from "react-icons/io5";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RoleHelper from "../../utils/roleHelper";
import { useAuthState } from "../../context/context";
import "./likeformation.css";
import EntrepriseService from "../../services/entrepriseService";
import { useHistory } from "react-router-dom";

export const LikeFormation = (props) => {

    const service = new FormationService();
    const entrepriseService = new EntrepriseService();
    const history = useHistory
    const currentUser = useAuthState();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;

    const [liked, setLiked] = useState(props.formation?.isFavorite);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const likeFormation = () => {
        if (RoleHelper.isNotConnected(currentUser)) {
            props.toast.current.show({ severity: 'info', summary: t('role.youre_not_connected_summary'), detail: t('role.youre_not_connected_detail'), life: 3000 })
        }
        else {
            setIsLoading(true)
            if(RoleHelper.isEntreprise(currentUser) || RoleHelper.isAdminImpersonatingEntreprise(currentUser)) {
                entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id) => {
                    service.likeEntrepriseFormation(props.formation.id, id)
                        .then(() => {
                            setLiked(true)
                            props.toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('favorite.formation_liked'), life: 3000 })
                        })
                        .catch(() => props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 }))
                        .finally(() => setIsLoading(false));
                });
            } else {
                service.likeFormation(props.formation.id)
                    .then(() => {
                        setLiked(true)
                        props.toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('favorite.formation_liked'), life: 3000 })
                    })
                    .catch(() => props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 }))
                    .finally(() => setIsLoading(false));
            }
        }

    }

    const unlikeFormation = () => {
        setIsLoading(true)
        if(RoleHelper.isEntreprise(currentUser) || RoleHelper.isAdminImpersonatingEntreprise(currentUser)) {
            entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id) => {
                service.unlikeEntrepriseFormation(props.formation.id, id)
                    .then(() => {
                        setLiked(false)
                        props.toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('favorite.formation_unliked'), life: 3000 })
                        if (props.onUnlike) {
                            props.onUnlike();
                        }
                    })
                    .catch(() => props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 }))
                    .finally(() => setIsLoading(false));
            });
        } else {
            service.unlikeFormation(props.formation.id)
                .then(() => {
                    setLiked(false)
                    props.toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('favorite.formation_unliked'), life: 3000 })
                    if (props.onUnlike) {
                        props.onUnlike();
                    }
                })
                .catch(() => props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 }))
                .finally(() => setIsLoading(false));
        }
    }

    return (
        (RoleHelper.isNotConnected(currentUser) || RoleHelper.isEtudiant(currentUser) || RoleHelper.isEntreprise(currentUser)) ?
            <>
                {isLoading && <i className="p-button p-component p-disabled p-button-rounded p-button-danger p-button-text p-button-icon-only pi pi-spin pi-spinner" style={{ fontSize: "1.2rem" }}></i>}
                {!isLoading && !liked &&
                    <div className="lf-heart-container p-button p-component p-button-rounded p-button-danger p-button-text p-button-icon-only" onClick={likeFormation} style={{ fontSize: '1.2rem' }}>
                        <IoHeartOutline />
                    </div>
                }
                {!isLoading && liked &&

                    <div className="lf-heart-container p-button p-component p-button-rounded p-button-danger p-button-text p-button-icon-only" onClick={unlikeFormation} style={{ fontSize: '1.2rem' }}>
                        <IoHeart />
                    </div>
                }
            </> :
            <>
                <br />
                <br />
            </>
    );

}

