import { AutoComplete } from "primereact/autocomplete"
import { Button } from "primereact/button"
import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { enumStatutFormation } from "../../../../../enums/enumStatutFormation"
import { DiplomeSelector } from "../../../../selector/diplomeSelector"
import { FormationSelector } from "../../../../selector/formationSelector"
import { OrganismeFormationSelector } from "../../../../selector/organismeFormationSelector"
import './formCourse.css'

export const FormCourse = (props) => {

    const { t } = useTranslation();

    const [isOrganismeFormationFound, setIsOrganismeFormationFound] = useState(true)
    const [isOrganismeFormationSelected, setIsOrganismeFormationSelected] = useState(false)
    const [isOrganismeFormationLoading, setIsOrganismeFormationLoading] = useState(false)
    const [isDiplomeFound, setIsDiplomeFound] = useState(true);
    const [isDiplomeSelected, setIsDiplomeSelected] = useState(false);
    const [isDiplomeLoading, setIsDiplomeLoading] = useState(false);
    const [isFormationFound, setIsFormationFound] = useState(true);
    const [isFormationLoading, setIsFormationLoading] = useState(false);
    const [showUrlField, setShowUrlField] = useState(props.currentFormation?.statut ? (true) : (false));
    const currentYear = new Date().getFullYear()
    const years = Array.from({ length: 100 }).map((_, i) => ({ label: `${currentYear - i}`, value: currentYear - i }));

    const statuts = [
        enumStatutFormation.SUIVIE,
        enumStatutFormation.ENCOURS,
        enumStatutFormation.AVENIR
    ]

    useEffect(() => {
        if (typeof (props.currentIndex) === 'number') {
            setIsOrganismeFormationFound(true);
            setIsOrganismeFormationSelected(true);
            setIsDiplomeFound(true);
            setIsDiplomeSelected(true);
            setIsFormationFound(true);
            props.setIsFormationSelected(true);
            if (props.currentFormation.url) {
                setShowUrlField(true);
            }
            else {
                setShowUrlField(false);
            }
        }
        else {
            setIsOrganismeFormationFound(true);
            setIsOrganismeFormationSelected(false);
            setIsDiplomeFound(true);
            setIsDiplomeSelected(false);
            setIsFormationFound(true);
            props.setIsFormationSelected(false);
            if (props.currentFormation?.url) {
                setShowUrlField(true);
            }
            else {
                setShowUrlField(false);
            }
        }
        props.setFormationToAdd(props.currentFormation)
    }, [props.currentFormation, props.currentIndex])


    const onOrganismeFormationContinue = () => {
        setIsOrganismeFormationSelected(true)
        setIsDiplomeSelected(true)
        props.setIsFormationSelected(true)
        setShowUrlField(true)
    }

    const onDiplomeContinue = () => {
        setIsDiplomeSelected(true)
        props.setIsFormationSelected(true)
        setShowUrlField(true)
    }

    const onFormationContinue = () => {
        props.setIsFormationSelected(true)
        setShowUrlField(true)
    }

    const onFormationToAddChange = (val, name) => {
        let _formationToAdd = { ...props.formationToAdd }
        let _isOrganismeFormationSelected = isOrganismeFormationSelected
        let _isDiplomeSelected = isDiplomeSelected
        let _isFormationSelected = props.isFormationSelected
        _formationToAdd[`${name}`] = val
        if (name == 'organismeFormation') {
            _formationToAdd.formation = null;
            _formationToAdd.diplome = null;
            _isDiplomeSelected = false;
            _isFormationSelected = false;
            setIsDiplomeFound(true);
            setIsFormationFound(true);
            if (typeof (val) === 'object') {
                _isOrganismeFormationSelected = true
            }
            else {
                _isOrganismeFormationSelected = false
            }
        }
        if (name == 'diplome') {
            _formationToAdd.formation = null;
            if (_formationToAdd.formation) {
                _isFormationSelected = false;
                setIsFormationFound(true);
            }
            if (typeof (val) === 'object' || (typeof (val) === 'string' && typeof (_formationToAdd.organismeFormation) === 'string')) {
                _isDiplomeSelected = true
            }
            else {
                _isDiplomeSelected = false
            }
        }
        if (name == "formation") {
            if (typeof (val) === 'object') {
                _isFormationSelected = true
            }
            else {
                _isFormationSelected = false
            }
        }
        //showUrlCheck
        if ((typeof (_formationToAdd.organismeFormation) === "string" && _isOrganismeFormationSelected) ||
            (typeof (_formationToAdd.diplome) === "string" && _isDiplomeSelected) ||
            (typeof (_formationToAdd.formation) === "string" && _isFormationSelected)
        ) {
            setShowUrlField(true);
        }
        else {
            setShowUrlField(false);
        }
        setIsOrganismeFormationSelected(_isOrganismeFormationSelected)
        setIsDiplomeSelected(_isDiplomeSelected)
        props.setIsFormationSelected(_isFormationSelected)
        props.setFormationToAdd(_formationToAdd)
    }

    return (
        <div className="formCourse-main-container">
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-sm-12 p-lg-12">
                    <label htmlFor='organisme'>
                        <Trans i18nKey='school.organisme' />*
                    </label>
                    <OrganismeFormationSelector
                        value={props.formationToAdd?.organismeFormation}
                        onChange={val => onFormationToAddChange(val, 'organismeFormation')}
                        onFound={setIsOrganismeFormationFound}
                        onSelect={setIsOrganismeFormationSelected}
                        onLoading={setIsOrganismeFormationLoading}
                    />
                    {!isOrganismeFormationLoading && !isOrganismeFormationSelected && props.formationToAdd?.organismeFormation && <div>
                        {!isOrganismeFormationFound && <div>
                            <small className="p-error">{t('profile.error.organisme_not_found')}</small>
                        </div>}
                        <div className="fcpu-btn-continue">
                            <Button
                                label={t('profile.error.organisme_continue')}
                                onClick={() => onOrganismeFormationContinue(true)}
                            />
                        </div>
                    </div>}
                </div>
                <div className="p-field p-col-12 p-sm-12 p-lg-12">
                    <label className={isOrganismeFormationSelected ? ('') : ("disabled-label")} htmlFor='diplome'>
                        <Trans i18nKey='formation.diploma' />*
                    </label>
                    <DiplomeSelector
                        disabled={!isOrganismeFormationSelected}
                        organismeFormation={props.formationToAdd?.organismeFormation} value={props.formationToAdd?.diplome}
                        onChange={val => onFormationToAddChange(val, 'diplome')}
                        onFound={setIsDiplomeFound}
                        onLoading={setIsDiplomeLoading}
                    />
                    {!isDiplomeLoading && !isDiplomeSelected && props.formationToAdd?.diplome &&
                        <div >
                            {!isDiplomeFound && <div>
                                <small className="p-error">{t('profile.error.diplome_not_found')}</small>
                            </div>}
                            <div className="fcpu-btn-continue">
                                <Button
                                    label={t('profile.error.diplome_continue')}
                                    onClick={() => onDiplomeContinue()}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className="p-field p-col-12 p-sm-12 p-lg-12">
                    <label className={isDiplomeSelected ? ('') : ("disabled-label")} htmlFor='nomFormation'>
                        <Trans i18nKey='formation.formationName' />*
                    </label>
                    <FormationSelector
                        disabled={!isDiplomeSelected}
                        organismeFormation={props.formationToAdd?.organismeFormation}
                        diplome={props.formationToAdd?.diplome}
                        value={props.formationToAdd?.formation}
                        onChange={val => onFormationToAddChange(val, 'formation')}
                        onFound={setIsFormationFound}
                        onLoading={setIsFormationLoading}
                    />
                    {!isFormationLoading && !props.isFormationSelected && props.formationToAdd?.formation &&
                        <div>
                            {!isFormationFound && <div>
                                <small className="p-error">{t('profile.error.formation_not_found')}</small>
                            </div>}
                            <div className="fcpu-btn-continue">
                                <Button
                                    label={t('profile.error.formation_continue')}
                                    onClick={() => onFormationContinue()}
                                />
                            </div>
                        </div>}
                </div>
                {showUrlField && <div className="p-field p-col-12 p-sm-12 p-lg-12">
                    <label htmlFor='webPage'>
                        <Trans i18nKey='profile.formationWebPage' />
                    </label>
                    <InputText value={props.formationToAdd?.url} onChange={e => onFormationToAddChange(e.value, 'url')} />
                </div>}
                <div className="p-col-12 p-sm-12 p-lg-6">
                    <div className={`p-field`}>
                        <label htmlFor='statut'>
                            <Trans i18nKey='profile.formation_status' />*
                        </label>
                        <Dropdown panelClassName="statut-panel-style" value={props.formationToAdd?.statut} appendTo="self" options={statuts} onChange={e => onFormationToAddChange(e.value, 'statut')} />
                    </div>
                </div>
                <div className="p-col-12 p-sm-12 p-lg-6">
                    {props.formationToAdd?.statut == enumStatutFormation.SUIVIE && <div className="p-field">
                        <label htmlFor='anneeObtention'>
                            <Trans i18nKey='profile.obtained_year' />*
                        </label>
                        <Dropdown appendTo="self" value={props.formationToAdd?.anneeDiplome} options={years} onChange={e => onFormationToAddChange(e.value, 'anneeDiplome')} optionLabel="label" />
                    </div>}
                </div>
            </div>
        </div>
    )
}