import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import UserService from '../../services/userService';
import './dt_gestionCollaborateurEntreprise.css';
import EntrepriseService from '../../services/entrepriseService';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

export const DT_gestionCollaborateurEntreprise = (props) => {
    
    const { t } = useTranslation();
    const toast = useRef(null);
    const userService = new UserService();
    const entrepriseService = new EntrepriseService();
    const [emailCollaborateur, setEmailCollaborateur] = useState('');
    const [displayDialog, setDisplayDialog] = useState(false);
    const [collaborateurs, setCollaborateurs] = useState([]);
    const [recruteurToDelete, setRecruteurToDelete] = useState(null);

    useEffect(() => {
        loadLazyData();
    }, []);

    const loadLazyData = () => {
        entrepriseService.getCollaborateursEntreprise(props.entreprise.id).then(data => {
            setCollaborateurs(data);
        });
    }

    const addCollaborateur = () => {
        if (emailCollaborateur === '') {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Veuillez renseigner un email', life: 3000 });
            return;
        }
        let submitCollaborateur = {
            Email: emailCollaborateur,
            NomEntreprise: props.entreprise.nom,
            EntrepriseId : props.entreprise.id
        }
        userService.createCollaborateurInvite(submitCollaborateur).then(data => {
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Email envoyé avec succès', life: 3000 });
            loadLazyData();
        })
        .catch(err => {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de l\'ajout du collaborateur', life: 3000 });
        });
    }

    const handleClickConfirmDelete = (recruteur) => {
        setRecruteurToDelete(recruteur);
        setDisplayDialog(true);
    }

    const onHide = () => {
        setRecruteurToDelete(null);
        setDisplayDialog(false);
    }

    const onDeleteCollaborateur = () => {
        // suppression du collaborateur en base
        entrepriseService.deleteCollaborateurEntreprise(recruteurToDelete.id, props.entreprise.id).then(data => {
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Collaborateur supprimé avec succès', life: 3000 });
            setDisplayDialog(false);
            loadLazyData();
        }
        ).catch(err => {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la suppression du collaborateur', life: 3000 });
        })
        .finally(() => {
            setRecruteurToDelete(null);
        });
    }

    const actionTemplate = (rowsData) => {
        return (
            // si le collaborateur est le propriétaire de l'entreprise, on ne l'affiche pas
            rowsData.isAdmin ? null : <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => handleClickConfirmDelete(rowsData)} />
        );
    }   
    
    return (
        <div className='p-mt-2'>
            <Toast ref={toast}/>
            <Dialog header={t("entreprise.collaborator.delete")} visible={displayDialog} style={{ width: '50vw' }} onHide={() => onHide()} >
                <div className='p-d-flex p-jc-center p-ai-center'>
                    <h3>{t("entreprise.collaborator.delete_confirmation", { collaborateurName: recruteurToDelete?.nom ? recruteurToDelete.nom : '', collaborateurFirstname: recruteurToDelete?.prenom ? recruteurToDelete.prenom : '' })}</h3>
                </div>
                <div className='p-d-flex p-jc-center p-ai-center'>
                    <Button label={t("general.yes")} className='p-button-danger p-mr-2' onClick={onDeleteCollaborateur} />
                    <Button label={t("general.no")} className='p-button-secondary' onClick={() => onHide()} />
                </div>
            </Dialog>
            <h3><Trans i18nKey="entreprise.collaborator.add" /></h3>
            <div className='dgce-add-collaborateur-container'>
                <InputText className='dgce-input-email-container p-mr-2' type="text" placeholder={t("login.email")} onChange={(e) => setEmailCollaborateur(e.target.value)} />
                <Button className='dip-btn-red-bg' label={t("general.add")} onClick={addCollaborateur} />
            </div>
            <h3><Trans i18nKey="entreprise.collaborator.list" /></h3>
            <DataTable value={collaborateurs} paginator rows={10}>
                <Column field="nom" header={t("general.name")}></Column>
                <Column field="prenom" header={t("general.firstname")}></Column>
                <Column field="email" header={t("login.email")} style={{width: '35em' }}></Column>
                <Column field="phoneNumber" header={t("general.phone")}></Column>
                <Column body={actionTemplate} style={{ textAlign: 'center', width: '8em' }}></Column>
            </DataTable>
        </div>
    )
}