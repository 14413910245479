import './secteursPorteurs.css';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import { Button } from "primereact/button";
import immobilier from "../../../images/accueil/pictosSecteursPorteurs/immobilier.png"
import comptabilite from "../../../images/accueil/pictosSecteursPorteurs/comptabilite.png"
import datascience from "../../../images/accueil/pictosSecteursPorteurs/casual-life-3d-artificial-intelligence-scheme.png"
import informatique from "../../../images/accueil/pictosSecteursPorteurs/3d-casual-life-laptop-and-charts.png"
import multimedia from "../../../images/accueil/pictosSecteursPorteurs/3d-casual-life-cloud-storage.png"
import rh from "../../../images/accueil/pictosSecteursPorteurs/rh.png"
import { enumSecteursPorteurs } from '../../../enums/enumSecteursPorteurs';
import MetierService from '../../../services/metierService';
import RechercheService from '../../../services/rechercheService';
import { useEffect } from 'react';
import { DipTag } from '../../../components/tag/dipTag';

export const SecteursPorteurs = (props) => {
    const metierService = new MetierService();
    const rechercheService = new RechercheService();
    const [listeSecteursPorteursComplete, setlisteSecteursPorteursComplete] = useState([]);
    const [formationCount, setFormationCount] = useState([]);
    const history = useHistory();
    const { t } = useTranslation();

    const icons = [
        immobilier,
        comptabilite,
        datascience,
        informatique,
        multimedia,
        rh
    ];

    const listeSecteursPorteurs = [
        enumSecteursPorteurs.IMMOBILIER,
        enumSecteursPorteurs.COMPTABILITE,
        enumSecteursPorteurs.DATASCIENCE,
        enumSecteursPorteurs.INFORMATIQUE,
        enumSecteursPorteurs.MULTIMEDIA,
        enumSecteursPorteurs.RH
    ];

    useEffect(async () => {
        let _listeTemp = []
        for (let i = 0; i < listeSecteursPorteurs.length; i++) {
            const response = await metierService.getSousDomaineByLabel(listeSecteursPorteurs[i])
            _listeTemp.push(response)
        }
        setlisteSecteursPorteursComplete(_listeTemp);
    }, []);

    useEffect(async () => {
        if (listeSecteursPorteursComplete.length) {
            await getFormationCount();
        }
    }, [listeSecteursPorteursComplete])

    const searchSousDomaine = (secteurPorteur) => {
        history.push({ pathname: '/rechercheAffinee', state: { filters: [secteurPorteur] } })
    }


    const getFormationCount = async () => {
        let _listeTempCounter = []

        for (let i = 0; i < listeSecteursPorteursComplete.length; i++) {
            const _lazyParamsDefault = {
                first: 0,
                rows: 6,
                page: 0,
                sortField: 'Pertinence',
                sortOrder: 1,
                filters: [listeSecteursPorteursComplete[i], { categorie: 12, value: true }]
            };
            try {
                const response = await rechercheService.getRechercheAffineeFormationCount(_lazyParamsDefault)
                _listeTempCounter.push({
                    label: listeSecteursPorteursComplete[i].label,
                    counter: response
                });
            }
            catch (e) {
                console.log(e);
            }
        }
        setFormationCount(_listeTempCounter);
    }

    return (
        <div className={`sp-selector-global ${props.className}`}>
            <h2 className="dip-ta-center dip-color-eb p-mr-1 p-ml-1">{t('formation.training_by_theme')}</h2>
            <div className="sp-selector-container">
                {listeSecteursPorteursComplete?.map((secteurPorteur, indexSecteurPorteur) => (


                    <Fragment key={`${secteurPorteur}~${indexSecteurPorteur}`}>
                        <Button className={`sp-selector-button domaine-selector-button-single`} onClick={e => searchSousDomaine(secteurPorteur)} >
                            <div>{icons[indexSecteurPorteur] &&
                                <div className='sp-container-image'>
                                    <img src={icons[indexSecteurPorteur]} alt={t('meta-img.alt-header-composante-formation') + " " + secteurPorteur}></img>
                                </div>}
                                <div className='sp-container-text'>
                                    <div className='sp-text-label'>{secteurPorteur?.label}</div>
                                    {formationCount &&
                                        <div>
                                            <DipTag className="dip-tag-pearl" label={formationCount[indexSecteurPorteur]?.counter + " " + (formationCount[indexSecteurPorteur]?.counter != 0 ? t('formation.formations') : t('profile.formation.singular_lowCase'))} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </Button>
                    </Fragment>
                ))
                }
            </div>
        </div>
    )
};
