import { Card } from "primereact/card"
import './presentationThumbnail.css'
import { Trans } from "react-i18next";
import { WysiwygRenderer } from "../wysiwyg/wysiwygRenderer";

export const PresentationThumbnail = (props) => {

    const header = 
        <h1 className="vue-candidat-card-title">
            <Trans i18nKey='profile.presentation' />
        </h1>
    

    return(
        <Card className="presentation-thumbnail-card p-shadow-5" header={header}>
            <WysiwygRenderer content={props.etudiant.presentation} />
        </Card>
    )
}