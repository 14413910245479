import { Button } from "primereact/button";
import { Trans, useTranslation } from "react-i18next"
import { enumStatutFormation } from "../../enums/enumStatutFormation";
import { DisplayImage } from "../image/displayImage";
import "./formationListItemForStudies.css"


export const FormationListItemForStudies = (props) => {

    const { t } = useTranslation();

    const renderNomFormation = () => {
        return props.formation.nomComplet ? (props.formation.nomComplet) : (
            (
                typeof (props.formation.diplome) === 'object' ? (
                    props.formation.diplome?.nom
                ) : (
                    props.formation.diplome
                )
            ) + ' ' + props.formation.formation)
    }

    return (
        <div className="formation-list-item-fs-main-container">
            <div className="formation-list-item-fs-formation-container">
                <div>
                    {(props.formation.organismeFormation?.logoId || props.formation.organismeFormation?.ecole?.logoId) && <DisplayImage imageId={props.formation.organismeFormation?.logoId ? (
                        props.formation.organismeFormation?.logoId
                    ) : (
                        props.formation.organismeFormation?.ecole?.logoId
                    )} />}
                </div>
                <div>
                    <div className="formation-list-item-fs-composante">
                        <span>{props.formation.organismeFormation?.nom || props.formation.organismeFormation}</span>
                    </div>
                    <div>
                        <span>{renderNomFormation()}</span>
                    </div>
                    <div className="formation-list-item-fs-obtention">
                        {props.formation.statut == enumStatutFormation.AVENIR && <Trans i18nKey='profile.formation_not_started' />}
                        {props.formation.statut == enumStatutFormation.ENCOURS && <Trans i18nKey='profile.formationInProgress' />}
                        {props.formation.statut == enumStatutFormation.SUIVIE && <Trans i18nKey='profile.diploma_passed_in' values={{ year: props.formation.anneeDiplome }} />}

                    </div>
                </div>
            </div>
            <div className="formation-list-item-fs-buttons">
                {props.onEdit &&
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-info p-mr-2"
                        onClick={props.onEdit}
                    />
                }
                {props.onRemove &&
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger p-mr-2"
                        onClick={props.onRemove}
                    />
                }
            </div>
        </div>
    )
}