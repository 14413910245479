import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { Chip } from "primereact/chip"
import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import "./offreEmploiSeekedProfileThumbnail.css"

export const OffreEmploiSeekedProfileThumbnail = (props) => {

    const { t } = useTranslation()

    const [competencesExigees, setCompetencesExigees] = useState([])
    const [competencesSouhaitees, setCompetencesSouhaitees] = useState([])
    const [softSkills, setSoftSkills] = useState([])
    const [languesExigees, setLanguesExigees] = useState([])
    const [languesSouhaitees, setLanguesSouhaitees] = useState([])
    const [showMoreCompetencesExigees, setShowMoreCompetencesExigees] = useState(false);
    const [showMoreCompetencesSouhaitees, setShowCompetencesSouhaitees] = useState(false);
    const [showMoreSoftSkills, setShowMoreSoftSkills] = useState(false);

    useEffect(() => {
        if (props.offreEmploi.providerId == 1) {
            setCompetencesSouhaitees(props.offreEmploi?.competences.sort((a, b) => (a.ordre > b.ordre) ? 1 : -1));
        }
        else {
            setCompetencesExigees(props.offreEmploi?.competences.filter(c => c.exigence == 2));
            setCompetencesSouhaitees(props.offreEmploi?.competences.filter(c => c.exigence == 1));
            setSoftSkills(props.offreEmploi?.qualiteProfessionnelle);
        }
        setLanguesExigees(props.offreEmploi?.offreEmploiLangue.filter(l => l.exigence == 2))
        setLanguesSouhaitees(props.offreEmploi?.offreEmploiLangue.filter(l => l.exigence != 2))
    }, [])


    return (
        <Card className="p-shadow-4 voe-card">
            <h2 className="dip-color-id"><Trans i18nKey="advert.seeked_profile" /></h2>
            <div className="p-grid">
                {(competencesExigees.length > 0 || competencesSouhaitees.length > 0 || softSkills.length > 0) && <>
                    <div className="p-col-12">
                        <h4 className="oespt-title"><Trans i18nKey="skills.skills" /></h4>
                    </div>
                    {competencesExigees.length > 0 && <>
                        <div className="p-col-12 p-sm-12 p-lg-3">
                            <i><Trans i18nKey="requirement.required.f_plural" /></i>
                        </div>
                        <div className="p-col-12 p-sm-12 p-lg-9">
                            {competencesExigees.map((c, indexC) => (
                                <Fragment key={`${c}~${indexC}`}>
                                    {indexC < 5 && <Chip label={c.competence.libelle} className="p-field" ></Chip>}
                                </Fragment>
                            ))}
                            {showMoreCompetencesExigees && competencesExigees.map((c, indexC) => (
                                <Fragment key={`${c}~${indexC}`}>
                                    {indexC >= 5 && <Chip label={c.competence.libelle} className="p-field" ></Chip>}
                                </Fragment>
                            ))}
                            {competencesExigees.length > 5 && showMoreCompetencesExigees==false && <div className="oespt-center"><Button className="p-button-raised dip-btn-red-bg" label={t('general.see_more')} onClick={() => setShowMoreCompetencesExigees(true)} /></div>}
                        </div>
                    </>}

                    {competencesSouhaitees.length > 0 && <>
                        <div className="p-col-12 p-sm-12 p-lg-3">
                            <i><Trans i18nKey="requirement.wished.f_plural" /></i>
                        </div>
                        <div className="p-col-12 p-sm-12 p-lg-9">
                            {competencesSouhaitees.map((c, indexC) => (
                                <Fragment key={`${c}~${indexC}`}>
                                    {indexC < 5 && <Chip label={c.competence.libelle} className="p-field" ></Chip>}
                                </Fragment>
                            ))}
                            {showMoreCompetencesSouhaitees && competencesSouhaitees.map((c, indexC) => (
                                <Fragment key={`${c}~${indexC}`}>
                                    {indexC >= 5 && <Chip label={c.competence.libelle} className="p-field" ></Chip>}
                                </Fragment>
                            ))}
                            {competencesSouhaitees.length > 5 && showMoreCompetencesSouhaitees==false && <div className="oespt-center"><Button className="p-button-raised dip-btn-red-bg" label={t('general.see_more')} onClick={() => setShowCompetencesSouhaitees(true)} /></div>}
                        </div>
                    </>}

                    {softSkills.length > 0 && <>
                        <div className="p-col-12 p-sm-12 p-lg-3">
                            <h4 className="oespt-title"><Trans i18nKey="skills.soft" /></h4>
                        </div>
                        <div className="p-col-12 p-sm-12 p-lg-9">
                            {softSkills.map((c, indexC) => (
                                <Fragment key={`${c}~${indexC}`}>
                                    {indexC < 5 && <Chip label={props.offreEmploi.providerId == 1 ? (c.competence.libelle) : (c.libelle)} className="p-field" ></Chip>}
                                </Fragment>
                            ))}
                            {showMoreSoftSkills && softSkills.map((c, indexC) => (
                                <Fragment key={`${c}~${indexC}`}>
                                    {indexC >= 5 && <Chip label={props.offreEmploi.providerId == 1 ? (c.competence.libelle) : (c.libelle)} className="p-field" ></Chip>}
                                </Fragment>
                            ))}
                            {softSkills.length > 5 && showMoreSoftSkills==false && <div className="oespt-center"><Button className="p-button-raised dip-btn-red-bg" label={t('general.see_more')} onClick={() => setShowMoreSoftSkills(true)} /></div>}
                        </div>
                    </>}
                </>}

                {(languesExigees.length > 0 || languesSouhaitees.length > 0) && <>
                    <div className="p-col-12">
                        <h4 className="oespt-title"><Trans i18nKey="profile.languages" /></h4>
                    </div>
                    {languesExigees.length > 0 && <>
                        <div className="p-col-12 p-sm-12 p-lg-3">
                            <i><Trans i18nKey="requirement.required.f_plural" /></i>
                        </div>
                        <div className="p-col-12 p-sm-12 p-lg-9">
                            <span>
                                {languesExigees.map((l, indexL) => (
                                    <Fragment key={`${l}~${indexL}`}>
                                        <span>{l.langue.libelle}{props.offreEmploi.providerId == 1 && (" (" + l.niveau + ")")} </span>
                                    </Fragment>
                                ))}
                            </span>
                        </div>
                    </>}
                    {languesSouhaitees.length > 0 && <>
                        <div className="p-col-12 p-sm-12 p-lg-3">
                            <i><Trans i18nKey="requirement.wished.f_plural" /></i>
                        </div>
                        <div className="p-col-12 p-sm-12 p-lg-9">
                            <span>
                                {languesSouhaitees.map((l, indexL) => (
                                    <Fragment key={`${l}~${indexL}`}>
                                        <span>{l.langue.libelle}{props.offreEmploi.providerId == 1 && (" (" + l.niveau + ")")} </span>
                                    </Fragment>
                                ))}
                            </span>
                        </div>
                    </>}
                </>}
            </div>
        </Card >
    )
}