
import { SelectButton } from "primereact/selectbutton"
import { Toast } from "primereact/toast"
import { useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { PickListCompetencesSavoir } from "./pickListCompetencesSavoir"
import { PickListCompetencesSavoirFaire } from "./pickListCompetencesSavoirFaire"

export const PickListCompetencesSavoirSavoirFaireSplitted = (props) => {
    const { t } = useTranslation();
    const toast = useRef(null);
    const [globalFilterCompetencesSavoir, setGlobalFilterCompetencesSavoir] = useState(null);
    const [globalFilterCompetencesSavoirFaire, setGlobalFilterCompetencesSavoirFaire] = useState(null);
    const [viewCompetences, setViewCompetences] = useState(t('skills.knowledge'));
    const viewCompetencesOptions = [t('skills.knowledge'), t('skills.expertise')];
    const handleViewCompetencesChange = (value) => {
        setViewCompetences(value);
    }


    return (
        <>
            <Toast ref={toast} />
            <div>
                <div style={{ textAlign: 'center', width: '40rem', margin: "2% auto" }}>
                    <SelectButton value={viewCompetences} options={viewCompetencesOptions} onChange={(e) => handleViewCompetencesChange(e.value)} />
                </div>
                <PickListCompetencesSavoir
                    show={viewCompetences === t('skills.knowledge')}
                    competenceType={props.competenceType}
                    selectedCompSavoir={props.selectedCompSavoir}
                    setSelectedCompSavoir={props.setSelectedCompSavoir}
                    globalFilter={globalFilterCompetencesSavoir}
                    setGlobalFilter={setGlobalFilterCompetencesSavoir}
                    isCompetenceAlreadyInAnOtherList={props.isCompetenceSavoirAlreadyInAnOtherList}
                    onCompSavoirAdd={props.onCompSavoirAdd}
                />
                <PickListCompetencesSavoirFaire
                    show={viewCompetences === t('skills.expertise')}
                    competenceType={props.competenceType}
                    selectedCompSavoirFaire={props.selectedCompSavoirFaire}
                    setSelectedCompSavoirFaire={props.setSelectedCompSavoirFaire}
                    globalFilter={globalFilterCompetencesSavoirFaire}
                    setGlobalFilter={setGlobalFilterCompetencesSavoirFaire}
                    isCompetenceAlreadyInAnOtherList={props.isCompetenceSavoirFaireAlreadyInAnOtherList}
                    onCompSavoirFaireAdd={props.onCompSavoirFaireAdd}
                />
            </div>
        </>
    )
}