import BaseService from "./baseService";

export default class AbonnementService extends BaseService {

  getTypesAbonnement() {
    return this.axiosApiInstance.get(window.API_URL + '/api/abonnement/typesabonnement')
      .then(res => res.data)
      .catch(err => console.log(err));
  }
}




