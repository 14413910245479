import { Card } from "primereact/card"
import { Toast } from "primereact/toast"
import { Fragment, useState, useEffect, useRef } from "react"
import { Trans, useTranslation } from "react-i18next"
import { enumValueError } from "../../enums/enumValueError"
import LangueHelper from "../../utils/langueHelper"
import './langueThumbnail.css'

export const LangueThumbnail = (props) => {

    const {t} = useTranslation();
    const [languesOrdonneesParNiveau, setlanguesOrdonneesParNiveau] = useState([])
    const toast = useRef(null)

    const header =
        <h1 className="vue-candidat-card-title">
            <Trans i18nKey='profile.languages' />
        </h1>

    const showUnrecognizedLanguageLevel = () => {
        toast.current.show({ severity: 'error', summary: t('errors.unrecognized_language_level_summary'), detail: t('errors.unrecognized_language_level_detail'), life: 6000 });
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.unknown'), life: 3000 });
    }

    useEffect(() => {
        try {
            setlanguesOrdonneesParNiveau(LangueHelper.sortLanguesByNiveau(props.etudiant.etudiantLangue))
        } catch (error) {
            switch (error) {
                case enumValueError.UNRECOGNIZED_NIVEAU_LANGUE:
                    showUnrecognizedLanguageLevel();
                    break;
                default:
                    showError();
                    break;
            }
        }

    }, [props.etudiant.etudiantLangue])


    return (
        <>
            <Toast ref={toast} />
            {languesOrdonneesParNiveau.length > 0 && <Card className="p-shadow-5 langue-thumbnail-card" header={header}>
                <div className="langue-thumbnail-main-container">
                    {languesOrdonneesParNiveau.map((l, indexL) => (
                        <Fragment key={`${l.id}~${indexL}`}>
                            <div>
                                <b>{l.langue.libelle}</b>
                                <span> ({l.niveauLangue})</span>
                            </div>
                        </Fragment>
                    ))}
                </div>
            </Card>}
        </>
    )
}