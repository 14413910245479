import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';

import './dt_recrutements.css';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { enumContextEtudiantThumbnail } from '../../enums/enumContextEtudiantThumbnail';

export const DT_Recrutement = (props) => {
    const { t } = useTranslation();
    const [recrutements, setRecrutements] = useState([]);
    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
    const [selectedRecrutements, setSelectedRecrutements] = useState([]);
    const [typeContratFilter, setTypeContratFilter] = useState([]);
    const [selectedTypeContratFilter, setSelectedTypeContratFilter] = useState([]);
    const dt = useRef(null);
    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 5,
        page: 0,
    });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        props.loadLazyData().then(_recrutements => {
            setRecrutements(_recrutements);
            setSelectedRecrutements(_recrutements.filter(etu => etu.isSelected == true))
            setTypeContratFilter([...new Set(_recrutements.map(function (a) { return a.typeContrat; }))])
            setLoading(false);
        });
    }

    const save = () => {
        setLoading(true);
        props.saveData(selectedRecrutements)
            .then(_recrutements => {
                props.onSucessMessage();
                if(props.context == enumContextEtudiantThumbnail.RECRUTEMENTBOARD){
                    props.updateRecrutementDisplayed(selectedRecrutements);
                }
                props.hide();
            })
            .catch((err) => {
                props.onErrorMessage();
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const onSelectedTypeContratFilterChange = (e) => {
        dt.current.filter(e.value, 'typeContrat', 'in');
        setSelectedTypeContratFilter(e.value);
    }

    const onSaveClick = () => {
        if (props.hasConfirmation && selectedRecrutements.filter(r => r.recrutementRecherche != null).length > 0) {
            setConfirmDialogVisible(true)
        }
        else {
            save()
        }
    }

    const typeContratFilter1 = <MultiSelect value={selectedTypeContratFilter} options={typeContratFilter} onChange={onSelectedTypeContratFilterChange} placeholder={t('recruitment.label.contract_type')} className="p-column-filter" />;

    const acceptConfirm = () => {
        save();
    }

    const rejectConfirm = () => {
        setConfirmDialogVisible(false)
    }

    const renderConfirmDialogMessage = () => {
        return props.confirmationMessage(selectedRecrutements);
    }

    return (
        <div>
            {props.hasConfirmation && <ConfirmDialog
                visible={confirmDialogVisible}
                onHide={() => setConfirmDialogVisible(false)}
                message={renderConfirmDialogMessage}
                header={t('general.confirm')}
                icon="pi pi-exclamation-triangle"
                accept={acceptConfirm}
                acceptLabel={t('general.yes')}
                reject={rejectConfirm}
                rejectLabel={t('general.no')}
                acceptClassName="dip-btn-red-bg"
            />}
            <div className="datatable-recrutements">
                <h3>{props.header}</h3>
                <div className="card">
                    <DataTable
                        ref={dt}
                        emptyMessage={t('recruitment.message.no_item_to_show')}
                        value={recrutements}
                        selection={selectedRecrutements}
                        onSelectionChange={(e) => setSelectedRecrutements(e.value)}
                        dataKey="id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={t('recruitment.label.footer_count_pagin')}
                        filterDisplay="row"
                        loading={loading}
                    >
                        <Column field="isSelected" selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="typeContrat" filter filterElement={typeContratFilter1}></Column>
                        <Column field="nom" filter filterPlaceholder={t('recruitment.label.name_of_rct')} filterMatchMode="contains"></Column>
                    </DataTable>
                </div>
                <div className='p-d-flex p-jc-center float-form-button'>
                    <Button label={t('general.save')} icon="pi pi-save" className="frs-button" onClick={onSaveClick} />
                </div>
            </div>
        </div>
    );
}