import BaseService from './baseService';

export default class CompetenceService extends BaseService {

  getPaginated(lazyParams, typeCompetence, cancelToken) {
    let interm = super.getAPIPagedQuery(lazyParams)
    interm.typeCompetence = typeCompetence;
    const queryParams = super.getQueryParams(interm)
    return this.axiosApiInstance.get(window.API_URL + `/api/Competence/getPaginated?${queryParams}`, cancelToken)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  addCompetence(competence) {
    const jsonCompetence = JSON.stringify(competence, null, 2);
    return this.axiosApiInstance.post(window.API_URL + '/api/Competence/AddCompetence', jsonCompetence)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getPaginatedSavoirSavoirFaire(lazyParams, cancelToken) {
    const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))
    return this.axiosApiInstance.get(window.API_URL + `/api/Competence/getPaginatedSavoirSavoirFaire?${queryParams}`, cancelToken)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getAllowedCompetencesSavoirSavoirFaireBasedOnEtudiantId(etudiantId) {
    const options = {
      params: { etudiantId: etudiantId }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/competence/AllowedCompetencesSavoirSavoirFaireBasedOnEtudiantId', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getLinkedCompetencesSavoirSavoirFaireBasedOnFunnelMetier(lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/competence/GetLinkedCompetencesSavoirSavoirFaireBasedOnFunnelMetier', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheCompetenceAjoutee(query, lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query || null }
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/competence/GetRechercheCompetenceAjoutee', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

}




