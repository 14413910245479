import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from "react-i18next"
import { SimpleSearchBar } from './bar/simpleSearchBar';
import { HeaderHome } from './headerHome/headerHome';
import { BodyHomeNotConnected } from './notConnected/bodyHomeNotConnected';
import { HeaderHomeEcole } from './ecole/headerHomeEcole';
import { BodyHomeEcole } from './ecole/bodyHomeEcole';
import { useAuthState } from "../../context/context";
import { Link } from "react-router-dom";
import "./home.css";
import RoleHelper from '../../utils/roleHelper';
import Helper from '../../utils/helper';
import { HeaderHomeEntreprise } from './entreprise/headerHomeEntreprise';
import { BodyHomeEntreprise } from './entreprise/bodyHomeEntreprise';
import StatistiquesService from '../../services/statistiquesService';
import { useHistory } from 'react-router-dom';
import { SecteursPorteurs } from './secteursPorteurs/secteursPorteurs'
import { DomaineSelector } from './courses/domaineSelector';
import { CarouselComponent } from '../../components/carouselComponent/carouselComponent';
import { enumCategorieRechercheAffinee } from "../../enums/enumCategorieRechercheAffinee"
import ComposanteService from "../../services/composanteService"
import OffreEmploiService from '../../services/offreEmploiService';
import { DisplayImage } from "../../components/image/displayImage"
import { LikeOffreEmploi } from '../../components/likeoffreemploi/likeoffreemploi';
import { Toast } from "primereact/toast";
import { Button } from 'primereact/button';
import { JobLocationFormater } from '../../components/formater/jobLocationFormater';
import { DialogInspireMe } from './dialogInspireMe/dialogInspireMe';
import { OffreEmploiThumbnail } from '../../components/thumbnail/offreEmploiThumbnail';
import { DipTag } from '../../components/tag/dipTag';


export const Home = (props) => {

    const { t } = useTranslation();
    const currentUser = useAuthState();
    const history = useHistory();
    const toast = useRef(null)

    const [composanteEcoles, setComposanteEcoles] = useState([]);
    const [offreEmploi, setOffresEmploi] = useState([]);
    const composanteService = new ComposanteService();
    const offreEmploiService = new OffreEmploiService();
    const titreCarouselFormations = "formation.formations_by_school";
    const titreCarouselOffresEmploi = "formation.last_offers";
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if(RoleHelper.isEntreprise(currentUser)){
            // si première connexion grace au register alors state est égal à firstConnection et si c'est la cas, alors on redirige vers /profile_company
            const destination = history.location.state === "firstConnection" ? { pathname: '/profile_company', state: "firstConnection" } : '/offers';
            history.push(destination);
        }
        getComposanteEcoles();
        getOffresEmplois();
    }, [])


    const responsiveOptions = [
        {
            breakpoint: '1120px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '860px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '610px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    function getComposanteEcoles() {
        composanteService.getPartneredSchoolsRandomized()
            .then((composantes) => {
                setComposanteEcoles(composantes)
            })
            .catch()
            .finally(() => {
            })
    }

    function getOffresEmplois() {
        offreEmploiService.getOffreEmploiMatchingFormationLastFifty()
            .then((offreEmploi) => {
                offreEmploi.forEach((offre) => {
                    offre.dateCreation = Helper.dateTimeToLocaleDateTime(offre.dateCreation)
                })
                setOffresEmploi(offreEmploi)
            })
    }


    const searchFormation = (composante) => {
        history.push({ pathname: '/rechercheAffinee', state: { filters: [{ label: composante.nom, value: new String(composante.id), categorie: enumCategorieRechercheAffinee.Composante, type: null }] } })
    }

    const composantesTemplate = (composante) => {
        return (
            <div className="slc-item-container p-shadow-4" onClick={() => searchFormation(composante)}>
                <div className="dip-ta-center dip-bg-color-white slc-container-img-max">
                    <div className="slc-table">
                        <div className="slc-img-container">
                            {composante.logoId ?
                                <DisplayImage imageId={composante.logoId} alt={t('meta-img.alt-logo-organisme-formation') + " " + composante.nom} />
                                :
                                composante.ecole?.logoId ?
                                    <DisplayImage imageId={composante.ecole.logoId} alt={t('meta-img.alt-logo-organisme-formation') + " " + composante.ecole.nom} />
                                    :
                                    <div></div>
                            }
                        </div>
                    </div>
                </div>
                <div className="p-p-2">
                    <DipTag className="dip-tag-orange dip-tag-p-lg" label={composante.nbFormations > 1 ? (t('formation.see_x_courses', { number: composante.nbFormations })) : (composante.nbFormations == 1 ? t('formation.see_course') : t('formation.see_courses'))} />
                </div>
            </div>
        );
    }
    const offresEmploiTemplate = (offreEmploi) => {
        return <OffreEmploiThumbnail offreEmploi={offreEmploi} toast={toast} />
    }

    return (
        <div>
            <Toast ref={toast}></Toast>
            <div className='header-margin-compensator'>
                {(RoleHelper.isNotConnected(currentUser) || RoleHelper.isEtudiant(currentUser)) &&
                    <DialogInspireMe visible={visible} setVisible={setVisible} />
                }
                {RoleHelper.isNotConnected(currentUser) && <HeaderHome setVisible={setVisible} appWidthLimit={props.className} />}
                {(RoleHelper.isAdminEcole(currentUser) || RoleHelper.isAdminComposante(currentUser)) && <HeaderHomeEcole className={props.className} />}
                {RoleHelper.isEtudiant(currentUser) && <HeaderHome appWidthLimit={props.className} setVisible={setVisible}/>}
                {RoleHelper.isEntreprise(currentUser) && <HeaderHomeEntreprise />}
                <br />
                <SimpleSearchBar currentUser={currentUser} className="header-search-section-style" appWithLimit={props.className} />
                <div className={props.className} >
                    {RoleHelper.isNotConnected(currentUser) && <BodyHomeNotConnected />}
                    {(RoleHelper.isAdminEcole(currentUser) || RoleHelper.isAdminComposante(currentUser)) && <BodyHomeEcole />}
                    {RoleHelper.isEntreprise(currentUser) && <BodyHomeEntreprise />}
                    <br />
                    <div className='p-mt-4'>
                        <div className='p-mt-6 p-mb-6'>
                            <SecteursPorteurs appWithLimit={props.className} />
                        </div>
                        <div className='p-mt-6 p-mb-6'>
                            <CarouselComponent itemTemplate={offresEmploiTemplate} responsiveOptions={responsiveOptions} value={offreEmploi} interval={4000} titreCarousel={titreCarouselOffresEmploi} numVisible={4} />
                        </div>
                        <CarouselComponent itemTemplate={composantesTemplate} responsiveOptions={responsiveOptions} value={composanteEcoles} interval={4000} titreCarousel={titreCarouselFormations} numVisible={4} />
                    </div>
                </div>
            </div>
        </div>
    )
}
