import number_1 from "../../../images/accueil/notConnected/number_1.svg"
import number_2 from "../../../images/accueil/notConnected/number_2.svg"
import number_3 from "../../../images/accueil/notConnected/number_3.svg"
import picto_1 from "../../../images/accueil/notConnected/picto_1.png"
import picto_2 from "../../../images/accueil/notConnected/picto_2.png"
import picto_3 from "../../../images/accueil/notConnected/picto_3.png"
import picto_4 from "../../../images/accueil/notConnected/picto_4.png"
import "./letsGoModule.css"
import { Button } from 'primereact/button';
import { Link, useHistory } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"

export const LetsGoModule = () => {

    const history = useHistory()

    const { t } = useTranslation();

    const onClick = () => {
        history.push('/register?User=Student')
    }

    return (
        <div className="dip-flex dip-ta-center lgm-container dip-only-pc">
            <div>
                <div className="lgm-img-container">
                    <img src={number_1} />
                    <img src={picto_1} className="picto-1"/>
                </div>
                <div className="lgm-text-container">
                    <h3 className="dip-mt-0 dip-color-id">{t('lets_go_module.title1')}</h3>
                    <span>{t('lets_go_module.text1')}</span>
                </div>
            </div>
            <div>
                <div className="lgm-img-container">
                    <img src={number_2} />
                    <img src={picto_2} className="picto-2"/>
                </div>
                <div className="lgm-text-container">
                    <h3 className="dip-mt-0 dip-color-id">{t('lets_go_module.title2')}</h3>
                    <span>{t('lets_go_module.text2')}</span>
                </div>
            </div>
            <div>
                <div className="lgm-img-container">
                    <img src={number_3} />
                    <img src={picto_3} className="picto-3"/>
                </div>
                <div className="lgm-text-container">
                    <div>
                        <h3 className="dip-mt-0 dip-mb-0 dip-color-id">
                            {t('lets_go_module.title3_1')}
                        </h3>
                    </div>
                    <div>
                        <h3 className="dip-mt-0 dip-mb-0 dip-color-id">
                            {t('lets_go_module.title3_2')}
                        </h3>
                    </div>
                    <span>{t('lets_go_module.text3')}</span>
                </div>

            </div>
            <div>
                <div className="lgm-img-container lgm-img-last-container">
                    <img src={picto_4} />
                </div>
                <div className="lgm-text-button-container">
                    <h3 className="dip-color-id">{t('lets_go_module.title4')}</h3>
                    <Link to="/register?User=Student">
                        <Button id="link-create-account-homepage" className="dip-btn-iris-bg lgm-button dip-ff-muslish">
                            <Trans i18nKey='general.iCreateMyAccount'/>
                            <i className="pi pi-arrow-right p-pl-2"></i>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
