import React, { useEffect, useState, useRef } from "react"
import { Button } from "primereact/button"
import { Tooltip } from "primereact/tooltip"
import { Toast } from "primereact/toast"
import { TabView, TabPanel } from 'primereact/tabview';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Trans, useTranslation } from "react-i18next"
import UserService from "../../services/userService"
import { useAuthDispatch, useAuthState } from "../../context/context"
import "./formProfile.css"
import { Card } from "primereact/card";
import { PersonnalInformation } from "./formProfileComponents/student/personnalInformation";
import { Studies } from "./formProfileComponents/student/studiesComponents/studies";
import { ActiveSearch } from "./formProfileComponents/student/activeSearch";
import { enumTypeStudentProfile } from "../../enums/enumTypeStudentProfile";
import FormationHelper from "../../utils/formationHelper";
import RoleHelper from "../../utils/roleHelper";
import Helper from "../../utils/helper";
import CommuneService from "../../services/communeService";
import EtudiantService from "../../services/etudiantService";
import { enumStatutFormation } from "../../enums/enumStatutFormation";
import { CompetenceSection } from "./formProfileComponents/student/competenceSection";
import { Menu } from "primereact/menu";
import { enumRaisonArchivage } from "../../enums/enumRaisonArchivage";
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from "primereact/dialog";
import { Wysiwyg } from "../wysiwyg/wysiwyg"
import { useHistory } from "react-router-dom";
import { logout } from '../../context/actions';
import { enumHttpError } from "../../enums/enumHttpError";

export const FormStudentProfile = (props) => {

    const [demandeFormation, setDemandeFormation] = useState([])
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [formIsDirty, setFormIsDirty] = useState(false);
    const [etudiant, setEtudiant] = useState({});
    const [formations, setFormations] = useState([]);
    const [competencesAfficheesDnD, setCompetencesAfficheesDnD] = useState(null)
    const [competencesMasqueesDnD, setCompetencesMasqueesDnD] = useState(null)
    const [refreshToken, setRefreshToken] = useState(null)


    const { t } = useTranslation()
    const toast = useRef(null)
    const menu = useRef(null);
    const history = useHistory();
    const dispatch = useAuthDispatch();
    const currentUser = useAuthState()
    const userService = new UserService()
    const etudiantService = new EtudiantService()
    const communeService = new CommuneService()
    const [adminImpersonatingEtudiantIdExist, setAdminImpersonatingEtudiantIdExist] = useState(false);
    const [loadingContent, setLoadingContent] = useState(true)
    const [dateEtudiant, setDateEtudiant] = useState(null)
    const [mobiliteTree, setMobiliteTree] = useState([])
    const [selectedMobilites, setSelectedMobilites] = useState(null);
    const [activeIndex, setActiveIndex] = useState(localStorage.getItem("adminSeeProfileEtudes") ? enumTypeStudentProfile.ETUDES : enumTypeStudentProfile.INFO_PERSO);
    const [visibleArchivage, setVisibleArchivage] = useState(false);
    const [raisonArchivage, setRaisonArchivage] = useState(null);
    const [detailArchivage, setDetailArchivage] = useState(null);
    const [adminImpersonatingEtudiantId, setAdminImpersonatingEtudiantId] = useState(localStorage.getItem("adminImpersonatingEtudiantId") ? localStorage.getItem("adminImpersonatingEtudiantId") : history.location?.state?.adminImpersonatingEtudiantId ? history.location?.state?.adminImpersonatingEtudiantId : null)


    const itemsMenu = [
        {
            label: RoleHelper.isAdmin(currentUser) ? (t('profile.disable_this_account')) : (t('profile.disable_my_account')),
            command: () => {
                setVisibleArchivage(true)
            }
        }
    ];

    useEffect(() => {
        if (localStorage.getItem("adminImpersonatingEtudiantId") || history.location?.state?.adminImpersonatingEtudiantId) {
            setAdminImpersonatingEtudiantIdExist(true)
            removeLocalStorage()
        }
    }, [])

    useEffect(() => {
        loadData();
    }, [activeIndex, refreshToken])

    const removeLocalStorage = () => {
        localStorage.removeItem("adminSeeProfileEtudes")
        localStorage.removeItem("adminImpersonatingEtudiantId")
    }

    const loadData = () => {
        setLoadingContent(true);
        switch (activeIndex) {
            case enumTypeStudentProfile.INFO_PERSO:
                etudiantService.getEtudiantInfoPerso(adminImpersonatingEtudiantId)
                    .then((e) => {
                        let _etudiant = { ...etudiant }
                        _etudiant.id = e.id;
                        _etudiant.urlCode = e.urlCode;
                        _etudiant.isDisabled = e.isDisabled;
                        _etudiant.isArchived = e.isArchived
                        _etudiant.isInvited = e.isInvited;
                        _etudiant.doitCompleterProfil = e.doitCompleterProfil;
                        _etudiant.nbEditionProfil = e.nbEditionProfil;
                        _etudiant.frequenceReception = e.frequenceReception
                        _etudiant.messagesEcoles = e.messagesEcoles;
                        _etudiant.messagesEntreprises = e.messagesEntreprises;
                        _etudiant.messagesEtudiants = e.messagesEtudiants;
                        _etudiant.prenom = e.prenom;
                        _etudiant.nom = e.nom;
                        _etudiant.prenom = e.prenom;
                        _etudiant.communeId = e.communeId;
                        _etudiant.commune = e.commune;
                        _etudiant.lieuResidence = e.lieuResidence;
                        _etudiant.etranger = e.etranger;
                        _etudiant.pays = e.pays;
                        _etudiant.phoneNumber = e.phoneNumber;
                        _etudiant.email = e.email;
                        _etudiant.document = e.document;
                        _etudiant.fichierPhoto = e.fichierPhoto;
                        _etudiant.fichierCvPdf = e.fichierCvPdf;
                        _etudiant.fichierPortfolio = e.fichierPortfolio;
                        _etudiant.presentation = e.presentation;
                        setEtudiant(_etudiant);
                    })
                    .catch(() => toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_load'), life: 3000 }))
                    .finally(() => setLoadingContent(false))
                break;
            case enumTypeStudentProfile.ETUDES:
                etudiantService.getEtudiantEtudes(adminImpersonatingEtudiantId)
                    .then((e) => {
                        let _etudiant = { ...etudiant }
                        _etudiant.id = e.id;
                        _etudiant.urlCode = e.urlCode;
                        _etudiant.prenom = e.prenom;
                        _etudiant.nom = e.nom;
                        _etudiant.email = e.email;
                        _etudiant.isDisabled = e.isDisabled;
                        _etudiant.isArchived = e.isArchived
                        _etudiant.isInvited = e.isInvited;
                        _etudiant.doitCompleterProfil = e.doitCompleterProfil;
                        _etudiant.nbEditionProfil = e.nbEditionProfil;
                        _etudiant.isDisabled = e.isDisabled;
                        _etudiant.isArchived = e.isArchived
                        _etudiant.isInvited = e.isInvited;
                        _etudiant.niveauEtude = e.niveauEtude;
                        _etudiant.formationSuivie = e.formationSuivie;
                        _etudiant.formationEnCours = e.formationEnCours;
                        _etudiant.formationAVenir = e.formationAVenir
                        _etudiant.demandeFormation = e.demandeFormation;
                        reorderFormationsAfterDownload(_etudiant);
                        setEtudiant(_etudiant);
                    })
                    .catch(() => toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_load'), life: 3000 }))
                    .finally(() => setLoadingContent(false))
                break;
            case enumTypeStudentProfile.COMPETENCES:
                etudiantService.getEtudiantCompetences(adminImpersonatingEtudiantId)
                    .then((e) => {
                        let _etudiant = { ...etudiant }
                        _etudiant.id = e.id;
                        _etudiant.urlCode = e.urlCode;
                        _etudiant.prenom = e.prenom;
                        _etudiant.nom = e.nom;
                        _etudiant.email = e.email;
                        _etudiant.isDisabled = e.isDisabled;
                        _etudiant.isArchived = e.isArchived
                        _etudiant.isInvited = e.isInvited;
                        _etudiant.doitCompleterProfil = e.doitCompleterProfil;
                        _etudiant.nbEditionProfil = e.nbEditionProfil;
                        _etudiant.isDisabled = e.isDisabled;
                        _etudiant.isArchived = e.isArchived
                        _etudiant.isInvited = e.isInvited;
                        _etudiant.etudiantLangue = e.etudiantLangue;
                        _etudiant.etudiantCompetences = e.etudiantCompetences;
                        _etudiant.formationSuivie = e.formationSuivie;
                        _etudiant.formationEnCours = e.formationEnCours;
                        _etudiant.formationAVenir = e.formationAVenir
                        _etudiant.demandeFormation = e.demandeFormation;

                        reorderFormationsAfterDownload(_etudiant);
                        setEtudiant(_etudiant);                        
                    })
                    .catch(() => toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_load'), life: 3000 }))
                    .finally(() => setLoadingContent(false))
                break;
            case enumTypeStudentProfile.SITUATION_PRO:
                communeService.getMobiliteAsTree().then((res) => {
                    setupMobiliteTree(res)
                })
                etudiantService.getEtudiantSituationPro(adminImpersonatingEtudiantId)
                    .then((e) => {
                        let _etudiant = { ...etudiant }
                        _etudiant.id = e.id;
                        _etudiant.urlCode = e.urlCode;
                        _etudiant.prenom = e.prenom;
                        _etudiant.nom = e.nom;
                        _etudiant.email = e.email;
                        _etudiant.isDisabled = e.isDisabled;
                        _etudiant.isArchived = e.isArchived
                        _etudiant.isInvited = e.isInvited;
                        _etudiant.doitCompleterProfil = e.doitCompleterProfil;
                        _etudiant.nbEditionProfil = e.nbEditionProfil;
                        _etudiant.isDisabled = e.isDisabled;
                        _etudiant.isArchived = e.isArchived
                        _etudiant.isInvited = e.isInvited;
                        _etudiant.mobiliteInternationale = e.mobiliteInternationale;
                        _etudiant.mobiliteFrancaise = e.mobiliteFrancaise;
                        _etudiant.mobiliteRegion = e.mobiliteRegion;
                        _etudiant.mobiliteDepartement = e.mobiliteDepartement;
                        _etudiant.rechercheActive = e.rechercheActive;
                        _etudiant.rechercheActiveCalendrier = e.rechercheActiveCalendrier ? new Date(Date.parse(e.rechercheActiveCalendrier)) : null;
                        _etudiant.etudiantAppellations = e.etudiantAppellations;
                        _etudiant.etudiantCompetences = e.etudiantCompetences;
                        _etudiant.emploiActuel = e.emploiActuel;
                        _etudiant.paireContrats = e.paireContrats;
                        _etudiant.paireContrats.forEach(e => {
                            e.libelle = t(`enums.paireContrat.${e.code}`);
                        });
                        setupSelectedMobilites(_etudiant);
                        setEtudiant(_etudiant);
                    })
                    .catch(() => toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_load'), life: 3000 }))
                    .finally(() => setLoadingContent(false))
                break;
            default:
                break;
        }
    }

    const goHome = () => {
        if (RoleHelper.isAdmin(currentUser) || RoleHelper.isSuperAdmin(currentUser)) {
            if(adminImpersonatingEtudiantIdExist){
                history.push('/demandeFormation_etudiants')
            }
            else
                history.push('/etudiants')
        }
        else {
            history.push('/offresCompatibles')
        }
    }

    const setupMobiliteTree = (regions) => {
        let tree = [];
        tree.push({ key: "International", label: "International (hors France)", data: "International" });
        let france = { key: "France", label: "France", data: "France" };
        let childrenFrance = [];
        regions.forEach(r => {
            let region = { key: `${r.id}`, label: r.libelle, data: r.id }
            let childrenRegion = []
            r.departements.forEach(d => {
                let departement = { key: r.id + "-" + d.id, label: d.code + " - " + d.libelle, data: d.id }
                childrenRegion.push(departement);
            });
            region.children = childrenRegion;
            childrenFrance.push(region);
        });
        france.children = childrenFrance;
        tree.push(france);
        setMobiliteTree(tree);
    }

    const setupSelectedMobilites = (_etudiant) => {
        let _selectedMobilites = {}
        if (_etudiant.mobiliteInternationale == true) {
            _selectedMobilites.International = { checked: true, partialChecked: false, label: 'International' }
        }
        if (_etudiant.mobiliteFrancaise == true) {
            _selectedMobilites.France = { checked: true, partialChecked: false, label: 'France' }
        }
        if (_etudiant.mobiliteDepartement.length > 0 || _etudiant.mobiliteRegion.length > 0) {
            _selectedMobilites.France = { checked: false, partialChecked: true, label: 'France' }
        }
        _etudiant.mobiliteRegion.forEach(r => {
            _selectedMobilites[`${r.id}`] = { checked: true, partialChecked: false, label: r.libelle }
        });
        _etudiant.mobiliteDepartement.forEach(d => {
            // a verifier si on récupère bien la région du departement
            _selectedMobilites[`${d.regionId}`] = { checked: false, partialChecked: true, label: d.region?.libelle };
            _selectedMobilites[`${d.regionId}-${d.id}`] = { checked: true, partialChecked: false, label: d.libelle };
        });
        setSelectedMobilites(_selectedMobilites);
    }

    const reorderFormationsAfterDownload = (_etudiant) => {
        setFormations(FormationHelper.unifyAndOrderAllStatutFormation(_etudiant.formationAVenir, _etudiant.formationEnCours, _etudiant.formationSuivie, t("formation.parcours_separator")));
        setDemandeFormation(FormationHelper.unifyDemandeFormation(_etudiant.demandeFormation));
    }

    const reuniteEtudiantData = () => {
        let _etudiant = FormationHelper.dispatchFormationsOnEtudiant({ ...etudiant }, [...formations], [...demandeFormation])
        let _selectedMobilites = { ...selectedMobilites }
        _etudiant.mobiliteInternationale = false;
        _etudiant.mobiliteFrancaise = false;
        let _mobiliteRegion = [];
        let _mobiliteDepartement = [];
        Object.entries(_selectedMobilites).forEach(row => {
            if (row[1].checked == true) {
                if (row[0] == "International") {
                    _etudiant.mobiliteInternationale = true;
                } else if (row[0] == "France") {
                    _etudiant.mobiliteFrancaise = true;
                } else {
                    let parts = row[0].split('-')
                    if (parts.length == 1) {
                        let _id = parseInt(parts[0])
                        _mobiliteRegion.push({ id: _id });
                    }
                    else if (parts.length == 2) {
                        let _id = parseInt(parts[1])
                        _mobiliteDepartement.push({ id: _id });
                    }
                }
            }

        }
        )
        _etudiant.mobiliteRegion = _mobiliteRegion;
        _etudiant.mobiliteDepartement = _mobiliteDepartement;
        setEtudiant(_etudiant);
        return _etudiant;
    }

    const onInputEtudiantChange = (e, name) => {
        setFormIsDirty(true)
        let val = (e.value || e.target.value) || (typeof e?.target?.getContent === "function" && e?.target?.getContent()) || '';
        if (val == '') {
            val = null;
        }
        let _etudiant = { ...etudiant };
        _etudiant[`${name}`] = val;
        if (name == 'commune') {
            if (val?.id) {
                _etudiant.communeId = val.id
            }
            else {
                _etudiant.communeId = null
            }
        }
        setEtudiant(_etudiant);
    };

    const onEditorChange = (content, editor) => {
        setFormIsDirty(true)
        let _etudiant = { ...etudiant };
        _etudiant.presentation = content
        setEtudiant(_etudiant)
    }

    const onInputEtudiantCheckChange = () => {
        setFormIsDirty(true)
    };

    const onInputEtudiantRBChange = (e, name) => {
        setFormIsDirty(true)
        let _etudiant = { ...etudiant };
        _etudiant[`${name}`] = e;
        setEtudiant(_etudiant);
    };

    const updateProfileCompletionStatus = (user) => {
        currentUser.user.doitCompleterProfil = user.doitCompleterProfil
        let _currentUser = JSON.parse(localStorage.getItem("currentUser"))
        _currentUser.user.doitCompleterProfil = user.doitCompleterProfil
        localStorage.setItem("currentUser", JSON.stringify(_currentUser))
    }

    const onVisualizeClick = () => {
        var win = window.open("/Candidat/" + `${etudiant.urlCode}` + "/" + Helper.clearString(etudiant.prenom) + "-" + Helper.clearString(etudiant.nom))
        win.focus();
    }

    // save functions

    const tryMajCompte = (formIsValid, destination, visualize) => {
        if (formIsValid) {
            toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 })
            setIsSubmitting(true);
            let etudiantMaj = reuniteEtudiantData();
            etudiantMaj.rechercheActiveCalendrier = etudiantMaj.rechercheActiveCalendrier != null ? etudiantMaj.rechercheActiveCalendrier.toLocaleString("en-US") : null;
            if (etudiantMaj.id) {
                let promise;
                switch (activeIndex) {
                    case enumTypeStudentProfile.INFO_PERSO:
                        promise = etudiantService.postEtudiantInfoPerso
                        break;
                    case enumTypeStudentProfile.ETUDES:
                        promise = etudiantService.postEtudiantEtudes
                        break;
                    case enumTypeStudentProfile.COMPETENCES:
                        promise = etudiantService.postEtudiantCompetences
                        break;
                    case enumTypeStudentProfile.SITUATION_PRO:
                        promise = etudiantService.postEtudiantSituationPro
                        break;
                    default:
                        break;
                }
                promise(currentUser, etudiantMaj).then((_etudiant) => {
                    if (RoleHelper.isEtudiant(currentUser)) {
                        updateProfileCompletionStatus(_etudiant);
                    }
                    setSubmitted(false);
                    setFormIsDirty(false)
                    toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('profile.account_updated'), life: 3000 });
                    if (typeof (destination) === 'number') {
                        setActiveIndex(destination)
                    }
                    else {
                        setRefreshToken(new Date())
                    }
                    if (visualize) {
                        var win = window.open("/Candidat/" + `${etudiantMaj.urlCode}` + "/" + Helper.clearString(etudiantMaj.prenom) + "-" + Helper.clearString(etudiantMaj.nom))
                        win.focus();
                    }
                })
                    .catch(err => {
                        toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_save'), life: 3000 })
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    })
            }
            else {
                userService.createCompteEtudiantInvite(currentUser, etudiantMaj).then((id) => {
                    if (RoleHelper.isAdmin(currentUser)) {
                        setAdminImpersonatingEtudiantId(id)
                    }
                    setSubmitted(false);
                    setFormIsDirty(false)
                    toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('profile.account_updated'), life: 3000 });
                    if (typeof (destination) === 'number') {
                        setActiveIndex(destination)
                    }
                    else {
                        setRefreshToken(new Date())
                    }
                    if (visualize) {
                        var win = window.open("/Candidat/" + `${etudiantMaj.urlCode}` + "/" + Helper.clearString(etudiantMaj.prenom) + "-" + Helper.clearString(etudiantMaj.nom))
                        win.focus();
                    }
                })
                    .catch(err => {
                        switch (err.message) {
                            case enumHttpError.ALREADYMAILEXIST:
                                toast.current.show({ severity: 'error', summary: t('register.mail_exist'), detail: t('register.mail_exist_detail'), life: 3000 });
                                break;
                            default:
                                toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_save'), life: 3000 })
                                break;
                        }
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    }
                    )
            }
        }
        else {
            toast.current.show({ severity: 'error', summary: t('errors.incomplete_summary'), detail: t('errors.incomplete_detail'), life: 3000 })
        }
    }

    const majForm = (formType, destination, visualize) => {
        setSubmitted(true);
        let formValidationFunction;
        switch (formType) {
            case enumTypeStudentProfile.INFO_PERSO:
                formValidationFunction = isInfoPersoFormValid;
                break;
            case enumTypeStudentProfile.ETUDES:
                formValidationFunction = isEtudesFormValid;
                break;
            case enumTypeStudentProfile.COMPETENCES:
                formValidationFunction = isLanguesFormValid;
                break;
            case enumTypeStudentProfile.SITUATION_PRO:
                formValidationFunction = isSituationProFormValid;
                break;
            default:
                formValidationFunction = () => false;
                break;
        }
        let formIsValid = formValidationFunction();
        if (formIsDirty) {
            tryMajCompte(formIsValid, destination, visualize);
        } else {
            if (typeof destination === 'number' && etudiant?.id) {
                setActiveIndex(destination);
            }
        }
    };

    const isInfoPersoFormValid = () => {
        if (
            typeof etudiant?.nom === 'string' && etudiant.nom.trim() &&
            typeof etudiant?.prenom === 'string' && etudiant.prenom.trim() &&
            typeof etudiant?.email === 'string' && etudiant.email.trim() &&
            ((etudiant.lieuResidence && etudiant.pays) || etudiant.commune)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const isEtudesFormValid = () => {
        let formIsValid = true;
        if (formIsValid) {
            formations.forEach(f => {
                if (f.id == null || (f.statut == enumStatutFormation.SUIVIE && (f.anneeDiplome == null || f.anneeDiplome == 0))) {
                    formIsValid = false
                }
            });
        }
        if (formIsValid) {
            demandeFormation.forEach(f => {
                if (!f.organismeFormation?.trim() || !f.diplome?.trim() || !f.formation?.trim() || (f.statut == enumStatutFormation.SUIVIE && !f.anneeDiplome)) {
                    formIsValid = false;
                }
            });
        }
        return formIsValid
    }

    const isLanguesFormValid = () => {
        let formIsValid = true
        etudiant.etudiantLangue.forEach(el => {
            if (el.langue == '' || el.niveauLangue == '') {
                formIsValid = false;
            }
        });
        return formIsValid
    }

    const isSituationProFormValid = () => {
        if (etudiant.rechercheActive == true &&
            etudiant.paireContrats?.length > 0 &&
            (
                (!etudiant.etudiantAppellations.every(x => x.prisEnCompte == false) || etudiant.etudiantAppellations == null || etudiant.etudiantAppellations.length == 0)
            ) &&
            (Object.entries(selectedMobilites).length > 0 || etudiant.rechercheActive == false) &&
            (etudiant.mobiliteInternationale || etudiant.mobiliteFrancaise || etudiant.mobiliteRegion.length > 0 || etudiant.mobiliteDepartement.length > 0))
        {
            return true
        }
        else {
            if (etudiant.rechercheActive == false) {
                return true
            }
            else {
                return false
            }
        }
    }

    //Formation CRUD

    const handleAddFormation = (formation) => {
        setFormIsDirty(true)
        let _formations = [...formations];
        _formations.push(formation);
        setFormations(_formations);
    }

    const handleEditFormation = (formation, index, doesCurrentFormationExistInDatabaseBeforeUpdate, doesCurrentFormationExistInDatabaseAfterUpdate) => {
        setFormIsDirty(true)
        if (doesCurrentFormationExistInDatabaseBeforeUpdate == doesCurrentFormationExistInDatabaseAfterUpdate) {
            let _formations = [...formations];
            _formations[index] = formation;
            setFormations(_formations);
        }
        else {
            handleRemoveDemandeFormation(index);
            handleAddFormation(formation);
        }
    }

    const handleRemoveFormation = index => {
        setFormIsDirty(true)
        let _formations = [...formations];
        _formations.splice(index, 1);
        setFormations(_formations);
    };

    // DemandeFormation CRUD

    const handleAddDemandeFormation = (formation) => {
        setFormIsDirty(true)
        let _demandeFormations = [...demandeFormation]
        _demandeFormations.push(formation)
        setDemandeFormation(_demandeFormations)
    }

    const handleEditDemandeFormation = (formation, index, doesCurrentFormationExistInDatabaseBeforeUpdate, doesCurrentFormationExistInDatabaseAfterUpdate) => {
        setFormIsDirty(true)
        if (doesCurrentFormationExistInDatabaseBeforeUpdate == doesCurrentFormationExistInDatabaseAfterUpdate) {
            let _demandeFormations = [...demandeFormation]
            _demandeFormations[index] = formation
            setDemandeFormation(_demandeFormations);
        }
        else {
            handleRemoveFormation(index);
            handleAddDemandeFormation(formation);
        }
    }

    const handleRemoveDemandeFormation = index => {
        setFormIsDirty(true)
        let _demandeFormation = [...demandeFormation];
        _demandeFormation.splice(index, 1);
        setDemandeFormation(_demandeFormation);
    };

    const previousStep = () => {
        switch (activeIndex) {
            case 1:
                majForm(enumTypeStudentProfile.ETUDES, activeIndex - 1)
                break;
            case 2:
                majForm(enumTypeStudentProfile.COMPETENCES, activeIndex - 1)
                break;
            case 3:
                majForm(enumTypeStudentProfile.SITUATION_PRO, activeIndex - 1)
                break;
        }
    }

    const nextStep = () => {
        switch (activeIndex) {
            case 0:
                majForm(enumTypeStudentProfile.INFO_PERSO, activeIndex + 1)
                break;
            case 1:
                majForm(enumTypeStudentProfile.ETUDES, activeIndex + 1)
                break;
            case 2:
                majForm(enumTypeStudentProfile.COMPETENCES, activeIndex + 1)
                break;
        }
    }

    const onTabChange = (index) => {
        if (activeIndex != index) {
            switch (activeIndex) {
                case 0:
                    majForm(enumTypeStudentProfile.INFO_PERSO, index)
                    break;
                case 1:
                    majForm(enumTypeStudentProfile.ETUDES, index)
                    break;
                case 2:
                    majForm(enumTypeStudentProfile.COMPETENCES, index)
                    break;
                case 3:
                    majForm(enumTypeStudentProfile.SITUATION_PRO, index)
                    break;
            }
        }
    }

    const acceptArchivage = () => {
        let _archivage = {
            etudiantId: etudiant.id,
            raison: raisonArchivage,
            detail: detailArchivage
        }
        userService.archiverEtudiant(_archivage)
            .then(() => {
                if (RoleHelper.isAdmin(currentUser)) {
                    history.push('/etudiants')
                } else {
                    logout(dispatch);
                    history.push('/');
                }
            })
            .catch(() =>
                toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_save'), life: 3000 })
            )
            .finally(
                onArchivageHide()
            );
    }

    const onArchivageHide = () => {
        setVisibleArchivage(false);
        setRaisonArchivage(null);
        setDetailArchivage(null);
    }

    const onWysiwygChange = (content, editor) => {
        setDetailArchivage(content);
    }

    const onCompetenceAfficheesDndChange = (state) => {
        setFormIsDirty(true)
        setCompetencesAfficheesDnD(state)
    }
    const onCompetenceMasqueesDndChange = (state) => {
        setFormIsDirty(true)
        setCompetencesMasqueesDnD(state)
    }

    const footerArchivage = (
        <div>
            <Button label={t('general.no')} icon="pi pi-times" onClick={onArchivageHide} />
            <Button label={t('general.yes')} disabled={raisonArchivage == null || (raisonArchivage == enumRaisonArchivage.AUTRE && detailArchivage == null)} icon="pi pi-check" onClick={acceptArchivage} />
        </div>
    )

    return (
        <div className={props.className}>
            <Toast ref={toast} />
            <Menu className="menu-small-pop-up" model={itemsMenu} popup ref={menu}></Menu>
            <Dialog visible={visibleArchivage} style={{ width: '180vh' }} onHide={onArchivageHide}
                header={RoleHelper.isAdmin(currentUser) ? (t('profile.disabling_this_account')) : (t('profile.disabling_my_account'))}
                footer={footerArchivage}
            >
                <div className="p-grid">
                    {RoleHelper.isAdmin(currentUser) ? (
                        <div className="p-col-12">
                            <Trans i18nKey={'profile.why_deleting'} />
                        </div>
                    ) : (
                        <div className="p-col-12">
                            <div><Trans i18nKey={'profile.sorry_leaving'} /></div>
                            <div><Trans i18nKey={'profile.why_leaving'} /></div>
                        </div>)}
                    <div className="p-col-12">
                        {Object.values(enumRaisonArchivage).map((raison, index) => (
                            <div className="p-field-radiobutton" key={index}>
                                <RadioButton inputId={raison} name="raisonArchivage" value={raison} onChange={(e) => setRaisonArchivage(e.value)} checked={raisonArchivage === raison} />
                                <label htmlFor={raison}>{raison}</label>
                            </div>
                        ))}
                    </div>
                    {raisonArchivage == enumRaisonArchivage.AUTRE &&
                        <div className="p-col-12">
                            <Wysiwyg value={detailArchivage} onChange={onWysiwygChange} />
                        </div>}
                    <div className="p-col-12">
                        {RoleHelper.isAdmin(currentUser) ? (
                            <div>
                                <Trans i18nKey={'profile.warning_deleting_this_account'} />
                            </div>
                        ) : (
                            <div>
                                <Trans i18nKey={'profile.warning_deleting_my_account'} />
                            </div>
                        )}
                    </div>
                    <div className="p-col-12">
                        <Trans i18nKey={'profile.are_you_sure'} />
                    </div>
                </div>
            </Dialog>
            <div className={`${isSubmitting && 'fsp-disabled'}`}>
                <div className="fsp-main-title">
                    <div><Button label={t('general.return_to_back')} className="btn-goback-whisper" onClick={() => goHome()} icon="pi pi-arrow-circle-left" iconPos="left" /></div>
                    <div>{etudiant.id && <>
                        {!etudiant?.isDisabled && <Button label={t('profile.visualize_my_profile')} className="fsp-visualize-profil" icon="pi pi-eye" onClick={onVisualizeClick} />}
                        {etudiant?.isDisabled && <span className="visualize_my_profile_disabled"><Button disabled label={t('profile.visualize_my_profile')} icon="pi pi-eye" /> </span>}
                        <Tooltip target={".visualize_my_profile_disabled"} position={'bottom'}>
                            <Trans i18nKey='profile.visualize_my_profile_disabled' />
                        </Tooltip>
                        <Button className="p-button-text fsp-ellipsis" icon="pi pi-ellipsis-v" onClick={(e) => menu.current.toggle(e)} />
                    </>}
                    </div>
                </div>
                <TabView className="fsp-tabview" activeIndex={activeIndex} onTabChange={(e) => onTabChange(e.index)}>
                    <TabPanel header={t('profile.personnalInfo')}>
                        <Card className={`fsp-main`}>
                            <PersonnalInformation
                                etudiant={etudiant}
                                setEtudiant={setEtudiant}
                                setFormIsDirty={setFormIsDirty}
                                onInputChange={onInputEtudiantChange}
                                onInputEtudiantRBChange={onInputEtudiantRBChange}
                                onEditorChange={onEditorChange}
                                onSave={majForm}
                                setOnSave={enumTypeStudentProfile.INFO_PERSO}
                                previousStep={previousStep}
                                nextStep={nextStep}
                                submitted={submitted}
                                isSubmitting={isSubmitting}
                                toast={toast}
                                loadingContent={loadingContent}
                            />
                        </Card>
                    </TabPanel>
                    <TabPanel header={t('profile.studies')}>
                        <Card className={`fsp-main`}>
                            <Studies
                                etudiant={etudiant}
                                formations={formations}
                                demandeFormation={demandeFormation}
                                onInputChange={onInputEtudiantChange}
                                handleAddFormation={handleAddFormation}
                                handleAddDemandeFormation={handleAddDemandeFormation}
                                handleEditFormation={handleEditFormation}
                                handleEditDemandeFormation={handleEditDemandeFormation}
                                handleRemoveFormation={handleRemoveFormation}
                                handleRemoveDemandeFormation={handleRemoveDemandeFormation}
                                onSave={majForm}
                                setOnSave={enumTypeStudentProfile.ETUDES}
                                previousStep={previousStep}
                                nextStep={nextStep}
                                submitted={submitted}
                                isSubmitting={isSubmitting}
                                loadingContent={loadingContent}
                            />
                        </Card>
                    </TabPanel>
                    <TabPanel header={t('skills.skills')}>
                        <Card className={`fsp-main`}>
                            <CompetenceSection
                                etudiant={etudiant}
                                formations={formations}
                                setEtudiant={setEtudiant}
                                statesDnD={[competencesAfficheesDnD, competencesMasqueesDnD]}
                                setStatesDnD={[onCompetenceAfficheesDndChange, onCompetenceMasqueesDndChange]}
                                onInputChange={onInputEtudiantChange}
                                setFormIsDirty={setFormIsDirty}
                                onSave={majForm}
                                setOnSave={enumTypeStudentProfile.COMPETENCES}
                                previousStep={previousStep}
                                nextStep={nextStep}
                                submitted={submitted}
                                isSubmitting={isSubmitting}
                                loadingContent={loadingContent}
                            />
                        </Card>
                    </TabPanel>

                    <TabPanel header={t('profile.professionalSituation')}>
                        <Card className={`fsp-main`}>
                            <ActiveSearch
                                etudiant={etudiant}
                                setEtudiant={setEtudiant}
                                setDateEtudiant={setDateEtudiant}
                                onInputEtudiantChange={onInputEtudiantChange}
                                onInputEtudiantCheckChange={onInputEtudiantCheckChange}
                                onInputEtudiantRBChange={onInputEtudiantRBChange}
                                setFormIsDirty={setFormIsDirty}
                                dateEtudiant={dateEtudiant}
                                mobiliteTree={mobiliteTree}
                                selectedMobilites={selectedMobilites}
                                setSelectedMobilites={setSelectedMobilites}
                                previousStep={previousStep}
                                nextStep={nextStep}
                                onSave={majForm}
                                setOnSave={enumTypeStudentProfile.SITUATION_PRO}
                                submitted={submitted}
                                isSubmitting={isSubmitting}
                                loadingContent={loadingContent}
                            />
                        </Card>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    )
}