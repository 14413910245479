import { DT_Administrateur } from "../components/datatable/dt_administrateurs"
import { DT_Editeur } from "../components/datatable/dt_editeurs"
import { DT_CV_Etudiant } from "../components/datatable/dt_cv_etudiants"
import { DT_Etudiant } from "../components/datatable/dt_etudiants"
import { DT_Entreprise } from "../components/datatable/dt_entreprises"
import { DT_DemandeFormation_Etudiant } from "../components/datatable/dt_demandeFormation_etudiants"
import { Advertisements } from "../scenes/advertisements"
import { Companies } from "../scenes/companies"
import { Dashboard } from "../scenes/dashboard/dashboard"
import { Unauthorized } from "../scenes/errors/unauthorized"
import { Home } from "../scenes/home/home"
import { PasswordReset } from "../scenes/passwordReset"
import { ActivateAccount } from "../scenes/activateAccount"
import { ActivateAccountAdmin } from "../scenes/activateAccountAdmin"
import { Students } from "../scenes/students"
import { VueFormation } from "../pages/vueFiche/vueFormation"
import { FormStudentProfile } from "../components/form/formStudentProfile"
import { NotFound } from "../scenes/errors/notFound";
import { OffresCompatibles } from "../scenes/offresCompatibles"
import { RechercheAffinee } from "../scenes/rechercheAffinee/rechercheAffinee"
import { RechercheCandidats } from "../pages/recrutement/rechercheCandidats"
import { DT_Ecoles_Admin } from "../components/datatable/dt_ecoles_admins"
import { VueOffreEmploi } from "../pages/vueFiche/vueOffreEmploi"
import { AdministrationFormationsPage } from "../pages/administration/formation/administrationFormationsPage"
import { AdministrationEcolesPage } from "../pages/administration/ecole/administrationEcolesPage"
import { FormCompanyProfile } from "../components/form/formCompanyProfile"
import { FormationsFavorites } from "../pages/favoris/formationsFavorites"
import { FormationsOrphelinesPage } from "../pages/administration/formation/formationsOrphelinesPage"
import { VueCandidat } from "../pages/vueFiche/vueCandidat"
import { Confidentialite } from "../pages/confidentialite"
import { SavoirPlus_Etudiant } from "../pages/Informations/savoirplus_etudiant"
import { SavoirPlus_Entreprise} from "../pages/Informations/savoirplus_entreprise"
import { SavoirPlus_Ecole} from "../pages/Informations/savoirplus_ecole"
import { AnnonceInterne } from "../pages/entreprise/annonceInterne"
import { EditionFicheEcole } from "../pages/editionFiche/editionFicheEcole"
import { Candidatures } from "../pages/candidatures"
import { VueEcole } from "../pages/vueFiche/vueEcole"
import { AdministrationComposanteEcolesPage } from "../pages/administration/composanteEcole/administrationComposanteEcolesPage"
import { EditionFicheComposante } from "../pages/editionFiche/editionFicheComposante"
import { VueComposanteEcole } from "../pages/vueFiche/vueComposanteEcole"
import { Messagerie } from "../pages/messagerie"
import { AboutUs } from "../pages/Informations/aboutUs"
import { StatistiquesFormation } from "../pages/statistiques/statistiquesFormation"
import { Register } from "../pages/register/register"
import { ImportExportAdminPage } from "../scenes/importExportAdminPage"
import { enumRoles } from "../enums/enumRoles"
import { LandingPageEntreprise } from "../pages/entreprise/landingPageEntreprise"
import { LandingPageEntrepriseUpe06 } from "../pages/entreprise/landingPageEntrepriseUpe06"
import { enumHeaderTheme } from "../enums/enumHeaderTheme"
import { enumBackgroundTheme } from "../enums/enumBackgroundTheme"
import { EntrepriseOffres } from "../pages/entreprise/entrepriseOffres"
import { EntrepriseCandidats } from "../pages/entreprise/entrepriseCandidats"
import { EntrepriseFicheCandidat } from "../pages/entreprise/entrepriseFicheCandidat"
import { ProfilsRecherche } from "../pages/entreprise/profilsRecherche"
import { EntrepriseProjets } from "../pages/entreprise/entrepriseProjets"
import { FormCompanyAbonnementAdmin } from "../components/form/formCompanyAbonnementAdmin"

const routes = [
    {
        path: '/about-us',
        component: AboutUs,
        needsLogin: false,
        hasDashboard : false,
        hasFooter : true,
    },
    {
        path: '/edition_annonce',
        component: AnnonceInterne,
        needsLogin: true,
        hasDashboard : false,
        hasFooter : true,
        headerTheme: enumHeaderTheme.WHITE
    },
    {
        path: '/students',
        component: Students,
        needsLogin: false,
        hasDashboard : false,
    },
    {
        path: '/companies',
        component: Companies,
        needsLogin: false,
        hasDashboard : false,
        hasWidthLimit : true,
    },
    {
        path: '/confidentiality',
        component: Confidentialite,
        needsLogin: false,
        hasDashboard : false,
    },
    {
        path: '/candidatures',
        component: Candidatures,
        needsLogin: true,
        hasDashboard : true,
        hasDashboardOnlyMobile : true,
        headerTheme : enumHeaderTheme.IRIS,
        hasWidthLimit : true,
    },
    {
        path: '/edition_fiche_ecole',
        component: EditionFicheEcole,
        needsLogin: true,
        hasDashboard : true,
    },
    {
        path: '/edition_fiche_composante',
        component: EditionFicheComposante,
        needsLogin: true,
        hasDashboard : true,
    },
    {
        path: '/advertisements',
        component: Advertisements,
        needsLogin: false,
        hasDashboard : false,
    },
    {
        path : '/Candidat/:candidatUrl/:prenomNom?',
        component : VueCandidat,
        needsLogin : false,
        hasDashboard : false,
        className : "whisper-background",
        hasWidthLimit : true,
        headerTheme : enumHeaderTheme.IRIS
    },
    {
        path: '/passwordreset',
        component: PasswordReset,
        needsLogin: false,
        hasDashboard : false,
    },
    {
        path: '/activateaccount',
        component: ActivateAccount,
        needsLogin: false,
        hasDashboard : false,
    },
    {
        path: '/activateaccountadmin',
        component: ActivateAccountAdmin,
        needsLogin: false,
        hasDashboard : false,
    },
    {
        path: '/dashboard',
        component: Dashboard,
        needsLogin: true,
        hasDashboard:true,
        headerTheme : enumHeaderTheme.IRIS
    },
    {
        path: '/profile_student',
        component: FormStudentProfile,
        needsLogin: true,
        hasDashboard : false,
        headerTheme : enumHeaderTheme.IRIS,
        hasWidthLimit : true,
    },
    {
        path: '/profile_company',
        component: FormCompanyProfile,
        needsLogin: true,
        hasDashboard : false,
        headerTheme : enumHeaderTheme.WHITE,
        hasWidthLimit : true
    },
    {
        path: '/subscription_admin_company',
        component: FormCompanyAbonnementAdmin,
        needsLogin: true,
        hasDashboard : false,
    },
    {
        path: '/administrateurs',
        component: DT_Administrateur,
        needsLogin: true,
        hasDashboard : true,
    },
    {
        path: '/administrateursComposanteEtEcole',
        component: DT_Ecoles_Admin,
        needsLogin: true,
        hasDashboard : true,
    },
    {
        path: '/AdminFormations',
        component: AdministrationFormationsPage,
        needsLogin: true,
        hasDashboard : true
    },
    {
        path: '/formationsOrphelines',
        component : FormationsOrphelinesPage,
        needsLogin:true,
        hasDashboard:true
    },
    {
        path : '/formationsFavorites',
        component : FormationsFavorites,
        needsLogin : true,
        hasDashboard : false,
        headerTheme : enumHeaderTheme.IRIS,
        hasWidthLimit : true

    },
    {
        path: '/course/:formationId/:composante?/:desc?',
        component: VueFormation,
        needsLogin: false,
        hasDashboard : false,
        hasFooter : true,
        hasWidthLimit : true,
        headerTheme : enumHeaderTheme.IRIS
    },
    {
        // Doublon de course
        path: '/Formation/:formationId/:composante?/:desc?',
        component: VueFormation,
        needsLogin: false,
        hasDashboard : false,
        hasFooter : true,
        hasWidthLimit : true,
        headerTheme : enumHeaderTheme.IRIS
    },
    {
        path: '/Statistiques/Formation/:formationId/:composante?/:desc?',
        component: StatistiquesFormation,
        needsLogin: true,
        hasDashboard : false,
        hasFooter : false,
        hasWidthLimit : true,
    },
    {
        path: '/vueOffreEmploi',
        component: VueOffreEmploi,
        needsLogin: false,
        hasDashboard : false,
        // className : "main-with-dashboard",
        hasWidthLimit : true,
        headerTheme: enumHeaderTheme.IRIS
    },
    {
        path: '/editeurs',
        component: DT_Editeur,
        needsLogin: true,
        hasDashboard : true
    },
    {
        path: '/cv_etudiants',
        component: DT_CV_Etudiant,
        needsLogin: true,
        hasDashboard : true,
    },
    {
        path: '/etudiants',
        component: DT_Etudiant,
        needsLogin: true,
        hasDashboard : true,
    },
    {
        path: '/entreprises',
        component: DT_Entreprise,
        needsLogin: true,
        hasDashboard : true,
    },
    {
        path: '/demandeFormation_etudiants',
        component: DT_DemandeFormation_Etudiant,
        needsLogin: true,
        hasDashboard : true,
    },
    {
        path: '/ecoles',
        component: AdministrationEcolesPage,
        needsLogin: true,
        hasDashboard : true,
    },
    {
        path: '/composantes',
        component: AdministrationComposanteEcolesPage,
        needsLogin: true,
        hasDashboard : true,
    },
    {
        path : '/school/:Id?',
        component : VueEcole,
        needsLogin : false,
        hasDashboard : false,
        hasWidthLimit : true,
        headerTheme : enumHeaderTheme.IRIS
    },
    {
        path : '/schoolEntity/:Id?',
        component : VueComposanteEcole,
        needsLogin : false,
        hasDashboard : false,
        hasWidthLimit : true,
        headerTheme : enumHeaderTheme.IRIS
    },
    {
        path: '/unauthorized',
        component: Unauthorized,
        needsLogin: false,
        hasDashboard : false,
    },
    {
        path: '/register:User?',
        component: Register,
        needsLogin: false,
        hasDashboard : false,
        hasWidthLimit : true,
        headerTheme : enumHeaderTheme.IRIS,
        backgroundTheme: enumBackgroundTheme.WHISPER
    },
    {
        path: '/login:User?',
        component: Register,
        needsLogin: false,
        hasDashboard : false,
        hasWidthLimit : true,
        headerTheme : enumHeaderTheme.IRIS,
        backgroundTheme: enumBackgroundTheme.WHISPER
    },
    {
        path: '/import_export',
        component: ImportExportAdminPage,
        needsLogin: true,
        hasDashboard : true,
    },
    {
        path: '/offresCompatibles',
        component: OffresCompatibles,
        needsLogin: true,
        hasDashboard : true,
        hasDashboardOnlyMobile : true,
        headerTheme : enumHeaderTheme.IRIS,
        hasWidthLimit : true,
    },
    {
        path: '/rechercheAffinee:Filters?',
        component: RechercheAffinee,
        needsLogin: false,
        hasDashboard : false,
        hasWidthLimit : true,
        hasFooter : true,
        className: "whisper-background",
        headerTheme : enumHeaderTheme.IRIS,
        backgroundTheme: enumBackgroundTheme.WHISPER
    },
    {
        path: '/rechercheCandidats',
        component: RechercheCandidats,
        needsLogin: false,
        needsRoles: [enumRoles.ENTREPRISE],
        hasDashboard : false,
        hasFooter : true,
    },
    {
        path: '/about-student',
        component: SavoirPlus_Etudiant,
        needsLogin: false,
        hasDashboard : false,
        hasFooter : true,
        hasWidthLimit : true,
    },
    {
        path: '/about-companies',
        component: SavoirPlus_Entreprise,
        needsLogin: false,
        hasDashboard : false,
        hasFooter : true,
        hasWidthLimit : true,
    },
    {
        path: '/about-schools',
        component: SavoirPlus_Ecole,
        needsLogin: false,
        hasDashboard : false,
        hasFooter : true,
        hasWidthLimit : true,
        headerTheme : enumHeaderTheme.IRIS
    },
    {
        path : '/Messagerie:Thread?',
        component : Messagerie,
        needsLogin : true,
        hasDashboard : true
    },
    {
        path : '/Chat:Thread?',
        component : Messagerie,
        needsLogin : true,
        hasDashboard : true
    },
    {
        path : '/entreprise',
        component : LandingPageEntreprise,
        needsLogin : false,
        hasDashboard : false,
        hasFooter : true,
        hasWidthLimit : true,
        headerTheme : enumHeaderTheme.BLUE
    },
    {
        path : '/partner/UPE06',
        component : LandingPageEntrepriseUpe06,
        needsLogin : false,
        hasDashboard : false,
        hasFooter : true,
        hasWidthLimit : true,
        headerTheme : enumHeaderTheme.BLUE
    },
    {
        path : '/offers',
        component : EntrepriseOffres,
        hasDashboard : false,
        needsLogin: true,
        needsRoles: [enumRoles.ENTREPRISE],
        hasFooter : true,
        headerTheme : enumHeaderTheme.WHITE,
        hasWidthLimit:true
    },
    {
        path : '/candidates',
        component : EntrepriseCandidats,
        hasDashboard : false,
        needsLogin: true,
        needsRoles: [enumRoles.ENTREPRISE],
        hasFooter : true,
        headerTheme : enumHeaderTheme.WHITE
    },
    {
        path:'/projects',
        component:EntrepriseProjets,
        hasDashboard:false,
        needsLogin:true,
        needsRoles:[enumRoles.ENTREPRISE],
        hasFooter:true,
        headerTheme : enumHeaderTheme.WHITE,
        hasWidthLimit:true
    },
    {
        path:'/seeked_profiles',
        component : ProfilsRecherche,
        hasDashboard:false,
        needsLogin:true,
        needsRoles: [enumRoles.ENTREPRISE],
        hasFooter: true,
        headerTheme : enumHeaderTheme.WHITE,
        hasWidthLimit:true
    },
    // {
    //     path : '/candidate_file',
    //     component : EntrepriseFicheCandidat,
    //     hasDashboard : false,
    //     needsLogin: true,
    //     needsRoles: [enumRoles.ENTREPRISE],
    //     hasFooter : true,
    //     headerTheme : enumHeaderTheme.WHITE
    // },
    {
        path: '/',
        component: Home,
        needsLogin: false,
        hasDashboard : false,
        hasFooter : true,
        hasWidthLimit : true,
        headerTheme : enumHeaderTheme.IRIS
    },
    {
        path: '/*',
        component: NotFound,
        needsLogin: false,
        hasDashboard : false,
    }
]
export default routes
