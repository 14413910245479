import { MultiSelect } from "primereact/multiselect"
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enumContextContratMultiSelect } from "../../enums/enumContextContratMultiSelect";
import ContratService from "../../services/contratService";

export const ContratMultiSelect = (props) => {

    const {t} = useTranslation()

    const contratService = new ContratService();

    const [ddlContrat, setDdlContrat] = useState([])
    const [ddlLoading, setDdlLoading] = useState(true)

    useEffect(() => {
        loadLazyData();
    }, [])

    const loadLazyData = () => {
        switch (props.context) {
            case enumContextContratMultiSelect.PROFILETUDIANT:
                contratService.getAllContratForProfilEtudiant()
                    .then((data) => {
                        data.forEach(e => {
                            e.libelle = t(`enums.paireContrat.${e.code}`);
                            if(props.value.length==0){
                                let e = {value:data}
                                props.onChange(e,'paireContrats')
                            }
                        });
                        setDdlContrat(data)
                        setDdlLoading(false)
                    })
                break;
            case enumContextContratMultiSelect.ANNONCEINTERNE:
                contratService.getAllContratForAnnonceInterne()
                    .then((data) => {
                        data.forEach(e => {
                            e.libelle = t(`enums.paireContrat.${e.code}`);
                        });
                        setDdlContrat(data)
                        setDdlLoading(false)
                    })
                break;
            case enumContextContratMultiSelect.RECHERCHEAFFINEE:
                contratService.getAllContratForRechercheAffinee()
                    .then((data) => {
                        data.forEach(e => {
                            e.libelle = t(`enums.paireContrat.${e.code}`);
                        });
                        setDdlContrat(data)
                        setDdlLoading(false)
                    })
                break;
            default:
                break;
        }
    }

    return (
        <>
            {props.label && <label className={props.labelClassName}>{props.label}</label>}
            {ddlLoading ? (<div><ProgressSpinner style={{ width: '1.5rem', height: '1.5rem' }} strokeWidth="8" animationDuration="2s" /></div>) : (<MultiSelect
                className='active-search-multi-select'
                panelClassName='active-search-multi-select-panel'
                value={props.value}
                options={ddlContrat} optionLabel="libelle"
                placeholder={props.placeholder}
                onChange={props.onChange}
                display="chip"
            />
            )}
        </>
    )
}