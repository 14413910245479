import React, { useEffect, useState, useRef } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DT_Recrutement } from '../datatable/dt_recrutements';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import "./menuOverlayEtudiant.css"
import { enumContextEtudiantThumbnail } from "../../enums/enumContextEtudiantThumbnail";
import { Wysiwyg } from "../wysiwyg/wysiwyg";
import RecrutementService from "../../services/recrutementService";
import UserService from "../../services/userService";

export const MenuOverlayEtudiant = (props) => {
    const menu = useRef(null);
    const userService = new UserService();
    const { t } = useTranslation();

    const recrutementService = new RecrutementService();

    const [displayDialogRecrutement, setDisplayDialogRecrutement] = useState(false);
    const [displayDialogNotes, setDisplayDialogNotes] = useState(false);
    const [displayDialogNPA, setDisplayDialogNPA] = useState(false)
    const [notes, setNotes] = useState(props.recrutementEtudiant?.notes)
    const [loadingSaveNotes, setLoadingSaveNotes] = useState(false)

    let itemsDefault = [
        {
            label: t('student.link_to_recruitment'),
            command: () => {
                showDialogRecrutement();
            }
        }
        //CHAT-COMMENTE
        // ,
        // {
        //     label: t('general.contact'),
        //     command: () => {
        //         props.openThread();
        //     }
        // }
    ];

    let itemsRecrutementBoard = [
        {
            label: t('general.notes'),
            command: () => {
                showDialogNotes();
            }
        },
        {
            label: "Ne plus afficher",
            command: () => {
                showDialogNPA();
            }
        },
    ].concat(itemsDefault)
    
    const loadLazyData = () => {
        return userService.getCandidatRecutements(props.studentUrl);
    }

    const saveRecrutementsEtudiant = (selectedRecrutements) => {
        return userService.UpdateCandidatRecutements(props.studentUrl, selectedRecrutements)
    }

    const showSaveSuccess = () => {
        props.toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('recruitment.message.candidat_rct_maj'), life: 3000 });
    }

    const showSaveError = () => {
        props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('recruitment.message.candidat_rct_maj_error'), life: 3000 });
    }

    const showDialogRecrutement = () => {
        setDisplayDialogRecrutement(true);
    }
    const hideDialogRecrutement = () => {
        setDisplayDialogRecrutement(false);
    }

    const showDialogNotes = () => {
        setDisplayDialogNotes(true);
    }
    const hideDialogNotes = () => {
        setDisplayDialogNotes(false);
    }

    const showDialogNPA = () => {
        setDisplayDialogNPA(true);
    }
    const hideDialogNPA = () => {
        setDisplayDialogNPA(false);
    }

    const onInputChange = (content, editor) => {
        setNotes(content);
    }

    const saveRecrutementsEtudiantNotes = () => {
        setLoadingSaveNotes(true);
        recrutementService.setCandidatsRecrutementNotes(props.recrutementEtudiant.recrutementId, props.recrutementEtudiant.etudiantId, notes)
            .then(_recrutements => {
                props.toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('recruitment.message.candidat_notes'), life: 3000 });
                hideDialogNotes();
            })
            .catch((err) => {
                props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('recruitment.message.candidat_rct_maj_error'), life: 3000 });
            })
            .finally(() => {
                setLoadingSaveNotes(false);
            });
    }

    const confirmNPA = () => {
        let _etudiantId = props.recrutementEtudiant.etudiantId;
        recrutementService.deleteRecrutementEtudiant(props.recrutementEtudiant.recrutementId, props.recrutementEtudiant.etudiantId)
            .then(_recrutementEtudiant => {
                props.toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('recruitment.message.candidat_rct_remove'), life: 3000 });
                props.onNePlusAfficher(_etudiantId);
                hideDialogNPA();
            })
            .catch((err) => {
                props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('recruitment.message.candidat_rct_maj_error'), life: 3000 });
            })
    }

    const updateRecrutementDisplayed = (sr) => {
        props.updateRecrutementDisplayed(sr,props.recrutementEtudiant.etudiantId);
    }

    return (
        <>
            <Dialog visible={displayDialogRecrutement} onHide={() => hideDialogRecrutement()}>
                <DT_Recrutement
                    toast={props.toast}
                    hide={hideDialogRecrutement}
                    loadLazyData={loadLazyData}
                    saveData={saveRecrutementsEtudiant}
                    onSucessMessage={showSaveSuccess}
                    onErrorMessage={showSaveError}
                    context={props.context}
                    updateRecrutementDisplayed={(sr) => updateRecrutementDisplayed(sr)}
                />
            </Dialog>
            <Dialog style={{ width: '80vw' }} header={t('general.notes')} visible={displayDialogNotes} onHide={() => hideDialogNotes()}>
                <div className="card">
                    <Wysiwyg value={notes} onChange={onInputChange} />
                </div>
                <div className="p-text-center p-mt-4">
                    <Button label={t('general.save')} icon="pi pi-save" className="frs-button" disabled={loadingSaveNotes} onClick={saveRecrutementsEtudiantNotes} />
                </div>
            </Dialog>
            <ConfirmDialog
                visible={displayDialogNPA}
                onHide={() => hideDialogNPA}
                header="Ne plus afficher"
                message="Souhaitez-vous vraiment ne plus afficher ce candidat sur cette page de recrutement ?"
                icon="pi pi-exclamation-triangle"
                accept={confirmNPA}
                reject={hideDialogNPA}
            />
            <Button icon="pi pi-ellipsis-v" className="p-button-rounded moe-button" onClick={(e) => menu.current.toggle(e)}>
                <Menu className="menu-small-pop-up" model={props.context == enumContextEtudiantThumbnail.RECRUTEMENTBOARD ? (itemsRecrutementBoard) : (itemsDefault)} popup ref={menu}>
                </Menu>
            </Button>
        </>
    )
}
