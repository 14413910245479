import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { DipTag } from "../tag/dipTag";
import "./candidateThumbnail.css"
import { DisplayProfileImage } from "../image/displayProfileImage";
import { Avatar } from "primereact/avatar";
import { LikeEtudiant } from "../like/likeEtudiant";
import { ProjetOverlayPanel } from "../overlayPanel/projetOverlayPanel";
import RoleHelper from "../../utils/roleHelper";
import { useAuthState } from "../../context/context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Tag } from "primereact/tag";
import { enumPaireContrat } from "../../enums/enumPaireContrat";
import { Fragment, useRef, useState } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import Helper from "../../utils/helper";
import DocumentService from "../../services/documentService";
import { Dialog } from "primereact/dialog";

export const CandidateThumbnail = (props) => {

    const history = useHistory();
    const currentUser = useAuthState();
    const { t } = useTranslation();
    const lieuxPanel = useRef(null);
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;
    const documentService = new DocumentService();
    const [dialogConnection, setDialogConnection] = useState(false);
    const [dialogFreemiumInfo, setDialogFreemiumInfo] = useState(false);

    const openVueCandidat = () => {
        const pathname = `/Candidat/${props.etudiant.urlCode}/${props.etudiant.prenom}-${props.etudiant.nom}`;
        RoleHelper.windowOpenadminImpersonatingRecruteurIdStorage(pathname, adminImpersonatingRecruteurId);
    }

    const renderTypeRecherche = () => {
        let hasAlternanceTag = false;
        let hasEmploiTag = false;
        let hasStageTag = false;
        props.etudiant?.paireContrats?.forEach(e => {
            if (e.code == enumPaireContrat.ALTCA || e.code == enumPaireContrat.ALTCP) {
                hasAlternanceTag = true
            }
            if (e.code == enumPaireContrat.CDD || e.code == enumPaireContrat.CDI || e.code == enumPaireContrat.CDIINTERIM || e.code == enumPaireContrat.MISINTERIM || e.code == enumPaireContrat.PROCOM || e.code == enumPaireContrat.PROLIB) {
                hasEmploiTag = true
            }
            if (e.code == enumPaireContrat.STA) {
                hasStageTag = true
            }
        });

        /*
        {hasAlternanceTag && <Tag className="tag-alternance p-mr-2" value={t('offers.alternance').toUpperCase()} />}
        {hasEmploiTag && <Tag className="tag-emploi p-mr-2" value={t('offers.job').toUpperCase()} />}
        {hasStageTag && <Tag className="tag-stage p-mr-2" value={t('offers.traineeship').toUpperCase()} />}
        */
        return (
            <div>
                {hasAlternanceTag && <DipTag className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"} hasLetterSpacing label={t('offers.alternance').toUpperCase()} />}
                {hasStageTag && <DipTag className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"} hasLetterSpacing label={t('offers.traineeship').toUpperCase()} />}
                {hasEmploiTag && <DipTag className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"} hasLetterSpacing label={t('offers.job').toUpperCase()} />}
            </div>)
    }

    const renderMobilite = () => {
        let _countLieux = 0;
        let _currentLocation;
        let _locations = [];
        if (props.etudiant.mobiliteInternationale) {
            _countLieux = _countLieux + 1;
            _currentLocation = t('geography.international_except_france')
            _locations.push(_currentLocation);
        }
        if (props.etudiant.mobiliteFrancaise) {
            _countLieux = _countLieux + 1
            _currentLocation = t('geography.france');
            _locations.push(_currentLocation);
        }
        props.etudiant.mobiliteRegion?.forEach(r => {
            _countLieux = _countLieux + 1
            _currentLocation = r.libelle
            _locations.push(_currentLocation);
        });
        props.etudiant.mobiliteDepartement?.forEach(d => {
            _countLieux = _countLieux + 1
            _currentLocation = d.code + " - " + d.libelle
            _locations.push(_currentLocation);
        });
        if (_countLieux > 1) {
            return <div>
                <b><Trans i18nKey="profile.mobility.colons" /></b><Button type="button" className="location-button" icon="pi pi-eye" label={`${_countLieux} lieux`} onClick={(e) => lieuxPanel.current.toggle(e)} />
                <OverlayPanel className="location-panel" ref={lieuxPanel}>
                    {_locations.map((l, indexL) => (
                        <Fragment key={`${l}~${indexL}`}>
                            <div>{l}</div>
                        </Fragment>
                    ))}
                </OverlayPanel>
                <span></span>
            </div>
        }
        else if (_countLieux == 1) {
            return <div><b><Trans i18nKey="profile.mobility.colons" /></b><span>{_currentLocation}</span></div>
        }
        else return <span>&nbsp;</span>;
    }

    const showCVPdf = () => {
        if(RoleHelper.isNotConnected(currentUser)){
            setDialogConnection(true);
        }
        else if(props.displayFakeInformation){
            setDialogFreemiumInfo(true);
        }
        else{
            documentService.TelechargerDocumentCandidat(props.etudiant.fichierCvPdf.id).then(blob => {
                Helper.openPdfInNewTab(blob, props.etudiant.fichierCvPdf.name);
            });
        }
    }

    const showPortfolio = () => {
        if(RoleHelper.isNotConnected(currentUser)){
            setDialogConnection(true);
        }
        else if(props.displayFakeInformation){
            setDialogFreemiumInfo(true);
        }
        else{
            documentService.TelechargerDocumentCandidat(props.etudiant.fichierPortfolio.id).then(blob => {
                Helper.openPdfInNewTab(blob, props.etudiant.fichierPortfolio.name);
            });
        }
    }

    return (
        <div className={props.className}>
            <Dialog header={t('entreprise.upgrade_to_preminum')} visible={dialogFreemiumInfo} style={{ width: '30vw' }} onHide={() => setDialogFreemiumInfo(false)}>
                <div className="dip-ta-center">
                    <p>{t('entreprise.feature_required_premium')}</p>
                    <Link to={{ pathname: '/profile_company', hash: "#abonnement" }}>
                        <Button label={t('entreprise.discover_our_offer')} className="dip-btn-iris-bg p-button-rounded" />
                    </Link>
                </div>
            </Dialog>
            <div className="ct-oe-item-container">
                <div className="ct-profile-image">
                    {props.etudiant.fichierPhoto ? (
                        <DisplayProfileImage imageId={props.etudiant.fichierPhoto?.id} alt={t('meta-img.alt-student-picture') + " " + props.etudiant?.prenom + " " + props.etudiant?.nom} />
                    ) : (
                        <Avatar icon="pi pi-user" />
                    )
                    }
                    {/* <span>S</span> */}
                    <div className="ct-like-container">
                        <div className="dip-flex-row">
                            {RoleHelper.isEntreprise(currentUser) || adminImpersonatingRecruteurId && <div className="p-mr-2"><ProjetOverlayPanel etudiantId={props.etudiant.id} /></div>}
                            <LikeEtudiant etudiant={props.etudiant} toast={props.toast} />
                        </div>
                    </div>
                </div>
                <div className="p-shadow-4 ct-box-container" >
                    <div>
                        <div className="ct-titre p-mb-2">{props.etudiant.prenom} {props.etudiant.nom.charAt(0).toUpperCase()}</div>
                        {renderTypeRecherche()}
                        <div className="p-mt-3">
                            <span>{props.etudiant.niveauEtude || '\u00A0'}</span>
                        </div>
                        <div className="p-mt-3">
                            {props.etudiant.rechercheActiveCalendrier ? (
                                <>
                                    <i className="pi pi-calendar p-mr-2" />
                                    {t('company.available_until') + ' ' + Helper.dateTimeToLocaleDateTime(props.etudiant.rechercheActiveCalendrier)}
                                </>
                            ) : (
                                <span>&nbsp;</span> 
                            )}
                        </div>
                        <div className="p-mt-3">
                            {renderMobilite()}
                        </div>
                        <div className="p-mt-5">
                            {props.etudiant.fichierCvPdf?.dateValidation && <Button label={t('student.cv')} icon="pi pi-file-pdf" className="etudiant-thumbnail-button p-mr-2" onClick={showCVPdf} />}
                            
                            {props.etudiant.fichierPortfolio?.dateValidation && <Button label={t('student.portfolio')} icon="pi pi-file-pdf" className="etudiant-thumbnail-button" onClick={showPortfolio} />}
                        </div>
                        {/* <div className="ct-contrats">
                            {(props.etudiant.paireContrats?.some(pc => pc.code == "STA")) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                    hasLetterSpacing
                                    label={t('offers.traineeship')}
                                />}
                            {(props.etudiant.paireContrats?.some(pc => pc.code == "ALTCA")) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                    hasLetterSpacing
                                    label={t('formation.app_contract')}
                                />}
                            {(props.etudiant.paireContrats?.some(pc => pc.code == "ALTCP")) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                    hasLetterSpacing
                                    label={t('formation.pro_contract')}
                                />}
                            {(props.etudiant.paireContrats?.some(pc => pc.code == "CDD")) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                    hasLetterSpacing
                                    label={t('enums.paireContrat.CDD')}
                                />}
                            {(props.etudiant.paireContrats?.some(pc => pc.code == "CDI")) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                    hasLetterSpacing
                                    label={t('enums.paireContrat.CDI')}
                                />}
                            {(props.etudiant.paireContrats?.some(pc => pc.code == "CDIINTERIM")) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                    hasLetterSpacing
                                    label={t('CDI Intérimaire')}
                                />}
                            {(props.etudiant.paireContrats?.some(pc => pc.code == "MISINTERIM")) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                    hasLetterSpacing
                                    label={"Mission intérimaire"}
                                />}
                            {(props.etudiant.paireContrats?.some(pc => pc.code == "PROCOM")) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                    hasLetterSpacing
                                    label={"Profession commerciale"}
                                />}
                            {(props.etudiant.paireContrats?.some(pc => pc.code == "PROLIB")) &&
                                <DipTag
                                    className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                    hasLetterSpacing
                                    label={"Profession libérale"}
                                />}
                        </div> */}
                    </div>
                    <div>
                        <div className="ct-text">
                            {/* {(props.etudiant.mobiliteInternationale || props.etudiant.mobiliteFrancaise || props.etudiant.mobiliteRegion || props.etudiant.mobiliteDepartement) && <div className="p-mb-2 ct-mobility">
                                <i className="pi pi-map-marker p-mr-2" />
                                <span>{t('profile.mobility.base')}</span>
                                <div>
                                    <div>{props.etudiant.mobiliteInternationale && t('geography.international_except_france')}</div>
                                    <div>{props.etudiant.mobiliteFrancaise && t('geography.france')}</div>
                                    <div>{props.etudiant.mobiliteRegion.map((r, i) => {
                                        if (i < 3)
                                            return <div key={r.id + i}>{r.libelle}</div>
                                    })}</div>
                                    <div>{props.etudiant.mobiliteDepartement.map((d, i) => {
                                        if (props.etudiant.mobiliteRegion?.length + i < 3)
                                            return <div key={d.id + i}>{d.libelle}</div>
                                    })}</div>
                                    {props.etudiant.mobiliteRegion?.length + props.etudiant.mobiliteDepartement?.length > 3 && <div>...</div>}
                                </div>
                            </div>} */}
                        </div>

                        <div className="dip-flex-row dip-jc-end">
                            <Button className="dip-btn-iris-bg p-button-rounded dip-ai-center " onClick={openVueCandidat}>
                                {"Consulter le profil"}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}