import { Trans } from "react-i18next"
import { useHistory } from "react-router-dom"
import RoleHelper from "../../utils/roleHelper"

export const EntrepriseOffreBreadCrumb = (props) => {

    const history = useHistory()

    const onOffersClick = () => {
        RoleHelper.redirectWithImpersonationCheck(history,'/offers');
    }

    const onCandidatesClick = () => {
        RoleHelper.redirectWithImpersonationCheck(history,'/candidates',{id:history.location.state.offreEmploiId});
    }

    return(
        <div className="dip-color-res-blue dip-pointer">
            <span className={props.step == 0 && "dip-fw-700"} onClick={props.step >= 1 && onOffersClick}><Trans i18nKey="entreprise.myOffers" /></span>
            {props.step >= 1 && <><span>{" > "}</span><span className={props.step == 1 && "dip-fw-700"} onClick={props.step >= 2 && onCandidatesClick}>{props.label ? props.label : <Trans i18nKey='company.candidate.plural' />}</span></>}
            {props.step >= 2 && <>{" > "}<span className={props.step == 2 && "dip-fw-700"}><Trans i18nKey="company.file_candidate" /></span></>}
        </div>
    )
}