import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import UserService from '../../services/userService';
import { EcoleUserHeader } from './items/ecoleUserHeader';
import "./dashboardEcoles.css";

export const DashboardEcoles = () => {

    const userService = new UserService();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true);
        userService.getUserWithEcole()
        .then( res => {
            setUser(res);
            setLoading(false);
        }
        );
    }, [])

    return (
        <div>
            {loading &&  <div style={{ textAlign: 'center',paddingTop:"10rem" }}><ProgressSpinner/></div>}
            {!loading && <EcoleUserHeader user={user} className="p-shadow-1 dashboard-item" />}
            
        </div>
    )

}