import axios from 'axios';
import BaseService from './baseService';

export default class RegistrationOnboardingService extends BaseService {

  RegistrationStepBackward() {
    return this.axiosApiInstance.get(window.API_URL + '/api/registrationOnboarding/RegistrationStepBackward')
      .then(res => res.data)
      .catch(err => console.log(err));
  }

  getEtudiantOnboardingSteps() {
    return this.axiosApiInstance.get(window.API_URL + '/api/registrationOnboarding/GetEtudiantOnboardingSteps')
      .then(res => res.data)
      .catch(err => console.log(err));
  }

  etudiantRegistrationStepForward(model) {
    const jsonModel = JSON.stringify(model, null, 2);
    return this.axiosApiInstance.post(window.API_URL + '/api/registrationOnboarding/EtudiantRegistrationStepForward', jsonModel)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  etudiantRegistrationStepForwardWithFiles(currentUser, model) {
    const jsonModel = JSON.stringify(model, null, 2);
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + currentUser.token
      }
    };

    let formData = new FormData()
    formData.append('etudiantUserOnboardingStep', jsonModel);
    
    if (model.etudiant.cvPdf !== undefined)
      formData.append('cvPdf', new Blob([model.etudiant.cvPdf], { type: model.etudiant.cvPdf.type }), model.etudiant.cvPdf.name);
    if (model.etudiant.portfolio !== undefined)
      formData.append('portfolio', new Blob([model.etudiant.portfolio], { type: model.etudiant.portfolio.type }), model.etudiant.portfolio.name);

    return axios.post(window.API_URL + '/api/registrationOnboarding/EtudiantRegistrationStepForwardWithFiles', formData, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));

  }


}


