import React, { useRef, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Trans, useTranslation } from "react-i18next"
import "./newNbResultsAndOrderBar.css";
import RechercheContext from '../RechercheContext';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tag } from 'primereact/tag';
import RoleHelper from '../../../utils/roleHelper';
import { useAuthState } from '../../../context/context';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { InputSwitch } from 'primereact/inputswitch';
import { enumTypeRechercheAffinee } from '../../../enums/enumTypeRechercheAffinee';
import { enumNiveauEtude } from '../../../enums/enumNiveauEtude';
import { enumNiveauDiplome } from '../../../enums/enumNiveauDiplome';
import { enumCategorieRecherche } from '../../../enums/enumCategorieRecherche';
import { SelectButton } from 'primereact/selectbutton';

export const NewNbResultsAndOrder = (props) => {

    const { t } = useTranslation();
    const toast = useRef(null);
    const contextRecherche = useContext(RechercheContext);
    const currentUser = useAuthState();
    const history = useHistory();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId

    const items = [
        {
            label: t('search.relevance'), value: t('search.relevance')
        },
        {
            label: t('search.training_organisations'), value: t('search.training_organisations')
        },
        {
            label: t('search.country_region'), value: t('search.country_region')
        },
        {
            label: t('search.job_offers'), value: t('search.job_offers')
        },
    ]
    const itemsCompany = [
        {
            label: t('search.relevance'), value: t('search.relevance')
        },
        {
            label: t('search.training_organisations'), value: t('search.training_organisations')
        },
        {
            label: t('search.country_region'), value: t('search.country_region')
        },
        {
            label: t('company.candidate.plural'), value: t('company.candidate.plural')
        },
    ]

    const selectButtonOptions = [
        { label: t('profile.formation.plural'), value: enumTypeRechercheAffinee.FORMATION },
        { label: t('company.candidate.plural'), value: enumTypeRechercheAffinee.CANDIDAT }
    ];


    const majResults = (_lazyParams, _typeRecherche) => {
        contextRecherche.setLazyParams(_lazyParams);
        contextRecherche.rechercheApi(_lazyParams, _typeRecherche);
    }

    const handleChangeTri = (e) => {
        e.preventDefault()
        try {
            let _lazyParams = { ...contextRecherche.lazyParams };
            _lazyParams.sortField = e.value;
            _lazyParams.first = 0;
            _lazyParams.page = 1;
            majResults(_lazyParams);

        } catch (error) {
            console.log(error);
        }
    }

    const renderCountFormationsOrCandidats = () => {
        if (props.loadingTotalFormationsRecords) {
            return (
                <div className='dip-flex-row dip-ai-center'>
                    <ProgressSpinner className='p-mr-2' style={{ width: '1rem', height: '1rem' }} animationDuration=".5s" />
                    {t('general.results')}
                </div>
            );
        } else {
            let totalRecords;
            
            switch (contextRecherche.typeRecherche) {
                case enumTypeRechercheAffinee.FORMATION:
                    totalRecords = contextRecherche.totalFormationsRecords;
                    break;
                case enumTypeRechercheAffinee.CANDIDAT:
                    totalRecords = contextRecherche.totalCandidatsRecords;
                    break;
                default:
                    totalRecords = 0; // Par défaut à 0 ou une autre valeur si nécessaire
                    break;
            }
    
            const label = totalRecords > 1 ? t('general.results') : t('general.result');
    
            return (
                <>
                    {totalRecords} {label}
                </>
            );
        }
    }       

    const onSwitchTypeRecherche = (e) => {
        let _lazyParams = { ...contextRecherche.lazyParams };
        _lazyParams.first = 0;
        _lazyParams.page = 1;
    
        const niveauSortieFilters = _lazyParams.filters?.filter(filter => filter.categorie === enumCategorieRecherche.NiveauSortie) || [];
    
        // Parcours de chaque filtre de catégorie "NiveauSortie"
        niveauSortieFilters.forEach(filter => {
            if (filter.value) {
                let newValue;
    
                if (contextRecherche.typeRecherche === enumTypeRechercheAffinee.FORMATION) {
                    // Si on est en mode FORMATION, utiliser enumNiveauDiplome
                    newValue = (() => {
                        switch (filter.value) {
                            case enumNiveauDiplome.BAC2: return enumNiveauEtude.BAC2;
                            case enumNiveauDiplome.BAC3: return enumNiveauEtude.BAC3;
                            case enumNiveauDiplome.BAC4: return enumNiveauEtude.BAC4;
                            case enumNiveauDiplome.BAC5: return enumNiveauEtude.BAC5;
                            case enumNiveauDiplome.BAC6: return enumNiveauEtude.BAC6;
                            case enumNiveauDiplome.BAC8: return enumNiveauEtude.BAC8;
                            // Ajouter d'autres cas si nécessaire
                            default: return filter.value;
                        }
                    })();
                } else if (contextRecherche.typeRecherche === enumTypeRechercheAffinee.CANDIDAT) {
                    // Si on est en mode CANDIDAT, utiliser enumNiveauEtude
                    newValue = (() => {
                        switch (filter.value) {
                            case enumNiveauEtude.BAC2: return enumNiveauDiplome.BAC2;
                            case enumNiveauEtude.BAC3: return enumNiveauDiplome.BAC3;
                            case enumNiveauEtude.BAC4: return enumNiveauDiplome.BAC4;
                            case enumNiveauEtude.BAC5: return enumNiveauDiplome.BAC5;
                            case enumNiveauEtude.BAC6: return enumNiveauDiplome.BAC6;
                            case enumNiveauEtude.BAC8: return enumNiveauDiplome.BAC8;
                            // Ajouter d'autres cas si nécessaire
                            default: return filter.value;
                        }
                    })();
                }
    
                // Mise à jour de la valeur du filtre
                filter.value = newValue;
            }
        });
    
        // Si e.value est vrai, on recherche des candidats, sinon des formations
        if (e.value === enumTypeRechercheAffinee.CANDIDAT) {
            contextRecherche.setTypeRecherche(enumTypeRechercheAffinee.CANDIDAT);
            _lazyParams.filters = _lazyParams.filters.filter(filter => filter.categorie !== enumCategorieRecherche.FormationActive && filter.categorie !== enumCategorieRecherche.FormationVisible);
            _lazyParams.filters.push({ categorie: enumCategorieRecherche.RechercheActive, value: true });
            majResults(_lazyParams, enumTypeRechercheAffinee.CANDIDAT);
        } else if (e.value === enumTypeRechercheAffinee.FORMATION) {
            contextRecherche.setTypeRecherche(enumTypeRechercheAffinee.FORMATION);
            _lazyParams.filters = _lazyParams.filters.filter(filter => filter.categorie !== enumCategorieRecherche.RechercheActive);
            _lazyParams.filters.push({ categorie: enumCategorieRecherche.FormationActive, value: true }, { categorie: enumCategorieRecherche.FormationVisible, value: true });
            majResults(_lazyParams, enumTypeRechercheAffinee.FORMATION);
        }
    }    
    
    return (
        <>
            <Toast ref={toast} />
            <div className={props.className}>
                <div className="dip-flex-row dip-jc-between">
                    <div className={'nnrao-results-text'/* + (props.displayFakeInformation && contextRecherche.typeRecherche == enumTypeRechercheAffinee.CANDIDAT ? " fake-candidat" : "") */}>
                        <Tag>{renderCountFormationsOrCandidats()}</Tag>
                    </div>
                    {RoleHelper.isEntreprise(currentUser) || RoleHelper.isAdmin(currentUser) || adminImpersonatingRecruteurId ?
                    (
                        <div className="dip-flex-row dip-ai-center nnrao-tri">
                            <SelectButton 
                                value={contextRecherche.typeRecherche} 
                                options={selectButtonOptions}
                                onChange={(e) => onSwitchTypeRecherche(e)}
                            />
                        </div>
                    ) : null}
                    <div className="dip-flex-row dip-ai-center nnrao-tri">
                        <div className="p-mr-2">
                            <b><Trans i18nKey='general.order_by' /></b>
                        </div>
                        <Dropdown
                            placeholder={t('general.order_by')}
                            value={contextRecherche.lazyParams.sortField}
                            options={RoleHelper.isEntreprise(currentUser) || adminImpersonatingRecruteurId  ? itemsCompany : items}
                            className="p-button-outlined p-button-danger p-button-rounded nrao-dd"
                            onChange={handleChangeTri} />
                    </div>
                </div>
            </div>
        </>
    )
};