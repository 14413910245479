import React from 'react';

export const NewLineFormater = (props) => {
    const affichageText = (text) => {
        if (text) {
            return text.split('\n').map(str => <p>{str}</p>)
        }
        else {
            return "";
        }
    }


    return (
        <>
            {affichageText(props.text)}
        </>
    )
}