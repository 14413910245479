import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { DisplayProfileImage } from "../../components/image/displayProfileImage";
import "./candidatThumbnail.css"
import { Tag } from 'primereact/tag';
import Helper from "../../utils/helper";
import { Trans, useTranslation } from "react-i18next";
import DocumentService from "../../services/documentService";
import MessagerieService from "../../services/messagerieService";
import { Avatar } from "primereact/avatar";
import { OverlayPanel } from "primereact/overlaypanel";
import { MenuOverlayEtudiant } from "../../components/menu/menuOverlayEtudiant";
import { Fragment, useRef, useState } from "react";
import { enumContextEtudiantThumbnail } from "../../enums/enumContextEtudiantThumbnail";
import RoleHelper from "../../utils/roleHelper";
import { useAuthState } from "../../context/context";
import { AnnonceCandidateesIndicator } from "../../components/indicator/annonceCandidateesIndicator";
import { enumPaireContrat } from "../../enums/enumPaireContrat";


export const CandidatThumbnail = (props) => {

    const { t } = useTranslation();
    const documentService = new DocumentService();
    const messagerieService = new MessagerieService();
    const lieuxPanel = useRef(null);
    const [annonceCandidatees, setAnnonceCandidatees] = useState(props.recrutementEtudiant?.recrutementEtudiantOffreEmplois?.filter(function (item) { return item.etudiantId == props.etudiant.id }))
    const currentUser = useAuthState();

    const renderLieuResidence = () => {
        if (props.etudiant.etranger) {
            return props.etudiant.lieuResidence + " (" + props.etudiant.pays + ")"
        }
        else {
            return props.etudiant.commune.libelle + " (" + props.etudiant.commune.departement.code + ")"
        }

    }

    const renderTypeRecherche = () => {
        let hasAlternanceTag = false;
        let hasEmploiTag = false;
        let hasStageTag = false;
        props.etudiant?.paireContrats?.forEach(e => {
            if (e.code == enumPaireContrat.ALTCA || e.code == enumPaireContrat.ALTCP) {
                hasAlternanceTag = true
            }
            if (e.code == enumPaireContrat.CDD || e.code == enumPaireContrat.CDI || e.code == enumPaireContrat.CDIINTERIM || e.code == enumPaireContrat.MISINTERIM || e.code == enumPaireContrat.PROCOM || e.code == enumPaireContrat.PROLIB) {
                hasEmploiTag = true
            }
            if (e.code == enumPaireContrat.STA) {
                hasStageTag = true
            }
        });

        return (
            <div>
                {hasAlternanceTag && <Tag className="tag-alternance p-mr-2" value={t('offers.alternance').toUpperCase()} />}
                {hasEmploiTag && <Tag className="tag-emploi p-mr-2" value={t('offers.job').toUpperCase()} />}
                {hasStageTag && <Tag className="tag-stage p-mr-2" value={t('offers.traineeship').toUpperCase()} />}
            </div>)
    }

    const renderMobilite = () => {
        let _countLieux = 0;
        let _currentLocation;
        let _locations = [];
        if (props.etudiant.mobiliteInternationale) {
            _countLieux = _countLieux + 1;
            _currentLocation = t('geography.international_except_france')
            _locations.push(_currentLocation);
        }
        if (props.etudiant.mobiliteFrancaise) {
            _countLieux = _countLieux + 1
            _currentLocation = t('geography.france');
            _locations.push(_currentLocation);
        }
        props.etudiant.mobiliteRegion?.forEach(r => {
            _countLieux = _countLieux + 1
            _currentLocation = r.libelle
            _locations.push(_currentLocation);
        });
        props.etudiant.mobiliteDepartement?.forEach(d => {
            _countLieux = _countLieux + 1
            _currentLocation = d.code + " - " + d.libelle
            _locations.push(_currentLocation);
        });
        if (_countLieux > 1) {
            return <div>
                <b><Trans i18nKey="profile.mobility.colons" /></b><Button type="button" className="location-button" icon="pi pi-eye" label={`${_countLieux} lieux`} onClick={(e) => lieuxPanel.current.toggle(e)} />
                <OverlayPanel className="location-panel" ref={lieuxPanel}>
                    {_locations.map((l, indexL) => (
                        <Fragment key={`${l}~${indexL}`}>
                            <div>{l}</div>
                        </Fragment>
                    ))}
                </OverlayPanel>
                <span></span>
            </div>
        }
        else if (_countLieux == 1) {
            return <div><b><Trans i18nKey="profile.mobility.colons" /></b><span>{_currentLocation}</span></div>
        }
        else return <></>
    }

    const showCVPdf = () => {
        documentService.TelechargerDocumentCandidat(props.etudiant.fichierCvPdf.id).then(blob => {
            Helper.downloadBlobFile(blob, props.etudiant.fichierCvPdf.name);
        });
    }

    const showPortfolio = () => {
        documentService.TelechargerDocumentCandidat(props.etudiant.fichierPortfolio.id).then(blob => {
            Helper.downloadBlobFile(blob, props.etudiant.fichierPortfolio.name);
        });
    }

    const seeProfile = () => {
        var win = window.open("/Candidat/" + `${props.etudiant.urlCode}` + "/" + Helper.clearString(props.etudiant.prenom) + "-" + Helper.clearString(props.etudiant.nom))
        win.focus();
    }

    const openThread = () => {
        let httpPromise;
        httpPromise = messagerieService.openThread(currentUser, props.etudiant.id);
        props.toast.current.show({ severity: 'warn', summary: t('general.redirect'), detail: t('warnings.redirect_chat'), life: 3000 })
        httpPromise
            .then(_threadId => {
                var win = window.open("/Chat/?Thread=" + _threadId)
                win.focus();
            })
            .catch((err) => {
                props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 })
            });
        // .finally(() => setDisplayRedirection(false));
    }



    return (
        <>
            <div className={props.className}>

                                    
                <div className={props.isInAList && "margin-etudiant-thumbnail"}>
                    <Card className="candidat-thumbnail-card p-shadow-5">
                        <div className={`candidat-thumbnail-main-container ${props.hasFormationAccordion ? ('etudiant-thumbnail-main-container-with-formation-radius') : ('etudiant-thumbnail-main-container-without-formation-radius')} ${props.small && "candidat-thumbnail-small"} `}>
                            {props.context && props.context == enumContextEtudiantThumbnail.RECRUTEMENTBOARD &&
                                <div className="candidat-thumbnail-toolbar">
                                    {annonceCandidatees.length > 0 && <AnnonceCandidateesIndicator className="p-mr-1" annonceCandidatees={annonceCandidatees} />}
                                    {props.context && props.context == enumContextEtudiantThumbnail.RECRUTEMENTBOARD && props.recrutementData != null &&
                                        <Button className="p-button-raised p-button-rounded check-button" onClick={() => props.recrutementData[0](props.recrutementData[1], props.etudiant.id, props.recrutementData[2])}>{props.recrutementData[4]}{t(props.recrutementData[3])}</Button>
                                    }
                                    <Button className="p-ml-1 p-button-raised p-button-rounded check-button" onClick={() => seeProfile()} ><label><i className="pi pi-eye p-mr-1" />{t('general.profile')}</label></Button>

                                </div>
                            }
                            <div className={`candidat-thumbnail-profile ${props.etudiant.rechercheActive && props.hasFormationAccordion && !props.small ? ('etudiant-thumbnail-elipsis-radius-with-formation') : ('etudiant-thumbnail-elipsis-radius-without-formation')}`}>
                                <div className="candidat-thumbnail-profile-image">
                                    {props.etudiant.fichierPhoto && <DisplayProfileImage imageId={props.etudiant.fichierPhoto.id} />}
                                    {!props.etudiant.fichierPhoto && props.small && <Avatar icon="pi pi-user" shape="circle" />}
                                </div>

                                <div className={`candidat-thumbnail-info-container `}>
                                    <div className="candidat-thumbnail-title-container">
                                        <h1>{props.etudiant.prenom} {props.etudiant.nom.toUpperCase()}</h1>
                                        {(!props.context || props.context != enumContextEtudiantThumbnail.RECRUTEMENTBOARD) && RoleHelper.isEntreprise(currentUser) && <div className={`candidat-thumbnail-moe-in-title ${props.small && "candidat-thumbnail-small-moe-in-title"}`}><MenuOverlayEtudiant context={props.context} studentUrl={props.etudiant.urlCode} toast={props.toast}></MenuOverlayEtudiant></div>}
                                    </div>
                                    {props.etudiant.emploiActuel && <span><Trans i18nKey="profile.actual_job" /></span>}
                                    <span><Trans i18nKey="profile.current_study_level" />{props.etudiant.niveauEtude}</span>
                                    {(props.etudiant.commune?.id || (props.etudiant.lieuResidence && props.etudiant.pays)) && <span><Trans i18nKey="profile.residence.colons" />{renderLieuResidence()}</span>}
                                    <div className="p-mt-1">
                                        {props.etudiant.fichierCvPdf?.dateValidation && <Button label={t('student.cv')} icon="pi pi-file-pdf" className="candidat-thumbnail-button p-mr-2" onClick={showCVPdf} />}
                                        {props.etudiant.fichierPortfolio?.dateValidation && <Button label={t('student.cv_video')} icon="pi pi-play" className="candidat-thumbnail-button" onClick={showPortfolio} />}
                                    </div>

                                </div>
                            </div>
                            {props.etudiant.rechercheActive && <div className="candidat-thumbnail-recherche-active-container">
                                <div className="candidat-thumbnail-recherche-active-title-container  p-mb-1">
                                    {(!props.context || props.context != enumContextEtudiantThumbnail.RECRUTEMENTBOARD) && props.hasCheckableProfile && !props.small ? (<div className="candidat-thumbnail-check-profile"><Button className="p-button-raised p-button-rounded check-button" onClick={seeProfile} ><label><i className="pi pi-eye p-mr-1" />{t('general.profile')}</label></Button></div>) : (<h2><Trans i18nKey="profile.active_search" /></h2>)}
                                    {(!props.context || props.context != enumContextEtudiantThumbnail.RECRUTEMENTBOARD) && RoleHelper.isEntreprise(currentUser) && !props.small && <div className="candidat-thumbnail-moe-in-active-search"><MenuOverlayEtudiant context={props.context} studentUrl={props.etudiant.urlCode} toast={props.toast} openThread={openThread}/></div>}

                                </div>
                                {renderTypeRecherche()}
                                {props.etudiant.rechercheActiveCalendrier && <div><b><Trans i18nKey="offers.from_date" /></b><span>{Helper.dateTimeToLocaleDateTime(props.etudiant.rechercheActiveCalendrier)}</span></div>}
                                {renderMobilite()}
                            </div>}
                            {(!props.context || props.context != enumContextEtudiantThumbnail.RECRUTEMENTBOARD) &&
                                <div className={`candidat-thumbnail-center etudiant-thumbnail-bottom-check-profile-hidden ${props.small && 'etudiant-thumbnail-bottom-check-profile-show'} ${props.hasCheckableProfile && !props.small && 'etudiant-thumbnail-bottom-check-profile-responsive'}`}>
                                    <Button className="p-button-raised p-button-rounded check-button" onClick={() => seeProfile()} ><label><i className="pi pi-eye p-mr-1" />{t('general.profile')}</label></Button>
                                    {/* CHAT-COMMENTE */}
                                    {/* <Button className="p-ml-1 p-button-raised p-button-rounded check-button" onClick={() => openThread()} ><IoMailOutline></IoMailOutline><label><i className="p-mr-1" />{t('general.contact')}</label></Button> */}
                                </div>}
                        </div>
                       
                    </Card>
                </div>
            </div>
        </>
    )
}
