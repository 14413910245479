import { useTranslation } from "react-i18next"
import { FileUploader } from "../fileUploader/fileUploader"
import "./entrepriseCard.css"
import allowedImageFileType from "../../valueContol/allowedImageFileType.json"
import { enumDocumentCategorie } from "../../enums/enumDocumentCategorie";

export const EntrepriseProfileCard = (props) => {

    const { t } = useTranslation()

    return (
        <div className="ec-container dip-ta-center">
            <FileUploader
                className="personnal-info-file-container"
                fileName="logoFile"
                documentName="logo"
                entity={props.entreprise}
                setEntity={props.setEntreprise}
                maxFileSize={10000000}
                flavorText={t('general.photo')}
                tooltip={t('profile.square_maxMo', { size: 10000000 / 1000000 })}
                hasPreview={true}
                accept=".png,.jpg,.jpeg"
                allowedType={allowedImageFileType}
                documentCategory={enumDocumentCategorie.LOGOENTREPRISE}
                isLight
            />
            <h1>
                {props.entreprise.prenom} {props.entreprise.nom}
            </h1>
            <h2>
                {props.entreprise.poste}
            </h2>
        </div>
    )
}