import React, { useRef, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './headerAuthLogin.css';
import { FormLogin } from '../../form/formLogin.js';
import { TabView, TabPanel } from 'primereact/tabview';
import { FormRegister } from '../../form/formRegister.js';
import { enumHeaderTheme } from "../../../enums/enumHeaderTheme"

export const HeaderAuthLogin = (props) => {
    const { t } = useTranslation()

    const op = useRef(null)

    useEffect(() => {
        op.current.hide();
    }, []);

    const toggle = () => {
        op.current.toggle();
    }

    return (
        <div className={props.className}>
            <Button className={`p-button-raised p-button-rounded Header_Auth_Login ${(props.theme == enumHeaderTheme.IRIS || props.theme == enumHeaderTheme.RED || props.theme == enumHeaderTheme.BLUE) ? "dip-btn-white-bg" : "dip-btn-iris-bg"}`} label={t('general.myAccount.full')} type="button" onClick={e => op.current.toggle(e)} aria-haspopup aria-controls="overlay_panel" />
            <OverlayPanel className={`auth-tab-panel hal-${props.userType?.toLowerCase()}`} toggle={toggle} ref={op} showCloseIcon >
                <TabView className={`auth-tab-container hal-${props.userType?.toLowerCase()}`}>
                    <TabPanel header={t('login.log_in')}>
                        <FormLogin op={op} showOnboarding={props.showOnboarding} userType={props.userType} />
                    </TabPanel>
                    <TabPanel header={t('login.create_account')}>
                        <FormRegister className="auth-fieldset-nullifier" op={op} userType={props.userType} showOnboarding={props.showOnboarding}/>
                    </TabPanel>
                </TabView>
            </OverlayPanel>
        </div >
    );
}
