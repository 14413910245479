import axios from 'axios';
import BaseService from './baseService';

export default class ComposanteService extends BaseService {

  getComposantesAdmin(currentUser) {
    const options = {
      headers: { Authorization: 'Bearer ' + currentUser.token }
    };
    return axios.get(window.API_URL + '/api/composanteecole/composantesAdmin', options)
      .then(res => res.data)
      .catch(err => console.log(err));
  }
  getComposanteEcoleFiltered(query, idEcole) {
    const options = {
      params: {
        query: query,
        idEcole: idEcole
      }
    }
    return axios.get(window.API_URL + '/api/composanteEcole/composanteEcoleFiltered', options)
      .then(res => res.data)
      .catch(err => console.log(err));
  }

  getComposantesPaginated(lazyParams, ecoleId) {
    let interm = super.getAPIPagedQuery(lazyParams)
    if (ecoleId) {
      interm.ecoleId = ecoleId
    }
    const queryParams = super.getQueryParams(interm)
    return this.axiosApiInstance.get(window.API_URL + `/api/composanteEcole/getPaginated?${queryParams}`)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getComposanteEcoleFiche(composanteEcoleId) {
    return this.axiosApiInstance.get(window.API_URL + '/api/composanteEcole/GetComposanteEcoleFiche/' + composanteEcoleId)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getComposanteEcoleFicheExtended(composanteEcoleId) {
    return this.axiosApiInstance.get(window.API_URL + '/api/composanteEcole/GetComposanteEcoleFicheExtended/' + composanteEcoleId)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  updateComposanteEcoleFiche(currentUser, composanteEcole) {
    const jsonComposanteEcole = JSON.stringify(composanteEcole, null, 2);
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + currentUser.token
      }
    };
    const formData = new FormData()
    formData.append('composanteEcole', jsonComposanteEcole);
    if (composanteEcole.fileImageCouverture !== undefined)
      formData.append('fileImageCouverture', new Blob([composanteEcole.fileImageCouverture], { type: composanteEcole.fileImageCouverture.type }), composanteEcole.fileImageCouverture.name);
    return axios.post(window.API_URL + '/api/composanteEcole/UpdateComposanteEcoleFiche', formData, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getPartneredSchoolsRandomized() {
    return this.axiosApiInstance.get(window.API_URL + '/api/composanteEcole/GetPartneredSchoolsRandomized/')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }
}