export const enumCategorieRecherche = {
    Competence: 0,
    Appellation: 1,
    Formation: 2,
    Diplome: 3,
    Ville: 4,
    Departement: 5,
    Region: 6,
    Pays: 7,
    Composante: 8,
    Ecole: 9,
    Bac: 10,
    Langue: 11,
    FormationActive: 12,
    Alternance: 13,
    FormationContinue: 14,
    FormationInitiale: 15,
    AccessibleADistance: 16,
    APartirDe: 17,
    DureeStage: 18,
    Stage: 19,
    NiveauEntree: 20,
    NiveauSortie: 21,
    PosteRecherche: 22,
    LieuMobilite: 23,
    HorsFrance: 24,
    DipDomaine: 25,
    DipMetier: 26,
    NiveauEtude: 100,
    TypeContrat: 101,
    DebutContrat: 102,
    DuréeContrat: 103,
    CompetenceLiee: 104,
    Diffusion: 105,
    Disponibilite:106,
    EnLigne: 107,
    FormationVisible: 108,
    FormationParcourSup: 109,
    FormationMonMaster: 110,
    OrganismeFormation: 111,
    CoutFormation: 112,
    ContratStage:201,
    ContratAltApp:202,
    ContratAltPro:203,
    ContratCDDCDI:204,
    ContratAutres:205,
    Projet:206,
    StatutRecrutement:207,
    RechercheActive:208
}