import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Button } from 'primereact/button';
import statistiqueLogo from '../../../images/accueil/ecole/statistiqueLogo.png'
import entrepriseRetexLogo from '../../../images/accueil/ecole/entrepriseRetexLogo.png'
import analyseLogo from '../../../images/accueil/ecole/analyseLogo.png'
import "./bodyHomeEcole.css";

export const BodyHomeEcole = () => {

    const { t } = useTranslation()

    return (
        <div className="catchphrase-modules">
            <div className="catchphrase-logo">
                <img src={statistiqueLogo} alt="" />
            </div>
            <div className="catchphrase-logo">
                <img src={entrepriseRetexLogo} alt="" />
            </div>
            <div className="catchphrase-logo">
                <img src={analyseLogo} alt="" />
            </div>
            <div className="catchphrase-phrase">
                <Trans i18nKey='home.catchphrase_ecole_pieChart' />
            </div>
            <div className="catchphrase-phrase">
                <Trans i18nKey='home.catchphrase_ecole_flowChart' />
            </div>
            <div className="catchphrase-phrase">
                <Trans i18nKey='home.catchphrase_ecole_pointChart' />
            </div>

            {/* <div className="catchphrase-button" style={{ textAlign: "center" }}>
                            <Button label={t('home.see_more')} className="p-button-outlined p-button-danger p-button-rounded" />
                        </div>
                        <div className="catchphrase-button" style={{ textAlign: "center" }}>
                            <Button label={t('home.available_soon')} className="p-button-outlined p-button-danger p-button-rounded" />
                        </div>
                        <div className="catchphrase-button" style={{ textAlign: "center" }}>
                            <Button label={t('home.available_soon')} className="p-button-outlined p-button-danger p-button-rounded" />
                        </div> */}
        </div>

    )
};