import React from 'react';
import { HeaderAuthLogin } from './header_auth/headerAuthLogin';
import { HeaderAuthProfile } from './header_auth/headerAuthProfile';
import {HeaderAccessButtons} from './headerAccessButtons';
import { useAuthState } from '../../context/context';


export const HeaderAuth = (props) => {

    const currentUser = useAuthState();

    return currentUser.user == '' ? (
        <>
            <HeaderAccessButtons theme={props.theme}  />
            <HeaderAuthLogin
                className={props.className}
                showOnboarding={props.showOnboarding}
                theme={props.theme}
                userType={props.userType}
            />
        </>
    ) : (
        <>
            <HeaderAccessButtons theme={props.theme} />
            <HeaderAuthProfile className={props.className} nom={currentUser.user.nom} prenom={currentUser.user.prenom} userId={currentUser.userId} theme={props.theme} />
        </>
    );

}