import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { AutoComplete } from 'primereact/autocomplete';
import { Trans, useTranslation } from "react-i18next"
import RechercheService from '../../../services/rechercheService';
import "./simpleSearchBar.css";
import { enumSearchQuoiLabel } from '../../../enums/enumSearchQuoiLabel';
import { enumSearchOuLabel } from '../../../enums/enumSearchOuLabel';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import StatistiquesService from '../../../services/statistiquesService';

export const SimpleSearchBar = (props) => {

    const rechercheService = new RechercheService();
    const statistiqueService = new StatistiquesService();
    const { t } = useTranslation();
    const toast = useRef(null)
    const [groupedQuoi, setGroupedQuoi] = useState(null);
    const [groupedOu, setGroupedOu] = useState(null);
    const [selectedQuoi, setSelectedQuoi] = useState(null);
    const [selectedOu, setSelectedOu] = useState(null);
    const [filteredQuoi, setFilteredQuoi] = useState(null);
    const [filteredOu, setFilteredOu] = useState(null);
    const [filters, setFilters] = useState([]);
    const history = useHistory();
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 6,
        page: 0,
        sortField: 'Pertinence',
        sortOrder: 1,
        filters: [{ categorie: 12, value: true }]
    });


    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {

    }

    const showNoResult = () => {
        toast.current.show({ severity: 'warn', summary: t('warnings.noResult'), detail: t('warnings.checkInputOrElse'), life: 3000 });
    }
    const searchQuoi = (event) => {

        let query = event.query;

        rechercheService.getRechercheQuoiSimple(query).then(_filteredQuoi => {
            let _appellationsIndex = _filteredQuoi.map(function (e) { return e.label }).indexOf(enumSearchQuoiLabel.APPELLATION)
            let _competencesIndex = _filteredQuoi.map(function (e) { return e.label }).indexOf(enumSearchQuoiLabel.COMPETENCE)
            let _diplomesIndex = _filteredQuoi.map(function (e) { return e.label }).indexOf(enumSearchQuoiLabel.DIPLOME)
            let _indexesOfCategoriesToRemove = [];

            //AliasAppellation
            if (_filteredQuoi[_appellationsIndex].items.length == 0)
                _indexesOfCategoriesToRemove.push(_appellationsIndex)
            else
                _filteredQuoi[_appellationsIndex].label = t('search.appellationsLabel');

            //AliasCompetence
            if (_filteredQuoi[_competencesIndex].items.length == 0)
                _indexesOfCategoriesToRemove.push(_competencesIndex)
            else
                _filteredQuoi[_competencesIndex].label = t('search.competencesLabel');

            if (_filteredQuoi[_diplomesIndex].items.length == 0)
                _indexesOfCategoriesToRemove.push(_diplomesIndex)
            else
                _filteredQuoi[_diplomesIndex].label = t('search.diplomesLabel');


            _indexesOfCategoriesToRemove.sort(function (a, b) { return b - a });
            _indexesOfCategoriesToRemove.forEach(i => {
                _filteredQuoi.splice(i, 1);
            });
            if (_filteredQuoi.length == 0) {
                showNoResult()
            }
            setGroupedQuoi(_filteredQuoi);

            setFilteredQuoi(_filteredQuoi);
        });
    }
    const searchOu = (event) => {

        let query = event.query;

        rechercheService.getRechercheOu(query).then(_filteredOu => {

            let stringifyRechercheOu = JSON.stringify(_filteredOu);
            stringifyRechercheOu = stringifyRechercheOu.replace('villesLabel', t('search.villesLabel'));
            stringifyRechercheOu = stringifyRechercheOu.replace('departementsLabel', t('search.departementsLabel'));
            stringifyRechercheOu = stringifyRechercheOu.replace('regionsLabel', t('search.regionsLabel'));
            stringifyRechercheOu = stringifyRechercheOu.replace('paysLabel', t('search.paysLabel'));
            stringifyRechercheOu = stringifyRechercheOu.replace('etablissementLabel', t('search.etablissementLabel'));

            let _villesIndex = _filteredOu.map(function (e) { return e.label }).indexOf(enumSearchOuLabel.VILLE)
            let _departementsIndex = _filteredOu.map(function (e) { return e.label }).indexOf(enumSearchOuLabel.DEPARTEMENT)
            let _regionsIndex = _filteredOu.map(function (e) { return e.label }).indexOf(enumSearchOuLabel.REGION)
            let _paysIndex = _filteredOu.map(function (e) { return e.label }).indexOf(enumSearchOuLabel.PAYS)
            let _etablissementIndex = _filteredOu.map(function (e) { return e.label }).indexOf(enumSearchOuLabel.ETABLISSEMENT)
            let _indexesOfCategoriesToRemove = [];

            if (_filteredOu[_villesIndex].items.length == 0)
                _indexesOfCategoriesToRemove.push(_villesIndex)
            else
                _filteredOu[_villesIndex].label = t('search.villesLabel');

            if (_filteredOu[_departementsIndex].items.length == 0)
                _indexesOfCategoriesToRemove.push(_departementsIndex)
            else
                _filteredOu[_departementsIndex].label = t('search.departementsLabel');

            if (_filteredOu[_regionsIndex].items.length == 0)
                _indexesOfCategoriesToRemove.push(_regionsIndex)
            else
                _filteredOu[_regionsIndex].label = t('search.regionsLabel');

            if (_filteredOu[_paysIndex].items.length == 0)
                _indexesOfCategoriesToRemove.push(_paysIndex)
            else
                _filteredOu[_paysIndex].label = t('search.paysLabel');

            if (_filteredOu[_etablissementIndex].items.length == 0)
                _indexesOfCategoriesToRemove.push(_etablissementIndex)
            else
                _filteredOu[_etablissementIndex].label = t('search.etablissementLabel');


            _indexesOfCategoriesToRemove.sort(function (a, b) { return b - a });
            _indexesOfCategoriesToRemove.forEach(i => {
                _filteredOu.splice(i, 1);
            });
            if (_filteredOu.length == 0) {
                showNoResult()
            }

            setGroupedOu(_filteredOu);

            setFilteredOu(_filteredOu);
        });
    }
    const handleClicRecherche = (e) => {
        e.preventDefault()
        let _lazyParams = { ...lazyParams };
        _lazyParams.filters = filters;
        setLazyParams(_lazyParams);
        statistiqueService.StatRechercheSimple2(props.currentUser, _lazyParams)
        try {
            history.push({
                pathname: '/rechercheAffinee',
                state: { filters }
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeQuoi = (value) => {
        if (typeof (value) === "object" && value !== null) {
            let _filters = [...filters];
            for (var i = _filters.length - 1; i >= 0; --i) {
                if (_filters[i].categorie === 0 || _filters[i].categorie === 1 || _filters[i].categorie === 2 || _filters[i].categorie === 3) {
                    _filters.splice(i, 1);
                }
            }
            if (value.categorie === 0)
                value.exige = false;
            if (value.categorie === 0 || value.categorie === 1)
                value.alias = true;
            _filters.push(value);
            setFilters(_filters);
        }
        setSelectedQuoi(value);
    }
    const handleChangeOu = (value) => {
        if (typeof (value) === "object" && value !== null) {
            let _filters = [...filters];
            for (var i = _filters.length - 1; i >= 0; --i) {
                if (_filters[i].categorie === 4 || _filters[i].categorie === 5 || _filters[i].categorie === 6 || _filters[i].categorie === 7 || _filters[i].categorie === 8 || _filters[i].categorie === 9) {
                    _filters.splice(i, 1);
                }
            }
            _filters.push(value);
            setFilters(_filters);
        }
        setSelectedOu(value);
    }
    const template = (item) => {
        return (
            <span className='ssb-item'>
                {item.label}
            </span>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <div className={`ssb-main ${props.className}`}>
                <div className={`ssb-submain ${props.appWithLimit}`}>
                    <div className="ssb-fields-container">
                        <h3 className="ssb-submain-title">
                            <Trans i18nKey='search.catchphrase' />
                        </h3>
                        <div className='ssb-fields-wraper'>
                            <div >
                                <div className="ssb-field p-pr-2 ssb-search-wrapper">
                                    <span className="p-input-icon-right icon-search-position">
                                        <i className="pi pi-search" />
                                        <AutoComplete
                                            className="ssb-autocomplete"
                                            placeholder={t('search.what_placeholder')}
                                            inputClassName="ssb-inputrounded"
                                            minLength="3"
                                            value={selectedQuoi}
                                            suggestions={filteredQuoi}
                                            completeMethod={searchQuoi}
                                            field="label"
                                            delay="1000"
                                            optionGroupLabel="label"
                                            optionGroupChildren="items"
                                            itemTemplate={template}
                                            optionGroupTemplate={template}
                                            onChange={(e) => handleChangeQuoi(e.value)} />
                                    </span>
                                </div>
                                <div className="ssb-field p-pr-2 ssb-search-wrapper">
                                    <span className="p-input-icon-right icon-search-position">
                                        <i className="pi pi-map-marker" />
                                        <AutoComplete
                                            className="ssb-autocomplete"
                                            // disabled={rechercheOuDisabled}
                                            placeholder={t('search.where_placeholder')}
                                            inputClassName="ssb-inputrounded"
                                            minLength="3"
                                            value={selectedOu}
                                            suggestions={filteredOu}
                                            completeMethod={searchOu}
                                            field="label"
                                            delay="1000"
                                            optionGroupLabel="label"
                                            optionGroupChildren="items"
                                            itemTemplate={template}
                                            optionGroupTemplate={template}
                                            onChange={(e) => handleChangeOu(e.value)} />
                                    </span>
                                </div>
                            </div>
                            <Tooltip target={".ssb-disabled-button"} position={'bottom'}>
                                <Trans i18nKey='search.select_at_least_one_value' />
                            </Tooltip>
                            <div className="ssb-search-button">
                                <div>
                                    <a id='homepage-advanced-search' href={"rechercheAffinee"} className='sbb-advanced-search'><Trans i18nKey='search.advanced_search' /></a>
                                </div>
                                <span className={filters.length == 0 && "ssb-disabled-button"} data-pr-disabled={filters.length != 0}>
                                    <Button
                                        id="homepage-search-simply-button"
                                        className="dip-btn-bk-bg p-button-rounded ssb-button-results"
                                        label={t('search.see_results')}
                                        disabled={filters.length == 0}
                                        icon="pi pi-search"
                                        onClick={(e) => handleClicRecherche(e)}
                                        style={{ width: "auto" }} 
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};