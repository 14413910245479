import axios from 'axios';
import BaseService from './baseService';
import RoleHelper from '../utils/roleHelper';

export default class StatistiquesService extends BaseService {



  fingerprint() {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    var txt = 'i9asdm..$#po((^@KbXrww!~cz';
    ctx.textBaseline = "top";
    ctx.font = "16px 'Arial'";
    ctx.textBaseline = "alphabetic";
    ctx.rotate(.05);
    ctx.fillStyle = "#f60";
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = "#069";
    ctx.fillText(txt, 2, 15);
    ctx.fillStyle = "rgba(102, 200, 0, 0.7)";
    ctx.fillText(txt, 4, 17);
    ctx.shadowBlur = 10;
    ctx.shadowColor = "blue";
    ctx.fillRect(-20, 10, 234, 5);
    var strng = canvas.toDataURL();

    var hash = 0;
    if (strng.length == 0) return 'nothing!';
    for (var i = 0; i < strng.length; i++) {
      var char = strng.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash;
    }
    return hash;
  }

  StatPageAccueil(currentUser, history) {

    let currentUserType = RoleHelper.isNotConnected(currentUser) ? 0 : (RoleHelper.isEtudiant(currentUser) ? 1 : (RoleHelper.isEntreprise(currentUser) ? 2 : -1));

    if (currentUserType != -1) {
      var hash = '0'
      if (currentUserType == 0) {
        hash = this.fingerprint();
      }
      else {
        hash = currentUser?.user.id
      }
      const httpParams =  {path : history.location.search};

      const options = {
        headers: { 'Content-Type': 'application/json' }
      };
      return axios.post(window.API_URL + '/api/Statistiques/StatPageAccueil/?userHash=' + hash + '&' + 'userType=' + currentUserType, httpParams, options).then(res => res.data);
    }

  }

  StatFormationConsultation(currentUser, formationId, history) {
    let currentUserType = RoleHelper.isNotConnected(currentUser) ? 0 : (RoleHelper.isEtudiant(currentUser) ? 1 : (RoleHelper.isEntreprise(currentUser) ? 2 : -1));

    if (currentUserType != -1) {
      var hash = '0'
      if (currentUserType == 0) {
        hash = this.fingerprint();
      }
      else {
        hash = currentUser?.user.id
      }

      const httpParams =  {path : history.location.search};

      const options = {
        headers: { 'Content-Type': 'application/json' }
      };
      return axios.post(window.API_URL + '/api/Statistiques/StatFormation/?userHash=' + hash + '&' + 'userType=' + currentUserType + '&' + 'formationId=' + formationId + '&' + 'consultationType=3', httpParams, options).then(res => res.data);
    }
  }

  StatFormationResultat(currentUser, formationId, history) {
    let currentUserType = RoleHelper.isNotConnected(currentUser) ? 0 : (RoleHelper.isEtudiant(currentUser) ? 1 : (RoleHelper.isEntreprise(currentUser) ? 2 : -1));

    if (currentUserType != -1) {
      var hash = '0'
      if (currentUserType == 0) {
        hash = this.fingerprint();
      }
      else {
        hash = currentUser?.user.id
      }
      const httpParams =  {path : history.location.search};

      const options = {
        headers: { 'Content-Type': 'application/json' }
      };
      return axios.post(window.API_URL + '/api/Statistiques/StatFormation/?userHash=' + hash + '&' + 'userType=' + currentUserType + '&' + 'formationId=' + formationId + '&' + 'consultationType=2', httpParams, options).then(res => res.data);
    }

  }

  StatFormationAccueil(currentUser, formationId, history) {
    let currentUserType = RoleHelper.isNotConnected(currentUser) ? 0 : (RoleHelper.isEtudiant(currentUser) ? 1 : (RoleHelper.isEntreprise(currentUser) ? 2 : -1));

    if (currentUserType != -1) {
      var hash = '0'
      if (currentUserType == 0) {
        hash = this.fingerprint();
      }
      else {
        hash = currentUser?.user.id
      }
      const httpParams =  {path : history.location.search};

      const options = {
        headers: { 'Content-Type': 'application/json' }
      };
      return axios.post(window.API_URL + '/api/Statistiques/StatFormation/?userHash=' + hash + '&' + 'userType=' + currentUserType + '&' + 'formationId=' + formationId + '&' + 'consultationType=1', httpParams, options).then(res => res.data);
    }

  }

  StatRechercheSimple(currentUser, ou, quoi) {
    let currentUserType = RoleHelper.isNotConnected(currentUser) ? 0 : (RoleHelper.isEtudiant(currentUser) ? 1 : (RoleHelper.isEntreprise(currentUser) ? 2 : -1));

    if (currentUserType != -1) {
      var hash = '0'
      if (currentUserType == 0) {
        hash = this.fingerprint();
      }
      else {
        hash = currentUser?.user.id
      }
      return axios.get(window.API_URL + '/api/Statistiques/StatRechercheSimple/?userHash=' + hash + '&' + 'userType=' + currentUserType + '&' + 'ou=' + ou + '&' + 'quoi=' + quoi).then(res => res.data);
    }

  }


  StatRechercheSimple2(currentUser, lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };

    let currentUserType = RoleHelper.isNotConnected(currentUser) ? 0 : (RoleHelper.isEtudiant(currentUser) ? 1 : (RoleHelper.isEntreprise(currentUser) ? 2 : -1));

    if (currentUserType != -1) {
      var hash = '0'
      if (currentUserType == 0) {
        hash = this.fingerprint();
      }
      else {
        hash = currentUser?.user.id
      }
      
      return this.axiosApiInstance.post(window.API_URL + '/api/Statistiques/StatRechercheSimple/?userHash=' + hash + '&' + 'userType=' + currentUserType, JSON.stringify(data), options);
    }
  }


  StatCookieChoose(currentUser, accepted, firstSubmission, history) {
    let currentUserType = RoleHelper.isNotConnected(currentUser) ? 0 : (RoleHelper.isEtudiant(currentUser) ? 1 : (RoleHelper.isEntreprise(currentUser) ? 2 : -1));

    if (currentUserType != -1) {
      var hash = '0'
      if (currentUserType == 0) {
        hash = this.fingerprint();
      }
      else {
        hash = currentUser?.user.id
      }

      const httpParams =  {path : history.location.search};

      const options = {
        headers: { 'Content-Type': 'application/json' }
      };
      return axios.post(window.API_URL + '/api/Statistiques/StatCookieChoose/?userHash=' + hash + '&'  + 'userType=' + currentUserType + '&' + 'accepted=' + accepted + '&' + 'firstSubmission=' + firstSubmission, httpParams, options).then(res => res.data);
    }

  }

  GetFormationByLast30Jours(formationId) {
    return this.axiosApiInstance.get(window.API_URL + '/api/Statistiques/GetFormationByLast30Jours/?formationId=' + formationId)
    .then(res => res.data)
    .catch(err => super.handleHttpError(err));;
  }

  GetStatFormationByMois(formationId, date) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { 
        formationId: formationId,
        date:date
       }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/Statistiques/GetStatFormationByMois', options)
    .then(res => res.data)
    .catch(err => super.handleHttpError(err));;
  }

}
