//STD + trans + Auth
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next"
import { useAuthState } from "../../context/context";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

//Prime react
import { Toast } from "primereact/toast";

//Project Module
import { RechercheCandidatsResultats } from "./rechercheCandidatsResultats";
import { NbResultsAndOrder } from "./bar/nbResultsAndOrderBar";

//Services
import RechercheService from "../../services/rechercheService";

//CSS + Icon
import "./rechercheCandidats.css"
import RecrutementRechercheContext from "./recrutement-RechercheContext";


export const RechercheCandidats = () => {

    const { t } = useTranslation();
    const toast = useRef(null);
    const location = useLocation();
    const history = useHistory();

    const rechercheService = new RechercheService();

    const [loading, setLoading] = useState(true);

    const [candidats, setCandidats] = useState([]);

    const [recrutementId, setRecrutementId] = useState(null)
    const [loadingButton, setLoadingButton] = useState(false);

    const [totalCandidatsRecords, setTotalCandidatsRecords] = useState(0)
    const [tokenResetFilters, setTokenResetFilters] = useState(null);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 6,
        page: 0,
        sortField: 'Pertinence',
        sortOrder: 1,
        filters: [{ categorie: 12, value: true }]
    });

    const categorieRecherche = {
        Niveau: 100,
        TypeContrat: 101,
        DebutContrat: 102,
        DuréeContrat: 103,

        CompetenceLiee: 104,

        Competence: 0,
        Appellation: 1,
        Formation: 2,
        Diplome: 3,
        Ville: 4,
        Departement: 5,
        Region: 6,
        Pays: 7,
        Composante: 8,
        Ecole: 9,

        Alternance: 13,
        FormationContinue: 14,
        FormationInitiale: 15,
        AccessibleADistance: 16,

        DipDomaine: 25,
        DipMetier: 26
    }

    const _lazyParamsDefault = {
        first: 0,
        rows: 8,
        page: 0,
        sortField: 'Pertinence',
        sortOrder: 1,
        filters: [{ categorie: 12, value: true }]
    };

    useEffect(() => {
        if (history.location.state && history.location.state.filters) {
            let _lazyParams = { ...lazyParams };
            _lazyParams.filters = history.location.state.filters
            setLazyParams(_lazyParams);
            loadLazyData(_lazyParams);
            if (history.location.state.idRecrutement) {
                setRecrutementId(history.location.state.idRecrutement)
            }
        }
        else {

            setLazyParams(_lazyParamsDefault);
            loadLazyData(_lazyParamsDefault);
        }
    }, [location]);


    const loadLazyData = (_lazyParams) => {
        onRechercheChanged(_lazyParams);
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const onRechercheChanged = (_lazyParams, _typeRecherche) => {
        let _newLazyParams = _lazyParams
        _newLazyParams.first = 0;
        _newLazyParams.page = 0;
        setLazyParams(_newLazyParams)
        setLoading(true);

        rechercheService.getRechercheCandidats(_newLazyParams)
            .then((data) => {
                setCandidats(data.items);
            })
            .catch(err => showError())
            .finally(() => setLoading(false))

        rechercheService.
            getRechercheCandidatsCount(_newLazyParams)
            .then((totalCount) => {
                setTotalCandidatsRecords(totalCount);
            })
            .catch(err => showError())
            .finally(() => { })
    }

    const handleClicEffacerFiltres = (e) => {
        e.preventDefault()
        try {
            // setSelectedMobilite(null)
            // setSelectedPosteRecherche(null)
            setTokenResetFilters(new Date())
            contextRechercheRecrutement.setLazyParams(_lazyParamsDefault)
            contextRechercheRecrutement.rechercheApi(_lazyParamsDefault)
        } catch (error) {
            console.log(error);
        }
    }

    const onClickMoreCandidats = () => {
        setLoadingButton(true);
        let _lazyParams = { ...lazyParams };
        _lazyParams.page += 1;
        _lazyParams.first += _lazyParams.rows;
        setLazyParams(_lazyParams);


        rechercheService.getRechercheCandidats(_lazyParams)
            .then((data) => {
                const _candidats = [...candidats];
                const _totalcandidats = _candidats.concat(data.items);
                setCandidats(_totalcandidats);
            })
            .catch(err => showError())
            .finally(() => setLoadingButton(false))

    }

    const contextRechercheRecrutement = {
        lazyParams,
        setLazyParams,
        rechercheApi: onRechercheChanged,
        categorieRecherche,
        totalCandidatsRecords,
        SetInitialValue: () => { },
        SetInitialValues: () => { },
        OnchangeSingle: () => { },
        OnchangeMultiple: () => { },
        tokenResetFilters
    }

    contextRechercheRecrutement.SetInitialValue = (categorieRecherche) => {
        let _value = null
        let _filters = [...contextRechercheRecrutement.lazyParams.filters]
        _filters.forEach(element => {
            if (element.categorie == categorieRecherche) {
                if (categorieRecherche == contextRechercheRecrutement.categorieRecherche.DebutContrat) {
                    _value = new Date(element.value);
                }
                else {
                    _value = element.value;
                }
            }
        })
        return _value
    }

    contextRechercheRecrutement.SetInitialValues = (categorieRecherche) => {
        let _values = []
        let _filters = [...contextRechercheRecrutement.lazyParams.filters]
        _filters.forEach(element => {
            if (element.categorie == categorieRecherche) {
                _values.push(element.value);
            }
        })
        return _values
    }

    contextRechercheRecrutement.OnchangeSingle = (e, categorieRecherche) => {
        if (e.target) {
            e.preventDefault()
        }
        try {
            let _filters = [...contextRechercheRecrutement.lazyParams.filters];
            let _indexToRemove = [];
            // seek niveau to remove
            _filters.forEach((element, index) => {
                if (element.categorie == categorieRecherche) {
                    _indexToRemove.push(index)
                }
            });
            //remove existing filte
            for (var i = _indexToRemove.length - 1; i >= 0; i--) {
                _filters.splice(_indexToRemove[i], 1);
            }
            //push new values
            if (e.value !== null) {
                _filters.push({ categorie: categorieRecherche, value: e.value });
            }
            let _lazyParams = { ...contextRechercheRecrutement.lazyParams };
            _lazyParams.filters = _filters;
            contextRechercheRecrutement.setLazyParams(_lazyParams);
            console.log(_lazyParams);

        } catch (error) {
            console.log(error);
        }
    }

    contextRechercheRecrutement.OnchangeMultiple = (e, categorieRecherche) => {
        if (e.target) {
            e.preventDefault()
        }
        try {
            let _filters = [...contextRechercheRecrutement.lazyParams.filters];
            let _indexToRemove = [];
            // seek niveau to remove
            _filters.forEach((element, index) => {
                if (element.categorie == categorieRecherche) {
                    _indexToRemove.push(index)
                }
            });
            //remove existing filte
            for (var i = _indexToRemove.length - 1; i >= 0; i--) {
                _filters.splice(_indexToRemove[i], 1);
            }
            //push new values
            if (e.value !== null) {
                e.value.forEach(element => {
                    _filters.push({ categorie: categorieRecherche, value: element });
                });
            }
            let _lazyParams = { ...contextRechercheRecrutement.lazyParams };
            _lazyParams.filters = _filters;
            contextRechercheRecrutement.setLazyParams(_lazyParams);
            console.log(_lazyParams);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <RecrutementRechercheContext.Provider value={contextRechercheRecrutement}>
            <div>
                <Toast ref={toast} />
                <div className="candidat-search-main-container">
                    <div className='candidat-search-title-container'>
                        <h1><Trans i18nKey='search.candidats_search' /></h1>
                    </div>
                    <NbResultsAndOrder
                        handleClicEffacerFiltres={handleClicEffacerFiltres}
                        recrutementId={recrutementId}
                    />
                    <RechercheCandidatsResultats
                        candidats={candidats}
                        loading={loading}
                        loadingButton={loadingButton}
                        lazyParams={lazyParams}
                        onClickMoreCandidats={onClickMoreCandidats}
                        toast={toast}
                    />
                </div>
            </div>
        </RecrutementRechercheContext.Provider>
    )
}