import { Button } from "primereact/button"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import './noLongerAvailable.css'

export const NoLongerAvailable = () => {

    const { t } = useTranslation()
    const history = useHistory()

    const goToHome = () => {
        history.push('/')
    }

    return (
        <div className="nla-container">
            <div><label>{t('errors.no_longer_available')}</label></div>
            <br/>
            <Button className="dip-color-white dip-bg-color-tc" label={t('menu.home')} onClick={goToHome}></Button>
        </div>
    )
}