import { classNames } from "primereact/utils";
import { Paginator } from "primereact/paginator";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import ScrollToAnchor from "../scroll/scrollToAnchor";
import ScrollToAnchorDropdown from "../scroll/scrollToAnchorDropdown";
import "./thumbnailPaginator.css"

export const ThumbnailPaginator = (props) => {

    const [ddlOptions, setDdlOptions] = useState(Array.from({ length: Math.floor(props.totalRecords / props.lazyParams.rows) + 1 }, (_, i) => i + 1));

    useEffect(() => {
        setDdlOptions(Array.from({ length: Math.floor(props.totalRecords / props.lazyParams.rows) + 1 }, (_, i) => i + 1))
    }, [props.totalRecords])


    const onPageChange = (event) => {
        let _lazyParamsTemp = {
            ...props.lazyParams,
            first: event.first,
            rows: event.rows,
            page: event.page + 1
        };
        props.setLazyParams(_lazyParamsTemp);

        if (props.onClickChangePage) {
            props.onClickChangePage(_lazyParamsTemp)
        }
    }

    const onPageInputChange = (event) => {
        const first = props.lazyParams.page ? props.lazyParams.rows * (event.target.value - 1) : 0;
        let _lazyParamsTemp = {
            ...props.lazyParams,
            page: event.target.value,
            first: first
        }
        props.setLazyParams(_lazyParamsTemp);

        if (props.onClickChangePage) {
            props.onClickChangePage(_lazyParamsTemp)
        }
    }

    const template = {
        layout: 'PrevPageLink PageLinks NextPageLink',
        'PrevPageLink': (options) => {
            return (
                <ScrollToAnchor btnId="top" type="button" icon="pi pi-angle-left" className={" dip-btn-iris-bg"} onClick={options.onClick} />
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <ScrollToAnchor btnId="top" label={options.page + 1} type="button" className={options.className} onClick={options.onClick} />
            )
        },
        'NextPageLink': (options) => {
            return (
                <ScrollToAnchor btnId="top" type="button" icon="pi pi-angle-right" className={" dip-btn-iris-bg"} onClick={options.onClick} />
            )
        },
        // 'CurrentPageReport': (options) => {
        //     return (
        //         <span className="mx-3 p-ml-2" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
        //             <Trans i18nKey="general.go_to" />
        //             <ScrollToAnchorDropdown btnId="top" value={props.lazyParams.page} type="dropdown" className="p-ml-2" onChange={onPageInputChange} options={ddlOptions} />
        //         </span>
        //     )
        // }
    };

    return (
        <Paginator className={`thumbnail-paginator`} template={template} first={props.lazyParams.first} rows={props.lazyParams.rows} totalRecords={props.totalRecords} onPageChange={onPageChange} ></Paginator>
    )
}