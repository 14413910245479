import React, { useState, useEffect, useRef } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import Helper from '../../utils/helper';
import FormationService from '../../services/formationService';

export const OrganismeFormationSelector = (props) => {

    const service = new FormationService();
    const { t } = useTranslation();
    const toast = useRef(null);

    const [filteredOrganismeFormation, setFilteredOrganismeFormation] = useState([props.value]);


    const searchOrganismeFormation = (event) => {
        service.getOrganismeFormationFiltered(event.query)
            .then(res => {
                setFilteredOrganismeFormation(res);
                if (res.length == 0) {
                    props.onFound(false)
                } else {
                    props.onFound(true)
                }
            })
            .catch((err) => {
                props.onFound(false)
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 });
            })
            .finally(() => {
                props.onLoading(false);
            }
            );
    }

    const onOrganismeFormationChange = (val) => {
        props.onLoading(true);
        props.onChange(val);

    }

    const template = (item) => {
        return (
            <span className="ler-item" >
                {item?.nom}
            </span>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <AutoComplete
                className={props.className}
                panelClassName="ler-autcomplete-panel"
                value={props.value}
                suggestions={filteredOrganismeFormation}
                forceSelection={props.forceSelection}
                completeMethod={searchOrganismeFormation}
                field={"nom"}
                onChange={e => onOrganismeFormationChange(e.value)} placeholder={props.placeholder} minLength={3}
                appendTo="self"
                itemTemplate={template}
            />
        </>
    );

}
