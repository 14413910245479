import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import "./entrepriseProjets.css"
import RechercheEntrepriseOffres from "./RechercheEntrepriseOffres";
import { ThumbnailPaginator } from "../../components/paginator/thumbnailPaginator";
import { FilterPanelProjects } from "../../components/filterPanel/filterPanelProjects";
import { EntrepriseProjetBreadCrumb } from "../../components/breadCrumb/EntrepriseProjetBreadCrumb";
import { EntrepriseProjetThumbnail } from "../../components/thumbnail/entrepriseProjetThumbnail";
import ProjetService from "../../services/projetService";
import { EntrepriseProjetForm } from "./entrepriseProjetForm";
import EntrepriseService from "../../services/entrepriseService";

export const EntrepriseProjets = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [projets, setProjets] = useState([])
    const [totalProjetsRecords, setTotalProjetsRecords] = useState(null)
    const [tokenResetFilters, setTokenResetFilters] = useState(null);
    const [displayFilterPanel, setDisplayFilterPanel] = useState(false)
    const [displayAddProject, setDisplayAddProject] = useState(false);
    const [loading, setLoading] = useState(true)
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;
    const lazyParamsDefault = {
        first: 0,
        rows: 6,
        page: 1,
        sortField: 'Pertinence',
        sortOrder: 1,
        filters: []
    }
    const [lazyParams, setLazyParams] = useState(lazyParamsDefault);
    const projetService = new ProjetService();
    const entrepriseService = new EntrepriseService();

    const toast = useRef(null)

    useEffect(() => {
        loadLazyData(lazyParams);
    }, [lazyParams])

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const loadLazyData = (_lazyParams) => {
        setLoading(true);
        setTotalProjetsRecords(null);
        entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id) =>{
            projetService.getProjetsPaginated(_lazyParams,id)
            .then((data) => {
                setProjets(data.items);
                setTotalProjetsRecords(data.totalCount);
                setDisplayAddProject(false)
            })
            .catch(err => showError())
            .finally(() => setLoading(false))
        })
        .catch(err => showError())
    }

    const onShowAddProject = () => {
        setDisplayAddProject(true)
    }

    const onHideAddProject = () => {
        setDisplayAddProject(false)
    }

    const onSave = () => {
        setLazyParams(lazyParamsDefault)
    }


    const contextRecherche = {
        lazyParams,
        setLazyParams,
        rechercheApi: loadLazyData,
        totalProjetsRecords,
        tokenResetFilters
    }

    return (
        <RechercheEntrepriseOffres.Provider value={contextRecherche}>
            <div className={props.className}>
                <Toast ref={toast} />
                <EntrepriseProjetBreadCrumb step={0} />
                <div className="dip-flex" >
                    <div className="eo-filter-panel-margin eo-only-pc">
                        <FilterPanelProjects />
                    </div>
                    <div className="dip-w-100" >
                        <div className="dip-flex-row dip-jc-between p-mb-1">
                            <div id="top"><h1 className="dip-color-res-blue"><Trans i18nKey="entreprise.myProjects" /> {totalProjetsRecords && <span>({totalProjetsRecords})</span>}</h1></div>
                            <div className="dip-as-center">
                                <Button className="dip-btn-red-bg dip-only-pc" label="Ajouter un projet" icon="pi pi-plus" onClick={onShowAddProject} />
                                <Button className="dip-btn-red-bg dip-only-mobile" label="Ajouter" icon="pi pi-plus" onClick={onShowAddProject} />
                            </div>
                        </div>
                        <div>
                            {loading ? (
                                <div className="dip-ta-center">
                                    <ProgressSpinner />
                                </div>
                            ) : projets?.length > 0 ? (
                                <div>
                                    <div className="p-grid">
                                        {projets?.map((projet, indexProjet) => (
                                            <div className="p-col-12 p-sm-12 p-lg-6">
                                                <Fragment key={`${projet.id}~${indexProjet}`}>
                                                    <EntrepriseProjetThumbnail projet={projet} contextRecherche={contextRecherche} onSave={onSave} />
                                                </Fragment>
                                            </div>
                                        ))}
                                    </div>
                                    <ThumbnailPaginator lazyParams={lazyParams} setLazyParams={setLazyParams} totalRecords={totalProjetsRecords} />
                                </div>
                            ) : t('search.no_results_matching')}
                        </div>

                    </div>
                </div>
                <div className='p-jc-center p-mt-2 refined-search-float-button'>
                    <div>
                        <Button className="mspf-button-label p-shadow-8" onClick={() => setDisplayFilterPanel(true)} >
                            <span >{t('general.filters')}</span>
                        </Button>
                        <Dialog visible={displayFilterPanel} className="mpsf-dialog" onHide={() => setDisplayFilterPanel(false)}>
                            <FilterPanelProjects />
                        </Dialog>
                    </div>
                </div>
            </div>
            <Dialog header={t('project.creation')} className="ep-confirm-dialog" visible={displayAddProject} onHide={onHideAddProject}>
                <EntrepriseProjetForm onSave={onSave} />
            </Dialog>
        </RechercheEntrepriseOffres.Provider>
    )
}