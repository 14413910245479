import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { JobThumbnail } from "../components/thumbnail/jobThumbnail";
import { enumHttpError } from "../enums/enumHttpError";
import OffreEmploiService from "../services/offreEmploiService";
import Helper from "../utils/helper";
import { OffreEmploiThumbnail } from "../components/thumbnail/offreEmploiThumbnail";
import { useHistory } from "react-router";

export const Candidatures = (props) => {
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const toast = useRef(null);
    const [offresEmploi, setOffresEmploi] = useState([]);
    const [error, setError] = useState(null);
    const history = useHistory();
    const adminImpersonatingEtudiantId = history.location?.state?.adminImpersonatingEtudiantId;

    const offreEmploiService = new OffreEmploiService();

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 12,
        page: 0,
        sortField: 'dateCreation'
    })

    useEffect(() => {
        loadLazyData();

        return () => {
            // cleanup
            setOffresEmploi([]);
        }
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        offreEmploiService.getOffreEmploisCandidatures(lazyParams, adminImpersonatingEtudiantId)
            .then(_pagedjobOffers => {
                _pagedjobOffers.items.forEach(offer => {
                    offer.dateCreation = Helper.dateTimeToLocaleDateTime(offer.dateCreation)
                });
                setTotalCount(_pagedjobOffers.totalCount)
                setOffresEmploi([...offresEmploi, ..._pagedjobOffers.items]);
                setLoading(false);
            })
            .catch((err) => {
                switch (err.message) {
                    case enumHttpError.CANCELED:
                        setError(enumHttpError.CANCELED)
                        break;
                    default:
                        setError('failed');
                        setLoading(false);
                        break;
                }
            });
        // .finally(() => setLoading(false));
    }

    const seeMore = () => {
        setLazyParams({
            ...lazyParams,
            first: lazyParams.first + lazyParams.rows,
            page: lazyParams.page + 1
        });
    }

    return (
        <div className={props.className}>
            <Toast ref={toast} />
            <h2><Trans i18nKey='application.my_applications.full' /></h2>
            <div>
                <div>
                    <div className='p-grid'>
                        {offresEmploi.map((offreEmploi, indexOE) => (
                            <Fragment key={`${offreEmploi.id}~${indexOE}`}>
                                <div className="p-col-12 p-sm-12 p-md-6 p-lg-3">
                                    <OffreEmploiThumbnail offreEmploi={offreEmploi} toast={toast} />
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
                {offresEmploi.length > 0 && totalCount > (lazyParams.page + 1) * lazyParams.rows &&
                    <div className="see-more-button">
                        <Button loading={loading}
                            label={t('home.see_more')}
                            className="p-button-outlined p-button-danger p-button-rounded"
                            onClick={seeMore} />
                    </div>
                }
                {loading && <div className="center-element-oc"><ProgressSpinner /></div>}
                {!loading && offresEmploi.length == 0 && error == 'failed' && <div className="center-element-oc"><Trans i18nKey="offers.server_connexion_error" /></div>}
                {!loading && offresEmploi.length == 0 && error != 'failed' && <div className="center-element-oc"><Trans i18nKey="offers.no_application" /></div>}
            </div>
        </div >
    )
}