import { useAuthState } from "../context/context";
import { useHistory } from "react-router";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { enumHttpError } from "../enums/enumHttpError";
import MessagerieService from "../services/messagerieService";
import Helper from "../utils/helper";
import './messagerie.css';
import { Messagerie_Box } from '../pages/messagerie-box';
import { Messagerie_Bar } from '../pages/messagerie-bar';
import { enumBreakpoint } from "../enums/enumBreakpoint";


export const Messagerie = () => {
    const history = useHistory();
    const currentUser = useAuthState();
    const [totalCount, setTotalCount] = useState(0);
    const [threadId, setThreadId] = useState(null);
    const [token, setTokent] = useState(null);
    const [endpoint, setEndpoint] = useState(null);
    const [communicationId, setCommunicationId] = useState(null);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const toast = useRef(null);
    const [displayConv, setDisplayConv] = useState(false);
    const [displayMobile, setDisplayMobile] = useState(false);

    const messagerieService = new MessagerieService();

    useEffect(() => {
        tryParseJsonFilters();
        loadLazyData();
    }, []);


    const tryParseJsonFilters = () => {
        var query = new URLSearchParams(history.location.search);
        var thread = query.get('Thread');

        if (thread != "") {
            setThreadId(thread);
        }
    }

    const loadLazyData = () => {
        setLoading(true);
        let httpPromise;
        httpPromise = messagerieService.getMyInfo(currentUser);
        httpPromise
            .then(_communicationInfo => {
                setCommunicationId(_communicationInfo.userCommunicationIdentifier);
                setTokent(_communicationInfo.userToken);
                setEndpoint(_communicationInfo.communicationEnpoint)
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 })
            })
            .finally(() => setLoading(false));
    }

    const MessageReceivedListener = (message) => {

    }

    const MessageSentListener = (message) => {

    }

    const onMessagerieBarClick = (data) => {
        setThreadId(data)
        setDisplayConv(true);
        let _windowWidth = window.innerWidth;
        setDisplayMobile(!(_windowWidth >= enumBreakpoint.PC))

    }

    const onToggleClickMobile = () => {
        setDisplayConv(!displayConv);
        let _windowWidth = window.innerWidth;
        
            setDisplayMobile(!(_windowWidth >= enumBreakpoint))

    }

    return (
        <div>
            <Toast ref={toast} />
            {loading ? (
                <div className="chat-loading">
                    <ProgressSpinner />
                </div>
            ) : (
                <div className="p-grid">
                    {communicationId && token && endpoint &&  <div className={`p-xs-12 p-sm-12 p-lg-4 ${(!((displayMobile && !displayConv) || (!displayMobile))) && "chat-hidden"}`}>
                        <Messagerie_Bar toast={toast} threadId={threadId} onMessagerieBarClick={onMessagerieBarClick}>

                        </Messagerie_Bar>
                    </div>}
                    {threadId && communicationId && token && endpoint && ((displayMobile && displayConv) || (!displayMobile)) && <div className="p-xs-12 p-sm-12 p-lg-8 chat-box-msg">
                        <Messagerie_Box toast={toast} threadId={threadId} userId={communicationId} token={token} endpointUrl={endpoint} MessageReceivedListener={MessageReceivedListener} MessageSentListener={MessageSentListener} ToggleBar={onToggleClickMobile}>

                        </Messagerie_Box>
                    </div>}
                </div>)}
        </div>
    )
}