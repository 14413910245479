import React from "react";
import { enumTypeRechercheAffinee } from "../../enums/enumTypeRechercheAffinee";

export default React.createContext({
    lazyParams: {},
    setLazyParams: () => { },
    rechercheApi: () => { },
    totalFormationsRecords: 0,
    totalCandidatsRecords: 0,
    categorieRecherche: {},
    typeRecherche : enumTypeRechercheAffinee.FORMATION,
    setTypeRecherche : () => {},
    tokenResetFilters : null
});