export const enumPaireContrat = {
    ALTCA : "ALTCA",
    ALTCP : "ALTCP",
    CDD : "CDD",
    CDI : "CDI",
    CDIINTERIM : "CDIINTERIM",
    MISINTERIM : "MISINTERIM",
    PROCOM : "PROCOM",
    PROLIB: "PROLIB",
    STA:"STA"
}