import { Trans, useTranslation } from "react-i18next"
import workers from "../../images/3D/3d-business-man-and-woman-working-at-the-table.png";
import "./landingPageEntreprise.css"
import { Divider } from "primereact/divider";
import parse from 'html-react-parser';
import { enumRoles } from "../../enums/enumRoles";
import { Button } from "primereact/button";
import { FormRegister } from "../../components/form/formRegister";

export const LandingPageEntreprise = (props) => {

    const { t } = useTranslation();

    return (
        <div className="lpe-main">
            <div className="lpe-header-container">
                <h1 className="dip-color-white">
                    <div>{parse(t('home.company.catchphrase_first'))}</div>
                    <div>{parse(t('home.company.catchphrase_second'))}</div>
                </h1>
                <img src={workers} />
            </div>
            <div className={`${props.className} lpe-top-container`}>
                <div className="lpe-description">
                    <div>
                        {/* <div className="dip-ta-center lpe-logo-container"><img src={dipLogoEntrepriseTC} alt="Logo DiplomAdvisor en toutes lettres"></img></div> */}
                        <p className="dip-color-res-blue lpe-description-container">
                            <Trans i18nKey="entreprise.landing_page_description" />
                        </p>
                    </div>
                    <div className="dip-ta-center p-mt-6">
                        {/* <Link to={{ pathname: "/register?User=Company", state: { userType: enumRoles.ENTREPRISE } }}>
                            <Button className="dip-btn-red-bg" label={t('lets_go_module.title1')} />
                        </Link> */}
                        <a href="https://meetings.hubspot.com/patrick-musso/demo-da" target={"_blank"}><Button className="dip-btn-white-bg" label={t('subscription.ask_demo')} /></a>    
                    </div>
                </div>
                <div>
                    <Divider layout="vertical" className="lpe-divider" />
                </div>
                <div className="lpe-register">
                    {/* <FormCompanyContact /> */}
                    <div className="lpe-register-title"><Trans i18nKey={'home.company.catchphrase_register'} /></div>
                    <FormRegister className="auth-fieldset-nullifier" userType={enumRoles.ENTREPRISE} homeDesign={true} />
                </div>
            </div>
            {/* <div className="p-grid">
                <div className="p-col-12 p-sm-12 p-lg-12 dip-bg-color-pb lpe-margin-compensator">
                    <div className={props.className}>
                        <div className="dip-ta-center"><h2 className="dip-color-res-blue lpe-abonnement-title"><Trans i18nKey="subscription.the_subscriptions" /></h2></div>
                        <div className="p-grid lpe-abonnement-container">
                            <div className="p-col-12 p-sm-12 p-lg-4  p-p-3">
                                <AbonnementCard
                                    className="abonnement-card"
                                    title={t('subscription.discover')}
                                    price={t('subscription.free')}
                                    buttonLabel={t('general.discover')}
                                    abonnementFeatureList={abonnementFeatureList1}
                                />
                            </div>
                            <div className="p-col-12 p-sm-12 p-lg-4 p-p-3">
                                <AbonnementCard
                                    className="abonnement-card"
                                    title={t('subscription.recruiter_casual')}
                                    price={t('subscription.70_per_month')}
                                    buttonLabel={t('general.discover')}
                                    abonnementFeatureList={abonnementFeatureList2}
                                />
                            </div>
                            <div className="p-col-12 p-sm-12 p-lg-4 p-p-3">
                                <AbonnementCard
                                    className="abonnement-card abonnement-grey-card"
                                    title={t('subscription.recruiter_regular')}
                                    price={t('subscription.coming_soon')}
                                    buttonLabel={t('subscription.ask_quote')}
                                    abonnementFeatureList={abonnementFeatureList3}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}