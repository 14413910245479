import { SelectButton } from "primereact/selectbutton"
import { classNames } from "primereact/utils"
import { Trans, useTranslation } from "react-i18next"
import EntrepriseService from "../../../services/entrepriseService"
import { useEffect } from "react"
import { useState } from "react"
import parse from 'html-react-parser';
import { ProgressSpinner } from "primereact/progressspinner"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import "./presentationEntrepriseSurOffre.css";


export const PresentationEntrepriseSurOffre = (props) => {

    const { t } = useTranslation();
    const entrepriseService = new EntrepriseService()
    const history = useHistory();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;

    const [loading, setLoading] = useState(true)
    const [entreprise, setEntreprise] = useState(null)

    useEffect(() => {
        entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then(id => {
            entrepriseService.getEntreprisePresentation(id)
                .then((entreprise) => {
                    setEntreprise(entreprise)
                    if (entreprise?.description == null || entreprise?.description == '') {
                        props.disabledPresentation('false');
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }, [])

    const privacyOptions = [
        { name: 'Affichée', value: 'true' },
        { name: 'Masquée', value: 'false' }
    ]

    const onStatusChange = (e) => {
        props.onInputChange(e, 'afficherPresentation');
    }

    return (
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-col-12">
                <div className="dip-flex fai-title-and-select-container">
                    <h2 className="p-mr-2"><Trans i18nKey="advert.company_presentation_on_ad" /></h2>
                    <div>
                        <SelectButton
                            value={props.annonce?.afficherPresentation}
                            onChange={(e) => onStatusChange(e)}
                            options={privacyOptions}
                            optionLabel="name"
                            unselectable={false}
                            required
                            disabled={entreprise?.description == null || entreprise?.description == '' ? true : false}
                            className={`fai-selectbutton ${classNames({ 'p-invalid': props.submitted && !props.annonce?.active })}`}
                        />
                    </div>
                </div>
            </div>
            {loading == true ?
                <div className="dip-ta-center">
                    <ProgressSpinner />
                </div>
                :
                (props.annonce?.afficherPresentation == 'true' ?
                    <>
                        {entreprise?.nom && <div className="p-field p-col-12 p-sm-12 p-lg-2">
                            <label><Trans i18nKey='entreprise.name' /></label>
                            <div><span className="dip-color-res-blue">{entreprise?.nom}</span></div>
                        </div>}
                        {/* {entreprise?.phoneNumberEntreprise && <div className="p-field p-col-12 p-sm-12 p-lg-2">
                            <label><Trans i18nKey='general.phone' /></label>
                            <div><span className="dip-color-res-blue">{entreprise?.phoneNumberEntreprise}</span></div>
                        </div>} */}
                        {/* {entreprise?.localisation && <div className="p-field p-col-12 p-sm-12 p-lg-2">
                            <label><Trans i18nKey='geography.city' /></label>
                            <div><span className="dip-color-res-blue">{entreprise?.localisation}</span></div>
                        </div>} */}
                        {/* {entreprise?.secteurActivite && <div className="p-field p-col-12 p-sm-12 p-lg-2">
                            <label><Trans i18nKey='entreprise.activitySector' /></label>
                            <div><span className="dip-color-res-blue">{entreprise?.secteurActivite}</span></div>
                        </div>} */}
                        {entreprise?.description && <div className="p-field p-col-12 p-sm-12 p-lg-12">
                            <label><Trans i18nKey='entreprise.company_description' /></label>
                            <div><span className="dip-color-res-blue">{parse(entreprise?.description)}</span></div>
                        </div>}
                    </>
                    :
                    <div className="p-field p-col-12 p-sm-12 p-lg-12">
                        {entreprise?.description == null || entreprise?.description == '' &&
                            <i className="peso-infotext" >{parse(t('advert.company_presentation_on_ad_not_filled'))}</i>
                        }
                    </div>
                )}
        </div>
    )
}