import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import UserService from '../../services/userService';
import ComposanteService from '../../services/composanteService';
import EcoleService from '../../services/ecoleService';
import { useAuthState } from "../../context/context";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import './dt_ecoles_admins.css';
import { DT_AdministrateursEcole } from './dt_administrateursEcole';

export const DT_Ecoles_Admin = () => {

    let emptyAdministrateur = {
        id: 0,
        nom: '',
        prenom: '',
        email: '',
        ecole: '',
        composanteEcole: []
    };

    const { t } = useTranslation();

    const [newAdministrateurDialog, setNewAdministrateurDialog] = useState(true);

    const [administrateurs, setAdministrateurs] = useState([]);
    const [ecole, setEcole] = useState(null);

    const [expandedRows, setExpandedRows] = useState(null);

    const [administrateurDialog, setAdministrateurDialog] = useState(false);
    const [deleteAdministrateurDialog, setDeleteAdministrateurDialog] = useState(false);
    const [deleteAdministrateursDialog, setDeleteAdministrateursDialog] = useState(false);

    const [loadingAdministrateur, setLoadingAdministrateur] = useState(false);

    const [viewTypeAdmin, setViewTypeAdmin] = useState(t('administrator.ecole_admin'));
    const [viewTypesAdmin, setViewTypesAdmin] = useState([t('administrator.ecole_admin'), t('administrator.composanteEcole_admin')]);

    const [administrateur, setAdministrateur] = useState(emptyAdministrateur);
    const [selectedAdministrateurs, setSelectedAdministrateurs] = useState([]);

    const [submittedEcole, setSubmittedEcole] = useState(false);
    const [submittedComposante, setSubmittedComposante] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [ddlEcolesAdminEcole, setDdlEcolesAdminEcole] = useState([]);
    const [ddlEcolesAdminComposante, setDdlEcolesAdminComposante] = useState([]);
    const [ddlComposantes, setDdlComposantes] = useState([]);

    const [composantes, setComposantes] = useState([]);
    const [ecoles, setEcoles] = useState([]);

    const toast = useRef(null);

    const userService = new UserService();
    const ecoleService = new EcoleService();
    const composanteService = new ComposanteService();

    const currentUser = useAuthState();

    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    });
    const isMounted = useRef(false);



    useEffect(() => {
        isMounted.current = true;
        loadLazyData();
    }, [lazyParams]);


    const loadLazyData = () => {
        setLoading(true);

        ecoleService.getEcolesAdmin(currentUser).then(_ecoles => {
            setEcoles(_ecoles);
            setDdlEcolesAdminEcole(_ecoles);
            setDdlEcolesAdminComposante(_ecoles);
        });

        composanteService.getComposantesAdmin(currentUser).then(_composantes => {
            setDdlComposantes(_composantes);
            setComposantes(_composantes);
            setLoading(false);
            setNewAdministrateurDialog(false);
        });

    }

    const openNew = () => {
        setAdministrateur(emptyAdministrateur);
        setSubmittedComposante(false);
        setSubmittedEcole(false);
        setViewTypesAdmin([t('administrator.ecole_admin'), t('administrator.composanteEcole_admin')]);
        setAdministrateurDialog(true);
    }

    const hideDialogEcole = () => {
        setSubmittedEcole(false);
        setAdministrateurDialog(false);
    }
    const hideDialogComposante = () => {
        setSubmittedComposante(false);
        setAdministrateurDialog(false);
    }

    const hideDeleteAdministrateurDialog = () => {
        setDeleteAdministrateurDialog(false);
    }

    const hideDeleteAdministrateursDialog = () => {
        setDeleteAdministrateursDialog(false);
    }

    const onAdministrateurSelect = (administrateur) => {
        setSelectedAdministrateurs([...selectedAdministrateurs, administrateur]);
    }

    const onAdministrateurUnselect = (administrateur) => {
        var index = selectedAdministrateurs.indexOf(administrateur);
        if (index !== -1) {
            setSelectedAdministrateurs(selectedAdministrateurs.splice(index, 1));
        }
    }

    const saveAdministrateurEcole = () => {
        setSubmittedEcole(true);

        var bOk = (viewTypeAdmin === t('administrator.ecole_admin') && administrateur.ecole !== '')

        if (bOk && administrateur.nom.trim() && administrateur.prenom.trim() && administrateur.email.trim() && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(administrateur.email))) {
            let _administrateurs = [];
            if (administrateurs !== undefined) {
                _administrateurs = [...administrateurs];
            }
            let _administrateur = { ...administrateur };
            if (administrateur.id) {
                (async function updateAdmin() {
                    await userService.updateAdministrateurEcole(_administrateur)
                        .then((_administrateur) => {
                            const index = findIndexById(administrateur.id);
                            _administrateurs[index] = _administrateur;

                            setAdministrateurs(_administrateurs);
                            setAdministrateurDialog(false);
                            setAdministrateur(emptyAdministrateur);

                            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('administrator.updated_admin'), life: 3000 });

                            loadLazyData();
                            const _expandedRows = { ...expandedRows };
                            setExpandedRows(null);
                            setExpandedRows(_expandedRows);
                        })
                        .catch((err) => {
                            if (err.message.includes("User already exists")) {
                                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('administrator.email_already_exists'), life: 3000 });
                            }
                            else {
                                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('administrator.not_created_admin'), life: 3000 });
                            }
                        })
                        .finally(() => {
                            setAdministrateurDialog(false)
                        }
                        )
                })();
            }
            else {

                (async function addAdmin() {
                    await userService.createAdministrateurEcole(administrateur)
                        .then((_admin) => {
                            _administrateur.id = _admin.id;
                            //console.log(JSON.stringify(_administrateur));
                            _administrateurs.push(_administrateur);
                            setAdministrateurs(_administrateurs);

                            setAdministrateurDialog(false);
                            setAdministrateur(emptyAdministrateur);

                            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('administrator.created_admin'), life: 3000 });

                            loadLazyData();
                            const _expandedRows = { ...expandedRows };
                            setExpandedRows(null);
                            setExpandedRows(_expandedRows);
                        })
                        .catch((err) => {
                            if (err.message.includes("User already exists")) {
                                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('administrator.email_already_exists'), life: 3000 });
                            }
                            else {
                                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('administrator.not_created_admin'), life: 3000 });
                            }
                        })
                        .finally(() => {
                            setAdministrateurDialog(false)
                        }
                        )
                })();
            }
        }
    }

    const saveAdministrateurComposante = () => {
        setSubmittedComposante(true);

        var bOk = (viewTypeAdmin === t('administrator.composanteEcole_admin') && administrateur.composanteEcole?.length > 0)

        if (bOk && administrateur.nom.trim() && administrateur.prenom.trim() && administrateur.email.trim() && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(administrateur.email))) {
            let _administrateurs = [];
            if (administrateurs !== undefined) {
                _administrateurs = [...administrateurs];
            }
            let _administrateur = { ...administrateur };
            if (administrateur.id) {
                (async function updateAdmin() {
                    await userService.updateAdministrateurComposante(_administrateur)
                        .then((_administrateur) => {
                            const index = findIndexById(administrateur.id);
                            _administrateurs[index] = _administrateur;

                            setAdministrateurs(_administrateurs);
                            setAdministrateurDialog(false);
                            setAdministrateur(emptyAdministrateur);

                            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('administrator.updated_admin'), life: 3000 });

                            loadLazyData();
                            const _expandedRows = { ...expandedRows };
                            setExpandedRows(null);
                            setExpandedRows(_expandedRows);
                        })
                        .catch((err) => {
                            if (err.message.includes("User already exists")) {
                                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('administrator.email_already_exists'), life: 3000 });
                            }
                            else {
                                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('administrator.not_created_admin'), life: 3000 });
                            }
                        })
                        .finally(() => {
                            setAdministrateurDialog(false)
                        }
                        )
                })();
            }
            else {

                (async function addAdmin() {
                    await userService.createAdministrateurComposante(administrateur)
                        .then((_admin) => {
                            _administrateur.id = _admin.id;
                            //console.log(JSON.stringify(_administrateur));
                            _administrateurs.push(_administrateur);
                            setAdministrateurs(_administrateurs);

                            setAdministrateurDialog(false);
                            setAdministrateur(emptyAdministrateur);

                            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('administrator.created_admin'), life: 3000 });

                            loadLazyData();
                            const _expandedRows = { ...expandedRows };
                            setExpandedRows(null);
                            setExpandedRows(_expandedRows);
                        })
                        .catch((err) => {
                            if (err.message.includes("User already exists")) {
                                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('administrator.email_already_exists'), life: 3000 });
                            }
                            else {
                                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('administrator.not_created_admin'), life: 3000 });
                            }
                        })
                        .finally(() => {
                            setAdministrateurDialog(false)
                        }
                        )
                })();
            }
        }
    }

    const deleteAdministrateur = () => {
        (async function deleteAdmin() {
            let _administrateur = { ...administrateur };
            await userService.delete(_administrateur);
            let _administrateurs = administrateurs.filter(val => val.id !== administrateur.id);
            setAdministrateurs(_administrateurs);
            setDeleteAdministrateurDialog(false);
            setAdministrateur(emptyAdministrateur);
            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('administrator.deleted_admin'), life: 3000 });

        })();
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < administrateurs?.length; i++) {
            if (administrateurs[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const confirmDeleteSelected = () => {
        setDeleteAdministrateursDialog(true);
    }

    const deleteSelectedAdministrateurs = () => {
        (async function deleteAdmin() {
            let _administrateurs = administrateurs.filter(val => !selectedAdministrateurs.includes(val));
            selectedAdministrateurs.forEach(admin => {
                userService.delete(admin);
            });
            setAdministrateurs(_administrateurs);
            setDeleteAdministrateursDialog(false);
            setSelectedAdministrateurs(null);
            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('administrator.deleted_admins'), life: 3000 });
        })();
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _administrateur = { ...administrateur };
        _administrateur[`${name}`] = val;

        setAdministrateur(_administrateur);
    }

    const onEcoleChangeC = (e, name) => {

        const val = (e.target && e.target.value) || '';

        //onInputChange(e, name);

        var _ddlComposantes = [...composantes];
        var _ddlComposantes = _ddlComposantes.filter(o => o.ecoleId === e.value.id);
        setDdlComposantes(_ddlComposantes);
        let _administrateur = { ...administrateur };
        _administrateur.ecole = val;
        _administrateur.composanteEcole = [];
        setAdministrateur(_administrateur);

    }
    const onEcoleChangeE = (e, name) => {
        onInputChange(e, name);
    }

    const onComposanteChange = (e, name) => {
        onInputChange(e, name);
    }

    const onTypeAdminChange = (e) => {
        setViewTypeAdmin(e.value);
        let _administrateur = { ...administrateur };
        if (e.value === t('administrator.ecole_admin')) {
            _administrateur.composanteEcole = [];
            setAdministrateurDialog(true);
        }
        _administrateur.ecole = '';
        setEcole(null);
        setAdministrateurDialog(true);
        setAdministrateur(_administrateur);
    }

    const leftToolbarTemplate = () => {
        return (
            <div>
                <Button label={t('administrator.new_admin')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} disabled={newAdministrateurDialog} />
                {/* <Button label={t('general.delete')} icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedAdministrateurs || !selectedAdministrateurs?.length} /> */}
            </div>
        )
    }

    const ddlAdminEcole = (
        <div className="p-field">
            <label htmlFor="ddlEcoleAdminEcole"><Trans i18nKey="general.school" />*</label>
            <Dropdown id="ddlEcoleAdminEcole" value={administrateur.ecole} options={ddlEcolesAdminEcole} onChange={(e) => onEcoleChangeE(e, 'ecole')} optionLabel="nomEcole" placeholder={t('administrator.select_school')} filter resetFilterOnHide emptyFilterMessage={t('school.no_school_to_show')} />
            {submittedEcole && !administrateur?.ecole && <small className="p-error"><Trans i18nKey="formation.ecole_required" /></small>}
        </div>
    );
    const ddlAdminComposanteEcole = (
        <div>
            <div className="p-field">
                <label htmlFor="ddlEcoleAdminComposante"><Trans i18nKey="general.school" />*</label>
                <Dropdown id="ddlEcoleAdminComposante" value={administrateur.ecole} options={ddlEcolesAdminComposante} onChange={(e) => onEcoleChangeC(e, 'ecole')} optionLabel="nomEcole" placeholder={t('administrator.select_school')} filter resetFilterOnHide emptyFilterMessage={t('school.no_school_to_show')} />
                {submittedComposante && !administrateur?.ecole && <small className="p-error"><Trans i18nKey="formation.ecole_required" /></small>}
            </div>
            <div className="p-field">
                <label htmlFor="ddlComposante"><Trans i18nKey="school.components" />*</label>
                <MultiSelect id="ddlComposante" value={administrateur?.composanteEcole} options={ddlComposantes} onChange={(e) => onComposanteChange(e, 'composanteEcole')} optionLabel="nom" placeholder={t('administrator.select_components')} display="chip" filter={true} />
                {submittedComposante && administrateur?.composanteEcole?.length < 1 && <small className="p-error"><Trans i18nKey="formation.composanteEcole_required" /></small>}
            </div>
        </div>
    );

    const formulaireAdminEcole = (
        <div>
            <div className="p-field" style={{ textAlign: 'center', width: '40rem', margin: "2% auto" }}>
                <SelectButton value={viewTypeAdmin} options={viewTypesAdmin} onChange={(e) => onTypeAdminChange(e)} />
            </div>
            <div className="p-field">
                <label htmlFor="nom"><Trans i18nKey="administrator.lastname" /></label>
                <InputText id="nom" value={administrateur.nom} onChange={(e) => onInputChange(e, 'nom')} required autoFocus className={classNames({ 'p-invalid': submittedEcole && !administrateur.nom })} />
                {submittedEcole && !administrateur.nom && <small className="p-error"><Trans i18nKey="general.required" /></small>}
            </div>
            <div className="p-field">
                <label htmlFor="prenom"><Trans i18nKey="administrator.firstname" /></label>
                <InputText id="prenom" value={administrateur.prenom} onChange={(e) => onInputChange(e, 'prenom')} required rows={3} cols={20} className={classNames({ 'p-invalid': submittedEcole && !administrateur.prenom })} />
                {submittedEcole && !administrateur.prenom && <small className="p-error"><Trans i18nKey="general.required" /></small>}
            </div>
            {ddlAdminEcole}
            <div className="p-field">
                <label htmlFor="fonctions"><Trans i18nKey="administrator.functions" /></label>
                <InputText id="fonctions" value={administrateur.fonctions} onChange={(e) => onInputChange(e, 'fonctions')} rows={3} cols={20} />
            </div>
            <div className="p-field">
                <label htmlFor="email"><Trans i18nKey="administrator.professional_email" /></label>
                <InputText id="email" value={administrateur.email} onChange={(e) => onInputChange(e, 'email')} required rows={3} cols={20} className={classNames({ 'p-invalid': submittedEcole && (!administrateur.email || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(administrateur.email))) })} />
                {submittedEcole && !administrateur.email && <small className="p-error"><Trans i18nKey="general.required" /></small>}
                {submittedEcole && !administrateur.email && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(administrateur.email)) && <br />}
                {submittedEcole && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(administrateur.email)) && <small className="p-error"><Trans i18nKey="general.invalid_email" /></small>}
            </div>
            <div className="p-field">
                <label htmlFor="phoneNumber"><Trans i18nKey="administrator.phone" /></label>
                <InputText id="phoneNumber" value={administrateur.phoneNumber} onChange={(e) => onInputChange(e, 'phoneNumber')} required rows={3} cols={20} />
            </div>
        </div>
    );


    const formulaireAdminComposante = (
        <div>
            <div className="p-field" style={{ textAlign: 'center', width: '40rem', margin: "2% auto" }}>
                <SelectButton value={viewTypeAdmin} options={viewTypesAdmin} onChange={(e) => onTypeAdminChange(e)} />
            </div>
            <div className="p-field">
                <label htmlFor="nom"><Trans i18nKey="administrator.lastname" /></label>
                <InputText id="nom" value={administrateur.nom} onChange={(e) => onInputChange(e, 'nom')} required autoFocus className={classNames({ 'p-invalid': submittedComposante && !administrateur.nom })} />
                {submittedComposante && !administrateur.nom && <small className="p-error"><Trans i18nKey="general.required" /></small>}
            </div>
            <div className="p-field">
                <label htmlFor="prenom"><Trans i18nKey="administrator.firstname" /></label>
                <InputText id="prenom" value={administrateur.prenom} onChange={(e) => onInputChange(e, 'prenom')} required rows={3} cols={20} className={classNames({ 'p-invalid': submittedComposante && !administrateur.prenom })} />
                {submittedComposante && !administrateur.prenom && <small className="p-error"><Trans i18nKey="general.required" /></small>}
            </div>
            {ddlAdminComposanteEcole}
            <div className="p-field">
                <label htmlFor="fonctions"><Trans i18nKey="administrator.functions" /></label>
                <InputText id="fonctions" value={administrateur.fonctions} onChange={(e) => onInputChange(e, 'fonctions')} rows={3} cols={20} />
            </div>
            <div className="p-field">
                <label htmlFor="email"><Trans i18nKey="administrator.professional_email" /></label>
                <InputText id="email" value={administrateur.email} onChange={(e) => onInputChange(e, 'email')} required rows={3} cols={20} className={classNames({ 'p-invalid': submittedComposante && (!administrateur.email || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(administrateur.email))) })} />
                {submittedComposante && !administrateur.email && <small className="p-error"><Trans i18nKey="general.required" /></small>}
                {submittedComposante && !administrateur.email && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(administrateur.email)) && <br />}
                {submittedComposante && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(administrateur.email)) && <small className="p-error"><Trans i18nKey="general.invalid_email" /></small>}
            </div>
            <div className="p-field">
                <label htmlFor="phoneNumber"><Trans i18nKey="administrator.phone" /></label>
                <InputText id="phoneNumber" value={administrateur.phoneNumber} onChange={(e) => onInputChange(e, 'phoneNumber')} required rows={3} cols={20} />
            </div>
        </div>
    );

    const administrateurComposanteDialogFooter = (
        <div>
            <Button label={t('general.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialogComposante} />
            <Button label={t('general.save')} icon="pi pi-check" className="p-button-text" onClick={saveAdministrateurComposante} />
        </div>
    );
    const administrateurEcoleDialogFooter = (
        <div>
            <Button label={t('general.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialogEcole} />
            <Button label={t('general.save')} icon="pi pi-check" className="p-button-text" onClick={saveAdministrateurEcole} />
        </div>
    );
    const deleteAdministrateurDialogFooter = (
        <div>
            <Button label={t('general.no')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteAdministrateurDialog} />
            <Button label={t('general.yes')} icon="pi pi-check" className="p-button-text" onClick={deleteAdministrateur} />
        </div>
    );
    const deleteAdministrateursDialogFooter = (
        <div>
            <Button label={t('general.no')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteAdministrateursDialog} />
            <Button label={t('general.yes')} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedAdministrateurs} />
        </div>
    );
    const headerEcole = (
        <div className="table-header">
            <h5 className="title"><Trans i18nKey="administrator.admins_composante_ecole_management.full" /></h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
            </span>
        </div>
    );

    const dtAdministrateurs = (ecole) => {
        return (
            <DT_AdministrateursEcole ecoleId={ecole.id}
                setAdministrateurDialog={setAdministrateurDialog}
                setLoadingAdministrateur={setLoadingAdministrateur}
                setAdministrateur={setAdministrateur}
                onAdministrateurUnselect={onAdministrateurUnselect}
                onAdministrateurSelect={onAdministrateurSelect}
                setViewTypesAdmin={setViewTypesAdmin}
                setViewTypeAdmin={setViewTypeAdmin}
                composantes={composantes}
                setDdlComposantes={setDdlComposantes}
            />
        );
    };


    return (
        <div className="datatable-administrateurs">
            <Toast ref={toast} />

            <div className="card">
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
                <DataTable
                    value={ecoles}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={dtAdministrateurs}
                    dataKey="id" header={headerEcole}
                    globalFilter={globalFilter}
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={t('component.footer_count_pagin')}
                    loading={loading}
                >
                    <Column expander style={{ width: '3em' }} />
                    <Column field="nomEcole" header={t('component.name')} sortable />
                </DataTable>

            </div>

            <Dialog visible={administrateurDialog} style={{ maxHeight: "99%", width: "99%" }} header={t('administrator.details_admin')} modal className="p-fluid" footer={viewTypeAdmin === t('administrator.ecole_admin') ? administrateurEcoleDialogFooter : administrateurComposanteDialogFooter} onHide={hideDialogEcole}>
                {viewTypeAdmin === t('administrator.ecole_admin') ?
                    formulaireAdminEcole : formulaireAdminComposante
                }
            </Dialog>


            <Dialog visible={deleteAdministrateurDialog} style={{ width: '50rem' }} header={t('general.confirm')} modal footer={deleteAdministrateurDialogFooter} onHide={hideDeleteAdministrateurDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {administrateur && <span><Trans i18nKey="administrator.are_you_sure_delete_admin" /><b>{administrateur.email}</b><Trans i18nKey="general.question_symbol" /></span>}
                </div>
            </Dialog>

            <Dialog visible={deleteAdministrateursDialog} style={{ width: '450rem50px' }} header={t('general.confirm')} modal footer={deleteAdministrateursDialogFooter} onHide={hideDeleteAdministrateursDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {administrateur && <span><Trans i18nKey="administrator.are_you_sure_delete_selected_admins" /></span>}
                </div>
            </Dialog>
        </div>
    );
}
