import { useState } from "react"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { Trans, useTranslation } from "react-i18next"
import AuthService from '../../../../services/authService';
import { ActivateAccountDialog } from '../../../../scenes/activateAccountDialog'
import { EntrepriseProfileCard } from "../../../card/entrepriseProfileCard"

export const PersonnalInfoCompany = (props) => {

    const { t } = useTranslation();
    const [showDialogActivation, setShowDialogActivation] = useState(false);
    const authService = new AuthService();

    const askValidationEmail = () => {
        setShowDialogActivation(true);
        authService.askValidationEmail(props.recruteur?.email)
            .then(async response => {
            })
            .catch(error => {
                props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.try_again') });
            });
    }


    return (
        <div className="dip-bg-color-pb fcp-card-container">
            <ActivateAccountDialog show={showDialogActivation} email={props.recruteur?.email} closeEvent={() => setShowDialogActivation(false)} />

            <div className="dip-flex">
                <div className="ec-margin">
                    <EntrepriseProfileCard
                        entreprise={props.recruteur}
                        setEntreprise={props.setRecruteur}
                    />
                </div>
                <div className="fcp-right-container"><h1><Trans i18nKey="profile.personnalInfo" /></h1>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='nom'><Trans i18nKey='general.lastname' /></label>
                            <label>*</label>
                            <InputText id='nom'
                                value={props.recruteur?.nom}
                                onChange={(e) => props.onInputChange(e, 'nom')}
                                required
                                maxLength="80"
                                className={classNames({ 'p-invalid': props.submitted && !props.recruteur?.nom })}
                            />
                            {props.submitted && !props.recruteur?.nom && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='prenom'><Trans i18nKey='general.firstname' /></label>
                            <label>*</label>
                            <InputText id='prenom'
                                value={props.recruteur?.prenom}
                                onChange={(e) => props.onInputChange(e, 'prenom')}
                                required
                                maxLength="80"
                                className={classNames({ 'p-invalid': props.submitted && !props.recruteur?.prenom })} />
                            {props.submitted && !props.recruteur?.prenom && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                        </div>

                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='phoneNumber'><Trans i18nKey='general.phone' /></label>
                            <label>*</label>
                            <InputText id='phoneNumber'
                                value={props.recruteur?.phoneNumber}
                                onChange={(e) => props.onInputChange(e, 'phoneNumber')}
                                maxLength="13"
                                keyfilter={/^[0-9]+$/}
                                className={classNames({ 'p-invalid': props.submitted && !props.recruteur?.phoneNumber })}
                            />
                            {props.submitted && !props.recruteur?.phoneNumber && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='poste'><Trans i18nKey='general.job' /></label>
                            <InputText id='poste'
                                value={props.recruteur?.poste}
                                onChange={(e) => props.onInputChange(e, 'poste')}
                            />
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            <label htmlFor='email'><Trans i18nKey='entreprise.proMail' /></label>
                            <label>*</label>
                            <InputText id='email'
                                value={props.recruteur?.email}
                                onChange={(e) => props.onInputChange(e, 'email')}
                                disabled
                                maxLength="255"
                                className={classNames({ 'p-invalid': props.submitted && !props.recruteur?.email })}
                            />
                            {props.submitted && !props.recruteur?.email && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                            {!props.recruteur?.emailConfirmed && <small className="p-error"><Trans i18nKey="register.message_account_activation_email_company" /></small>}
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                            {!props.recruteur?.emailConfirmed && <div className="send-email-validation-btn"><Button label={t('register.receive_account_activation_email')} className="frs-button" onClick={askValidationEmail} /></div>}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}