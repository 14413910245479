import React, { useRef, useState, useContext, useEffect } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from "primereact/dropdown";
import { Trans, useTranslation } from 'react-i18next';
import "./menuSearchFilter.css"
import { ListeElementsRecherche } from '../listeElementsRecherche';
import { ListeElementsRechercheAvecClic } from '../listeElementsRechercheAvecClic';
import RechercheContext from '../RechercheContext';
import Helper from '../../../utils/helper';
import RoleHelper from "../../../utils/roleHelper";
import { useAuthState } from "../../../context/context";
import RechercheService from '../../../services/rechercheService';
import { enumSearchOuLabel } from '../../../enums/enumSearchOuLabel';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';
import { enumNiveauDiplome } from '../../../enums/enumNiveauDiplome';
import { enumNiveauEtude } from '../../../enums/enumNiveauEtude';
import { Tag } from 'primereact/tag';
import { MenuSearchTooltip } from './menuSearchTooltip';
import { FilterMultiSelect } from '../../../components/multiselect/filterMultiSelect';
import { Checkbox } from 'primereact/checkbox';
import { ListeElements } from '../listeElements';
import { DipTag } from '../../../components/tag/dipTag';
import { Slider } from 'primereact/slider';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { enumTypeRechercheAffinee } from '../../../enums/enumTypeRechercheAffinee';

export const MenuAccordionSearchFilter = (props) => {
    const selectedNiveauInitialValue = (categorieRecherche) => {
        let _selectedNiveau = []
        if (contextRecherche.lazyParams?.filters) {
            let _filters = [...contextRecherche.lazyParams?.filters]
            _filters.forEach(element => {
                if (element.categorie == categorieRecherche) {
                    _selectedNiveau.push(element.value);
                }
            })
        }
        return _selectedNiveau
    }

    const toast = useRef(null);

    const { t } = useTranslation();
    const currentUser = useAuthState();
    const contextRecherche = useContext(RechercheContext);
    const rechercheService = new RechercheService();
    const [recherches, setRecherches] = useState([]);
    const [rangeSetupMax, setRangeSetupMax] = useState([]);
    const [rangeSetup, setRangeSetup] = useState(rangeSetupMax);
    const [loadingCoutFormation, setLoadingCoutFormation] = useState(false);
    const [unselectTokenDipDomaine, setUnselectTokenDipDomaine] = useState(null)
    const [unselectTokenDipMetier, setUnselectTokenDipMetier] = useState(null)
    const [unselectTokenAppellation, setUnselectTokenAppellation] = useState(null)
    const [competencesPrisEnCompte, setCompetencesPrisEnCompte] = useState(contextRecherche?.lazyParams?.filters?.filter(function (obj) { return obj.categorie == contextRecherche.categorieRecherche.CompetenceLiee && obj.exige == true }))
    const [competencesIgnore, setCompetencesIgnore] = useState(contextRecherche?.lazyParams?.filters?.filter(function (obj) { return obj.categorie == contextRecherche.categorieRecherche.CompetenceLiee && obj.exige == false }))
    const history = useHistory();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId


    const dureeMois = [
        { value: '1' },
        { value: '2' },
        { value: '3' },
        { value: '4' },
        { value: '5' },
        { value: '6' }
    ];
    const months = [
        { calendrierStage: 1, label: t('months.january'), },
        { calendrierStage: 2, label: t('months.february'), },
        { calendrierStage: 3, label: t('months.march'), },
        { calendrierStage: 4, label: t('months.april'), },
        { calendrierStage: 5, label: t('months.may'), },
        { calendrierStage: 6, label: t('months.june'), },
        { calendrierStage: 7, label: t('months.july'), },
        { calendrierStage: 8, label: t('months.august'), },
        { calendrierStage: 9, label: t('months.september'), },
        { calendrierStage: 10, label: t('months.october'), },
        { calendrierStage: 11, label: t('months.november'), },
        { calendrierStage: 12, label: t('months.december'), }
    ]

    const niveauEntreeOptions = [
        { name: 'Bac', value: enumNiveauDiplome.BAC },
        { name: 'Bac+1', value: enumNiveauDiplome.BAC1 },
        { name: 'Bac+2', value: enumNiveauDiplome.BAC2 },
        { name: 'Bac+3', value: enumNiveauDiplome.BAC3 },
        { name: 'Bac+4', value: enumNiveauDiplome.BAC4 },
        { name: 'Bac+5', value: enumNiveauDiplome.BAC5 }
    ]

    const niveauSortieOptions = [
        { name: 'Bac+2', value: contextRecherche.typeRecherche == enumTypeRechercheAffinee.FORMATION ? enumNiveauDiplome.BAC2 : contextRecherche.typeRecherche == enumTypeRechercheAffinee.CANDIDAT ? enumNiveauEtude.BAC2 : enumNiveauDiplome.BAC2},
        { name: 'Bac+3', value: contextRecherche.typeRecherche == enumTypeRechercheAffinee.FORMATION ? enumNiveauDiplome.BAC3 : contextRecherche.typeRecherche == enumTypeRechercheAffinee.CANDIDAT ? enumNiveauEtude.BAC3 : enumNiveauDiplome.BAC3 },
        { name: 'Bac+4', value: contextRecherche.typeRecherche == enumTypeRechercheAffinee.FORMATION ? enumNiveauDiplome.BAC4 : contextRecherche.typeRecherche == enumTypeRechercheAffinee.CANDIDAT ? enumNiveauEtude.BAC4 : enumNiveauDiplome.BAC4 },
        { name: 'Bac+5 et plus', value: contextRecherche.typeRecherche == enumTypeRechercheAffinee.FORMATION ? enumNiveauDiplome.BAC5 : contextRecherche.typeRecherche == enumTypeRechercheAffinee.CANDIDAT ? enumNiveauEtude.BAC5 : enumNiveauDiplome.BAC5 }
    ]

    const filtreConfiguration = {
        [enumTypeRechercheAffinee.FORMATION]: [
            'NiveauDiplome',
            'TypeDiplome',
            'TypeContrat',
            'DipDomaine',
            'DipSousDomaine',
            'DipMetier',
            'Appellation',
            'Competences',
            'Localisation',
            'Formation',
            'Ecole',
            'Langue'
        ],
        [enumTypeRechercheAffinee.CANDIDAT]: [
            'NiveauDiplome',
            'TypeDiplome',
            'TypeContrat',
            'DipDomaine',
            'DipSousDomaine',
            'DipMetier',
            'Appellation',
            'Competences',
            'Localisation',
            'Formation',
            'Ecole',
            'Langue',
            'RechercheActive'
        ]
    };

    const filtresActuels = filtreConfiguration[contextRecherche.typeRecherche];

    useEffect(() => {
        let _activeIndexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
        props.setActiveIndex(_activeIndexes);

        searchMinMaxCoutFormation({ ...contextRecherche.lazyParams });
    }, [contextRecherche.tokenResetFilters])

    const handleChange = (recherche) => {
        if (typeof (recherche) === "object" && recherche !== null) {
            if (!Helper.containsValueAndCategorie([...contextRecherche.lazyParams.filters], recherche.value, recherche.categorie)) {
                let _recherches = [...recherches];
                if (recherche.categorie == 0)
                    recherche.exige = false;
                _recherches.push(recherche);
                setRecherches(_recherches);
                try {
                    let _lazyParams = { ...contextRecherche.lazyParams };
                    let _filters = [...contextRecherche.lazyParams.filters];
                    _recherches.forEach(recherche => {
                        _filters.push(recherche);
                    });
                    _lazyParams.filters = _filters;
                    // contextRecherche.setLazyParams(_lazyParams);
                    // contextRecherche.rechercheApi(_lazyParams);
                    majResults(_lazyParams);

                } catch (error) {
                    console.log(error);
                }
                finally {
                    setRecherches([]);
                }
            }
        }
    }

    const handleChangeNiveauEntree = (e) => {
        e.preventDefault()
        try {
            let _filters = [...contextRecherche.lazyParams.filters];

            // seek niveau to remove and remove existing filter
            removeNumberTagFilter(_filters, contextRecherche.categorieRecherche.NiveauEntree);

            //push new values
            if (e.value !== null) {
                e.value.forEach(element => {
                    _filters.push({ categorie: contextRecherche.categorieRecherche.NiveauEntree, value: element });
                });
            }
            let _lazyParams = { ...contextRecherche.lazyParams };
            _lazyParams.first = 0;
            _lazyParams.page = 1;
            _lazyParams.filters = _filters;
            majResults(_lazyParams);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeNiveauSortie = (e) => {
        e.preventDefault()
        try {
            let _filters = [...contextRecherche.lazyParams.filters];

            // seek niveau to remove and remove existing filter
            removeNumberTagFilter(_filters, contextRecherche.categorieRecherche.NiveauSortie);

            //push new values
            if (e.value !== null) {
                e.value.forEach(element => {
                    _filters.push({ categorie: contextRecherche.categorieRecherche.NiveauSortie, value: element });
                });
            }
            let _lazyParams = { ...contextRecherche.lazyParams };
            _lazyParams.first = 0;
            _lazyParams.page = 1;
            _lazyParams.filters = _filters;
            majResults(_lazyParams);
        } catch (error) {
            console.log(error);
        }
    }

    const searchMinMaxCoutFormation = (lazyParams) => {
        setLoadingCoutFormation(true);
        rechercheService.getMinMaxCostFormation(lazyParams).then(res => {
            if(res.length == 0){
                res = [0, 0];
            }
            setRangeSetupMax(res);
            setRangeSetup(res);
            setLoadingCoutFormation(false);
        }).catch(error => {
            console.log(error);
        });
    }

    const majResults = (_lazyParams) => {
 
        searchMinMaxCoutFormation(_lazyParams);

        // avant de faire la recherche, si l'index coutFormation et present dans le filtre, on le supprime
        let _filters = [..._lazyParams.filters];
        let indexCoutFormation = Helper.findIndexByCategorie(_filters, contextRecherche.categorieRecherche.CoutFormation);
        if(indexCoutFormation !== -1){
            removeNumberTagFilter(_filters, contextRecherche.categorieRecherche.CoutFormation);
            _lazyParams.filters = _filters;
            setRangeSetup(rangeSetupMax);
        }
        
        _lazyParams.first = 0;
        _lazyParams.page = 1;
        contextRecherche.setLazyParams(_lazyParams);
        contextRecherche.rechercheApi(_lazyParams);
    }

    const removeNumberTagFilter = (__filters, categorieRecherche) => {
        // seek niveau to remove
        let _indexToRemove = [];
        __filters.forEach((element, index) => {
            if (element.categorie == categorieRecherche) {
                _indexToRemove.push(index)
            }
        });
        //remove existing filte
        for (var i = _indexToRemove.length - 1; i >= 0; i--) {
            __filters.splice(_indexToRemove[i], 1);
        }
    }

    const onCheckChange = (e, categorie) => {

        let __lazyParams = { ...contextRecherche.lazyParams };
        let __filters = [...contextRecherche.lazyParams.filters];
        let _bMaj = true;
        switch (categorie) {
            case contextRecherche.categorieRecherche.Stage:
                const indexStage = Helper.findIndexByCategorie(__filters, contextRecherche.categorieRecherche.Stage);
                if (indexStage === -1)
                    __filters.push({ categorie: contextRecherche.categorieRecherche.Stage, value: e, disabled: true });
                else {
                    __filters[indexStage] = { categorie: contextRecherche.categorieRecherche.Stage, value: e, disabled: true };
                    removeNumberTagFilter(__filters, contextRecherche.categorieRecherche.Stage);
                }
                if (!e) {
                    const indexDuree = Helper.findIndexByCategorie(__filters, contextRecherche.categorieRecherche.DureeStage);
                    if (indexDuree !== -1)
                        __filters.splice(indexDuree, 1);
                    const indexAPartirDe = Helper.findIndexByCategorie(__filters, contextRecherche.categorieRecherche.APartirDe);
                    if (indexAPartirDe !== -1)
                        __filters.splice(indexAPartirDe, 1);
                }
                else {
                    _bMaj = false;
                }
                break;
            case contextRecherche.categorieRecherche.CoutFormation:
                const indexCoutFormation = Helper.findIndexByCategorie(__filters, contextRecherche.categorieRecherche.CoutFormation);
                if (indexCoutFormation === -1) { // check false
                    onSliderCoutFormationChange(rangeSetup, contextRecherche.categorieRecherche.CoutFormation)
                    return;
                }
                else { // check true
                    removeNumberTagFilter(__filters, contextRecherche.categorieRecherche.CoutFormation);
                }
                break;
            default:
                let index = Helper.findIndexByCategorie(__filters, categorie);
                if (index === -1)
                    __filters.push({ categorie: categorie, value: e });
                else {
                    __filters[index] = { categorie: categorie, value: e };
                    removeNumberTagFilter(__filters, categorie);
                }
                break;
        }
        __lazyParams.filters = __filters;
        contextRecherche.setLazyParams(__lazyParams);
        if (_bMaj)
            majResults(__lazyParams);
        // contextRecherche.rechercheApi(__lazyParams);
    };

    const onSliderCoutFormationChange = (e, categorie) => {
        let _lazyParams = { ...contextRecherche.lazyParams };
        let _filters = [...contextRecherche.lazyParams.filters];
        let _value = e[0] + "-" + e[1];

        const indexTrouve = Helper.findIndexByCategorie(_filters, categorie);
        if (indexTrouve === -1)
            _filters.push({ categorie: categorie, value: _value, isChecked: true });
        else {
            removeNumberTagFilter(_filters, categorie);
            _filters.push({ categorie: categorie, value: _value, isChecked: true });
        }
        _lazyParams.filters = _filters;
        contextRecherche.setLazyParams(_lazyParams);    
        contextRecherche.rechercheApi(_lazyParams);
    };

    const handleChangeDuree = (value) => {

        let _lazyParams = { ...contextRecherche.lazyParams };
        let _filters = [...contextRecherche.lazyParams.filters];
        const indexTrouve = Helper.findIndexByCategorie(_filters, contextRecherche.categorieRecherche.DureeStage);
        if (indexTrouve === -1)
            _filters.push({ categorie: contextRecherche.categorieRecherche.DureeStage, value: value });
        else
            _filters[indexTrouve] = { categorie: contextRecherche.categorieRecherche.DureeStage, value: value };
        _lazyParams.filters = _filters;
        // contextRecherche.setLazyParams(_lazyParams);
        // contextRecherche.rechercheApi(_lazyParams);
        majResults(_lazyParams);
    }
    const handleChangeMonth = (value) => {

        let _lazyParams = { ...contextRecherche.lazyParams };
        let _filters = [...contextRecherche.lazyParams.filters];
        const indexTrouve = Helper.findIndexByCategorie(_filters, contextRecherche.categorieRecherche.APartirDe);
        if (indexTrouve === -1)
            _filters.push({ categorie: contextRecherche.categorieRecherche.APartirDe, value: value.calendrierStage });
        else
            _filters[indexTrouve] = { categorie: contextRecherche.categorieRecherche.APartirDe, value: value.calendrierStage };
        _lazyParams.filters = _filters;
        // contextRecherche.setLazyParams(_lazyParams);
        // contextRecherche.rechercheApi(_lazyParams);
        majResults(_lazyParams);
    }

    const handleChangeDiplomeType = (values, mustCleanUp) => {

        let _lazyParams = { ...contextRecherche.lazyParams };
        let _filters = [...contextRecherche.lazyParams.filters];
        _filters = _filters.filter((item) => item.categorie != contextRecherche.categorieRecherche.Diplome);
        if (mustCleanUp) {
            _filters = _filters.filter((item) => item.categorie != contextRecherche.categorieRecherche.Appellation);
        }
        values.forEach(e => {
            _filters.push(e)
        })
        _lazyParams.filters = _filters;
        // contextRecherche.setLazyParams(_lazyParams);
        // contextRecherche.rechercheApi(_lazyParams);
        majResults(_lazyParams);
    }

    const handleChangeLangueType = (values, mustCleanUp) => {
        let _lazyParams = { ...contextRecherche.lazyParams };
        let _filters = [...contextRecherche.lazyParams.filters];
        _filters = _filters.filter((item) => item.categorie != contextRecherche.categorieRecherche.Langue);
        if (mustCleanUp) {
            _filters = _filters.filter((item) => item.categorie != contextRecherche.categorieRecherche.Appellation);
        }
        values.forEach(e => {
            _filters.push(e)
        })
        _lazyParams.filters = _filters;
        // contextRecherche.setLazyParams(_lazyParams);
        // contextRecherche.rechercheApi(_lazyParams);
        majResults(_lazyParams);
    }

    const handleChangeDipDomaineMultiSelect = (values, mustCleanUp) => {
        let _lazyParams = { ...contextRecherche.lazyParams };
        let _filters = [...contextRecherche.lazyParams.filters];
        _filters = _filters.filter((item) => item.categorie != contextRecherche.categorieRecherche.DipDomaine);
        if (mustCleanUp) {
            _filters = _filters.filter((item) => item.categorie != contextRecherche.categorieRecherche.DipMetier && item.categorie != contextRecherche.categorieRecherche.Appellation);
        }
        values.forEach(e => {
            _filters.push(e)
        })
        _lazyParams.filters = _filters;
        // contextRecherche.setLazyParams(_lazyParams);
        // contextRecherche.rechercheApi(_lazyParams);
        majResults(_lazyParams);
    }

    const handleChangeDipMetierMultiSelect = (values, mustCleanUp) => {
        let _lazyParams = { ...contextRecherche.lazyParams };
        let _filters = [...contextRecherche.lazyParams.filters];
        _filters = _filters.filter((item) => item.categorie != contextRecherche.categorieRecherche.DipMetier);
        if (mustCleanUp) {
            _filters = _filters.filter((item) => item.categorie != contextRecherche.categorieRecherche.Appellation);
        }
        values.forEach(e => {
            _filters.push(e)
        })
        _lazyParams.filters = _filters;
        // contextRecherche.setLazyParams(_lazyParams);
        // contextRecherche.rechercheApi(_lazyParams);
        majResults(_lazyParams);
    }

    const handleChangeMultiSelect = (values,categorie) => {
        let _lazyParams = { ...contextRecherche.lazyParams };
        let _filters = [...contextRecherche.lazyParams.filters];
        _filters = _filters.filter((item) => item.categorie != categorie);
        values.forEach(e => {
            _filters.push(e)
        })
        _lazyParams.filters = _filters;
        // contextRecherche.setLazyParams(_lazyParams);
        // contextRecherche.rechercheApi(_lazyParams);
        majResults(_lazyParams);
    }

    const clearCompetencesStates = () => {
        setCompetencesPrisEnCompte([]);
        setCompetencesIgnore([]);
    }

    const showNoResult = () => {
        toast.current.show({ severity: 'warn', summary: t('warnings.noResult'), detail: t('warnings.checkInputOrElse'), life: 3000 });
    }

    //search functions
    const searchOrganisme = (query) => {
        return rechercheService.getRechercheOrganisme(query).then(res => {
            if (res.length == 0) {
                showNoResult()
            }
            return res
        });
    }
    const searchLocalisation = (query) => {

        let isLocalisationFavoriteEntreprise = false;
        if (props.isInFavoris)
            isLocalisationFavoriteEntreprise = true;

        return rechercheService.getRechercheLocalisation(query, isLocalisationFavoriteEntreprise).then(res => {
            let stringifyRechercheOu = JSON.stringify(res);
            stringifyRechercheOu = stringifyRechercheOu.replace('villesLabel', t('search.villesLabel'));
            stringifyRechercheOu = stringifyRechercheOu.replace('departementsLabel', t('search.departementsLabel'));
            stringifyRechercheOu = stringifyRechercheOu.replace('regionsLabel', t('search.regionsLabel'));
            stringifyRechercheOu = stringifyRechercheOu.replace('paysLabel', t('search.paysLabel'));
            stringifyRechercheOu = stringifyRechercheOu.replace('etablissementLabel', t('search.etablissementLabel'));

            let _villesIndex = res.map(function (e) { return e.label }).indexOf(enumSearchOuLabel.VILLE)
            let _departementsIndex = res.map(function (e) { return e.label }).indexOf(enumSearchOuLabel.DEPARTEMENT)
            let _regionsIndex = res.map(function (e) { return e.label }).indexOf(enumSearchOuLabel.REGION)
            let _paysIndex = res.map(function (e) { return e.label }).indexOf(enumSearchOuLabel.PAYS)
            let _indexesOfCategoriesToRemove = [];

            if (res[_villesIndex].items.length == 0)
                _indexesOfCategoriesToRemove.push(_villesIndex)
            else
                res[_villesIndex].label = t('search.villesLabel');

            if (res[_departementsIndex].items.length == 0)
                _indexesOfCategoriesToRemove.push(_departementsIndex)
            else
                res[_departementsIndex].label = t('search.departementsLabel');

            if (res[_regionsIndex].items.length == 0)
                _indexesOfCategoriesToRemove.push(_regionsIndex)
            else
                res[_regionsIndex].label = t('search.regionsLabel');

            if (res[_paysIndex].items.length == 0)
                _indexesOfCategoriesToRemove.push(_paysIndex)
            else
                res[_paysIndex].label = t('search.paysLabel');

            _indexesOfCategoriesToRemove.sort(function (a, b) { return b - a });
            _indexesOfCategoriesToRemove.forEach(i => {
                res.splice(i, 1);
            });
            if (res.length == 0) {
                showNoResult()
            }
            return res
        });
    }

    const searchAppellation = (query) => {
        return rechercheService.getRechercheAppellationFunnel(query, contextRecherche.lazyParams).then(res => {
            if (res.length == 0 && showNoResult()) {
                showNoResult()
            }
            return res
        });
    }

    const searchCompetence = (query) => {
        return rechercheService.getRechercheCompetenceSavoirSavoirFaire(query).then(res => {
            if (res.length == 0) {
                showNoResult()
            }
            return res
        });
    }

    const tabHeaderTemplate = (name, values, text) => {
        return (
            <div className='msf-tab-header'>
                <div className='msf-tab-subheader'>
                    <div className="msf-tab-title-filter">{name}</div>
                    {text && <MenuSearchTooltip className="p-ml-2" text={text}></MenuSearchTooltip>}
                </div>
                <div>{contextRecherche.lazyParams?.filters?.filter(o => values.includes(o.categorie)).length > 0 && <Tag>{contextRecherche.lazyParams?.filters?.filter(o => values.includes(o.categorie)).length}</Tag>}</div>
            </div>
        )
    }

    return (
        <div>
            <Toast ref={toast} />
            {props.isInFavoris ? (
                <Accordion className="msf-sub-accordion" multiple activeIndex={props.activeIndex} onTabChange={(e) => { props.setActiveIndex(e.index) }} expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
                    {/* Projet */}
                    <AccordionTab header={tabHeaderTemplate(t('project.projects'), [contextRecherche.categorieRecherche.Projet])}>
                        <FilterMultiSelect
                            className='msf-diplome-multiselect msf-diplome-multiselect-company'
                            values={[contextRecherche.categorieRecherche.Projet]}
                            placeholder={t('search.select') + '...'}
                            contextRecherche={contextRecherche}
                            onChange={(e) => handleChangeMultiSelect(e,contextRecherche.categorieRecherche.Projet)}
                            label='projetType'
                        />
                    </AccordionTab>
                    {/* Localisation Campus */}
                    <AccordionTab header={tabHeaderTemplate(t('general.location'), [contextRecherche.categorieRecherche.Ville])}>
                        <FilterMultiSelect
                            className='msf-diplome-multiselect msf-diplome-multiselect-company'
                            values={[contextRecherche.categorieRecherche.Ville]}
                            placeholder={t('search.select') + '...'}
                            contextRecherche={contextRecherche}
                            onChange={(e) => handleChangeMultiSelect(e,contextRecherche.categorieRecherche.Ville)}
                            label='villeType'
                        />
                    </AccordionTab>
                    {/* niveau de diplome */}
                    <AccordionTab header={tabHeaderTemplate(t('formation.diplom_level.base'), [contextRecherche.categorieRecherche.NiveauSortie])}>
                        <SelectButton
                            className='msf-selectbutton msf-level-diplom'
                            options={niveauSortieOptions}
                            value={selectedNiveauInitialValue(contextRecherche.categorieRecherche.NiveauSortie)}
                            optionLabel="name"
                            multiple
                            onChange={(e) => handleChangeNiveauSortie(e)}
                        />
                    </AccordionTab>
                    {/* type de contrat */}
                    <AccordionTab header={tabHeaderTemplate(t('offers.contract_type'), [contextRecherche.categorieRecherche.ContratStage, contextRecherche.categorieRecherche.ContratAltApp, contextRecherche.categorieRecherche.ContratAltPro, contextRecherche.categorieRecherche.ContratCDDCDI, contextRecherche.categorieRecherche.ContratAutres])}>
                        <div className="container-global-domaines">
                            {/* Stage */}
                            <div className='field-checkbox dip-flex-row dip-ai-center'>
                                <Checkbox
                                    inputId='ContratStage'
                                    className='msf-input-switch'
                                    checked={contextRecherche.lazyParams?.filters?.filter(o => o.categorie === contextRecherche.categorieRecherche.ContratStage)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.ContratStage)}
                                />
                                <label htmlFor='ContratStage' className="dip-flex-grow-1 msf-tab-title">
                                    <Trans i18nKey='search.stage' />
                                </label>
                            </div>
                            {/* Alternance Apprentissage */}
                            <div className='field-checkbox dip-flex-row dip-ai-center'>
                                <Checkbox
                                    inputId='ContratAltApp'
                                    className='msf-input-switch'
                                    checked={contextRecherche.lazyParams.filters?.filter(o => o.categorie === contextRecherche.categorieRecherche.ContratAltApp)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.ContratAltApp)}
                                />
                                <label htmlFor='ContratAltApp' className="dip-flex-grow-1 msf-tab-title">
                                    <Trans i18nKey='search.alt_app' />
                                </label>
                            </div>
                            {/* Alternance Pro */}
                            <div className='field-checkbox dip-flex-row dip-ai-center'>
                                <Checkbox
                                    inputId='ContratAltPro'
                                    className='msf-input-switch'
                                    checked={contextRecherche.lazyParams.filters?.filter(o => o.categorie === contextRecherche.categorieRecherche.ContratAltPro)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.ContratAltPro)}
                                />
                                <label htmlFor='ContratAltPro' className="dip-flex-grow-1 msf-tab-title">
                                    <Trans i18nKey='search.alt_pro' />
                                </label>
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>

            ) : RoleHelper.isEntreprise(currentUser) || adminImpersonatingRecruteurId ? (
                <Accordion className="msf-sub-accordion" multiple activeIndex={props.activeIndex} onTabChange={(e) => { props.setActiveIndex(e.index) }} expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
                
                {/* Niveau de diplôme */}
                {filtresActuels.includes('NiveauDiplome') && (
                    <AccordionTab header={tabHeaderTemplate(t('formation.diplom_level.base'), [contextRecherche.categorieRecherche.NiveauSortie])}>
                        <SelectButton
                            className='msf-selectbutton msf-level-diplom'
                            options={niveauSortieOptions}
                            value={selectedNiveauInitialValue(contextRecherche.categorieRecherche.NiveauSortie)}
                            optionLabel="name"
                            multiple
                            onChange={(e) => handleChangeNiveauSortie(e)}
                        />
                    </AccordionTab>
                )}
    
                {/* Type de diplôme */}
                {filtresActuels.includes('TypeDiplome') && (
                    <AccordionTab header={tabHeaderTemplate(t('formation.diplom_type'), [contextRecherche.categorieRecherche.Diplome])}>
                        <FilterMultiSelect
                            className='msf-diplome-multiselect'
                            values={[contextRecherche.categorieRecherche.Diplome]}
                            placeholder={t('search.select') + '...'}
                            contextRecherche={contextRecherche}
                            onChange={handleChangeDiplomeType}
                            label='diplomeType'
                        />
                    </AccordionTab>
                )}
    
                {/* Type de contrat */}
                {filtresActuels.includes('TypeContrat') && (
                    <AccordionTab header={tabHeaderTemplate(t('offers.contract_type'), [contextRecherche.categorieRecherche.ContratStage, contextRecherche.categorieRecherche.ContratAltApp, contextRecherche.categorieRecherche.ContratAltPro, contextRecherche.categorieRecherche.ContratCDDCDI, contextRecherche.categorieRecherche.ContratAutres])}>
                        <div className="container-global-domaines">
                            {/* Stage */}
                            <div className='field-checkbox dip-flex-row dip-ai-center'>
                                <Checkbox
                                    inputId='ContratStage'
                                    className='msf-input-switch'
                                    checked={contextRecherche.lazyParams?.filters?.filter(o => o.categorie === contextRecherche.categorieRecherche.ContratStage)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.ContratStage)}
                                />
                                <label htmlFor='ContratStage' className="dip-flex-grow-1 msf-tab-title">
                                    <Trans i18nKey='search.stage' />
                                </label>
                            </div>
                            {/* Alternance Apprentissage */}
                            <div className='field-checkbox dip-flex-row dip-ai-center'>
                                <Checkbox
                                    inputId='ContratAltApp'
                                    className='msf-input-switch'
                                    checked={contextRecherche.lazyParams.filters?.filter(o => o.categorie === contextRecherche.categorieRecherche.ContratAltApp)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.ContratAltApp)}
                                />
                                <label htmlFor='ContratAltApp' className="dip-flex-grow-1 msf-tab-title">
                                    <Trans i18nKey='search.alt_app' />
                                </label>
                            </div>
                            {/* Alternance Pro */}
                            <div className='field-checkbox dip-flex-row dip-ai-center'>
                                <Checkbox
                                    inputId='ContratAltPro'
                                    className='msf-input-switch'
                                    checked={contextRecherche.lazyParams.filters?.filter(o => o.categorie === contextRecherche.categorieRecherche.ContratAltPro)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.ContratAltPro)}
                                />
                                <label htmlFor='ContratAltPro' className="dip-flex-grow-1 msf-tab-title">
                                    <Trans i18nKey='search.alt_pro' />
                                </label>
                            </div>
                            {/* CDD / CDI */}
                            <div className='field-checkbox dip-flex-row dip-ai-center'>
                                <Checkbox
                                    inputId='ContratCDDCDI'
                                    className='msf-input-switch'
                                    checked={contextRecherche.lazyParams.filters?.filter(o => o.categorie === contextRecherche.categorieRecherche.ContratCDDCDI)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.ContratCDDCDI)}
                                />
                                <label htmlFor='ContratCDDCDI' className="dip-flex-grow-1 msf-tab-title">
                                    <Trans i18nKey='search.cdd_cdi' />
                                </label>
                            </div>
                            {/* Autres */}
                            <div className='field-checkbox dip-flex-row dip-ai-center'>
                                <Checkbox
                                    inputId='ContratAutres'
                                    className='msf-input-switch'
                                    checked={contextRecherche.lazyParams.filters?.filter(o => o.categorie === contextRecherche.categorieRecherche.ContratAutres)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.ContratAutres)}
                                />
                                <label htmlFor='ContratAutres' className="dip-flex-grow-1 msf-tab-title">
                                    <Trans i18nKey='search.others' />
                                </label>
                            </div>
                        </div>
                    </AccordionTab>
                )}
    
                {/* Domaine */}
                {filtresActuels.includes('DipDomaine') && (
                    <AccordionTab header={tabHeaderTemplate(t('search.domainesLabel'), [contextRecherche.categorieRecherche.DipDomaine])}>
                        <FilterMultiSelect
                            className='msf-diplome-multiselect'
                            values={[contextRecherche.categorieRecherche.DipDomaine]}
                            placeholder={t('search.select') + '...'}
                            contextRecherche={contextRecherche}
                            onChange={handleChangeDipDomaineMultiSelect}
                            label='domaineType'
                            triggerUnselectAll={unselectTokenDipDomaine}
                            setUnselectTokenDipMetier={setUnselectTokenDipMetier}
                            setUnselectTokenAppellation={setUnselectTokenAppellation}
                            onRemoveValeur={clearCompetencesStates}
                        />
                    </AccordionTab>
                )}
    
                {/* Sous domaine */}
                {filtresActuels.includes('DipSousDomaine') && (
                    <AccordionTab header={tabHeaderTemplate(t('search.sousDomainesLabel'), [contextRecherche.categorieRecherche.DipMetier])}>
                        <FilterMultiSelect
                            className='msf-diplome-multiselect'
                            values={[contextRecherche.categorieRecherche.DipMetier]}
                            placeholder={t('search.select') + '...'}
                            contextRecherche={contextRecherche}
                            onChange={handleChangeDipMetierMultiSelect}
                            label='metierType'
                            triggerUnselectAll={unselectTokenDipMetier}
                            setUnselectTokenAppellation={setUnselectTokenAppellation}
                            onRemoveValeur={clearCompetencesStates}
                        />
                    </AccordionTab>
                )}
    
                {/* Metier */}
                {filtresActuels.includes('DipMetier') && (
                    <AccordionTab header={tabHeaderTemplate(t('search.metiersLabel'), [contextRecherche.categorieRecherche.Appellation])}>
                        <ListeElementsRecherche
                            contextRecherche={contextRecherche}
                            filter
                            values={[contextRecherche.categorieRecherche.Appellation]}
                            onChange={handleChange}
                            onSearch={searchAppellation}
                            field='label'
                            placeholder={t('search.metiersPlaceholder')}
                            icon='pi pi-search'
                            triggerUnselectAll={unselectTokenAppellation}
                            onRemoveValeur={clearCompetencesStates}
                        />
                    </AccordionTab>
                )}
    
                {/* Compétences */}
                {filtresActuels.includes('Competences') && (
                    <AccordionTab header={tabHeaderTemplate(t('skills.skills'), [contextRecherche.categorieRecherche.Competence])}>
                        <ListeElementsRechercheAvecClic
                            values={[contextRecherche.categorieRecherche.Competence]}
                            onChange={handleChange}
                            onSearch={searchCompetence}
                            field='label'
                            placeholder={t('skills.search_skills')}
                            icon='pi pi-search'
                            />
                        </AccordionTab>
                    )}
        
                    {/* Localisation */}
                    {filtresActuels.includes('Localisation') && (
                        <AccordionTab header={tabHeaderTemplate(t('general.location'), [contextRecherche.categorieRecherche.Ville, contextRecherche.categorieRecherche.Departement, contextRecherche.categorieRecherche.Region, contextRecherche.categorieRecherche.Pays])}>
                            <ListeElementsRecherche
                                values={[contextRecherche.categorieRecherche.Ville, contextRecherche.categorieRecherche.Departement, contextRecherche.categorieRecherche.Region, contextRecherche.categorieRecherche.Pays]}
                                onChange={handleChange}
                                onSearch={searchLocalisation}
                                field="label"
                                placeholder={t('search.localiation_placeholder_filter')}
                                icon="pi pi-map-marker"
                                optionGroupLabel="label"
                                optionGroupChildren="items"
                            />
                        </AccordionTab>
                    )}
        
                    {/* Nom de la formation */}
                    {filtresActuels.includes('Formation') && (
                        <AccordionTab header={tabHeaderTemplate(t('search.search_formationLabel'), [contextRecherche.categorieRecherche.Formation])}>
                            <ListeElements
                                values={[contextRecherche.categorieRecherche.Formation]}
                                onChange={handleChange}
                                placeholder={t('search.search_formationPlaceholder')}
                                icon="pi pi-search"
                            />
                        </AccordionTab>
                    )}
        
                    {/* Ecole/Université */}
                    {filtresActuels.includes('Ecole') && (
                        <AccordionTab header={tabHeaderTemplate(t('search.school_universityLabel'), [contextRecherche.categorieRecherche.Composante, contextRecherche.categorieRecherche.Ecole])}>
                            <ListeElementsRecherche
                                values={[contextRecherche.categorieRecherche.Composante, contextRecherche.categorieRecherche.Ecole]}
                                onChange={handleChange}
                                onSearch={searchOrganisme}
                                field="label"
                                placeholder={t('search.school_universityPlaceholder')}
                                icon="pi pi-book"
                            />
                        </AccordionTab>
                    )}
        
                    {/* Langue d'enseignement */}
                    {filtresActuels.includes('Langue') && (
                        <AccordionTab header={tabHeaderTemplate(t('search.educationLanguageLabel'), [contextRecherche.categorieRecherche.Langue])}>
                            <FilterMultiSelect
                                className='msf-diplome-multiselect'
                                values={[contextRecherche.categorieRecherche.Langue]}
                                placeholder={t('search.ChoiceLanguagePlaceholder')}
                                contextRecherche={contextRecherche}
                                onChange={handleChangeLangueType}
                                label='langueType'
                            />
                        </AccordionTab>
                    )}

                    {/* Divers */}
                    {filtresActuels.includes('RechercheActive') && (
                        <AccordionTab header={tabHeaderTemplate(t('general.various'), [contextRecherche.categorieRecherche.RechercheActive])}>
                            <div>
                                {/* recherche active checkbox */}
                                <div className='msf-otheroptions-borderless-line-various-filter'>
                                    <Checkbox
                                        inputId='RechercheActive'
                                        className='msf-input-switch msf-checkbox-various-filter'
                                        checked={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.RechercheActive)[0]?.value || false}
                                        onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.RechercheActive)}
                                    />
                                    <div htmlFor='RechercheActive' className="field-checkbox msf-tab-title">
                                        <Trans i18nKey='profile.candidates_in_active_search' />
                                        <MenuSearchTooltip className="p-ml-2 msf-tooltip" text={t('tooltip.searchRechercheActive')}></MenuSearchTooltip>
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>
                    )}
                </Accordion>
            ) : (
                <Accordion className="msf-sub-accordion" multiple activeIndex={props.activeIndex} onTabChange={(e) => { props.setActiveIndex(e.index) }} expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
                    {/* niveau de diplome */}
                    <AccordionTab header={tabHeaderTemplate(t('formation.diplom_level.base'), [contextRecherche.categorieRecherche.NiveauSortie])}>
                        <SelectButton
                            className='msf-selectbutton msf-level-diplom'
                            options={niveauSortieOptions}
                            value={selectedNiveauInitialValue(contextRecherche.categorieRecherche.NiveauSortie)}
                            optionLabel="name"
                            multiple
                            onChange={(e) => handleChangeNiveauSortie(e)}
                        />
                    </AccordionTab>
                    {/* type de diplome */}
                    <AccordionTab header={tabHeaderTemplate(t('formation.diplom_type'), [contextRecherche.categorieRecherche.Diplome])}>
                        <FilterMultiSelect
                            className='msf-diplome-multiselect'
                            values={[contextRecherche.categorieRecherche.Diplome]}
                            placeholder={t('search.select') + '...'}
                            contextRecherche={contextRecherche}
                            onChange={handleChangeDiplomeType}
                            label='diplomeType'
                        />
                    </AccordionTab>
                    {/* modalité de suivi*/}
                    <AccordionTab header={tabHeaderTemplate(t('search.modaliteSuiviLabel'), [contextRecherche.categorieRecherche.Contrat, contextRecherche.categorieRecherche.Alternance, contextRecherche.categorieRecherche.AccessibleADistance, contextRecherche.categorieRecherche.EnLigne, contextRecherche.categorieRecherche.FormationContinue])}>
                        <div className="container-global-domaines">
                            {/* Stage durant la formation */}
                            <div className='field-checkbox dip-ai-center'>
                                <div className='dip-flex-row'>
                                    <Checkbox
                                        inputId='Stage'
                                        className='msf-input-switch'
                                        checked={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.Stage)[0]?.value || false}
                                        onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.Stage)}
                                    />
                                    <label htmlFor='Stage' className="dip-flex-grow-1 msf-tab-title">
                                        <Trans i18nKey='search.internship_during_course' />
                                    </label>
                                </div>
                                {contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.Stage)[0]?.value ? (
                                    <div className='msf-otheroptions'>
                                        <div className=" p-grid">
                                            <div className="p-fluid p-col-12 p-md-12">
                                                <label htmlFor="APartirDe" className="p-col-fixed" style={{ width: '100px' }}>
                                                    <Trans i18nKey='profile.from' />
                                                </label>
                                                <Dropdown value={months[contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.APartirDe)[0]?.value - 1] || null} options={months} onChange={(e) => handleChangeMonth(e.value)} optionLabel="label" editable />
                                            </div>
                                        </div>
                                        <div className=" p-grid">
                                            <div className="p-fluid p-col-12 p-md-12">
                                                <label htmlFor="DureeStage" className="p-col-fixed" style={{ width: '100px' }}>
                                                    <Trans i18nKey='profile.duration_in_months' />
                                                </label>
                                                <Dropdown value={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.DureeStage)[0]?.value || null} options={dureeMois} onChange={(e) => handleChangeDuree(e.value)} optionLabel="value" editable />
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}
                            </div>
                            {/* Formation en Alternance */}
                            <div className='field-checkbox dip-flex-row dip-ai-center'>
                                <Checkbox
                                    inputId='Alternance'
                                    className='msf-input-switch'
                                    checked={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.Alternance)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.Alternance)}
                                />
                                <label htmlFor='Alternance' className="dip-flex-grow-1 msf-tab-title">
                                    <Trans i18nKey='search.with_alternance' />
                                </label>
                            </div>
                            {/* Accessible à distance */}
                            <div className='field-checkbox dip-flex-row dip-ai-center'>
                                <Checkbox
                                    inputId='AccessibleADistance'
                                    className='msf-input-switch'
                                    checked={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.AccessibleADistance)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.AccessibleADistance)}
                                />
                                <label htmlFor='AccessibleADistance' className="dip-flex-grow-1 msf-tab-title">
                                    <Trans i18nKey='formation.remotely_accessible.base' />
                                </label>
                            </div>
                            {/* Formation Initiale */}
                            {/* <div className='field-checkbox dip-flex-row dip-ai-center'>
                            <Checkbox
                                inputId='FormationInitiale'
                                name='FormationInitiale'
                                className='msf-input-switch'
                                checked={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.FormationInitiale)[0]?.value || false}
                                onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.FormationInitiale)}
                            />
                            <label htmlFor='FormationInitiale' className="dip-flex-grow-1 msf-tab-title">
                                <Trans i18nKey='formation.initial_training' />
                            </label>
                        </div> */}
                            {/* Formation en ligne 100% */}
                            <div className='field-checkbox dip-flex-row dip-ai-center'>
                                <Checkbox
                                    inputId='FormationEnLigne'
                                    className='msf-input-switch'
                                    checked={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.EnLigne)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.EnLigne)}
                                />
                                <label htmlFor='FormationEnLigne' className="dip-flex-grow-1 msf-tab-title">
                                    <Trans i18nKey='formation.only_online_formation' />
                                </label>
                                <DipTag className="dip-tag-pink dip-fw-700 dip-tag-p-md" label={t('formation.hundred_percent_online')} />
                            </div>
                            {/* Formation Continue */}
                            <div className='field-checkbox dip-flex-row dip-ai-center'>
                                <Checkbox
                                    inputId='FormationContinue'
                                    className='msf-input-switch'
                                    checked={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.FormationContinue)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.FormationContinue)}
                                />
                                <label htmlFor='FormationContinue' className="dip-flex-grow-1 msf-tab-title">
                                    <Trans i18nKey='formation.continuous_training' />
                                </label>
                            </div>
                        </div>
                    </AccordionTab>
                    {/* Localisation */}
                    <AccordionTab header={tabHeaderTemplate(t('general.location'), [contextRecherche.categorieRecherche.Ville, contextRecherche.categorieRecherche.Departement, contextRecherche.categorieRecherche.Region, contextRecherche.categorieRecherche.Pays])}>
                        <ListeElementsRecherche
                            values={[contextRecherche.categorieRecherche.Ville, contextRecherche.categorieRecherche.Departement, contextRecherche.categorieRecherche.Region, contextRecherche.categorieRecherche.Pays]}
                            onChange={handleChange}
                            onSearch={searchLocalisation}
                            field="label"
                            placeholder={t('search.localiation_placeholder_filter')}
                            icon="pi pi-map-marker"
                            optionGroupLabel="label"
                            optionGroupChildren="items"
                        />
                    </AccordionTab>
                    {/* Ecole/Université */}
                    <AccordionTab header={tabHeaderTemplate(t('search.school_universityLabel'), [contextRecherche.categorieRecherche.Composante, contextRecherche.categorieRecherche.Ecole])}>
                        <ListeElementsRecherche
                            values={[contextRecherche.categorieRecherche.Composante, contextRecherche.categorieRecherche.Ecole]}
                            onChange={handleChange}
                            onSearch={searchOrganisme}
                            field="label"
                            placeholder={t('search.school_universityPlaceholder')}
                            icon="pi pi-book"
                        />
                    </AccordionTab>
                    {/* frais de scolarité */}
                    <AccordionTab header={tabHeaderTemplate(t('search.school_formation_cost'), [contextRecherche.categorieRecherche.CoutFormation])}>
                        <div className='p-mb-2'>
                            <Slider 
                                disabled={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.CoutFormation)[0]?.isChecked ? false : true}
                                className='p-mt-2 msf-slider'
                                value={rangeSetup} 
                                onChange={(e) => setRangeSetup(e.value)} 
                                onSlideEnd={(e) => onSliderCoutFormationChange(rangeSetup, contextRecherche.categorieRecherche.CoutFormation)} 
                                range 
                                min={rangeSetupMax[0]} 
                                max={rangeSetupMax[1]} 
                            />
                            <div className='dip-flex dip-jc-center p-mt-2'>
                                {loadingCoutFormation ? (   
                                    <ProgressSpinner strokeWidth='3' style={{ width: '30px', height: '30px' }} />                    
                                ) : (
                                    rangeSetup[0] != 0 && rangeSetup[1] != 0 && 
                                        <>{rangeSetup[0]}<Trans i18nKey='monnaie.euro' /> - {rangeSetup[1]}<Trans i18nKey='monnaie.euro' /></>
                                    )
                                }
                            </div>
                        </div>
                        <div className='msf-otheroptions-borderless-line-various-filter p-ml-3 p-mr-3'>
                            <Checkbox
                                disabled={rangeSetup[0] == 0 && rangeSetup[1] == 0 ? true : false}
                                inputId='CoutFormation'
                                className='msf-input-switch msf-checkbox-various-filter'
                                checked={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.CoutFormation)[0]?.isChecked || false}
                                onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.CoutFormation)}
                            />
                            <div htmlFor='CoutFormation' className="field-checkbox msf-tab-title">
                                <Trans i18nKey='search.school_filter_text_formation_cost' />
                            </div>
                        </div>
                    </AccordionTab>
                    {/* Nom de la formation */}
                    <AccordionTab header={tabHeaderTemplate(t('search.search_formationLabel'), [contextRecherche.categorieRecherche.Formation])}>
                        <ListeElements
                            values={[contextRecherche.categorieRecherche.Formation]}
                            onChange={handleChange}
                            placeholder={t('search.search_formationPlaceholder')}
                            icon="pi pi-search"
                        />
                    </AccordionTab>
                    {/* Niveau d'entrée */}
                    <AccordionTab header={tabHeaderTemplate(t('formation.diplom_level_entrance'), [contextRecherche.categorieRecherche.NiveauEntree])}>
                        <SelectButton
                            className='msf-selectbutton'
                            options={niveauEntreeOptions}
                            value={selectedNiveauInitialValue(contextRecherche.categorieRecherche.NiveauEntree)}
                            optionLabel="name"
                            multiple
                            onChange={(e) => handleChangeNiveauEntree(e)}
                        />
                    </AccordionTab>
                    {/* Domaine */}
                    <AccordionTab header={tabHeaderTemplate(t('search.domainesLabel'), [contextRecherche.categorieRecherche.DipDomaine])}>
                        <FilterMultiSelect
                            className='msf-diplome-multiselect'
                            values={[contextRecherche.categorieRecherche.DipDomaine]}
                            placeholder={t('search.select') + '...'}
                            contextRecherche={contextRecherche}
                            onChange={handleChangeDipDomaineMultiSelect}
                            label='domaineType'
                            triggerUnselectAll={unselectTokenDipDomaine}
                            setUnselectTokenDipMetier={setUnselectTokenDipMetier}
                            setUnselectTokenAppellation={setUnselectTokenAppellation}
                            onRemoveValeur={clearCompetencesStates}
                        />
                    </AccordionTab>
                    {/* Sous domaine */}
                    <AccordionTab header={tabHeaderTemplate(t('search.sousDomainesLabel'), [contextRecherche.categorieRecherche.DipMetier])}>
                        <FilterMultiSelect
                            className='msf-diplome-multiselect'
                            values={[contextRecherche.categorieRecherche.DipMetier]}
                            placeholder={t('search.select') + '...'}
                            contextRecherche={contextRecherche}
                            onChange={handleChangeDipMetierMultiSelect}
                            label='metierType'
                            triggerUnselectAll={unselectTokenDipMetier}
                            setUnselectTokenAppellation={setUnselectTokenAppellation}
                            onRemoveValeur={clearCompetencesStates}
                        />
                    </AccordionTab>
                    {/* Metier */}
                    <AccordionTab header={tabHeaderTemplate(t('search.metiersLabel'), [contextRecherche.categorieRecherche.Appellation])}>
                        <ListeElementsRecherche
                            contextRecherche={contextRecherche}
                            filter
                            values={[contextRecherche.categorieRecherche.Appellation]}
                            onChange={handleChange}
                            onSearch={searchAppellation}
                            field='label'
                            placeholder={t('search.metiersPlaceholder')}
                            icon='pi pi-search'
                            triggerUnselectAll={unselectTokenAppellation}
                            onRemoveValeur={clearCompetencesStates}
                        />
                    </AccordionTab>
                    {/* Compétences */}
                    <AccordionTab header={tabHeaderTemplate(t('skills.skills'), [contextRecherche.categorieRecherche.Competence])}>
                        <ListeElementsRechercheAvecClic
                            values={[contextRecherche.categorieRecherche.Competence]}
                            onChange={handleChange}
                            onSearch={searchCompetence}
                            field='label'
                            placeholder={t('skills.search_skills')}
                            icon='pi pi-search'
                        />
                    </AccordionTab>
                    {/* Langue d'enseignement */}
                    <AccordionTab header={tabHeaderTemplate(t('search.educationLanguageLabel'), [contextRecherche.categorieRecherche.Langue])}>
                        <FilterMultiSelect
                            className='msf-diplome-multiselect'
                            values={[contextRecherche.categorieRecherche.Langue]}
                            placeholder={t('search.ChoiceLanguagePlaceholder')}
                            contextRecherche={contextRecherche}
                            onChange={handleChangeLangueType}
                            label='langueType'
                        />
                    </AccordionTab>
                    {/* Divers */}
                    <AccordionTab header={tabHeaderTemplate(t('general.various'), (RoleHelper.isSuperAdmin(currentUser) || RoleHelper.isAdmin(currentUser)) ? [contextRecherche.categorieRecherche.FormationActive, contextRecherche.categorieRecherche.FormationVisible, contextRecherche.categorieRecherche.FormationParcourSup, contextRecherche.categorieRecherche.FormationMonMaster] : [contextRecherche.categorieRecherche.FormationActive, contextRecherche.categorieRecherche.FormationParcourSup, contextRecherche.categorieRecherche.FormationMonMaster])}>
                        <div>
                            {/* formation active checkbox */}
                            <div className='msf-otheroptions-borderless-line-various-filter'>
                                <Checkbox
                                    inputId='FormationActive'
                                    className='msf-input-switch msf-checkbox-various-filter'
                                    checked={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.FormationActive)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.FormationActive)}
                                />
                                <div htmlFor='FormationActive' className="field-checkbox msf-tab-title">
                                    <Trans i18nKey='formation.active_formation' />
                                    <MenuSearchTooltip className="p-ml-2 msf-tooltip" text={t('tooltip.searchFormationActive')}></MenuSearchTooltip>
                                </div>
                            </div>
                            {/* formation visible */}
                            {(RoleHelper.isSuperAdmin(currentUser) || RoleHelper.isAdmin(currentUser)) && (
                                <div className='msf-otheroptions-borderless-line-various-filter'>
                                    <Checkbox
                                        inputId='FormationVisible'
                                        className='msf-input-switch msf-checkbox-various-filter'
                                        checked={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.FormationVisible)[0]?.value || false}
                                        onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.FormationVisible)}
                                    />
                                    <div htmlFor='FormationVisible' className="field-checkbox msf-tab-title">
                                        <Trans i18nKey='formation.visible_formation' />
                                    </div>
                                </div>
                            )}
                            {/* formation ParcourSup */}
                            <div className='msf-otheroptions-borderless-line-various-filter'>
                                <Checkbox
                                    inputId='FormationParcourSup'
                                    className='msf-input-switch msf-checkbox-various-filter'
                                    checked={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.FormationParcourSup)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.FormationParcourSup)}
                                />
                                <div htmlFor='FormationParcourSup' className="field-checkbox msf-tab-title">
                                    <Trans i18nKey='formation.parcoursup' />
                                    <MenuSearchTooltip className="p-ml-2 msf-tooltip" text={t('tooltip.searchFormationParcourSup')}></MenuSearchTooltip>
                                </div>
                            </div>
                            {/* formation Mon Master */}
                            <div className='msf-otheroptions-borderless-line-various-filter'>
                                <Checkbox
                                    inputId='FormationMonMaster'
                                    className='msf-input-switch msf-checkbox-various-filter'
                                    checked={contextRecherche.lazyParams.filters.filter(o => o.categorie === contextRecherche.categorieRecherche.FormationMonMaster)[0]?.value || false}
                                    onChange={(e) => onCheckChange(e.checked, contextRecherche.categorieRecherche.FormationMonMaster)}
                                />
                                <div htmlFor='FormationMonMaster' className="field-checkbox msf-tab-title">
                                    <Trans i18nKey='formation.myMaster' />
                                    <MenuSearchTooltip className="p-ml-2 msf-tooltip" text={t('tooltip.searchFormationMonMaster')}></MenuSearchTooltip>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
            )}
        </div>
    )
}
