import axios from 'axios';

export default class AuthService {

    resetPassword(form) {
        const jsonResetPassword = JSON.stringify(form, null, 2);
        const options = {
            headers: { 'Content-Type': 'application/json'}
          };
        return axios.post(window.API_URL + '/api/auth/resetpassword',jsonResetPassword, options)
            .then(res => res.data)
            .catch(err => console.log(err));
    }

    forgotPassword(mail){
        const email = {email : mail};
        const options = {
            headers: { 'Content-Type': 'application/json' },
        };
        return axios.post(window.API_URL + '/api/auth/forgotpassword',email, options)
            .then(res => res.data)
            .catch(err => console.log(err));
    }

    askValidationEmail(mail){
        const email = {email : mail};
        const options = {
            headers: { 'Content-Type': 'application/json' },
        };
        return axios.post(window.API_URL + '/api/auth/askvalidationemail',email, options)
            .then(res => res.data)
            .catch(err => console.log(err));
    }

    



}