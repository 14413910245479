import React, { useState, useEffect, useRef } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import Helper from '../../utils/helper';
import FormationService from '../../services/formationService';

export const DiplomeSelector = (props) => {

    const service = new FormationService();
    const { t } = useTranslation();
    const toast = useRef(null);

    const [filteredDiplome, setFilteredDiplome] = useState([props.value]);

    const searchDiplome = (event) => {
        if (typeof (props.organismeFormation) === 'object') {
            service.getDiplomeFiltered(event.query, props.organismeFormation)
                .then(res => {
                    setFilteredDiplome(res);
                    if (res.length == 0) {
                        props.onFound(false)
                    } else {
                        props.onFound(true)
                    }
                })
                .catch((err) => {
                    props.onFound(false)
                    toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 });
                })
                .finally(() => {
                }
                );
        }
        else {
            setFilteredDiplome([])
        }
    }

    const onDiplomeChange = (val) => {
        props.onChange(val);
    }

    const template = (item) => {
        return (
            <span className="ler-item" >
                {item?.nom}
            </span>
        );
    }

    return (<>
        <Toast ref={toast} />
        <AutoComplete
            className={props.className}
            panelClassName="ler-autcomplete-panel"
            value={props.value}
            suggestions={filteredDiplome}
            forceSelection={props.forceSelection} dropdown={typeof (props.organismeFormation) === 'object'}
            completeMethod={searchDiplome}
            field="nom"
            onChange={e => onDiplomeChange(e.value)}
            placeholder={props.placeholder}
            disabled={props.disabled}
            minLength={3} 
            appendTo="self"
            />
    </>
    );

}
