import BaseService from './baseService';

export default class CampusService extends BaseService{
  getCampusFiltered(query, idEcole) {
    const options = {
      params: {
        query: query,
        idEcole: idEcole
      }
    }
    return this.axiosApiInstance.get(window.API_URL + '/api/campus/campusFiltered', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

}