import { Trans, useTranslation } from "react-i18next"
import { Button } from "primereact/button";

export const RobChoix = (props) => {

    const { t } = useTranslation();


    return (
        <div className="dip-ta-center rob-font-size" >
            <h2><Trans i18nKey="onboarding.are_you_looking_for_job" /></h2>
            <br/>
            <div className="dip-flex-row dip-pc-center">
                <Button disabled={props.submitting} className="dip-btn-round rob-font-size" label={t('general.yes').toUpperCase()} onClick={props.yesClick} />
                <Button disabled={props.submitting} className="dip-btn-round rob-font-size" label={t('general.no').toUpperCase()} onClick={props.noClick} />
            </div>
        </div>
    )
}