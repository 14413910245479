export const enumNiveauDiplome = {
        COLLEGELYCEE : "0",
        CAPBEP : "3",
        BAC : "4",
        BAC2 : "5",
        BAC3 : "6",
        BAC5 : "7",
        BAC8 : "8",
        BAC1: "9",
        BAC4 : "10",
        BAC6 : "11",
        BAC7 : "12",
}