import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { useState } from "react"
import { Trans } from "react-i18next"
import { CommuneSelector } from "../../location-selector/communeSelector"
import Pays from "../../../utils/pays"
import { classNames } from "primereact/utils"

export const LocalisationPoste = (props) => {

    const [selectedPays, setSelectedPays] = useState({ alpha2: "FR", libelle: "France" })
    const [selectedCity, setSelectedCity] = useState(props.annonce?.lieuTravail?.libelle || null)

    const onPaysChange = (e) => {
        setSelectedPays(e.value)
        setSelectedCity(null);
        if (props.onPaysChange) {
          props.onPaysChange(e);
        }
      }
    
      const onCityChange = (e) => {
        setSelectedCity(e.value)
        if (props.onCityChange) {
          props.onCityChange(e);
        }
      }

    return (
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-sm-12 p-lg-12">
                <h2><Trans i18nKey="advert.job_location" /></h2>
            </div>
            <div className="p-field p-col-12 p-sm-12 p-lg-4">
                <label className={!props.annonce.lieuTravail.paysLibelle && "disabled-label"} htmlFor='ville'><Trans i18nKey='geography.city' /></label>
                {props.annonce?.lieuTravail?.paysCodeAlpha2 == 'FR' ? (
                    <CommuneSelector
                        className="no-borders"
                        value={selectedCity}
                        onChange={e => onCityChange(e)}
                        showDepartementNumber
                    />
                ) : (
                    <InputText id='ville'
                        value={selectedCity}
                        onChange={(e) => onCityChange(e)}
                        disabled={!props.annonce.lieuTravail.paysLibelle}
                        required
                        className={classNames({ 'p-invalid': props.submitted && !props.annonce?.lieuTravail?.libelle })}
                    />
                )}
            </div>
            <div className="p-field p-col-12 p-sm-12 p-lg-4">
                <label htmlFor='pays'><Trans i18nKey='geography.country' /></label>
                <Dropdown id='pays'
                    value={selectedPays}
                    onChange={(e) => onPaysChange(e)}
                    options={Pays}
                    optionLabel="libelle"
                    filter
                    required
                    className={classNames({ 'p-invalid': props.submitted && !props.annonce?.lieuTravail?.paysLibelle } , "no-borders")}
                />
                {props.submitted && !props.annonce?.lieuTravail?.paysLibelle && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
            </div>
        </div>
    )
}