import { Card } from "primereact/card"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { SelectButton } from "primereact/selectbutton"
import { useContext, useEffect, useState } from "react"
import { enumCategorieRecherche } from "../../enums/enumCategorieRecherche"
import RechercheEntrepriseOffres from "../../pages/entreprise/RechercheEntrepriseOffres"
import RechercheService from "../../services/rechercheService"
import "./filterPanelOffers.css"
import { Trans, useTranslation } from "react-i18next"
import { MultiSelect } from "primereact/multiselect"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import EntrepriseService from "../../services/entrepriseService"

export const FilterPanelOffers = () => {

    const history = useHistory()
    const { t } = useTranslation();
    const [diffusion, setDiffusion] = useState(0);
    const [localisation, setLocalisation] = useState(null);
    const [projetSelected, setProjetSelected] = useState(history?.location?.state?.filters?.map(x => { return x.value }) || [])
    const [localisationOptions, setLocalisationOptions] = useState([])
    const [projetOptions, setProjetOptions] = useState([])
    const [typeContrat, setTypeContrat] = useState([0]);
    const [loadingLocalisation, setLoadingLocalisation] = useState(true)
    const [loadingProjet, setLoadingProjet] = useState(true)
    const contextRecherche = useContext(RechercheEntrepriseOffres);
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId

    const rechercheService = new RechercheService();
    const entrepriseService = new EntrepriseService();

    const diffusionOptions = [
        { name: 'Toutes', value: 0 },
        { name: 'Diffusées', value: 1 },
        { name: 'Non Diffusées', value: 2 }
    ]

    const typeContratOptions = [
        { name: 'Tous', value: 0 },
        { name: 'Stage', value: 4 },
        { name: 'Alternance', value: 1 },
        { name: 'CDD', value: 2 },
        { name: 'CDI', value: 3 },
    ]

    const majResults = (_lazyParams) => {
        contextRecherche.setLazyParams(_lazyParams);
        contextRecherche.rechercheApi(_lazyParams);
    }

    const handleChangeProjet = (e) => {
        e.preventDefault()
        try {
            let _filters = [...contextRecherche.lazyParams.filters];

            let _newFilters = _filters.filter((e) => {
                return e.categorie !== enumCategorieRecherche.Projet
            })
            if (e.value) {
                e.value.forEach(p => {
                    _newFilters.push({ categorie: enumCategorieRecherche.Projet, value: p })
                });
            }
            let _lazyParams = { ...contextRecherche.lazyParams };
            _lazyParams.first = 0;
            _lazyParams.page = 1;
            _lazyParams.filters = _newFilters;
            majResults(_lazyParams);
            setProjetSelected(e.value)
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeDiffusion = (e) => {
        e.preventDefault()
        try {
            let _filters = [...contextRecherche.lazyParams.filters];

            let _newFilters = _filters.filter((e) => {
                return e.categorie !== enumCategorieRecherche.Diffusion
            })
            if (e.value) {
                _newFilters.push({ categorie: enumCategorieRecherche.Diffusion, value: e.value })
            }
            let _lazyParams = { ...contextRecherche.lazyParams };
            _lazyParams.first = 0;
            _lazyParams.page = 1;
            _lazyParams.filters = _newFilters;
            majResults(_lazyParams);
            setDiffusion(e.value)
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeLocalisation = (e) => {
        e.preventDefault()
        try {
            let _filters = [...contextRecherche.lazyParams.filters];

            let _newFilters = _filters.filter((e) => {
                return e.categorie !== enumCategorieRecherche.Ville
            })
            if (e.value) {
                _newFilters.push({ categorie: enumCategorieRecherche.Ville, value: e.value, label: e.value })
            }
            let _lazyParams = { ...contextRecherche.lazyParams };
            _lazyParams.first = 0;
            _lazyParams.page = 1;
            _lazyParams.filters = _newFilters;
            majResults(_lazyParams);
            setLocalisation(e.value)
        } catch (error) {
        }
    }

    const handleChangeContrat = (e) => {
        e.preventDefault()
        try {
            let _filters = [...contextRecherche.lazyParams.filters];

            let _newFilters = _filters.filter((e) => {
                return e.categorie !== enumCategorieRecherche.TypeContrat
            })
            let _typeContrat = [...typeContrat]
            if ((_typeContrat.indexOf(0) == -1 && e.value.indexOf(0) > -1) || e.value.length === 0) {
                setTypeContrat([0])
            } else {
                let _filtersToPush = []
                e.value.forEach(val => {
                    if (val != 0) {
                        _filtersToPush.push(val)
                    }
                });
                _filtersToPush.forEach(f => {
                    _newFilters.push({ categorie: enumCategorieRecherche.TypeContrat, value: f, label: f })
                });
                setTypeContrat(_filtersToPush)
            }

            let _lazyParams = { ...contextRecherche.lazyParams };
            _lazyParams.first = 0;
            _lazyParams.page = 1;
            _lazyParams.filters = _newFilters;
            majResults(_lazyParams);
        } catch (error) {
        }
    }

    useEffect(() => {
        entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id) => {
            rechercheService.getRechercheLocalisationOffreEmploiEntreprise(id)
                .then(data => {
                    setLocalisationOptions(data)
                    setLoadingLocalisation(false)
                })
            rechercheService.getRechercheProjetOfEntreprise(id)
                .then(data => {
                    setProjetOptions(data)
                    setLoadingProjet(false)
                })
        })
    }, [])


    return (
        <div className="fpo-main">
            <h1><Trans i18nKey="offers.filter_by" /></h1>
            <div>
                <label><Trans i18nKey="project.projects" /></label>
                <div>
                    <MultiSelect
                        disabled={loadingProjet}
                        className='p-mt-2'
                        display="chip"
                        options={projetOptions}
                        value={projetSelected}
                        optionLabel="label"
                        optionValue="value"
                        onChange={(e) => handleChangeProjet(e)}
                        placeholder={t('project.select')}
                    />
                </div>
            </div>
            <div>
                <label><Trans i18nKey="offers.diffusion" /></label>
                <SelectButton
                    className='fpo-selectbutton  p-mt-2 '
                    options={diffusionOptions}
                    value={diffusion}
                    optionLabel="name"
                    unselectable={false}
                    multiple={false}
                    onChange={(e) => handleChangeDiffusion(e)}
                />
            </div>
            <div>
                <label><Trans i18nKey='general.location' /></label>
                <div>
                    <Dropdown
                        className=" p-mt-2 "
                        disabled={loadingLocalisation}
                        value={localisation}
                        options={localisationOptions}
                        optionLabel="label"
                        onChange={(e) => handleChangeLocalisation(e)}
                        showClear
                        placeholder="Choisissez une localisation"
                    />
                </div>
            </div>
            <div>
                <label><Trans i18nKey='offers.contract_type' /></label>
                <SelectButton
                    className='fpo-selectbutton  p-mt-2 '
                    options={typeContratOptions}
                    value={typeContrat}
                    optionLabel="name"
                    unselectable={true}
                    multiple={true}
                    onChange={(e) => handleChangeContrat(e)}
                />
            </div>
        </div>
    )
}