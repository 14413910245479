import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import UserService from '../../services/userService';
import { useAuthState } from "../../context/context";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import './dt_administrateurs.css';

export const DT_Administrateur = () => {

    let emptyAdministrateur = {
        id: 0,
        nom: '',
        prenom: '',
        email: '',
        ecole: [],
        nbFormations: 0
    };

    const { t } = useTranslation();
    const [newAdministrateurDialog, setNewAdministrateurDialog] = useState(true);
    const [administrateurs, setAdministrateurs] = useState([]);
    const [administrateurDialog, setAdministrateurDialog] = useState(false);
    const [deleteAdministrateurDialog, setDeleteAdministrateurDialog] = useState(false);
    const [deleteAdministrateursDialog, setDeleteAdministrateursDialog] = useState(false);
    const [administrateur, setAdministrateur] = useState(emptyAdministrateur);
    const [selectedAdministrateurs, setSelectedAdministrateurs] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const userService = new UserService();
    const currentUser = useAuthState();
    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    });
    const isMounted = useRef(false);



    useEffect(() => {
        isMounted.current = true;
        loadLazyData();
    }, [lazyParams]);


    const loadLazyData = () => {
        setLoading(true);

        userService.getAdministrateurs(currentUser, { lazyEvent: JSON.stringify(lazyParams) }).then(_administrateurs => {

            setAdministrateurs(_administrateurs);

            setLoading(false);

            setNewAdministrateurDialog(false);
        });

    }

    const openNew = () => {
        setAdministrateur(emptyAdministrateur);
        setSubmitted(false);
        setAdministrateurDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setAdministrateurDialog(false);
    }

    const hideDeleteAdministrateurDialog = () => {
        setDeleteAdministrateurDialog(false);
    }

    const hideDeleteAdministrateursDialog = () => {
        setDeleteAdministrateursDialog(false);
    }

    const saveAdministrateur = () => {
        setSubmitted(true);

        if (administrateur.nom.trim() && administrateur.prenom.trim() && administrateur.email.trim() && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(administrateur.email))) {
            let _administrateurs = [];
            if (administrateurs !== undefined) {
                _administrateurs = [...administrateurs];
            }
            let _administrateur = { ...administrateur };
            if (administrateur.id) {
                (async function updateAdmin() {
                    await userService.edit(_administrateur)
                        .then(() => {
                            const index = findIndexById(administrateur.id);
                            _administrateurs[index] = _administrateur;

                            setAdministrateurs(_administrateurs);
                            setAdministrateur(emptyAdministrateur);

                            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('administrator.updated_admin'), life: 3000 });
                        })
                        .catch((err) => {
                            if (err.message.includes("User already exists")) {
                                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('administrator.email_already_exists'), life: 3000 });
                            }
                            else {
                                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('administrator.not_created_admin'), life: 3000 });
                            }
                        })
                        .finally(() => {
                            setAdministrateurDialog(false)
                        }
                        )
                })();
            }
            else {
                userService
                    .createOrUpdateAdministrateur(administrateur)
                    .then((_administrateur) => {

                        _administrateurs.push(_administrateur);
                        setAdministrateurs(_administrateurs);

                        setAdministrateurDialog(false);
                        setAdministrateur(emptyAdministrateur);

                        toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('administrator.created_admin'), life: 3000 });

                    })
                    .catch((err) => {
                        if (err.message.includes("User already exists")) {
                            toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('administrator.email_already_exists'), life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('administrator.not_created_admin'), life: 3000 });
                        }
                    })
                    .finally(() => {
                        setAdministrateurDialog(false)
                    }
                    )

                // let _admin = await userService.createOrUpdateAdministrateur(currentUser, administrateur);
                // if (_admin != undefined) {
                //     _administrateur.id = _admin.id;
                //     //console.log(JSON.stringify(_administrateur));
                //     _administrateurs.push(_administrateur);
                //     setAdministrateurs(_administrateurs);

                //     setAdministrateurDialog(false);
                //     setAdministrateur(emptyAdministrateur);

                //     toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('administrator.created_admin'), life: 3000 });

                // }
                // else {
                //     setAdministrateurDialog(false);
                //     toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('administrator.not_created_admin'), life: 3000 });

                // }

            }
        }
    }

    const editAdministrateur = (administrateur) => {
        setAdministrateur({ ...administrateur });
        setAdministrateurDialog(true);
    }

    const confirmDeleteAdministrateur = (administrateur) => {
        setAdministrateur(administrateur);
        setDeleteAdministrateurDialog(true);
    }

    const deleteAdministrateur = () => {
        (async function deleteAdmin() {
            let _administrateur = { ...administrateur };
            await userService.delete(_administrateur);
            let _administrateurs = administrateurs.filter(val => val.id !== administrateur.id);
            setAdministrateurs(_administrateurs);
            setDeleteAdministrateurDialog(false);
            setAdministrateur(emptyAdministrateur);
            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('administrator.deleted_admin'), life: 3000 });

        })();
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < administrateurs.length; i++) {
            if (administrateurs[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const confirmDeleteSelected = () => {
        setDeleteAdministrateursDialog(true);
    }

    const deleteSelectedAdministrateurs = () => {
        (async function deleteAdmin() {
            let _administrateurs = administrateurs.filter(val => !selectedAdministrateurs.includes(val));
            selectedAdministrateurs.forEach(admin => {
                userService.delete(admin);
            });
            setAdministrateurs(_administrateurs);
            setDeleteAdministrateursDialog(false);
            setSelectedAdministrateurs(null);
            toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('administrator.deleted_admins'), life: 3000 });
        })();
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _administrateur = { ...administrateur };
        _administrateur[`${name}`] = val;

        setAdministrateur(_administrateur);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label={t('administrator.new_admin')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} disabled={newAdministrateurDialog} />
                <Button label={t('general.delete')} icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedAdministrateurs || !selectedAdministrateurs.length} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div style={{ textAlign: "right" }}>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editAdministrateur(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteAdministrateur(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="title"><Trans i18nKey="administrator.admins_management.full" /></h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
            </span>
        </div>
    );
    const administrateurDialogFooter = (
        <React.Fragment>
            <Button label={t('general.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={t('general.save')} icon="pi pi-check" className="p-button-text" onClick={saveAdministrateur} />
        </React.Fragment>
    );
    const deleteAdministrateurDialogFooter = (
        <React.Fragment>
            <Button label={t('general.no')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteAdministrateurDialog} />
            <Button label={t('general.yes')} icon="pi pi-check" className="p-button-text" onClick={deleteAdministrateur} />
        </React.Fragment>
    );
    const deleteAdministrateursDialogFooter = (
        <React.Fragment>
            <Button label={t('general.no')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteAdministrateursDialog} />
            <Button label={t('general.yes')} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedAdministrateurs} />
        </React.Fragment>
    );

    return (
        <div className="datatable-administrateurs">
            <Toast ref={toast} />

            <div className="card">
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>

                <DataTable ref={dt} emptyMessage={t('administrator.no_admin_to_show')} value={administrateurs} selection={selectedAdministrateurs} onSelectionChange={(e) => setSelectedAdministrateurs(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={t('administrator.footer_count_pagin')}
                    globalFilter={globalFilter}
                    header={header}
                    loading={loading}
                >

                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="nom" header={t('administrator.lastname')} sortable></Column>
                    <Column field="prenom" header={t('administrator.firstname')} sortable></Column>
                    <Column field="email" header={t('administrator.email')} sortable></Column>
                    <Column field="nbFormationsAssignees" header={t('administrator.assigned_courses')} sortable style={{ textAlign: "right" }}></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </div>

            <Dialog visible={administrateurDialog} style={{ width: '50rem' }} header={t('administrator.details_admin')} modal className="p-fluid" footer={administrateurDialogFooter} onHide={hideDialog}>
                {administrateur.image && <img src={`showcase/demo/images/administrateur/${administrateur.image}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={administrateur.image} className="administrateur-image" />}
                <div className="p-field">
                    <label htmlFor="nom"><Trans i18nKey="administrator.lastname" /></label>
                    <InputText id="nom" value={administrateur.nom} onChange={(e) => onInputChange(e, 'nom')} required autoFocus className={classNames({ 'p-invalid': submitted && !administrateur.nom })} />
                    {submitted && !administrateur.nom && <small className="p-error"><Trans i18nKey="general.required" /></small>}
                </div>
                <div className="p-field">
                    <label htmlFor="prenom"><Trans i18nKey="administrator.firstname" /></label>
                    <InputText id="prenom" value={administrateur.prenom} onChange={(e) => onInputChange(e, 'prenom')} required rows={3} cols={20} className={classNames({ 'p-invalid': submitted && !administrateur.prenom })} />
                    {submitted && !administrateur.prenom && <small className="p-error"><Trans i18nKey="general.required" /></small>}
                </div>
                <div className="p-field">
                    <label htmlFor="email"><Trans i18nKey="administrator.email" /></label>
                    <InputText id="email" value={administrateur.email} onChange={(e) => onInputChange(e, 'email')} required rows={3} cols={20} className={classNames({ 'p-invalid': submitted && (!administrateur.email || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(administrateur.email))) })} />
                    {submitted && !administrateur.email && <small className="p-error"><Trans i18nKey="general.required" /></small>}
                    {submitted && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(administrateur.email)) && <small className="p-error"><Trans i18nKey="general.invalid_email" /></small>}
                </div>
            </Dialog>

            <Dialog visible={deleteAdministrateurDialog} style={{ width: '50rem' }} header={t('general.confirm')} modal footer={deleteAdministrateurDialogFooter} onHide={hideDeleteAdministrateurDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {administrateur && <span><Trans i18nKey="administrator.are_you_sure_delete_admin" /></span>}
                </div>
            </Dialog>

            <Dialog visible={deleteAdministrateursDialog} style={{ width: '450rem50px' }} header={t('general.confirm')} modal footer={deleteAdministrateursDialogFooter} onHide={hideDeleteAdministrateursDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {administrateur && <span><Trans i18nKey="administrator.are_you_sure_delete_selected_admins" /></span>}
                </div>
            </Dialog>
        </div>
    );
}