const Description = {
    0: 'Moteur de recherche de formations',
    1: 'Offres de stage',
    2: 'Offres d’emploi et d’alternance',
    3: 'Gestion des candidatures',
    4: 'Gestion des projets de recrutement',
    5: 'Accès illimité à la CVthèque',
    6: 'Accompagnement personnalisé',
    7: 'Sourcing de candidats auprès des écoles',
}

export default Description;