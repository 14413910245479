import axios from 'axios';
import BaseService from './baseService';

export default class EcoleService extends BaseService {

  getEcolesAdmin(currentUser) {
    const options = {
      headers: { Authorization: 'Bearer ' + currentUser.token }
    };
    return axios.get(window.API_URL + '/api/ecole/ecolesAdmin', options)
      .then(res => res.data)
      .catch(err => console.log(err));
  }

  getEcoleComplete(ecole) {

    return this.axiosApiInstance.get(window.API_URL + `/api/ecole/EcoleComplete/${ecole.id}`)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }


  checkIfCodeUAIsAreAvailable(ecole) {
    const jsonEcole = JSON.stringify(ecole, null, 2);
    return this.axiosApiInstance.post(window.API_URL + `/api/ecole/checkIfCodeUAIsAreAvailable`,jsonEcole)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }


  createOrUpdateEcole(ecole) {
    const jsonEcole = JSON.stringify(ecole, null, 2);
    const formData = new FormData();
    formData.append('ecole', jsonEcole);
    if (ecole.fileImage !== undefined)
      formData.append('fileImage', new Blob([ecole.fileImage], { type: ecole.fileImage.type }), ecole.fileImage.name);

    ecole.composanteEcole.forEach(composante => {
      if (composante.fileLogo !== undefined)
        formData.append('fileLogoComposante', new Blob([composante.fileLogo], { type: composante.fileLogo.type }), composante.fileLogo.name);
      else
        formData.append('fileLogoComposante', new Blob(), '.');
    });

    return this.axiosApiInstance.post(window.API_URL + '/api/ecole/ecole', formData)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  delete(ecole) {
    return this.axiosApiInstance.delete(window.API_URL + '/api/ecole/EcoleDelete/' + ecole.id)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));

  }

  getEcolesPaginated(lazyParams) {
    const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))
    return this.axiosApiInstance.get(window.API_URL + `/api/ecole/getPaginated?${queryParams}`)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getEcoleFiche(ecoleId) {
    let _id = ecoleId ? (ecoleId) : ('')
    return this.axiosApiInstance.get(window.API_URL + '/api/ecole/GetEcoleFiche/' + _id)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getEcoleFicheExtended(ecoleId) {
    return this.axiosApiInstance.get(window.API_URL + '/api/ecole/GetEcoleFicheExtended/' + ecoleId)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  updateEcoleFiche(currentUser, ecole) {
    const jsonEcole = JSON.stringify(ecole, null, 2);
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + currentUser.token
      }
    };
    const formData = new FormData()
    formData.append('ecole', jsonEcole);
    if (ecole.fileImageCouverture !== undefined)
      formData.append('fileImageCouverture', new Blob([ecole.fileImageCouverture], { type: ecole.fileImageCouverture.type }), ecole.fileImageCouverture.name);
    return axios.post(window.API_URL + '/api/ecole/UpdateEcoleFiche', formData, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

}