import React, { useState, useEffect, useRef, useContext } from 'react';
import "./listeElementsRecherche.css";
import { Fragment } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import RechercheContext from './RechercheContext';
import Helper from '../../utils/helper';
import { AutoComplete } from 'primereact/autocomplete';
import { useTranslation } from 'react-i18next';


export const ListeElementsRecherche = (props) => {

    const {t} = useTranslation();
    const contextRecherche = useContext(RechercheContext);
    const [filteredValues, setFilteredValues] = useState(null);
    const [selectedValues, setSelectedValues] = useState(null);

    const handleRemoveValeur = (valeur) => {

        let _filters = [...contextRecherche.lazyParams.filters];
        const indexSupprime = Helper.findIndexByValueAndCategorie(_filters, valeur.value, valeur.categorie);
        _filters.splice(indexSupprime, 1);
        // si filtre est une école supprimer les diplomes et les formations
        if (valeur.categorie == contextRecherche.categorieRecherche.Composante || valeur.categorie == contextRecherche.categorieRecherche.Ecole) {
            let _indexToRemove = [];
            // seek diplome and formatio, to remove
            _filters.forEach((element, index) => {
                if (element.categorie == contextRecherche.categorieRecherche.Diplome || element.categorie == contextRecherche.categorieRecherche.Formation) {
                    _indexToRemove.push(index)
                }
            });
            //remove existing filte
            for (var i = _indexToRemove.length - 1; i >= 0; i--) {
                _filters.splice(_indexToRemove[i], 1);
            }
        }
        // si filtre est un diplome supprimer les formations
        if (valeur.categorie == contextRecherche.categorieRecherche.Diplome) {
            let _indexToRemove = [];
            // seek formation, to remove
            _filters.forEach((element, index) => {
                if (element.categorie == element.categorie == contextRecherche.categorieRecherche.Formation) {
                    _indexToRemove.push(index)
                }
            });
            //remove existing filte
            for (var i = _indexToRemove.length - 1; i >= 0; i--) {
                _filters.splice(_indexToRemove[i], 1);
            }
        }
        const _lazyParams = { ...contextRecherche.lazyParams };
        _lazyParams.filters = _filters;

        contextRecherche.setLazyParams(_lazyParams);
        contextRecherche.rechercheApi(_lazyParams);
    }

    const handleRemoveAllValues = () => {
        let _filters = [...contextRecherche.lazyParams.filters];
        _filters = _filters.filter(function( obj ) {
            return props.values.indexOf(obj.categorie) == -1;
        });
        const _lazyParams = { ...contextRecherche.lazyParams };
        _lazyParams.filters = _filters;

        contextRecherche.setLazyParams(_lazyParams);
        contextRecherche.rechercheApi(_lazyParams);
    }

    const searchValues = (event) => {
        let query = event.query;
        props.onSearch(query).then(res => setFilteredValues(res));
    }

    const handleChange = (value) => {
        setSelectedValues(value);
        if (typeof (value) === 'object') {
            setSelectedValues(null);
        }
        props.onChange(value)

    }

    const template = (item) => {
        return (
            <span className="ler-item" >
                {item.label}
            </span>
        );
    }

    return (
        <div>
            <div className='p-grid'>
                <div className='p-col-12 p-fluid ler-autocomplete-container'>

                    <AutoComplete
                        className='ler-autcomplete'
                        panelClassName="ler-autcomplete-panel"
                        minLength="3"
                        value={selectedValues}
                        suggestions={filteredValues}
                        completeMethod={searchValues}
                        placeholder={props.placeholder}
                        field={props.field}
                        delay="1000"
                        optionGroupLabel={props.optionGroupLabel}
                        optionGroupChildren={props.optionGroupChildren}
                        itemTemplate={template}
                        optionGroupTemplate={template}
                        onChange={(e) => handleChange(e.value)}
                    />

                    {props.icon && <div className='ler-autocomplete-icon'>
                        <i className={props.icon}></i>
                    </div>}

                </div>
                {contextRecherche.lazyParams.filters?.filter(o => props.values.includes(o.categorie)).length > 0 && props.canDeleteAll && <div className='p-col-12'>
                    <Button
                    className='ler-delete-all'
                    icon="pi pi-times"
                    label={t('search.erase_all')}
                    onClick={(e) => handleRemoveAllValues()}></Button>
                </div>}
                {contextRecherche.lazyParams.filters?.filter(o => props.values.includes(o.categorie)).length > 0 && <div className='p-col-12'>
                    {contextRecherche.lazyParams.filters?.filter(o => props.values.includes(o.categorie))?.sort((a, b) => (a.label > b.label) ? 1 : -1).map((valeur, indexValeur) => (
                        <Fragment key={`${valeur}~${indexValeur}`}>
                            <div className='dms-filter-item'>
                                {/* <div> */}
                                    <Button 
                                        label={valeur?.label}
                                        icon="pi pi-times" 
                                        iconPos="right"
                                        className="p-button-rounded p-button-text p-mr-2 dmp-button-style" 
                                        onClick={(e) => handleRemoveValeur(valeur)} 
                                    />
                                {/* </div> */}
                                {/* <span className='ler-filter-item-value'>{valeur?.label}</span> */}
                            </div>
                        </Fragment>
                    ))}
                </div>}
            </div>
        </div>
    );

}