import { SelectButton } from 'primereact/selectbutton';
import "./candidatStatutSelectButton.css"
import { useTranslation } from 'react-i18next';

export const CandidatStatutSelectButton = (props) => {

    const { t } = useTranslation();

    const statutOptions = [
        {
            label:
                <>
                    <div>{props.entity?.filter(oe => { return oe.statut == 1 }).length}</div>
                    <div>{props.entity?.filter(oe => { return oe.statut == 1 }).length == 1 ? t('company.new.singular') : t('company.new.plural')}</div>
                    <div>{props.entity?.filter(oe => { return oe.statut == 1 }).length == 1 ? t('company.candidate.singular').toLowerCase() : t('company.candidate.plural').toLowerCase()}</div>
                </>,
            value: 1
        },
        {
            label:
                <>
                    <div>{props.entity?.filter(oe => { return oe.statut == 2 }).length}</div>
                    <div>{props.entity?.filter(oe => { return oe.statut == 2 }).length == 1 ? t('company.candidate.singular') : t('company.candidate.plural')}</div>
                    <div>{t('company.waiting').toLowerCase()}</div>
                </>,
            value: 2
        },
        {
            label:
                <>
                    <div>{props.entity?.filter(oe => { return oe.statut == 3 }).length}</div>
                    <div>{props.entity?.filter(oe => { return oe.statut == 3 }).length == 1 ? t('company.candidate.singular') : t('company.candidate.plural')}</div>
                    <div>{props.entity?.filter(oe => { return oe.statut == 3 }).length == 1 ? t('company.contacted.singular').toLowerCase() : t('company.contacted.plural').toLowerCase()}</div>
                </>,
            value: 3
        },
        {
            label:
                <>
                    <div>{props.entity?.filter(oe => { return oe.statut == 4 }).length}</div>
                    <div>{props.entity?.filter(oe => { return oe.statut == 4 }).length == 1 ? t('company.interview.singular') : t('company.interview.plural')}</div>
                    <div>{props.entity?.filter(oe => { return oe.statut == 4 }).length == 1 ? t('company.done.singular').toLowerCase() : t('company.done.plural').toLowerCase()}</div>
                </>,
            value: 4
        },
        {
            label:
                <>
                    <div>{props.entity?.filter(oe => { return oe.statut == 5 }).length}</div>
                    <div>{props.entity?.filter(oe => { return oe.statut == 5 }).length == 1 ? t('company.candidate.singular') : t('company.candidate.plural')}</div>
                    <div>{props.entity?.filter(oe => { return oe.statut == 5 }).length == 1 ? t('company.recruited.singular').toLowerCase() : t('company.recruited.plural').toLowerCase()}</div>
                </>,
            value: 5
        },
        {
            label:
                <>
                    <div>{props.entity?.filter(oe => { return oe.statut == 6 }).length}</div>
                    <div>{props.entity?.filter(oe => { return oe.statut == 6 }).length == 1 ? t('company.candidate.singular') : t('company.candidate.plural')}</div>
                    <div>{props.entity?.filter(oe => { return oe.statut == 6 }).length == 1 ? t('company.rejected.singular').toLowerCase() : t('company.rejected.plural').toLowerCase()}</div>
                </>,
            value: 6
        }
    ]

    return (
        <SelectButton className="cssb-select-button" value={props.value} onChange={(e) => props.setValue(e.value)} options={statutOptions} />
    )
}