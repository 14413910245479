import { Button } from "primereact/button"
import { ProgressSpinner } from "primereact/progressspinner"
import { Fragment, useContext } from "react"
import recrutementRechercheContext from "./recrutement-RechercheContext"
import { useTranslation } from "react-i18next"
import { enumTypeRechercheAffinee } from "../../enums/enumTypeRechercheAffinee";
import { CandidatThumbnail } from "./candidatThumbnail";


export const RechercheCandidatsResultats = (props) => {

    const { t } = useTranslation()
    const contextRecherche = useContext(recrutementRechercheContext);

    return (
        <div className={props.className}>
            {props.loading ? (
                <div className="p-col-12" style={{ textAlign: 'center' }}>
                    <ProgressSpinner></ProgressSpinner>
                </div>
            ) : (
                (props.candidats?.length > 0) ? (
                    props.loading === true ?
                        (<div style={{ marginTop: "15%", textAlign: 'center' }}><ProgressSpinner></ProgressSpinner></div>)
                        :
                        (<>
                            {<div className="candidat-search-results-container">{props.candidats?.map((candidat, indexCandidat) => (
                                <Fragment key={`${candidat.id}~${indexCandidat}`}>
                                    <CandidatThumbnail
                                        etudiant={candidat}
                                        small={true}
                                        className="candidat-search-item"
                                        isInAList={true}
                                        hasFormationAccordion={true}
                                        hasCheckableProfile={true}
                                        toast={props.toast} />
                                </Fragment>
                            ))}</div>}
                        </>)
                ) : t('search.no_results_matching')
            )}
            {((props.lazyParams?.page + 1) * props.lazyParams?.rows < contextRecherche.totalCandidatsRecords)
                && !props.loading
                &&
                <div style={{ textAlign: 'center' }}>
                    <Button
                        loading={props.loadingButton}
                        label={t('general.see_more')}
                        className="candidat-search-button-seemore p-button-outlined p-button-danger p-button-rounded"
                        onClick={props.onClickMoreCandidats} />
                </div>
            }
        </div>
    )
}