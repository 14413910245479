import React, { useState, useEffect, useRef } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import CommuneService from '../../services/communeService';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import Helper from '../../utils/helper';

export const CommuneSelector = (props) => {

    const service = new CommuneService();
    const { t } = useTranslation();
    const toast = useRef(null);

    const [selectedCommune, setSelectedCommune] = useState(props.value);
    const [filteredCommune, setFilteredCommune] = useState(props.value != null ? [props.value] : null);

    useEffect(() => {
        setSelectedCommune(props.value)
    }, [props.value])

    const searchCommune = (event) => {

        service.getCommuneFiltered(event.query)
            .then(res => {
                setFilteredCommune(res);
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 });
            })
            .finally(() => {
            }
            );
    }

    const onCommuneChange = (e) => {

        if(e.originalEvent.type != "blur"){
            setSelectedCommune(e.value);
            if (e.value?.id == null && !Helper.isEmptyOrSpaces(e.value))
                return;
            props.onChange(e);
        }
    }

    return (<>
        <Toast ref={toast} />
        <AutoComplete
            className={props.className}
            disabled={props.disabled}
            value={selectedCommune}
            placeholder="Ex: Paris"
            suggestions={filteredCommune}
            forceSelection completeMethod={searchCommune}
            field={props.showDepartementNumber ? ("fullLibelleCommune") : (selectedCommune?.libelle ? "libelle" : "fullLibelleCommune")}
            onChange={onCommuneChange} 
            appendTo="self"/>
    </>
    );

}

