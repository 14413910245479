import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Trans } from 'react-i18next';
import 'primeflex/primeflex.css';
import { Dialog } from 'primereact/dialog';



export const ActivateAccountDialog = (props) => {

    useEffect(() => {
    }, [props.show]);

    const close = () => {
        props.closeEvent();
    }

    const dialogFooter = <div className="plain-button p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => close()} /></div>;

    return (
        <div className="form-activate-account">
            <Dialog visible={props.show} onHide={() => close()} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5><Trans i18nKey="register.message_account_activation_email_send" /></h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        <Trans i18nKey="register.message_account_activation_email_send_detail" /> {props.email}
                    </p>
                </div>
            </Dialog>
        </div>
    );
}
