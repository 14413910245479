import React from 'react';

export const JobLocationFormater = (props) => {
    const getLocation = (offreEmploi) => {
        if (offreEmploi?.lieuTravail?.libelle == null) {
            return null
        }
        if (offreEmploi?.providerId == 1) {
            if(offreEmploi?.lieuTravail?.paysCodeAlpha2 == "FR") {
                return offreEmploi?.lieuTravail?.libelle
            }else{
                return offreEmploi?.lieuTravail?.libelle + ' (' + offreEmploi?.lieuTravail?.paysLibelle + ')'
            }
            
        } else {
            const words = offreEmploi?.lieuTravail?.libelle.split(' - ');
            let chaineRes = '';
            for (let index = 1; index < words.length; index++) {
                chaineRes = words[index];
                if (index != words.length - 1) {
                    chaineRes = chaineRes + '-'
                }
            }
            chaineRes = chaineRes + ' (' + words[0] + ')'
            return chaineRes
        }

    }


    return (
        <>
            {getLocation(props.offreEmploi)}
        </>
    )
}