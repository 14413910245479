import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import UserService from '../../services/userService';
import { useAuthState } from "../../context/context";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { enumStudentStatus } from '../../enums/enumStudentStatus';
import './dt_etudiants.css';
import { Dt_etudiants_action } from './dt_etudiants_actions';
import { Dt_delete_etudiant } from './dt_delete_etudiant';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const DT_Etudiant = () => {

    const { t } = useTranslation();
    const [etudiants, setEtudiants] = useState([]);
    const [selectedEtudiants, setSelectedEtudiants] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const history = useHistory();
    const [totalRecords, setTotalCount] = useState(0);
    const toast = useRef(null);
    const dt = useRef(null);
    const userService = new UserService();
    const currentUser = useAuthState();
    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 25,
        page: 0,
        sortField: 'dateCreation',
        sortOrder: 0
    });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        userService.getEtudiants(currentUser, lazyParams)
            .then(_data => {
                setTotalCount(_data.totalCount);
                setEtudiants(_data.items);
            })
            .catch(err => showError())
            .finally(() => setLoading(false))

    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        _lazyParams['page'] = 0;
        _lazyParams['first'] = 0;
        setLazyParams(_lazyParams);
    }

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onNewAccountClick = () => {
        history.push({pathname:'/profile_student'})
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
            {/* design position button à vérifier */}
                <Dt_etudiants_action 
                    etudiant={rowData}
                    onEtudiantDelete={loadLazyData}
                />
                {rowData?.isInvited != true &&
                    <Dt_delete_etudiant
                        etudiant={rowData}
                        onEtudiantDelete={loadLazyData}
                    />
                }
            </>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="title"><Trans i18nKey="student.students_management.full" /></h5>
            <div>
                <Button
                    className='dip-btn-red-bg p-mr-2'
                    label={t('profile.new_invited_account')}
                    icon="pi pi-plus"
                    onClick={onNewAccountClick}
                />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
                </span>
            </div>
        </div>
    );

    const statusBodyTemplate = (rowData) => {
        return <span className={`p-tag ${"status-" + enumStudentStatus[rowData.status]}`} >{t('enums.enumCompanyStatus.' + enumStudentStatus[rowData.status])}</span>;
    }

    const lastConnexionDateBodyTemplate = (rowData) => {
        return <span >{rowData.lastConnexionDate && new Date(rowData.lastConnexionDate).toLocaleString()}</span>;
    }
    const creationDateBodyTemplate = (rowData) => {
        return <span >{rowData.creationDate && new Date(rowData.creationDate).toLocaleString()}</span>;
    }

    return (
        <div className="datatable-etudiants">
            <Toast ref={toast} />

            <div className="card">
                <DataTable
                    ref={dt}
                    value={etudiants}
                    lazy
                    filterDelay={1000}
                    paginator
                    first={lazyParams.first}
                    rows={lazyParams.rows}
                    emptyMessage={t('student.no_student_to_show')}
                    selection={selectedEtudiants}
                    onSelectionChange={(e) => setSelectedEtudiants(e.value)}
                    dataKey="id"
                    rowsPerPageOptions={[25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={t('student.footer_count_pagin')}
                    globalFilter={globalFilter}
                    header={header}
                    loading={loading}
                    columnResizeMode="expand"
                    resizableColumns
                    responsiveLayout="auto"
                    autoLayout
                    onSort={onSort}
                    sortField={lazyParams.sortField}
                    sortOrder={lazyParams.sortOrder}
                    onPage={onPage}
                    onFilter={onFilter}
                    filters={lazyParams.filters}
                    totalRecords={totalRecords}
                >
                    {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                    <Column field="nom" header={t('general.lastname')} sortable></Column>
                    <Column field="prenom" header={t('general.firstname')} sortable></Column>
                    <Column field="email" header={t('general.email')} sortable></Column>
                    <Column field="status" header={t('general.status')} body={statusBodyTemplate} sortable className='getu-status-container'></Column>
                    <Column field="creationDate" dataType="date" header={t('date.creation')} body={creationDateBodyTemplate} sortable></Column>
                    <Column field="lastConnexionDate" dataType="date" header={t('profile.lastconnexion')} body={lastConnexionDateBodyTemplate} sortable></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </div>

        </div>
    );
}