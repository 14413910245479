import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import './i18n';

import './index.css';
import './theme/palette.css';

if (process.env.REACT_APP_API_URL) {
  window.API_URL = process.env.REACT_APP_API_URL;
}
if (!window.API_URL)
  throw new Error("API_URL not defined");

if (process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
  window.STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
}

if (!window.STRIPE_PUBLIC_KEY)
  throw new Error("STRIPE_PUBLIC_KEY not defined");

if (process.env.REACT_APP_STRIPE_PORTAL_URL) {
  window.STRIPE_PORTAL_URL = process.env.REACT_APP_STRIPE_PORTAL_URL;
}

if (!window.STRIPE_PORTAL_URL)
  throw new Error("STRIPE_PORTAL_URL not defined");

ReactDOM.render(
  <App />
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
