import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import { Fragment } from 'react'
import { Trans } from 'react-i18next'
import { enumStatutFormation } from '../../enums/enumStatutFormation'
import { FormationListItem } from '../listItem/formationListItem'
import './cursusThumbnail.css'

const header = 
        <h1 className="vue-candidat-card-title">
            <Trans i18nKey='profile.formation.singular' />
        </h1>

export const CursusThumbnail = (props) => {
    return (
        <Card className="p-shadow-5 cursus-card" header={header}>
            {props.etudiant.formationAVenir?.map((f, indexF) => (
                <Fragment key={`${f.id}~${indexF}`}>
                    <FormationListItem formation={f.formation} statut={enumStatutFormation.AVENIR} />
                    {(indexF < props.etudiant.formationAVenir.length-1 || props.etudiant.formationEnCours.length > 0 || props.etudiant.formationSuivie.length > 0) && <Divider />}
                </Fragment>
            ))}
            {props.etudiant.formationEnCours?.map((f, indexF) => (
                <Fragment key={`${f.id}~${indexF}`}>
                    <FormationListItem formation={f} statut={enumStatutFormation.ENCOURS}/>
                    {(indexF < props.etudiant.formationEnCours.length-1 || props.etudiant.formationSuivie.length > 0) && <Divider />}
                </Fragment>
            ))}
            {props.etudiant.formationSuivie?.sort((a, b) => b.anneeDiplome - a.anneeDiplome).map((f, indexF) => (
                <Fragment key={`${f.id}~${indexF}`}>
                    <FormationListItem formation={f.formation} anneeDiplome={f.anneeDiplome} statut={enumStatutFormation.SUIVIE} />
                    {(indexF < props.etudiant.formationSuivie.length-1) && <Divider />}
                </Fragment>
            ))}
        </Card>
    )
}