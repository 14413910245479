import React, { useEffect, useRef, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthState, useAuthDispatch } from "../context/context";
import { MenuDashboard } from "./menu/menuDashboard";
import { Sidebar } from "primereact/sidebar"
import { Button } from "primereact/button";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Footer } from "./footer/footer";
import { CookiesDialog } from './dialog/cookiesDialog'
import { RenewUser } from "../context/actions";
import jwt_decode from "jwt-decode";
import { Header } from "./header/header";
import RoleHelper from "../utils/roleHelper";

export const AppRoutes = ({ component: Component, path, needsLogin, needsRoles, hasDashboard, hasDashboardOnlyMobile, hasAlabasterBackground, className, hasFooter, hasWidthLimit, redirect, showOnboarding, headerTheme, backgroundTheme, ...rest }) => {
    let currentUser = useAuthState();
    let dispatch = useAuthDispatch();
    const [visible, setVisible] = useState(true);
    const { t } = useTranslation();
    const history = useHistory();
    const [doitCompleterProfil, setDoitCompleterProfil] = useState(false)
    const [cookieRefresh, setCookieRefresh] = useState(null)
    const [tokenRefresh, setTokenRefresh] = useState(false)
    const [activeOverlayPanelIndex, setActiveOverlayPanelIndex] = useState(0);
    const loginOverlayPanel = useRef(null)

    if (!tokenRefresh) {
        const token = localStorage.getItem("currentUser")
            ? JSON.parse(localStorage.getItem("currentUser")).token
            : "";
        if (token !== '') {
            if (jwt_decode(token).expiration < (Date.now() / 1000) + 518400 && !jwt_decode(token).expiration < Date.now() / 1000) {
                setTokenRefresh(true);
                RenewUser(dispatch)
            }
        }
    }
    
    useEffect(() => {
        let _currentUser = JSON.parse(localStorage.getItem("currentUser"))
        if (_currentUser?.user) {
            let _roles = RoleHelper.decodeRoles(_currentUser.token);
            _currentUser.roles = _roles
            if (RoleHelper.isEntreprise(_currentUser) && history.location.pathname == "/") {
                history.push('/offers');
            }
        }
        if (_currentUser == null && (currentUser == undefined || !currentUser?.isAuthenticated)) {
            setDoitCompleterProfil(false)
        }
        else {
            setDoitCompleterProfil(_currentUser?.user?.doitCompleterProfil || currentUser?.user?.doitCompleterProfil);
        }
    }, [currentUser])

    const handleClickCompleteProfile = () => {
        history.push('/profile_student');
    }

    const showLogin = (e) => {
        setActiveOverlayPanelIndex(0);
        loginOverlayPanel.current.toggle(e);
    }

    const showRegister = (e) => {
        setActiveOverlayPanelIndex(1)
        loginOverlayPanel.current.toggle(e);
    }
    return (
        <>
            <Header
                theme={headerTheme}
                showOnboarding={showOnboarding}
                loginOverlayPanel={loginOverlayPanel}
                activeOverlayPanelIndex={activeOverlayPanelIndex}
                setActiveOverlayPanelIndex={setActiveOverlayPanelIndex}
                className="app-width-limit"
                
            />
            <div className={history.location.pathname.toLowerCase() == "/register?user=company" || (history.location.pathname == "/register" && history.location.search.toLowerCase() == "?user=company") ? 'main-blue' : headerTheme ? (`main-${headerTheme}`) : ("main-red")}>
                <Route
                    path={path}
                    render={props =>
                        (needsLogin && !Boolean(currentUser.isAuthenticated)) || !(needsRoles ? needsRoles.some(it => currentUser.roles.includes(it) || RoleHelper.isAdmin(currentUser)) : true) ?
                            (
                                <Redirect to={{ pathname: "/unauthorized" }} />
                            ) : (
                                redirect ?
                                    <Redirect to={{ pathname: redirect }} />
                                    :
                                    <>
                                        <div>
                                            {hasDashboard && <MenuDashboard hasDashboardOnlyMobile={hasDashboardOnlyMobile} />}
                                            {!hasDashboard && doitCompleterProfil && path != "/onboarding" && <Sidebar className="global-top-sidebar-info" visible={visible} position="top" onHide={() => setVisible(false)} >
                                                <div className="complete-profile-header-text"><Trans i18nKey='profile.please_complete_profile' values={{ prenom: currentUser.user.prenom }} /></div>
                                                <br />
                                                <Button label={t('profile.complete_my_profile').toUpperCase()} className="p-button-outlined p-button-danger p-button-rounded global-top-sidebar-button" onClick={() => handleClickCompleteProfile()} />
                                            </Sidebar>}
                                            <main className={`${hasDashboard ? hasDashboardOnlyMobile ? "" : "main-with-dashboard" : ''} ${className}`}>
                                                <Component
                                                    className={`${hasWidthLimit ? "app-width-limit" : ''}`}
                                                    showOnboarding={showOnboarding}
                                                    backgroundTheme={backgroundTheme}
                                                    {...props}
                                                />
                                            </main>
                                        </div>
                                        <CookiesDialog currentUser={currentUser} cookieReset={cookieRefresh}></CookiesDialog>
                                        {hasFooter && <Footer setCookieRefresh={setCookieRefresh} />}
                                    </>
                            )
                    }
                    {...rest}
                />
            </div>
        </>
    )
}
