import diplomIcon from '../../images/icons/diplomIcon.svg'
import { Tooltip } from 'primereact/tooltip'
import './annonceCandidateesIndicator.css'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

export const AnnonceCandidateesIndicator = (props) => {

    const renderText = () => {
        let chaine = t('recruitment.candidate_applied_to_the_job')
        props.annonceCandidatees.forEach((e, i) => {
            chaine = chaine + ' "' + e.offreEmploiRecrutement.offreEmploi.intitule + '"'
            if (i < props.annonceCandidatees.length-1) {
                chaine = chaine + ", "
            }
        });
        return chaine
    }

    const { t } = useTranslation()
    const [text, setText] = useState(renderText)

    

    return (
        <span className={props.className}>
            <Tooltip appendTo='self' target=".aci-tooltip" content={text} position="bottom" className='aci-align'/>
            <img className="aci-tooltip" src={diplomIcon} ></img>
        </span>
    )
}