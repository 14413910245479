import BaseService from './baseService';

export default class MetierService extends BaseService {

    getDomaineByLabel(label) {
        return this.axiosApiInstance.get(window.API_URL + '/api/metier/domaineByLabel/' + label)
            .then(res => res.data)
            .catch(err => console.log(err));
    }

    getSousDomaineByLabel(label) {
        const encodeUrl = label.replace("/", "-");
        return this.axiosApiInstance.get(window.API_URL + '/api/metier/sousDomaineByLabel/' + encodeUrl)
            .then(res => res.data)
            .catch(err => console.log(err));
    }


}