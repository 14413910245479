import React, { useState } from 'react';
import "./stepper.css";


export const Stepper = ({ steps, handleClick, setActiveStep, activeStep }) => {

    function handleClick(stepNumber) {
        setActiveStep(stepNumber);
    }

    return (
        <div className="stepper">
            <div className="container-stepper">
                <ul className={`progressbar stepper-active-${activeStep}`}>
                    {steps.map((step) => {
                        return (
                            <li
                                key={step.number} onClick={() => handleClick(step.number)}>{step.name}</li>
                        )
                    }
                    )}
                </ul>
            </div>
        </div>
    );
}