import { IoHeartOutline, IoHeart } from "react-icons/io5";
import React, { useState  } from 'react';
import { useAuthState } from '../../context/context';
import { useTranslation } from 'react-i18next';
import OffreEmploiService from "../../services/offreEmploiService";
import RoleHelper from "../../utils/roleHelper";
import { Link, useHistory } from 'react-router-dom';
import { enumRoles } from "../../enums/enumRoles";


export const LikeOffreEmploi = (props) => {

    const service = new OffreEmploiService();
    const currentUser = useAuthState();
    const history = useHistory();
    const [liked, setLiked] = useState(props.offreEmploi?.isFavorite);
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const like = () => {
        setIsLoading(true)
        service.like(props.offreEmploi.id)
            .then(() => {
                setLiked(true)
            })
            .catch(() => props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 }))
            .finally(() => setIsLoading(false));
        ;
    }

    const unlike = () => {
        setIsLoading(true)
        service.unlike(props.offreEmploi.id)
            .then(() => {
                setLiked(false)
                if (props.onUnlike) {
                    props.onUnlike();
                }
            })
            .catch(() => props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.failed'), life: 3000 }))
            .finally(() => setIsLoading(false));
    }

    return (<>
        {isLoading && <i className="p-button p-component p-disabled p-button-rounded p-button-danger p-button-text p-button-icon-only pi pi-spin pi-spinner" style={{ fontSize: "1.2rem" }}></i>}
        {!isLoading && liked && <div className="p-button p-component p-button-rounded p-button-danger p-button-text p-button-icon-only" onClick={unlike} style={{ fontSize: '1.2rem' }}>
            <IoHeart />
        </div>}
        {RoleHelper.isEtudiant(currentUser) ?
            (!isLoading && !liked && <div className="p-button p-component p-button-rounded p-button-danger p-button-text p-button-icon-only" onClick={like} style={{ fontSize: '1.2rem' }}>
                <IoHeartOutline />
            </div>) :
            (!isLoading && !liked &&
                <Link to={{ pathname: "/register?User=Student", state: { userType: enumRoles.ETUDIANT, previousUrl: history.location.pathname + history.location.search, isFavorite: true } }}>
                    <div className="p-button p-component p-button-rounded p-button-danger p-button-text p-button-icon-only" style={{ fontSize: '1.2rem' }}>
                        <IoHeartOutline />
                    </div>
                </Link>)}
    </>

    );

}

