import { Fragment, useEffect, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { InputSwitch } from 'primereact/inputswitch';
import { Trans, useTranslation } from 'react-i18next';
import './activeSearch.css'
import { TreeSelect } from 'primereact/treeselect';
import { FormNavigationButtons } from '../../formNavigationButtons';
import { MultiSelect } from 'primereact/multiselect';
import AppellationService from '../../../../services/appellationService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { enumContextContratMultiSelect } from '../../../../enums/enumContextContratMultiSelect';
import Helper from '../../../../utils/helper';
import { ContratProfilMultiSelect } from '../../../multiselect/contratProfilMultiSelect';
import { Button } from 'primereact/button';

export const ActiveSearch = (props) => {

    const appellationService = new AppellationService();

    const [allowedAppellations, setAllowedAppellations] = useState([]);
    const [selectedAppellations, setSelectedAppellations] = useState([]);

    const { t } = useTranslation()

    useEffect(() => {
        if(!props.loadingContent && props.selectedMobilites && props.mobiliteTree)
        loadLazyData();

    }, [props.loadingContent])

    const loadLazyData = () => {
        appellationService
            .getAllowedAppellationsForEtudiant(props.etudiant.id)
            .then((_appellations) => {
                setAllowedAppellations(_appellations);
                let _selectedAppellations = _appellations.filter(x => props.etudiant?.etudiantAppellations?.some(o => o.appellationId === x.id && o.prisEnCompte == true))
                setSelectedAppellations(_selectedAppellations)
            })
    }

    const onSelectionChange = (e) => {
        props.setFormIsDirty(true);
        let _tree = [...props.mobiliteTree]
        let _res = { ...e.value };
        // si France selectionné alors on décoche toutes les régions et départements
        if (_res.France?.checked == true) {
            _tree[1].children.forEach(r => {
                r.children.forEach(d => {
                    delete _res[d.key]
                })
                delete _res[r.key]
            });
        }
        Object.entries(_res).forEach(row => {
            // vérifier si des départements sont sélectionnés dans une région
            if (row[1].checked == true && _tree[1].children?.filter(r => { return r.key == row[0] }).length > 0) {
                let _index = _tree[1].children.map(function (e) { return e.key }).indexOf(row[0])
                _tree[1].children[_index].children.forEach(d => {
                    delete _res[d.key]
                })
            }
            // si key row International => mettre label international, si key row France => mettre label France, puis check si c'est une région ou departement
            if (row[0] == "International" || row[0] == "France") {
                row[1].label = row[0]
            }
            else {
                if (row[1].checked == true) {
                    let _splittedKey = row[0].split('-');
                    if (_splittedKey.length == 1) {
                        // région : retrouver avec la key la région dans _tree
                        let _index = _tree[1].children.map(function (e) { return e.key }).indexOf(row[0])
                        let _label = _tree[1].children[_index].label
                        row[1].label = _label;
                    }
                    else if (_splittedKey.length == 2) {
                        // département : retrouver avec la key la région/département dans _tree ou dans la région retrouvé
                        let _indexRegion = _tree[1].children.map(function (e) { return e.key }).indexOf(_splittedKey[0])
                        let _indexDepartement = _tree[1].children[_indexRegion].children.map(function (e) { return e.key }).indexOf(row[0])
                        let _label = _tree[1].children[_indexRegion].children[_indexDepartement].label
                        row[1].label = _label;
                    }
                }
            }
        }
        )
        props.setSelectedMobilites(_res)
    }

    const onRemoveMobilityValeur = (e) => {
        let values = { ...props.selectedMobilites }


        if (e[0] == 'International' || e[0] == 'France') {
            if (e[0] == 'France') {
                delete values['France'];
            }
            else if (e[0] == 'International') {
                delete values['International'];
            }
        }
        else {
            let _splittedKey = e[0].split('-');
            if (_splittedKey.length == 1) {
                // on delete tous les départements de la région et la région
                let _index = props.mobiliteTree[1].children.map(function (e) { return e.key }).indexOf(e[0])
                props.mobiliteTree[1].children[_index].children.forEach(d => {
                    delete values[d.key]
                })
                delete values[`${e[0]}`];
            }
            else if (_splittedKey.length == 2) {
                // on delete le département et si aucun département checked dans la région alors on delete la région et si aucune region checked ou partialchecked dans France alors on delete France
                let _indexRegion = props.mobiliteTree[1].children.map(function (e) { return e.key }).indexOf(_splittedKey[0])
                delete values[`${e[0]}`];
                let _regionChecked = false;
                props.mobiliteTree[1].children[_indexRegion].children.forEach(d => {
                    if (values[d.key]?.checked == true) {
                        _regionChecked = true;
                    }
                })
                if (!_regionChecked) {
                    delete values[_splittedKey[0]];
                }
            }

            let _franceChecked = false;
            props.mobiliteTree[1].children.forEach(r => {
                if (values[r.key]?.checked == true || values[r.key]?.partialChecked == true) {
                    _franceChecked = true;
                }
            }
            )
            if (!_franceChecked) {
                delete values['France'];
            }
        }

        let event = { value: values }
        onSelectionChange(event)
    }

    const onEmploisChange = (e) => {
        props.setFormIsDirty(true);
        setSelectedAppellations(e.value)
        let _etudiant = { ...props.etudiant }
        let _unselectedAppellations = allowedAppellations.filter(x => !e.value.some(o => o.id === x.id));
        let _etudiantAppellations = []
        _unselectedAppellations.forEach(a => {
            _etudiantAppellations.push({ etudiantId: props.etudiant.id, appellationId: a.id, prisEnCompte: false })
        })
        e.value.forEach(a => {
            _etudiantAppellations.push({ etudiantId: props.etudiant.id, appellationId: a.id, prisEnCompte: true })
        })
        _etudiant.etudiantAppellations = _etudiantAppellations
        props.setEtudiant(_etudiant);
    }

    const onRemoveValeurEmploi = (e) => {
        let values = [...selectedAppellations]
        values = values.filter((valeur) => valeur.id != e.id)
        let event = { value: values }
        onEmploisChange(event)
    }

    const onCheckChange = (value, name) => {
        let _etudiant = { ...props.etudiant };
        _etudiant[`${name}`] = value;

        // if (name === 'rechercheActive') {
        //     if (value == false) {
        //         _etudiant.paireContrats = []
        //         _etudiant.rechercheActiveCalendrier = null;
        //         props.setDateEtudiant(null);
        //     }
        // }
        props.setEtudiant(_etudiant)
        props.onInputEtudiantCheckChange()
    }

    return (
        props.loadingContent ? (
            <div className="dip-ta-center">
                <ProgressSpinner />
            </div>
        ) : (
        <div>
            {/* input actuellement en emploi */}
            <div className="p-field-checkbox">
                <label htmlFor='emploiActuel' className="field-checkbox sp-checkbox-label">
                    <Trans i18nKey='profile.actual_job' />
                </label>
                <InputSwitch
                    checked={props.etudiant?.emploiActuel}
                    onChange={(e) => onCheckChange(e.value, 'emploiActuel')}
                />
            </div>
            {/* input recherche active */}
            <div className="p-field-checkbox">
                <label htmlFor='rechercheActive' className="field-checkbox sp-checkbox-label">
                    <Trans i18nKey='profile.active_search' />
                </label>
                <InputSwitch
                    checked={props.etudiant?.rechercheActive}
                    onChange={(e) => onCheckChange(e.value, 'rechercheActive')}
                />
            </div>

            {props.etudiant?.rechercheActive &&
                <div className="p-fluid p-formgrid p-grid">
                    {/* contrat recherche */}
                    <div className="p-field p-col-12 p-sm-12 p-lg-4 as-div-container">
                        <div className='as-title-grid-height'>
                            <span className="personnal-info-subtitle"><Trans i18nKey={'profile.seeked_contract'} /></span>
                        </div>
                        <ContratProfilMultiSelect
                            context={enumContextContratMultiSelect.PROFILETUDIANT}
                            // label={t('profile.seeked_contract')}
                            value={props.etudiant?.paireContrats}
                            onChange={(e) => props.onInputEtudiantChange(e, 'paireContrats')}
                        />
                        <small className="p-error">{props.submitted && (props.etudiant?.paireContrats?.length == 0 || !props.etudiant?.paireContrats) && <Trans i18nKey="profile.at_least_one_contract" />}</small>
                    </div>
                    {/* mobilité */}
                    <div className="p-field p-col-12 p-sm-12 p-lg-4 as-div-container">
                        <div className='as-title-grid-height'>
                            <span className="personnal-info-subtitle"><Trans i18nKey={'profile.mobility.base'} />*</span>
                        </div>
                        <TreeSelect
                            className='active-search-tree-select'
                            value={props.selectedMobilites}
                            options={props.mobiliteTree}
                            onChange={(e) => onSelectionChange(e)}
                            metaKeySelection={false}
                            selectionMode="checkbox"
                            placeholder={t('profile.pick_mobility')}
                        ></TreeSelect>
                        <small className="p-error">{props.submitted && Object.entries(props.selectedMobilites).length == 0 && <Trans i18nKey="profile.at_least_one_mobility" />}</small>
                        {Object.entries(props.selectedMobilites).length > 0 && <div className='cpms-filter-button-position'>
                            {Object.entries(props.selectedMobilites).filter(x => x[1].checked == true).sort((a, b) => (a.libelle > b.libelle) ? 1 : -1).map((valeur, indexValeur) => (
                                <Fragment key={`${valeur}~${indexValeur}`}>
                                    <div className='cpms-filter-button-container'>
                                        <Button
                                            label={valeur[1].label}
                                            icon="pi pi-times"
                                            iconPos="right"
                                            className="p-button-rounded p-button-text p-mr-2 cpms-button-style"
                                            onClick={(e) => onRemoveMobilityValeur(valeur)}
                                        />
                                    </div>
                                </Fragment>
                            ))}
                        </div>}
                    </div>
                    {/* disponibilité */}
                    <div className="p-field p-col-12 p-sm-12 p-lg-4 as-div-container">
                        <div className='as-title-grid-height'>
                            <span className="personnal-info-subtitle"><Trans i18nKey={'profile.available_until'} /></span>
                        </div>
                        {props.isSubmitting ? (<div><ProgressSpinner style={{ width: '1.5rem', height: '1.5rem' }} strokeWidth="8" animationDuration="2s" /></div>) : (
                            <Calendar
                                id="rechercheActiveCalendrier"
                                placeholder={t('profile.pick_date')}
                                locale="fr"
                                value={props.etudiant?.rechercheActiveCalendrier}
                                onChange={(e) => props.onInputEtudiantChange(e, 'rechercheActiveCalendrier')}
                                monthNavigator
                                yearNavigator yearRange={`${Helper.getCurrentYear()}:${Helper.getCurrentYear() + 20}`}
                                dateFormat='dd/mm/yy'
                            />)}
                    </div>
                    {/* emploi recherche */}
                    <div className="p-col-12 p-sm-12 p-lg-4 as-div-container">
                        <div className='as-title-grid-height'>
                            <span className="personnal-info-subtitle">{t('profile.searched_jobs', { value: selectedAppellations.length })}</span>
                        </div>
                        <MultiSelect
                            disabled={props.etudiant?.formationSuivie?.length == 0 && props.etudiant?.formationEnCours?.length == 0 && props.etudiant?.formationAVenir?.length == 0 && props.etudiant?.demandeFormation?.length == 0}
                            className='active-search-multi-select'
                            panelClassName='active-search-multi-select-panel'
                            // display='chip'
                            value={selectedAppellations}
                            filter
                            options={allowedAppellations}
                            optionLabel="libelle"
                            onChange={(e) => onEmploisChange(e)}
                            selectedItemsLabel={t('primereact.selectedItemsLabel')}
                        />
                        <div><small className="p-error">{props.etudiant?.formationSuivie?.length == 0 && props.etudiant?.formationEnCours?.length == 0 && props.etudiant?.formationAVenir?.length == 0 && props.etudiant?.demandeFormation?.length == 0 && <Trans i18nKey="profile.must_complete_studies" />}</small></div>
                        <div><small className="p-error">{props.etudiant?.formationSuivie?.length == 0 && props.etudiant?.formationEnCours?.length == 0 && props.etudiant?.formationAVenir?.length == 0 && props.etudiant?.demandeFormation?.length != 0 && <Trans i18nKey="profile.must_wait_asked_course_validation" />}</small></div>
                        <div><small className="p-error">{(props.etudiant?.formationSuivie?.length > 0 || props.etudiant?.formationEnCours?.length > 0 || props.etudiant?.formationAVenir?.length > 0) && props.submitted && props.etudiant?.etudiantAppellations?.every(x => x.prisEnCompte == false) && <Trans i18nKey="profile.at_least_one_job" />}</small></div>
                    </div>
                    <div className="p-field p-col-12 as-div-container">
                        {selectedAppellations.length > 0 && <div className='cpms-filter-button-position'>
                            {selectedAppellations?.sort((a, b) => (a.libelle > b.libelle) ? 1 : -1).map((valeur, indexValeur) => (
                                <Fragment key={`${valeur}~${indexValeur}`}>
                                    <div className='cpms-filter-button-container'>
                                        <Button
                                            label={valeur?.libelle}
                                            icon="pi pi-times"
                                            iconPos="right"
                                            className="p-button-rounded p-button-text p-mr-2 cpms-button-style"
                                            onClick={(e) => onRemoveValeurEmploi(valeur, 'appellations')}
                                        />
                                    </div>
                                </Fragment>
                            ))}
                        </div>}
                    </div>
                </div>
            }
            <FormNavigationButtons
                isVisualizationAllowed={props.etudiant?.id}
                previousStep={props.previousStep}
                nextStep={props.nextStep}
                onSave={props.onSave}
                setOnSave={props.setOnSave}
                isSubmitting={props.isSubmitting}
                lastStep
            />
        </div>
        )
    )
}