import { enumNiveauLangue } from "../enums/enumNiveauLangue";
import { enumValueError } from "../enums/enumValueError";

export default class LangueHelper {
    static getNiveauLangueRanking(niveau) {
        switch (niveau) {
            case enumNiveauLangue.LANGUEMATERNELLE:
                return 5;
            case enumNiveauLangue.COURANT:
                return 4;
            case enumNiveauLangue.AVANCE:
                return 3;
            case enumNiveauLangue.INTERMEDIAIRE:
                return 2;
            case enumNiveauLangue.ELEMENTAIRE:
                return 1;
            default:
                throw enumValueError.UNRECOGNIZED_NIVEAU_LANGUE;
        }
    }

    static sortLanguesByNiveau(data) {
        return data.sort((a,b) => (this.getNiveauLangueRanking(a.niveauLangue) < this.getNiveauLangueRanking(b.niveauLangue)) ? 1 : -1);
    }
}