import { useAuthState } from "../context/context";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { DisplayProfileImage } from '../components/image/displayProfileImage';
import { AvatarRenderer } from '../components/avatar/avatarRenderer';

import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Avatar } from "primereact/avatar";
import { ProgressBar } from 'primereact/progressbar';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';

import MessagerieService from "../services/messagerieService";

import { IoBriefcaseSharp, IoChevronForward } from "react-icons/io5";
import './messagerie-bar.css';



export const Messagerie_Bar = (props) => {
    const { t } = useTranslation();

    const currentUser = useAuthState();
    const [threadList, setThreadList] = useState(null);
    const [informationComplementaire, setInformationComplementaire] = useState(null);
    const [informationComplementaireLoading, setInformationComplementaireLoading] = useState(true);
    const [layout, setLayout] = useState('list');
    const [loading, setLoading] = useState(false);

    const messagerieService = new MessagerieService();

    useEffect(() => {
        loadLazyData(true);
    }, []);

    const loadLazyData = (reload) => {
        setLoading(true);
        let httpPromise;
        httpPromise = messagerieService.getMyThread2(currentUser);
        httpPromise
            .then(_threads => {
                setThreadList(_threads);
                if (reload && _threads && _threads.length > 0 && props.threadId == null) {
                    props.onMessagerieBarClick(_threads[0].thread.id)
                }
                loadInformationComplementaire(_threads.map(x => x.thread.id));
            })
            .catch((err) => {
                props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 })
            })
            .finally(() => setLoading(false));
    }

    const loadInformationComplementaire = (threadsIds) => {
        if (threadsIds.length > 0) {
            setInformationComplementaireLoading(true);
            let httpPromise;
            httpPromise = messagerieService.getMyThreadInfo(currentUser, threadsIds);
            httpPromise
                .then(_compInfo => {
                    setInformationComplementaire(_compInfo);
                })
                .catch((err) => {
                    props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 })
                })
                .finally(() => setInformationComplementaireLoading(false));
        }
    }

    const clickThread = (data) => {
        props.onMessagerieBarClick(data);
    }

    const clickDeleteThread = (data) => {
        let httpPromise;
        httpPromise = messagerieService.closeThread(currentUser, data);
        httpPromise
            .then(_threads => {
                loadLazyData();
            })
            .catch((err) => {
                props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 })
            })
            .finally(() => setLoading(false));
    }


    const renderListItem = (data) => {
        return (
            <div className="col-12 p-col-12 message-bar-m" >
                <div className="thread-list-item" onClick={() => clickThread(data.thread.id)}>
                    <div className="thread-list-table">

                        <table>
                            <tr>
                                <td>
                                    <div className="thread-list-picture">
                                        <AvatarRenderer size="large" shape="circle" imageId={informationComplementaire?.find(thread => thread.threadId == data.thread.id)?.pictureId} text={!informationComplementaireLoading ? informationComplementaire?.find(thread => thread.threadId == data.thread.id)?.interlocuteur?.nom[0] + informationComplementaire?.find(thread => thread.threadId == data.thread.id)?.interlocuteur?.prenom[0] : ""} colorSeed={data.thread.id}></AvatarRenderer>
                                    </div>
                                </td>
                                <td>
                                    <div className="thread-list-info" >

                                        {/* Topic */}
                                        <div className="thread-info thread-topic" hidden>Topic : {data.thread.topic}</div>

                                        {/* Nom de l'interlocuteur : En bleu */}

                                        {!informationComplementaireLoading && informationComplementaire && informationComplementaire.find(thread => thread.threadId == data.thread.id)?.interlocuteur.typeUser == 0 && <div className="thread-info thread-interlocutor-name">{informationComplementaire.find(thread => thread.threadId == data.thread.id)?.interlocuteur?.nom + ' ' + informationComplementaire.find(thread => thread.threadId == data.thread.id)?.interlocuteur?.prenom}</div>}
                                        {!informationComplementaireLoading && informationComplementaire && informationComplementaire.find(thread => thread.threadId == data.thread.id)?.interlocuteur.typeUser == 1 && <div className="thread-info thread-interlocutor-name">{informationComplementaire.find(thread => thread.threadId == data.thread.id)?.interlocuteur?.nom + ' ' + informationComplementaire.find(thread => thread.threadId == data.thread.id)?.interlocuteur?.prenom}</div>}
                                        {!informationComplementaireLoading && informationComplementaire && informationComplementaire.find(thread => thread.threadId == data.thread.id)?.interlocuteur.typeUser == 1 && <div className="thread-info thread-interlocutor-name-comp">{informationComplementaire.find(thread => thread.threadId == data.thread.id)?.interlocuteur?.nomEntreprise}</div>}

                                        {/* Dernier message recu : En gras si non lu */}
                                        <div className="thread-info thread-last-msg">{data.lastMessage.content.message ? (<div>{data.lastMessage.content.message}</div>) : (<div><Trans i18nKey='messagerie.new_conversation' /></div>)}</div>

                                        {/* Recrutement/OffreEmploi Liée : Affiché si > 0 : Icone Valise + Concat Intitulé */}
                                        <div>{informationComplementaireLoading ?
                                            (<ProgressBar className="thread-info-progressbar" mode="indeterminate"></ProgressBar>)
                                            :
                                            (informationComplementaire.find(thread => thread.threadId == data.thread.id)?.offreEmploi.length > 0 ?
                                                (<div className="thread-info thread-linked-recruitment">
                                                    <IoBriefcaseSharp /> {informationComplementaire.find(thread => thread.threadId == data.thread.id)?.offreEmploi?.map(e => e.intitule).join(',')}</div>)
                                                : '')}
                                        </div>

                                        {/* Bouttons d'action : Commenté */}
                                        {/* {data.deletedOn == null && <div className="">
                            <Button icon="pi pi-eye" label="Voir" onClick={() => clickThread(data.thread.id)}></Button>
                            <Button icon="pi pi-trash" label="Supprimer" onClick={() => clickDeleteThread(data.id)}></Button>

                        </div>}
                        {data.deletedOn != null && <div className="message-bar-warn">
                            <span>Conversation suprimée</span>

                        </div>} */}

                                    </div>
                                    </td>
                            </tr>
                        </table>
                        <div className=" thread-list-arrow"><IoChevronForward></IoChevronForward></div>
                    </div>
                </div>
            </div>
        );
    }


    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }
        return renderListItem(product);
    }

    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <Trans i18nKey='dashboard.messaging' />
            </div>
        );
    }

    const header = renderHeader();

    return (
        <div className="chatbar-main-container">
            <DataView value={threadList} layout={layout} header={header} className={"chatbar-main"}
                itemTemplate={itemTemplate} paginator rows={10} loading={loading} emptyMessage={t('messagerie.no_thread')} />
        </div>
    )
}