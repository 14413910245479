import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { enumHttpError } from "../../enums/enumHttpError";
import { ThumbnailPaginator } from "../paginator/thumbnailPaginator";
import "./jobThumbnailForCourseList.css";
import { CandidateThumbnail } from "../thumbnail/candidateThumbnail";
import UserService from "../../services/userService";
import Helper from "../../utils/helper";
import { useAuthState } from "../../context/context";
import EntrepriseService from "../../services/entrepriseService";
import { Link } from "react-router-dom"
import RoleHelper from "../../utils/roleHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const CandidateThumbnailForCourseList = (props) => {
    const history = useHistory();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const currentUser = useAuthState();
    const { t } = useTranslation();
    const toast = useRef(null);
    const [etudiants, setEtudiants] = useState([]);
    const [error, setError] = useState(null);
    const [displayFakeCandidat, setDisplayFakeCandidat] = useState(true);
    const [displayFakeInformation, setDisplayFakeInformation] = useState(true);

    const [fakeEtudiants, setFakeEtudiants] = useState([
        {
            prenom: "Pierre",
            nom: "Martin",
            urlCode: "-------",
            email: "pierremartin@yopmail.com",
            niveauEtude: "Bac + 5",
            mobiliteInternationale: true,
            mobiliteFrancaise: false,
            mobiliteRegion: [],
            mobiliteDepartement: [],
            rechercheActive: true,
            suisContratApprentissage: false,
            suisContratProfessionnalisation: false,
            rechercheActiveCalendrier: "05/06/2023",
            paireContrats: [
                { id: 1, code: "ALTCA" }
            ],
            commune: {
                libelle: "Lyon",
                departement: {
                    code: "69",
                    libelle: "Rhône",
                    chefLieu: "64445",
                    region: {
                        libelle: "Auvergne-Rhône-Alpes",
                    },
                },
            },
            fichierCvPdf: {
                name: "Conditions_Factices.pdf",
                path: "etudiant/36/CvPdfEtudiant/Conditions_Factices.pdf",
                dateValidation: "2021-12-07T15:22:49.6702956",
                format: "application/pdf",
                size: 73505
            },
            fichierPortfolio: {
                name: "Portfolio.pdf",
                dateValidation: "2021-12-07T14:29:20.6837427",
                format: "application/pdf",
                size: 73505
            },
        },
        {
            urlCode: "HD9IT1FG",
            nom: "Léa",
            prenom: "Petit",
            email: "leapetit@yopmail.com",
            lieuResidence: "Londres",
            pays: "Angleterre",
            etranger: true,
            niveauEtude: "Bac + 3",
            mobiliteInternationale: true,
            mobiliteFrancaise: true,
            mobiliteRegion: [],
            mobiliteDepartement: [],
            rechercheActive: true,
            suisContratApprentissage: false,
            suisContratProfessionnalisation: false,
            rechercheActiveCalendrier: "15/04/2022",
            fichierCvPdf: {
                id: 2554,
                name: "Conditions_Factices.pdf",
                path: "etudiant/36/CvPdfEtudiant/Conditions_Factices.pdf",
                dateValidation: "2021-12-07T15:22:49.6702956",
                format: "application/pdf",
                size: 73505
            },
        },
        {
            prenom: "Thomas",
            nom: "Durand",
            urlCode: "-------",
            email: "ThomasDurand@yopmail.com",
            niveauEtude: "Bac + 2",
            mobiliteInternationale: false,
            mobiliteFrancaise: false,
            mobiliteRegion: [],
            mobiliteDepartement: [],
            rechercheActive: true,
            suisContratApprentissage: true,
            suisContratProfessionnalisation: false,
            rechercheActiveCalendrier: "01/01/2022",
            paireContrats: [
                { id: 1, code: "ALTCA" }
            ],
            commune: {
                libelle: "Lyon",
                departement: {
                    code: "69",
                    libelle: "Rhône",
                    chefLieu: "64445",
                    region: {
                        libelle: "Auvergne-Rhône-Alpes",
                    },
                },
            },
            fichierCvPdf: {
                name: "Conditions_Factices.pdf",
                path: "etudiant/36/CvPdfEtudiant/Conditions_Factices.pdf",
                dateValidation: "2021-12-07T15:22:49.6702956",
                format: "application/pdf",
                size: 73505
            },
        },
        {
            prenom: "Zoe",
            nom: "Orlando",
            urlCode: "-------",
            email: "ZoeOrlando13@yopmail.com",
            niveauEtude: "Bac + 2",
            mobiliteInternationale: false,
            mobiliteFrancaise: false,
            mobiliteRegion: [],
            mobiliteDepartement: [],
            rechercheActive: true,
            suisContratApprentissage: true,
            suisContratProfessionnalisation: false,
            rechercheActiveCalendrier: "01/01/2022",
            paireContrats: [
                { id: 1, code: "ALTCA" }
            ],
            commune: {
                libelle: "Lyon",
                departement: {
                    code: "69",
                    libelle: "Rhône",
                    chefLieu: "64445",
                    region: {
                        libelle: "Auvergne-Rhône-Alpes",
                    },
                },
            },
            fichierCvPdf: {
                name: "Conditions_Factices.pdf",
                path: "etudiant/36/CvPdfEtudiant/Conditions_Factices.pdf",
                dateValidation: "2021-12-07T15:22:49.6702956",
                format: "application/pdf",
                size: 73505
            },
        },
    ])

    const userService = new UserService();
    const entrepriseService = new EntrepriseService();

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 8,
        page: 1,
        sortField: 'dateCreation'
    })

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        if (RoleHelper.isEntreprise(currentUser) || adminImpersonatingRecruteurId) {
            entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then(entrepriseId => {
                userService.getListEtudiantByFormationId(lazyParams, props.formationId, entrepriseId)
                    .then(_pagedStudents => {
                        _pagedStudents.items.forEach(e => {
                            if (e.rechercheActiveCalendrier)
                                e.rechercheActiveCalendrier = Helper.dateTimeToLocaleDateTime(e.rechercheActiveCalendrier)
                        });
                        setTotalCount(_pagedStudents.totalCount)
                        setEtudiants(_pagedStudents.items);
                    })
                    .catch((err) => {
                        switch (err.message) {
                            case enumHttpError.CANCELED:
                                setError(enumHttpError.CANCELED)
                                break;
                            default:
                                setError('failed');
                                break;
                        }
                    })
                    .finally(() => setLoading(false));

            })
        }
        else {
            userService.getListEtudiantByFormationId(lazyParams, props.formationId)
                .then(_pagedStudents => {
                    _pagedStudents.items.forEach(e => {
                        if (e.rechercheActiveCalendrier)
                            e.rechercheActiveCalendrier = Helper.dateTimeToLocaleDateTime(e.rechercheActiveCalendrier)
                    });
                    setTotalCount(_pagedStudents.totalCount)
                    setEtudiants(_pagedStudents.items);
                })
                .catch((err) => {
                    switch (err.message) {
                        case enumHttpError.CANCELED:
                            setError(enumHttpError.CANCELED)
                            break;
                        default:
                            setError('failed');
                            break;
                    }
                })
                .finally(() => setLoading(false));
        }
        if (RoleHelper.isAdmin(currentUser) || RoleHelper.isSuperAdmin(currentUser)) {
            setDisplayFakeInformation(false);
        }
        else {
            entrepriseService
                .getExtendEntrepriseProfil(currentUser.user.id)
                .then((_recruteur) => {
                    // let isInPeriodeEssai = Helper.isInPeriodeEssai(_recruteur.creationDate, _recruteur.recruteurEntreprises[0].entreprise.abonnementEntreprise.nbSemaineEssai);
                    if (!_recruteur.recruteurEntreprises[0].entreprise.abonnementEntreprise.isFree) {
                        setDisplayFakeInformation(false);
                    }
                })
        }
    }

    return (
        <div id={props.id}>
            <Toast ref={toast} />
            <div id="top">
                <div>
                    <h3 className="dip-fs-2 dip-fw-700 dip-ff-poppins"><Trans i18nKey="formation.potential_candidates" /></h3>
                    {loading ? (
                        <div className="dip-ta-center loader-height-for-thumbnail-list p-mt-6 p-mb-6">
                            <ProgressSpinner />
                        </div>
                    ) : (
                        // displayFakeInformation ? (
                        //     <>
                        //         <div className="search-candidat-infomessage">
                        //             <div><i><Trans i18nKey="entreprise.search_candidat_unvalid_abonnement_1" /></i></div>
                        //             <div><i><Trans i18nKey="entreprise.search_candidat_unvalid_abonnement_2" /></i></div>
                        //         </div>
                        //         <Link className="link-search-candidat-fake-display" to={{ pathname: '/profile_company', hash: "#abonnement" }}>
                        //             <div className="search-candidat-fake-display">
                        //                 <div className='p-grid'>
                        //                     {fakeEtudiants.map((etudiant, indexE) => (
                        //                         <Fragment key={`${etudiant.id}~${indexE}`}>
                        //                             <div className="p-col-12 p-sm-12 p-md-6 p-lg-3">
                        //                                 <CandidateThumbnail etudiant={etudiant} showLogo={false} toast={toast} />
                        //                             </div>
                        //                         </Fragment>
                        //                     ))}
                        //                 </div>
                        //             </div>
                        //         </Link>
                        //     </>
                        // ) : (
                            <>
                                <div className='p-grid'>
                                    {etudiants.map((etudiant, indexE) => (
                                        <Fragment key={`${etudiant.id}~${indexE}`}>
                                            <div className="p-col-12 p-sm-12 p-md-6 p-lg-3">
                                                <CandidateThumbnail etudiant={etudiant} showLogo={false} toast={toast} displayFakeInformation={displayFakeInformation} />
                                            </div>
                                        </Fragment>
                                    ))}
                                </div>
                            </>
                        // )
                    )}
                </div>

                {!loading && !displayFakeInformation && etudiants.length == 0 && error == 'failed' && <div className="dip-ta-center p-mt-6 p-mb-6"><Trans i18nKey="offers.server_connexion_error" /></div>}
                {!loading && !displayFakeInformation && etudiants.length == 0 && error != 'failed' && error != enumHttpError.CANCELED && <div className="dip-ta-center p-mt-6 p-mb-6"><Trans i18nKey="company.no_candidates" /></div>}
                {!displayFakeInformation && (
                    <ThumbnailPaginator lazyParams={lazyParams} setLazyParams={setLazyParams} totalRecords={totalCount} />
                )}
            </div>

        </div>
    )
}