import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState, useEffect, useRef } from 'react';
import OffreEmploiService from '../../services/offreEmploiService';
import { Trans, useTranslation } from 'react-i18next';
import { NewLineFormater } from '../../components/formater/newLineFormater';
import "./vueOffreEmploi.css"
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { Toast } from "primereact/toast";
import Helper from '../../utils/helper';
import { OffreEmploiPrimaryThumbnail } from '../../components/thumbnail/offreEmploiPrimaryThumbnail';
import { CardPresentationEntrepriseThumbnail } from '../../components/thumbnail/cardPresentationEntrepriseThumbnail';
import { useAuthState } from "../../context/context";
import RoleHelper from "../../utils/roleHelper";
import { OffreEmploiSeekedProfileThumbnail } from '../../components/thumbnail/offreEmploiSeekedProfileThumbnail';
import { WysiwygRenderer } from '../../components/wysiwyg/wysiwygRenderer';
import { WysiwygResponsiveRenderer } from '../../components/wysiwyg/wysiwygResponsiveRenderer';
import parse from 'html-react-parser';
import { enumCategorieRecherche } from '../../enums/enumCategorieRecherche';
import { enumNiveauDiplome } from '../../enums/enumNiveauDiplome';

export const VueOffreEmploi = (props) => {

    const { t } = useTranslation();
    const toast = useRef(null);
    const currentUser = useAuthState();

    const [loading, setLoading] = useState(true);
    const [offreEmploi, setOffreEmploi] = useState(null);


    const history = useHistory();
    const offreEmploiService = new OffreEmploiService();

    const search = props.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('offreEmploiId');

    useEffect(() => {

        // si l'utilisateur est un AdminImpersonatingEntreprise, on récupère son adminImpersonatingRecruteurId pour le placer dans le state courant
        if (RoleHelper.isAdminImpersonatingEntreprise(currentUser, history.location.pathname)) {
            const storedState = sessionStorage.getItem('myState');
            const state = storedState ? JSON.parse(storedState) : null;
            if (state && state.adminImpersonatingRecruteurId) {
                history.replace(history.location.pathname, { ...history.location.state, adminImpersonatingRecruteurId: state.adminImpersonatingRecruteurId });
            }
            // on supprime le state de la session storage
            sessionStorage.removeItem('myState');
        }

        //récupérer l'annonce avec l'id
        setLoading(true);
        offreEmploiService.getOffreEmploiExtended(id).then(_offreEmploi => {
            if (_offreEmploi.dateCreation) { _offreEmploi.dateCreation = Helper.dateTimeToLocaleDateTime(_offreEmploi.dateCreation) }
            if (_offreEmploi.dateDebutSouhaite) { _offreEmploi.dateDebutSouhaite = Helper.dateTimeToLocaleDateTime(_offreEmploi.dateDebutSouhaite) }
            setOffreEmploi(_offreEmploi);
            setLoading(false);
        })
            .catch(err => {
                history.push('/')
            });
    }, [])

    const handleClickRetourListe = () => {
        if (RoleHelper.isEtudiant(currentUser)) {
            const pageProfile = history.location?.state?.pageProfil ? history.location.state.pageProfil : 1;
            const pageFormation = history.location?.state?.pageFormation ? history.location.state.pageFormation : 1;
            const pageFavorites = history.location?.state?.pageFavorites ? history.location.state.pageFavorites : 1;
            history.push(`/offresCompatibles?pageProfil=${pageProfile}&pageFormation=${pageFormation}&pageFavorites=${pageFavorites}`);
        }
        else if (RoleHelper.isEntreprise(currentUser)) {
            history.push('/offers');
        }
        else {
            history.push('/');
        }

    }

    const handleClickRetourAccueil = () => {
        history.push('/');
    }

    const handleClickFormationsCompatibles = () => {
        let _filters = []
        // métier
        if (offreEmploi.offreEmploiAppellations?.length > 0) {
            offreEmploi.offreEmploiAppellations.forEach(
                e => {
                    _filters.push({ categorie: enumCategorieRecherche.Appellation, label: e.appellation.libelle, value: e.appellation.id })
                });
        }
        else if (offreEmploi.appellation) {
            _filters.push({ categorie: enumCategorieRecherche.Appellation, label: offreEmploi.appellation.libelle, value: offreEmploi.appellation.id })
        }
        // niveau de diplome
        switch (offreEmploi.niveauDiplome) {
            case parseInt(enumNiveauDiplome.BAC2):
                _filters.push({ categorie: enumCategorieRecherche.NiveauSortie, value: enumNiveauDiplome.BAC2 }
                    , { categorie: enumCategorieRecherche.NiveauSortie, value: enumNiveauDiplome.BAC3 }
                    , { categorie: enumCategorieRecherche.NiveauSortie, value: enumNiveauDiplome.BAC4 }
                    , { categorie: enumCategorieRecherche.NiveauSortie, value: enumNiveauDiplome.BAC5 }
                )
                break;
            case parseInt(enumNiveauDiplome.BAC3):
                _filters.push({ categorie: enumCategorieRecherche.NiveauSortie, value: enumNiveauDiplome.BAC3 }
                    , { categorie: enumCategorieRecherche.NiveauSortie, value: enumNiveauDiplome.BAC4 }
                    , { categorie: enumCategorieRecherche.NiveauSortie, value: enumNiveauDiplome.BAC5 }
                )
                break;
            case parseInt(enumNiveauDiplome.BAC4):
                _filters.push({ categorie: enumCategorieRecherche.NiveauSortie, value: enumNiveauDiplome.BAC4 }
                    , { categorie: enumCategorieRecherche.NiveauSortie, value: enumNiveauDiplome.BAC5 }
                )
                break;
            case parseInt(enumNiveauDiplome.BAC5):
                _filters.push({ categorie: enumCategorieRecherche.NiveauSortie, value: enumNiveauDiplome.BAC5 })
                break;
            default:
                break;
        }
        // compétences
        if (offreEmploi.competences?.length > 0) {
            if (offreEmploi.competences.length === 1) {
                const e = offreEmploi.competences[0];
                _filters.push({ label: e.competence.libelle, value: e.competenceId, type: null, categorie: 0, exige: true });
            } else {
                offreEmploi.competences.forEach(
                    e => {
                        _filters.push({ label: e.competence.libelle, value: e.competenceId, type: null, categorie: 0, exige: false });
                    });
            }
        }
        // type de contrat
        if (offreEmploi.typeContrat) {
            switch(offreEmploi.natureContrat.code) {
                // CDD/CDI
                case 'E1':
                    _filters.push({ categorie: enumCategorieRecherche.ContratCDDCDI, value: true })
                    break;
                // Alternance - contrat d'apprentissage
                case 'E2':
                    _filters.push({ categorie: enumCategorieRecherche.ContratAltApp, value: true })
                    break;
                // Alternance - contrat de professionnalisation
                case 'FS':
                    _filters.push({ categorie: enumCategorieRecherche.ContratAltPro, value: true })
                    break;
                // Stage
                case 'ST':
                    _filters.push({ categorie: enumCategorieRecherche.ContratStage, value: true })
                    break;
                default:
                    break;
            }
        }
        history.push({ pathname: '/rechercheAffinee', state: { filters: _filters, adminImpersonatingRecruteurId: history.location?.state?.adminImpersonatingRecruteurId } });
    }

    const handlePostuler = () => {
        if (offreEmploi.providerId == 1) {
            if (offreEmploi.isApplied) {
                handleClickAnnulerCandidatureAnnonceInterne();
            }
            else {
                handleClickPostulerAnnonceInterne();
            }
        } else {
            handleClickPostulerAnnonceExterne();
        }

    }

    const handleClickPostulerAnnonceInterne = () => {
        offreEmploiService.postulerOffreEmploiInterne(offreEmploi.id)
            .then(data => {
                toast.current.show({ severity: 'success', summary: t('recruitment.message.postuler_candidature'), detail: t('recruitment.message.message_envoye_entreprise'), life: 3000 });
                let _offreEmploi = { ...offreEmploi }
                _offreEmploi.isApplied = true
                setOffreEmploi(_offreEmploi)
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.unknown'), life: 3000 })
            })
            .finally(() => {

            })
    }

    const handleClickAnnulerCandidatureAnnonceInterne = () => {
        offreEmploiService.annulerCandidatureOffreEmploiInterne(offreEmploi.id)
            .then(data => {
                toast.current.show({ severity: 'success', summary: t('recruitment.message.annuler_candidature'), detail: t('recruitment.message.message_envoye_entreprise'), life: 3000 });
                let _offreEmploi = { ...offreEmploi }
                _offreEmploi.isApplied = false
                setOffreEmploi(_offreEmploi)
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.unknown'), life: 3000 })
            })
            .finally(() => {

            })
    }

    const handleClickPostulerAnnonceExterne = () => {
        let link;
        if (offreEmploi.origineOffre.origine == 1) {
            link = offreEmploi.origineOffre.urlOrigine;
        }
        else if (offreEmploi.origineOffre.origine == 2) {
            link = offreEmploi.origineOffre.partenaire[0].url
        }

        var win = window.open(link)
        win.focus();
    }

    const renderTeleTravail = () => {
        if (offreEmploi.providerId == 1) {
            switch (offreEmploi.teletravail) {
                case 1:
                    return <span><b><Trans i18nKey="advert.remote_working.colons" /></b><Trans i18nKey="general.no" /></span>;
                case 2:
                    return <span><b><Trans i18nKey="advert.remote_working.colons" /></b><Trans i18nKey="general.possible" /></span>;
                case 3:
                    return <span><b><Trans i18nKey="advert.remote_working.colons" /></b><Trans i18nKey="days_per_week.1" /></span>;
                case 4:
                    return <span><b><Trans i18nKey="advert.remote_working.colons" /></b><Trans i18nKey="days_per_week.2" /></span>;
                case 5:
                    return <span><b><Trans i18nKey="advert.remote_working.colons" /></b><Trans i18nKey="days_per_week.3" /></span>;
                case 6:
                    return <span><b><Trans i18nKey="advert.remote_working.colons" /></b><Trans i18nKey="days_per_week.4" /></span>;
                case 7:
                    return <span><b><Trans i18nKey="advert.remote_working.colons" /></b><Trans i18nKey="days_per_week.5" /></span>;
                default:
                    return <></>;
            }
        }
        else {
            return <></>
        }
    }
    const renderSalaire = () => {
        if (offreEmploi.providerId == 1) {
            if (offreEmploi?.salaire?.montantEuro)
                return <><label className="label-for-OE"><Trans i18nKey="label.salary" /></label><span>{offreEmploi?.salaire?.montantEuro} €</span></>
            else
                return <></>
        }
        else {
            //Fix Temp -> LM
            if (offreEmploi?.salaire?.libelle && offreEmploi?.salaire?.libelle != "De" && offreEmploi?.salaire?.libelle != "Mensuel de" && offreEmploi?.salaire?.libelle != "Annuel de")
                return <><label className="label-for-OE"><Trans i18nKey="label.salary" /></label><span>{offreEmploi?.salaire?.libelle} {offreEmploi?.salaire?.commentaire}</span></>
            else
                return <></>
        }
    }

    const renderSecteurActivite = () => {
        if (offreEmploi.providerId == 1) {
            if (offreEmploi.entreprise?.secteurActivite)
                return <><label className="label-for-OE"><Trans i18nKey="label.activity_sector" /></label><span>{offreEmploi.entreprise?.secteurActivite}</span></>
            else
                return <></>
        }
        else {
            if (offreEmploi?.secteurActiviteLibelle)
                return <><label className="label-for-OE"><Trans i18nKey="label.activity_sector" /></label><span>{offreEmploi.secteurActiviteLibelle}</span></>
            else
                return <></>
        }
    }
    const notavailable_Student = (
        <div className="p-col-12 p-sm-12 p-lg-12 center-bloc">
            <div><Trans i18nKey="advert.notavailable_student" />
                <div className="OE-card-margin">
                    <div className="p-grid">
                        <div className="p-col-12 p-sm-12 p-lg-12 center-bloc">
                            <Button className="p-button-raised p-button-rounded primary-color-border" label={t('offers.goToOffers')} onClick={() => handleClickRetourListe()} />
                        </div>
                    </div>
                </div>
            </div></div>)


    const notavailable_Other = (
        <div className="p-col-12 p-sm-12 p-lg-12 center-bloc">
            <div><Trans i18nKey="advert.notavailable_other" /> <div className="OE-card-margin">
                <div className="p-grid">
                    <div className="p-col-12 p-sm-12 p-lg-12 center-bloc">
                        <Button className="p-button-raised p-button-rounded primary-color-border" label={t('offers.goToHome')} onClick={() => handleClickRetourAccueil()} />
                    </div>
                </div>
            </div>
            </div>
        </div>)



    return (
        <div className={props.className}>
            <div className='p-grid OE-main-container'>
                <div className='p-col-12 p-sm-12 p-lg-8'>
                    <Toast ref={toast} />

                    {loading ? (
                        <div style={{ textAlign: 'center', marginTop: '20%' }}><ProgressSpinner></ProgressSpinner></div>
                    ) : (

                        <div>
                            <div className="p-col-12 p-sm-12 p-lg-6 p-mb-2">
                                <Button className="p-button-raised primary-color-border dip-color-tc" onClick={() => handleClickRetourListe()} label={t('offers.goToList')} icon="pi pi-arrow-left" />
                            </div>
                            {offreEmploi?.statut == "Archivee" ? (RoleHelper.isEtudiant(currentUser) ? (
                                <div className="p-grid">{notavailable_Student}</div>
                            ) : (
                                <div className="p-grid">{notavailable_Other}</div>
                            )
                            ) : (
                                <div>

                                    <OffreEmploiPrimaryThumbnail
                                        offreEmploi={offreEmploi}
                                        toast={toast}
                                        handlePostuler={handlePostuler}
                                        handleConsulterFormations={handleClickFormationsCompatibles}
                                    />
                                    {offreEmploi.afficherPresentation && offreEmploi.entreprise?.description != null && offreEmploi.entreprise?.description != "" &&
                                        <>
                                            <br />
                                            <CardPresentationEntrepriseThumbnail entreprise={offreEmploi.entreprise} />
                                        </>
                                    }
                                    <br />
                                    <Card className={`p-shadow-4  voe-card`}>
                                        <div style={{ positon: "relative" }} >
                                            <div className='voe-compatible-course-container'>
                                                <div className='app-width-limit'>
                                                    <div className='p-grid dip-jc-center p-pr-6 p-pl-6'>
                                                        <div className='p-col-12 p-sm-12 p-lg-8 dip-flex-row dip-jc-end'>
                                                            {!RoleHelper.isNotConnected(currentUser) &&
                                                                <Button className='dip-btn-red-outline p-button-rounded p-pr-5 p-pl-5 dip-only-pc' label={t('offers.matching_courses.full')} onClick={handleClickFormationsCompatibles} />
                                                            }
                                                        </div>
                                                        {/* please connect */}
                                                        {/* {RoleHelper.isNotConnected(currentUser) &&
                                                            <>
                                                                <div className='p-col-12 p-sm-12 p-lg-8 dip-flex-row  dip-jc-center voe-compatible-course'>
                                                                    <p className='dip-fs-2 dip-ta-center'><Trans i18nKey="offers.please_connect" /></p>
                                                                </div>
                                                            </>} 
                                                        */}
                                                    </div>
                                                </div>

                                            </div>
                                            {/* blur effect */}
                                            {/* <div className={`${RoleHelper.isNotConnected(currentUser) && "voe-blur"}`}> 
                                                <h2 className="dip-color-id"><Trans i18nKey="general.description" /></h2>
                                                // ligne à commenter // <h4 className="dip-color-id">{offreEmploi?.intitule === offreEmploi?.appellation?.libelle ? (<p></p>) : (<p>{offreEmploi?.intitule}</p>)}</h4>
                                                {currentUser.isAuthenticated ? (offreEmploi.providerId == 1 ? (
                                                    offreEmploi?.description && parse(offreEmploi?.description)
                                                ) : (
                                                    <NewLineFormater text={offreEmploi?.description}></NewLineFormater>
                                                )) : (
                                                    <div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam luctus dapibus efficitur. Aliquam ut arcu sed justo eleifend tincidunt quis eget arcu. Vivamus id lacinia nisl, vel vehicula nulla. Donec sed mi eu purus mollis aliquet. In sed tellus a lorem auctor dapibus eget a massa. Integer feugiat erat sed sem suscipit faucibus. Ut efficitur eu velit ac sagittis. Aenean at euismod nisi. Aenean ullamcorper, velit non elementum laoreet, tellus erat convallis ipsum, ac aliquet lorem odio quis purus.</p>
                                                        <p>In pretium odio nunc, eu cursus erat dictum ac. Phasellus accumsan molestie pharetra. Pellentesque ullamcorper urna eu dolor tincidunt, vel cursus felis ultrices. Curabitur ut ex facilisis, maximus tellus mattis, accumsan lectus. Morbi accumsan nisi nec turpis blandit, et imperdiet justo congue. Aenean interdum dignissim quam sit amet accumsan. Proin porttitor pretium ullamcorper.</p>
                                                        <p>Ut posuere, velit quis venenatis maximus, purus magna consequat felis, et vestibulum mi elit sit amet nulla. Sed ut est efficitur, euismod mauris ut, sodales ipsum. Nullam id tortor at metus interdum dignissim eget at leo. Phasellus at malesuada tellus, quis aliquam ligula. Duis id nulla ligula. Cras vel ante arcu. Curabitur interdum ante ut dictum tincidunt. Aenean pharetra lectus et lorem porttitor, id malesuada sem aliquet. Vestibulum posuere nunc eu lorem mattis, eget placerat nibh feugiat. Cras pharetra dapibus purus, id dapibus quam commodo vel. Etiam imperdiet, felis et molestie suscipit, ligula odio hendrerit dui, nec rutrum eros neque faucibus orci. Sed tincidunt eu metus vel vulputate.</p>
                                                    </div>)}
                                            </div> */}

                                            <div>
                                                <h2 className="dip-color-id"><Trans i18nKey="general.description" /></h2>
                                                {offreEmploi.providerId == 1 ? (
                                                    offreEmploi?.description && parse(offreEmploi?.description)
                                                ) : (
                                                    <NewLineFormater text={offreEmploi?.description}></NewLineFormater>
                                                )}
                                            </div>
                                        </div>
                                    </Card>
                                    <br />

                                    {(offreEmploi.competences?.length > 0 || offreEmploi.qualiteProfessionnelle?.length > 0 || offreEmploi.offreEmploiLangue?.length > 0) &&
                                        <>
                                            <OffreEmploiSeekedProfileThumbnail
                                                offreEmploi={offreEmploi}
                                            />
                                            <br />
                                        </>}

                                    {(offreEmploi?.salaire || offreEmploi?.teletravail || offreEmploi?.qualificationLibelle || offreEmploi?.secteurActiviteLibelle || offreEmploi?.entreprise?.secteurActivite) && <>
                                        <Card className="p-shadow-4  voe-card">
                                            <h2 className="dip-color-id"><Trans i18nKey="offers.additional_info" /></h2>
                                            <p>{offreEmploi?.permisDeConduire?.exigence == 2 && <><label className="label-for-OE"><Trans i18nKey="label.driver_licence" /></label>
                                                <span><Trans i18nKey="requirement.required.m" /></span></>}</p>
                                            <p>{renderTeleTravail()}</p>
                                            <p>{renderSalaire()}</p>
                                            <p>{offreEmploi?.qualificationLibelle && <label className="label-for-OE"><Trans i18nKey="label.qualification" /></label>}{<span>{offreEmploi?.qualificationLibelle}</span>}</p>
                                            <p>{renderSecteurActivite()}</p>
                                            <p>{offreEmploi?.secteurActiviteLibelle && <label className="label-for-OE"><Trans i18nKey="label.activity_sector" /></label>}{<span>{offreEmploi?.secteurActiviteLibelle}</span>}</p>

                                        </Card><br /></>}

                                    <div className="p-grid">
                                        <>
                                            <div className="p-col-12 p-sm-12 p-lg-6 p-ml-2">
                                                <Button className="p-button-raised primary-color-border dip-color-tc" label={t('offers.goToList')} icon="pi pi-arrow-left" onClick={() => handleClickRetourListe()} />
                                            </div>
                                            <div className="p-col-12 p-sm-12  p-lg-12 center-bloc">
                                                {offreEmploi.providerId == 1 ? (
                                                    offreEmploi.isApplied ? (
                                                        <Button icon="pi pi-check" className="OE-plain-button primary-color-border dip-color-tc" label={t('advert.application_in_progress')} onClick={() => handleClickAnnulerCandidatureAnnonceInterne()} />
                                                    ) : (
                                                        RoleHelper.isNotConnected(currentUser) ? (
                                                            <>
                                                                <div className="p-col-12 p-sm-12 p-lg-12 center-bloc"><i><Trans i18nKey='advert.log_in_to_apply' /></i></div>
                                                            </>
                                                        ) :
                                                        RoleHelper.isEtudiant(currentUser) && (
                                                            <Button id='internal-announcement' className="p-button-raised dip-btn-red-bg" onClick={() => handleClickPostulerAnnonceInterne()} >
                                                                <Trans i18nKey="advert.apply_in_1_clic" />
                                                            </Button>
                                                        )
                                                    )
                                                ) : (
                                                    <Button id='external-announcement' className="p-button-raised dip-btn-red-bg" onClick={() => handleClickPostulerAnnonceExterne()} >
                                                        <Trans i18nKey="offers.goToPartner" />
                                                    </Button>
                                                )}
                                            </div>
                                        </>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                    }
                </div >
            </div >
        </div>
    )
}
