import { useEffect, useRef, useState } from "react";
import "./formCompanyAbonnementAdmin.css"
import { Button } from "primereact/button";
import { ButtonGoBack } from "../buttonGoBack/buttonGoBack";
import { Trans, useTranslation } from "react-i18next";
import { useAuthState } from "../../context/context";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { enumRoles } from "../../enums/enumRoles";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from "primereact/dropdown";
import EntrepriseService from "../../services/entrepriseService";
import { enumRythmePaiement } from "../../enums/enumRythmePaiement";
import { enumChooseSubscription } from "../../enums/enumChooseSubscription";

import { IgrMultiColumnComboBoxModule } from 'igniteui-react-grids';
import { IgrMultiColumnComboBox } from 'igniteui-react-grids';
import RoleHelper from "../../utils/roleHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


export const FormCompanyAbonnementAdmin = () => {

    const { t } = useTranslation()
    const toast = useRef(null)
    const history = useHistory();
    const adminImpersonatingRecruteurId = history.location.state.adminImpersonatingRecruteurId;
    const currentUser = useAuthState()
    const entrepriseService = new EntrepriseService()
    const [loading, setLoading] = useState(true)
    const [dialogLoading, setDialogLoading] = useState(false);
    const [entreprise, setEntreprise] = useState(null);
    const [abonnement, setAbonnement] = useState(null);
    const [abonnementOptions, setAbonnementOptions] = useState(null);
    const [dialogSubscriptionVisible, setDialogSubscriptionVisible] = useState(false);
    IgrMultiColumnComboBoxModule.register();

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        setLoading(true);
        if (RoleHelper.isAdmin(currentUser) && adminImpersonatingRecruteurId !== undefined) {
            entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((entrepriseId) => {
                entrepriseService
                    .getEntrepriseAbonnement(entrepriseId)
                    .then((_entreprise) => {
                        setEntreprise(_entreprise);
                        setAbonnement({ abonnementEntrepriseId: _entreprise.abonnementEntreprise.id, rythmePaiement: _entreprise.rythmePaiement });
                    })
                    .catch((err) => {
                        toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_load'), life: 3000 })
                    });
                entrepriseService
                    .getAbonnementEntreprise()
                    .then((_abonnement) => {
                        // créer un tableau contenant le label et la valeur = id abonnement
                        const options = [];
                        _abonnement.forEach(element => {
                            options.push({ id: element.code, label: element.label, tarifMensuel: element.tarifMensuel + t('monnaie.euro'), value: element.id })
                        });
                        setAbonnementOptions(options);
                    })
                    .catch((err) => {
                        toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_load'), life: 3000 })
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
        }
        else {
            history.push("/")
        }
    }

    const setDialogSubscription = () => {
        setDialogSubscriptionVisible(true);
    }

    const hideDialogSubscription = () => {
        setDialogSubscriptionVisible(false);
    }

    const subscriptionDate = (date) => {
        return date && new Date(date).toLocaleDateString();
    }

    const onDropdownChange = (e, name) => {
        let _abonnement = { ...abonnement };
        _abonnement[name] = e;

        setAbonnement(_abonnement);
    }

    const updateSubscription = () => {
        if (abonnement.abonnementEntrepriseId != entreprise.abonnementEntreprise.id) {
            setDialogLoading(true);
            entrepriseService
                .updateAbonnementEntreprise(entreprise.id, abonnement)
                .then(() => {
                    toast.current.show({ severity: 'success', summary: t('success.success'), detail: t('subscription.subscription_update'), life: 3000 })
                    loadData();
                    hideDialogSubscription();
                })
                .catch((err) => {
                    toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_save'), life: 3000 })
                });
        }
        setDialogLoading(false);
    }

    const echeanceDateIsExpired = (date) => {
        let dateEcheance = new Date(date);
        dateEcheance.setDate(dateEcheance.getDate() + (entreprise?.abonnementEntreprise?.nbSemaineEssai * 7));
        // si la date d'échéance est supérieur à la date du jour on return true
        return dateEcheance < new Date();
    }

    const echeanceDate = (date) => {
        let dateEcheance = new Date(date);
        dateEcheance.setDate(dateEcheance.getDate() + (entreprise?.abonnementEntreprise?.nbSemaineEssai * 7));
        return dateEcheance.toLocaleDateString();
    }

    return (
        <>
            <Toast ref={toast} />
            {loading ? (
                <div className="fsp-loading">
                    <ProgressSpinner />
                </div>
            ) : (
                <>
                    {/* <ButtonGoBack /> */}
                    <div className="dip-flex-row fca-name-container">
                        <div className="fca-name-company"><span><Trans i18nKey="subscription.name_company" /></span> : {entreprise?.nom}</div>
                        {/* <div className="fca-name-company"><span><Trans i18nKey="subscription.name_user" /></span> : {entreprise?.prenom} {entreprise?.nom}</div> */}
                    </div>

                    <div className="fca-container-grid">
                        <div className="dip-ta-center fca-title-company"><Trans i18nKey="subscription.current_subscriptions" /></div>
                        <div className="p-field p-grid dip-jc-center">
                            <div className="dip-flex-column fca-border-left">
                                <div><Trans i18nKey="subscription.id" /></div>
                                <div><Trans i18nKey="subscription.subscription_name" /></div>
                                {/* <div><Trans i18nKey="subscription.subscription_type" /></div> */}
                                <div><Trans i18nKey="subscription.subscription_price" /></div>
                                {/* <div><Trans i18nKey="subscription.trial_period" /></div> */}
                                <div className="fca-border-bottom"><Trans i18nKey="subscription.subscription_date" /></div>
                                {/* <div className="fca-border-bottom">{entreprise?.abonnementEntreprise.isFree ? <Trans i18nKey="subscription.end_trial_period" /> : <Trans i18nKey="subscription.subscription_term" />}</div> */}
                            </div>

                            {entreprise?.abonnementEntreprise?.isFree ? (
                                <div className="dip-flex-column fca-border-right">
                                    <div>{entreprise?.abonnementEntreprise.code}</div>
                                    <div>{entreprise?.abonnementEntreprise.label}</div>
                                    {/* <div><Trans i18nKey={"campus.no_location"}/></div> */}
                                    <div><Trans i18nKey="subscription.free" /></div>
                                    {/* <div>{echeanceDateIsExpired(entreprise?.creationDate) ? <Trans i18nKey={"subscription.expired"} /> : <Trans i18nKey={"subscription.in_progress"} />} </div> */}
                                    <div className="fca-border-bottom">{entreprise?.dateSouscription ? subscriptionDate(entreprise?.dateSouscription) : <Trans i18nKey={"campus.no_location"} />}</div>
                                    {/* <div className="fca-border-bottom">{echeanceDate(entreprise?.creationDate)}</div> */}
                                </div>
                            ) : (
                                <div className="dip-flex-column fca-border-right">
                                    <div>{entreprise?.abonnementEntreprise.code}</div>
                                    <div>{entreprise?.abonnementEntreprise.label}</div>
                                    {/* <div>{entreprise?.rythmePaiement == null ? <Trans i18nKey={"campus.no_location"} /> : entreprise?.rythmePaiement == enumRythmePaiement.Mensuel ? <Trans i18nKey={"subscription.monthly"} /> : <Trans i18nKey={"subscription.yearly"} />}</div> */}
                                    <div>{entreprise?.abonnementEntreprise.tarifMensuel}<Trans i18nKey="monnaie.euro" /></div>
                                    {/* <div>{entreprise?.calculPeriodeEssai ? <Trans i18nKey={"subscription.in_progress"} /> : <Trans i18nKey={"subscription.expired"} />} </div> */}
                                    <div className="fca-border-bottom">{subscriptionDate(entreprise?.dateSouscription)}</div>
                                    {/* <div className="fca-border-bottom">{subscriptionDate(entreprise?.calculEcheanceDate)}</div> */}
                                </div>
                            )}
                        </div>
                        <div className="dip-ta-center">
                            <Button className="fca-update-button" label={<Trans i18nKey="subscription.modify_current_subscription" />} onClick={setDialogSubscription} />
                        </div>
                    </div>
                    <Dialog visible={dialogSubscriptionVisible} style={{ width: '50vw' }} header={<Trans i18nKey="subscription.choose_new_subscription" />} modal className="p-fluid fca-dialog-container" onHide={hideDialogSubscription}>
                        <>
                            {dialogLoading ? (
                                <div className="fsp-loading">
                                    <ProgressSpinner />
                                </div>
                            ) : (
                                <div className="p-m-2">
                                    <div className="p-field p-grid dip-flex-column fca-dropdown-container">
                                        <div className="p-col-12 p-md-12 fca-dropdown-contain">
                                            <label htmlFor='abonnement'><Trans i18nKey="subscription.subscription" /></label>
                                            <div id="fca-multi-column-design" className="fca-multi-column-container">
                                                <IgrMultiColumnComboBox
                                                    className="customComboBox"
                                                    width="auto"
                                                    height="40px"
                                                    defaultColumnWidth="150"
                                                    dataSource={abonnementOptions}
                                                    placeholder={t("subscription.select_new_subscription")}
                                                    autoComplete="true"
                                                    fields={["id", "label", "tarifMensuel"]}
                                                    textField="label"
                                                    valueField={['value']}
                                                    valueChanged={(s, e) => {
                                                        // on active la fonction de changement de valeur seulement si la valeur est modifié
                                                        if (e.oldValue != null && e.oldValue != e.newValue) {
                                                            onDropdownChange(e.newValue, 'abonnementEntrepriseId')
                                                        }
                                                    }}
                                                    value={abonnement?.abonnementEntrepriseId}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field p-grid dip-flex-rows">
                                        <div className="p-col-6 p-md-6">
                                            <Button label={<Trans i18nKey={"general.cancel"} />} className="fca-button-cancel" onClick={hideDialogSubscription} />
                                        </div>
                                        <div className="p-col-6 p-md-6">
                                            <Button label={<Trans i18nKey={"general.confirm_your_choice"} />} className="fca-button-confirm" onClick={updateSubscription} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    </Dialog>
                </>
            )}
        </>
    )
}