import { Button } from "primereact/button"
import { Trans, useTranslation } from "react-i18next"
import dip_phone_hands from "../../../images/onBoarding/dip_phone_hands.png";

export const RobIntro = (props) => {
    const { t } = useTranslation()

    return (
        <div>
            <div className="dip-flex-column dip-pc-center dip-ai-center">
                <div className="rob-font-size dip-ta-center">
                    <h1 className="dip-m-0"><Trans i18nKey="onboarding.complete_your_profile" /></h1>
                    <h1 className="dip-m-0"><div className="dip-color-tc"><Trans i18nKey='onboarding.in_3_min' /></div></h1>
                    <br />
                    <h3 className="dip-m-0"><Trans i18nKey="home.catchphrase_students_second" /></h3>
                </div>
                <img className="rob-intro-section-img" src={dip_phone_hands}></img>
            </div>
            <br />
            <div className="dip-ta-center">
                <Button
                    id="startOnboardingEtudiant"
                    loading={props.submitting}
                    className={`dip-btn-red-bg`}
                    label={t('general.lets_go')}
                    onClick={props.onNextClick}
                />
            </div>
        </div>
    )
}